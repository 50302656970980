import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import { Box } from "@mui/material";
import AppBar from "../common/Appbar";
import "../../assets/css/Global.css";
import "../../assets/css/layout/style.css";
import forestImg from "../../assets/imgs/forestCoverImg.jpg";

const RegisterLayout = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <AppBar />

      {/* <div style={{ position: "relative", width: "100%", height: "300px", zIndex: "0", backgroundImage: `url(${forestImg})` }}>
      </div> */}
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          backgroundColor: "white",
          zIndex: "15",
          position: "relative",
          top: "150px",
        }}
      >
        <Outlet />
      </Container>
    </div>
  );
};

export default RegisterLayout;
