import React from "react";
import { Box, Button } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';

import invoiceApi from "../../../api/invoiceApi";

const PdfIcon = ({ params }) => {
  const open = () => {
    window.open(params.value)
  }

  const send = async () => {
    console.log(params)
    // companyIdをもとにuser情報を取得し、メールアドレス宛にPDF添付してメール送信
    if (window.confirm(`以下の企業に請求書を送付します。よろしいですか？\n${params.row.companyName}`)) {
      await invoiceApi.annualFeePdfSender({
        year: "2024",
        companyId: params.row.companyId,
        membership: params.row.membership,
        url: params.row.pdfUrl,
      })
    }
  }

  return (
    <>
      {params.value &&
        <Box>
          <Button
            onClick={open}
          >
            <PictureAsPdfIcon sx={{ color: "#ff6347" }} />
          </Button>
          <Button
            onClick={send}
          >
            <SendIcon sx={{ color: "#00b0ff" }}/>
          </Button>
        </Box>
      }
    </>
  );
};

export default PdfIcon;
