import React from "react";
import { Box, Typography } from "@mui/material";
import managi from "../../assets/imgs/managi.png";
import books from "../../assets/imgs/books.png";
import profile from "../../assets/imgs/profile.png";
import { SubpageHeader } from "../../components/homepage/SubpageHeader";

export const AboutManagi = () => {
  return (
    <Box>
      <Box>
        <SubpageHeader
          title="理事長紹介"
          breadcrumb={[{ name: "理事長紹介", link: "/shunsuke-managi" }]}
        />
      </Box>
      <Box sx={{ margin: "0 auto", padding: "20px", maxWidth: "1024px" }}>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
            margin: "20px 20px",
            borderRadius: "20px",
          }}
        >
          <Box sx={{ flexShrink: "1" }}>
            <img
              src={managi}
              alt="managi_shunsuke"
              style={{
                borderRadius: "50%",
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box sx={{ padding: "20px 10px" }}>
            <Typography variant="h5">理事長　馬奈木俊介（まなぎ しゅんすけ）</Typography>
            <Box sx={{ padding: "20px 0px" }}>
              九州大学工学研究院主幹教授 都市研究センター長
              <br />
              国連・新国富報告書代表 国連・世界SDGs報告書評価員
              <br />
              国連・新しい資本円卓会議委員
              <br />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: "column",
            padding: "40px",
          }}
        >
          <img src={profile} alt="profile" />
        </Box>
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            alighItems: "center",
            gap: "20px",
          }}
        >
          <span
            style={{
              backgroundColor: "white",
              border: "solid 5px #78B94F",
              width: "20px",
              height: "20px",
              marginTop: "5px",
              borderRadius: "50%",
              boxShadow: "0 0 0 1px #78B94F",
              display: "inline",
            }}
          ></span>
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            著書
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <img src={books} alt="books" width="100%" />
        </Box>
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            alighItems: "center",
            gap: "20px",
          }}
        >
          <span
            style={{
              backgroundColor: "white",
              border: "solid 5px #78B94F",
              width: "20px",
              height: "20px",
              marginTop: "5px",
              borderRadius: "50%",
              boxShadow: "0 0 0 1px #78B94F",
              display: "inline",
            }}
          ></span>
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            関連リンク
          </Typography>
        </Box>
        <Box sx={{ marginLeft: "20px", padding: "0px 20px" }}>
          <div>
            <a href="https://aiesg.co.jp/" target="_blank" rel="noopener noreferrer">aiESG</a>
            <span> - 「プロダクト/サービスレベルのESG分析」を通して、持続可能な社会の実現を目指す大学発スタートアップ</span>
          </div>
          <div>
            <a href="https://www.kyushu-u.ac.jp/ja/" target="_blank" rel="noopener noreferrer">九州大学</a>
            {/* <span> - </span> */}
          </div>
          <div>
            <a href="https://www.managi-lab.com/" target="_blank" rel="noopener noreferrer">馬奈木俊介研究室</a>
            {/* <span> - </span> */}
          </div>
        </Box>
      </Box>
    </Box>
  );
};
