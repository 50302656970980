import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { createCanvas } from 'canvas';
import storage from "../../../../config/firebaseConfig.js";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf"
import * as pdfjsWoker from "pdfjs-dist/legacy/build/pdf.worker"
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWoker

// const getHash = async (text) => {
//   const uint8 = new TextEncoder().encode(text);
//   const digest = await crypto.subtle.digest("SHA-256", uint8);
//   return Array.from(new Uint8Array(digest))
//     .map((v) => v.toString(16).padStart(2, "0"))
//     .join("");
// };

/* *********************************************************************
* ファイル名称を保ったままアップロードするファンクションです。
* PDFファイルをpngに変換し、Blob形式の文字列を合わせて返却します。
* 同一フォルダ内に同一名称のファイルがある場合は上書きされるので注意してください。
********************************************************************* */

const convertToPng = async (file) => {
  return new Promise((resolve, reject) => {
    const imageList = []
    file.arrayBuffer()
    .then(async (fileBuffer) => {
      // const pdfDoc = await pdfjsLib.getDocument({ data: fileBuffer }).Promise
      const pdfDoc = await (await pdfjsLib.getDocument({ data: fileBuffer }).promise)

      for(let i = 1; i <= pdfDoc.numPages; i++ ){
        const page = await pdfDoc.getPage(i)
        const viewport = page.getViewport({ scale: 1.0 })
        const canvas = createCanvas(viewport.width, viewport.height)
        const ctx = canvas.getContext("2d")

        await page.render({ canvasContext: ctx, viewport }).promise
        const image = canvas.toDataURL("image/png")
        imageList.push(image)
      }
    })
    .then(() => {
      resolve(imageList)
    })
  })
}

const dataURL2File = async (dataURL, fileName) => {
  const blob = await (await fetch(dataURL)).blob()
  // return new File([blob], fileName, { type: "image/png"})
  return new File([blob], fileName, { type: blob.type})
}

const convertProcess = async (file, pathAry, fileName) => {
  const pngUrls = []
  const convertFiles = await convertToPng(file)
  return new Promise((resolve, reject) => {
    const pngFileName = fileName.replace(".pdf", ".png")
    convertFiles.forEach(async (pngFile, index) => {
      const pngPath = "/" + (pathAry.length ? pathAry.join("/") + "/png/" : "") + index + "_" + pngFileName;
      const storageRefForPng = ref(storage, pngPath);
      const pngUploadTask = uploadBytesResumable(storageRefForPng, await dataURL2File(pngFile, pngFileName));
      const pngUrl = await getDownloadURL(pngUploadTask.snapshot.ref);
      // pngPaths.push(pngPath)
      pngUrls.push(pngUrl)
      // const pngBlob = await dataURL2File(pngFile, pngFileName)
      // pngBlobs.push(pngBlob)
      // pngDataUrls.push(pngFile)
      // console.log(pngBlobs)
      // console.log(pngDataUrls)
    })
    resolve(pngUrls)
  })
}

// ex) uploadImg(file, [dir1, dir2] -> path: /dir1/dir2/file.name)
const uploadPdfWithConvertImg = async (file, pathAry) => {
  if (!file.size) return "";
  
  const fileName = file.name
  const pngUrls = await convertProcess(file, pathAry, fileName);
  const pdfPath = "/" + (pathAry.length ? pathAry.join("/") + "/pdf/" : "") + fileName;
  const storageRefForPdf = ref(storage, pdfPath);
  const pdfUploadTask = uploadBytesResumable(storageRefForPdf, file);
  const pdfUrl = await getDownloadURL(pdfUploadTask.snapshot.ref);

  // pngUrls の返却値がArray(0)になってしまう(consoleで表示してみると中身はある)ので処理遅延用の非同期処理を入れる
  // https://atsu-developer.net/262/
  // 普通に60秒遅延とかでもいいのかもしれないが、ファイルサイズが大きい場合とかにそれでいいかわからない。(未検証)
  // 要件が確定しない状態でとにかく急ぎで作らされているので、一旦はこれで逃げる
  // 以下処理遅延用
  const wait1 = await getDownloadURL(pdfUploadTask.snapshot.ref);
  const wait2 = await getDownloadURL(pdfUploadTask.snapshot.ref);
  const wait3 = await getDownloadURL(pdfUploadTask.snapshot.ref);
  const wait4 = await getDownloadURL(pdfUploadTask.snapshot.ref);
  const wait5 = await getDownloadURL(pdfUploadTask.snapshot.ref);

  return new Promise((resolve, reject) => {
    return resolve({
      name: file.name,
      url: pdfUrl,
      fullPath: pdfPath,
      // pngBlobs: pngBlobs,
      // pngDataUrls: convertFiles,
      pngUrls: pngUrls,
    })
  });
};

export default uploadPdfWithConvertImg;
