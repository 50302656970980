import React from "react";
import { Box, Typography } from "@mui/material";
import { Button, Link } from "@mui/material";
import acceptHero from "../../assets/imgs/accept_hero.jpg";
import applicationFlow from "../../assets/imgs/application_flow_sg.png";
import "../../assets/css/Global.css";
import { SubpageHeader } from "../../components/homepage/SubpageHeader";

const generalH3 = {
  fontSize: "1.5rem",
  paddingLeft: "1rem",
  borderLeft: "solid 5px #00695f",
};

const AcceptGuide = () => {
  return (
    <Box>
      <Box>
        <SubpageHeader
          breadcrumb={[{ name: "入会のご案内", link: "/accept_guide" }]}
        />
      </Box>
      <Box sx={{ margin: "0 auto", padding: "20px", maxWidth: "1024px" }}>
        <Typography component="h2" variant="h3" sx={generalH3}>
          入会のご案内
        </Typography>
        <Box
          className="bodyText"
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: { xs: "column", md: "row" },
            padding: "50px 0px  ",
          }}
        >
          <Box>
            入会の受付から申請までシステム上のフォームにて入会申請をいただいております。
            <br />
            下図に入会までのフローを掲載しておりますので、ご一読よろしくお願いいたします。
          </Box>
          <Button
            variant="contained"
            color="primary"
            href="/accept"
            style={{ fontSize: "18px", fontWeight: "700" }}
          >
            入会受付フォームに進む
          </Button>
        </Box>

        <img src={applicationFlow} width="100%"></img>

        <Box sx={{ mt: 5 }}>
          <Typography component="h2" variant="h3" sx={generalH3}>
            入会のメリット
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <Typography variant="body1" component="p">
              {/* <div className="melitTitle">① 共創ビジネスの具体化支援</div>
              <ul>
                <li>業界の垣根を超えたビジネスパートナー発掘イベントに参加できます。</li>
                <li>様々な事業者を交えて、サービスの実用化に向けた検討を行うことができます。</li>
                <li>国／自治体等との政策連携機会に参加できます。</li>
              </ul>
              <div className="melitTitle">②事業発展に役立つ情報の取得</div> */}
              <div className="melitTitle">事業発展に役立つ情報の取得</div>
              <ul>
                <li>導入側のニーズ、提供側のシーズに関する情報を入手できます。</li>
                <li>国内外のユースケースや市場動向等の情報収集・分析ができます。</li>
              </ul>
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography component="h2" variant="h3" sx={generalH3}>
            入会資格
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <Typography variant="body1" component="p">
              <ul>
                <li>本コンソーシアムの目的及び事業に賛同する企業・団体。</li>
                <li>
                  <a href="/constitution">NCCC会員規約</a>
                  に同意いただける企業・団体。
                </li>
              </ul>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AcceptGuide;
