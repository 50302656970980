import React from "react";
import programApi from "../../../api/programApi";
import branchApi from "../../../api/branchApi";
import { DataGrid, GridRowsProp, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import "../../../assets/css/Global.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";

export const ProgramList = () => {
  const storedUserInfo = useSelector((state) => state.user);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("1");
  const [offerTabValue, setOfferTabValue] = useState("1");
  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isBranchMaster, setIsBranchMaster] = useState(false);
  const [isMasking, setIsMasking] = useState(false);
  const [checkedPrograms, setCheckedPrograms] = useState([]);
  const dispatch = useDispatch();

  const programDetailPath = (applyflg) => {
    if (applyflg === 3 || applyflg === 4 || applyflg === 5 || applyflg === 8) {
      return "/member/programs/offer/";
    } else {
      return "/member/programs/";
    }
  };

  const ChangeOfferTab = (event, newValue) => {
    setOfferTabValue(newValue);
  };
  const ChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const maskingStr = "*****";

  useEffect(() => {
    async function fetchBranchList() {
      const branchs = await branchApi.getTargetList({
        params: { companyId: storedUserInfo.value.companyId },
      });
      setButtonDisabled(true);
      for (let i = 0; i < branchs.branchs.length; i++) {
        if (branchs.branchs[i].applyflg === 1) {
          setButtonDisabled(false);
          // プロジェクト申請可否判定（ログインユーザーが支部長かどうか）
          if (branchs.branchs[i].branchMasterId === storedUserInfo.value._id) {
            setIsBranchMaster(true);
          }
        }
      }
    }
    fetchBranchList();
  }, []);

  // 承認済みのプログラムリスト
  const approvedList = list.filter((program) => program.applyflg === 1 || program.applyflg === 4);
  // 審査済みのプログラムリスト
  const examinationList = list.filter(
    (program) => program.applyflg === 1 || program.applyflg === 4 || program.applyflg === 5
  );
  // 提案待ちのプログラムリスト
  const notProposedList = list.filter((program) => {
    if (!checkedPrograms.includes(program._id)) {
      program.isNotice = true;
    }
    return program.applyflg === 3;
  });

  // 提案差し戻し中のプログラムリスト
  const offerRemandList = list.filter(
    (program) => program.applyflg === 8 && storedUserInfo.value._id === program.applicantId
  );
  // 提案差し戻し中のプログラムリスト
  const remandListForApprover = list.filter((program) => program.applyflg === 8);

  useEffect(() => {
    async function fetchprogramList() {
      const res = await programApi.getList();
      let programs = [];
      for (let i = 0; i < res.programs.length; i++) {
        let program = {};
        program._id = res.programs[i]._id;
        program.programName = res.programs[i].programName;
        let programId = [];
        programId.push(res.programs[i]._id);
        programId.push(res.programs[i].programName);
        program.programId = programId;
        program.programDescription = res.programs[i].programDescription;
        program.applicationDocuments = res.programs[i].applicationDocuments;
        program.applyflg = res.programs[i].applyflg;
        program.applicantId = res.programs[i].applicantId;
        programs.push(program);
      }
      setList(programs);
    }
    fetchprogramList();
    getCheckedProgramList();
    // 権限の設定
    setRole(authUser.roleType);
    // 権限によるマスキング有無の設定
    if (
      authUser.roleType === RoleType.GeneralMember ||
      authUser.roleType === RoleType.ObserverMember
    ) {
      setIsMasking(true);
    }
  }, []);

  const getCheckedProgramList = async () => {
    const checkedProgramMap = await programApi.getProgramCheckLog({
      params: { operateUserId: storedUserInfo.value._id },
    });
    let checkedProgramIds = [];
    checkedProgramMap.programCheckLogList.map((checkedProgram) => {
      checkedProgramIds.push(checkedProgram.checkedProgramId);
    });
    setCheckedPrograms(checkedProgramIds);
  };

  const handleToDetail = async (checkedProgramId) => {
    // 未確認の場合のみ確認済みログを作成する
    if (role !== RoleType.ThirdPartyCommittee && !checkedPrograms.includes(checkedProgramId)) {
      await programApi.createProgramCheckLog({
        operateUserId: storedUserInfo.value._id,
        checkedProgramId: checkedProgramId,
      });
      dispatch(setUpdateBadgeFlg(true));
    }
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const id = data.get("radioId");
    const url = "/member/projects/create?programId=" + id;
    navigate(url);
  };
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const pageSizeOptions = [10, 25, 100];

  const getHeader = (tabValue) => {
    let valueText = "";
    let headerName = "";

    if (tabValue === "1" || tabValue === "2" || tabValue === "3") {
      valueText = "プログラム登録書類";
      headerName = "プログラム登録書類";
    } else {
      valueText = "プログラム提案書類";
      headerName = "プログラム提案書類";
    }
    return { valueText, headerName };
  };

  const getHeaderFullMember = (tabValue) => {
    let valueText = "";
    let headerName = "";

    if (
      role !== RoleType.BoardOfDirectors &&
      role !== RoleType.ThirdPartyCommittee &&
      tabValue === "2"
    ) {
      valueText = "プログラム提案書類";
      headerName = "プログラム提案書類";
    } else {
      valueText = "プログラム登録書類";
      headerName = "プログラム登録書類";
    }
    return { valueText, headerName };
  };

  const columns = [
    {
      field: "programId",
      headerName: "プログラム名",
      width: 300,
      renderCell: (params) => {
        if (
          role === RoleType.Admin ||
          role === RoleType.BoardOfDirectors ||
          role === RoleType.ThirdPartyCommittee
        ) {
          return (
            <Box sx={{ p: 2 }}>
              <Typography component="body2">{params.value[1]}</Typography>
            </Box>
          );
        } else {
          return (
            <Link to={programDetailPath(params.row.applyflg) + `${params.value[0]}`}>
              {params.value[1]}
            </Link>
          );
        }
      },
    },
    {
      field: "programDescription",
      headerName: "プログラムの概要",
      width: 350,
      renderCell: (params) => {
        if (!isMasking || tabValue === "2") {
          return (
            <Box sx={{ p: 2 }}>
              <Typography component="body2">{params.value}</Typography>
            </Box>
          );
        } else {
          return (
            <Box sx={{ p: 2 }}>
              <Typography component="body2">{maskingStr}</Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "applicationDocuments",
      headerName: getHeaderFullMember(tabValue).headerName,
      width: 200,
      renderCell: (params) => {
        if (!isMasking || tabValue === "2") {
          return (
            params.value && (
              <Typography
                component="a"
                href={params.value}
                target="_blank"
                color="primary"
                sx={{ display: "flex" }}
              >
                {getHeaderFullMember(tabValue).valueText}
              </Typography>
            )
          );
        } else {
          return (
            <Box sx={{ p: 2 }}>
              <Typography component="body2">{maskingStr}</Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "_id",
      headerName: "",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (
          role === RoleType.Admin ||
          role === RoleType.BoardOfDirectors ||
          role === RoleType.ThirdPartyCommittee
        ) {
          return (
            <Link to={"/member/programs/offer/" + params.value}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                詳細
              </Button>
            </Link>
          );
        } else if (params.row.applyflg === 8) {
          return (
            <Link to={"/member/programs/offer?programId=" + params.value}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                修正
              </Button>
            </Link>
          );
        } else if (isButtonDisabled && role === RoleType.FullMember) {
          return (
            <Link to={"/member/branches/create"}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                支部組成
              </Button>
            </Link>
          );
        } else if (isBranchMaster) {
          return (
            <Link to={"/member/projects/create?programId=" + params.value}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                プロジェクト設計書作成
              </Button>
            </Link>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const examinationColumns = [
    {
      field: "programId",
      headerName: "プログラム名",
      width: 300,
      renderCell: (params) => {
        return (
          <Box sx={{ p: 2 }}>
            <Typography component="body2">{params.value[1]}</Typography>
          </Box>
        );
      },
    },
    {
      field: "programDescription",
      headerName: "プログラムの概要",
      width: 350,
      renderCell: (params) => {
        return (
          <Box sx={{ p: 2 }}>
            <Typography component="body2">{params.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: "applicationDocuments",
      headerName: getHeader(tabValue).headerName,
      width: 200,
      renderCell: (params) => {
        return (
          params.value && (
            <Typography
              component="a"
              href={params.value}
              target="_blank"
              color="primary"
              sx={{ display: "flex" }}
            >
              {getHeader(tabValue).valueText}
            </Typography>
          )
        );
      },
    },
    {
      field: "applyflg",
      headerName: "承認ステータス",
      width: 150,
      renderCell: (params) => {
        const applyflg = params.value;
        let statusText = "";
        let statusColor = "";

        if (applyflg === 0 || applyflg === 3) {
          statusText = "未審査";
          statusColor = "orange";
        } else if (applyflg === 1 || applyflg === 4) {
          statusText = "承認";
          statusColor = "aqua";
        } else if (applyflg === 2 || applyflg === 5) {
          statusText = "否認";
          statusColor = "magenta";
        }
        return (
          <Box sx={{ p: 1 }}>
            <Typography component="body2" style={{ color: statusColor }}>
              {statusText}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "_id",
      headerName: "",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (params.row.applyflg === 0) {
          return (
            <Link to={"/member/programs/" + params.value}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                プログラム審査
              </Button>
            </Link>
          );
        } else {
          return (
            <Link to={programDetailPath(params.row.applyflg) + `${params.value}`}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                詳細
              </Button>
            </Link>
          );
        }
      },
    },
  ];

  const programOfferColumns = [
    {
      field: "isNotice",
      headerName: "",
      width: 5,
      renderCell: (params) => {
        return (
          <Box>
            {(params.value || role === RoleType.ThirdPartyCommittee) && (
              <Badge color="warning" variant="dot"></Badge>
            )}
          </Box>
        );
      },
    },
    {
      field: "programId",
      headerName: "プログラム名",
      width: 300,
      renderCell: (params) => {
        if (role === RoleType.ThirdPartyCommittee) {
          return (
            <Box sx={{ p: 2 }}>
              <Typography component="body2">{params.value[1]}</Typography>
            </Box>
          );
        } else {
          return (
            <Link
              to={programDetailPath(params.row.applyflg) + `${params.value[0]}`}
              onClick={() => handleToDetail(params.value[0])}
            >
              {params.value[1]}
            </Link>
          );
        }
      },
    },
    {
      field: "programDescription",
      headerName: "プログラムの概要",
      width: 350,
      renderCell: (params) => {
        return (
          <Box sx={{ p: 2 }}>
            <Typography component="body2">{params.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: "applicationDocuments",
      headerName: "プログラム提案書類",
      width: 200,
      renderCell: (params) => {
        return (
          <Typography
            component="a"
            href={params.value}
            target="blank"
            color="primary"
            sx={{ display: "flex" }}
          >
            プログラム提案書類
          </Typography>
        );
      },
    },
    {
      field: "_id",
      headerName: "",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (role === RoleType.ThirdPartyCommittee) {
          return (
            <Link to={"/member/programs/offer/" + params.value}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                審査
              </Button>
            </Link>
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (
    <Box component="form" onSubmit={handleSumbit} noValidate>
      {/* <Box component="form"> */}
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          プログラム一覧
        </Typography>
      </div>
      {/* 理事、第三者委員会用の表示 */}
      <Box sx={{ width: "100%", typography: "body1" }}>
        {(role === RoleType.Admin ||
          role === RoleType.BoardOfDirectors ||
          role === RoleType.ThirdPartyCommittee) && (
          <TabContext value={offerTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={ChangeOfferTab} aria-label="lab API tabs example">
                <Tab label="提案一覧" value="1" />
                <Tab label="差し戻し対象" value="2" />
                <Tab label="審査済み" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={notProposedList}
                    columns={programOfferColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                    key={isButtonDisabled}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={remandListForApprover}
                    columns={columns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                    key={isButtonDisabled}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="3">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={examinationList}
                    columns={examinationColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                    key={isButtonDisabled}
                  />
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        )}
        {/* 承認者以外の表示 */}
        {role !== RoleType.BoardOfDirectors && role !== RoleType.ThirdPartyCommittee && (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                <Tab label="プログラム一覧" value="1" />
                <Tab label="差し戻し対象" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={approvedList}
                    columns={columns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                    key={isButtonDisabled}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={offerRemandList}
                    columns={columns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                    key={isButtonDisabled}
                  />
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </Box>
  );
};
