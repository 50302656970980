import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Pagination } from "@mui/material";
import newspaperImg from "../../assets/imgs/absolutvision.jpg";
import News from "../../components/common/News";
import { API_URL, API_KEY } from "../../utils/microcms.js";
import { useTranslation } from "react-i18next";
import ScrollToTopOnMount from "../../components/common/utils/ScrollToTopOnMount";

export const InternationalStatus = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState({});
  const [pageNum, setPageNum] = useState(1); // pageの合計
  const contentName = "news"; // APIのコンテンツ名
  const categoryName = "nhydbujkz"; // カテゴリーId　-> 国際状況
  const fileter = "?filters=category[equals]" + categoryName;

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [page, setPage] = useState(Number(query.get("page")) || 1); // 現在のページ
  const navigate = useNavigate();
  const handleChange = (event, value) => {
    setPage(value);
    navigate("/status?page=" + value);
  };

  useEffect(() => {
    const offset = page ? (Number(page) - 1) * 10 : 0;
    fetch(API_URL + contentName + fileter + "&offset=" + offset, {
      headers: { "X-MICROCMS-API-KEY": API_KEY },
    })
      .then((res) => res.json())
      .then((data) => {
        setNews(data);
        setPageNum(Math.ceil(Number(data.totalCount) / 10));
      });
  }, [fileter, page]);

  return (
    <div>
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, .2),  rgba(0, 0, 0, .2)),url(${newspaperImg})`,
          height: "250px",
          display: "flex",
          alignItems: "center",
          padding: "0px 0px 0px 100px",
        }}
      >
        <Typography variant="h4" color="white.main">
          {t("InternationalStatus.title")}
        </Typography>
      </Box>
      <Box sx={{ padding: { xs: "5%", md: "70px" } }}>
        <Typography variant="h5" color="primary.main">
          {t("InternationalStatus.labels.label1")}
        </Typography>
        <div style={{ margin: "0 auto", maxWidth: "1200px" }}>
          {!!news.contents?.length && <News items={news.contents} contentName={contentName} />}
        </div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          {pageNum > 1 && <Pagination count={pageNum} page={page} onChange={handleChange} />}
        </Box>
      </Box>
      <ScrollToTopOnMount />
    </div>
  );
};
