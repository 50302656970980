import axiosClient from "./axiosClient";

const actWorkApi = {
  register: (params) => axiosClient.post("actWork/register", params),
  getList: (params) => axiosClient.post("actwork/list", params),
  getTargetList: (params) => axiosClient.get("actWork/getTargetList", params),
  getById: (id) => axiosClient.get(`actWork/getById?_id=${id}`),
  approval: (params) => axiosClient.post("actWork/approval", params),
  nonApproval: (params) => axiosClient.post("actWork/nonApproval", params),
  getListByUserId: (params) => axiosClient.get("actWork/listByUserId", params),
  updateProgress: (params) => axiosClient.post("actWork/updateProgress", params),
  getByProgress: (params) => axiosClient.get("actWork/getByProgress", params),
  getAssignListForManage: (params) => axiosClient.get("actWork/assignListForManage", params),
  remandUpdate: (params) => axiosClient.post("actWork/remandUpdate", params),
  createActWorkCheckLog: (params) => axiosClient.post("actWork/createActWorkCheckLog", params),
  getActWorkCheckLog: (params) => axiosClient.get("actWork/getActWorkCheckLog", params),
  getApprovalProjectJoinList: (params) => axiosClient.get("actWork/getApprovalProjectJoinList", params),
};

export default actWorkApi;
