import React from "react";
import programApi from "../../../api/programApi";
import mailApi from "../../../api/mailApi";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import { Box, TextField } from "@mui/material";
// import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { Dialog, DialogContent, Button } from "@mui/material";
import { ExaminationChat } from "../examinationCommon";

const itemHeaderStyle = {
  color: "#000000",
};
const itemValueStyle = {
  color: "#000000",
  marginLeft: "5px",
  fontWeight: "bold",
};

export const ProgramExamination = (props) => {
  const { id } = useParams();
  let [program, setProgram] = useState([]);
  let [company, setCompany] = useState([]);
  let [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const [title, setTitle] = useState("");
  const [commentErrText, setCommentErrText] = useState("");
  const userInfo = useSelector((state) => state.user);
  const [showDialog, setShowDialog] = useState(false);

  const navigateToAccept = () => {
    navigate("/accept_guide");
  };

  const handleSubmit = async (buttonValue) => {
    let examinationForm = document.getElementById("examinationForm");
    const data = new FormData(examinationForm);
    const approvalComment = data.get("approvalComment");
    setCommentErrText("");
    if (buttonValue === 9 && approvalComment === "") {
      setCommentErrText("差し戻しコメントを入力してください。");
      return;
    }

    let comment = "";
    if (buttonValue === 1) {
      comment = "承認";
    } else if (buttonValue === 2) {
      comment = "否認";
    } else {
      comment = "差し戻し";
    }

    if (window.confirm(comment + "します。よろしいですか？")) {
      const requestData = {
        id: id,
        examinationId: userInfo.value._id,
        approvalStatus: buttonValue,
        approvalComment: approvalComment,
      };
      try {
        //承認／否認　処理
        await programApi.examination(requestData);
        // 差し戻しの場合、差し戻し通知を送付
        if (buttonValue === 9) {
          const email = program.applicantId ? program.applicantId.email : "";
          const mailRequestData = {
            applicantName: program.applicantName,
            targetName: program.programName,
            targetGenre: "プログラム",
            remandReason: approvalComment,
            url: "/member/programs",
            // TODO 固定値を後で定数クラス等を作成して管理するか検討
            mailid: "18",
            mailaddress: email,
          };
          await mailApi.returnpost(mailRequestData);
        }
        const url = "/member/programs";
        navigate(url);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const downloadDocument = async (path) => {
    try {
      const fileName = "program_application_document";
      saveAs(path, fileName);
    } catch (err) {
      console.error(err);
    }
  };

  const historyColumns = [
    {
      field: "created",
      headerName: "審査日時",
      width: 200,
      renderCell: (params) => {
        return (
          params.value && (
            <div style={{ flexBasis: "200px" }}>{moment(params.value).format("YYYY/MM/DD")}</div>
          )
        );
      },
    },
    {
      field: "examinationId",
      headerName: "審査者",
      width: 200,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "200px" }}>{params.value.username}</div>;
      },
    },
    {
      field: "result",
      headerName: "審査結果",
      width: 200,
    },
    {
      field: "comment",
      headerName: "コメント",
      width: 400,
    },
  ];

  useEffect(() => {
    async function fetchProgram() {
      const res = await programApi.getProgramExaminationInfo({ params: { id: id } });
      setProgram(res.program);
      setCompany(res.company);
      setHistory(res.examinationHistories);
      // タイトルの設定
      let pageTitle = "";
      if (res.program.applyflg === 0) {
        pageTitle = "プログラム審査／承認";
      } else {
        pageTitle = "プログラム詳細";
      }
      setTitle(pageTitle);
    }
    fetchProgram();

    // 権限の設定
    setRole(authUser.roleType);
    if (
      authUser.roleType === RoleType.GeneralMember ||
      authUser.roleType === RoleType.ObserverMember
    ) {
      setShowDialog(true);
    }
  }, []);

  return (
    <>
      <Grid container justifyContent="left" alignItems="center">
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <hr width="100%"></hr>
      <Box component="form" id="examinationForm" noValidate>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
          }}
        >
          <Typography sx={itemHeaderStyle}>プログラム名</Typography>
          <Typography sx={itemValueStyle}>{program.programName}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
          }}
        >
          <Typography sx={itemHeaderStyle}>申請者名</Typography>
          {role !== RoleType.GeneralMember && role !== RoleType.ObserverMember ? (
            <Typography sx={itemValueStyle}>{program.applicantName}</Typography>
          ) : (
            <div style={{ filter: "blur(8px)" }}>
              <Typography sx={itemValueStyle}>***********</Typography>
            </div>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
          }}
        >
          <Typography sx={itemHeaderStyle}>申請者所属会社／団体</Typography>
          {company && role !== RoleType.GeneralMember && role !== RoleType.ObserverMember ? (
            <Typography sx={itemValueStyle}>{company.groupName}</Typography>
          ) : (
            <div style={{ filter: "blur(8px)" }}>
              <Typography sx={itemValueStyle}>***********</Typography>
            </div>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
          }}
        >
          <Typography sx={itemHeaderStyle}>プログラムの概要</Typography>
          {role !== RoleType.GeneralMember && role !== RoleType.ObserverMember ? (
            <Typography sx={itemValueStyle}>{program.programDescription}</Typography>
          ) : (
            <div style={{ filter: "blur(8px)" }}>
              <Typography sx={itemValueStyle}>***********</Typography>
            </div>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            padding: "20px",
          }}
        >
          {[0, 1, 2, 9].includes(program.applyflg) && (
            <>
              <Typography sx={itemHeaderStyle}>プログラム登録書類</Typography>
              {role !== RoleType.GeneralMember && role !== RoleType.ObserverMember && (
                <IconButton
                  type="button"
                  onClick={() => {
                    downloadDocument(program.applicationDocuments);
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </>
          )}
          {[3, 4, 5, 8].includes(program.applyflg) && (
            <>
              <Typography sx={itemHeaderStyle}>プログラム提案書類</Typography>
              {role !== RoleType.GeneralMember && role !== RoleType.ObserverMember && (
                <IconButton
                  type="button"
                  onClick={() => {
                    downloadDocument(program.applicationDocuments);
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </>
          )}
        </Box>
        {program.applyflg === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <TextField
              fullWidth
              id="approvalComment"
              label="コメント"
              margin="normal"
              name="approvalComment"
              multiline
              minRows={3}
              maxRows={5}
              error={commentErrText !== ""}
              helperText={commentErrText}
            />
          </Box>
        )}
        {role !== RoleType.GeneralMember &&
          role !== RoleType.ObserverMember &&
          (program.applyflg === 1 || program.applyflg === 2) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "20px",
              }}
            >
              <Typography sx={itemHeaderStyle}>コメント</Typography>
              <Typography sx={itemValueStyle}>{program.approvalComment}</Typography>
            </Box>
          )}
        {/*
        {role === RoleType.BoardOfDirectors && history && history.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography sx={itemHeaderStyle}>審査履歴</Typography>
            <div style={{ height: "max-content", width: "100%" }}>
              <DataGrid
                rows={history}
                columns={historyColumns}
                getRowId={(history) => history._id}
                autoHeight
              />
            </div>
          </Box>
        )}
        **/}
        {/*
        {role === RoleType.BoardOfDirectors && program.applyflg === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "20px",
            }}
          >
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#FF69B4",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                handleSubmit(2);
              }}
            >
              否認
            </LoadingButton>
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#008000",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                handleSubmit(9);
              }}
            >
              差し戻し
            </LoadingButton>
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#1976D2",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                handleSubmit(1);
              }}
            >
              承認
            </LoadingButton>
          </Box>
        )}
        **/}
      </Box>
      <Box>
        <ExaminationChat id={id} isDisabled={true} />
      </Box>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <p>正会員のみが閲覧可能です</p>
          {/*TODO：正会員への権限UP審査について確認を取り実装等を行う
          <LoadingButton
            sx={{
              width: "150px",
              color: "white",
              background: "#008000",
            }}
            fullWidth
            color="primary"
            variant="contained"
            size="large"
            onClick={() => navigateToAccept()}
          >
            正会員になる
          </LoadingButton>
          **/}
        </DialogContent>
      </Dialog>
    </>
  );
};
