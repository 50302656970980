import axiosClient from "./axiosClient";

const workApi = {
  register: (params) => axiosClient.post("work/register", params),
  update: (params) => axiosClient.post("work/update", params),
  getList: () => axiosClient.get("work/list"),
  getTargetList: (params) => axiosClient.get("work/getTargetList", params),
  getById: (params) => axiosClient.get("work/getById", params),
  updatePublicScope: (params) => axiosClient.put("work/updatePublicScope", params),
};

export default workApi;
