import React from "react";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/features/userSlice";

const AuthUserContext = React.createContext({});

export const useAuthUserContext = () => {
  return React.useContext(AuthUserContext);
};

export const AuthUserProvider = (props) => {
  const cookies = parseCookies();
  const [roleType, setRoleType] = React.useState(cookies.roleType || null);
  const [isSignin, setIsSignin] = React.useState(false);
  // const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const signin = (newUser, callback) => {
    dispatch(setUser(newUser));
    setRoleType(newUser.membership);
    setIsSignin(true);
    // setCookie(null, "roleType", newUser.role, { maxAge: 30 * 24 * 60 * 60 });
    setCookie(null, "roleType", newUser.membership, { maxAge: 30 * 24 * 60 * 60 });
    console.log("signin by " + newUser.membership);
    callback();
  };

  const signout = (callback) => {
    dispatch(setUser(null));
    setRoleType(null);
    setIsSignin(false);
    // destroyCookie(null, "roleType");
    callback();
  };

  // React.useEffect(() => {
  //   // TODO: 認証処理を追加
  //   if (cookies.roleType) {
  //     setLoading(false);
  //     setRoleType(cookies.roleType || null);
  //     setIsSignin(true);
  //     setLoading(false);
  //   }
  // }, [cookies.roleType]);

  const value = { roleType, isSignin, signin, signout };
  return <AuthUserContext.Provider value={value}>{props.children}</AuthUserContext.Provider>;
};

export const RoleType = {
  SystemAdmin: "systemAdmin",
  Admin: "admin",
  BoardOfDirectors: "boardOfDirectors",
  ThirdPartyCommittee: "thirdPartyCommittee",
  FullMember: "fullMember",
  GeneralMember: "generalMember",
  ObserverMember: "observerMember",
};
