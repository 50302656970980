import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import userApi from "../../../api/userApi";
import proposalApi from "../../../api/proposalApi";
import examinationApi from "../../../api/examinationApi";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import HelpIcon from "@mui/icons-material/Help";

export const ProposalList = () => {
  const [proposalList, setProposalList] = React.useState([]);
  const [exList, setExList] = React.useState([]);
  const [proposalDetails, setProposalDetails] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const proposalColumns = [
    {
      field: "_id",
      headerName: "詳細へ",
      width: 200,
      renderCell: (params) => {
        // 下書きの場合は編集画面へ
        if (params.row.status === 40) {
          return (
            <div>
              <Link to={`/member/proposal/create?proposalId=${params.value}`}>
                <span>編集</span>
              </Link>
            </div>
          );
        } else {
          return (
            <div>
              <Link to={`/member/proposal/detail/${params.value}`}>
                {!checkIsApproval(params.value) && (
                  <Badge color="warning" variant="dot" sx={{ mr: 1 }} />
                )}
                <span>詳細</span>
              </Link>
            </div>
          );
        }
      },
    },
    { field: "subject", headerName: "件名", width: 200 },
    {
      field: "status",
      headerName: "申請状況",
      width: 200,
      renderCell: (params) => (
        <div>{params.value === 10 ? "申請中" : params.value === 40 ? "下書き" : params.value === 20 ? "承認済" : "却下"}</div>
      ),
    },
    {
      field: "proposalDate",
      headerName: "申請日",
      width: 200,
      renderCell: (params) => <div>{dayjs(new Date(params.value)).format("YYYY/MM/DD HH:mm")}</div>,
    },
    {
      field: "to",
      headerName: "宛先",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value === 10 ? "理事長" : params.value === 11 ? "理事会" : params.value === 12 ? "事務局" : ""}
        </div>
      ),
    },
    {
      field: "username",
      headerName: "申請者",
      width: 200,
      renderCell: (params) => <div>{params.value}</div>,
    },
  ];
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const pageSizeOptions = [10, 25, 100];
  const user = useSelector((state) => state.user.value);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchData = async () => {
      const exRes = await examinationApi.getListByCode({ examinationCode: 1 });
      const fetchData = await proposalApi.aggregateProposalWithChat({
        membership: user.membership,
        userId: user._id,
      });
      setProposalDetails(fetchData.proposals);
      setExList(exRes.examinations);
      changeList();
    };
    fetchData();
  }, []);

  const changeList = async () => {
    let res;

    const secretariat = await userApi.applyer({ to: 12 });
    const secretariatIds = secretariat.map((user) => user._id);
    const isSecretriat = secretariatIds.includes(user._id);

    // to 事務局
    if (user.membership === "admin") {
      res = await proposalApi.getByMembership({ membership: 12 });
    }
    // to 理事長
    else if (user.membership === "boardOfDirectors" && user.directorCode === "boardChairman") {
      const proposalList1 = await proposalApi.getByMembership({ membership: 10 });
      const proposalList2 = await proposalApi.getByMembership({ membership: 11 });
      res = {
        proposals: [...proposalList1.proposals, ...proposalList2.proposals],
      };
    }
    // to 理事1,2(理事 兼 事務局)
    else if (isSecretriat) {
      const proposalList1 = await proposalApi.getByMembership({ membership: 11 });
      const proposalList2 = await proposalApi.getByMembership({ membership: 12 });
      res = {
        proposals: [...proposalList1.proposals, ...proposalList2.proposals],
      };
    }
    // to 理事(理事長以外)
    else if (user.membership === "boardOfDirectors") {
      res = await proposalApi.getByMembership({ membership: 11 });
    }

    let resultList = [];
    for (let i = 0; i < res.proposals.length; i++) {
      if (res.proposals[i].status !== 20 && res.proposals[i].status !== 40) {
        res.proposals[i].username = await fetchName(res.proposals[i].from);
        resultList.push(res.proposals[i]);
      }
    }
    resultList.sort((a, b) => {
      return new Date(b.proposalDate) - new Date(a.proposalDate);
    });
    setProposalList(resultList);
  };
  const changeApplying = async () => {
    const res = await proposalApi.getByFromId({ userId: user._id });
    let resultList = [];
    for (let i = 0; i < res.proposals.length; i++) {
      if (res.proposals[i].status === 10 || res.proposals[i].status === 40) {
        res.proposals[i].username = await fetchName(res.proposals[i].from);
        resultList.push(res.proposals[i]);
      }
    }
    setProposalList(resultList);
  };
  const changeApproval = async () => {
    const res = await proposalApi.getByStatus({ status: 20 });
    let resultList = [];
    for (let i = 0; i < res.proposals.length; i++) {
      if (res.proposals[i].status === 20) {
        res.proposals[i].username = await fetchName(res.proposals[i].from);
        resultList.push(res.proposals[i]);
      }
    }
    setProposalList(resultList);
  };

  const checkIsApproval = (id) => {
    let result = exList.some((item) => item.companyId === id && item.approvalBy === user._id);
    proposalDetails.forEach((item) => {
      if (item._doc._id === id && item.notReadChatNum > 0) {
        result = false;
      }
    });
    return result;
  };
  const fetchName = async (id) => {
    const res = await userApi.getByOne({
      params: {
        _id: id,
      },
    });
    return res.user.username || res.user.name;
  };
  return (
    <>
      <div>申請一覧</div>
      <Box sx={{ m: 2, display: "flex", gap: 2 }}>
        <Button onClick={changeList} variant="outlined">
          要承認一覧
        </Button>
        <Button onClick={changeApplying} variant="outlined">
          審査中マイ申請一覧
        </Button>
        <Button onClick={changeApproval} variant="outlined">
          承認済一覧
        </Button>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            aria-label="delete"
            size="small"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <HelpIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 3 }}>
          <Box>
            <Typography variant="subtitle1">申請一覧</Typography>
            <Typography sx={{ p: 1 }}>承認対応が必要な申請一覧が表示されます。</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">審査中マイ申請一覧</Typography>
            <Typography sx={{ p: 1 }}>
              自分が申請した審査中の稟議一覧を表示します。
              <br />
              ※承認後は承認済一覧に移動します。
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">承認済一覧</Typography>
            <Typography sx={{ p: 1 }}>
              承認された過去の申請一覧を閲覧することができます。
            </Typography>
          </Box>
        </Box>
      </Popover>
      <Box sx={{ display: "flex", padding: "5px" }}>
        <div style={{ height: "max-content", width: "100%" }}>
          <DataGrid
            rows={proposalList}
            columns={proposalColumns}
            getRowId={(list) => list._id}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={pageSizeOptions}
            autoHeight
          />
        </div>
      </Box>
    </>
  );
};
