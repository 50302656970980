import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { task_setPriority } from "../../../redux/features/taskSlice";

const PriorityDropdown = (field, fieldState) => {
  const dispatch = useDispatch();

  const [priorityList, setPriorityList] = useState([]);
  const [priority, setPriority] = useState("");

  // 初期表示
  useEffect(() => {
    fetchPriorityList();
  }, []);

  useEffect(() => {
    setPriority(field.field.value);
  }, [field.field.value]);

  const getPriorityIcon = (iconName) => {
    switch (iconName) {
      case "KeyboardDoubleArrowUp":
        return <KeyboardDoubleArrowUpIcon sx={{ color: "#8b0000", paddingRight: "3px" }} />;
      case "KeyboardArrowUp":
        return <KeyboardArrowUpIcon sx={{ color: "#ff0000", paddingRight: "5px" }} />;
      case "HorizontalRule":
        return <HorizontalRuleIcon sx={{ color: "#008000", paddingRight: "6px" }} />;
      case "KeyboardArrowDown":
        return <KeyboardArrowDownIcon sx={{ color: "#4169e1", paddingRight: "5px" }} />;
      case "KeyboardDoubleArrowDown":
        return <KeyboardDoubleArrowDownIcon sx={{ color: "#00008b", paddingRight: "3px" }} />;
      default:
        return <HorizontalRuleIcon sx={{ color: "#008000", paddingRight: "6px" }} />;
    }
  };

  const fetchPriorityList = async () => {
    setPriorityList([
      { key: "priority1", icon: "KeyboardDoubleArrowUp", text: "最高", value: 2 },
      { key: "priority2", icon: "KeyboardArrowUp", text: "高", value: 1 },
      { key: "priority3", icon: "HorizontalRule", text: "中", value: 0 },
      { key: "priority4", icon: "KeyboardArrowDown", text: "低", value: -1 },
      { key: "priority5", icon: "KeyboardDoubleArrowDown", text: "最低", value: -2 },
    ]);
  };

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
    dispatch(task_setPriority(event.target.value));
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <FormControl fullWidth error={fieldState.invalid}>
        <InputLabel>優先度</InputLabel>
        <Select
          {...field}
          input={<OutlinedInput label="優先度" />}
          value={priority}
          MenuProps={MenuProps}
          onChange={handlePriorityChange}
        >
          {priorityList &&
            priorityList.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                <div style={menuItemStyle}>
                  {getPriorityIcon(item.icon)}
                  {item.text}
                </div>
              </MenuItem>
            ))}
        </Select>
        {fieldState.invalid && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default PriorityDropdown;
