import React, { useEffect, useState } from "react";
import projectApi from "../../../api/projectApi";
import eventApi from "../../../api/eventApi";
import attendanceApi from "../../../api/attendanceApi";
import workApi from "../../../api/workApi";
import actWorkApi from "../../../api/actWorkApi";

import { useParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

const ManageDetailLink = (params) => {
  if (params.data.row.actWorkId) {
    return (
      // pathは正式に決定してから変更
      <Link to={`/member/projects/progress/detail/${params.data.row.actWorkId}`}>
        {/* TODO: リンクのCSSを変更したい(優先度: 低) */}
        {params.data.value}
      </Link>
    );
  } else {
    return (
      <>
        {params.data.value}
      </>
    );
  }
};

export const ProjectManage = () => {
  const { slug } = useParams();
  // 呼び出すAPIを変更したらここも修正する
  const [project, setProject] = React.useState({});
  const [projectWorks, setProjectWorks] = React.useState({});
  const [attendanceList, setAttendanceList] = React.useState({});
  const [memberRows, setMemberRows] = React.useState({});
  const [publicScopeErrText, setPublicScopeErrText] = React.useState("");
  let [reDisplay, setReDisplay] = React.useState(0);

  let publicScopes = [];

  const ScopeDropdown = (params) => {
    const [scope, setScope] = useState(params.data.row.public_scope);
    // セレクトボックスの値が変更されたときに値を更新する関数
    const handleScopeChange = (event) => {
      setScope(event.target.value);
      // 既にIDが存在する場合は更新、無ければ追加
      if (publicScopes.find((ps) => ps._id === params.data.row._id)) {
        publicScopes.find((ps) => ps._id === params.data.row._id).publicScope = event.target.value;
      } else {
        let publicScopeInfo = {};
        publicScopeInfo._id = params.data.row._id;
        publicScopeInfo.publicScope = event.target.value;
        publicScopes.push(publicScopeInfo);
      }
    };

    return (
      <>
        <Typography variant="contained" gutterBottom>
          <select value={scope} onChange={handleScopeChange}>
            {/* 選択肢はマスタ設定で変更できるのが望ましい。まずは項目精査のうえでハードコーディングでOK */}
            <option value="0">プロジェクト参加者</option>
            <option value="1">支部長のみ</option>
            <option value="2">正会員</option>
            <option value="3">オブザーバー会員限定</option>
          </select>
        </Typography>
      </>
    );
  };

  const workitemColumns = [
    {
      field: "work_item",
      headerName: "項目",
      width: 150,
      renderCell: (params) => {
        return <ManageDetailLink data={params} />;
      },
    },
    {
      field: "task_status",
      headerName: "タスク状況",
      width: 150,
      renderCell: (params) => {
        let taskStatus = "";
        if (params.value === 100) {
          taskStatus = "完了";
        } else if (params.value > 0 && params.value < 100) {
          taskStatus = "進行中（" + params.value + "%）";
        } else {
          taskStatus = "未着手";
        }
        return <Typography>{taskStatus}</Typography>;
      },
    },
    {
      field: "documentUrl",
      headerName: "ドキュメント",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography
            component="a"
            href={params.value}
            target="blank"
            color="primary"
            sx={{ display: "flex" }}
          >
            {params.row.documentName}
          </Typography>
        );
      },
    },
    { field: "assign", headerName: "アサイン", width: 400 },
    {
      field: "public_scope",
      headerName: "当項目の公開範囲",
      width: 200,
      renderCell: (params) => {
        return <ScopeDropdown data={params} />;
      },
    },
  ];

  const memberColumns = [
    { field: "assign", headerName: "アサイン", width: 400 },
    { field: "contribution", headerName: "貢献度", width: 140 },
    { field: "total_amount", headerName: "総実施金額", width: 140 },
    { field: "forecast_reduce_co2", headerName: "予想CO2削減量", width: 180 },
    { field: "related_task", headerName: "紐づく作業", width: 250 },
  ];

  async function fetchProjectWorks() {
    const response = await projectApi.getWorkListForManage({ params: { _id: slug } });
    setProject(response.project);
    setProjectWorks(response.projectWorks);
  }

  async function fetchProjectAssign() {
    const response = await actWorkApi.getAssignListForManage({ params: { _id: slug } });
    const actWorks = response.assignList;
    const projectInfo = response.projectInfo;
    let assignList = [];
    for (let i = 0; i < actWorks.length; i++) {
      let assign = {};
      assign._id = actWorks[i]._id;
      assign.assign = actWorks[i].assign;
      // 貢献度の設定
      assign.contribution =
        Math.round((actWorks[i].estimateTotal / projectInfo.projectEstimateTotal) * 100) + "%";
      assign.total_amount = actWorks[i].estimateTotal.toLocaleString() + "円";
      assign.forecast_reduce_co2 = actWorks[i].forecastReduceCo2Total + "kg";
      // 紐づく作業を設定
      let relatedTask = "";
      for (let j = 0; j < actWorks[i].companyWorks.length; j++) {
        if (relatedTask !== "") {
          relatedTask = relatedTask + ", " + actWorks[i].companyWorks[j].workName;
        } else {
          relatedTask = relatedTask + actWorks[i].companyWorks[j].workName;
        }
      }
      assign.related_task = relatedTask;
      assignList.push(assign);
    }
    setMemberRows(assignList);
  }

  useEffect(() => {
    fetchProjectWorks();
    fetchProjectAssign();
  }, [reDisplay]);

  const onSubmit = async () => {
    setPublicScopeErrText("");
    try {
      // console.log(publicScopes);
      await workApi.updatePublicScope({
        projectId: project._id,
        publicScopes: publicScopes,
      });
      alert("公開範囲を更新しました");
      setReDisplay(reDisplay + 1);
    } catch (error) {
      console.error(error);
      const errors = error.data.errors;
      errors.forEach((e) => {
        setPublicScopeErrText(e.msg);
      });
    }
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const pageSizeOptions = [5, 25, 100];

  return (
    <>
      <Typography variant="h5">プロジェクト管理</Typography>
      <Box>
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #ebebeb",
            margin: "20px",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h6">プロジェクト基本情報</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography variant="subtitle1">プロジェクト名</Typography>
            <Typography variant="body1">{project.projectName}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography variant="subtitle1">利用プログラム</Typography>
            <Typography variant="body1">
              {project.programId && project.programId.programName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Box>
              <Typography variant="subtitle1">プロジェクト概要</Typography>
              <Typography variant="body1">{project.projectDesc}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Box>
              <Typography variant="subtitle1">支部</Typography>
              <Typography variant="body1">
                {project.branchId && project.branchId.branchName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="subtitle1">作業項目</Typography>
          <div style={{ flexGrow: 1 }}></div>
          <Button variant="contained" onClick={onSubmit}>
            更新
          </Button>
        </Grid>
        {publicScopeErrText && (
          <Typography style={{ color: "#f44336" }}>{publicScopeErrText}</Typography>
        )}
        <div style={{ height: "max-content", width: "100%" }}>
          <DataGrid
            rows={projectWorks}
            columns={workitemColumns}
            getRowId={(row) => row._id}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={pageSizeOptions}
            autoHeight
          />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="subtitle1">会員</Typography>
          <div style={{ flexGrow: 1 }}></div>
        </Grid>
        <div style={{ height: "max-content", width: "100%" }}>
          <DataGrid
            rows={memberRows}
            columns={memberColumns}
            getRowId={(row) => row._id}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={pageSizeOptions}
            autoHeight
          />
        </div>
      </Box>
    </>
  );
};
