import axiosClient from "./axiosClient";

const branchUserApi = {
  register: (params) => axiosClient.post("branchUser/register", params),
  modify: (params) => axiosClient.post("branchUser/update", params),
  getList: (params) => axiosClient.get("branchUser/list", params),
  getApprovedList: () => axiosClient.get("branchUser/approvedList"),
  getTargetList: (params) => axiosClient.get("branchUser/getTargetList", params),
  getTargetOne: (params) => axiosClient.get("branchUser/getTargetOne", params),
  getById: (params) => axiosClient.get("branchUser/getById", params),
  examination: (params) => axiosClient.post("branchUser/examination", params),
  getListWithJoinUser: (params) => axiosClient.get("branchUser/getListWithJoinUser", params),
  createBranchUserCheckLog: (params) => axiosClient.post("branchUser/createBranchUserCheckLog", params),
  getBranchUserCheckLog: (params) => axiosClient.get("branchUser/getBranchUserCheckLog", params),
};

export default branchUserApi;
