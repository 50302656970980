import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  // Badge,
  Chip,
  Divider,
  Grid,
  Tab,
  Typography,
  TextField,
} from "@mui/material";
import {
  TabContext,
  TabList,
  TabPanel,
} from "@mui/lab/"
import { styled } from "@mui/material/styles";
// import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

import dayjs from "dayjs";

import "../../../../assets/css/Global.css";
import projectApi from "../../../../api/projectApi";
import { useAuthUserContext, RoleType } from "../../../../providers/auth";
import { setUpdateBadgeFlg } from "../../../../redux/features/sidebarSlice";
import PriorityDropdown from "../../../../components/member/task/priorityDropdown";
import StatusDropdown from "../../../../components/member/task/statusDropdown";
import TermInput from "../../../../components/member/task/termInput";
import { task_setInitialState } from "../../../../redux/features/taskSlice";
import { project_setIsChargeApplicationModalOpen } from "../../../../redux/features/projectSlice";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TablePagination from '@mui/material/TablePagination';

export const ProjectTaskList = ({ props }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [taskList, setTaskList] = useState([]);
  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const location = useLocation();
  const [filter, setFilter] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const taskState = useSelector((state) => state.task);

  const { control, formState, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      category: "",
      priority: "",
      sharedUser: [],
      title: "",
      body: "",
      taskLimit: "",
      // milestoneLimit: "",
      // assigned: "",
    },
  });

  const changeTab = (event, newValue) => {
    setActiveTab(newValue);
    switch (newValue) {
      case "1":
        getTaskList();
        break;
      case "2":
        getAssignedTaskList();
        break;
      case "3":
        getRegisterTaskList();
        break;
      default:
        getTaskList();
    }
  };

  const getPriorityIcon = (priority) => {
    switch (priority) {
      case 2:
        return <KeyboardDoubleArrowUpIcon sx={{ color: "#8b0000", paddingRight: "3px" }} />;
      case 1:
        return <KeyboardArrowUpIcon sx={{ color: "#ff0000", paddingRight: "5px" }} />;
      case 0:
        return <HorizontalRuleIcon sx={{ color: "#008000", paddingRight: "6px" }} />;
      case -1:
        return <KeyboardArrowDownIcon sx={{ color: "#4169e1", paddingRight: "5px" }} />;
      case -2:
        return <KeyboardDoubleArrowDownIcon sx={{ color: "#00008b", paddingRight: "3px" }} />;
      default:
        return <HorizontalRuleIcon sx={{ color: "#008000", paddingRight: "6px" }} />;
    }
  };

  const getCategoryName = (category) => {
    switch (category) {
      case 1:
        // return <Chip label="トピック" size="small" color="taskCategory.topic" />
        return <Chip label="削減活動" size="small" color="success" sx={{ paddingLeft: "2px", paddingRight: "2px" }} />
      case 2:
        return <Chip label="その他" size="small" color="warning" sx={{ paddingLeft: "2px", paddingRight: "2px"}} />
      default:
        return <Chip label="" size="small" />
    }
  }

  const getLimitColor = (day, status) => {
    const today = dayjs().format("YYYY/MM/DD");
    const limitDay = dayjs(day).format("YYYY/MM/DD");
    // 日付の比較をして今日日付を超えていたら赤文字
    if (limitDay < today && [1, 2].includes(status)) {
      return {color: "red"};
    } else {
      return {};
    }
  }

  const getTaskList = async () => {
    const searchBoardOfDirectors = user.membership === "boardOfDirectors" ? {sharedUser: "boardOfDirectors"} : {};
    const data = await projectApi.getProjectTask({
      params: {
        searchCondition: {
          projectNo: props.projectNo,
          $or: [
            searchBoardOfDirectors,
            { sharedUser: user._id },
            { createdUser: user._id }
          ], 
        }
      },
    });
    let taskList = JSON.parse(JSON.stringify(data.items));
    let parentTaskList = taskList.filter((task) => !task.parentTaskId);
    let tasks = [];
    for (let i = 0; i < parentTaskList.length; i++) {
      const parentTask = parentTaskList[i];
      // 子タスクが存在する場合
      if (parentTask.childTaskId && parentTask.childTaskId.length) {
        let newTaskList = [];
        parentTask.childTask = getChildTask(data.items, parentTask.childTaskId, newTaskList)
      }
      tasks.push(parentTask);
    }
    setTaskList(tasks);
  };

  const getChildTask = (taskList, childTaskIdList, newTaskList) => {
    for (let i = 0; i < childTaskIdList.length; i++) {
      // 子タスクIDに紐づくタスクを取得
      const filtertask = taskList.filter((task) => task._id === childTaskIdList[i]);
      const task = filtertask[0];
      // 子タスクがある場合
      if (task.childTaskId && task.childTaskId.length) {
        // 再帰的に実行
        let childTaskList = [];
        task.childTask = getChildTask(taskList, task.childTaskId, childTaskList);
        newTaskList.push(task);
      } else {
        newTaskList.push(task);
      }
    }
    return newTaskList;
  }


  const getAssignedTaskList = async () => {
    const data = await projectApi.getProjectTask({
      params: {
        shared: {
          userId: user._id,
          membership: user.membership,
        },
        searchCondition: {
          assigned: user._id,
        }
      },
    });
    setTaskList(data.items);
  };

  const getRegisterTaskList = async () => {
    const data = await projectApi.getProjectTask({
      params: {
        shared: {
          userId: user._id,
          membership: user.membership,
        },
        searchCondition: {
          createdUser: user._id,
        }
      },
    });
    setTaskList(data.items);
  };

  useEffect(() => {
    if (location.state && location.state.updated) {
      getTaskList();
      setRole(authUser.roleType);
      // 検索条件の初期化
      dispatch(task_setInitialState());
    }
  }, [location]);

  useEffect(() => {
    getTaskList();
    // 権限の設定
    setRole(authUser.roleType);
    // 検索条件の初期化
    dispatch(task_setInitialState());
  }, []);

  useEffect(() => {}, [taskList])

  const handleFilterAndSearch = (event) => {
    const value = event.target.value;
    if (value === "all") {
      // すべてのデータを表示
      setFilter(value);
      setSearchValue("");
    } else {
      setFilter(value);
    }
  };

  let filteredList = [];
  if (taskList) {
    filteredList = taskList;
    // 優先度で絞り込み
    if (taskState.priority !== "") {
      filteredList = filteredList.filter((task) => task.priority === taskState.priority);
    }
    // タスク状態で絞り込み
    if (taskState.taskStatus !== "") {
      filteredList = filteredList.filter((task) => (
        taskState.taskStatus === 0 || task.taskStatus === taskState.taskStatus));
    }
    // 登録日で絞り込み
    if (taskState.createdStart !== "") {
      filteredList = filteredList.filter((task) => (
        dayjs(taskState.createdStart).format("YYYY/MM/DD") === dayjs(task.created).format("YYYY/MM/DD") ||
        dayjs(taskState.createdStart).format("YYYY/MM/DD") < dayjs(task.created).format("YYYY/MM/DD")));
    }
    if (taskState.createdEnd !== "") {
      filteredList = filteredList.filter((task) => (
        dayjs(taskState.createdEnd).format("YYYY/MM/DD") === dayjs(task.created).format("YYYY/MM/DD") ||
        dayjs(taskState.createdEnd).format("YYYY/MM/DD") > dayjs(task.created).format("YYYY/MM/DD")));
    }
    // 更新日で絞り込み
    if (taskState.updatedStart !== "") {
      filteredList = filteredList.filter((task) => (
        dayjs(taskState.updatedStart).format("YYYY/MM/DD") === dayjs(task.updated).format("YYYY/MM/DD") ||
        dayjs(taskState.updatedStart).format("YYYY/MM/DD") < dayjs(task.updated).format("YYYY/MM/DD")));
    }
    if (taskState.updatedEnd !== "") {
      filteredList = filteredList.filter((task) => (
        dayjs(taskState.updatedEnd).format("YYYY/MM/DD") === dayjs(task.updated).format("YYYY/MM/DD") ||
        dayjs(taskState.updatedEnd).format("YYYY/MM/DD") > dayjs(task.updated).format("YYYY/MM/DD")));
    }
    // タスク期限日で絞り込み
    if (taskState.taskLimitStart !== "") {
      filteredList = filteredList.filter((task) => (
        task.taskLimit && (
          dayjs(taskState.taskLimitStart).format("YYYY/MM/DD") === dayjs(task.taskLimit).format("YYYY/MM/DD") ||
          dayjs(taskState.taskLimitStart).format("YYYY/MM/DD") < dayjs(task.taskLimit).format("YYYY/MM/DD"))));
    }
    if (taskState.taskLimitEnd !== "") {
      filteredList = filteredList.filter((task) => (
        task.taskLimit && (
          dayjs(taskState.taskLimitEnd).format("YYYY/MM/DD") === dayjs(task.taskLimit).format("YYYY/MM/DD") ||
          dayjs(taskState.taskLimitEnd).format("YYYY/MM/DD") > dayjs(task.taskLimit).format("YYYY/MM/DD"))));
    }
    // 担当者名で絞り込み
    if (searchValue) {
      filteredList = filteredList.filter((task) => (
        task.assigned && task.assigned.username.toLowerCase().includes(searchValue.toLowerCase()))
      );
    }
  }

  const getStatusName = (status) => {
    if (status === 0) {
      return "---";
    } else if(status === 1) {
      return "未対応";
    } else if(status === 2) {
      return "処理中";
    } else if(status === 3) {
      return "処理済";
    } else if(status === 99) {
      return "完了";
    }
    return "";
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const pageSizeOptions = [50, 100, 150];

  const searchAreaStyle = {
    accordionStyle: {
      backgroundColor: "#FFFFFF",
      margin: "10px 5px 5px 5px"
    },
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
  }));

  const ListTableHead = () => {
    return (
      <>
        <TableHead sx={{ backgroundColor: "#faebd7" }}>
          <TableRow>
            <StyledTableCell width="50px" />
            <StyledTableCell width="5.3%" align="center">種別</StyledTableCell>
            <StyledTableCell width="5.3%" align="center">優先度</StyledTableCell>
            <StyledTableCell align="center">件名</StyledTableCell>
            <StyledTableCell width="5.3%" align="center">状態</StyledTableCell>
            {/* <StyledTableCell width="5.3%" align="center">マイル状態</StyledTableCell> */}
            <StyledTableCell width="9%" align="center">{activeTab !== "3" ? "担当者" : "登録者"}</StyledTableCell>
            <StyledTableCell width="5.3%" align="center">期限日</StyledTableCell>
            {/* <StyledTableCell width="5.3%" align="center">マイル<br />期限日</StyledTableCell> */}
            <StyledTableCell width="5.3%" align="center">登録日</StyledTableCell>
            <StyledTableCell width="5.3%" align="center">更新日</StyledTableCell>
          </TableRow>
        </TableHead>
      </>
    )
  }

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow >
          <StyledTableCell>
            {row.childTask && row.childTask.length > 0 && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </StyledTableCell>
          <StyledTableCell align="center">{getCategoryName(row.category)}</StyledTableCell>
          <StyledTableCell align="center">{getPriorityIcon(row.priority)}</StyledTableCell>
          <StyledTableCell align="left">
            <Link to={`/member/projects/task/detail/${row._id}`} onClick={() => window.scrollTo(0, 0)}>{row.title}</Link>
          </StyledTableCell>
          <StyledTableCell align="center">{getStatusName(row.taskStatus)}</StyledTableCell>
          {/* <StyledTableCell align="center">{getStatusName(row.milestoneStatus)}</StyledTableCell> */}
          <StyledTableCell align="center">
            {
              activeTab !== "3"
              ? row.assigned?.username
                ? row.assigned?.username
                // : <Link to={`/member/projects/${row._id}/join`}>
                //     <Button variant="contained" sx={{ fontSize: "5px" }}>
                //       担当申請
                //     </Button>
                //   </Link>
                : <Button
                    variant="contained"
                    sx={{ fontSize: "5px" }}
                    onClick={() => dispatch(project_setIsChargeApplicationModalOpen(true))}
                  >
                    担当申請
                  </Button>
              : row.createdUser?.username
            }
          </StyledTableCell>
          <StyledTableCell align="center">
            <div style={ getLimitColor(row.taskLimit, row.taskStatus) }>
              {row.taskLimit && dayjs(row.taskLimit).format("YYYY/MM/DD")}
            </div>
          </StyledTableCell>
          {/* <StyledTableCell align="center">
            <div style={ getLimitColor(row.milestoneLimit, row.milestoneStatus) }>
              {row.milestoneLimit && dayjs(row.milestoneLimit).format("YYYY/MM/DD")}
            </div>
          </StyledTableCell> */}
          <StyledTableCell align="center">{row.created && dayjs(row.created).format("YYYY/MM/DD")}</StyledTableCell>
          <StyledTableCell align="center">{row.updated && dayjs(row.updated).format("YYYY/MM/DD")}</StyledTableCell>
        </TableRow>
        { row.childTask && row.childTask.length > 0 && (
          <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "1%", paddingRight: 0 }} colSpan={12}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <SubRow row={row.childTask}/>
              </Collapse>
            </StyledTableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  const SubRow = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <Box >
          <Table aria-label="collapsible table">
            {/* <ListTableHead /> */}
            <TableBody>
              {row.map((row) => (
                <>
                  <TableRow >
                    <StyledTableCell width="50px">
                      {row.childTask && row.childTask.length > 0 && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      )}
                    </StyledTableCell>
                    <StyledTableCell width="5.3%" align="center">{getCategoryName(row.category)}</StyledTableCell>
                    <StyledTableCell width="5.3%" align="center">{getPriorityIcon(row.priority)}</StyledTableCell>
                    <StyledTableCell align="left">
                      <Link to={`/member/projects/task/detail/${row._id}`} onClick={() => window.scrollTo(0, 0)}>{row.title}</Link>
                    </StyledTableCell>
                    <StyledTableCell width="5.3%" align="center">{getStatusName(row.taskStatus)}</StyledTableCell>
                    {/* <StyledTableCell width="5.3%" align="center">{getStatusName(row.milestoneStatus)}</StyledTableCell> */}
                    <StyledTableCell width="9%" align="center">
                    {
                      activeTab !== "3"
                      ? row.assigned?.username
                        ? row.assigned?.username
                        : <Link to={`/member/projects/${row._id}/join`}>
                            <Button variant="contained" sx={{ fontSize: "5px" }}>
                              担当申請
                            </Button>
                          </Link>
                      : row.createdUser?.username
                    }
                    </StyledTableCell>
                    <StyledTableCell width="5.3%" align="center">
                      <div style={ getLimitColor(row.taskLimit, row.taskStatus) }>
                        {row.taskLimit && dayjs(row.taskLimit).format("YYYY/MM/DD")}
                      </div>
                    </StyledTableCell>
                    {/* <StyledTableCell width="5.3%" align="center">
                      <div style={ getLimitColor(row.milestoneLimit, row.milestoneStatus) }>
                        {row.milestoneLimit && dayjs(row.milestoneLimit).format("YYYY/MM/DD")}
                      </div>
                    </StyledTableCell> */}
                    <StyledTableCell width="5.3%" align="center">{row.created && dayjs(row.created).format("YYYY/MM/DD")}</StyledTableCell>
                    <StyledTableCell width="5.3%" align="center">{row.updated && dayjs(row.updated).format("YYYY/MM/DD")}</StyledTableCell>
                  </TableRow>
                  {/* 子タスクが存在する分、本関数を繰り返す */}
                  { row.childTask && row.childTask.length > 0 && (
                  <TableRow>
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <SubRow row={row.childTask}/>
                      </Collapse>
                    </StyledTableCell>
                  </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </Box>
      </React.Fragment>
    );
  }

  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ mt: 5 }}>
      <div style={{ marginBottom: "20px" }}>
        <Grid
          container
          spacing={1}
        >
          <Grid xs={10} lg={10}>
            <Typography variant="h5" gutterBottom>
              作業一覧
            </Typography>
          </Grid>
          <Grid xs={2} lg={2} align="right">
            <Link to={`/member/projects/task/add`} state={props}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                作業追加
              </Button>
            </Link>
          </Grid>
        </Grid>
        {/* <Typography sx={{ color: "#444444" }}>
          トピック・タスクの閲覧および追加 は 理事会, 事務局 のみが可能です。
        </Typography> */}

        <Accordion style={searchAreaStyle.accordionStyle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ flexDirection: 'row-reverse' }}
          >
            <Typography variant="h7" gutterBottom sx={{ pl: "8px" }}>
              検索条件
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider variant="middle" />
            {/* 中間検索とか色々あるので一旦コメントアウト */}
            {/* <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "1rem",
                marginTop: "1rem",
                marginBottom: "10px"
              }}
            >
              <Grid xs={12} lg={7}>
                <Box sx={{ paddingRight: "2rem" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <TextField
                      label="件名"
                      value={searchValue}
                      style={{ height: "max-content", width: "90%", margin: "5px" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid> */}
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "1rem",
                marginTop: "1rem",
                marginBottom: "10px"
              }}
            >
              <Grid xs={12} lg={1.6}>
                <Box sx={{ paddingRight: "2rem" }}>
                  <Controller
                    name="status"
                    control={control}
                    // rules={validationRules.priority}
                    render={({ field, fieldState }) => (
                      <StatusDropdown field={field} fieldState={fieldState} category={"task"} />
                    )}
                  />
                </Box>
              </Grid>
              <Grid xs={12} lg={1.4}>
                <Box sx={{ paddingRight: "2rem" }}>
                  <Controller
                    name="priority"
                    control={control}
                    // rules={validationRules.priority}
                    render={({ field, fieldState }) => (
                      <PriorityDropdown field={field} fieldState={fieldState} />
                    )}
                  />
                </Box>
              </Grid>
              <Grid xs={12} lg={3}>
                <Box sx={{ paddingRight: "2rem" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <TextField
                      label="担当者名"
                      value={searchValue}
                      style={{ height: "max-content", width: "90%", margin: "5px" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
              <Typography variant="h7" marginRight="1.5rem">
                登録日
              </Typography>
              <TermInput termId="register" category="created" />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
              <Typography variant="h7" marginRight="1.5rem">
                更新日
              </Typography>
              <TermInput termId="update" category="updated" />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
              <Typography variant="h7" marginRight="1.5rem">
                期限日
              </Typography>
              <TermInput termId="limit" category="task" />
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        {/* {(role === RoleType.BoardOfDirectors || role === RoleType.Admin) && ( */}
        {(
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={changeTab} aria-label="task tab">
                <Tab label="一覧" value="1" />
                <Tab label="担当" value="2" />
                <Tab label="登録" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%",}}>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" sx={{ backgroundColor: "#ffffff" }}>
                      <ListTableHead />
                      <TableBody>
                        {filteredList.map((row) => (
                          <Row key={row._id} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={filteredList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%",}}>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" sx={{ backgroundColor: "#ffffff" }}>
                      <ListTableHead />
                      <TableBody>
                        {filteredList.map((row) => (
                          <Row key={row._id} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={filteredList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="3">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%",}}>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" sx={{ backgroundColor: "#ffffff" }}>
                      <ListTableHead />
                      <TableBody>
                        {filteredList.map((row) => (
                          <Row key={row._id} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={filteredList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </Box>
  );
};
