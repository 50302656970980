import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  Paper,
  Popover,
  Chip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { LoadingButton } from "@mui/lab";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import taskApi from "../../../api/taskApi";
import userApi from "../../../api/userApi";
import { useAuthUserContext, RoleType } from "../../../providers/auth";

import {
  task_setInitialState,
  task_setMilestoneAssigned,
  task_setMilestoneLimit,
  task_setSharedUser,
  task_setTaskLimit,
} from "../../../redux/features/taskSlice";
import { useParams } from "react-router-dom";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
export const TaskDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const taskState = useSelector((state) => state.task);
  const userInfo = useSelector((state) => state.user);
  const [task, setTask] = useState([]);
  const [taskHistories, setTaskHistories] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [reDisplay, setReDisplay] = useState(false);
  const [assignedMemberOptionList, setAssignedMemberOptionList] = useState([]);
  const [assignedMemberId, setAssignedMemberId] = useState("");
  const [popupContent, setPopupContent] = useState("");
  const [popupAnchor, setPopupAnchor] = useState(null);
  const [fileUrl, setFileUrl] = useState([]);
  const authUser = useAuthUserContext();

  const handlePopupOpen = (content, anchorEl) => {
    setPopupContent(content);
    setPopupAnchor(anchorEl);
  };

  const handlePopupClose = () => {
    setPopupContent("");
    setPopupAnchor(null);
  };
  const InputSection = styled(Paper)(({ theme }) => ({
    position: "fixed",
    left: 250,
    bottom: 0,
    width: "100%",
    padding: theme.spacing(3),
    backgroundColor: "#FFFFFF",
  }));

  const getPriorityIcon = (priority) => {
    switch (priority) {
      case 2:
        return <KeyboardDoubleArrowUpIcon sx={{ color: "#8b0000", paddingRight: "3px" }} />;
      case 1:
        return <KeyboardArrowUpIcon sx={{ color: "#ff0000", paddingRight: "5px" }} />;
      case 0:
        return <HorizontalRuleIcon sx={{ color: "#008000", paddingRight: "6px" }} />;
      case -1:
        return <KeyboardArrowDownIcon sx={{ color: "#4169e1", paddingRight: "5px" }} />;
      case -2:
        return <KeyboardDoubleArrowDownIcon sx={{ color: "#00008b", paddingRight: "3px" }} />;
      default:
        return <HorizontalRuleIcon sx={{ color: "#008000", paddingRight: "6px" }} />;
    }
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return <Chip label="未対応" color="error" variant="outlined" />;
      case 2:
        return <Chip label="処理中" color="info" variant="outlined" />;
      case 3:
        return <Chip label="処理済み" color="success" variant="outlined" />;
      case 99:
        return <Chip label="完了" color="warning" />;
      default:
        return "";
    }
  };

  const getCategoryName = (category) => {
    switch (category) {
      case 1:
        // return <Chip label="トピック" size="small" color="taskCategory.topic" />
        return <Chip label="トピック" size="small" color="success" sx={{ paddingLeft: "2px", paddingRight: "2px", marginLeft: "6px", marginRight: "12px" }} />
      case 2:
        return <Chip label="タスク" size="small" color="warning" sx={{ paddingLeft: "2px", paddingRight: "2px", marginLeft: "6px", marginRight: "12px" }} />
      default:
        return <Chip label="" size="small" />
    }
  }

  const replaceUrl = (text) => {
    let newText = text
    let newTextArray
    const urls = text.match(/https?:\/\/[\w/:%#\$&\?~\.=\+\-]+/g)
    Array.from(new Set(urls)).forEach((url) => {
      newText = newText.replaceAll(url, `@@!@@${url}@@!@@`)
    })
    newTextArray = newText.split("@@!@@")
    return (
      newTextArray.map((contents) => {
        if (contents.search(/https?:\/\/[\w/:%#\$&\?~\.=\+\-]+/g) === 0) {
          return <Link to={contents}>{contents}</Link>
        } else {
          return contents
        }
      })
    )
  }

  const td1 = {
    width: "20%",
    textAlign: "left",
  };
  const td2 = {
    width: "35%",
    textAlign: "left",
  };
  const td3 = {
    width: "150px",
    textAlign: "left",
  };
  const td4 = {
    width: "60px",
    textAlign: "left",
  };

  // タスク情報取得
  const fetchTask = async () => {
    const task = await taskApi.getById({
      params: { _id: id },
    });
    setTask(task);
    setAssignedMemberId(task.milestoneAssigned?._id);
    setTaskLimit(task.taskLimit && dayjs(task.taskLimit));
    setMilestoneLimit(task.milestoneLimit && dayjs(task.milestoneLimit));
    setTaskStatus(task.taskStatus);
    setMilestoneStatus(task.milestoneStatus);
    setFileUrl(task.fileUrl)
    // 変更履歴情報の編集
    let histories = [];
    let prevCreated = "";
    let prevUser = "";
    for (let i = 0; i < task.history.length; i++) {
      let taskHistory = {};
      taskHistory.created = task.history[i].created;
      taskHistory.user = task.history[i].user;
      taskHistory.text = task.history[i].text;
      taskHistory.isHidden = task.history[i].isHidden;
      taskHistory.isTextPopUp = task.history[i].isTextPopUp;
      // 同じタイミングで登録されたレコードは変更内容のみ表示
      const currentCreated = dayjs(task.history[i].created).format("YYYY/MM/DD HH:mm:ss")
      if (taskHistory.user === prevUser && currentCreated === prevCreated) {
        taskHistory.created = "";
        taskHistory.user = "";
      }
      histories.push(taskHistory);
      prevUser = task.history[i].user;
      prevCreated = dayjs(task.history[i].created).format("YYYY/MM/DD HH:mm:ss");
    }
    setTaskHistories(histories);
  };

  // 理事会ID情報取得
  const getBoardOfDirectorIds = async () => {
    const response = await userApi.getByMemberships({
      params: {
        memberships: ["boardOfDirectors"],
      },
    });
    return response.userList.map((user) => user._id);
  }

  const { control, formState, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      commenty: "",
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  useEffect(() => {
    fetchTask();
    setReDisplay(false);
    fetchAssignedMemberOptionList();
  }, [reDisplay]);

  const validationRules = {};

  const onSubmit = async (data) => {
    // 変更された担当者が共有範囲に設定されていなかった場合
    let isAddSharedUser = false;
    const boardOfDirectors = await getBoardOfDirectorIds();
    if (!task.sharedUser.includes(assignedMemberId) && 
      (task.sharedUser.includes("boardOfDirectors") && !boardOfDirectors.includes(assignedMemberId))
    ) {
      isAddSharedUser = true;
    }
    if (isAddSharedUser) {
      if ( window.confirm("共有範囲に担当者を追加しますがよろしいですか？")) {
        task.sharedUser.push(assignedMemberId);
      } else {
        // 更新処理を中止
        return;
      }
    }

    setLoading(true);
    const reqData = {
      taskId: id,
      comment: data.comment,
      username: userInfo?.value?.username,
      taskLimit: taskLimit,
      milestoneLimit: milestoneLimit,
      milestoneAssigned: assignedMemberId,
      taskStatus: taskStatus,
      milestoneStatus: milestoneStatus,
      updatedUser: userInfo?.value?._id,
      sharedUser: task.sharedUser,
    };

    taskApi
      .detailUpdate(reqData)
      .then(async (res) => {
        // console.log(res)
        alert("更新が完了しました。");
        setLoading(false);
        setReDisplay(true);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  const getStatusOptions = (status) => {
    const options = [
      { value: 1, label: "未対応" },
      { value: 2, label: "処理中" },
      { value: 3, label: "処理済み" },
      { value: 99, label: "完了" },
    ];
    return options.map((option) => (
      <option key={option.value} value={option.value} selected={status === option.value}>
        {option.label}
      </option>
    ));
  };
  const [taskStatus,setTaskStatus] = useState(0);
  const [milestoneStatus,setMilestoneStatus] = useState(0);
  const [taskLimit, setTaskLimit] = useState(
    task.taskLimit ? dayjs(task.taskLimit).toDate() : null
  );
  const [milestoneLimit, setMilestoneLimit] = useState(
    task.milestoneLimit ? dayjs(task.milestoneLimit).toDate() : null
  );

  const handleChangeTaskLimit = (newValue) => {
    setTaskLimit(newValue);
  };

  const handleChangeMilestoneLimit = (newValue) => {
    setMilestoneLimit(newValue);
  };

  const handleTaskStatusChange = (e) => {
    const newStatus = parseInt(e.target.value); // 選択された値を数値に変換
    setTaskStatus(newStatus);
  };

  const handleMilestoneStatusChange = (e) => {
    const newStatus = parseInt(e.target.value); // 選択された値を数値に変換
    setMilestoneStatus(newStatus);
  };

  const handleAssignedMemberIdValue = (value) => {
    setValue("assignedMemberId", value);
  };
  useEffect(() => {
    if (assignedMemberId) {
      handleAssignedMemberIdValue(assignedMemberId);
    }
  }, [assignedMemberId]);

  // マイルストーン担当者を保持する
  const handleChangeAssignedMember = (newValue) => {
    setAssignedMemberId(newValue.target.value);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
  };
  const getShareMemberIcon = (group) => {
    if (group) {
      return <GroupIcon sx={{ color: "#a9a9a9", paddingRight: "3px" }} />;
    } else {
      return <PersonIcon sx={{ color: "#a9a9a9", paddingRight: "5px" }} />;
    }
  };

  // 担当者ドロップダウンの選択肢
  const fetchAssignedMemberOptionList = async () => {
    const assignedMemberOptionList = [];
    const response = await userApi.getByMemberships({
      params: {
        memberships: ["boardOfDirectors", "admin"],
      },
    });

    response.userList &&
      response.userList.forEach((user) => {
        assignedMemberOptionList.push({
          key: user._id,
          group: false,
          text: user.username,
        });
      });

    setAssignedMemberOptionList(assignedMemberOptionList);
  };
  const parentContainerStyle = {
    // height: "1000px",
    overflow: "auto",
  };

  const containerStyle = {
    // height: "600px",
    overflow: "auto",
    paddingBottom: "400px"
  };

  const commentContainerStyle = {
    border: "1px solid #388E3C",
    // height: "150px",
    overflow: "auto",
  };

  const historyContainerStyle = {
    // width: "1000px",
  };

  const getLimitColor = (day, status) => {
    const today = dayjs().format("YYYY/MM/DD");
    const limitDay = dayjs(day).format("YYYY/MM/DD");
    // 日付の比較をして今日日付を超えていたら赤文字
    if (limitDay < today && [1, 2].includes(status)) {
      return {color: "red"};
    } else {
      return {};
    }
  }

  const editButton = () => {
    if (task.createdUser?._id === userInfo.value?._id || [RoleType.Admin].includes(authUser?.roleType)) {
      return (
        <Link to={"/member/task/add?taskId=" + id}>
          <Button variant="contained" sx={{ color: "#fff" }}>
            編集
          </Button>
        </Link>
      )
    } else {
      return (
        <Button variant="contained" sx={{ color: "#fff" }} disabled>
          編集
        </Button>
      )
    }
  }

  const displayImgOnNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div style={parentContainerStyle}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div style={containerStyle}>
          <Grid container spacing={1}>
            <Grid xs={10} lg={11}>
              <h3>
                {getPriorityIcon(task.priority)}
                {getCategoryName(task.category)}
                {task.title}
              </h3>
            </Grid>
            <Grid xs={2} lg={1}>
              { editButton() }
            </Grid>
          </Grid>
          <Box sx={{ mb: 3 }}>
            <table style={{ borderCollapse: "collapse" }} align="left">
              <tr>
                <td style={td1}>タスク</td>
                <td style={td1}>{getStatusName(task.taskStatus)}</td>
                <td style={td2}>
                  期限日: 
                  <span style={ getLimitColor(task.taskLimit, task.taskStatus) }>
                    {task.taskLimit && dayjs(task.taskLimit).format("YYYY/MM/DD")}
                  </span>
                </td>
              </tr>
              <tr>
                <td style={td1}>マイルストーン</td>
                <td style={td1}>{getStatusName(task.milestoneStatus)}</td>
                <td style={td2}>
                    期限日:
                    <span style={ getLimitColor(task.milestoneLimit, task.milestoneStatus) }>
                      {task.milestoneLimit && dayjs(task.milestoneLimit).format("YYYY/MM/DD")}
                  </span>
                </td>
                <td style={td2}>担当:{task.milestoneAssigned?.username}</td>
              </tr>
            </table>
          </Box>
          <Box sx={{ width: "100%", display: "flex", mb: 1 }}>
            <Typography color="text.secondary">
              <h4>
                親トピック・タスク:
                <Link to={`/member/task/detail/${task?.parentTaskId?._id}`} onClick={() => {
                  window.scrollTo(0, 0);
                  setReDisplay(true);
                }}> 
                  {task?.parentTaskId?.title}
                </Link>
              </h4>
            </Typography>
          </Box>
          <Card
            sx={{
              mt: 6,
              width: `calc("100vh" - 200)`,
              padding: 1,
              backgroundColor: "#ffffff",
              border: "1px solid #388E3C",
              overflow: "auto",
              minHeight: 150,
              maxHeight: "48vh",
            }}
          >
            <CardContent>
              <Box sx={{ mb: 3 }}>
                <Box>
                  <Typography sx={{ mb: 2, fontWeight: "bold" }} color="text.secondary">
                    {task.createdUser?.username}
                  </Typography>
                  <Typography sx={{ mb: 2 }} color="text.secondary">
                    登録日:{task.created && dayjs(task.created).format("YYYY/MM/DD HH:mm:ss")}
                  </Typography>
                  <Typography
                    sx={{ mb: 2, whiteSpace: "pre-wrap" }}
                    color="text.secondary"
                    variant="body1"
                    gutterBottom
                  >
                    {task.body && replaceUrl(task.body)}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Box sx={{ mt: 1, ml: 2, display: "flex", gap: 2 }}>
            {fileUrl &&
              fileUrl.map((fileInfo, index) => (
                // <li key={url._id} style={{ display: "flex", marginBottom: "5px" }}>
                //   <a href={url} target="_blank" rel="noopener noreferrer">
                //     {getMetadata(url, `topicDoc%2F${task.seqNo}%2F`)}
                //   </a>
                // </li>
                <>
                  <Chip
                    icon={<AttachFileIcon />}
                    label={fileInfo.name}
                    onClick={() => displayImgOnNewTab(fileInfo.url)}
                    // onDelete={() => removeUploadedFile(index)}
                  />
                </>
              ))}
          </Box>
          <Typography sx={{ mt: 2, mb: 1 }} color="text.secondary">
            コメント件数: {task.commentId?.length || 0}
          </Typography>
          <div style={commentContainerStyle}>
            {task.commentId?.map((comment) => (
              <Card sx={{ minWidth: 400, maxWidth: "100%", padding: 1, backgroundColor: "#ffffff" }}>
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", mb: 2 }}>
                      <Typography sx={{ mb: 2, mr: 2, fontWeight: "bold" }} color="text.secondary">
                        {comment.commentUser}
                      </Typography>
                      <Typography sx={{ mb: 2 }} color="text.secondary">
                        {comment.created && dayjs(comment.created).format("YYYY/MM/DD HH:mm:ss")}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{ mb: 2, whiteSpace: "pre-wrap" }}
                        color="text.secondary"
                      >
                        {comment.comment}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </div>
          <Typography sx={{ mt: 3, mb: 1 }} color="text.secondary">
            変更履歴
          </Typography>
          <div style={ historyContainerStyle }>
            <Box sx={{ display: "flex", gap: "20px", mt: 1, flexWrap: "wrap" }}>
              <Card
                sx={{
                  width: "100%",
                  padding: 1,
                  backgroundColor: "#ffffff",
                  overflow: "auto",
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "center",
                      flexDirection: "column",
                      fontSize: "12px",
                    }}
                  >
                    {taskHistories?.map((history, index) => {
                      const content = () => (
                        <div>
                          <span style={{ marginLeft: "20px" }}>{history.text}</span>
                        </div>
                      );

                      if (history.isTextPopUp) {
                        return (
                          <div
                            key={index}
                            onClick={(e) => handlePopupOpen(content(), e.currentTarget)}
                          >
                            <li key={index} style={{ display: "flex" }}>
                              <span style={{ flexBasis: "180px" }}>{history.created && dayjs(history.created).format("YYYY/MM/DD HH:mm:ss")}</span>
                              <span style={{ flexBasis: "150px", marginLeft: "20px" }}>{history.user}</span>
                              <span
                                style={{
                                  flexBasis: "670px",
                                  marginLeft: "20px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              >
                                {history.text.substring(0, 0)}変更内容
                              </span>
                            </li>
                          </div>
                        );
                      } else if (!history.isHidden){
                        return (
                          <li key={index} style={{ display: "flex" }}>
                            <span style={{ flexBasis: "180px" }}>{history.created && dayjs(history.created).format("YYYY/MM/DD HH:mm:ss")}</span>
                            <span style={{ flexBasis: "150px", marginLeft: "20px" }}>{history.user}</span>
                            <span
                              style={{
                                flexBasis: "670px",
                                marginLeft: "20px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {history.text}
                            </span>
                          </li>
                        );
                      }
                    })}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </div>
        </div>
        <InputSection elevation={2}>
          <Box sx={{ display: "flex", mt: 1, mb: 2 }}>
            <Box sx={{ display: "flex", width: "600px", mt: 5, mb: 1 }}>
              <Controller
                name="comment"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="comment"
                    label="コメント"
                    multiline
                    rows={6}
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 1, mb: 1, ml: 5 }}>
              <table>
                <td style={{ verticalAlign: "top" }}>
                  <Box sx={{ mr: 3, }}>
                    <Typography>
                      トピック・タスク
                    </Typography>
                    <table style={{ borderCollapse: "collapse" }} align="left">
                      <tr>
                        <td style={td4}>状態</td>
                        <td style={td3}>
                          <Box sx={{ marginBottom: "0.2rem" }}>
                            <select value={taskStatus} onChange={(e) => handleTaskStatusChange(e)}>
                              {getStatusOptions(taskStatus)}
                            </select>
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <td style={td4}>期限日</td>
                        <td style={td3}>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                              <Box sx={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                                <DatePicker
                                  id="taskLimit"
                                  label="期限日"
                                  value={taskLimit}
                                  onChange={handleChangeTaskLimit}
                                  inputFormat="yyyy/MM/dd"
                                  mask="____/__/__"
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Box>
                            </LocalizationProvider>
                          </Box>
                        </td>
                      </tr>
                    </table>
                  </Box>
                </td>
                <td>
                  <Box>
                    <Typography>
                      マイルストーン
                    </Typography>
                    <table style={{ borderCollapse: "collapse" }} align="left">
                      <tr>
                        <td style={td4}>状態</td>
                        <td style={td3}>
                        <Box sx={{ marginBottom: "0.2rem" }}>
                          <select
                            value={milestoneStatus}
                            onChange={(e) => handleMilestoneStatusChange(e)}
                          >
                            {getStatusOptions(milestoneStatus)}
                          </select>
                        </Box>
                        </td>
                      </tr>
                      <tr>
                        <td style={td4}>期限日</td>
                        <td style={td3}>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                              <Box sx={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                                <DatePicker
                                  id="milestoneLimit"
                                  label="期限日"
                                  value={milestoneLimit}
                                  onChange={handleChangeMilestoneLimit}
                                  inputFormat="yyyy/MM/dd"
                                  mask="____/__/__"
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Box>
                            </LocalizationProvider>
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <td style={td4}>担当者</td>
                        <td style={td3}>
                          <Box sx={{ marginBottom: "0.5rem", marginTop: "0.5rem"  }}>
                            <Controller
                              name="milestoneAssigned"
                              control={control}
                              defaultValue={task.milestoneAssigned?._id}
                              render={({ field, fieldState }) => (
                                <FormControl fullWidth error={fieldState.invalid}>
                                  <InputLabel>担当者</InputLabel>
                                  <Select
                                    id="milestoneAssigned"
                                    {...field}
                                    input={<OutlinedInput label="担当者" />}
                                    value={assignedMemberId}
                                    MenuProps={MenuProps}
                                    onChange={handleChangeAssignedMember}
                                  >
                                    {assignedMemberOptionList &&
                                      assignedMemberOptionList.map((item) => (
                                        <MenuItem
                                          key={item.key}
                                          value={item.key}
                                          style={menuItemStyle}
                                        >
                                          {getShareMemberIcon(item.group)}
                                          {item.text}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  {fieldState.invalid && (
                                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                  )}
                                </FormControl>
                              )}
                            />
                          </Box>
                        </td>
                      </tr>
                    </table>
                  </Box>
                </td>
              </table>
            </Box>
          </Box>
          <Box sx={{ 
            display: "flex", 
            justifyContent: "center", 
            mr:30,
            }}
          >
            <LoadingButton
              sx={{ mt: 1, mb: 1, width: "300px"}}
              fullWidth
              type="submit"
              loading={loading}
              color="primary"
              variant="contained"
              disabled={loading}
              size="large"
            >
              更新
            </LoadingButton>
          </Box>
        </InputSection>
      </form>
      {/* ポップアップの定義 */}
      <Popover
        open={Boolean(popupAnchor)}
        anchorEl={popupAnchor}
        onClose={handlePopupClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>{popupContent}</Box>
      </Popover>
    </div>
  );
};
