import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage from "../../../config/firebaseConfig.js";

const manualData = [
  { title: "入会受付", fileName: "accept_flow.mp4" },
  { title: "稟議書", fileName: "proposal_flow.mp4" },
];

export const Manual = () => {
  const [urlList, setUrlList] = useState([]);

  useEffect(() => {
    const listRef = ref(storage, "video_manual");
    listAll(listRef)
      .then(async (list) => {
        const urls = await Promise.all(list.items.map((ref) => getDownloadURL(ref)));
        setUrlList(urls);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const manuals = manualData.map((data, index) => {
    return (
      <>
        <Typography gutterBottom fontSize={"1rem"} marginBottom={"1rem"}>
          {index + 1 + ". " + data.title}
        </Typography>
        <Box sx={{ marginBottom: "2rem", width: { xs: "auto", md: "700px" }, maxWidth: "100%" }}>
          <video
            style={{ width: "100%", height: "auto" }}
            src={urlList[index]}
            muted
            controls
          ></video>
        </Box>
      </>
    );
  });

  return (
    <div>
      <Typography variant="h5" gutterBottom marginBottom={"2rem"}>
        操作マニュアル
      </Typography>
      {manuals}
    </div>
  );
};
