import { Button, MenuItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useState } from "react";
// import { RichText } from '../../../components/common/RichText';
import eventApi from "../../../api/eventApi";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import uploadImg from "../../../components/common/utils/uploadImg";
import { useSelector } from "react-redux";

export const EventRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storedUserInfo = useSelector((state) => state.user);
  var now = dayjs();

  // start Date time
  const [startDateVal, setStartDateVal] = React.useState(now);
  const handleChangeStartDate = (newValue) => {
    setStartDateVal(newValue);
  };

  // end Date time
  const [endDateVal, setEndDateVal] = React.useState(now.add(1, "hour"));
  const handleChangeEndDate = (newValue) => {
    setEndDateVal(newValue);
  };

  // start available Date time
  const [startAvailableDateVal, setStartAvailableDateVal] = React.useState(now);
  const handleChangeStartAvailableDate = (newValue) => {
    setStartAvailableDateVal(newValue);
  };

  // end available Date time
  const [endAvailableDateVal, setEndAvailableDateVal] = React.useState(now.add(1, "hour"));
  const handleChangeEndAvailableDate = (newValue) => {
    setEndAvailableDateVal(newValue);
  };

  // event name
  const [eventName, setEventName] = useState(
    !location.state ? "" : location.state.params.eventName
  );
  const handleEventNameChange = (e) => {
    setEventName(e.target.value);
  };

  // event description
  const [eventDesc, setEventDesc] = useState(
    !location.state ? "" : location.state.params.eventDesc
  );
  const handleEventDescChange = (e) => {
    setEventDesc(e.target.value);
  };

  // webinar url
  const [webinarUrl, setWebinarUrl] = useState(
    !location.state ? "" : location.state.params.webinarUrl
  );
  const handleWebinarUrlChange = (e) => {
    setWebinarUrl(e.target.value);
  };
  // archive url
  const [archiveUrl, setArchiveUrl] = useState(
    !location.state ? "" : location.state.params.archiveUrl
  );
  const handleArchiveUrlChange = (e) => {
    setArchiveUrl(e.target.value);
  };

  const [coverImgUrl, setCoverImgUrl] = useState(
    !location.state ? "" : location.state.params.coverImgUrl
  );
  const [coverImg, setCoverImg] = useState();

  // form Type
  /*
   * 0: default, 1:支払方法ありパターン
   */
  const [formType, setFormType] = useState(0);
  const handleFormTypeChange = (e) => {
    setFormType(e.target.value);
  };

  useEffect(() => {
    if (location.state) {
      setStartDateVal(dayjs(location.state.params.startDate));
      setEndDateVal(dayjs(location.state.params.endDate));
      setStartAvailableDateVal(dayjs(location.state.params.startAvailableDate));
      setEndAvailableDateVal(dayjs(location.state.params.endAvailableDate));
      setEventName(location.state.params.eventName);
      setEventDesc(location.state.params.eventDesc);
      setWebinarUrl(location.state.params.webinarUrl);
      setArchiveUrl(location.state.params.archiveUrl);
      setFormType(location.state.params.formType);
      setCoverImgUrl(location.state.params.coverImgUrl);
    }
  }, [location.state]);
  const eventStyleCss = {
    boxStyle: {
      display: "flex",
      gap: "20px",
      flexDirection: "column",
      margin: "20px 0px",
    },
  };

  const onSubmitDraft = async () => {
    const fileUrl = coverImg?.size ? await uploadImg(coverImg, ["eventCoverImg"]) : coverImgUrl;
    if (!location.state) {
      const payload = {
        eventName: eventName,
        eventDesc: eventDesc,
        startDate: startDateVal,
        endDate: endDateVal,
        startAvailableDate: startAvailableDateVal,
        endAvailableDate: endAvailableDateVal,
        webinarUrl: webinarUrl,
        formType: formType,
        coverImgUrl: fileUrl,
        isDraft: true,
        createdUserId: storedUserInfo.value?._id,
      };
      await eventApi.register(payload)
        .then(() => {
          alert("イベントの一時保存が完了しました");
          navigate("/admin/event_list");
        })
        .catch((e) => {
          console.error(e);
          alert("イベントの一時保存に失敗しました。");
        });
    } else {
      const body = {
        id: location.state.params.id,
        eventName: eventName,
        eventDesc: eventDesc,
        startDate: startDateVal,
        endDate: endDateVal,
        startAvailableDate: startAvailableDateVal,
        endAvailableDate: endAvailableDateVal,
        webinarUrl: webinarUrl,
        formType: formType,
        coverImgUrl: fileUrl,
        archiveUrl: archiveUrl,
      };
      await eventApi.updateById({ ...body })
        .then(() => {
          alert("イベントの一時保存の更新が完了しました");
          navigate("/admin/event_list");
        })
        .catch((e) => {
          console.error(e);
          alert("イベントの一時保存の更新に失敗しました。");
          return;
        });
    }
  }

  const onSubmit = async () => {
    if (!startDateVal) {
      alert("開催期間の開始時間を入力してください");
      return;
    } else {
      const formatDate = dayjs(startDateVal).format("YYYY/MM/DD HH:mm");
      if (!dayjs(formatDate).isValid()) {
        alert("開催期間の開始時間の日付形式が間違っています");
        return;
      }
    }
    if (!endDateVal) {
      alert("開催期間の終了時間を入力してください");
      return;
    } else {
      const formatDate = dayjs(endDateVal).format("YYYY/MM/DD HH:mm");
      if (!dayjs(formatDate).isValid()) {
        alert("開催期間の終了時間の日付形式が間違っています");
        return;
      }
      // 日付の前後チェック
      const formatStartDate = dayjs(startDateVal).format("YYYY/MM/DD HH:mm");
      if (formatDate < formatStartDate) {
        alert("終了時間は開始時間以降の日付を入力してください");
        return;
      }
    }
    if (!startAvailableDateVal) {
      alert("受付可能期間の受付開始日を入力してください");
      return;
    } else {
      const formatDate = dayjs(startAvailableDateVal).format("YYYY/MM/DD HH:mm");
      if (!dayjs(formatDate).isValid()) {
        alert("受付可能期間の受付開始日の日付形式が間違っています");
        return;
      }
    }
    if (!endAvailableDateVal) {
      alert("受付可能期間の受付終了日を入力してください");
      return;
    } else {
      const formatDate = dayjs(endAvailableDateVal).format("YYYY/MM/DD HH:mm");
      if (!dayjs(formatDate).isValid()) {
        alert("受付可能期間の受付終了日の日付形式が間違っています");
        return;
      }
      // 日付の前後チェック
      const formatStartDate = dayjs(startAvailableDateVal).format("YYYY/MM/DD HH:mm");
      if (formatDate < formatStartDate) {
        alert("受付終了日は受付開始日以降の日付を入力してください");
        return;
      }
    }
    if (eventName === "") {
      alert("イベント名を入力してください。");
      return;
    }
    if (eventDesc === "") {
      alert("イベント説明を入力してください。");
      return;
    }
    if (formType === 0 && webinarUrl === "") {
      alert("受付フォームタイプAを選択時はZoom Webinar URLを入力してください。");
      return;
    }
    const fileUrl = coverImg?.size ? await uploadImg(coverImg, ["eventCoverImg"]) : coverImgUrl;
    if (!location.state) {
      const payload = {
        eventName: eventName,
        eventDesc: eventDesc,
        startDate: startDateVal,
        endDate: endDateVal,
        startAvailableDate: startAvailableDateVal,
        endAvailableDate: endAvailableDateVal,
        webinarUrl: webinarUrl,
        formType: formType,
        coverImgUrl: fileUrl,
        createdUserId: storedUserInfo.value?._id,
      };
      await eventApi.register(payload)
      .then(() => {
        alert("イベントの作成が完了しました");
        navigate("/admin/event_list");
      })
      .catch((e) => {
        console.error(e);
        alert("イベントの作成に失敗しました。");
      });
    } else {
      const body = {
        id: location.state.params.id,
        eventName: eventName,
        eventDesc: eventDesc,
        startDate: startDateVal,
        endDate: endDateVal,
        startAvailableDate: startAvailableDateVal,
        endAvailableDate: endAvailableDateVal,
        webinarUrl: webinarUrl,
        formType: formType,
        coverImgUrl: fileUrl,
        archiveUrl: archiveUrl,
        isDraft: false,
      };
      await eventApi.updateById({ ...body })
      .then(() => {
        alert("イベントの更新が完了しました");
        navigate("/admin/event_list");
      })
      .catch((e) => {
        console.error(e);
        alert("イベントの更新に失敗しました。");
      });
    }
  };

  return (
    <div>
      <Typography variant="h5">{!location.state ? "イベント登録" : "イベント更新"}</Typography>
      <Box sx={eventStyleCss.boxStyle}>
        <Typography variant="h6">開催期間</Typography>
        <Divider variant="middle" />
        <Box sx={{ display: "flex" }}>
          <Box>
            <Typography variant="body1">開始時間</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <Box sx={{ m: 2 }}>
                <DateTimePicker
                  value={startDateVal}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box>
            <Typography variant="body1">終了時間</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <Box sx={{ m: 2 }}>
                <DateTimePicker
                  value={endDateVal}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>

      <Box sx={eventStyleCss.boxStyle}>
        <Typography variant="h6">受付可能期間</Typography>
        <Divider variant="middle" />
        <Box sx={{ display: "flex" }}>
          <Box>
            <Typography variant="body1">受付開始日</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <Box sx={{ m: 2 }}>
                <DateTimePicker
                  value={startAvailableDateVal}
                  onChange={handleChangeStartAvailableDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box>
            <Typography variant="body1">受付終了日</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <Box sx={{ m: 2 }}>
                <DateTimePicker
                  value={endAvailableDateVal}
                  onChange={handleChangeEndAvailableDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>

      <Box sx={eventStyleCss.boxStyle}>
        <Typography variant="h6">イベント基本情報</Typography>
        <Divider variant="middle" />
        <TextField
          id="outlined-basic"
          label="イベント名"
          variant="outlined"
          value={eventName}
          onChange={handleEventNameChange}
        />
        {/* <Box sx={{ border: "1px solid #ebebeb", borderRadius: "5px" }}>
          <RichText />
        </Box> */}
        <TextField
          multiline
          rows={4}
          id="outlined-basic"
          label="イベント説明"
          variant="outlined"
          value={eventDesc}
          onChange={handleEventDescChange}
        />
        <Box sx={{ display: "flex", mt: 2, gap: 2, alignItems: "center" }}>
          <Button
            variant="contained"
            component="label"
            onChange={(e) => {
              setCoverImg(e.target.files[0]);
            }}
          >
            カバー画像アップロード
            <input hidden accept="image/*" multiple type="file" />
          </Button>
        </Box>
        {(coverImg?.size && (
          <>
            <Box>
              <img
                src={URL.createObjectURL(coverImg)}
                style={{ objectFit: "cover", maxWidth: "800px" }}
                alt="カバー写真"
              />
            </Box>
            <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
              <Button
                variant="contained"
                type="button"
                sx={{ color: "#fff" }}
                onClick={() => setCoverImg(null)}
              >
                取消
              </Button>          
            </Box>            
          </>
        )) ||
          (coverImgUrl && (
            <Box>
              <img
                src={coverImgUrl}
                style={{ objectFit: "cover", maxWidth: "800px" }}
                alt="カバー写真"
              />
            </Box>
          ))}
        <TextField
          id="webinarUrl"
          label="Zoom Webinar URL"
          variant="outlined"
          value={webinarUrl}
          onChange={handleWebinarUrlChange}
        />
        {location.state && (
          <TextField
            id="archiveUrl"
            label="アーカイブ動画URL"
            variant="outlined"
            value={archiveUrl}
            onChange={handleArchiveUrlChange}
          />
        )}
      </Box>
      <Box sx={eventStyleCss.boxStyle}>
        <Typography variant="h6">受付フォームタイプ選択</Typography>
        <Divider variant="middle" />
        <Select value={formType} onChange={handleFormTypeChange}>
          <MenuItem value={0}>タイプA(一般)</MenuItem>
          <MenuItem value={1}>タイプB(支払方法欄あり)</MenuItem>
        </Select>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" onClick={onSubmit}>
          {!location.state ? "イベントを作成する" : "イベントを更新する"}
        </Button>
        {(!location.state || location.state.params.isDraft) && (
          <Button 
            sx={{ ml: 3, color: "white", background: "#1976D2" }}
            variant="contained" 
            onClick={onSubmitDraft}
          >
            下書き保存
          </Button>
        )}
      </Box>
    </div>
  );
};
