import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import newspaperImg from "../../assets/imgs/subpage_title_bgimg.jpg";
import { API_URL, API_KEY } from "../../utils/microcms.js";
import moment from "moment";
import Chip from "@mui/material/Chip";
import classes from "../../assets/css/layout/blog.module.css";
import { useSelector } from "react-redux";
import newsBgimg from "../../assets/imgs/subpage_bgimg.png";
import ScrollToTopOnMount from "../../components/common/utils/ScrollToTopOnMount";

export const ReleaseDetails = () => {
  let storeLng = useSelector((state) => state.language.value);

  const { id } = useParams();
  const [item, setItem] = useState({});
  const contentName = "release"; // APIのコンテンツ名
  useEffect(() => {
    fetch(API_URL + contentName + "/" + id, {
      headers: { "X-MICROCMS-API-KEY": API_KEY },
    })
      .then((res) => res.json())
      .then((data) => {
        setItem(data);
        // console.log(data);
      });
    if (!storeLng) {
      storeLng = "ja";
    }
  }, [id, storeLng]);

  return (
    <Box
      sx={{
        background: "#F6F6F6",
        minHeight: "100Vh",
        paddingBottom: "1rem",
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, .2),  rgba(0, 0, 0, .2)),url(${newspaperImg})`,
          backgroundSize: "cover",
          height: "350px",
          display: "flex",
          alignItems: "center",
          padding: "0px 0px 0px 17%",
        }}
      >
        <Typography
          variant="h4"
          color="white.main"
          sx={{
            color: "#FFF",
            fontFamily: "source-han-sans-japanese",
            fontSize: "32px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            letterSpacing: "9px",
          }}
        >
          PRESS RELEASE
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "54px",
          alignItems: "center",
          padding: "0px 0px 0px 75px",
          borderBottom: "1px solid #D1D1D1",
        }}
      >
        <Box
          sx={{
            maxWidth: "50%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            color: "#818181",
            fontFamily: "source-han-sans-japanese",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "4px",
          }}
        >
          <a href="/" style={{ color: "#818181" }}>
            HOME
          </a>
          /
          <a href="/news" style={{ color: "#818181" }}>
            PRESS RELEASE
          </a>
          /
          {storeLng === "ja"
            ? item.title
            : item.title_english?.length
            ? item.title_english
            : item.title}
        </Box>
      </Box>
      <Box
        sx={{
          background: `url(${newsBgimg})`,
          backgroundPosition: "50% 50%",
          backgroundSize: "250%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            width: "1145px",
            maxWidth: "90%",
            margin: { xs: "1rem auto", md: "2rem auto" },
            padding: { xs: "0rem", md: "3rem 5rem" },
            overflow: "hidden",
            wordBreak: "break-all",
          }}
        >
          <div className={classes.articlecontents}>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: "#000",
                  fontFamily: "source-han-sans-japanese",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                  letterSpacing: "4px",
                }}
              >
                {moment(item.createdAt).format("YYYY/MM/DD")}
              </Typography>
            </Box>

            <Box sx={{ paddingTop: "45px" }}>
              <Typography
                variant="h4"
                sx={{
                  color: "#000",
                  fontFamily: "source-han-sans-japanese",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                  letterSpacing: "8px",
                }}
              >
                {storeLng === "ja"
                  ? item.title
                  : item.title_english?.length
                  ? item.title_english
                  : item.title}
              </Typography>
            </Box>

            <Divider sx={{ borderColor: "#000", margin: "45px 0px" }} />
            <Box sx={{ padding: { md: "20px" } }}>
              <Box sx={{ padding: { md: "20px" } }}>
                {storeLng === "ja" ? (
                  <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.content }} />
                ) : item.content_english ? (
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: item.content_english }}
                  />
                ) : (
                  <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.content }} />
                )}
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
      <ScrollToTopOnMount />
    </Box>
  );
};
