import React from "react";
import logo from "../../assets/imgs/NCCC_logo_symbol.png";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const logoTextStyle = {
  fontFamily: "Kozuka Gothic Pro",
  fontStyle: "normal",
  fontWeight: "900",
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  letterSpacing: " 0.06em",
  color: "#3A3A3A",
  marginTop: "5px",
};

const Logo = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "white.main",
      }}
    >
      <img src={logo} alt="logo" style={{ maxWidth: "56px" }} />
      <Typography variant="body1" textAlign="center" sx={logoTextStyle}>
        {t("logo.line1")}
        <br />
        {t("logo.line2")}
        <br />
        {t("logo.line3")}
      </Typography>
    </Box>
  );
};

export default Logo;
