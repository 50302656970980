import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { DataGrid } from "@mui/x-data-grid";
import { Badge, Box, Button, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import dayjs from "dayjs";

import authApi from "../../../api/authApi";
import userApi from "../../../api/userApi";
import examinationApi from "../../../api/examinationApi";
import joinMemberApi from "../../../api/joinMember";

const formatDate = (num) => {
  return num < 10 ? "0" + num : num;
};

const handleToDate = (date) => {
  date = new Date(date);
  date.setDate(date.getDate() + 3);

  const formattedMonth = formatDate((date.getMonth() % 12) + 1);
  const formattedDate = formatDate(date.getDate());
  const formattedHour = formatDate(date.getHours());
  const formattedMinut = formatDate(date.getMinutes());

  date =
    date.getFullYear() +
    "/" +
    formattedMonth +
    "/" +
    formattedDate +
    " " +
    formattedHour +
    ":" +
    formattedMinut;

  return date;
};
const handleToDateForApplicationDate = (date) => {
  date = new Date(date);

  const formattedMonth = formatDate((date.getMonth() % 12) + 1);
  const formattedDate = formatDate(date.getDate());
  const formattedHour = formatDate(date.getHours());
  const formattedMinut = formatDate(date.getMinutes());

  date =
    date.getFullYear() +
    "/" +
    formattedMonth +
    "/" +
    formattedDate +
    " " +
    formattedHour +
    ":" +
    formattedMinut;

  return date;
};
const approvalComponent = (params) => {
  if (params.value === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          border: "1px solid #388E3C",
          borderRadius: "20px",
          color: "#ffffff",
          backgroundColor: "#388E3C",
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        承認
      </Grid>
    );
  } else if (params.value === 1) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          border: "1px solid #8b0000",
          borderRadius: "20px",
          fontSize: "12px",
          fontWeight: "bold",
          color: "#FFFFFF",
          bgcolor: "#8b0000",
        }}
      >
        否認
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          border: "1px solid #E53935",
          borderRadius: "20px",
          fontSize: "12px",
          color: "#E53935",
        }}
      >
        未対応
      </Grid>
    );
  }
};
const auditorComponent = (params) => {
  if (params.value === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          border: "1px solid #388E3C",
          borderRadius: "20px",
          color: "#ffffff",
          backgroundColor: "#388E3C",
          fontSize: "12px",
        }}
      >
        異議なし
      </Grid>
    );
  } else if (params.value === 1) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          border: "1px solid #8b0000",
          borderRadius: "20px",
          fontSize: "12px",
          fontWeight: "bold",
          color: "#FFFFFF",
          bgcolor: "#8b0000",
        }}
      >
        異議あり
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          border: "1px solid #E53935",
          borderRadius: "20px",
          fontSize: "12px",
          color: "#E53935",
        }}
      >
        未対応
      </Grid>
    );
  }
};

const cancelListColumns = [
  {
    field: "_id",
    headerName: "詳細",
    renderCell: (params) => {
      return (
        <>
          <Link to={"/admin/primary-pass/details/" + params.value}>
            <Button size="small" variant="contained" sx={{ fontSize: "5px", fontWeight: 600 }}>
              詳細へ
            </Button>
          </Link>
        </>
      );
    },
  },
  { field: "groupName", headerName: "会社名", width: 250 },
  {
    field: "created",
    headerName: "入会受付日時",
    width: 150,
    renderCell: (params) => {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            borderRadius: "20px",
          }}
        >
          {handleToDateForApplicationDate(params.value)}
        </Grid>
      );
    },
  },
  {
    field: "cancelDay",
    headerName: "審査キャンセル日",
    width: 150,
    renderCell: (params) => {
      if (params && params.value) {
        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: "20px",
            }}
          >
            {handleToDateForApplicationDate(params.value)}
          </Grid>
        );
      }
    },
  },
  {
    field: "cancelReason",
    headerName: "キャンセル理由",
    width: 250,
    renderCell: (params) => {
      if (params && params.value) {
        return (
          <Grid
            container
            justifyContent="left"
            alignItems="center"
            sx={{
              borderRadius: "20px",
            }}
          >
            {params.value}
          </Grid>
        );
      }
    },
  },
];

export const PrimaryPass = () => {
  const storedUserInfo = useSelector((state) => state.user);

  const [list, setList] = useState([]);
  const [cancelCompanyList, setCancelCompanyList] = useState([]);
  const [tabValue, setTabValue] = useState("1");
  const [companyList, setCompanyList] = useState([])
  const [boardOfDirectors, setBoardOfDirectors] = useState()

  const columns = [
    {
      field: "isNotice",
      headerName: "",
      width: 5,
      renderCell: (params) => {
        return <Box>{params.value ? "" : <Badge color="warning" variant="dot"></Badge>}</Box>;
      },
    },
    {
      field: "_id",
      headerName: "詳細",
      renderCell: (params) => {
        return (
          <>
            <Link to={"/admin/primary-pass/details/" + params.value}>
              <Button size="small" variant="contained" sx={{ fontSize: "5px", fontWeight: 600 }}>
                詳細へ
              </Button>
            </Link>
          </>
        );
      },
    },
    { field: "groupName", headerName: "会社名", width: 250 },
    {
      field: "created",
      headerName: "入会受付日時",
      width: 150,
      renderCell: (params) => {
        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: "20px",
            }}
          >
            {handleToDateForApplicationDate(params.value)}
          </Grid>
        );
      },
    },
    {
      field: "updated",
      headerName: "承認期限",
      width: 150,
      renderCell: (params) => {
        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: "20px",
            }}
          >
            {handleToDate(params.value)}
          </Grid>
        );
      },
    },
    {
      field: "claimDay",
      headerName: "請求日",
      width: 150,
      renderCell: (params) => {
        if (params && params.value) {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                borderRadius: "20px",
              }}
            >
              {handleToDateForApplicationDate(params.value)}
            </Grid>
          );
        }
      },
    },
    {
      //field: "isApply",
      field: "companyStatus",
      headerName: "Status",
      width: 75,
      renderCell: (params) => {
        if (params.value === 1) {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: "1px solid #388E3C",
                borderRadius: "20px",
                color: "#ffffff",
                backgroundColor: "#388E3C",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              承認
            </Grid>
          );
        } else if (params.value === 0) {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: "1px solid #FFCA28",
                borderRadius: "20px",
                fontSize: "12px",
                color: "#FFCA28",
              }}
            >
              未承認
            </Grid>
          );
        } else if (params.value === 2) {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: "1px solid #000000",
                borderRadius: "20px",
                fontSize: "12px",
                color: "#000000",
              }}
            >
              請求中
            </Grid>
          );
        } else if (params.value === 3) {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: "1px solid #000000",
                borderRadius: "20px",
                fontSize: "12px",
                color: "#000000",
              }}
            >
              完了
            </Grid>
          );
        }
      },
    },
  ];

  const keys = boardOfDirectors && Object.keys(boardOfDirectors)
  keys?.forEach((key) => {
    switch (key) {
      case "chairman":
        columns.push({
          field: boardOfDirectors[key]?.applyerRole,
          headerName: boardOfDirectors[key]?.shortName,
          width: 73,
          renderCell: (params) => {
            // 理事となった日付以前の申請は承認不要のため日付の条件を追加で付与
            if (dayjs(params.row.created) > dayjs(boardOfDirectors[key]?.startDate)) {
              return approvalComponent(params);
            }
          },
        })
        break
      case "directors":
        const directors = boardOfDirectors[key]
        directors.forEach((director) => {
          columns.push({
            field: director.applyerRole,
            headerName: director.shortName,
            width: 73,
            renderCell: (params) => {
              // 理事となった日付以前の申請は承認不要のため日付の条件を追加で付与
              if (dayjs(params.row.created) > dayjs(director.startDate)) {
                return approvalComponent(params);
              }
            },
          })
        })
        break
      case "auditors":
        const auditors = boardOfDirectors[key]
        auditors.forEach((auditor) => {
          columns.push({
            field: auditor.applyerRole,
            headerName: auditor.shortName,
            width: 73,
            renderCell: (params) => {
              // 理事となった日付以前の申請は承認不要のため日付の条件を追加で付与
              if (dayjs(params.row.created) > dayjs(auditor.startDate)) {
                return auditorComponent(params);
              }
            },
          })
        })
        break
      default:
        return
    }
  })

  const fetchCancelCompanyList = async () => {
    const cancelCompanyList = await authApi.getCompanyList({
      params: {
        // 入会審査キャンセルフラグON の会社のみを絞り込む
        conditions: { isExamCancel: true },
        sort: { created: -1 },
      },
    });
    setCancelCompanyList(cancelCompanyList.companys)
  }

  const updateIsNotice = (director) => {
    if (storedUserInfo.value._id === director) {
      return true;
    } else {
      return false;
    }
  };

  const checkExamResult = async (boardOfDirectors) => {
    let resultList = [];
    // 審査データ取得
    const examinations = await examinationApi.getList();
    const keys = Object.keys(boardOfDirectors)
    // 審査対象企業と審査データを二重ループで確認する
    companyList.forEach((company) => {
      if (company.isAdmin !== 0) return
      let isNoticeList = [];
      // let createdDate = new Date(company.created);
      // let baseDate = new Date();
      examinations.examinations.forEach((examination) => {
        // 確認対象企業でない場合は早期リターンして、処理を実施しない
        if (company._id !== examination.companyId) return
        keys.forEach((key) => {
          switch (key) {
            case "chairman":
              // baseDate = new Date(boardOfDirectors["chairman"]?.startDate);
              if (examination.approvalBy === boardOfDirectors["chairman"]?._id) {
                company[boardOfDirectors["chairman"]?.applyerRole] = examination.status;
                isNoticeList.push(updateIsNotice(boardOfDirectors["chairman"]?._id));
              }
              break
            case "directors":
              const directors = boardOfDirectors["directors"]
              // baseDate = new Date(directors.startDate);
              directors.forEach((director) => {
                if (examination.approvalBy === director?._id) {
                  company[director.applyerRole] = examination.status;
                  isNoticeList.push(updateIsNotice(director?._id));
                }
              })
              break
            case "auditors":
              const auditors = boardOfDirectors["auditors"]
              // baseDate = new Date(auditors.startDate);
              auditors.forEach((auditor) => {
                if (examination.approvalBy === auditor?._id) {
                  company[auditor.applyerRole] = examination.status;
                  isNoticeList.push(updateIsNotice(auditor?._id));
                }
              })
              break
            default:
              break
          }
        })
      })
      company.isNotice = isNoticeList.includes(true);
      resultList.push(company);
    })
    setList(resultList);
  }

  useEffect(() => {
    async function fetchCompanyList() {
      const resCompanyList = await authApi.getCompanyList({
        params: {
          // 入会審査が未完了 かつ 入会審査キャンセルフラグOFF かつ 退会フラグOFF のユーザに絞り込む
          conditions: {
            companyStatus: { $ne: 3 },
            isExamCancel: { $ne: true },
            isDelete: { $ne: true },
          },
          sort: { created: -1 },
        },
      });

      const companyList = resCompanyList.companys;
      setCompanyList(companyList)
      const joinMember = await joinMemberApi.getAll()
      setBoardOfDirectors(joinMember['boardOfDirectors'])
    }
    fetchCompanyList();
    fetchCancelCompanyList();
  }, []);

  useEffect(() => {
    checkExamResult(boardOfDirectors)
  }, [boardOfDirectors])

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const pageSizeOptions = [50, 100, 150];

  const changeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Box sx={{ mb: 2 }}>一次審査</Box>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={changeTab} aria-label="lab API tabs example">
            <Tab label="一覧" value="1" />
            <Tab label="キャンセル一覧" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{ mt: 1 }}>
            <div style={{ height: "max-content", width: "100%" }}>
              <DataGrid
                rows={list}
                columns={columns}
                getRowId={(list) => list._id}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={pageSizeOptions}
                autoHeight
              />
            </div>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box sx={{ mt: 1 }}>
            <div style={{ height: "max-content", width: "100%" }}>
              <DataGrid
                rows={cancelCompanyList}
                columns={cancelListColumns}
                getRowId={(list) => list._id}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={pageSizeOptions}
                autoHeight
              />
            </div>
          </Box>
        </TabPanel>
      </TabContext>
    </div>
  );
};
