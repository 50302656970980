import React, { useState } from "react";
import NCCClogo from "../../assets/imgs/NCCC_logo_symbol.png";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Select,
  List,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { setLng } from "../../redux/features/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import RemoveIcon from "@mui/icons-material/Remove";
import Collapse from "@mui/material/Collapse";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
const MENU_NUMBER = 6;

export const Header = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let storeLng = useSelector((state) => state.language.value);

  const [isOpen, setIsOpen] = useState(false);
  const ary = [...Array(MENU_NUMBER)].map(() => false);
  const [isOpenMenu, setIsOpenMenu] = React.useState(ary);

  const changeLanguage = (event) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng);
    dispatch(setLng(lng));
  };

  const toggleMenu = (num) => {
    setIsOpenMenu((prevState) => prevState.map((value, index) => (index === num ? !value : value)));
  };

  const openDefaultMailer = () => {
    const recipient = 'contact.sg@nccc.earth'; // 送信先メールアドレス
  const subject = '【問い合わせ】'; // メールの件名

  const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;

  // デフォルトのメーラーアプリを起動
  window.location.href = mailtoLink;
  }

  const menuDrawer = (
    <>
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={() => setIsOpen(false)}
        sx={{ width: "100vw", height: "100vh" }}
        color="white"
      >
        {/* app bar */}
        <Box
          sx={{ display: "flex", justifyContent: "space-between", padding: "15px 20px 0px 20px" }}
        >
          <Button color="inherit" component={Link} to="/member/login" variant="outlined">
            ログイン
          </Button>
          <div>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
        {/* menu */}
        <List
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "white",
            color: "#3a3a3a",
            p: 2,
          }}
        >
          <ListItemButton
            onClick={() => toggleMenu(0)}
            sx={{ color: "#00ff00", borderBottom: "1px solid #ebebeb", pt: 2, pb: 2 }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: "14px" }}
              primary={t("home.navi.info.title")}
            />
            {isOpenMenu[0] ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={isOpenMenu[0]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/news">
                <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "13px" }}
                    primary={t("home.navi.info.submenu.news")}
                  />
                </ListItemButton>
              </Link>
              <Link to="/news">
                <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "13px" }}
                    primary={t("home.navi.info.submenu.release")}
                  />
                </ListItemButton>
              </Link>
              <Link to="/status">
                <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "13px" }}
                    primary={t("home.navi.newsOfCC.title")}
                  />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => toggleMenu(1)}
            sx={{ color: "#00ff00", borderBottom: "1px solid #ebebeb", pt: 2, pb: 2 }}
          >
            <ListItemText
              primaryTypographyProps={{ fontSize: "14px" }}
              primary={t("home.navi.aboutNCCC.title")}
            />
            {isOpenMenu[1] ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={isOpenMenu[1]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/organization">
                <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={t("home.navi.aboutNCCC.submenu.aboutPurpose")}
                  />
                </ListItemButton>
              </Link>
              <Link to="/about">
                <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={t("home.navi.aboutNCCC.submenu.general")}
                  />
                </ListItemButton>
              </Link>
              {storeLng === "ja" ? (
                <Link to="/constitution">
                  <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "14px" }}
                      primary={t("home.navi.aboutNCCC.submenu.constitution")}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                <Link to="/comingsoon">
                  <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "14px" }}
                      primary={t("home.navi.aboutNCCC.submenu.constitution")}
                    />
                  </ListItemButton>
                </Link>
              )}
              <Link to="/members">
                <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={t("home.navi.aboutNCCC.submenu.members")}
                  />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <Link to="/accept_guide">
            <ListItemButton
              sx={{ color: "#00ff00", borderBottom: "1px solid #ebebeb", pt: 2, pb: 2 }}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "14px" }}
                primary={t("home.navi.accept.title")}
              />
            </ListItemButton>
          </Link>
        </List>
        {storeLng === "ja" ? (
          <Link to="/privacypolicy">
            <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
              <ListItemText
                primaryTypographyProps={{ fontSize: "10px" }}
                primary={t("home.navi.other.submenu.privacypolicy")}
              />
            </ListItemButton>
          </Link>
        ) : (
          <Link to="/comingsoon">
            <ListItemButton sx={{ pl: 4, color: "#3a3a3a" }}>
              <ListItemIcon>
                <ArrowCircleRightOutlinedIcon sx={{ color: "#3a3a3a" }} />
              </ListItemIcon>
              <ListItemText primary={t("home.navi.other.submenu.privacypolicy")} />
            </ListItemButton>
          </Link>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", color: "white", p: 2 }}>
          <Typography variant="caption">言語/Language</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={storeLng}
            label="言語/Language"
            onChange={changeLanguage}
            size="small"
          >
            <MenuItem value="ja">日本語</MenuItem>
            <MenuItem value="en">English</MenuItem>
          </Select>
        </Box>
      </Drawer>
    </>
  );

  return (
    <>
      <Box sx={headerWrapper}>
        <div>
          <Link to="/">
            <img src={NCCClogo} alt="NCCC logo" style={{ height: "60px" }} />
          </Link>
        </div>
        <div style={headerMenu}>
          <Link to="/organization" style={linkStyle}>
            ABOUT
          </Link>
          <Link to="/news" style={linkStyle}>
            NEWS
          </Link>
          <Link to="/comingsoon" style={linkStyle}>
            PROJECT
          </Link>
          <Link to="/observer" style={linkStyle}>
            PARTNER
          </Link>
          <Box sx={{ display: "flex", flexDirection: "column", color: "white" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={storeLng}
              label="言語/Language"
              onChange={changeLanguage}
              style={{ color: "white" }}
            >
              <MenuItem value="ja">日本語</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </Select>
          </Box>
          <Link to="/accept_guide" style={btnStyle01}>
            JOIN CONSORTIUM
          </Link>
          {/* お問い合わせ画面は未作成のためデフォルトメーラーを開くようにする暫定対応を行う */}
          {/* <Link to="/comingsoon" style={btnStyle02}>
            CONTACT
          </Link> */}
          <Link onClick={openDefaultMailer} style={btnStyle02}>
            CONTACT
          </Link>
          <Link to="/member/login" style={btnStyle02}>
            LOGIN
          </Link>
        </div>
      </Box>

      {/* mobile view */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 10px 0px 35px",
            zIndex: 1000,
          }}
        >
          {/* <Box sx={{ width: "32px" }}></Box> */}
          <Box>
            <Link to="/">
              <img src={NCCClogo} alt="logo" width="28px" />
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "60px",
              height: "40px",
              borderRadius: "32px",
              color: "#3a3a3a",
              cursor: "pointer",
              zIndex: 1000,
              backgroundColor: "#00ff00",
            }}
            onClick={() => setIsOpen(true)}
          >
            <MenuIcon></MenuIcon>
          </Box>
          {menuDrawer}
        </Box>
      </Box>
    </>
  );
};
const linkStyle = {
  color: "#ffffff",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
};

const btnStyle01 = {
  display: "inline-flex",
  height: "60px",
  padding: "20px 34px 21px 34px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  borderRadius: "10px",
  background: "#AEFF7B",
  color: "#3a3a3a",
};
const btnStyle02 = {
  display: "inline-flex",
  height: "60px",
  padding: "20px 34px 21px 34px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  borderRadius: "10px",
  background: "linear-gradient(124deg, #00695F 0%, #083A75 100%)",
  color: "#ffffff",
};
const headerWrapper = {
  width: "100%",
  position: "fixed",
  top: 0,
  display: { xs: "none", md: "flex" },
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px 120px",
  zIndex: 10,
  backdropFilter: "blur(8px)",
  backgroundColor: "rgba(95, 130, 95, 0.22)",
};

const headerMenu = {
  display: "flex",
  gap: "30px",
  color: "#ffffff",
  fontFamily: "neue-haas-grotesk-display,Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "4px",
};
