import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate() {
    const sx = {
        display: 'flex',
    }
    return (
        <Box sx={sx}>
            <CircularProgress />
        </Box>
    );
}