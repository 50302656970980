import React from "react";
import { Box, Typography } from "@mui/material";
import logo from "../assets/imgs/NCCC_logo_symbol.png";
import { useLocation } from "react-router-dom";

export const FormCompleted = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const status = urlParams.get("status");

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          padding: "40px",
          "@media only screen and (max-device-width: 480px)": {
            padding: "40px 20px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "50px",
            "@media only screen and (max-device-width: 480px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <img src={logo} alt="logo" style={{ maxWidth: "76px" }} />
        </Box>

        <Box sx={{ textAlign: "end", margin: "20px" }}>
          一般社団法人ナチュラルキャピタルクレジットコンソーシアム
          <br />
        </Box>
        {/* <Box
          sx={{
            backgroundColor: "#EAEAEA",
            textAlign: "left",
            fontSize: "36px",
            padding: "20px",
            margin: "70px 0",
            "@media only screen and (max-device-width: 480px)": {
              fontSize: "24px",
              margin: "10px 0",
            },
          }}
        >
          一般社団法人ナチュラルキャピタルクレジットコンソーシアム
          設立総会・CO2削減に取り組むコンシャス企業に向けた説明会開催のお知らせ
        </Box> */}

        <Box sx={{ margin: "40px 0px" }}>
          { !status && (
            <>
              <Typography variant="h5">送信完了しました</Typography>
              <Box sx={{ margin: "20px 0px" }}>
                受付が完了いたしました。
                <br />
                メールにて受付完了のご連絡をお送りしておりますので、ご確認ください。
              </Box>
            </>
          )}
          { status === "closed" && (
            <>
              <Box sx={{ margin: "20px 0px" }}>
                このイベントは終了いたしました。
                <br />
                たくさんの方のご参加ありがとうございました。
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
