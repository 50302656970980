import React from "react";
import branchApi from "../../../api/branchApi";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/Global.css";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid, GridColDef, GridRowsProp, GridValueGetterParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import { Grid } from "@mui/material";

export const BranchList = () => {
  const [list, setList] = useState([]);
  const [tabValue, setTabValue] = useState("1");
  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const [branches, setBranches] = useState([]);
  const location = useLocation();
  const storedUserInfo = useSelector((state) => state.user);

  const ChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  // 承認済みの支部リスト
  const approvedList = list.filter((branch) => branch.applyflg === 1);
  // 審査済みの支部リスト
  const examinationList = list.filter((branch) => branch.applyflg === 1 || branch.applyflg === 2);
  // 承認待ちの支部リスト
  const notExaminationList = list.filter((branch) => !branch.applyflg || branch.applyflg === 9);
  // 申請差し戻し中の支部リスト
  const remandList = list.filter(
    (branch) =>
      branch.applyflg === 9 &&
      (storedUserInfo.value._id === branch.branchMasterId ||
        storedUserInfo.value._id === branch.createdUser)
  );

  async function fetchBranchList() {
    const res = await branchApi.getListWithRecruitProject({
      params: {
        id: storedUserInfo.value._id,
      },
    });
    const resList = res.branchList;
    // 募集中のプロジェクトを表示するようにリストを組みなおす
    let branchList = [];
    for (let i = 0; i < resList.length; i++) {
      let branch = {};
      branch._id = resList[i]._id;
      branch.branchName = resList[i].branchName;
      branch.branchMasterName = resList[i].branchMasterName;
      branch.branchMasterId = resList[i].branchMasterId;
      branch.createdUser = resList[i].createdUser;
      branch.secretariatAddress = resList[i].secretariatAddress;
      branch.activityPurpose = resList[i].activityPurpose;
      branch.applyflg = resList[i].applyflg;
      branch.joinFlg = resList[i].joinFlg;
      branch.applyer1 = resList[i].reviewId?.boardChairman;
      branch.applyer2 = resList[i].reviewId?.director01;
      branch.applyer3 = resList[i].reviewId?.director02;
      branch.applyer4 = resList[i].reviewId?.director03;
      branch.applyer5 = resList[i].reviewId?.director04;
      branch.applyer6 = resList[i].reviewId?.director05;
      branch.auditor = resList[i].reviewId?.auditor;
      if (resList[i].reviewId) {
        branch.isNotice = resList[i].reviewId[storedUserInfo.value.directorCode] !== 0;
      }

      if (resList[i].isRecruitment === "1") {
        let projectInfo = [];
        projectInfo[0] = resList[i].projectId;
        projectInfo[1] = resList[i].projectName;
        branch.projectInfo = projectInfo;
      }
      branchList.push(branch);
    }
    //    console.log("branchList :", branchList);
    setList(branchList);
  }
  useEffect(() => {
    if (location.state && location.state.updated) {
      fetchBranchList();
      setRole(authUser.roleType);
    }
  }, [location]);

  useEffect(() => {
    fetchBranchList();
    // 権限の設定
    setRole(authUser.roleType);
  }, []);

  const approvalComponent = (params) => {
    if (params.value === 1) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #388E3C",
            borderRadius: "20px",
            color: "#ffffff",
            backgroundColor: "#388E3C",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          承認
        </Grid>
      );
    } else if (params.value === 2) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #FFCA28",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#FFCA28",
          }}
        >
          否認
        </Grid>
      );
    } else if (params.value === 9) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #FFCA28",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#FFCA28",
          }}
        >
          差戻し
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #E53935",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#E53935",
          }}
        >
          未対応
        </Grid>
      );
    }
  };
  const auditorComponent = (params) => {
    if (params.value === 1) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #388E3C",
            borderRadius: "20px",
            color: "#ffffff",
            backgroundColor: "#388E3C",
            fontSize: "12px",
          }}
        >
          異議なし
        </Grid>
      );
    } else if (params.value === 2) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #FFCA28",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#FFCA28",
          }}
        >
          異議あり
        </Grid>
      );
    } else if (params.value === 9) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #FFCA28",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#FFCA28",
          }}
        >
          差戻し
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #E53935",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#E53935",
          }}
        >
          未対応
        </Grid>
      );
    }
  };

  const approvedLColumns = [
    {
      field: "branchName",
      headerName: "支部名",
      width: 300,
      renderCell: (params) => {
        return (
          <Link to={"/member/branches/" + params.row._id} onClick={() => window.scrollTo(0, 0)}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "branchMasterName",
      headerName: "支部長名",
      width: 200,
    },
    {
      field: "secretariatAddress",
      headerName: "事務局住所",
      width: 300,
    },
    {
      field: "activityPurpose",
      headerName: "活動目標",
      width: 300,
    },
    {
      field: "_id",
      headerName: "支部参加",
      width: 150,
      renderCell: (params) => {
        const joinFlg = params.row.joinFlg;
        let statusText = "";
        let statusColor = "";
        if (joinFlg === 0) {
          statusText = "申請中";
          statusColor = "orange";
          return (
            <Box sx={{ p: 1 }}>
              <Typography component="body2" style={{ color: statusColor }}>
                {statusText}
              </Typography>
            </Box>
          );
        } else if (joinFlg === 1) {
          statusText = "参加済み";
          statusColor = "aqua";
          return (
            <Box sx={{ p: 1 }}>
              <Typography component="body2" style={{ color: statusColor }}>
                {statusText}
              </Typography>
            </Box>
          );
        } else if (joinFlg === 9) {
          return (
            <Link to={"/member/branches/joinList/"} onClick={() => window.scrollTo(0, 0)}>
              <Button
                variant="contained"
                sx={{
                  fontSize: "5px",
                  backgroundColor: "pink",
                  "&:hover": {
                    backgroundColor: "hotpink",
                    color: "white",
                  },
                }}
              >
                差戻中
              </Button>
            </Link>
          );
        } else {
          return (
            params.value &&
            !(params.row.branchMasterId === storedUserInfo.value._id) &&
            (role === RoleType.FullMember ||
              role === RoleType.GeneralMember ||
              role === RoleType.ObserverMember) && (
              <Link
                to={"/member/branches/" + params.value + "/join"}
                onClick={() => window.scrollTo(0, 0)}
              >
                <Button variant="contained" sx={{ fontSize: "5px" }}>
                  参加
                </Button>
              </Link>
            )
          );
        }
      },
    },
    {
      field: "projectInfo",
      headerName: "募集プロジェクト",
      width: 200,
      renderCell: (params) => {
        const joinFlg = params.row.joinFlg;
        if (
          !(params.row.branchMasterId === storedUserInfo.value._id) &&
          (role === RoleType.FullMember ||
            role === RoleType.GeneralMember ||
            role === RoleType.ObserverMember) &&
          params.value &&
          joinFlg === 1
        ) {
          return (
            <Link
              to={"/member/projects/" + params.value[0] + "/join"}
              onClick={() => window.scrollTo(0, 0)}
            >
              {params.value[1]}
            </Link>
          );
        } else if (params.value) {
          return <span>{params.value[1]}</span>;
        }
      },
    },
  ];

  const columns = [
    {
      field: "branchName",
      headerName: "支部名",
      width: 300,
      renderCell: (params) => {
        return (
          <Link to={"/member/branches/" + params.row._id} onClick={() => window.scrollTo(0, 0)}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "branchMasterName",
      headerName: "支部長名",
      width: 200,
    },
    {
      field: "secretariatAddress",
      headerName: "事務局住所",
      width: 300,
    },
    {
      field: "activityPurpose",
      headerName: "活動目標",
      width: 300,
    },
    {
      field: "applyflg",
      headerName: "承認ステータス",
      width: 130,
      renderCell: (params) => {
        //        console.log("applyflg -> ", params.row.applyflg);
        const applyflg = params.row.applyflg;
        let statusText = "";
        let statusColor = "";
        if (applyflg === 0) {
          statusText = "未審査";
          statusColor = "orange";
        } else if (applyflg === 1) {
          statusText = "承認";
          statusColor = "aqua";
        } else if (applyflg === 2) {
          statusText = "否認";
          statusColor = "magenta";
        }
        return (
          <Box sx={{ p: 1 }}>
            <Typography component="body2" style={{ color: statusColor }}>
              {statusText}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "projectInfo",
      headerName: "募集プロジェクト",
      width: 200,
      renderCell: (params) => {
        if (
          !(params.row.branchMasterId === storedUserInfo.value._id) &&
          (role === RoleType.FullMember ||
            role === RoleType.GeneralMember ||
            role === RoleType.ObserverMember) &&
          params.value
        ) {
          return (
            <Link
              to={"/member/projects/" + params.value[0] + "/join"}
              onClick={() => window.scrollTo(0, 0)}
            >
              {params.value[1]}
            </Link>
          );
        } else if (params.value) {
          return <span>{params.value[1]}</span>;
        }
      },
    },
  ];
  const examinationColumns = [
    {
      field: "isNotice",
      headerName: "",
      width: 5,
      renderCell: (params) => {
        return <Box>{params.value ? "" : <Badge color="warning" variant="dot"></Badge>}</Box>;
      },
    },
    {
      field: "_id",
      headerName: "詳細",
      renderCell: (params) => {
        return (
          <>
            <Link to={"/member/branches/" + params.value} onClick={() => window.scrollTo(0, 0)}>
              <Button size="small" variant="contained" sx={{ fontSize: "5px", fontWeight: 600 }}>
                詳細へ
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "branchName",
      headerName: "支部名",
      width: 250,
    },
    {
      field: "branchMasterName",
      headerName: "支部長名",
      width: 200,
    },
    {
      field: "secretariatAddress",
      headerName: "事務局住所",
      width: 250,
    },
    {
      field: "activityPurpose",
      headerName: "活動目標",
      width: 250,
    },
    {
      field: "applyflg",
      headerName: "Status",
      width: 75,
      renderCell: (params) => {
        if (params.value === 9) {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: "1px solid #FFCA28",
                borderRadius: "20px",
                color: "#ffffff",
                // backgroundColor: "#FFCA28",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#FFCA28",
              }}
            >
              差戻し
            </Grid>
          );
        } else {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: "1px solid #E53935",
                borderRadius: "20px",
                fontSize: "12px",
                color: "#E53935",
              }}
            >
              未承認
            </Grid>
          );
        }
      },
    },
    {
      field: "applyer1",
      headerName: "理事長",
      width: 75,
      renderCell: approvalComponent,
    },
    {
      field: "applyer2",
      headerName: "理事１",
      width: 75,
      renderCell: approvalComponent,
    },
    {
      field: "applyer3",
      headerName: "理事２",
      width: 75,
      renderCell: approvalComponent,
    },
    {
      field: "applyer4",
      headerName: "理事３",
      width: 75,
      renderCell: approvalComponent,
    },
    {
      field: "applyer5",
      headerName: "理事４",
      width: 75,
      renderCell: approvalComponent,
    },
    {
      field: "applyer6",
      headerName: "理事5",
      width: 75,
      renderCell: approvalComponent,
    },
    {
      field: "auditor",
      headerName: "監事",
      width: 75,
      renderCell: auditorComponent,
    },
  ];

  const remandColumns = [
    {
      field: "branchName",
      headerName: "支部名",
      width: 300,
    },
    {
      field: "branchMasterName",
      headerName: "支部長名",
      width: 200,
    },
    {
      field: "secretariatAddress",
      headerName: "事務局住所",
      width: 300,
    },
    {
      field: "activityPurpose",
      headerName: "活動目標",
      width: 300,
    },
    {
      field: "_id",
      headerName: "",
      width: 200,
      renderCell: (params) => {
        return (
          <Link
            to={"/member/branches/create?branchId=" + params.value}
            onClick={() => window.scrollTo(0, 0)}
          >
            <Button variant="contained" sx={{ fontSize: "5px" }}>
              修正
            </Button>
          </Link>
        );
      },
    },
  ];
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const pageSizeOptions = [10, 25, 100];

  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          支部一覧
        </Typography>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }}>
        {/* 承認者用の表示 */}
        {role === RoleType.BoardOfDirectors && (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                <Tab label="審査待ち" value="1" />
                <Tab label="審査済み" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={notExaminationList}
                    columns={examinationColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={examinationList}
                    columns={columns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        )}
        {/* 承認者以外の表示 */}
        {role !== RoleType.BoardOfDirectors && (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                <Tab label="支部一覧" value="1" />
                <Tab label="差し戻し対象" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={approvedList}
                    columns={approvedLColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={remandList}
                    columns={remandColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </Box>
  );
};
