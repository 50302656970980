import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import authApi from "../../../api/authApi";
import companyApi from "../../../api/companyApi";
import userApi from "../../../api/userApi";
import examinationApi from "../../../api/examinationApi";
import pdfApi from "../../../api/pdfApi";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  Backdrop,
  Box,
  Button,
  Chip,
  Collapse,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";
import docusignApi from "../../../api/docusignApi";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import chatApi from "../../../api/examinationChatApi";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CircularIndeterminate from "../../../components/common/Loading";
import mailApi from "../../../api/mailApi";

export const PrimaryPassDetails = (props) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.value);

  let [approval1, setApproval1] = useState(2);
  let [approval2, setApproval2] = useState(2);
  let [approval3, setApproval3] = useState(2);
  let [approval4, setApproval4] = useState(2);
  let [approval5, setApproval5] = useState(2);
  let [approval6, setApproval6] = useState(2);
  let [approval7, setApproval7] = useState(2);
  let [approval8, setApproval8] = useState(2);
  let [isDisableApprovalButton, setIsDisableApprovalButton] = useState(false);
  let [isPaymentGuideChecked, setIsPaymentGuideChecked] = useState(false);
  let [isAllChecked, setIsAllChecked] = useState(false);
  let [isPaymentCompleteChecked, setIsPaymentCompleteChecked] = useState(false);
  let [isExecuteChecked, setIsExecuteChecked] = useState(false);
  let [examinations, setExaminations] = useState([]);
  let [approvalButtonName, setApprovalButtonName] = useState("");
  let [denialButtonName, setDenialButtonName] = useState("");
  let [reDisplay, setReDisplay] = useState(false);
  let [recordOfProceedings, setRecordOfProceedings] = useState("");
  // 会員一覧から会員詳細に遷移したときに承認/否認などの欄が一瞬表示されてしまうので、
  // 初期値を 3 (表示なしのステータス)にする暫定対応
  let [companyStatus, setCompanyStatus] = useState(3);
  const [dispClaimDay, setDispClaimDay] = useState("");
  const [dispApplicationDay, setDispApplicationDay] = useState("");
  const [dispJoinDay, setDispJoinDay] = useState("");
  const [isDisplayExamPanel, setIsDisplayExamPanel] = useState(false);
  const authUser = useAuthUserContext();
  const { slug } = useParams();

  let [list, setList] = useState([]);
  let [user, setUser] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [comapnyData, setCompanyData] = useState(null);

  const [chatList, setChatList] = React.useState([]);
  const MENU_NUMBER = 1000;
  const ary = [...Array(MENU_NUMBER)].map(() => false);
  const [isOpenMenu, setIsOpenMenu] = React.useState(ary);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [mainTopicId, setMainTopicId] = React.useState("");
  const [beforeSenderId, setBeforeSenderId] = React.useState("");
  const [isReply, setIsReply] = React.useState(false);
  const [isUpdateChat, setIsUpadteChat] = React.useState(false);
  const [text, setText] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const storedUserInfo = useSelector((state) => state.user);

  const deleteTopic = async (chatId) => {
    var result = window.confirm("このトピックを削除しますか？※削除後は復元できません");

    if (result) {
      const req = {
        id: chatId,
      };
      const response = await chatApi.deleteById(req);
      if (response === "Delete Success") {
        setIsReply(false);
        setIsUpadteChat(true);
        alert("削除しました。");
      }
    }
  };

  const chatSubmit = async () => {
    setLoading(true);
    if (text === "" || text === null) {
      alert("メッセージを入力してください。");
      return;
    }
    const req = {
      pkId: list._id,
      senderId: storedUserInfo.value._id,
      senderName: storedUserInfo.value.username || storedUserInfo.value.name,
      mainTopicId: isReply ? mainTopicId : "",
      chatType: isReply ? 1 : 0,
      text: text,
    };
    const res = await chatApi.create(req);
    setText("");
    setLoading(false);
    setIsReply(setIsReply(false));
    alert("コメントを記入しました。");
    setIsUpadteChat(true);
  };

  const toggleMenu = async (num, reply) => {
    if (reply.length >= 1) {
      for await (let item of reply) {
        if (item.readUsers) {
          await chatApi.update({
            id: item._id,
            readUsers: [...item.readUsers, storedUserInfo.value._id],
          });
        } else {
          await chatApi.update({ id: item._id, readUsers: [storedUserInfo.value._id] });
        }
      }
    }
    setIsOpenMenu((prevState) => prevState.map((value, index) => (index === num ? !value : value)));
  };
  const replyChat = (id, senderId) => {
    setMainTopicId(id);
    setBeforeSenderId(senderId);
    setIsReply(isReply ? false : true);
  };

  const getChatList = async () => {
    const chatRes = await chatApi.getByPkId({ pkId: slug });
    const chatList = chatRes.chats;
    let resultChatList = [];
    for (let i = 0; i < chatList.length; i++) {
      if (chatList[i].chatType === 0) {
        const list = chatList.reduce((acc, cur) => {
          if (cur.mainTopicId === chatList[i]._id) {
            acc.push(cur);
          }
          return acc;
        }, []);
        const notReadChat = list.filter((item) => item.readUsers.includes(user._id) === false);
        resultChatList.push({ ...chatList[i], reply: list, notReadChat: notReadChat });
      }
    }
    setChatList(resultChatList);
    setIsUpadteChat(false);
  };
  useEffect(() => {
    if (isUpdateChat) {
      getChatList();
    }
  }, [isUpdateChat]);

  const handleToDate = (date) => {
    date = new Date(date);
    if (date.getMinutes() < 10) {
      date =
        date.getFullYear() +
        "/" +
        ((date.getMonth() % 12) + 1) +
        "/" +
        date.getDate() +
        " " +
        date.getHours() +
        ":0" +
        date.getMinutes();
    } else {
      date =
        date.getFullYear() +
        "/" +
        ((date.getMonth() % 12) + 1) +
        "/" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes();
    }

    return date;
  };

  const handleIsPaymentGuideChecked = (event) => {
    setIsPaymentGuideChecked(event.target.checked);
  };
  const handleIsPaymentCompleteChecked = (event) => {
    setIsPaymentCompleteChecked(event.target.checked);
  };
  const handleIsAllChecked = (event) => {
    setIsAllChecked(event.target.checked);
  };
  const handleIsExecuteChecked = (event) => {
    setIsExecuteChecked(event.target.checked);
  };
  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  // 理事が追加になったときの必要承認数を管理する
  // 基本的には日付条件のみで足りる想定
  const requiredApprovalNum = () => {
    if (dayjs(list.created) < dayjs("2023/08/31")) {
      return 7;
    } else {
      return 8;
    }
  };

  const approvalAction = async () => {
    setProcessing(true)
    const requestData = {
      companyId: slug,
      approvalBy: storedUserInfo.value._id,
      status: 0, // 0: 承認, 1: 否認
      examinationCode: 0, // 0: 入会受付審査
    };
    const res = await examinationApi.register(requestData);

    // approvalの状態チェック
    const fetchExamination = await examinationApi.getWithQuery({
      query: { companyId: slug, examinationCode: 0 },
    });

    let isAllApproved = false;
    let approvedCount = 0;
    if (fetchExamination.length === requiredApprovalNum()) {
      // TODO: 上の状態チェックでstatusも条件に加えたらこのfor文いらないのでは？
      for (let i = 0; i < fetchExamination.length; i++) {
        if (fetchExamination[i].status === 0) {
          approvedCount++;
        }
      }
      if (approvedCount === fetchExamination.length) {
        isAllApproved = true;
      }
    }
    if (isAllApproved) {
      // 議事録　出力
      const pdfMakeRequestData = {
        companyId: slug,
        documentId: "1",
      };
      let pdfMakeOutputUrl = "";
      try {
        const res = await pdfApi.make(pdfMakeRequestData);
        pdfMakeOutputUrl = res.url;

        // システム承認のみでOKになったためDocuSignには送らない
        // const docResult = await docusignApi.sendSignature({
        //   pdfMakeOutputUrl: pdfMakeOutputUrl,
        //   requiredApprovalNum: requiredApprovalNum(),
        // });
        // console.log(docResult);
      } catch (err) {
        console.error(err);
      }

      // update company status
      const updateData = {
        companyId: slug,
        recordOfProceedingsUrl: pdfMakeOutputUrl,
      };
      await authApi.approval(updateData);

      // メール送信:【NCCC】入会申請WEBフォームのご連携のお知らせ
      const requestData = {
        userId: user._id,
        companyId: slug,
        // TODO 固定値を後で定数クラス等を作成して管理するか検討
        mailid: 3,
      };
      try {
        const res = await authApi.sendMail(requestData);
      } catch (err) {
        console.error(err);
      }
    }
    // setExaminations(fetchExamination);
    alert("承認しました。");
    setReDisplay(true);
    setProcessing(false)
  };
  const denialAction = async () => {
    setProcessing(true)
    const requestData = {
      companyId: slug,
      approvalBy: storedUserInfo.value._id,
      status: 1, // 0: 承認, 1: 否認
      examinationCode: 0, // 0: 入会受付審査
    };
    const res = await examinationApi.register(requestData);
    alert("否認しました。");
    setReDisplay(true);
    setProcessing(false)
  };

  const sendInvoiceMail = async () => {
    setProcessing(true)
    const pdfMakeRequestData = {
      companyId: slug,
      // documentId: Number(storedUserInfo.value.membership) === 0 ? "2" : "3",
      documentId: user.membership === "fullMember" ? "2" : "3",
    };
    let pdfMakeOutputUrl = "";
    try {
      const res = await pdfApi.make(pdfMakeRequestData);
      pdfMakeOutputUrl = res.url;
      // console.log(docResult);
      // メール送信:【NCCC】【NCCC】会費振込先のご案内
      const requestData = {
        userId: user._id,
        companyId: slug,
        // TODO 固定値を後で定数クラス等を作成して管理するか検討
        mailid: 4,
        urlLink: pdfMakeOutputUrl,
      };
      const updateData = {
        companyId: slug,
        claimDay: new Date(),
        companyStatus: 2,
      };
      await companyApi.update(updateData);
      const res2 = await authApi.sendMail(requestData);
      alert("振込案内を送信しました。");
    } catch (err) {
      console.error(err);
      alert("振込案内送信時にエラーが発生しました。");
    }
    setProcessing(false)
  };

  const sendMainForm = async () => {
    setProcessing(true)
    // メール送信:【NCCC】入会申請WEBフォームのご連携のお知らせ
    const requestData = {
      userId: user._id,
      companyId: slug,
      // TODO 固定値を後で定数クラス等を作成して管理するか検討
      mailid: 3,
    };
    const updateData = {
      companyId: slug,
      claimDay: new Date(),
      companyStatus: 1,
    };
    try {
      await companyApi.update(updateData);
      const res = await authApi.sendMail(requestData);
    } catch (err) {
      console.error(err);
    }
    finally {
      setProcessing(false)
    }
  }

  const sendCheckPayment = async () => {
    setProcessing(true)
    // メール送信:【NCCC】入金確認のお知らせ
    const requestData = {
      userId: user._id,
      companyId: slug,
      // TODO 固定値を後で定数クラス等を作成して管理するか検討
      mailid: 6,
    };
    const updateData = {
      companyId: slug,
      joinDay: new Date(),
      companyStatus: 3,
    };
    await companyApi.update(updateData);

    // メール送信処理の中に入金完了フラグをtrueにする処理あり
    const res2 = await authApi.sendMail(requestData);

    alert("入会完了のご案内を送信しました。");
    dispatch(setUpdateBadgeFlg(true));
    setProcessing(false)
  };

  // 入会審査キャンセル処理
  const examCancelExecute = async () => {
    setProcessing(true)
    handleCloseDialog()
    const updateData = {
      companyId: slug,
      cancelDay: new Date(),
      cancelReason: reason,
      companyStatus: 90,
      isExamCancel: true,
    };
    await companyApi.update(updateData);

    // // メール送信:【NCCC】入金確認のお知らせ
    // const requestData = {
    //   userId: user._id,
    //   companyId: slug,
    //   // TODO 固定値を後で定数クラス等を作成して管理するか検討
    //   mailid: 6,
    // };
    // // メール送信処理の中に入金完了フラグをtrueにする処理あり
    // const res2 = await authApi.sendMail(requestData);

    fetchCompanyList()
    alert("入会審査キャンセルが完了しました。");
    dispatch(setUpdateBadgeFlg(true));
    setProcessing(false)
  };

  // 退会処理
  const deleteExecute = async () => {
    setProcessing(true)
    handleCloseDialog()
    const updateData = {
      companyId: slug,
      deleteDay: new Date(),
      deleteReason: reason,
      companyStatus: 91,
      isDelete: true,
    };
    await companyApi.update(updateData);

    // // メール送信:【NCCC】入金確認のお知らせ
    // const requestData = {
    //   userId: user._id,
    //   companyId: slug,
    //   // TODO 固定値を後で定数クラス等を作成して管理するか検討
    //   mailid: 6,
    // };
    // // メール送信処理の中に入金完了フラグをtrueにする処理あり
    // const res2 = await authApi.sendMail(requestData);

    fetchCompanyList()
    alert("退会処理が完了しました。");
    dispatch(setUpdateBadgeFlg(true));
    setProcessing(false)
  };

  const fetchCompanyList = async () => {
    const res = await authApi.getCompany({ id: slug });
    // 承認済みの場合、議事録出力先のパスを表示する
    if (res.company.recordOfProceedingsUrl) {
      setRecordOfProceedings(res.company.recordOfProceedingsUrl);
    }
    // 承認済みの場合、振込案内・入会完了のご案内のボタンを活性にする

    // ユーザ審査がすべて完了している場合、入会審査, 振込案内, 入金完了案内を非表示にする
    // ここで true がsetされることによって、レンダー時に!isApplyで非表示制御される
    setCompanyStatus(res.company.companyStatus);
    setDispApplicationDay((res.company.applicationDay));
    setDispClaimDay(res.company.claimDay);
    setDispJoinDay(res.company.joinDay);
    setCompanyData(res.company)

    const fetchedUser = await authApi.getUser({
      params: { companyId: slug },
    });

    // 理事メンバーの取得
    const fetchDirectors = await userApi.getDirectors().then((docs) => {
      const directorObj = docs.directors.reduce((doc, obj) => {
        doc[obj.directorCode] = obj;
        return doc;
      }, {});
      return directorObj;
    });
    setDirectors(fetchDirectors);

    const examinations = await examinationApi.getWithQuery({
      query: { companyId: res.company._id, examinationCode: 0 },
    });

    setExaminations(examinations);
    for (let i = 0; i < examinations.length; i++) {
      if (examinations[i].examinationCode === 0) {
        // 0: 入会受付審査
        // console.log(examinations[i].approvalBy, BOARD_CHAIRMAN_ID);

        if (examinations[i].approvalBy === storedUserInfo.value._id) {
          setIsDisableApprovalButton(true);
        }
        if (examinations[i].approvalBy === directors["boardChairman"]?._id) {
          setApproval1(examinations[i].status);
        } else if (examinations[i].approvalBy === directors["director01"]?._id) {
          setApproval2(examinations[i].status);
        } else if (examinations[i].approvalBy === directors["director02"]?._id) {
          setApproval3(examinations[i].status);
        } else if (examinations[i].approvalBy === directors["director03"]?._id) {
          setApproval4(examinations[i].status);
        } else if (examinations[i].approvalBy === directors["director04"]?._id) {
          setApproval5(examinations[i].status);
        } else if (examinations[i].approvalBy === directors["director05"]?._id) {
          setApproval7(examinations[i].status);
        } else if (examinations[i].approvalBy === directors["director06"]?._id) {
          setApproval8(examinations[i].status);
        } else if (examinations[i].approvalBy === directors["auditor"]?._id) {
          setApproval6(examinations[i].status);
        }
      }
    }
    getChatList();
    setList(res.company);
    setUser(fetchedUser.user);
    // Stateへのsetとの時間差で承認ボタン欄がちらつくので、取得値を引数として渡して処理する
    isOtherExcludeCondition(fetchDirectors, res.company);
  };

  const isOtherExcludeCondition = (directorList, company) => {
    if (
      userState._id === directorList["director06"]?._id &&
      dayjs(company.created) < dayjs("2023/08/31")
    ) {
      setIsDisplayExamPanel(false);
    } else {
      setIsDisplayExamPanel(true);
    }
  };

  useEffect(() => {
    fetchCompanyList();
  }, []);

  useEffect(() => {
    fetchCompanyList();

    // 理事か監事かで表示するボタン名を出し分ける
    if (storedUserInfo.value.isAuditor === 1) {
      setApprovalButtonName("異議なし");
      setDenialButtonName("異議あり");
    } else {
      setApprovalButtonName("承認");
      setDenialButtonName("否認");
    }
  }, [reDisplay]);

  const getMemberShipText = (list) => {
    switch (list.membership) {
      case "fullMember":
        return "正会員";
      case "generalMember":
        return "一般会員";
      case "observerMember":
        return "オブザーバー";
      default:
        return "";
    }
  };

  return (
    <>
      詳細情報
      {[90].includes(companyStatus) &&
        <div>
          <Typography variant="h5" color="red">入会審査キャンセル済み</Typography>
          <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            <Typography variant="h7" color="red">キャンセル理由：</Typography>
            <Typography variant="h7" color="red">{comapnyData.cancelReason}</Typography>
          </Box>
        </div>
      }
      {[91].includes(companyStatus) &&
        <div>
          <Typography variant="h5" color="red">退会済み</Typography>
          <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            <Typography variant="h7" color="red">退会理由：</Typography>
            <Typography variant="h7" color="red">{comapnyData.deleteReason}</Typography>
          </Box>
        </div>
      }
      <Box sx={{ display: "flex", gap: "30px", flexDirection: "row", mt: 4 }}>
        {[0, 1, 2].includes(companyStatus) &&
          [RoleType.BoardOfDirectors].includes(authUser?.roleType) &&
          isDisplayExamPanel && (
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
              <Card sx={{ backgroundColor: "#ffffff" }}>
                <CardContent>
                  <Typography variant="h6">入会審査結果</Typography>
                  <Typography variant="caption">入会審査の結果を確認してください</Typography>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Button
                      variant="contained"
                      onClick={approvalAction}
                      disabled={isDisableApprovalButton}
                    >
                      {approvalButtonName}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={denialAction}
                      disabled={isDisableApprovalButton}
                    >
                      {denialButtonName}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          )}
          {/* 理事が存在しないので、オブザーバと同様に事務局承認のみで対応できるようにする */}
        {/* {list.membership !== "observerMember" &&
          [0, 1, 2].includes(companyStatus) &&
          [RoleType.Admin].includes(authUser?.roleType) && (
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
              <Card sx={{ backgroundColor: "#ffffff" }}>
                <CardContent>
                  <Typography variant="h6">振込案内</Typography>
                  <Typography variant="caption">
                    請求書が添付された振込のご案内メールを送信します
                  </Typography>
                  <Box sx={{ mt: 1, mb: 1, pl: 1 }}>
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          size="small"
                          checked={isPaymentGuideChecked}
                          onChange={handleIsPaymentGuideChecked}
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={!(companyStatus === 1 || companyStatus === 2)}
                        />
                      }
                      label="印鑑証明・謄本の確認"
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Button
                      variant="contained"
                      onClick={sendInvoiceMail}
                      disabled={
                        !isPaymentGuideChecked || !(companyStatus === 1 || companyStatus === 2)
                      }
                    >
                      送信する
                    </Button>
                    {dispClaimDay &&
                      <Box
                        sx={{ fontSize: "12px", pt: "5px" }}
                      >
                        前回送信日時: {handleToDate(dispClaimDay)}
                      </Box>
                    }
                  </Box>
                </CardContent>
              </Card>
            </Box>
          )}
        {list.membership !== "observerMember" &&
          [0, 1, 2].includes(companyStatus) &&
          [RoleType.Admin].includes(authUser?.roleType) && (
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
              <Card sx={{ backgroundColor: "#ffffff" }}>
                <CardContent>
                  <Typography variant="h6">入会完了のご案内送信</Typography>
                  <Typography variant="caption">
                    ご入金を確認したら、入金確認ボタンを押してください
                  </Typography>
                  <Box sx={{ mt: 1, mb: 1, pl: 1 }}>
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          size="small"
                          checked={isPaymentCompleteChecked}
                          onChange={handleIsPaymentCompleteChecked}
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={list.isCompletePayment || companyStatus !== 2}
                        />
                      }
                      label="入金チェック"
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Button
                      variant="contained"
                      onClick={sendCheckPayment}
                      disabled={
                        list.isCompletePayment || !isPaymentCompleteChecked || companyStatus !== 2
                      }
                    >
                      入金確認
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          )}*/}
          {/* 理事が存在しない場合の事務局手動対応（理事全承認時の代替操作） */}
          {
            [0, 1, 2].includes(companyStatus) &&
            [RoleType.Admin].includes(authUser?.roleType) && (
              <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
                <Card sx={{ backgroundColor: "#ffffff" }}>
                  <CardContent>
                    <Typography variant="h6">本申込フォームのご案内送信</Typography>
                    <Typography variant="caption">本申込フォームのご案内メールを送信します</Typography>
                    <Box sx={{ mt: 1, mb: 1, pl: 1 }}>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            size="small"
                            checked={isAllChecked}
                            onChange={handleIsAllChecked}
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={companyStatus !== 0}
                          />
                        }
                        label="事務局の入会承認"
                      />
                    </Box>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Button
                        variant="contained"
                        onClick={sendMainForm}
                        disabled={
                          !isAllChecked || companyStatus !== 0
                        }
                      >
                        送信する
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )
          }
        {//list.membership === "observerMember" &&
          [0, 1, 2].includes(companyStatus) &&
          [RoleType.Admin].includes(authUser?.roleType) && (
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
              <Card sx={{ backgroundColor: "#ffffff" }}>
                <CardContent>
                  <Typography variant="h6">入会完了のご案内送信</Typography>
                  <Typography variant="caption">入会完了のご案内メールを送信します</Typography>
                  <Box sx={{ mt: 1, mb: 1, pl: 1 }}>
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          size="small"
                          checked={isPaymentGuideChecked}
                          onChange={handleIsPaymentGuideChecked}
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={!(companyStatus === 1 || companyStatus === 2)}
                        />
                      }
                      // label="印鑑証明・謄本の確認"
                      label="事務局の入会最終承認"
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Button
                      variant="contained"
                      onClick={sendCheckPayment}
                      disabled={
                        !isPaymentGuideChecked || !(companyStatus === 1 || companyStatus === 2)
                      }
                    >
                      送信する
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          )}
        {[0, 1, 2, 3].includes(companyStatus) &&
          [RoleType.Admin].includes(authUser?.roleType) && (
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
              <Card sx={{ backgroundColor: "#ffffff" }}>
                <CardContent>
                  <Typography variant="h6">
                    { companyStatus === 3 ? "退会手続き" : "審査キャンセル手続き" }
                  </Typography>
                  <Typography variant="caption">
                    {
                      companyStatus === 3
                      ? "ユーザの退会処理を行います。こちらの操作は取り消せません。"
                      : "審査をキャンセルします。こちらの操作は取り消せません。"
                    }
                  </Typography>
                  <Box sx={{ mt: 1, mb: 1, pl: 1 }}>
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          size="small"
                          checked={isExecuteChecked}
                          onChange={handleIsExecuteChecked}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="手続きを実施する"
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleOpenDialog}
                      disabled={!isExecuteChecked}
                    >
                      { companyStatus === 3 ? "退会" : "審査キャンセル" }
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          )
        }
      </Box>
      <Box sx={{ display: "flex", gap: "20px", mt: 5, flexWrap: "wrap" }}>
        <Card sx={{ minWidth: 400, maxWidth: 500, padding: 1, backgroundColor: "#ffffff" }}>
          <CardContent>
            <Box sx={{ mb: 3 }}>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                申請情報
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Typography variant="body2" color="text.secondary">
                    入会受付日時
                  </Typography>
                  {handleToDate(list.created)}
              </Box>
              {dispApplicationDay &&
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Typography variant="body2" color="text.secondary">
                    {/* 文字数合わせの空白（直したい） */}
                    本申込日時　
                  </Typography>
                  {handleToDate(dispApplicationDay)}
                </Box>
              }
              {dispJoinDay &&
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px", mb: "5px" }}>
                  <Typography variant="body2" color="text.secondary">
                    {/* 文字数合わせの空白（直したい） */}
                    入会日時　　
                  </Typography>
                  {handleToDate(dispJoinDay)}
                </Box>
              }

              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant="body2" color="text.secondary">
                  {companyStatus !== 3 ? "希望会員種別" : "会員種別"}
                </Typography>
                {getMemberShipText(list)}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    申請動機
                  </Typography>
                </Box>
                {/* TODO:　マスターから引いてくる？ */}
                <Box sx={{ padding: 1, fontWeight: "400", fontSize: "12px" }}>
                  {list.applicationReason}
                </Box>
              </Box>
            </Box>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              会社情報
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography variant="body2" color="text.secondary">
                会社名
              </Typography>
              {list.groupName}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {/* 文字数合わせの空白（直したい） */}
                  住所　
                </Typography>
              </Box>
              <Box>{list.address}</Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {/* 文字数合わせの空白（直したい） */}
                  URL　
                </Typography>
              </Box>
              <Box>
                <a href={list.siteUrl} target="_blank" rel="noreferrer">
                  {list.siteUrl}
                </a>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {/* 文字数合わせの空白（直したい） */}
                  業種　
                </Typography>
              </Box>
              <Box>{list.businessType}</Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  資本金
                </Typography>
              </Box>
              <Box>{Number(list.capital).toLocaleString() + " 円"}</Box>
            </Box>
            <Box sx={{ display: "flex",alignItems: "center", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {/* 文字数合わせの空白（直したい） */}
                  {list.annualTurnover ? "年商　" : ""}
                </Typography>
              </Box>
              <Box>
                {list.annualTurnover ? Number(list.annualTurnover).toLocaleString() + "円" : ""}
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {/* TODO: 本番運用が開始したら、条件に当てはまるデータが存在しないことを確認して削除する Issue#163
                    list.certificatesDocは今後登録されない項目となる*/}
                  {(list.certificatesDoc && (list.certificatesDoc[0] || list.certificatesDoc[1])) ||
                  list.businessCertificates ||
                  list.sealCertificates ||
                  list.registerCertificates
                    ? "許可業種証明書 / 登記簿謄本 / 印鑑証明書"
                    : ""}
                </Typography>
              </Box>
              <box>
                <Box sx={{ fontSize: "12px" }}>
                  {list.businessCertificates ? "許可業種証明書:" : ""}
                  {list.businessCertificates && (
                    <a href={list.businessCertificates} target="_blank" rel="noreferrer">
                      {/* {list.businessCertificates} */}
                      添付ファイル
                    </a>
                  )}
                </Box>
                <Box sx={{ fontSize: "12px" }}>
                  {list.registerCertificates ? "登記簿謄本:" : ""}
                  {list.registerCertificates && (
                    <a href={list.registerCertificates} target="_blank" rel="noreferrer">
                      {/* {list.registerCertificates} */}
                      添付ファイル
                    </a>
                  )}
                </Box>
                <Box sx={{ fontSize: "12px" }}>
                  {list.sealCertificates ? "印鑑証明書:" : ""}
                  {list.sealCertificates && (
                    <a href={list.sealCertificates} target="_blank" rel="noreferrer">
                      {/* {list.sealCertificates} */}
                      添付ファイル
                    </a>
                  )}
                </Box>
              </box>
              {/* TODO: 本番運用が開始したら、条件に当てはまるデータが存在しないことを確認して削除する Issue#163
                    list.certificatesDocは今後登録されない項目となる*/}
              {/* <box>
                <Box sx={{ fontSize: "8px" }}>
                  {!list.businessCertificates && list.certificatesDoc && list.certificatesDoc[0]
                    ? "許可業種証明書:"
                    : ""}
                  {!list.businessCertificates &&
                    list.certificatesDoc &&
                    list.certificatesDoc[0] && (
                      <a href={list.certificatesDoc[0]} target="_blank">
                        {list.certificatesDoc[0]}
                      </a>
                    )}
                </Box>
                <Box sx={{ fontSize: "8px" }}>
                  {!list.sealCertificates && list.certificatesDoc && list.certificatesDoc[1]
                    ? "謄本・印鑑証明書:"
                    : ""}
                  {!list.sealCertificates && list.certificatesDoc && list.certificatesDoc[1] && (
                    <a href={list.certificatesDoc[1]} target="_blank">
                      {list.certificatesDoc[1]}
                    </a>
                  )}
                </Box>
              </box> */}
              {/*
              <Box>
                {list.certificatesDoc?.map((item, index) => (
                  <Box sx={{ fontSize: "8px" }}>
                    {index + 1}:
                    <a href={item} target="_blank">
                      {item}
                    </a>
                  </Box>
                ))}
              </Box>
                */}
            </Box>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 400, maxWidth: 500, padding: 1, backgroundColor: "#ffffff" }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              代表者情報
            </Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  代表者名
                </Typography>
              </Box>
              <Box>{list.daihyo}</Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  代表者名（カナ）
                </Typography>
              </Box>
              <Box>{list.daihyoKana}</Box>
            </Box>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 400, maxWidth: 500, padding: 1, backgroundColor: "#ffffff" }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              担当者情報
            </Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  担当者名
                </Typography>
              </Box>
              <Box>{user && user.username && <span>{user.username}</span>}</Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  担当者名(カナ)
                </Typography>
              </Box>
              <Box>{user && user.usernameKana && <span>{user.usernameKana}</span>}</Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  役職
                </Typography>
              </Box>
              <Box>{user && user.role && <span>{user.role}</span>}</Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Email
                </Typography>
              </Box>
              <Box>{user && user.email && <span>{user.email}</span>}</Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  名刺
                </Typography>
              </Box>
              <Box>
                <img src={list.namecardUrl} style={{ maxWidth: "350px", objectFit: "cover" }}></img>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ display: "flex", gap: "20px", mt: 5, flexWrap: "wrap" }}>
        <Card sx={{ minWidth: 400, maxWidth: 1000, padding: 1, backgroundColor: "#ffffff" }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              ログ
            </Typography>
            {recordOfProceedings && (
              <Typography
                component="a"
                href={recordOfProceedings}
                target="blank"
                color="primary"
                sx={{ mb: 1.5 }}
              >
                入会審査議事録
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "left",
                justifyContent: "center",
                flexDirection: "column",
                fontSize: "12px",
              }}
            >
              {examinations.map((exm) => {
                return (
                  <div key={exm}>
                    <span>{handleToDate(exm.created)}:</span>
                    <span
                      style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        color: "#000000",
                        fontWeight: "bold",
                      }}
                    >
                      {exm.approvalBy === directors["boardChairman"]?._id
                        ? "理事長"
                        : exm.approvalBy === directors["director01"]?._id
                        ? "理事1"
                        : exm.approvalBy === directors["director02"]?._id
                        ? "理事2"
                        : exm.approvalBy === directors["director03"]?._id
                        ? "理事3"
                        : exm.approvalBy === directors["director04"]?._id
                        ? "理事4"
                        : exm.approvalBy === directors["director05"]?._id
                        ? "理事5"
                        : exm.approvalBy === directors["director06"]?._id
                        ? "理事6"
                        : exm.approvalBy === directors["auditor"]?._id
                        ? "監事"
                        : "理事長"}
                    </span>
                    が
                    <span
                      style={{
                        marginLeft: "5px",
                        color: exm.approval ? "#ff1744" : "#348F41",
                        fontWeight: "bold",
                      }}
                    >
                      {exm.status === 0 ? "承認" : "否認"}
                    </span>
                    しました。
                  </div>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ mt: 2 }}>
        <div>コメント欄</div>
        <Grid container>
          <Grid item xs={9}>
            <Box sx={{ p: 3, maxHeight: "500px", overflowY: "auto" }}>
              {chatList?.map((chat, index) => (
                <>
                  <ListItemButton onClick={() => toggleMenu(index, chat.reply)}>
                    {chat.notReadChat.length >= 1 && (
                      <Badge
                        color="warning"
                        sx={{ mr: 2 }}
                        badgeContent={chat.notReadChat.length}
                      />
                    )}

                    <ListItemText
                      primary={chat.text}
                      secondary={
                        "投稿者:" +
                        chat.senderName +
                        " / 投稿日:" +
                        dayjs(chat.created)?.format("YYYY/MM/DD HH:mm") +
                        " / 返信数:" +
                        chat.reply.length
                      }
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    />
                    {companyStatus !== 3 && (
                      <IconButton
                        color="secondary"
                        onClick={() => replyChat(chat._id, chat.senderId)}
                      >
                        <ReplyIcon />
                      </IconButton>
                    )}
                    {chat.reply.length <= 0 && companyStatus !== 3 && (
                      <IconButton color="danger" onClick={() => deleteTopic(chat._id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}

                    {isOpenMenu[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  <Collapse
                    in={isOpenMenu[index]}
                    timeout="auto"
                    unmountOnExit
                    sx={{ pt: 1, pb: 2, pl: 3 }}
                  >
                    {chat.reply.map((reply) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            pr: 3,
                          }}
                        >
                          {chat.notReadChat.includes(user._id) && (
                            <>
                              <Badge color="warning" variant="dot" sx={{ mr: 2 }} />
                            </>
                          )}
                          <ListItemText
                            primary={reply.text}
                            secondary={
                              "投稿者:" +
                              reply.senderName +
                              " / 投稿日:" +
                              dayjs(reply.created)?.format("YYYY/MM/DD HH:mm")
                            }
                            sx={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                          />
                          {/* <IconButton
                              color="secondary"
                              onClick={() => {
                                setIsReply(isReply ? false : true);
                              }}
                            >
                              <ReplyIcon />
                            </IconButton> */}
                        </Box>
                      </>
                    ))}
                  </Collapse>
                </>
              ))}
            </Box>
            <Divider />
            <Grid container style={{ padding: "20px" }}>
              <Grid item xs={11}>
                <TextField
                  id="chatText"
                  label={isReply ? "返信内容の入力" : "メイントピックを作成"}
                  fullWidth
                  multiline
                  rows={4}
                  value={text}
                  disabled={companyStatus === 3}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </Grid>
              <Grid xs={1} align="right">
                {loading ? (
                  <Box sx={{ ml: 5 }}>
                    <CircularIndeterminate />
                  </Box>
                ) : (
                  <IconButton color="secondary" onClick={chatSubmit} disabled={companyStatus === 3}>
                    <SendIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={isOpenDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{ companyStatus === 3 ? "退会手続きを実施します" : "審査キャンセル手続きを実施します" }</DialogTitle>
        <DialogContent
          sx={{ bgcolor: "white" }}
        >
          <DialogContentText>
            <br />
            この操作は取り戻しができません。<br />
            <b>{list.groupName}</b>に対して、{ companyStatus === 3 ? "退会" : "審査キャンセル" }処理を実行します。<br />
            以下のフォームに経緯・理由を入力し、実行ボタンを押してください。
          </DialogContentText>
                <TextField
                  margin="dense"
                  id="reason"
                  type="text"
                  label="経緯・理由"
                  fullWidth
                  multiline
                  variant="standard"
                  required
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
        </DialogContent>
        <DialogActions
          sx={{ bgcolor: "white" }}
        >
          <Button onClick={handleCloseDialog}>戻る</Button>
          <Button
            onClick={companyStatus === 3 ? deleteExecute : examCancelExecute}
            disabled={reason === ""}
          >
            実行
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
