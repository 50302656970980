import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: "ja" };

export const languageSlice = createSlice({
    name: "lng",
    initialState,
    reducers: {
        setLng: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setLng } = languageSlice.actions;
export default languageSlice.reducer;
