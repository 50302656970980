import React from "react";
import branchApi from "../../../api/branchApi";
import mailApi from "../../../api/mailApi";
import chatApi from "../../../api/examinationChatApi";
import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
//import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/Global.css";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { ExaminationChat } from "../examinationCommon";
const itemHeaderStyle = {
  color: "#000000",
};

const itemValueStyle = {
  color: "#000000",
  marginLeft: "5px",
  fontWeight: "bold",
};

export const BranchApply = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [history, setHistory] = useState([]);
  const { id } = useParams();
  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const [commentErrText, setCommentErrText] = useState("");
  const userInfo = useSelector((state) => state.user);

  const [isDisabled, setIsDisabled] = useState(false);
  const handleSubmit = async (buttonValue) => {
    let examinationForm = document.getElementById("examinationForm");
    const data = new FormData(examinationForm);
    const approvalComment = data.get("approvalComment");
    setCommentErrText("");
    if (buttonValue === 9 && approvalComment === "") {
      setCommentErrText("差し戻しコメントを入力してください。");
      return;
    }

    let comment = "";
    if (buttonValue === 1) {
      comment = "承認";
    } else if (buttonValue === 2) {
      comment = "否認";
    } else {
      comment = "差し戻し";
    }

    if (window.confirm(comment + "します。よろしいですか？")) {
      const requestData = {
        id: id,
        examinationId: userInfo.value._id,
        applyflg: buttonValue,
        approvalComment: approvalComment,
        directorCode: userInfo.value.directorCode,
      };
      try {
        //承認／否認　処理
        await branchApi.examination(requestData);
        // 差し戻しの場合、差し戻し通知を送付
        if (buttonValue === 9) {
          const email = list.branchMasterId ? list.branchMasterId.email : "";
          const mailRequestData = {
            applicantName: list.branchMasterId.username,
            targetName: list.branchName,
            targetGenre: "支部組成",
            remandReason: approvalComment,
            url: "/member/branches",
            // TODO 固定値を後で定数クラス等を作成して管理するか検討
            mailid: 18,
            mailaddress: email,
          };
          mailApi.returnpost(mailRequestData);
        }
        navigate("/member/branches", { state: { updated: true } });
      } catch (err) {
        console.error(err);
      }
    }
  };

  // 審査処理

  useEffect(() => {
    async function fetchBranch() {
      const res = await branchApi.getById({
        params: { _Id: id },
      });
      //console.log(res);
      setList(res.branch);
      setHistory(res.examinationHistories);
      if (res.branch.applyflg > 0) {
        setIsDisabled(true);
      }
    }
    fetchBranch();

    // 権限の設定
    setRole(authUser.roleType);
    // 審査済みの場合
  }, [id, navigate]);

  const historyColumns = [
    {
      field: "created",
      headerName: "審査日時",
      width: 200,
      renderCell: (params) => {
        return (
          params.value && (
            <div style={{ flexBasis: "200px" }}>{moment(params.value).format("YYYY/MM/DD")}</div>
          )
        );
      },
    },
    {
      field: "examinationId",
      headerName: "審査者",
      width: 200,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "200px" }}>{params.value.username}</div>;
      },
    },
    {
      field: "result",
      headerName: "審査結果",
      width: 200,
    },
    {
      field: "comment",
      headerName: "コメント",
      width: 400,
    },
  ];

  return (
    <Box component="form" id="examinationForm" noValidate>
      <div style={{ marginBottom: "20px" }}>
        {list.applyflg === 0 && (
          <Typography variant="h5" gutterBottom>
            支部組成審査/承認
          </Typography>
        )}
        {(list.applyflg === 1 || list.applyflg === 2 || list.applyflg === 9) && (
          <Typography variant="h5" gutterBottom>
            支部詳細
          </Typography>
        )}
      </div>
      {list.applyflg === 9 && (
        <>
          <Typography mb={2} style={{ color: "#f44336" }}>
            差し戻し中です。再申請後に承認をお願いします。
          </Typography>
        </>
      )}

      <div style={{ marginBottom: "20px" }}></div>

      <ul
        style={{
          listStyleType: "none",
          paddingLeft: 0,
          borderBottom: "2px solid #cccccc",
          paddingBottom: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {list.branchName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                支部名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.branchName}
              </div>
            </div>
          )}
          {list.area && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                地域
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.area}
              </div>
            </div>
          )}
          {list.companyId && list.companyId.goupName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                企業名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.companyId.goupName}
              </div>
            </div>
          )}
          {list.branchMasterId && list.branchMasterId.username && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                支部長名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.branchMasterId.username}
              </div>
            </div>
          )}
          {list.secretariatAddress && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                事務局住所
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.secretariatAddress}
              </div>
            </div>
          )}
          {list.activityPurpose && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                支部の役割・目的
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.activityPurpose}
              </div>
            </div>
          )}
          {list.appDocument && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                支部組成申請書類{" "}
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                <a
                  href={list.appDocument}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "400px" }}
                >
                  支部組成申請書類
                </a>
              </div>
            </div>
          )}
        </div>
        {(!list.applyflg || list.applyflg === 0) &&
          list.reviewId &&
          list.reviewId[userInfo.value.directorCode] === 0 && (
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "20px",
                  width: "750px",
                }}
              >
                <TextField
                  fullWidth
                  id="approvalComment"
                  label="コメント"
                  margin="normal"
                  name="approvalComment"
                  multiline
                  minRows={3}
                  maxRows={5}
                  error={commentErrText !== ""}
                  helperText={commentErrText}
                />
              </Box>
            </div>
          )}
        {role === RoleType.BoardOfDirectors && history && history.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography sx={itemHeaderStyle}>審査履歴</Typography>
            <div style={{ height: "max-content", width: "100%" }}>
              <DataGrid
                rows={history}
                columns={historyColumns}
                getRowId={(history) => history._id}
                autoHeight
              />
            </div>
          </Box>
        )}
        {userInfo.value.directorCode !== "auditor" &&
          role === RoleType.BoardOfDirectors &&
          (list.applyflg === 0 || list.applyflg === 9) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "50px",
                padding: "20px",
              }}
            >
              <LoadingButton
                sx={{
                  width: "150px",
                  color: "white",
                  background: "#FF69B4",
                }}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                disabled={
                  (list.reviewId && list.reviewId[userInfo.value.directorCode] !== 0) ||
                  list.applyflg === 9
                }
                onClick={() => {
                  handleSubmit(2);
                }}
              >
                否認
              </LoadingButton>
              <LoadingButton
                sx={{
                  width: "150px",
                  color: "white",
                  background: "#008000",
                }}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                disabled={
                  (list.reviewId && list.reviewId[userInfo.value.directorCode] !== 0) ||
                  list.applyflg === 9
                }
                onClick={() => {
                  handleSubmit(9);
                }}
              >
                差し戻し
              </LoadingButton>
              <LoadingButton
                sx={{
                  width: "150px",
                  color: "white",
                  background: "#1976D2",
                }}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                disabled={
                  (list.reviewId && list.reviewId[userInfo.value.directorCode] !== 0) ||
                  list.applyflg === 9
                }
                onClick={() => {
                  handleSubmit(1);
                }}
              >
                承認
              </LoadingButton>
            </Box>
          )}
        {userInfo.value.directorCode === "auditor" &&
          role === RoleType.BoardOfDirectors &&
          (list.applyflg === 0 || list.applyflg === 9) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "50px",
                padding: "20px",
              }}
            >
              <LoadingButton
                sx={{
                  width: "150px",
                  color: "white",
                  background: "#FF69B4",
                }}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                disabled={list.reviewId[userInfo.value.directorCode] !== 0 || list.applyflg === 9}
                onClick={() => {
                  handleSubmit(2);
                }}
              >
                異議あり
              </LoadingButton>
              <LoadingButton
                sx={{
                  width: "150px",
                  color: "white",
                  background: "#008000",
                }}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                disabled={list.reviewId[userInfo.value.directorCode] !== 0 || list.applyflg === 9}
                onClick={() => {
                  handleSubmit(9);
                }}
              >
                差し戻し
              </LoadingButton>
              <LoadingButton
                sx={{
                  width: "150px",
                  color: "white",
                  background: "#1976D2",
                }}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                disabled={list.reviewId[userInfo.value.directorCode] !== 0 || list.applyflg === 9}
                onClick={() => {
                  handleSubmit(1);
                }}
              >
                異議なし
              </LoadingButton>
            </Box>
          )}
      </ul>
      <Box>
        <ExaminationChat id={id} isDisabled={isDisabled} />
      </Box>
    </Box>
  );
};
