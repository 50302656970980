import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Backdrop,
    Box,
    SpeedDial,
    SpeedDialAction,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';

import { TextFieldInput } from "../../../../components/common/form/textFieldInput";
import { TextFieldInputWithImg } from "../../../../components/common/form/textFieldInputWithImg";
import { FormulaFieldInput } from "../../../../components/common/form/formulaFieldInput";
import { InputTable } from "../../../../components/common/form/inputTable";
import { InputSideHeaderTable } from "../../../../components/common/form/inputSideHeaderTable"
import { FileUploadInput } from "../../../../components/common/form/fileUploadInput";
import { DropdownInput } from "../../../../components/common/form/dropdownInput";

export const CreatePdd = () => {
    const useFormMethods = useForm({
        defaultValues: {
            projectName: "",
        }
    })

    const sections = [
        {
            index: 0,
            title: "基本情報",
            subsections: [
                {
                    title: "テスト",
                    items: [
                        {
                            index: 1,
                            title: "タイトル",
                            explanation: "説明文章テスト",
                            name: "baseInfo",
                            label: "プロジェクト名",
                            type: "side-header-table",
                            header: [
                                {
                                    id: "item1",
                                    label: "",
                                    width: "70%",
                                    minWidth: 100,
                                    type: "text",
                                    default: "",
                                },
                            ],
                            sideHeader: [
                                "プロジェクト名",
                                "プロジェクトID",
                                "モニタリング期間",
                                "クレジット期間",
                                "当初の発行日",
                                "最新の発行日",
                                "バージョン",
                                "NCCC Standardバージョン",
                                "作成者",
                            ],
                            xs: 12,
                            lg: 12,
                        },
                    ],
                },
            ]
        },
        {
            index: 1,
            title: "プロジェクト概要",
            subsections: [
                {
                    index: 1,
                    title: "プロジェクトの概要説明",
                    items: [
                        {
                            index: 1,
                            title: "",
                            explanation: "",
                            name: "projectDesc",
                            label: "プロジェクトの概要説明",
                            type: "text-with-image",
                            validation: {
                                required: "必須項目です",
                            },
                            required: true,
                            fullWidth: true,
                            multiline: true,
                            rows: 10,
                            xs: 12,
                            lg: 12,
                        },
                    ],
                },
                {
                    index: 2,
                    title: "セクターの範囲とプロジェクトの種類",
                    items: [
                        {
                            index: 1,
                            title: "",
                            explanation: "",
                            name: "projectCategory",
                            label: "プロジェクトtest",
                            type: "dropdown",
                            category: "project",
                            xs: 12,
                            lg: 12,
                        },
                    ],
                },
                {
                    index: 3,
                    title: "監査履歴",
                    items: [
                        {
                            index: 1,
                            title: "",
                            explanation: "",
                            name: "item1-3",
                            label: "プロジェクトtest",
                            type: "table",
                            header: [
                                {
                                    id: "type",
                                    label: "監査タイプ",
                                    width: "20%",
                                    minWidth: 100,
                                    type: "text",
                                    default: "",
                                },
                                {
                                    id: "term",
                                    label: "期間",
                                    width: "20%",
                                    minWidth: 100,
                                    type: "text",
                                    default: 0,
                                },
                                {
                                    id: "program",
                                    label: "プログラム",
                                    width: "55%",
                                    minWidth: 100,
                                    type: "text",
                                    default: "",
                                },
                                {
                                    id: "aaaa",
                                    label: "バリデーション／検証機関名 ",
                                    width: "55%",
                                    minWidth: 100,
                                    type: "text",
                                    default: "",
                                },
                                {
                                    id: "years",
                                    label: "年数",
                                    width: "55%",
                                    minWidth: 100,
                                    type: "text",
                                    default: "",
                                },
                                {
                                    id: "actions",
                                    label: "",
                                    width: "5%",
                                    minWidth: 20,
                                    type: "actions",
                                    default: "",
                                }
                            ],
                            xs: 12,
                            lg: 12,
                        },
                    ],
                },
            ]
        },
        {
            index: 2,
            title: "プロジェクト詳細",
            subsections: [
                {
                    index: 1,
                    title: "プロジェクト・デザイン",
                    items: [],
                },
                {
                    index: 2,
                    title: "プロジェクト活動の説明",
                    items: [],
                },
                {
                    index: 3,
                    title: "プロジェクト所在地",
                    items: [],
                },
                {
                    index: 4,
                    title: "プロジェクト提案者",
                    items: [],
                },
                {
                    index: 5,
                    title: "プロジェクトに関与するその他の団体",
                    items: [],
                },
                {
                    index: 6,
                    title: "所有権",
                    items: [],
                },
            ]
        },
        {
            index: 3,
            title: "プロジェクトの適格性",
            subsections: [
                {
                    index: 1,
                    title: "プロジェクトの適格性",
                    items: [],
                },
                {
                    index: 2,
                    title: "プロジェクト開始前の状況",
                    items: [],
                },
                {
                    index: 3,
                    title: "法律、法令、その他の規制枠組みの遵守",
                    items: [],
                },
                {
                    index: 4,
                    title: "二重請求と他のGHGプログラムへの参加",
                    items: [],
                },
                {
                    index: 5,
                    title: "二重請求、他の形態のクレジット、スコープ3排出量",
                    items: [],
                },
            ]
        },
        {
            index: 4,
            title: "環境および社会的配慮",
            subsections: [
                {
                    index: 1,
                    title: "ステークホルダーの参画と協議",
                    items: [],
                },
                {
                    index: 2,
                    title: "ステークホルダーと環境へのリスク",
                    items: [],
                },
                {
                    index: 3,
                    title: "人権と公平性の尊重",
                    items: [],
                },
                {
                    index: 4,
                    title: "生態系の健全性",
                    items: [],
                },
                {
                    index: 5,
                    title: "持続可能な開発への貢献",
                    items: [],
                },
                {
                    index: 6,
                    title: "プロジェクトに関連する追加情報",
                    items: [],
                },
            ]
        },
        {
            index: 5,
            title: "実施状況",
            subsections: [
                {
                    index: 1,
                    title: "プロジェクト活動の実施状況",
                    items: [],
                },
                {
                    index: 2,
                    title: "逸脱",
                    items: [],
                },
                {
                    index: 3,
                    title: "グループ化されたプロジェクト",
                    items: [],
                },
                {
                    index: 4,
                    title: "ベースライン再評価",
                    items: [],
                },
            ]
        },
        {
            index: 6,
            title: "プロジェクト期間と推定GHG排出量",
            subsections: [
                {
                    index: 1,
                    title: "プロジェクト開始日",
                    items: [],
                },
                {
                    index: 2,
                    title: "プロジェクトのクレジット期間",
                    items: [],
                },
                {
                    index: 3,
                    title: "プロジェクト規模とGHG排出削減量または除去量の推定値",
                    items: [],
                },
            ]
        },
        {
            index: 7,
            title: "方法論の適用",
            subsections: [
                {
                    index: 1,
                    title: "方法論のタイトルと参考文献",
                    items: [],
                },
                {
                    index: 2,
                    title: "方法論の適用性",
                    items: [],
                },
                {
                    index: 3,
                    title: "プロジェクト境界",
                    items: [],
                },
                {
                    index: 4,
                    title: "ベースライン・シナリオ",
                    items: [],
                },
                {
                    index: 5,
                    title: "追加性",
                    items: [],
                },
                {
                    index: 6,
                    title: "方法論の逸脱",
                    items: [],
                },
            ]
        },
        {
            index: 8,
            title: "GHG排出削減量と除去量の計算",
            subsections: [
                {
                    index: 1,
                    title: "ベースライン排出量",
                    items: [
                        {
                            index: 1,
                            title: "",
                            explanation: "",
                            name: "baselineEmissionsTable",
                            label: "ベースライン排出量",
                            type: "table",
                            required: false,
                            fullWidth: true,
                            header: [
                                {
                                    id: "item",
                                    label: "項目",
                                    width: "20%",
                                    minWidth: 100,
                                    type: "text",
                                    default: "",
                                },
                                {
                                    id: "ghg",
                                    label: "GHG（CO2換算 トン/年間）",
                                    width: "20%",
                                    minWidth: 100,
                                    type: "numeric",
                                    default: 0,
                                },
                                {
                                    id: "evidence",
                                    label: "根拠",
                                    width: "55%",
                                    minWidth: 100,
                                    type: "text",
                                    default: "",
                                },
                                {
                                    id: "actions",
                                    label: "",
                                    width: "5%",
                                    minWidth: 20,
                                    type: "actions",
                                    default: "",
                                }
                            ],
                            data: [
                                {
                                    item: "土地からの自然排出分",
                                    ghg: 13.111,
                                    evidence: "土地面積は494773.18m²（49.477ha）である。IPCC (2000, p.363)によると、土地1haあたり年間1kgの亜酸化窒素N2Oが自然排出されるため、49.477kgのN2Oが排出される。IPCC (2014)によると、N2OのCO2換算係数は265倍である。したがって、49.477kg×265÷1000＝13.111トンである。",
                                }
                            ],
                            xs: 12,
                            lg: 12,
                        },
                        {
                            index: 2,
                            title: "",
                            explanation: "追加の説明をこちらに記載してください。",
                            name: "baselineEmissions",
                            label: "ベースライン排出量",
                            type: "formula-text",
                            required: false,
                            fullWidth: true,
                            multiline: true,
                            rows: 10,
                            xs: 12,
                            lg: 12,
                        }
                    ],
                },
                {
                    index: 2,
                    title: "プロジェクト排出量",
                    items: [],
                },
                {
                    index: 3,
                    title: "リーケージ排出量",
                    items: [],
                },
                {
                    index: 4,
                    title: "GHG排出削減量と二酸化炭素除去料の推定値",
                    items: [],
                },
            ]
        },
        {
            index: 9,
            title: "モニタリング",
            subsections: [
                {
                    index: 1,
                    title: "検証時に利用可能なデータとパラメータ",
                    items: [],
                },
                {
                    index: 2,
                    title: "モニタリングされるデータとパラメータ",
                    items: [],
                },
                {
                    index: 3,
                    title: "モニタリング計画",
                    items: [],
                },
            ]
        },
        {
            index: 10,
            title: "GHG排出削減と除去の定量化",
            subsections: [
                {
                    index: 1,
                    title: "ベースライン排出量",
                    items: [],
                },
                {
                    index: 2,
                    title: "プロジェクト排出量",
                    items: [],
                },
                {
                    index: 3,
                    title: "リーケージ排出量",
                    items: [],
                },
                {
                    index: 4,
                    title: "GHG排出削減量と二酸化炭素除去量",
                    items: [],
                },
            ]
        },
        {
            index: 11,
            title: "参考文献",
            subsections: [
                {
                    index: 0,
                    title: "",
                    items: [],
                },
            ]
        },
        {
            index: 12,
            title: "APPENDIX",
            subsections: [
                {
                    index: 1,
                    title: "APPENDIX1. 商業上の機密情報",
                    items: [],
                },
                {
                    index: 1,
                    title: "APPENDIX2. 使用材料の品質証明証",
                    items: [],
                },
            ]
        },
    ]

    const [isSpeedDialogOpen, setIsSpeedDialogOpen] = useState(false);
    const handleOpen = () => setIsSpeedDialogOpen(true);
    const handleClose = () => setIsSpeedDialogOpen(false);
    const speedDialogActions = [
        {
            icon: <SaveIcon />,
            name: '一時保存',
            onClick: handleClose,
        },
        {
            icon: <SendIcon />,
            name: '申請する',
            onClick: handleClose,
        },
    ];

    const tagProvider = (v) => {
        console.log(v.title)
        switch(v.type) {
            case "text":
                return <TextFieldInput fieldData={v} />
            case "formula-text":
                return <FormulaFieldInput fieldData={v} />
            case "text-with-image":
                return <TextFieldInputWithImg fieldData={v} />
            case "table":
                return <InputTable fieldData={v} />
            case "side-header-table":
                return <InputSideHeaderTable fieldData={v} />
            case "file":
                return <FileUploadInput fieldData={v} />
            case "dropdown":
                return <DropdownInput fieldData={v} />
            default:
                return (
                    <>
                        {v.title
                            && <Typography variant="subtitle1" gutterBottom>{v.title}</Typography>
                        }
                        {v.explanation
                            && <Typography variant="subtitle2" gutterBottom>{v.explanation}</Typography>
                        }
                    </>
                )
        }
    }

    return (
        <div>
            <FormProvider {...useFormMethods}>
                <h3>プロジェクト設計書作成</h3>
                {sections.map((section, index) => {
                    return (
                        <Box mb={1}>
                            <Accordion sx={{ bgcolor: "white" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {section.index ? section.index + ". " + section.title : section.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                {
                                    section.subsections.map((subsection, index) => {
                                        return (
                                            <Box mb={2}>
                                                <Typography variant="body1" gutterBottom>
                                                    {subsection.index ? `${section.index}.${subsection.index} ` + subsection.title : subsection.title}
                                                </Typography>
                                                {
                                                    subsection.items.map((item) => {
                                                        return tagProvider(item)
                                                    })
                                                }
                                            </Box>
                                        )
                                    })
                                }
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )
                })}
            </FormProvider>
            <Backdrop open={isSpeedDialogOpen} />
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={isSpeedDialogOpen}
            >
                {speedDialogActions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
        </div>
    );
};
