import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import CircleIcon from '@mui/icons-material/Circle';
import { 
  task_setTaskStatus,
  task_setMilestoneStatus
} from "../../../redux/features/taskSlice";

const StatusDropdown = ({field, fieldState, category}) => {
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  // 初期表示
  useEffect(() => {
    fetchStatusList();
  }, []);

  const getStatusIcon = (statusKey) => {
    switch (statusKey) {
      case "nostatus":
        return "";
      case "status1":
        return <CircleIcon sx={{ color: "#ff6347", paddingRight: "5px" }} />;
      case "status2":
        return <CircleIcon sx={{ color: "#4169e1", paddingRight: "5px" }} />;
      case "status3":
        return <CircleIcon sx={{ color: "#3cb371", paddingRight: "5px" }} />;
      case "status99":
        return <CircleIcon sx={{ color: "#daa520", paddingRight: "5px" }} />;
      default:
        return <CircleIcon sx={{ color: "#bdb76b", paddingRight: "5px" }} />;
    }
  };

  const fetchStatusList = async () => {
    setStatusList([
      { key: "nostatus", text: "---", value: 0 },
      { key: "status1", text: "未対応", value: 1 },
      { key: "status2", text: "処理中", value: 2 },
      { key: "status3", text: "処理済み", value: 3 },
      { key: "status99", text: "完了", value: 99 },
    ]);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    if (category === "task") {
      dispatch(task_setTaskStatus(event.target.value));
    } else if (category === "milestone") {
      dispatch(task_setMilestoneStatus(event.target.value));
    }
  };

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <FormControl fullWidth error={fieldState.invalid}>
        <InputLabel>状態</InputLabel>
        <Select
          {...field}
          input={<OutlinedInput label="状態" />}
          value={status}
          MenuProps={MenuProps}
          onChange={handleStatusChange}
        >
          {statusList &&
            statusList.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                <div style={menuItemStyle}>
                  {getStatusIcon(item.key)}
                  {item.text}
                </div>
              </MenuItem>
            ))}
        </Select>
        {fieldState.invalid && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default StatusDropdown;
