import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import languageReducer from "./features/languageSlice";
import sidebarReducer from "./features/sidebarSlice";
import taskReducer from "./features/taskSlice";
import projectReducer from "./features/projectSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    language: languageReducer,
    sidebar: sidebarReducer,
    task: taskReducer,
    project: projectReducer,
  },
});
