import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
    Box,
    Typography,
} from "@mui/material"
import { MuiFileInput } from "mui-file-input";

export const FileUploadInput = ({ fieldData }) => {
    // 親コンポーネントは FormProvider でラップされていること
    const { control } = useFormContext()
    console.log(control)

    return (
        <Box mt={3}>
            {fieldData.title
                && <Typography variant="subtitle1" gutterBottom>{fieldData.title}</Typography>
            }
            {fieldData.explanation
                && <Typography variant="subtitle2" gutterBottom>{fieldData.explanation}</Typography>
            }
            <Controller
                name={fieldData.name}
                control={control}
                rules={fieldData.validation}
                render={({ field, fieldState }) => (
                    <MuiFileInput
                        type="file"
                        fullWidth
                        {...field}
                        error={fieldState.invalid}
                        helperText={
                            fieldState.error?.message ?? fieldData.helperText
                        }
                    />
                )}
            />
        </Box>
    )
}
