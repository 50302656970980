import React from "react";
import branchUserApi from "../../../api/branchUserApi";
import branchApi from "../../../api/branchApi";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/Global.css";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid, GridColDef, GridRowsProp, GridValueGetterParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Badge from "@mui/material/Badge";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";

export const BranchJoinList = () => {
  const [list, setList] = useState([]);
  const [tabValue, setTabValue] = useState("1");
  const [role, setRole] = useState("");
  const [isBranchMaster, setIsBranchMaster] = useState(false);
  const authUser = useAuthUserContext();
  const location = useLocation();
  const storedUserInfo = useSelector((state) => state.user);
  const [checkedBranchUsers, setCheckedBranchUsers] = useState([]);
  const dispatch = useDispatch();
  const ChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  // 審査済みの参加支部リスト
  const examinationList = list.filter(
    (branchUser) => (branchUser.applyflg === 1 || branchUser.applyflg === 2) 
    && storedUserInfo.value._id !== branchUser.userId
  );
  // 審査待ちの参加支部リスト
  const notExaminationList = list.filter(
    (branchUser) => {
      if (!checkedBranchUsers.includes(branchUser._id)) {
        branchUser.isNotice = true;
      }
      return branchUser.applyflg === 0 && storedUserInfo.value._id !== branchUser.userId;
    });

  // 承認済みの参加支部リスト
  const approvedList = list.filter((branchUser) => branchUser.applyflg === 1);
  // 差し戻しされた参加支部リスト
  const remandList = list.filter(
    (branchUser) => branchUser.applyflg === 9  && storedUserInfo.value._id === branchUser.userId);

  async function fetchBranchUserList(branchMasterFlg) {
    let queryParams = {};
    // 理事会の場合
    if (RoleType.BoardOfDirectors === authUser?.roleType) {
      queryParams = { applyflg: 0 };
    // 承認者の場合
    } else if (branchMasterFlg === 1) {
      queryParams = {
        $or: [{ branchMaster: storedUserInfo.value._id }, { userId: storedUserInfo.value._id }],
      };
    } else {
      queryParams = { userId: storedUserInfo.value._id };
    }
    const res = await branchUserApi.getTargetList({
      params: queryParams,
    });
    const resList = res.branchUsers;
    let branchList = [];
    for (let i = 0; i < resList.length; i++) {
      let branch = {};
      branch._id = resList[i]._id;
      branch.userId = resList[i].userId._id;
      branch.userName = resList[i].userId.username;
      branch.branchId = resList[i].branchId._id;
      branch.branchName = resList[i].branchId.branchName;
      branch.companyName = resList[i].companyId.groupName;
      branch.applyflg = resList[i].applyflg;
      branchList.push(branch);
    }

    setList(branchList);
  }

  async function fetchBranchList() {
    const res = await branchApi.getTargetList({
      params: { branchMasterId: storedUserInfo.value._id },
    });
    return res.branchs;
  }

  const getCheckedBranchUserList = async () => {
    const checkedBranchUserMap = await branchUserApi.getBranchUserCheckLog(
      { params: { operateUserId: storedUserInfo.value._id } }
    );
    let checkedBranchUserIds = []
    checkedBranchUserMap.branchUserCheckLogList.map((checkedBranchUser) => {
      checkedBranchUserIds.push(checkedBranchUser.checkedBranchUserId);
    });
    setCheckedBranchUsers(checkedBranchUserIds);
  }

  const handleToDetail = async (checkedBranchUserId) => {
    // 未確認の場合のみ確認済みログを作成する
    if (!isBranchMaster && !checkedBranchUsers.includes(checkedBranchUserId)) {
      await branchUserApi.createBranchUserCheckLog({
        operateUserId: storedUserInfo.value._id,
        checkedBranchUserId: checkedBranchUserId,
      });
      dispatch(setUpdateBadgeFlg(true));
    }
  };

  useEffect(() => {
    // ログインユーザーが支部長（承認者）かどうかで取得対象を変更する
    fetchBranchList().then((branchs) => {
      if (branchs && branchs.length > 0) {
        setIsBranchMaster(true);
        fetchBranchUserList(1);
      } else {
        fetchBranchUserList(0);
      }
    });
    // 通知バッチ判定情報を取得
    getCheckedBranchUserList();
    // 権限の設定
    setRole(authUser.roleType);
  }, []);

  const columns = [
    {
      field: "companyName",
      headerName: "会社名",
      width: 300,
    },
    {
      field: "userName",
      headerName: "申請者名",
      width: 200,
    },
    {
      field: "branchName",
      headerName: "支部名",
      width: 300,
    },
    {
      field: "_id",
      headerName: "詳細",
      width: 150,
      renderCell: (params) => {
        return (
          params.value && (
            <Link to={"/member/branches/joinApply/" + params.value}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                詳細
              </Button>
            </Link>
          )
        );
      },
    },
  ];
  const examinationColumns = [
    {
      field: "companyName",
      headerName: "会社名",
      width: 300,
    },
    {
      field: "userName",
      headerName: "申請者名",
      width: 200,
    },
    {
      field: "branchName",
      headerName: "支部名",
      width: 300,
    },
    {
      field: "applyflg",
      headerName: "承認ステータス",
      width: 200,
      renderCell: (params) => {
        const applyflg = params.value;
        let statusText = "";
        let statusColor = "";

        if (applyflg === 0) {
          statusText = "未審査";
          statusColor = "orange";
        } else if (applyflg === 1) {
          statusText = "承認";
          statusColor = "aqua";
        } else if (applyflg === 2) {
          statusText = "否認";
          statusColor = "magenta";
        }

        return (
          <Box sx={{ p: 1 }}>
            <Typography component="body2" style={{ color: statusColor }}>
              {statusText}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "_id",
      headerName: "詳細",
      width: 150,
      renderCell: (params) => {
        return (
          params.value && (
            <Link to={"/member/branches/joinApply/" + params.value}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                詳細
              </Button>
            </Link>
          )
        );
      },
    },
  ];
  const notExaminationColumns = [
    {
      field: "isNotice",
      headerName: "",
      width: 5,
      renderCell: (params) => {
        return <Box>{(params.value) && <Badge color="warning" variant="dot"></Badge>}</Box>;
      },
    },
    {
      field: "companyName",
      headerName: "会社名",
      width: 300,
    },
    {
      field: "userName",
      headerName: "申請者名",
      width: 200,
    },
    {
      field: "branchName",
      headerName: "支部名",
      width: 300,
    },
    {
      field: "_id",
      headerName: "参加審査",
      width: 150,
      renderCell: (params) => {
        if (role === RoleType.BoardOfDirectors) {
          return (
            params.value && (
              <Link to={"/member/branches/joinApply/" + params.value}>
                <Button variant="contained" sx={{ fontSize: "5px" }} onClick={() => handleToDetail(params.value)}>
                  詳細
                </Button>
              </Link>
            )
          );
        } else {
          return (
            params.value && (
              <Link to={"/member/branches/joinApply/" + params.value}>
                <Button variant="contained" sx={{ fontSize: "5px" }}>
                  審査
                </Button>
              </Link>
            )
          );
        }
      },
    },
  ];
  const remandColumns = [
    {
      field: "companyName",
      headerName: "会社名",
      width: 300,
    },
    {
      field: "userName",
      headerName: "申請者名",
      width: 200,
    },
    {
      field: "branchName",
      headerName: "支部名",
      width: 300,
    },
    {
      field: "_id",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        return (
          params.value && (
            <Link
              to={"/member/branches/" + params.row.branchId + "/join?branchUserId=" + params.value}
            >
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                修正
              </Button>
            </Link>
          )
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const pageSizeOptions = [10, 25, 100];

  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          支部入会一覧
        </Typography>
      </div>

      {/* 承認者用の表示 */}
      <Box sx={{ width: "100%", typography: "body1" }}>
        {(isBranchMaster || RoleType.BoardOfDirectors === role) && (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                <Tab label="審査待ち" value="1" />
                <Tab label="審査済み" value="2" />
                <Tab label="差し戻し対象" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={notExaminationList}
                    columns={notExaminationColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={examinationList}
                    columns={examinationColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="3">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={remandList}
                    columns={remandColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        )}
        {/* 承認者以外の表示 */}
        {(!isBranchMaster && RoleType.BoardOfDirectors !== role) && (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                <Tab label="支部参加一覧" value="1" />
                <Tab label="差し戻し対象" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={approvedList}
                    columns={columns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={remandList}
                    columns={remandColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </Box>
  );
};
