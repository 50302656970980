import React, { useState, useEffect, useRef } from "react";
import programApi from "../../../api/programApi";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import { AttachFile } from "@mui/icons-material";
import { MuiFileInput } from "mui-file-input";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import uploadImg from "../../../components/common/utils/uploadImg";

export const MemberProgramApply = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user);
  let [program, setProgram] = useState({});

  // validation
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const programId = urlParams.get("programId");

  const { control, formState, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      applicantName: "",
      programName: "",
      programDescription: "",
      applicationDocuments: "",
    },
  });

  useEffect(() => {
    async function fetchProgram() {
      if (programId) {
        const res = await programApi.getProgramExaminationInfo({ params: { id: programId } });

        setProgram(res.program);
      }
      // 提案されたプログラム情報
    }

    fetchProgram();
  }, []);
  useEffect(() => {
    setValue("applicantName", program.applicantName);
  }, [program.applicantName]);

  useEffect(() => {
    setValue("programName", program.programName);
  }, [program.programName]);

  useEffect(() => {
    setValue("programDescription", program.programDescription);
  }, [program.programDescription]);

  const documentTarget = (applyflg) => {
    if (applyflg === 4) {
      return "提案";
    } else {
      return "申請";
    }
  };

  const validationRules = {
    // pack_file: {},
    applicantName: {
      required: "名前を入力してください。",
      maxLength: { value: 20, message: "20文字以内で入力してください" },
    },
    programName: {
      required: "プログラム名を入力してください。",
      maxLength: { value: 30, message: "30文字以内で入力してください" },
    },
    programDescription: {
      required: "プログラム概要を入力してください",
      maxLength: { value: 800, message: "800文字以内で入力してください" },
    },
    applicationDocuments: {
      validate: !program.applicationDocuments && {
        isExists: (value) => value || "プログラム登録書類をアップロードしてください",
        isPdf: (value) => value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
  };

  const onSubmit = async (data) => {
    if (window.confirm("登録します。よろしいですか？")) {
      // 書類が更新されていればファイルをアップロード
      const url = data.applicationDocuments
        ? await uploadImg(data.applicationDocuments, ["programDocuments"])
        : program.applicationDocuments;

      const requestData = {
        applicantId: userInfo.value._id,
        applicantName: data.applicantName,
        companyId: userInfo.value.companyId,
        programName: data.programName,
        programDescription: data.programDescription,
        applicationDocuments: url,
      };

      // 差し戻されたものを再修正した場合
      if (programId && program.applyflg === 9) {
        reApply(requestData);
      }
      // 通常申請
      else {
        programApi
          .apply(requestData)
          .then(async (res) => {
            const mailRes = await sendApplicationMail(requestData, res.id);
            return mailRes;
          })
          .then(() => {
            alert("登録しました");
            navigate("/member/programs");
          })
          .catch((e) => {
            console.error(e);
            alert("登録に失敗しました");
          });
      }
    }
  };

  // 再申請処理
  const reApply = async (data) => {
    const requestData = {
      _id: programId,
      applicantId: data.applicantId,
      applicantName: data.applicantName,
      companyId: data.companyId,
      programName: data.programName,
      programDescription: data.programDescription,
      applicationDocuments: data.applicationDocuments,
      applyflg: 0,
    };
    programApi
      .modify(requestData)
      .then(async () => {
        const res = await sendApplicationMail(requestData, programId);
        return res;
      })
      .then(() => {
        alert("再申請しました");
        navigate("/member/programs");
      })
      .catch((e) => {
        console.error(e);
        alert("再申請に失敗しました");
      });
  };

  // 承認依頼メール送信
  const sendApplicationMail = async (data, programId) => {
    const mailRequestData = {
      applicantName: data.applicantName,
      programName: data.programName,
      programDescription: data.programDescription,
      programId: programId,
      mailid: "15",
    };
    try {
      const res = await programApi.sendApplicationMail(mailRequestData);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h3>プログラム登録</h3>
      {program.applyflg === 9 && (
        <Typography mb={2} style={{ color: "#f44336" }}>
          差し戻しコメント：{program.approvalComment}
        </Typography>
      )}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid xs={12} lg={4}>
            <Typography mb={2}>申請者情報</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="applicantName"
                control={control}
                rules={validationRules.applicantName}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="applicantName"
                    label="申請者名"
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={4}>
            <Typography mb={2}>申請プログラム情報</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="programName"
                control={control}
                rules={validationRules.programName}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="programName"
                    label="プログラム名"
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="programDescription"
                control={control}
                rules={validationRules.programDescription}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="programDescription"
                    label="プログラム概要"
                    multiline
                    rows={6}
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={4}>
            <Typography mb={2}>プログラム登録書類</Typography>
            {program.applicationDocuments && (
              <Box sx={{ marginBottom: "2rem" }}>
                {documentTarget(program.applyflg)}時の書類：
                <a href={program.applicationDocuments} target="_blank" rel="noopener noreferrer">
                  確認する場合はこちら
                </a>
              </Box>
            )}
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="applicationDocuments"
                control={control}
                rules={validationRules.applicationDocuments}
                render={({ field, fieldState }) => (
                  <MuiFileInput
                    type="file"
                    fullWidth
                    {...field}
                    error={fieldState.invalid}
                    helperText={
                      fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                    }
                  />
                )}
              />
            </Box>
            <Button
              // disabled={!formState.isDirty || !formState.isValid}
              variant="contained"
              fullWidth
              sx={{ color: "#fff" }}
              type="submit"
            >
              登録
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
