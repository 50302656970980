import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyB_gjxgjv9nEAYy7BARQbKyxNAwcP_ev8g",
  authDomain: "nccc-382601.firebaseapp.com",
  projectId: "nccc-382601",
  storageBucket: "nccc-382601.appspot.com",
  messagingSenderId: "585098978140",
  appId: "1:585098978140:web:82307bfdc302059d2b5799",
});

// Firebase storage reference
const storage = getStorage(app);
export default storage;
