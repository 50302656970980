import React from "react";
import { useAuthUserContext } from "../providers/auth";
import { Navigate, useLocation } from "react-router-dom";

export const RouteAuthGuard = (props) => {
  const roleType = useAuthUserContext().roleType;
  const isSignin = useAuthUserContext().isSignin;

  let allowRoute = false;
  if (roleType) {
    allowRoute = props.allowroles ? props.allowroles.includes(roleType) : true;
  }

  if (!allowRoute || !isSignin) {
    return (
      <Navigate
        to={props.redirect}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        state={{ from: useLocation() }}
        replace={false}
      />
    );
  }

  return <>{props.component}</>;
};
