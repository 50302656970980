import React from "react";
import { Link } from "react-router-dom";
import NcccLogoHorizontal from "../../assets/imgs/NCCC_logo_horizon.png";
import Facebook from "../../assets/imgs/homepage/Facebook.svg";
import Twitter from "../../assets/imgs/homepage/Twitter.svg";
import Instagram from "../../assets/imgs/homepage/Instagram.svg";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={footerBackgroundStyle}>
        <Box sx={footerRowStyle}>
          <Link to="/" style={{ color: "white" }}>
            <img src={NcccLogoHorizontal} width="350px" style={{ maxWidth: "100%" }} />
          </Link>
          <div>{t("footer.companyName")}</div>
          <div style={{ display: "flex", gap: "20px" }}>
            <a href="https://www.facebook.com/NCCC.earth" target="_blank">
              <img src={Facebook} />
            </a>
            <a href="https://twitter.com/NCCC_earth" target="_blank">
              <img src={Twitter} />
            </a>
            <a href="https://instagram.com/nccc.earth?igshid=MjEwN2IyYWYwYw==" target="_blank">
              <img src={Instagram} />
            </a>
          </div>
        </Box>
        <div style={footerRowStyle}>
          <div style={footerLinkTitleStyle}>About NCCC</div>
          <Link to="/organization" style={footerLinkTextStyle}>
            {t("footer.aboutus.link01")}
          </Link>
          <Link to="/about" style={footerLinkTextStyle}>
            {t("footer.aboutus.link02")}
          </Link>
          <Link to="/members" style={footerLinkTextStyle}>
            {t("footer.aboutus.link03")}
          </Link>
          <Link to="/observer" style={footerLinkTextStyle}>
            {t("footer.aboutus.link04")}
          </Link>
          <Link to="/constitution" style={footerLinkTextStyle}>
            {t("footer.aboutus.link05")}
          </Link>
        </div>
        <div style={footerRowStyle}>
          <div style={footerLinkTitleStyle}>News</div>
          <Link to="/news" style={footerLinkTextStyle}>
            {t("footer.News.link01")}
          </Link>
          <Link to="/status" style={footerLinkTextStyle}>
            {t("footer.News.link02")}
          </Link>
          {/* セミナーは画面未作成のためコメントアウト */}
          {/* <Link to="/" style={footerLinkTextStyle}>
            {t("footer.News.link03")}
          </Link> */}
        </div>
        <div style={footerRowStyle}>
          <div style={footerLinkTitleStyle}>Others</div>
          {/* お問い合わせ画面は未作成のためコメントアウト */}
          {/* <Link to="/contact" style={footerLinkTextStyle}>
            {t("footer.Others.link01")}
          </Link> */}
          <Link to="/privacypolicy" style={footerLinkTextStyle}>
            {t("footer.Others.link02")}
          </Link>
        </div>
      </Box>
      <div
        style={{
          background: "#F6F6F6",
          display: "flex",
          alignContent: "center",
          justifyContent: "right",
          padding: "10px",
        }}
      >
        <span
          style={{
            color: "#3A3A3A",
            fontSize: "8px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "1.32px",
            background: "#F6F6F6",
          }}
        >
          © 2023 NCCC ALL RIGHTS RESERVED.
        </span>
      </div>
    </>
  );
};

const footerBackgroundStyle = {
  padding: { xs: "50px 25px", md: "100px 200px" },
  display: "flex",
  flexWrap: "wrap",
  justifyContent: { xs: "start", md: "center" },
  color: "white",
  background: "linear-gradient(144deg, #66D26A 0%, #1E649E 100%)",
  gap: { xs: "50px", md: "100px" },
};

const footerRowStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "25px",
};

const footerLinkTitleStyle = {
  color: "#FFFFFF",
  fontFamily: "source-han-sans-japanese",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "1.76px",
};

const footerLinkTextStyle = {
  color: "#FFFFFF",
  fontFamily: "neue-haas-grotesk-display",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "1.76px",
};
