import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import moment from "moment";

import "../../../assets/css/Global.css";
import projectApi from "../../../api/projectApi";
import actWorkApi from "../../../api/actWorkApi";
import branchUserApi from "../../../api/branchUserApi";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import { project_setIsChargeApplicationModalOpen } from "../../../redux/features/projectSlice";

import { ProjectTaskList } from "./task/list"
import { MemberProjectJoin } from "./task/join";


export const ProjectDetail = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [work, setWork] = useState([]);
  const [branchFlg, setBranchFlg] = useState(false);
  const { id } = useParams();
  const storedUserInfo = useSelector((state) => state.user);
  const projectState = useSelector((state) => state.project);
  const authUser = useAuthUserContext();
  const [role, setRole] = useState("");
  const [branchMasterId, setBranchMasterId] = useState("");
  const [actWorks, setActWorks] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const isWorkDisplay = (work) => {
    // 管理者、理事、第三者委員会
    if (role === RoleType.Admin || role === RoleType.BoardOfDirectors || role === RoleType.ThirdPartyCommittee) {
      return true;
      // 支部長
    } else if (branchMasterId === storedUserInfo.value._id) {
      return true;
      // プロジェクト参加者
    } else if (work.publicScope === "0" && actWorks.length > 0) {
      return true;
      // 支部長のみ
    } else if (work.publicScope === "1" && branchMasterId === storedUserInfo.value._id) {
      return true;
      // 正会員
    } else if (work.publicScope === "2" && role === RoleType.FullMember) {
      return true;
      // オブザーバー会員限定
    } else if (work.publicScope === "3" && role === RoleType.ObserverMember) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    async function fetchProject() {
      const res = await projectApi.getById({
        params: { _id: id },
      });
      const joinBranch = await branchUserApi.getTargetList({
        params: {
          userId: storedUserInfo.value._id,
          branchId: res.project.branchId._id,
          applyflg: "1",
        },
      });
      if (joinBranch.branchUsers && joinBranch.branchUsers.length > 0) {
        setBranchFlg(true);
      }
      setList(res.project);
      setWork(res.workData);
      setBranchMasterId(res.project.branchId.branchMasterId);
    }
    fetchProject();
    fetchActWork();
    setRole(authUser.roleType);
  }, [id]);

  useEffect(() => {
    if (projectState.isChargeApplicationModalOpen) {
      setIsOpenDialog(true);
    }
  }, [projectState.isChargeApplicationModalOpen])

  async function fetchActWork() {
    // プロジェクト参加情報取得
    let actWorks = await actWorkApi.getListByUserId({
      params: { userId: storedUserInfo.value._id, projectId: id },
    });
    setActWorks(actWorks.actWorks);
  }

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
    dispatch(project_setIsChargeApplicationModalOpen(false))
  };

  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          プロジェクト詳細
        </Typography>
      </div>

      <div style={{ marginBottom: "20px" }}></div>

      <ul
        style={{
          listStyleType: "none",
          paddingLeft: 0,
          borderBottom: "2px solid #cccccc",
          paddingBottom: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {list.programId && list.programId.programName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プログラム名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.programId.programName}
              </div>
            </div>
          )}
          {list.branchId && list.branchId.branchName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                支部名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.branchId.branchName}
              </div>
            </div>
          )}
          {list.projectName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プロジェクト名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.projectName}
              </div>
            </div>
          )}

          {list.projectTimeLine && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                期間
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {moment(list.projectTimeLine[0]).format("YYYY/MM/DD")} -{" "}
                {moment(list.projectTimeLine[1]).format("YYYY/MM/DD")}
              </div>
            </div>
          )}
          {list.projectTimeLine && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                見積資料
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                <a
                  href={list.planDocuments}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "400px" }}
                >
                  見積資料
                </a>
              </div>
            </div>
          )}
          {/* <li style={{ display: "flex", marginBottom: "10px" }}>
            <div style={{ fontWeight: "bold", flexBasis: "300px" }}>作業名</div>
            <div style={{ fontWeight: "bold", flexBasis: "300px" }}>予算</div>
            <div style={{ fontWeight: "bold", flexBasis: "300px" }}>参加申請</div>
          </li> */}
          {/* {work.map(
            (work) =>
              isWorkDisplay(work) && (
                <li key={work._id} style={{ display: "flex", marginBottom: "5px" }}>
                  <div style={{ flexBasis: "300px" }}>{work.workName}</div>
                  <div style={{ flexBasis: "300px" }}>{Number(work.budget).toLocaleString() + " 円"}</div>
                  <div style={{ flexBasis: "300px" }}>
                    {work.applyflg == 0 && branchFlg && (
                      <Link to={`/member/projects/${list._id}/join`}>
                        <Button variant="contained" sx={{ fontSize: "5px" }}>
                          参加申請
                        </Button>
                      </Link>
                      // <Button variant="contained" component="a" href={`projects/${work._id}/join`}>
                      //   参加申請
                      // </Button>
                    )}
                    {work.applyflg == 1 && <span>作業中</span>}
                    {work.applyflg == 2 && <span>完了</span>}
                  </div>
                </li>
              )
          )} */}
          <ProjectTaskList
            props={{
              projectId: list._id,
              projectNo: list.projectNo,
              historyNo: list.historyNo,
            }}
          />
          {list.branchId && list.branchId.branchMasterId === storedUserInfo.value._id && 
            list.projectStatus !== "3" && (
              <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                <Link to={`/member/projects/${list._id}/close`}>
                  <Button
                    // disabled={!formState.isDirty || !formState.isValid}
                    variant="contained"
                    fullWidth
                    style={{ width: "150px" }}
                    sx={{ color: "#fff" }}
                    type="submit"
                  >
                    クローズ
                  </Button>
                </Link>
              </div>
          )}
        </div>
      </ul>
      <Dialog
        maxWidth="lg"
        open={isOpenDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>作業担当申請</DialogTitle>
        <DialogContent
          sx={{ bgcolor: "white" }}
        >
          <DialogContentText>
            <br />
            本プロジェクトのタスク実施者として申請します。<br />
            承認されるとプロジェクト実施者として登録されます。
          </DialogContentText>
          <MemberProjectJoin />
        </DialogContent>
        <DialogActions
          sx={{ bgcolor: "white" }}
        >
          <Button onClick={handleCloseDialog}>戻る</Button>
          <Button
            onClick={handleCloseDialog}
            disabled={""}
          >
            申請
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
