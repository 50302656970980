import axiosClient from "./axiosClient";

const branchApi = {
  register: (params) => axiosClient.post("branch/register", params),
  modify: (params) => axiosClient.post("branch/update", params),
  getBranch: (id) => axiosClient.get(`branch?id=${id}`),
  getList: (params) => axiosClient.get("branch/list", params),
  getApprovedList: () => axiosClient.get("branch/approvedList"),
  getTargetList: (params) => axiosClient.get("branch/getTargetList", params),
  getTargetOne: (params) => axiosClient.get("branch/getTargetOne", params),
  getById: (params) => axiosClient.get("branch/getById", params),
  examination: (params) => axiosClient.post("branch/examination", params),
  getListWithRecruitProject: (params) => axiosClient.get("branch/listWithRecruitProject", params),
  userAdd: (params) => axiosClient.post("branch/userAdd", params),
};

export default branchApi;
