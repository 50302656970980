import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import forest from "../../assets/imgs/forest01.jpg";
import "../../assets/css/layout/style.css";
import { Link } from "react-router-dom";
import joinMemberApi from "../../api/joinMember";
import ScrollToTopOnMount from "../../components/common/utils/ScrollToTopOnMount";
import { SubpageHeader } from "../../components/homepage/SubpageHeader";

export const Members = () => {
  const { t } = useTranslation();

  const [companyList, setCompanyList] = useState([]);
  const [municipalityList, setMunicipalityList] = useState([]);
  const [patronageList, setPatronageList] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [boardOfDirectorsMap, setBoardOfDirectorsMap] = useState({});

  const getJoinMember = async () => {
    const joinMemberMap = await joinMemberApi.getAll();
    setCompanyList(joinMemberMap['company'])
    setMunicipalityList(joinMemberMap['municipality'])
    setPatronageList(joinMemberMap['patronage'])
    setAudienceList(joinMemberMap['audience'])
    setBoardOfDirectorsMap(joinMemberMap['boardOfDirectors'])
  }

  useEffect(() => {
    getJoinMember()
  }, []);

  return (
    <Box>
      <Box>
        <SubpageHeader
          title="Members"
          breadcrumb={[{ name: "理事及び会員一覧", link: "/Members" }]}
        />
      </Box>
      <Box sx={{ margin: "0 auto", padding: "20px", maxWidth: "1024px" }}>
        <List sx={{ maxWidth: "650px" }}>
          <ListItem>
            <Link to="/shunsuke-managi">
              <ListItemText primary={"理事長 " + boardOfDirectorsMap?.chairman?.name} />
            </Link>
          </ListItem>
          {
            boardOfDirectorsMap?.directors?.map((director) => {
              return (
                <ListItem>
                  <ListItemText
                    primary={director?.name && director?.affiliation
                      ? "理事 " + director?.name +  "（" + director.affiliation + "）"
                      : "理事 " + director?.name
                    }
                  />
                </ListItem>
              )
            })
          }
          <ListItem>
            <ListItemText sx={{ textAlign: "center" }} primary="・" />
          </ListItem>
          {
            boardOfDirectorsMap?.auditors?.map((auditor) => {
              return (
                <ListItem>
                  <ListItemText
                    primary={auditor?.name && auditor?.affiliation
                      ? "監事 " + auditor?.name +  "（" + auditor.affiliation + "）"
                      : "監事 " + auditor?.name
                    }
                  />
                </ListItem>
              )
            })
          }
        </List>
        <hr />
        <Box sx={{ marginLeft: "80px" }}>（五十音順）</Box>
        <table>
          <tr>
            <th>参画企業</th>
            {/* TODO：ハードコーディング微妙すぎるのでマスタから取得するようにしたい */}
            <td>
              <br />
              {
                companyList.map((company) => {
                  return (
                    <>
                      { company } <br />
                    </>
                  )
                })
              }
            </td>
          </tr>
          <hr />
          <tr>
            <th>参画自治体</th>
            <td>
              {
                municipalityList.map((municipality) => {
                  return (
                    <>
                      { municipality } <br />
                    </>
                  )
                })
              }
            </td>
          </tr>
          <hr />
          <tr>
            <th>後援</th>
            <td>
              {
                patronageList.map((patronage) => {
                  return (
                    <>
                      { patronage } <br />
                    </>
                  )
                })
              }
            </td>
          </tr>
          <hr />
          <tr>
            <th>聴講参加</th>
            <td>
              {
                audienceList.map((audience) => {
                  return (
                    <>
                      { audience } <br />
                    </>
                  )
                })
              }
            </td>
          </tr>
        </table>
      </Box>
      <ScrollToTopOnMount />
    </Box>
  );
};
