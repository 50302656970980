import React from "react";
import projectApi from "../../../api/projectApi";
import actWorkApi from "../../../api/actWorkApi";
import branchApi from "../../../api/branchApi";
import branchUserApi from "../../../api/branchUserApi";
import { DataGrid, GridColDef, GridRowsProp, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/Global.css";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Badge from "@mui/material/Badge";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";

export const ParticipantsList = () => {
  const [list, setList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filter, setFilter] = useState("all");
  const [projectIdFilter, setProjectIdFilter] = useState("all");
  const storedUserInfo = useSelector((state) => state.user);
  const [checkedActWorks, setCheckedActWorks] = useState([]);
  const dispatch = useDispatch();

  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const [tabValue, setTabValue] = useState("1");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [res, setRes] = useState([]);
  const [hasBranchMaster, setHasBranchMaster] = useState(false);

  // フィルターを適用したリストを作成
  const ChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  //理事会一覧
  const filteredList = list.filter((work) => {
    if (!checkedActWorks.includes(work._id)) {
      work.isNotice = true;
    }
    return (
      (projectIdFilter === "all" || work.projectId === projectIdFilter) &&
      work.applyflg === false &&
      work.status !== "9"
    );
  });

  //正会員対応済一覧
  const examinationList = list.filter(
    (work) =>
      (projectIdFilter === "all" || work.projectId === projectIdFilter) &&
      ((work.branchMasterId &&
        work.branchMasterId === storedUserInfo.value._id &&
        work.applyflg !== false &&
        work.status !== "9") ||
        (work.applicantId && work.applicantId === storedUserInfo.value._id && work.status !== "9"))
  );

  //差戻一覧
  const filteredReturn = res.filter(
    (work) =>
      work.applicantId && work.applicantId === storedUserInfo.value._id && work.status === "9"
  );

  //その他一覧
  const myList = res.filter(
    (work) =>
      work.applicantId && work.applicantId === storedUserInfo.value._id && work.status !== "9"
  );

  //正会員申請一覧
  const unExaminationList = list.filter((work) => {
    if (!checkedActWorks.includes(work._id)) {
      work.isNotice = true;
    }
    return (
      (projectIdFilter === "all" || work.projectId === projectIdFilter) &&
      work.branchMasterId &&
      work.branchMasterId === storedUserInfo.value._id &&
      work.applyflg === false &&
      work.status !== "9"
    );
  });

  useEffect(() => {
    async function fetchProjectList() {
      const response = await projectApi.getList({
        params: {
          applyflg: 1,
        },
      });
      const branchs = await branchApi.getTargetList({
        params: {
          branchMasterId: storedUserInfo.value._id,
          applyflg: 1,
        },
      });
      const branchUser = await branchUserApi.getTargetList({
        params: { userId: storedUserInfo.value._id },
      });
      const branchIds = branchs.branchs.map((branch) => branch._id);
      const branchUserIds = branchUser.branchUsers.map((branchUesr) => branchUesr.branchId._id);
      let filteredProjects = [];
      // 理事会の場合
      if (RoleType.BoardOfDirectors === authUser.roleType) {
        filteredProjects = response.projects;
      } //一般会員、オブザーバー会員の場合
      else if (
        RoleType.ObserverMember === authUser.roleType ||
        RoleType.GeneralMember === authUser.roleType
      ) {
        filteredProjects = response.projects.filter(
          (project) => project.branchId && branchUserIds.includes(project.branchId._id)
        );
      } else {
        filteredProjects = response.projects.filter(
          (project) => project.branchId && branchIds.includes(project.branchId._id)
        );
      }
      setProjects(filteredProjects);

      //actWorkListの作成
      const projectIds = filteredProjects.map((project) => project._id);
      const hasBranchMaster = (projectIds && projectIds.length);
      setHasBranchMaster(hasBranchMaster);
      let conditions = {};
      // 理事会の場合、参加待ち全て表示
      if (RoleType.BoardOfDirectors === authUser.roleType) {
        conditions = { status: "0" };
      // 支部長の場合
      } else if (hasBranchMaster) {
          conditions = { projectId: { $in: projectIds }};
      // 理事会、支部長でもない場合、差し戻し対象を取得
      } else {
        conditions = { status: "9" };
      }

      const res = await actWorkApi.getList(conditions);
      const filteredActWorks = res.ActWorks;

      // 作業名とリンク先の
      let actWorks = [];
      let actWorkProjectIds = [];
      for (let i = 0; i < filteredActWorks.length; i++) {
        const actWorkData = {
          _id: filteredActWorks[i]._id,
          projectId: filteredActWorks[i].projectId?._id || "（空白）",
          projectName: filteredActWorks[i].projectId?.projectName || "（空白）",
          workId: filteredActWorks[i]._id,
          workName: filteredActWorks[i].workId?.workName || "(空白)",
          period: filteredActWorks[i].period,
          username: filteredActWorks[i].userId?.username || "（空白）",
          estimate: filteredActWorks[i].estimate,
          applyflg: filteredActWorks[i].applyflg,
          status: filteredActWorks[i].status,
          applicantId: filteredActWorks[i].applicantId || "（空白）",
          branchMasterId: filteredActWorks[i].projectId?.branchId.branchMasterId || "（空白）",
        };

        actWorks.push(actWorkData);
        if (actWorkData.status !== "9") {
          actWorkProjectIds.push(filteredActWorks[i].projectId?._id);
        }
      }
      setList(actWorks);

      //セレクトボックスの作成
      setProjects(filteredProjects.filter((fpj) => actWorkProjectIds.includes(fpj._id)));

      // 差し戻し対象の取得
      let filteredReturnWorks = [];
      // 支部長の場合は再度取得する
      if (hasBranchMaster) {
        const returnRes = await actWorkApi.getList({ status: "9" });
        filteredReturnWorks = returnRes.ActWorks;
      } else {
        filteredReturnWorks = res.ActWorks;
      }
      // 作業名とリンク先の
      let returnActWorks = [];
      for (let i = 0; i < filteredActWorks.length; i++) {
        const returnActWork = {
          _id: filteredActWorks[i]._id,
          projectId: filteredActWorks[i].projectId?._id || "（空白）",
          projectName: filteredActWorks[i].projectId?.projectName || "（空白）",
          workId: filteredActWorks[i]._id,
          workName: filteredActWorks[i].workId?.workName || "(空白)",
          period: filteredActWorks[i].period,
          username: filteredActWorks[i].userId?.username || "（空白）",
          estimate: filteredActWorks[i].estimate,
          applyflg: filteredActWorks[i].applyflg,
          status: filteredActWorks[i].status,
          applicantId: filteredActWorks[i].applicantId || "（空白）",
        };
        returnActWorks.push(returnActWork);
      }

      setList(actWorks);
      setRes(returnActWorks);
    }
    setRole(authUser.roleType);
    fetchProjectList();
    // 通知バッチ判定情報の取得
    getCheckedActWorkList();
  }, []);

  const getCheckedActWorkList = async () => {
    const checkedActWorkMap = await actWorkApi.getActWorkCheckLog({
      params: { operateUserId: storedUserInfo.value._id },
    });
    let checkedActWorkIds = [];
    checkedActWorkMap.actWorkCheckLogList.map((checkedActWork) => {
      checkedActWorkIds.push(checkedActWork.checkedActWorkId);
    });
    setCheckedActWorks(checkedActWorkIds);
  };

  const handleToDetail = async (checkedActWorkId) => {
    // 未確認の場合のみ確認済みログを作成する
    if (role === RoleType.BoardOfDirectors && !checkedActWorks.includes(checkedActWorkId)) {
      await actWorkApi.createActWorkCheckLog({
        operateUserId: storedUserInfo.value._id,
        checkedActWorkId: checkedActWorkId,
      });
      dispatch(setUpdateBadgeFlg(true));
    }
  };

  // セレクトボックスの値が変更されたときにフィルターを更新する関数
  const handleFilterChange = (event) => {
    // setPageNumber(0); // ページ番号を最初のページにリセットする
    setFilter(event.target.value);
  };

  // programIdのセレクトボックスの値が変更されたときにフィルターを更新する関数
  const handleProjectIdFilterChange = (event) => {
    // setPageNumber(0);
    const value = event.target.value;
    setProjectIdFilter(value);
  };

  const columns: GridColDef[] = [
    {
      field: "isNotice",
      headerName: "",
      width: 5,
      renderCell: (params) => {
        return <Box>{params.value && <Badge color="warning" variant="dot"></Badge>}</Box>;
      },
    },
    {
      field: "projectId",
      headerName: "プロジェクト名",
      width: 350,
      renderCell: (params) => {
        return <Typography component="body2">{params.row.projectName}</Typography>;
      },
    },
    {
      field: "workItem",
      headerName: "作業名",
      width: 350,
      renderCell: (params) => {
        return (
          <Typography component="body2">
            <Link
              to={`/member/project_applicants/${params.row.workId}`}
              onClick={() => handleToDetail(params.row._id)}
            >
              {params.row.workName}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "period",
      headerName: "期間",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography component="body2">
            <div style={{ flexBasis: "300px" }}>
              {moment(params.row.period[0]).format("YYYY/MM/DD")} -{" "}
              {moment(params.row.period[1]).format("YYYY/MM/DD")}
            </div>
          </Typography>
        );
      },
    },
    {
      field: "userId",
      headerName: "参加希望者",
      width: 200,
      renderCell: (params) => {
        return <Typography component="body2">{params.row.username}</Typography>;
      },
    },
    {
      field: "estimate",
      headerName: "見積",
      width: 200,
      renderCell: (params) => {
        return <Typography component="body2">{Number(params.value).toLocaleString() + " 円"}</Typography>;
      },
    },
  ];

  const examinationColumns: GridColDef[] = [
    {
      field: "projectId",
      headerName: "プロジェクト名",
      width: 350,
      renderCell: (params) => {
        return <Typography component="body2">{params.row.projectName}</Typography>;
      },
    },
    {
      field: "workItem",
      headerName: "作業名",
      width: 350,
      renderCell: (params) => {
        return (
          <Typography component="body2">
            <Link
              to={`/member/project_applicants/${params.row.workId}`}
              onClick={() => handleToDetail(params.row._id)}
            >
              {params.row.workName}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "period",
      headerName: "期間",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography component="body2">
            <div style={{ flexBasis: "300px" }}>
              {moment(params.row.period[0]).format("YYYY/MM/DD")} -{" "}
              {moment(params.row.period[1]).format("YYYY/MM/DD")}
            </div>
          </Typography>
        );
      },
    },
    {
      field: "userId",
      headerName: "参加希望者",
      width: 200,
      renderCell: (params) => {
        return <Typography component="body2">{params.row.username}</Typography>;
      },
    },
    {
      field: "estimate",
      headerName: "見積",
      width: 200,
      renderCell: (params) => {
        return <Typography component="body2">{Number(params.value).toLocaleString() + " 円"}</Typography>;
      },
    },
    {
      field: "status",
      headerName: "承認ステータス",
      width: 130,
      renderCell: (params) => {
        //        console.log("applyflg -> ", params.row.applyflg);
        const status = params.row.status;
        let statusText = "";
        let statusColor = "";
        if (status === "0") {
          statusText = "未審査";
          statusColor = "orange";
        } else if (status === "1") {
          statusText = "承認";
          statusColor = "aqua";
        } else if (status === "2") {
          statusText = "否認";
          statusColor = "magenta";
        }
        return (
          <Box sx={{ p: 1 }}>
            <Typography component="body2" style={{ color: statusColor }}>
              {statusText}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const pageSizeOptions = [10, 25, 100];

  const returnColumns: GridColDef[] = [
    {
      field: "projectId",
      headerName: "プロジェクト名",
      width: 350,
      renderCell: (params) => {
        return <Typography component="body2">{params.row.projectName}</Typography>;
      },
    },
    {
      field: "workItem",
      headerName: "作業名",
      width: 350,
      renderCell: (params) => {
        return <Typography component="body2">{params.row.workName}</Typography>;
      },
    },
    {
      field: "period",
      headerName: "期間",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography component="body2">
            <div style={{ flexBasis: "300px" }}>
              {moment(params.row.period[0]).format("YYYY/MM/DD")} -{" "}
              {moment(params.row.period[1]).format("YYYY/MM/DD")}
            </div>
          </Typography>
        );
      },
    },
    {
      field: "userId",
      headerName: "参加希望者",
      width: 200,
      renderCell: (params) => {
        return <Typography component="body2">{params.row.username}</Typography>;
      },
    },
    {
      field: "estimate",
      headerName: "見積",
      width: 200,
      renderCell: (params) => {
        return <Typography component="body2">{Number(params.value).toLocaleString() + " 円"}</Typography>;
      },
    },
    {
      field: "_id",
      headerName: "",
      width: 150,
      align: "center",
      renderCell: (params) => {
        return (
          <Link to={"/member/projects/" + params.row.projectId + "/join?actWorkId=" + params.value}>
            <Button variant="contained" sx={{ fontSize: "5px" }}>
              修正
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          参加申請者一覧
        </Typography>
      </div>

      {/* セレクトボックスを追加 */}
      {(role === RoleType.BoardOfDirectors || hasBranchMaster === true) && (
        <div style={{ marginBottom: "20px" }}>
          <Typography variant="body1" gutterBottom>
            プロジェクト名：
            <select value={projectIdFilter} onChange={handleProjectIdFilterChange}>
              <option value="all">すべて</option>
              {projects.map((project) => (
                <option key={project._id} value={project._id}>
                  {project.projectName}
                </option>
              ))}
            </select>
          </Typography>
        </div>
      )}
      {/* 承認者の表示 */}
      {role === RoleType.FullMember && (
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={ChangeTab} aria-label="lab API tabs example">
              <Tab label="申請一覧" value="1" />
              <Tab label="対応済一覧" value="2" />
              <Tab label="差し戻し対象" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box sx={{ display: "flex", padding: "5px" }}>
              <div style={{ height: "max-content", width: "100%" }}>
                <DataGrid
                  rows={unExaminationList}
                  columns={columns}
                  getRowId={(list) => list._id}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={pageSizeOptions}
                  autoHeight
                  key={isButtonDisabled}
                />
              </div>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box sx={{ display: "flex", padding: "5px" }}>
              <div style={{ height: "max-content", width: "100%" }}>
                <DataGrid
                  rows={examinationList}
                  columns={examinationColumns}
                  getRowId={(list) => list._id}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={pageSizeOptions}
                  autoHeight
                  key={isButtonDisabled}
                />
              </div>
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box sx={{ display: "flex", padding: "5px" }}>
              <div style={{ height: "max-content", width: "100%" }}>
                <DataGrid
                  rows={filteredReturn}
                  columns={returnColumns}
                  getRowId={(list) => list._id}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={pageSizeOptions}
                  autoHeight
                  key={isButtonDisabled}
                />
              </div>
            </Box>
          </TabPanel>
        </TabContext>
      )}
      {/* 理事会の表示 */}
      {role === RoleType.BoardOfDirectors && (
        <Box sx={{ width: "100%", display: "flex", padding: "5px" }}>
          <div style={{ height: "max-content", width: "100%" }}>
            <DataGrid
              rows={filteredList}
              columns={examinationColumns}
              getRowId={(list) => list._id}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={pageSizeOptions}
              autoHeight
              key={isButtonDisabled}
            />
          </div>
        </Box>
      )}
      {/* その他の表示 */}
      {role !== RoleType.BoardOfDirectors && role !== RoleType.FullMember && (
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={ChangeTab} aria-label="lab API tabs example">
              <Tab label="申請一覧" value="1" />
              <Tab label="差し戻し対象" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box sx={{ display: "flex", padding: "5px" }}>
              <div style={{ height: "max-content", width: "100%" }}>
                <DataGrid
                  rows={myList}
                  columns={examinationColumns}
                  getRowId={(list) => list._id}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={pageSizeOptions}
                  autoHeight
                  key={isButtonDisabled}
                />
              </div>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box sx={{ display: "flex", padding: "5px" }}>
              <div style={{ height: "max-content", width: "100%" }}>
                <DataGrid
                  rows={filteredReturn}
                  columns={returnColumns}
                  getRowId={(list) => list._id}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={pageSizeOptions}
                  autoHeight
                  key={isButtonDisabled}
                />
              </div>
            </Box>
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
};
