import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import orgChartJa from "../../assets/imgs/nccc_orgchart_ja.png";
import orgChartEn from "../../assets/imgs/nccc_orgchart_en.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NCCC_logo_horizon from "../../assets/imgs/NCCC_logo_horizon.png";
import { SubpageHeader } from "../../components/homepage/SubpageHeader";
import ScrollToTopOnMount from "../../components/common/utils/ScrollToTopOnMount";
import classes from "../../assets/css/layout/organization.module.css";
import joinMemberApi from "../../api/joinMember";

export const Organization = () => {
  const { t } = useTranslation();

  const [boardOfDirectorsMap, setBoardOfDirectorsMap] = useState({});

  const getJoinMember = async () => {
    const joinMemberMap = await joinMemberApi.getAll();
    setBoardOfDirectorsMap(joinMemberMap['boardOfDirectors'])
  }

  useEffect(() => {
    getJoinMember()
  }, []);

  return (
    <Box
      sx={{
        background: "#F6F6F6",
        minHeight: "100Vh",
        paddingBottom: "1rem",
      }}
    >
      <SubpageHeader
        title={t("organization.title")}
        breadcrumb={[{ name: "社団概要", link: "/organization" }]}
      />

      <div style={wrapper}>
        <div style={content_wrapper}>
          <div style={title_font_text} className={classes.organizationtitle}>
            {t("organization.labels.label1")}
          </div>
        </div>
        <div>
          <div style={content_detail}>
            <div>
              <img
                src={NCCC_logo_horizon}
                alt="logo"
                style={{ width: "450px", maxWidth: "100%" }}
              />
            </div>
            <div style={{ margin: "100px 0px" }}>
              <div css={table_grid}>
                <div css={table_grid_title}>{t("organization.labels.label2")}</div>
                <div>{t("organization.contents.content1")}</div>
              </div>
              <div css={table_grid}>
                <div css={table_grid_title}>{t("organization.labels.label3")}</div>
                <Link to="/shunsuke-managi">
                  <div>{t("organization.contents.content2")}</div>
                </Link>
              </div>
              <div css={table_grid}>
                <div css={table_grid_title}>{t("organization.labels.label4")}</div>
                <div>
                  {
                    boardOfDirectorsMap?.directors?.map((director) => {
                      return (
                        <>
                          {director?.name && director?.affiliation
                                ? director?.name +  "（" + director.affiliation + "）"
                                : director?.name
                          }
                          <br />
                        </>
                      )
                    })
                  }
                </div>
              </div>
              <div css={table_grid}>
                <div css={table_grid_title}>{t("organization.labels.label5")}</div>
                <div>{
                    boardOfDirectorsMap?.auditors?.map((auditor) => {
                      return (
                        <>
                          {auditor?.name && auditor?.affiliation
                                ? auditor?.name +  "（" + auditor.affiliation + "）"
                                : auditor?.name
                          }
                          <br />
                        </>
                      )
                    })
                  }
                </div>
              </div>
              <div css={table_grid}>
                <div css={table_grid_title}>{t("organization.labels.label6")}</div>
                <div>{t("organization.contents.content5")}</div>
              </div>
              <div css={table_grid}>
                <div css={table_grid_title}>
                  {t("organization.labels.label8")}
                  <br />
                  ・
                  <br />
                  {t("organization.labels.label12")}
                </div>
                <div>
                  {t("organization.contents.content3")}
                  <br />
                  <Link to="/about">{t("organization.contents.content7")}</Link>
                </div>
              </div>
              <div css={table_grid_large}>
                <div css={table_grid_title}>{t("organization.labels.label9")}</div>
                <div>
                  <img
                    src={
                      t("organization.contents.content9") === "ja" ? orgChartJa : orgChartEn
                    }
                    alt="orgChart"
                    css={organization_img}
                  />
                </div>
              </div>
              <div css={table_grid}>
                <div css={table_grid_title}>{t("organization.labels.label10")}</div>
                <div>
                  <Link to="/members">{t("organization.contents.content6")}</Link>
                </div>
              </div>
              <div css={table_grid_last}>
                <div css={table_grid_title}>{t("organization.labels.label11")}</div>
                <div>
                  <Link to="/constitution">{t("organization.contents.content8")}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTopOnMount />
    </Box>
  );
};
const wrapper = {
  margin: "0 auto",
  padding: "20px",
  maxWidth: "1024px",
};

const content_wrapper = {
  maxWidth: "1600px",
  margin: "0 auto",
  padding: "100px 0",
};

const content_detail = {
  maxWidth: "1024px",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  padding: "0 20px",
};

const title_font_text = {
  position: "relative",
  color: "#3A3A3A",
  fontFamily: "neue-haas-grotesk-display, sans-serif",
  fontSize: "1.5rem",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "2px",
  padding: "1rem 2rem",
  borderBottom: "6px solid #094",
};

const table_grid = css`
  display: grid;
  grid-template-rows: 200px;
  grid-template-columns: 150px 1fr;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  @media all and (max-width: 780px) {
    grid-template-rows: 70px 1fr;
    grid-template-columns: none;
    padding: 20px 0;
  }
`;

const table_grid_large = css`
  display: grid;
  grid-template-rows: 550px;
  grid-template-columns: 150px 1fr;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  @media all and (max-width: 480px) {
    grid-template-rows: 70px 1fr;
    grid-template-columns: none;
    padding: 20px 0;
  }
`;

const organization_img = css`
  height: 500px;
  max-width: 100%;
  @media all and (max-width: 480px) {
    height: auto;
  }
`;

const table_grid_last = css`
  display: grid;
  grid-template-rows: 150px;
  grid-template-columns: 150px 1fr;
  align-items: center;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  @media all and (max-width: 480px) {
    grid-template-rows: 70px 1fr;
    grid-template-columns: none;
    padding: 20px 0;
  }
`;
const table_grid_title = css`
  color: #454545;
  fontsize: 16px;
  fontweight: 700;
  padding: 0px 20px;
  @media all and (max-width: 480px) {
    padding: 0;
  }
`;
