import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  TextField,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AttachFileIcon from "@mui/icons-material/AttachFile";

import authApi from "../api/authApi";
import companyApi from "../api/companyApi";
import "../assets/css/Global.css";
import uploadImg from "../components/common/utils/uploadImg";

const h2Style = {
  backgroundColor: "#00695f",
  color: "#ffffff",
  fontWeight: 700,
  padding: "5px 20px",
};
const boxStyle = {
  padding: "20px 10px",
};
// const generalH3 = {
//   fontSize: "1.5rem",
//   paddingLeft: "1rem",
//   borderLeft: "solid 5px #00695f",
// };

const Register = () => {
  let error = false;

  const navigate = useNavigate();
  const { companyId } = useParams();

  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [annualTurnoverErrText, setAnnualTurnoverErrText] = useState("");
  const [invitedByErrText, setInvitedByErrText] = useState("");
  const [applicationPurposeErrText, setApplicationPurposeErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState("");
  // const [businessCertErrText, setBusinessCertErrText] = useState("");
  const [registerCertErrText, setRegisterCertErrText] = useState("");
  // const [sealCertErrText, setSealCertErrText] = useState("");
  // const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  // const [file3, setFile3] = useState();
  const [isRegistered, setIsRegistered] = useState(false);
  const [registeredCompany, setRegisteredCompany] = useState({});
  const [membership, setMembership] = useState("");
  const [companyStatus, setCompanyStatus] = useState(0);

  useEffect(() => {
    const checkRegister = async () => {
      try {
        const res = await authApi.getCompany({ id: companyId });
        const companyData = res.company;
        if (companyData.annualTurnover) {
          // 申請済みの場合、申請済みページを表示
          setIsRegistered(true);
          setRegisteredCompany(companyData);
        }
        setMembership(companyData.membership)
        setCompanyStatus(companyData.companyStatus)
      } catch (error) {
        console.error(error);
        // 存在しないcompanyIdの場合、入会受付フォームに遷移
        navigate("/accept");
      }
    };
    checkRegister();
  }, [companyId, navigate]);

  const handleSumbit = async (e) => {
    e.preventDefault();

    setAnnualTurnoverErrText("");
    setApplicationPurposeErrText("");
    setInvitedByErrText("");
    setPasswordErrText("");
    setConfirmPasswordErrText("");
    // setBusinessCertErrText("");
    setRegisterCertErrText("");
    // setSealCertErrText("");

    const data = new FormData(e.target);
    const password = data.get("password").trim();
    const annualTurnover = data.get("annualTurnover").trim();
    const applicationPurpose = data.get("applicationPurpose").trim();
    const invitedBy = data.get("invitedBy").trim();
    const confirmPassword = data.get("confirmPassword").trim();
    // const businessCertificates = data.get("businessCertificates");
    const registerCertificates = data.get("registerCertificates");
    // const sealCertificates = data.get("sealCertificates");

    // if (annualTurnover === "") {
    //   error = true;
    //   setAnnualTurnoverErrText("年商を入力してください。");
    // } else 
    if (annualTurnover && isNaN(annualTurnover)) {
      error = true;
      setAnnualTurnoverErrText("年商は半角数字で入力してください。");
    } else if (annualTurnover && annualTurnover.length > 15) {
      error = true;
      setAnnualTurnoverErrText("年商は15桁以下で入力してください。");
    }
    if (applicationPurpose === "") {
      error = true;
      setApplicationPurposeErrText("会員の目的を入力してください。");
    } else if (annualTurnover && annualTurnover.length > 400) {
      error = true;
      setApplicationPurposeErrText("会員の目的は400字以内で入力してください。");
    }
    if (invitedBy.length > 30) {
      error = true;
      setInvitedByErrText("紹介者は30文字以内で入力してください。");
    }
    if (password === "") {
      error = true;
      setPasswordErrText("パスワードを入力してください。");
    } else if (password.length < 8) {
      error = true;
      setPasswordErrText("パスワードは８文字以上である必要があります。");
    }
    if (confirmPassword === "") {
      error = true;
      setConfirmPasswordErrText("確認用パスワードを入力してください。");
    } else if (password !== confirmPassword) {
      error = true;
      setConfirmPasswordErrText("パスワードと確認用パスワードが異なります。");
    }
    // if (businessCertificates.size === 0) {
    //   error = true;
    //   setBusinessCertErrText("許可業種証明書をアップロードしてください。");
    // }
    // if (registerCertificates.size === 0) {
    //   error = true;
    //   setRegisterCertErrText("bizfileをアップロードしてください。");
    // }
    // if (sealCertificates.size === 0) {
    //   error = true;
    //   setSealCertErrText("印鑑証明書をアップロードしてください。");
    // }

    if (error) return;
    setLoading(true);

    try {
      // const [fileUrl, fileUrl2, fileUrl3] = await Promise.all([
      //   uploadImg(businessCertificates, ["certificates_doc"]),
      //   uploadImg(registerCertificates, ["certificates_doc"]),
      //   uploadImg(sealCertificates, ["certificates_doc"]),
      // ]);

      const registerData = {
        companyId,
        annualTurnover: annualTurnover || 0,
        applicationPurpose,
        invitedBy,
        password,
        confirmPassword,
      };

      if (registerCertificates) {
        const [fileUrl2] = await Promise.all([
          uploadImg(registerCertificates, ["certificates_doc"]),
        ]);
        registerData.registerCertificates = fileUrl2
      }

      await authApi.register(registerData);

      // リマインドメール送信
      try {
        const remindMailRequestData = { companyId, mailid: 11 /*,loginId*/ };
        await authApi.sendRemindMail(remindMailRequestData);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
      setLoading(false);
      const updateData = {
        companyId,
        applicationDay: new Date()
      }
      await companyApi.update(updateData);
      navigate("/register_complete");
    } catch (err) {
      const errors = err?.data?.errors;
      if (errors) {
        errors.reverse().forEach((error) => {
          switch (error.param) {
            // case "annualTurnover":
            //   setAnnualTurnoverErrText(error.msg);
            //   break;
            case "applicationPurpose":
              setApplicationPurposeErrText(error.msg);
              break;
            case "invitedBy":
              setInvitedByErrText(error.msg);
              break;
            case "password":
              setPasswordErrText(error.msg);
              break;
            case "confirmPassword":
              setConfirmPasswordErrText(error.msg);
              break;
            // case "businessCertificates":
            //   setBusinessCertErrText(error.msg);
            //   break;
            // case "registerCertificates":
            //   setRegisterCertErrText(error.msg);
            //   break;
            // case "sealCertificates":
            //   setSealCertErrText(error.msg);
            //   break;
            default:
              break;
          }
          if (error.param === "password") {
            setPasswordErrText(error.msg);
          }
        });
      } else {
        alert("送信に失敗しました。");
      }
      setLoading(false);
    }
  };

  function notRegisteredForm() {
    return (
      <Box component="form" onSubmit={handleSumbit} noValidate>
        <Typography component="h2" variant="h6" sx={h2Style}>
          申請情報
        </Typography>
        <Box sx={boxStyle}>
          <TextField
            fullWidth
            id="annualTurnover"
            label="年商"
            margin="normal"
            name="annualTurnover"
            helperText={annualTurnoverErrText}
            disabled={loading}
            error={annualTurnoverErrText !== ""}
          ></TextField>
          <Typography component="span" variant="overline">個人会員は空欄で良い</Typography>

          <TextField
            fullWidth
            id="applicationPurpose"
            label="会員の目的"
            margin="normal"
            name="applicationPurpose"
            required
            multiline
            helperText={applicationPurposeErrText}
            minRows={3}
            maxRows={5}
            error={applicationPurposeErrText !== ""}
          />

          <TextField
            fullWidth
            id="invitedBy"
            label="紹介者（任意）"
            margin="normal"
            name="invitedBy"
            helperText={invitedByErrText}
            disabled={loading}
            error={invitedByErrText !== ""}
          ></TextField>

          {/* bizfileで代用するためコメントアウト */}
          {/* <Box sx={{ p: 2 }}>
            <Typography component="span">許可業種証明書アップロードをお願いします</Typography>
            <br />
            <Box sx={{ display: "flex", mt: 2, gap: 2, alignItems: "center" }}>
              <Button
                variant="contained"
                component="label"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                disabled={loading}
              >
                許可業種証明書アップロード
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  name="businessCertificates"
                />
              </Button>
              {file?.size && <Chip icon={<AttachFileIcon />} label={file.name} />}
            </Box>
            {!!businessCertErrText && (
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  lineHeight: "1",
                  mt: 1,
                }}
              >
                {businessCertErrText}
              </Typography>
            )}
          </Box> */}

          <Box sx={{ p: 2 }}>
            {/* <Typography component="span">登記簿謄本のアップロードをお願いします *</Typography> */}
            <Typography component="span">bizfile のアップロードをお願いします</Typography>
            <br />
            <Box sx={{ display: "flex", mt: 2, gap: 2, alignItems: "center" }}>
              <Button
                variant="contained"
                component="label"
                onChange={(e) => {
                  setFile2(e.target.files[0]);
                }}
                disabled={loading}
              >
                書類アップロード
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  name="registerCertificates"
                />
              </Button>
              {file2?.size && <Chip icon={<AttachFileIcon />} label={file2.name} />}
            </Box>
            {!!registerCertErrText && (
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  lineHeight: "1",
                  mt: 1,
                }}
              >
                {registerCertErrText}
              </Typography>
            )}
          </Box>

          {/* bizfileで代用するためコメントアウト */}
          {/* <Box sx={{ p: 2 }}>
            <Typography component="span">印鑑証明書のアップロードをお願いします *</Typography>
            <br />
            <Box sx={{ display: "flex", mt: 2, gap: 2, alignItems: "center" }}>
              <Button
                variant="contained"
                component="label"
                onChange={(e) => {
                  setFile3(e.target.files[0]);
                }}
                disabled={loading}
              >
                書類アップロード
                <input
                  hidden
                  accept="application/pdf"
                  multiple
                  type="file"
                  name="sealCertificates"
                />
              </Button>
              {file3?.size && <Chip icon={<AttachFileIcon />} label={file3.name} />}
            </Box>
            {!!sealCertErrText && (
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  lineHeight: "1",
                  mt: 1,
                }}
              >
                {sealCertErrText}
              </Typography>
            )}
          </Box> */}
        </Box>
        <Typography component="h2" variant="h6" sx={h2Style}>
          申請内容
        </Typography>
        <Box sx={boxStyle}>
          <TextField
            fullWidth
            id="password"
            label="パスワード"
            margin="normal"
            name="password"
            type="password"
            required
            helperText={passwordErrText}
            disabled={loading}
            error={passwordErrText !== ""}
          ></TextField>
          <TextField
            fullWidth
            id="confirmPassword"
            label="確認用パスワード"
            margin="normal"
            name="confirmPassword"
            type="password"
            required
            helperText={confirmPasswordErrText}
            disabled={loading}
            error={confirmPasswordErrText !== ""}
          ></TextField>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography
              component="a"
              href="/privacypolicy"
              target="blank"
              color="primary"
              sx={{ display: "flex" }}
            >
              <OpenInNewIcon />
              　プライバシーポリシー
            </Typography>
            <Typography
              component="a"
              href="/constitution"
              target="blank"
              color="primary"
              sx={{ display: "flex" }}
            >
              <OpenInNewIcon />
              　NCCC会員規約
            </Typography>
            {
              membership === "fullMember" && (
                <Typography
                  component="a"
                  href="https://firebasestorage.googleapis.com/v0/b/nccc-382601.appspot.com/o/appDocument%2FArticles%20of%20Incorporation%20of%20NCCC.pdf?alt=media&token=9c5fe6d0-398f-428b-a186-51ce0d96d052"
                  target="blank"
                  color="primary"
                  sx={{ display: "flex" }}
                >
                  <InsertDriveFileRoundedIcon />
                  　定款
                </Typography>
              )
            }
          </Box>
          <FormControlLabel
            label={
              membership === "fullMember"
                ? "プライバシーポリシー、NCCC会員規約、定款を確認し、同意します。"
                : "プライバシーポリシー、NCCC会員規約を確認し、同意します。"
            }
            control={
              <Checkbox
                checked={agreement}
                onChange={(event) => {
                  setAgreement(event.target.checked);
                }}
              />
            }
          ></FormControlLabel>

          <LoadingButton
            sx={{ mt: 3, mb: 2 }}
            fullWidth
            type="submit"
            loading={loading}
            color="primary"
            variant="contained"
            disabled={!agreement || isRegistered}
            size="large"
          >
            送信
          </LoadingButton>
          <Box>{error}</Box>
        </Box>
      </Box>
    );
  }

  function registeredForm() {
    return (
      <>
        <Alert severity="success" sx={{ fontWeight: "600" }}>
          申請は以下内容で、送信済みです。
        </Alert>
        <Box component="form" onSubmit={handleSumbit} noValidate>
          <Typography component="h2" variant="h6" sx={h2Style}>
            申請情報
          </Typography>
          <Box sx={boxStyle}>
            <TextField
              fullWidth
              label="年商"
              value={registeredCompany.annualTurnover}
              disabled={true}
              margin="normal"
            ></TextField>

            <TextField
              label="会員の目的"
              fullWidth
              multiline
              value={registeredCompany.applicationPurpose}
              disabled={true}
              margin="normal"
            />

            <TextField
              fullWidth
              label="紹介者（任意）"
              margin="normal"
              value={registeredCompany.invitedBy ? registeredCompany.invitedBy : "未記入"}
              disabled={true}
            ></TextField>

            {/* <Box sx={{ p: 2 }}>
              <Typography component="span">許可業種証明書アップロード</Typography>
              <br />
              <Box sx={{ display: "flex", mt: 2, gap: 2, alignItems: "center" }}>
                <a href={registeredCompany.businessCertificates} target="_blank" rel="noreferrer">
                  <Chip icon={<AttachFileIcon />} label={"添付ファイル"} />
                </a>
              </Box>
            </Box> */}

            <Box sx={{ p: 2 }}>
              {/* <Typography component="span">登記簿謄本アップロード</Typography> */}
              <Typography component="span">bizfile アップロード</Typography>
              <br />
              <Box sx={{ display: "flex", mt: 2, gap: 2, alignItems: "center" }}>
                <a href={registeredCompany.registerCertificates} target="_blank" rel="noreferrer">
                  <Chip icon={<AttachFileIcon />} label={"添付ファイル"} />
                </a>
              </Box>
            </Box>

            {/* <Box sx={{ p: 2 }}>
              <Typography component="span">印鑑証明書アップロード</Typography>
              <br />
              <Box sx={{ display: "flex", mt: 2, gap: 2, alignItems: "center" }}>
                <a href={registeredCompany.sealCertificates} target="_blank" rel="noreferrer">
                  <Chip icon={<AttachFileIcon />} label={"添付ファイル"} />
                </a>
              </Box>
            </Box> */}
          </Box>
          <Typography component="h2" variant="h6" sx={h2Style}>
            申請内容
          </Typography>
          <Box sx={boxStyle}>
            <TextField
              fullWidth
              id="password"
              label="パスワード"
              margin="normal"
              name="password"
              type="password"
              value="**********"
              disabled={true}
            ></TextField>
            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography
                component="a"
                href="/privacypolicy"
                target="blank"
                color="primary"
                sx={{ display: "flex" }}
              >
                <OpenInNewIcon />
                　プライバシーポリシー
              </Typography>
              <Typography
                component="a"
                href="/constitution"
                target="blank"
                color="primary"
                sx={{ display: "flex" }}
              >
                <OpenInNewIcon />
                　NCCC会員規約
              </Typography>
              {
                membership === "fullMember" && (
                  <Typography
                    component="a"
                    href="https://firebasestorage.googleapis.com/v0/b/nccc-382601.appspot.com/o/appDocument%2FArticles%20of%20Incorporation%20of%20NCCC.pdf?alt=media&token=9c5fe6d0-398f-428b-a186-51ce0d96d052"
                    target="blank"
                    color="primary"
                    sx={{ display: "flex" }}
                  >
                    <InsertDriveFileRoundedIcon />
                    　定款
                  </Typography>
                )
              }
            </Box>
            <FormControlLabel
              label={
                membership === "fullMember"
                  ? "プライバシーポリシー、NCCC会員規約、定款を確認し、同意します。"
                  : "プライバシーポリシー、NCCC会員規約を確認し、同意します。"
              }
              control={<Checkbox checked={true} disabled={true} />}
            ></FormControlLabel>
            <Box>{error}</Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ maxWidth: "1024px", margin: "0 auto", padding: "100px 0px" }}>
      {
        companyStatus === 90
        ? <Typography component="h2" variant="h6">審査はキャンセルされているため、このフォームは使用できません。</Typography>
        : isRegistered
          ? registeredForm()
          : notRegisteredForm()}
    </Box>
  );
};

export default Register;
