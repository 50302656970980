import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import HomepageSideBar from "../homepage/HomepageSideBar";
import Menu from "../homepage/Menu";

const HomepageLayout = () => {
  return (
    <Box sx={{ display: "flex", fontFamily: "Kozuka Gothic Pro" }}>
      <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Menu />
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: { xs: "60px", md: "0" },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default HomepageLayout;
