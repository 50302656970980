import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  FormControl,
  Checkbox,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import { AttachFile } from "@mui/icons-material";
import { MuiFileInput } from "mui-file-input";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import userApi from "../../../api/userApi";
import branchApi from "../../../api/branchApi";
import companyApi from "../../../api/companyApi";
import mailApi from "../../../api/mailApi";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import uploadImg from "../../../components/common/utils/uploadImg";

export const MemberBranchCompose = () => {
  const navigate = useNavigate();
  const [isBehalf, setIsBehalf] = useState(false);
  const storedUserInfo = useSelector((state) => state.user);
  const theme = useTheme();
  const [option, setOption] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const branchId = urlParams.get("branchId");
  const [branch, setBranch] = useState({});

  const [userList, setUserList] = useState({
    entities: {},
    allIds: [],
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name, option, theme) {
    return {
      fontWeight:
        option.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  useEffect(() => {
    async function fetchUserList() {
      const users = await userApi.getTargetList({
        params: { companyId: storedUserInfo.value.companyId },
      });
      const updatedUserList = {
        entities: {},
        allIds: [],
      };
      for (let i = 0; i < users.users.length; i++) {
        const user = users.users[i];
        // 正会員で絞る
        if (user.membership !== "fullMember") {
          continue;
        }
        updatedUserList.entities[user._id] = user.username ? user.username : user.name;
        updatedUserList.allIds.push(user._id);
      }
      setUserList(updatedUserList);
    }
    fetchUserList();
    fetchBranch();
  }, []);

  async function fetchBranch() {
    if (branchId) {
      const res = await branchApi.getById({ params: { _Id: branchId } });
      setBranch(res.branch);
      setValue("branchName", res.branch.branchName);
      setValue("secretariatAddress", res.branch.secretariatAddress);
      setValue("activityPurpose", res.branch.activityPurpose);
      setValue("branchMasterId", res.branch.branchMasterId._id);

      const branchMasterId = String(res.branch.branchMasterId._id);
      const createdUser = String(res.branch.createdUser);

      if (branchMasterId !== createdUser) {
        setIsBehalf(true);
        setValue("subBranchMasterId", branchMasterId);
      }
    }
  }

  // validation
  const { control, formState, register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      branchName: "",
      secretariatAddress: "",
      activityPurpose: "",
      appDocument: "",
      subBranchMasterId: "",
      createdUser: "",
    },
  });

  const validationRules = {
    subBranchMasterId: {
      validate: (value) => {
        if (isBehalf && !value) {
          return "代理申請にチェックがある場合は支部長名を選択してください";
        }
      },
    },
    branchName: {
      required: "支部名を入力してください",
      maxLength: { value: 20, message: "20文字以内で入力してください" },
    },
    secretariatAddress: {
      required: "支部所在地を入力してください",
      maxLength: { value: 50, message: "50文字以内で入力してください" },
    },
    activityPurpose: {
      required: "支部の役割・目的・活動内容を入力してください",
      maxLength: { value: 800, message: "800文字以内で入力してください" },
    },
    appDocument: {
      validate: !branch.appDocument && {
        isExists: (value) => value || "支部組成申請書類をアップロードしてください",
        isPdf: (value) => value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
  };

  const sendApplyMail = async (data, branch) => {
    const user = storedUserInfo.value;
    const company = await companyApi.getCompany(data.companyId);
    const branchMaster = await userApi.getById(data.branchMasterId);
    const replaceWords = {
      applyUserName: user.username,
      groupName: company?.groupName,
      branchName: branch.branchName,
      branchMasterName: branchMaster.username,
      branchAddress: data.secretariatAddress,
      branchPurpose: data.activityPurpose,
      planDocuments: data.appDocument,
      url: "/member/branches/" + branch._id,
    };

    const reqData = { mailId: "23", replaceWords };
    return mailApi.apply(reqData);
  };

  const onSubmit = async (data) => {
    if (window.confirm("申請します。よろしいですか？")) {
      // 書類が更新されていればファイルをアップロード
      const url = data.appDocument
        ? await uploadImg(data.appDocument, ["appDocument"])
        : branch.appDocument;

      const reqData = {
        branchName: data.branchName,
        branchMasterId: isBehalf ? data.subBranchMasterId : storedUserInfo.value._id,
        secretariatAddress: data.secretariatAddress,
        activityPurpose: data.activityPurpose,
        companyId: storedUserInfo.value.companyId,
        createdUser: storedUserInfo.value._id,
        appDocument: url,
        userId: [storedUserInfo.value._id],
      };

      // 差し戻されたものを再修正した場合
      if (branchId && branch.applyflg === 9) {
        reApply(reqData);
      }
      // 通常の申請
      else {
        branchApi
          .register(reqData)
          .then(async (branch) => {
            const mailRes = await sendApplyMail(reqData, branch);
            return mailRes;
          })
          .then(() => {
            alert("申請しました");
            navigate("/member/branches");
          })
          .catch((e) => {
            console.error(e);
            alert("申請に失敗しました");
          });
      }
    }
  };

  // 再申請処理
  const reApply = async (data) => {
    const requestData = {
      _id: branchId,
      branchName: data.branchName,
      branchMasterId: data.branchMasterId,
      secretariatAddress: data.secretariatAddress,
      activityPurpose: data.activityPurpose,
      appDocument: data.appDocument,
      applyflg: 0,
      reviewId: branch.reviewId,
    };
    branchApi
      .modify(requestData)
      .then(() => {
        alert("再申請しました");
        navigate("/member/branches");
      })
      .catch((e) => {
        console.error(e);
        alert("再申請に失敗しました");
      });
  };

  ///////////////////////
  // file type指定テスト //
  ///////////////////////

  // const ref = useRef(null);
  // const handleChange = (e) => {
  //   if (!e.target.files) return;
  //   const files = Array.from(e.target.files);
  //   setFile(files);
  // };
  // const getFileNames = () =>
  //   file?.reduce(
  //     (fileNames, file) =>
  //       `${fileNames} ${fileNames !== "" ? "," : ""} ${file.name}`,
  //     ""
  //   ) || "";

  ///////////////////////

  return (
    <div>
      <h3>支部組成申請</h3>
      {branch.applyflg === 9 && (
        <Typography mb={2} style={{ color: "#f44336" }}>
          差し戻しコメント：{branch.approvalComment}
        </Typography>
      )}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid xs={12} lg={4}>
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography sx={{ display: "inline" }}>
                申請者名：<strong>{storedUserInfo?.value.username}</strong>
              </Typography>
            </Box>
            {/* 現状、1会社1ユーザー想定の為、代理申請は不可の為、コメントアウト  */}
            {/* <Box sx={{ marginBottom: "2rem" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ display: "inline" }}>代理申請</Typography>
                <Checkbox
                  checked={isBehalf}
                  onChange={() => {
                    setIsBehalf(!isBehalf);
                    if (isBehalf) setValue("subBranchMasterId", ""); // branchManagerNameを空にする
                  }}
                />
              </Box>
              <Controller
                name="subBranchMasterId"
                control={control}
                rules={validationRules.subBranchMasterId}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid} disabled={!isBehalf}>
                    <InputLabel>支部長名*</InputLabel>
                    <Select
                      {...field}
                      input={<OutlinedInput label="支部長名" />}
                      MenuProps={MenuProps}
                    >
                      {userList.allIds.map((id) => (
                        <MenuItem key={id} value={id} style={getStyles(id, option, theme)}>
                          {userList.entities[id]}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.invalid && (
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={4}> */}
            <Typography mb={2}>支部名称</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="branchName"
                control={control}
                rules={validationRules.branchName}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="branchName"
                    label="支部名称"
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="secretariatAddress"
                control={control}
                rules={validationRules.secretariatAddress}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="secretariatAddress"
                    label="支部所在地"
                    multiline
                    rows={3}
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="activityPurpose"
                control={control}
                rules={validationRules.activityPurpose}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="activityPurpose"
                    label="支部の役割・目的・活動内容など"
                    multiline
                    rows={6}
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={4}>
            <Typography mb={2}>支部組成申請書類</Typography>
            {branch.appDocument && (
              <Box sx={{ marginBottom: "2rem" }}>
                提案時の書類：
                <a href={branch.appDocument} target="_blank" rel="noopener noreferrer">
                  確認する場合はこちら
                </a>
              </Box>
            )}
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="appDocument"
                control={control}
                rules={validationRules.appDocument}
                render={({ field, fieldState }) => (
                  <MuiFileInput
                    type="file"
                    fullWidth
                    {...field}
                    error={fieldState.invalid}
                    helperText={
                      fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                    }
                  />
                )}
              />
            </Box>
            <Button
              // disabled={!formState.isDirty || !formState.isValid}
              variant="contained"
              fullWidth
              sx={{ color: "#fff" }}
              type="submit"
            >
              申請
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
