import { Typography, styled } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import ScrollToTopOnMount from "../components/common/utils/ScrollToTopOnMount";

const BoxStyle = {
  margin: "30px 0",
};

const CustomedOl = styled('ol')(({theme}) =>
  ({
    paddingLeft: 0,
    marginLeft: theme.spacing(4),
    "& li": {
      listStyleType: "none",
      counterIncrement: "cnt",
      display: "block",
      position: "relative",
      "&::before": {
        content: `"(" counter(cnt) ") "`,
        marginLeft: -theme.spacing(4),
        width: theme.spacing(5),
        // position: "absolute",
        top: 0,
        left: 0,
      },
    },
  })
)

export const NonAntisocialForcesAgreement = () => {
  return (
    <Box sx={{ maxWidth: "1024px", margin: "110px auto", padding: "20px" }}>
      {/* このmetaタグ効くんですか？？？ */}
      <meta name="robots" content="noindex" />
      <Box sx={{ padding: "30px", backgroundColor: "primary.main" }}>
        <Typography variant="h4" color="white.main">
          反社会的勢力ではないことの表明・確約に関する同意
        </Typography>
      </Box>
      <Box sx={BoxStyle}>
        <Typography variant="body1">
          私（申請者が法人の場合には、当該法人の役員等を含む。以下同じ。）は、これら各項のいずれかに反したと認められることが判明した場合及びこの表明・確約が虚偽の申告であることが判明した場合は、
          貴社団から除名されても一切異議を申し立てず、また損害賠償及び補償等を求めないとともに、これにより貴社団に損害が生じた場合は、一切の損害を賠償することを表明、確約いたします。
        </Typography>
      </Box>

      <Box sx={BoxStyle}>
        １．私は、現在又は将来にわたって、次の各号の反社会的勢力のいずれにも該当しないことを表明、確約いたします。
        <CustomedOl>
        <li>暴力団</li>
        <li>暴力団員</li>
        <li>暴力団準構成員</li>
        <li>暴力団関係企業</li>
        <li>総会屋等、社会運動等標ぼうゴロ</li>
        <li>暴力団員でなくなったときから５年を経過していない者</li>
        <li>特殊知能暴力集団</li>
        <li>その他前各号に準ずる者</li>
        </CustomedOl>
      </Box>

      <Box sx={BoxStyle}>
        ２．私は、現在又は将来にわたって、前項の反社会的勢力又は反社会的勢力と密接な交友関係にある者（以下「反社会的勢力等」という。）と次の各号のいずれかに該当する関係がないことを表明、確約いたします。
        <CustomedOl>
        <li>反社会的勢力等によって、その経営を支配されている関係</li>
        <li>反社会的勢力等が、その経営に実質的に関与している関係</li>
        <li>自己、自社若しくは第三者の不正の利益を図り、又は第三者に損害を加えるなど、反社会的勢力等を利用している関係</li>
        <li>反社会的勢力等に対して資金等を提供し、又は便宜を供与するなどの関係</li>
        <li>その他役員等又は経営に実質的に関与している者が、反社会的勢力等との社会的に非難されるべき関係</li>
        </CustomedOl>
      </Box>

      <Box sx={BoxStyle}>
        ３．私は、自ら又は第三者を利用して次の各号のいずれの行為も行わないことを表明、確約いたします。
        <CustomedOl>
        <li>暴力的な要求行為</li>
        <li>法的な責任を超えた不当な要求行為</li>
        <li>取引に関して脅迫的な言動をし、又は暴力を用いる行為</li>
        <li>風説を流布し、偽計又は威力を用いて貴社団社の信用を毀損し、又は貴社団貴社の業務を妨害する行為</li>
        <li>その他前各号に準ずる行為</li>
        </CustomedOl>
      </Box>

      <Box sx={BoxStyle}>
        <Typography variant="body1">
          以上
        </Typography>
      </Box>
      <ScrollToTopOnMount />
    </Box>
  );
};
