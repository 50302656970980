import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";

import moment from "moment";
import { saveAs } from "file-saver";

import "../../../assets/css/Global.css";
import projectApi from "../../../api/projectApi";
import mailApi from "../../../api/mailApi";
import reviewerApi from "../../../api/reviewerApi";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import { ExaminationChat } from "../examinationCommon";

const itemHeaderStyle = {
  color: "#FFFFFF",
};
const itemValueStyle = {
  color: "#FFFFFF",
  marginLeft: "5px",
  fontWeight: "bold",
};

export const ProjectApply = () => {
  const [projectInfo, setProjectInfo] = useState([]);
  const [work, setWork] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  let [history, setHistory] = useState([]);
  const [commentErrText, setCommentErrText] = useState("");
  const userInfo = useSelector((state) => state.user);
  const authUser = useAuthUserContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const [role, setRole] = useState("");
  const [reviewers, setReviewers] = useState({});
  const [isReviewed, setIsReviewed] = useState(false);

  // console.log(id);
  const historyColumns = [
    {
      field: "created",
      headerName: "審査日時",
      width: 200,
      renderCell: (params) => {
        return (
          params.value && (
            <div style={{ flexBasis: "200px" }}>{moment(params.value).format("YYYY/MM/DD HH:mm:ss")}</div>
          )
        );
      },
    },
    {
      field: "examinationId",
      headerName: "審査者",
      width: 200,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "200px" }}>{params.value.username}</div>;
      },
    },
    {
      field: "result",
      headerName: "審査結果",
      width: 200,
    },
    {
      field: "comment",
      headerName: "コメント",
      width: 400,
    },
  ];
  const downloadDocument = async (path) => {
    try {
      const fileName = "program_application_document";
      saveAs(path, fileName);
    } catch (err) {
      console.error(err);
    }
  };

  const getReviewCategory = () => {
    switch (projectInfo.projectStatus) {
      case "10":
      case "25":
          return "documentExam"
      case "20":
        return "validationExam"
      default:
        return ""
    }
  }

  const handleSubmit = async (buttonValue) => {
    let examinationForm = document.getElementById("examinationForm");
    const data = new FormData(examinationForm);
    const approvalComment = data.get("approvalComment");
    setCommentErrText("");
    if (buttonValue === 9 && approvalComment === "") {
      setCommentErrText("差し戻しコメントを入力してください。");
      return;
    }

    let comment = "";
    let projectStatus = "";
    let isRecruitment = "";
    switch (buttonValue) {
      case 1:
        comment = "承認";
        projectStatus = "1";
        isRecruitment = "0";
        break
      case 2:
        comment = "否認";
        projectStatus = "2";
        isRecruitment = "4";
        break
      default:
        comment = "差し戻し";
        projectStatus = "0";
        isRecruitment = "0";
        break
    }

    if (window.confirm(comment + "します。よろしいですか？")) {
      // const reviewers = await reviewerApi.get({
      //   params: {
      //     funcId: "project",
      //   }
      // });
      const reviewerMyInfo = reviewers.reviewer.workingGroup.filter((reviewer) =>
        reviewer.userId === userInfo.value._id
      )
      const requestData = {
        id: id,
        reviewCategory: getReviewCategory(),
        reviewerRole: reviewerMyInfo[0].role,
        reviewerNum: reviewers.reviewer.workingGroup.length,
        examinationId: userInfo.value._id,
        approvalStatus: buttonValue,
        approvalComment: approvalComment,
        projectStatus: projectStatus,
        isRecruitment: isRecruitment,
        directorCode: userInfo.value.directorCode,
      };

      try {
        //承認／否認　処理
        await projectApi.approval(requestData);
        // 差し戻しの場合、差し戻し通知を送付
        if (buttonValue === 9) {
          const email = projectInfo.applyUserId ? projectInfo.applyUserId.email : "";
          const mailRequestData = {
            applicantName: projectInfo.applyUserId.username,
            targetGenre: "プロジェクト申請",
            targetName: projectInfo.projectName,
            remandReason: approvalComment,
            url: "/member/projects",
            mailaddress: email,
            // TODO 固定値を後で定数クラス等を作成して管理するか検討
            mailid: "18",
          };
          await mailApi.returnpost(mailRequestData);
        }
        const url = "/member/projects";
        navigate(url);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleJump = (n) => {
    setActiveStep(n);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const steps = [
    {
      label: "プロジェクト概要"
    },
    {
      label: "プロジェクト詳細"
    },
    {
      label: "プロジェクトの適格性"
    },
    {
      label: "環境および社会的配慮"
    },
    {
      label: "プロジェクト期間と推定GHG排出量"
    },
    {
      label: "方法論の摘要"
    },
    {
      label: "GHG排出削減量と除去量の計算"
    },
    {
      label: "モニタリング"
    },
    {
      label: "参考文献"
    },
    {
      label: "補足事項等"
    },
  ]

  const getProposerKeyName = (key) => {
    switch (key) {
      case "company":
        return "組織名"
      case "staff":
        return "担当者名"
      case "role":
        return "役職"
      case "address":
        return "住所"
      case "email":
        return "Eメール"
      case "tel":
        return "電話番号"
      default:
        return ""
    }
  }

  const getCheckText = (check) => {
    return check ? "はい" : "いいえ"
  }
  const getRiskCheckText = (check) => {
    return check ? "リスクあり" : "リスクなし"
  }
  const concatDate = (start, end) => {
    return (moment(start).format("YYYY/MM/DD") + " - " + moment(end).format("YYYY/MM/DD"))
  }

  // 6.1 方法論のタイトルと参考文献
  const methodologyColumns = [
    { field: "methodologyTypeText", headerName: "タイプ" },
    { field: "methodologyRefId", headerName: "参照ID" },
    { field: "methodologyTitle", headerName: "タイトル" },
    { field: "methodologyVersion", headerName: "Version" },
  ];

  // 6.3 プロジェクト境界
  const projectBoundaryColumns = [
    { field: "baselineOrSource", headerName: "ベースライン／ソース" },
    { field: "gasType", headerName: "Gas" },
    { field: "gasInclude", headerName: "含まれる？" },
    { field: "boundaryExplain", headerName: "正当性／説明" },
  ];

  // 7.4 GHG排出削減量と二酸化炭素除去量の推定値
  const projectCreditTermColumns = [
    {
      field: "projectTerm",
      headerName: "プロジェクト期間",
      renderCell: (params) => {
        console.log(params)
        return (
          <div style={{ flexBasis: "300px" }}>
            {moment(params.value[0]).format("YYYY/MM/DD")} -{" "}
            {moment(params.value[1]).format("YYYY/MM/DD")}
          </div>
        );
      },
    },
    { field: "estimatedBaselineEmissions", headerName: "推定ベースライン排出量" },
    { field: "estimatedProjectEmissions", headerName: "推定プロジェクト排出量" },
    { field: "estimatedLeakageEmissions", headerName: "推定リーケージ排出量" },
    { field: "estimatedReductionCredit", headerName: "推定削減クレジット" },
    { field: "estimatedRemoveCredit", headerName: "推定除去クレジット" },
    { field: "estimatedTotalCredit", headerName: "推定合計クレジット" },
  ];

  // 8.1 検証時に利用可能なデータとパラメータ
  const monitoring1Columns = [
    { field: "monitoringDataOrParam1", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit1", headerName: "データ単位" },
    { field: "monitoringDataContents1", headerName: "データ内容" },
    { field: "monitoringDataSource1", headerName: "データソース" },
    { field: "monitoringApplicableValue1", headerName: "適用値" },
    { field: "monitoringExplain1", headerName: "データ選択の正当性、または適用した測定方法と手順の説明" },
    { field: "monitoringPurpose1", headerName: "データの目的" },
    { field: "monitoringComment1", headerName: "コメント" },
  ];

  // 8.2 モニタリングされるデータとパラメータ
  const monitoring2Columns = [
    { field: "monitoringDataOrParam2", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit2", headerName: "データ単位" },
    { field: "monitoringDataContents2", headerName: "データ内容" },
    { field: "monitoringDataSource2", headerName: "データソース" },
    { field: "monitoringExplain2", headerName: "適用される測定方法と手順の説明" },
    { field: "monitoringFrequency", headerName: "モニタリング／記録の頻度" },
    { field: "monitoringApplicableValue2", headerName: "適用値" },
    { field: "monitoringDevice", headerName: "モニタリング機器" },
    { field: "monitoringQaQcProcedure", headerName: "適用されるQA/QC手順" },
    { field: "monitoringPurpose2", headerName: "データの目的" },
    { field: "howToCalc", headerName: "計算方法" },
    { field: "monitoringComment2", headerName: "コメント" },
  ];

  // 10.1 商業上の機密情報
  const secretInfoColumns = [
    { field: "secretSection", headerName: "セクション" },
    { field: "secretInfo", headerName: "情報" },
    { field: "secretJustification", headerName: "正当化" },
  ];

  const Proposer = () => {
    Object.keys(projectInfo?.proposer).forEach((key) => {
      return (
        <Grid container>
          <Grid items xs={4} lg={1}>
            <Box>{getProposerKeyName(key)}: </Box>
          </Grid>
          <Grid items xs={8} lg={5}>
            <Box>{projectInfo?.proposer[key] || "記載なし"}</Box>
          </Grid>
        </Grid>
      )
    })
  }

  useEffect(() => {
    async function fetchProject() {
      const res = await projectApi.getById({
        params: { _id: id },
      });
      // console.log(res)
      setProjectInfo(res.project);
      setWork(res.workData);
      setHistory(res.examinationHistories);
      // 権限の設定
      setRole(authUser.roleType);
      // 審査済みの場合
      if (res.project.applyflg > 0) {
        setIsDisabled(true);
      }

      // レビューマスタを取得
      const reviewersRes = await reviewerApi.get({
        params: {
          funcId: "project",
        }
      });
      setReviewers(reviewersRes);
      // ログインユーザーが審査したかどうか
      const reviewerMyInfo = reviewersRes.reviewer.workingGroup.filter((reviewer) =>
        reviewer.userId === userInfo.value._id
      )
      const resurt = res.project.reviewId.result;
      if (resurt && resurt.documentExam && resurt.documentExam[reviewerMyInfo[0].role]) {
        setIsReviewed(true);
      }
    }
    fetchProject();
  }, [id]);

  useEffect(() => {}, [projectInfo])

  return (
    <Box component="form" id="examinationForm" noValidate>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          プロジェクト書類審査
        </Typography>
      </div>
      {projectInfo.applyflg === 9 && (
        <>
          <Typography mb={2} style={{ color: "#f44336" }}>
            差し戻し中です。再申請後に承認をお願いします。
          </Typography>
        </>
      )}

      <div style={{ marginBottom: "20px" }}></div>

      <ul
        style={{
          listStyleType: "none",
          paddingLeft: 0,
          borderBottom: "2px solid #cccccc",
          paddingBottom: "10px",
          marginBottom: "10px",
          display: "flex",
          // flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              支部名
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {projectInfo?.branchId?.branchName ?? "設定なし"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              プロジェクト名
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {projectInfo?.projectName}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              プロジェクトID
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {projectInfo?.serialId ?? "設定なし"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              クレジット期間
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {concatDate(projectInfo?.projectCreditTerm?.creditStartDate, projectInfo?.projectCreditTerm?.creditEndDate)}
              {/* {projectInfo?.projectCreditTerm?.creditTerm !== "" ? projectInfo?.projectCreditTerm?.creditTerm : "記載なし"} */}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              当初の発行日
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {moment(projectInfo?.created).format("YYYY/MM/DD") ?? "設定なし"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              最新の発行日
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {moment(projectInfo?.updated).format("YYYY/MM/DD") ?? "記載なし"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              バージョン
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {projectInfo?.historyNo + 1}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              NCCC standardバージョン
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {projectInfo?.serialId ? projectInfo?.serialId : "設定なし"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                backgroundColor: "#f2f2f2",
                color: "black",
                border: "1px solid ",
                padding: "5px",
                width: "300px",
              }}
            >
              作成者
            </div>
            <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
              {projectInfo?.createdCompanyId?.groupName + "　" + projectInfo?.createdUserId?.username ?? "記載なし"}
            </div>
          </div>
        </div>
        {(projectInfo.applyflg === 1 || projectInfo.applyflg === 2) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography sx={itemHeaderStyle}>コメント</Typography>
            <Typography sx={itemValueStyle}>{projectInfo.approvalComment}</Typography>
          </Box>
        )}
        {projectInfo.applyflg === 0 && projectInfo.reviewId && projectInfo.reviewId[userInfo.value.directorCode] === 0 && (
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "20px",
                width: "750px",
              }}
            >
              <TextField
                fullWidth
                id="approvalComment"
                label="コメント"
                margin="normal"
                name="approvalComment"
                multiline
                minRows={3}
                maxRows={5}
                error={commentErrText !== ""}
                helperText={commentErrText}
              />
            </Box>
          </div>
        )}
      </ul>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={0}>
          <StepLabel
            optional={null}
            onClick={() => handleJump(0)}
          >
            {steps[0].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>1.1 プロジェクトの概要説明</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectDesc ? projectInfo?.projectDesc : "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>1.2 セクターの範囲とプロジェクトの種類</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectDesc}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>1.3 監査履歴</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectDesc}
            </Box>
          </StepContent>
        </Step>
        <Step key={1}>
          <StepLabel
            optional={null}
            onClick={() => handleJump(1)}
          >
            {steps[1].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>2.1 プロジェクトデザイン</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectDesc}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>2.2 プロジェクト活動の説明</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.actExplain}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>2.3 プロジェクト所在地</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectPlace}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>2.4 プロジェクト提案者</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {
                projectInfo?.proposer && <Proposer />
              }
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>2.5 プロジェクトに関与するその他の団体</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.refOtherCompany || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>2.6 所有権</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectOwnership || "記載なし"}
            </Box>
          </StepContent>
        </Step>
        <Step key={2}>
          <StepLabel
            optional={null}
            onClick={() => handleJump(2)}
          >
            {steps[2].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>3.1 プロジェクトの適格性</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectEligibility || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>3.2 プロジェクト開始前の状況</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.beforeStartingProject || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>3.3 法律、法令、その他の規制枠組みの遵守</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.legalCompliance || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>3.4 二重請求と他のGHGプログラムへの参加</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Box>
                {getCheckText(projectInfo?.noDuplicatePublish?.check) || "記載なし"}
              </Box>
              {projectInfo?.noDuplicatePublish?.check && 
                <Box sx={{ marginTop: "1rem" }}>
                  {projectInfo?.noDuplicatePublish?.evidence}
                </Box>
              }
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>3.5 二重請求、他の形態のクレジット、スコープ3排出量</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Box>
                {getCheckText(projectInfo?.noDuplicateClaim?.check) || "記載なし"}
              </Box>
              {projectInfo?.noDuplicateClaim?.check && 
                <Box sx={{ marginTop: "1rem" }}>
                  {projectInfo?.noDuplicateClaim?.evidence}
                </Box>
              }
            </Box>
          </StepContent>
        </Step>
        <Step key={3}><StepLabel
            optional={null}
            onClick={() => handleJump(3)}
          >
            {steps[3].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>4.1 ステークホルダーの参画と協議</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.stakeholderRegulations || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>4.2 ステークホルダーと環境へのリスク</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.envRisk || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>4.3 人権と公平性の尊重</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.fairness || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>4.4 生態系の健全性</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Typography sx={{ mt: "1rem" }}>生物多様性と生態系への影響</Typography>
              <Box sx={{ pl: 2 }}>
                {getRiskCheckText(projectInfo?.soundnessA?.soundnessACheck) || "記載なし"}
                {projectInfo?.soundnessA?.soundnessACheck &&
                  <Box sx={{ mt: 2 }}>
                    <Typography>特定されたリスク</Typography>
                    {projectInfo?.soundnessA?.soundnessA1 || "記載なし"}
                  </Box>
                }
                <Box sx={{ mt: 2 }}>
                  <Typography>{projectInfo?.soundnessA?.soundnessACheck ? "講じられた緩和策または予防策" : "理由"}</Typography>
                  {projectInfo?.soundnessA?.soundnessA2 || "記載なし"}
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Typography sx={{ mt: "1rem" }}>土壌劣化と土壌侵食</Typography>
              <Box sx={{ pl: 2 }}>
                {getRiskCheckText(projectInfo?.soundnessB?.soundnessBCheck) || "記載なし"}
                {projectInfo?.soundnessB?.soundnessBCheck &&
                  <Box sx={{ mt: 2 }}>
                    <Typography>特定されたリスク</Typography>
                    {projectInfo?.soundnessB?.soundnessB1 || "記載なし"}
                  </Box>
                }
                <Box sx={{ mt: 2 }}>
                  <Typography>{projectInfo?.soundnessB?.soundnessBCheck ? "講じられた緩和策または予防策" : "理由"}</Typography>
                  {projectInfo?.soundnessB?.soundnessB2 || "記載なし"}
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Typography sx={{ mt: "1rem" }}>水の消費とストレス</Typography>
              <Box sx={{ pl: 2 }}>
                {getRiskCheckText(projectInfo?.soundnessC?.soundnessCCheck) || "記載なし"}
                {projectInfo?.soundnessC?.soundnessCCheck &&
                  <Box sx={{ mt: 2 }}>
                    <Typography>特定されたリスク</Typography>
                    {projectInfo?.soundnessC?.soundnessC1 || "記載なし"}
                  </Box>
                }
                <Box sx={{ mt: 2 }}>
                  <Typography>{projectInfo?.soundnessC?.soundnessCCheck ? "講じられた緩和策または予防策" : "理由"}</Typography>
                  {projectInfo?.soundnessC?.soundnessC2 || "記載なし"}
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Typography sx={{ mt: "1rem" }}>肥料の使用</Typography>
              <Box sx={{ pl: 2 }}>
                {getRiskCheckText(projectInfo?.soundnessD?.soundnessDCheck) || "記載なし"}
                {projectInfo?.soundnessA?.soundnessACheck &&
                  <Box sx={{ mt: 2 }}>
                    <Typography>特定されたリスク</Typography>
                    {projectInfo?.soundnessD?.soundnessD1 || "記載なし"}
                  </Box>
                }
                <Box sx={{ mt: 2 }}>
                  <Typography>{projectInfo?.soundnessD?.soundnessDCheck ? "講じられた緩和策または予防策" : "理由"}</Typography>
                  {projectInfo?.soundnessD?.soundnessD2 || "記載なし"}
                </Box>
              </Box>
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>4.5 持続可能な開発への貢献</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.sdContribution || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>4.6 プロジェクトに関連する追加情報</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectAddInfo || "記載なし"}
            </Box>
          </StepContent>
        </Step>
        <Step key={4}><StepLabel
            optional={null}
            onClick={() => handleJump(4)}
          >
            {steps[4].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>5.1 プロジェクト開始日</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.projectStartDate ? moment(projectInfo?.projectStartDate).format("YYYY/MM/DD") : "入力なし"}
              <Box sx={{ mt: 2 }}>
                {projectInfo?.startDateRightfulness || "記載なし"}
              </Box>
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>5.2 プロジェクトのクレジット期間</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              クレジット期間： {projectInfo?.projectCreditTerm?.creditTerm || "入力なし"}
              <Box sx={{ mt: 2 }}>
                {concatDate(projectInfo?.projectCreditTerm?.creditStartDate, projectInfo?.projectCreditTerm?.creditEndDate)}
              </Box>
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>5.3 プロジェクト規模とGHG排出削減量または除去量の推定値</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Box sx={{ mt: 2 }}>
                {projectInfo?.startDateRightfulness || "記載なし"}
              </Box>
            </Box>
          </StepContent>
        </Step>
        <Step key={5}>
          <StepLabel
            optional={null}
            onClick={() => handleJump(5)}
          >
            {steps[5].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>6.1 方法論のタイトルと参考文献</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <DataGrid
                columns={methodologyColumns}
                rows={projectInfo?.programs}
                autoHeight
              />
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>6.2 方法論の適用性</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.programApplicability || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>6.3 プロジェクト境界</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <DataGrid
                columns={projectBoundaryColumns}
                rows={projectInfo?.projectBoundary}
                autoHeight
              />
              <Box sx={{ mt: 2 }}>
                {projectInfo?.projectBoundaryFile || "ファイルなし"}
              </Box>
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>6.4 ベースライン・シナリオ</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
                {projectInfo?.baselineScenario || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>6.5 追加性</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
                {projectInfo?.additionality || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>6.6 方法論の逸脱</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
                {projectInfo?.deviateProgram || "記載なし"}
            </Box>
          </StepContent>
        </Step>
        <Step key={6}>
          <StepLabel
            optional={null}
            onClick={() => handleJump(6)}
          >
            {steps[6].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>7.1 ベースライン排出量</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
                {projectInfo?.baselineEmissions || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>7.2 プロジェクト排出量</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
                {projectInfo?.projectEmissions || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>7.3 リーケージ排出量</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
                {projectInfo?.leakageEmissions || "記載なし"}
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>7.4 GHG排出削減量と二酸化炭素除去量の推定値</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Typography>プロジェクトのクレジット期間</Typography>
              <DataGrid
                columns={projectCreditTermColumns}
                rows={projectInfo?.emissions?.detail}
                autoHeight
              />
            </Box>
            <Box sx={{ marginBottom: "2rem", mt: 2 }}>
              <Typography sx={{ mr: 1 }}>合計</Typography>
              <Grid container>
                <Grid items xs={4} lg={2}>
                  <Box>推定ベースライン排出量: </Box>
                </Grid>
                <Grid items xs={8} lg={5}>
                  <Box>{projectInfo?.emissions?.total?.estimatedBaselineEmissionsTotal || "0"} (tCO2e)</Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid items xs={4} lg={2}>
                  <Box>推定プロジェクト排出量: </Box>
                </Grid>
                <Grid items xs={8} lg={5}>
                  <Box>{projectInfo?.emissions?.total?.estimatedProjectEmissionsTotal || "0"} (tCO2e)</Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid items xs={4} lg={2}>
                  <Box>推定リーケージ排出量: </Box>
                </Grid>
                <Grid items xs={8} lg={5}>
                  <Box>{projectInfo?.emissions?.total?.estimatedLeakageEmissionsTotal || "0"} (tCO2e)</Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid items xs={4} lg={2}>
                  <Box>推定削減クレジット: </Box>
                </Grid>
                <Grid items xs={8} lg={5}>
                  <Box>{projectInfo?.emissions?.total?.estimatedReductionCreditTotal || "0"} (tCO2e)</Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid items xs={4} lg={2}>
                  <Box>推定除去クレジット: </Box>
                </Grid>
                <Grid items xs={8} lg={5}>
                  <Box>{projectInfo?.emissions?.total?.estimatedRemoveCreditTotal || "0"} (tCO2e)</Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid items xs={4} lg={2}>
                  <Box>推定合計クレジット: </Box>
                </Grid>
                <Grid items xs={8} lg={5}>
                  <Box>{projectInfo?.emissions?.total?.estimatedTotalCreditTotal || "0"} (tCO2e)</Box>
                </Grid>
              </Grid>
            </Box>
          </StepContent>
        </Step>
        <Step key={7}>
          <StepLabel
            optional={null}
            onClick={() => handleJump(7)}
          >
            {steps[7].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>8.1 検証時に利用可能なデータとパラメータ</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <DataGrid
                columns={monitoring1Columns}
                rows={projectInfo?.availableData}
                autoHeight
              />
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>8.2 モニタリングされるデータとパラメータ</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <DataGrid
                columns={monitoring2Columns}
                rows={projectInfo?.monitoringData}
                autoHeight
              />
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>8.3 モニタリング計画</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.monitoringPlan || "記載なし"}
            </Box>
          </StepContent>
        </Step>
        <Step key={8}>
          <StepLabel
            optional={null}
            onClick={() => handleJump(8)}
          >
            {steps[8].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>9.1 参考文献</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.references || "記載なし"}
            </Box>
          </StepContent>
        </Step>
        <Step key={9}>
          <StepLabel
            optional={null}
            onClick={() => handleJump(9)}
          >
            {steps[9].label}
          </StepLabel>
          <StepContent>
            <Typography sx={{ fontWeight: "bold" }}>10.1 商業上の機密情報</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <DataGrid
                columns={secretInfoColumns}
                rows={projectInfo?.secret}
                autoHeight
              />
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>10.2 その他・補足事項</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {projectInfo?.otherInfo || "記載なし"}
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      {role === RoleType.BoardOfDirectors && history?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography sx={itemHeaderStyle}>審査履歴</Typography>
            <div style={{ height: "max-content", width: "100%" }}>
              <DataGrid
                rows={history}
                columns={historyColumns}
                getRowId={(history) => history._id}
                autoHeight
              />
            </div>
          </Box>
        )}
        {userInfo.value.directorCode !== "auditor" && role === RoleType.BoardOfDirectors && (projectInfo.applyflg === 0 || projectInfo.applyflg === 9) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "20px",
            }}
          >
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#FF69B4",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              disabled={isReviewed || projectInfo.applyflg === 9}
              onClick={() => {
                handleSubmit(2);
              }}
            >
              否認
            </LoadingButton>
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#008000",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              disabled={isReviewed || projectInfo.applyflg === 9}
              onClick={() => {
                handleSubmit(9);
              }}
            >
              差し戻し
            </LoadingButton>
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#1976D2",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              disabled={isReviewed || projectInfo.applyflg === 9}
              onClick={() => {
                handleSubmit(1);
              }}
            >
              承認
            </LoadingButton>
          </Box>
        )}
        {userInfo.value.directorCode === "auditor" && role === RoleType.BoardOfDirectors && (projectInfo.applyflg === 0 || projectInfo.applyflg === 9) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "20px",
            }}
          >
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#FF69B4",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              disabled={isReviewed || projectInfo.applyflg === 9}
              onClick={() => {
                handleSubmit(2);
              }}
            >
              異議あり
            </LoadingButton>
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#008000",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              disabled={isReviewed || projectInfo.applyflg === 9}
              onClick={() => {
                handleSubmit(9);
              }}
            >
              差し戻し
            </LoadingButton>
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#1976D2",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              disabled={isReviewed || projectInfo.applyflg === 9}
              onClick={() => {
                handleSubmit(1);
              }}
            >
              異議なし
            </LoadingButton>
          </Box>
        )}
      <Box>
        <ExaminationChat id={id} isDisabled={isDisabled} />
      </Box>
    </Box>
  );
};