import React from 'react'
import bg from '../../assets/imgs/comingsoon_bg.jpg'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'

export const Comingsoon = () => {
    return (
        <div style={cs_bg}>
            <Box sx={{
                backgroundColor: "rgba(0,0,0,0.5)",
                width: "100%",
                height: "100vh",
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 3 }}>
                    <Box>
                        <div style={title_font}>Coming Soon</div>
                        <div>
                            <p style={content_font}>Our website is currently undergoing scheduled maintenance.<br />
                                We Should be back shortly. Thank you for your patience.</p>
                        </div>
                    </Box>
                    <Link to="/" sx={{ backgroundColor: "primary.main" }}>
                        back to TOP
                    </Link>
                </Box>
            </Box>

        </div>
    )
}

const cs_bg = {
    backgroundImage: `url(${bg})`,
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}

const title_font = {
    fontSize: '3.3rem',
    fontFamily: "neue-haas-grotesk-text, sans-serif",
    fontWeight: "400",
    fontStyle: "normal"
}

const content_font = {
    fontSize: '1rem',
    fontFamily: "neue-haas-grotesk-display, sans-serif",
    fontWeight: "300",
    fontStyle: "normal"
}