import React from "react";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/Global.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useParams } from "react-router-dom";
import projectApi from "../../../api/projectApi";
import actWorkApi from "../../../api/actWorkApi";
import workApi from "../../../api/workApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import { useSelector } from "react-redux";

export const ProjectProgress = () => {
  const [list, setList] = useState([]);
  const [work, setWork] = useState([]);
  const [task, setTask] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const authUser = useAuthUserContext();
  const [role, setRole] = useState("");
  const storedUserInfo = useSelector((state) => state.user);
  const [branchMasterId, setBranchMasterId] = useState("");
  const [actWorks, setActWorks] = useState([]);

  const isWorkDisplay = (work) => {
    // 管理者、理事、第三者委員会、権限設定なし
    if (role === RoleType.Admin || role === RoleType.BoardOfDirectors || role === RoleType.ThirdPartyCommittee || !work.publicScope) {
      return true;
      // プロジェクト参加者
    } else if (work.publicScope === "0" && actWorks.length > 0) {
      return true;
      // 支部長のみ
    } else if (work.publicScope === "1" && branchMasterId === storedUserInfo.value._id) {
      return true;
      // 正会員
    } else if (work.publicScope === "2" && role === RoleType.FullMember) {
      return true;
      // オブザーバー会員限定
    } else if (work.publicScope === "3" && role === RoleType.ObserverMember) {
      return true;
    } else {
      return false;
    }
  };

  const isWorkDocumentDisplay = (workDocument) => {
    // 管理者、第三者委員会
    if (role === RoleType.Admin || role === RoleType.ThirdPartyCommittee) {
      return true;
      // ログインユーザーの作業ドキュメント
    } else if (workDocument.userId === storedUserInfo.value._id) {
      return true;
      // 未承認のものは非表示にする
    } else if (workDocument.applyflg !== 1) {
      return false;
      // 全体公開
    } else if (workDocument.publicScope === 0) {
      return true;
      // プロジェクト参加者
    } else if (workDocument.publicScope === 1 && actWorks.length > 0) {
      return true;
      // 支部長のみ
    } else if (workDocument.publicScope === 2 && branchMasterId === storedUserInfo.value._id) {
      return true;
    }
    // 該当なし
    return false;
  };

  async function fetchActWork() {
    // プロジェクト参加情報取得
    let actWorks = await actWorkApi.getListByUserId({
      params: { 
        projectId: id,
        userId: storedUserInfo.value._id,
      },
    });
    setActWorks(actWorks.actWorks);
  }

  useEffect(() => {
    async function fetchProject() {
      const res = await projectApi.getByProgress({
        params: {
          _id: id,
          userId: storedUserInfo.value._id,
        },
      });
      setList(res.project);
      setWork(res.workData);
      setTask(res.taskData);
      setRole(authUser.roleType);
      setBranchMasterId(res.project.branchId.branchMasterId);
    }
    fetchProject();
    fetchActWork();
  }, [id]);

  //test
  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          プロジェクト管理
        </Typography>
      </div>

      <div style={{ marginBottom: "20px" }}></div>

      <ul
        style={{
          listStyleType: "none",
          paddingLeft: 0,
          borderBottom: "2px solid #cccccc",
          paddingBottom: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {list.programId && list.programId.programName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プログラム名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.programId.programName}
              </div>
            </div>
          )}
          {list.branchId && list.branchId.branchName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                支部名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.branchId.branchName}
              </div>
            </div>
          )}
          {list.projectName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プロジェクト名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.projectName}
              </div>
            </div>
          )}
          {list.projectDesc && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プロジェクト概要
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.projectDesc}
              </div>
            </div>
          )}
          {list.projectTimeLine && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                期間
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {moment(list.projectTimeLine[0]).format("YYYY/MM/DD")} -{" "}
                {moment(list.projectTimeLine[1]).format("YYYY/MM/DD")}
              </div>
            </div>
          )}
          {list.planDocuments && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プロジェクト見積書類
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                <a
                  href={list.planDocuments}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "400px" }}
                >
                  プロジェクト見積書類
                </a>
              </div>
            </div>
          )}

          {list.additionalDocuments && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                追加書類
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                <a
                  href={list.additionalDocuments}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "400px" }}
                >
                  追加書類
                </a>
              </div>
            </div>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h5" gutterBottom>
            作業工程一覧
          </Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "1000px" }}>
          <li style={{ display: "flex", marginBottom: "10px" }}>
            <div style={{ fontWeight: "bold", flexBasis: "200px" }}>作業名</div>
            <div style={{ fontWeight: "bold", flexBasis: "200px" }}>タスク状況</div>
            <div style={{ fontWeight: "bold", flexBasis: "200px" }}>マイルストーン</div>
            <div style={{ fontWeight: "bold", flexBasis: "400px" }}>ドキュメント</div>
          </li>
          {work.map(
            (work) =>
              isWorkDisplay(work) && (
                <li key={work._id} style={{ display: "flex", marginBottom: "5px" }}>
                  <div style={{ flexBasis: "200px" }}>
                    {work.userId && (
                      <Link to={`/member/projects/progress/detail/${work._id}`}>{work.workName}</Link>
                    )}
                    {!work.userId && (
                      <>
                        {work.workName}
                      </>
                    )}
                  </div>
                  <div style={{ flexBasis: "200px" }}>{work.progress || 0}%</div>
                  <div style={{ flexBasis: "200px" }}>
                    {work.period && moment(work.period).format("YYYY/MM/DD")}
                  </div>
                  <div style={{ flexBasis: "400px" }}>
                    {work &&
                      work.workDocument &&
                      work.workDocument.map((material, index) => {
                        if (isWorkDocumentDisplay(material)) {
                          return (
                            <a
                              key={index}
                              href={material.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {material.documentName}
                              <br />
                            </a>
                          );
                        }
                        return null;
                      })}
                  </div>
                </li>
              )
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h5" gutterBottom>
            タスク
          </Typography>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "1000px" }}>
          <li style={{ display: "flex", marginBottom: "10px" }}>
            <div style={{ fontWeight: "bold", flexBasis: "200px" }}>作業名</div>
            <div style={{ fontWeight: "bold", flexBasis: "200px" }}>タスク状況</div>
            <div style={{ fontWeight: "bold", flexBasis: "200px" }}>マイルストーン</div>
            <div style={{ fontWeight: "bold", flexBasis: "400px" }}>ドキュメント</div>
          </li>
          {task.map((task) => {
            if (!task) {
              // 空の要素をスキップ
              return null;
            }
            return (
              <li key={task._id} style={{ display: "flex", marginBottom: "5px" }}>
                <div style={{ flexBasis: "200px" }}>
                  <Link to={`/member/projects/progress/detail/${task._id}`}>{task.workName}</Link>
                </div>
                <div style={{ flexBasis: "200px" }}>{task.progress}%</div>
                <div style={{ flexBasis: "200px" }}>{task.period && moment(task.period).format("YYYY/MM/DD")}</div>
                <div style={{ flexBasis: "400px" }}>
                  {task &&
                    task.workDocument &&
                    task.workDocument.map((material, index) => {
                      if (isWorkDocumentDisplay(material)) {
                        return (
                          <a
                            key={index}
                            href={material.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {material.documentName}
                            <br />
                          </a>
                        );
                      }
                      return null;
                    })}
                </div>
              </li>
            );
          })}
        </div>
      </ul>
    </Box>
  );
};
