import React, { useEffect, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import "../../../assets/css/Global.css";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import actWorkApi from "../../../api/actWorkApi";
import projectApi from "../../../api/projectApi";
import mailApi from "../../../api/mailApi";
import moment from "moment";
import { useAuthUserContext, RoleType } from "../../../providers/auth";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";

const itemHeaderStyle = {
  color: "#FFFFFF",
};
const itemValueStyle = {
  color: "#FFFFFF",
  marginLeft: "5px",
  fontWeight: "bold",
};

export const ParticipantsApply = () => {
  const [list, setList] = useState([]);
  const { id } = useParams();
  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const navigate = useNavigate();
  const [commentErrText, setCommentErrText] = useState("");
  const userInfo = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  let [history, setHistory] = useState([]);
  const [examinationErrText, setExaminationErrText] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (buttonValue) => {
    setExaminationErrText("");
    let examinationForm = document.getElementById("examinationForm");
    const data = new FormData(examinationForm);
    const approvalComment = data.get("approvalComment");
    setCommentErrText("");
    if (buttonValue === 9 && approvalComment === "") {
      setCommentErrText("差し戻しコメントを入力してください。");
      return;
    }

    let comment = "";
    if (buttonValue === 1) {
      comment = "承認";
    } else if (buttonValue === 2) {
      comment = "否認";
    } else {
      comment = "差し戻し";
    }

    if (window.confirm(comment + "します。よろしいですか？")) {
      const requestData = {
        id: id,
        examinationId: userInfo.value._id,
        approvalStatus: buttonValue,
        approvalComment: approvalComment,
        workId: list.workId._id,
      };
      try {
        //承認／否認　処理
        await actWorkApi.approval(requestData);
        // 差し戻しの場合、差し戻し通知を送付
        if (buttonValue === 9) {
          const email = list.applicantId ? list.applicantId.email : "";
          const mailRequestData = {
            applicantName: list.applicantId.username,
            targetGenre: "プロジェクト参加申請",
            targetName: list.projectId.projectName,
            remandReason: approvalComment,
            url: "/member/project_applicants",
            mailaddress: email,
            // TODO 固定値を後で定数クラス等を作成して管理するか検討
            mailid: "18",
          };
          await mailApi.returnpost(mailRequestData);
        }

        // サイドメニューの通知バッチ表示判定
        dispatch(setUpdateBadgeFlg(true));

        const url = "/member/project_applicants";
        navigate(url);
      } catch (err) {
        console.error(err);
        const errors = err.data?.errors;
        errors.forEach((e) => {
          setExaminationErrText(e.msg);
        });
      }
    }
  };

  useEffect(() => {
    async function fetchWork() {
      const res = await actWorkApi.getById(id);
      setList(res.actWork);
      setHistory(res.examinationHistories);
      setRole(authUser.roleType);
      const projectData = await projectApi.getById({
        params: { _id: res.actWork.projectId._id },
      });
      setStartDate(projectData.project.projectTimeLine[0]);
      setEndDate(projectData.project.projectTimeLine[1]);
    }
    fetchWork();
  }, [id]);
  const historyColumns = [
    {
      field: "created",
      headerName: "審査日時",
      width: 200,
      renderCell: (params) => {
        return (
          params.value && (
            <div style={{ flexBasis: "200px" }}>{moment(params.value).format("YYYY/MM/DD")}</div>
          )
        );
      },
    },
    {
      field: "examinationId",
      headerName: "審査者",
      width: 200,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "200px" }}>{params.value.username}</div>;
      },
    },
    {
      field: "result",
      headerName: "審査結果",
      width: 200,
    },
    {
      field: "comment",
      headerName: "コメント",
      width: 400,
    },
  ];

  return (
    <Box component="form" id="examinationForm" noValidate>
      <div style={{ marginBottom: "20px" }}>
        {role === RoleType.FullMember && list.status === "0" && (
          <>
            <Typography variant="h5" gutterBottom>
              プロジェクト参加審査/承認
            </Typography>
          </>
        )}
        {list.status !== "0" && (
            <>
              <Typography variant="h5" gutterBottom>
                プロジェクト参加詳細
              </Typography>
            </>
        )}
      </div>
      {examinationErrText && (
        <div style={{ color: "#f44336", whiteSpace: "pre-wrap" }}>{examinationErrText}</div>
      )}

      <div style={{ marginBottom: "20px" }}></div>

      <ul
        style={{
          listStyleType: "none",
          paddingLeft: 0,
          borderBottom: "2px solid #cccccc",
          paddingBottom: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {list.projectId && list.projectId.projectName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プロジェクト名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.projectId.projectName}
              </div>
            </div>
          )}
          {list.workId && list.workId.workName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                希望役割
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.workId.workName}
              </div>
            </div>
          )}
          {list.companyId && list.companyId.groupName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                代表会社名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.companyId.groupName}
              </div>
            </div>
          )}
          {list.userId && list.userId.username && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                氏名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.userId.username}
              </div>
            </div>
          )}
          {list.purpose && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                参加目的
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.purpose}
              </div>
            </div>
          )}
          {list.period && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                希望期間
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {moment(list.period[0]).format("YYYY/MM/DD")} -{" "}
                {moment(list.period[1]).format("YYYY/MM/DD")}
              </div>
            </div>
          )}
          {startDate &&
            endDate &&
            (dayjs(list.period[0]).format("YYYY/MM/DD") < dayjs(startDate).format("YYYY/MM/DD") ||
              dayjs(list.period[1]).format("YYYY/MM/DD") >
                dayjs(endDate).format("YYYY/MM/DD") <=
                endDate) && (
              <>
                <div style={{ color: "red" }}>
                  プロジェクト期間:
                  {moment(startDate).format("YYYY/MM/DD")} - {moment(endDate).format("YYYY/MM/DD")}
                </div>
                <div style={{ color: "red" }}>
                  プロジェクト期間の範囲外です。差し戻しを行い、日付を変更してください。
                </div>
              </>
            )}
          {list.estimate && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                見積
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {Number(list.estimate).toLocaleString() + " 円"}
              </div>
            </div>
          )}
          {list.quotationMaterials && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                見積資料{" "}
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                <a
                  href={list.quotationMaterials}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "400px" }}
                >
                  見積資料
                </a>
              </div>
            </div>
          )}
        </div>
        {role === RoleType.FullMember &&
          userInfo.value._id === list.projectId.branchId.branchMasterId &&
          list.status === "0" && (
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "20px",
                  width: "750px",
                }}
              >
                <TextField
                  fullWidth
                  id="approvalComment"
                  label="コメント"
                  margin="normal"
                  name="approvalComment"
                  multiline
                  minRows={3}
                  maxRows={5}
                  error={commentErrText !== ""}
                  helperText={commentErrText}
                />
              </Box>
            </div>
          )}
        {(list.status === "1" || list.status === "2") && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography sx={itemHeaderStyle}>コメント</Typography>
            <Typography sx={itemValueStyle}>{list.approvalComment}</Typography>
          </Box>
        )}
        {role === RoleType.FullMember && history && history.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography sx={itemHeaderStyle}>審査履歴</Typography>
            <div style={{ height: "max-content", width: "100%" }}>
              <DataGrid
                rows={history}
                columns={historyColumns}
                getRowId={(history) => history._id}
                autoHeight
              />
            </div>
          </Box>
        )}
        {role === RoleType.FullMember &&
          userInfo.value._id === list.projectId.branchId.branchMasterId &&
          list.status === "0" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "50px",
                padding: "20px",
              }}
            >
              <LoadingButton
                sx={{
                  width: "150px",
                  color: "white",
                  background: "#FF69B4",
                }}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  handleSubmit(2);
                }}
              >
                否認
              </LoadingButton>
              <LoadingButton
                sx={{
                  width: "150px",
                  color: "white",
                  background: "#008000",
                }}
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  handleSubmit(9);
                }}
              >
                差し戻し
              </LoadingButton>
              {list.period &&
                (dayjs(list.period[0]).format("YYYY/MM/DD") >=
                  dayjs(startDate).format("YYYY/MM/DD") ||
                  dayjs(list.period[1]).format("YYYY/MM/DD") <=
                    dayjs(endDate).format("YYYY/MM/DD") <=
                    endDate) && (
                  <>
                    <LoadingButton
                      sx={{
                        width: "150px",
                        color: "white",
                        background: "#1976D2",
                      }}
                      fullWidth
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={() => {
                        handleSubmit(1);
                      }}
                    >
                      承認
                    </LoadingButton>
                  </>
                )}
            </Box>
          )}
      </ul>
    </Box>
  );
};
