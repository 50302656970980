import React, { useState, useEffect } from "react";
import { Box, Typography, Pagination } from "@mui/material";
import News from "../../../components/common/LoginedNews";
import { SubpageHeader } from "../../../components/homepage/LoginedSubpageHeader";
import { API_URL, API_KEY } from "../../../utils/microcms.js";
import ScrollToTopOnMount from "../../../components/common/utils/ScrollToTopOnMount";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";

export const LoginedNewsList = () => {
  const breadcrumb = [
    {
      name: "NEWS",
      link: "/member/hp/news",
    },
  ];
  const { t } = useTranslation();

  const [release, setRelease] = useState({});
  const [releaseCurrentPage, setReleaseCurrentPage] = useState(1); // 現在のページ
  const [releasePageNum, setReleasePageNum] = useState(1); // pageの合計
  const changeReleasePage = (event, value) => {
    setReleaseCurrentPage(value);
  };
  const [news, setNews] = useState({});
  const [newsCurrentPage, setNewsCurrentPage] = useState(1); // 現在のページ
  const [newsPageNum, setNewsPageNum] = useState(1); // pageの合計
  const changeNewsPage = (event, value) => {
    setNewsCurrentPage(value);
  };
  const pageDisplayNum = 12;
  useEffect(() => {
    const offset = releaseCurrentPage ? (Number(releaseCurrentPage) - 1) * pageDisplayNum : 0;
    fetch(API_URL + "release?orders=-createdAt&offset=" + offset + "&limit=" + pageDisplayNum, {
      headers: { "X-MICROCMS-API-KEY": API_KEY },
    })
      .then((res) => res.json())
      .then((data) => {
        setRelease(data);
        setReleasePageNum(Math.ceil(Number(data.totalCount) / pageDisplayNum));
      });
  }, [releaseCurrentPage]);

  useEffect(() => {
    const offset = newsCurrentPage ? (Number(newsCurrentPage) - 1) * pageDisplayNum : 0;
    fetch(API_URL + "news?orders=-createdAt&offset=" + offset + "&limit=" + pageDisplayNum, {
      headers: { "X-MICROCMS-API-KEY": API_KEY },
    })
      .then((res) => res.json())
      .then((data) => {
        setNews(data);
        setNewsPageNum(Math.ceil(Number(data.totalCount) / pageDisplayNum));
      });
  }, [newsCurrentPage]);

  const [value, setValue] = useState("1");
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <SubpageHeader title="NEWS" breadcrumb={breadcrumb} />

      <Box sx={{ padding: { xs: "0px", md: "20px 70px" } }}>
        {/* <Typography variant="h5" color="primary.main">
          リリース
        </Typography> */}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={changeTab} aria-label="lab API tabs example">
                <Tab label={t("news.labels.label2")} value="1" />
                <Tab label={t("news.labels.label1")} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div style={newsSection}>
                {!!release.contents?.length && (
                  <News items={release.contents} contentName="release" />
                )}
              </div>
              <Box sx={pagenationStyle}>
                {releasePageNum > 1 && (
                  <Pagination
                    count={releasePageNum}
                    page={releaseCurrentPage}
                    onChange={changeReleasePage}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <div style={newsSection}>
                {!!news.contents?.length && <News items={news.contents} contentName="news" />}
              </div>
              <Box sx={pagenationStyle}>
                {newsPageNum > 1 && (
                  <Pagination
                    count={newsPageNum}
                    page={newsCurrentPage}
                    onChange={changeNewsPage}
                  />
                )}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
      <ScrollToTopOnMount />
    </>
  );
};

const newsSection = {
  margin: "0 auto",
  maxWidth: "1200px",
};

const pagenationStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "1rem",
};
