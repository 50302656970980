import axiosClient from "./axiosClient";

const memberApi = {
  register: (params) => axiosClient.post("member/register", params),
  signin: (params) => axiosClient.post("member/signin", params),
  auth: (params) => axiosClient.post("member/auth", params),
  createMemberCheckLog: (params) => axiosClient.post("member/createMemberCheckLog", params),
  getMemberCheckLog: (params) => axiosClient.post("member/getMemberCheckLog", params),
};

export default memberApi;
