import TextField from "@mui/material/TextField/TextField";
import { CSSProperties } from "react";

// 型をコメントで書いておく
// type P = {
//   state: string,
//   setState: React.Dispatch<React.SetStateAction<string>>,
//   label?: string,
//   style?: CSSProperties,
//   required?: boolean,
//   fullWidth?: boolean,
// };

const NumericField = (props) => {
  const onChangeHandle = (e) => {
    const result = Math.abs(Number(e.target.value)).toString();
    if (result === "NaN") {
      props.onChange("");
    } else {
      props.onChange(result);
    }
  };

  return (
    <TextField
      fullWidth={props.fullWidth}
      required={props.required}
      value={props.state}
      onChange={onChangeHandle}
      label={props.label}
      style={props.style}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      margin="normal"
    />
  );
};

export default NumericField;
