import axiosClient from "./axiosClient";

const authApi = {
  getCompany: (params) => axiosClient.post("auth/company", params),
  getCompanyList: (params) => axiosClient.get("auth/companyList", params),
  getUser: (params) => axiosClient.get("auth/user", params),
  accept: (params) => axiosClient.post("auth/register", params),
  register: (params) => axiosClient.post("auth/updater", params),
  login: (params) => axiosClient.post("auth/login", params),
  verifyToken: () => axiosClient.post("auth/verify-token"),
  sendMail: (params) => axiosClient.post("mail/send", params),
  sendRemindMail: (params) => axiosClient.post("mail/remind", params),
  // sendProposalMail: (params) => axiosClient.post("mail/proposal", params),
  approval: (params) => axiosClient.post("auth/approval", params),
  claimUp: (params) => axiosClient.post("auth/ClaimUp", params),
};

export default authApi;
