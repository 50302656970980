import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authApi from "../../api/authApi";
import userApi from "../../api/userApi";
import "../../assets/css/Global.css";
import uploadImg from "../../components/common/utils/uploadImg";

import { Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import validator from "validator";
import acceptHero from "../../assets/imgs/accept_hero.jpg";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import { Dialog, DialogTitle, DialogContent, DialogActions, Checkbox } from "@mui/material";

import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const h2Style = {
  backgroundColor: "#00695f",
  color: "#ffffff",
  fontWeight: 700,
  padding: "5px 20px",
};
const boxStyle = {
  padding: "20px 10px",
};
const generalH3 = {
  fontSize: "1.5rem",
  paddingLeft: "1rem",
  borderLeft: "solid 5px #00695f",
};

const Accept = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [businessType, setBusinessType] = React.useState("");
  let error = false;

  const [groupNameErrText, setGroupNameText] = useState("");
  const [addressErrText, setAddressErrText] = useState("");
  // const [capitalErrText, setCapitalErrText] = useState("");
  const [siteUrlErrText, setSiteUrlErrText] = useState("");
  const [businessTypeErrText, setBusinessTypeErrText] = useState("");
  const [usernameSeiErrText, setUsernameSeiErrText] = useState("");
  const [usernameMeiErrText, setUsernameMeiErrText] = useState("");
  const [usernameSeiKanaErrText, setUsernameSeiKanaErrText] = useState("");
  const [usernameMeiKanaErrText, setUsernameMeiKanaErrText] = useState("");
  const [daihyoSeiErrText, setdaihyoSeiErrText] = useState("");
  const [daihyoMeiErrText, setdaihyoMeiErrText] = useState("");
  const [daihyoSeiKanaErrText, setdaihyoSeiKanaErrText] = useState("");
  const [daihyoMeiKanaErrText, setdaihyoMeiKanaErrText] = useState("");
  const [roleErrText, setRoleErrText] = useState("");
  const [emailErrText, setEmailErrText] = useState("");
  const [nameCardErrText, setNameCardErrText] = useState("");
  const [applicationReasonErrText, setApplicationReasonErrText] = useState("");
  const [membershipErrText, setMembershipErrText] = useState("");
  const [observer, setObserver] = useState(false);
  const [file, setFile] = useState();
  const [agreement, setAgreement] = useState(false);

  const handleMembershipChange = (event) => {
    if (event.target.value === "observerMember") {
      setObserver(true);
    }
  };
  const observerClose = () => {
    setObserver(false);
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    setGroupNameText("");
    setAddressErrText("");
    // setCapitalErrText("");
    setSiteUrlErrText("");
    setBusinessTypeErrText("");
    setUsernameSeiErrText("");
    setUsernameMeiErrText("");
    setUsernameSeiKanaErrText("");
    setUsernameMeiKanaErrText("");
    setdaihyoSeiErrText("");
    setdaihyoMeiErrText("");
    setdaihyoSeiKanaErrText("");
    setdaihyoMeiKanaErrText("");
    setRoleErrText("");
    setEmailErrText("");
    setNameCardErrText("");
    setMembershipErrText("");
    setApplicationReasonErrText("");

    const data = new FormData(e.target);
    const groupName = data.get("groupName").trim();
    const address = data.get("address").trim();
    // const capital = data.get("capital").trim();
    const siteUrl = data.get("siteUrl").trim();
    const businessType = data.get("businessType").trim();
    const username_sei = data.get("username_sei").trim();
    const username_mei = data.get("username_mei").trim();
    const username_sei_kana = data.get("username_sei_kana").trim();
    const username_mei_kana = data.get("username_mei_kana").trim();
    const daihyo_sei = data.get("daihyo_sei").trim();
    const daihyo_mei = data.get("daihyo_mei").trim();
    const daihyo_sei_kana = data.get("daihyo_sei_kana").trim();
    const daihyo_mei_kana = data.get("daihyo_mei_kana").trim();
    const role = data.get("role").trim();
    const nameCard = data.get("nameCard");
    const email = data.get("email").trim();
    const applicationReason = data.get("applicationReason").trim();
    const membership = data.get("membership") ? data.get("membership").trim() : "";
    const existence = await userApi.getByOne({ params: { email: email } });

    if (groupName === "") {
      error = true;
      setGroupNameText("企業名/団体名/個人名を入力してください。");
    }
    if (address === "") {
      error = true;
      setAddressErrText("住所を入力してください。");
    }
    // if (capital === "") {
    //   error = true;
    //   setCapitalErrText("資本金を入力してください。");
    // } else if (isNaN(capital)) {
    //   error = true;
    //   setCapitalErrText("資本金は半角数字で入力してください。");
    // } else if (capital.length > 15) {
    //   error = true;
    //   setCapitalErrText("資本金は15桁以下で入力してください。");
    // }
    // if (siteUrl === "") {
    //   error = true;
    //   setSiteUrlErrText("サイトURLを入力してください。");
    // }
    // if (!validator.isURL(siteUrl)) {
    //   error = true;
    //   setSiteUrlErrText("正しいURLを入力してください。");
    // }
    if (businessType === "") {
      error = true;
      setBusinessTypeErrText("事業内容を入力してください。");
    }
    if (username_sei === "") {
      error = true;
      setUsernameSeiErrText("担当者名（姓）を入力してください。");
    }
    if (username_mei === "") {
      error = true;
      setUsernameMeiErrText("担当者名（名）を入力してください。");
    }
    if (username_sei_kana === "") {
      error = true;
      setUsernameSeiKanaErrText("担当者名（セイ）を入力してください。");
    }
    if (!username_sei_kana.match(/^[ァ-ヶー　]*$/)) {
      error = true;
      setUsernameSeiKanaErrText("担当者名（セイ）はカタカナで入力してください。");
    }
    if (username_mei_kana === "") {
      error = true;
      setUsernameMeiKanaErrText("担当者名（メイ）を入力してください。");
    }
    if (!username_mei_kana.match(/^[ァ-ヶー　]*$/)) {
      error = true;
      setUsernameMeiKanaErrText("担当者名（メイ）はカタカナで入力してください。");
    }
    if (daihyo_sei === "") {
      error = true;
      setdaihyoSeiErrText("代表者名（姓）を入力してください。個人の場合は「個人」と入力ください。");
    }
    // if (daihyo_mei === "") {
    //   error = true;
    //   setdaihyoMeiErrText("代表者名（名）を入力してください。");
    // }
    // if (daihyo_sei_kana === "") {
    //   error = true;
    //   setdaihyoSeiKanaErrText("代表者名（セイ）を入力してください。");
    // }
    // if (!daihyo_sei_kana.match(/^[ァ-ヶー　]*$/)) {
    //   error = true;
    //   setdaihyoSeiKanaErrText("代表者名（セイ）はカタカナで入力してください。");
    // }
    // if (daihyo_mei_kana === "") {
    //   error = true;
    //   setdaihyoMeiKanaErrText("代表者名（メイ）を入力してください。");
    // }
    // if (!daihyo_mei_kana.match(/^[ァ-ヶー　]*$/)) {
    //   error = true;
    //   setdaihyoMeiKanaErrText("代表者名（メイ）はカタカナで入力してください。");
    // }
    if (email === "") {
      error = true;
      setEmailErrText("メールアドレスを入力してください。");
    }
    if (!validator.isEmail(email)) {
      error = true;
      setEmailErrText("正しいメールアドレスを入力してください。");
    }
    if (role === "") {
      error = true;
      setRoleErrText("役職を入力してください。");
    }
    if (applicationReason === "") {
      error = true;
      setApplicationReasonErrText("申請動機を入力してください。");
    }
    if (nameCard.size === 0) {
      error = true;
      setNameCardErrText("名刺をアップロードしてください。");
    }
    if (membership === "") {
      error = true;
      setMembershipErrText("会員種別を選択してください。");
    }
    if (existence.user) {
      error = true;
      setEmailErrText("登録済みのメールアドレスです。");
    }
    if (error) return;
    setLoading(true);

    const username = username_sei + " " + username_mei;
    const usernameKana = username_sei_kana + " " + username_mei_kana;
    const daihyo = daihyo_sei + " " + daihyo_mei;
    const daihyoKana = daihyo_sei_kana + " " + daihyo_mei_kana;
    let userId = "";
    let companyId = "";

    try {
      const nameCardUrl = nameCard?.size ? await uploadImg(nameCard, ["namecard"]) : "";

      const res = await authApi.accept({
        groupName,
        address,
        siteUrl,
        businessType,
        capital: "-",
        username,
        usernameKana,
        daihyo,
        daihyoKana,
        role,
        email,
        applicationReason,
        membership,
        namecardUrl: nameCardUrl,
      });

      userId = res.user._id;
      companyId = res.company._id;
      setLoading(false);
      navigate("/accept_complete");
    } catch (err) {
      const errors = err.data?.errors;
      errors.forEach((error) => {
        if (error.param === "username") {
          setUsernameSeiErrText(error.msg);
        }
      });
      setLoading(false);
    }

    try {
      // メール送信:入会のお申込み（groupName）
      const requestData = {
        userId: userId,
        companyId: companyId,
        // TODO 固定値を後で定数クラス等を作成して管理するか検討
        mailid: "1",
      };
      const res = await authApi.sendMail(requestData);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
    // リマインドメール送信
    const remindMailRequestData = {
      companyId: companyId,
      // TODO 固定値を後で定数クラス等を作成して管理するか検討
      mailid: "10",
    };
    try {
      const res = await authApi.sendRemindMail(remindMailRequestData);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, .2),  rgba(0, 0, 0, .2)),url(${acceptHero})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "250px",
          display: "flex",
          alignItems: "center",
          padding: "0px 0px 0px 100px",
        }}
      >
        <Typography variant="h4" color="white.main">
          入会のご案内
        </Typography>
      </Box>
      <Box sx={{ maxWidth: "1024px", margin: "0 auto", padding: "100px 0px" }}>
        <Box component="form" onSubmit={handleSumbit} noValidate>
          <Typography component="h2" variant="h6" sx={h2Style}>
            会員情報
          </Typography>
          <Box sx={boxStyle}>
            <TextField
              fullWidth
              id="groupName"
              label="企業名/団体名/個人名"
              margin="normal"
              name="groupName"
              required
              helperText={groupNameErrText}
              disabled={loading}
              error={groupNameErrText !== ""}
            ></TextField>
            <TextField
              fullWidth
              id="address"
              label="住所"
              margin="normal"
              name="address"
              required
              helperText={addressErrText}
              disabled={loading}
              error={addressErrText !== ""}
            ></TextField>
            <TextField
              fullWidth
              id="siteUrl"
              label="サイトURL"
              margin="normal"
              name="siteUrl"
              type="url"
              helperText={siteUrlErrText}
              disabled={loading}
              error={siteUrlErrText !== ""}
            ></TextField>
            <FormControl
              fullWidth
              required
              disabled={loading}
              margin="normal"
              error={businessTypeErrText !== ""}
            >
              <InputLabel id="businessType-label" required>
                業種
              </InputLabel>
              <Select
                labelId="businessType-label"
                id="businessType"
                name="businessType"
                label="業種"
                value={businessType}
                onChange={(event) => {
                  setBusinessType(event.target.value);
                }}
              >
                <MenuItem value="0">製造業</MenuItem>
                <MenuItem value="1">建築業</MenuItem>
                <MenuItem value="2">設備業</MenuItem>
                <MenuItem value="3">運輸業</MenuItem>
                <MenuItem value="4">流通業</MenuItem>
                <MenuItem value="5">農林水産業</MenuItem>
                <MenuItem value="6">印刷・出版業</MenuItem>
                <MenuItem value="7">金融業・保険業</MenuItem>
                <MenuItem value="8">不動産業</MenuItem>
                <MenuItem value="9">IT・情報通信業</MenuItem>
                <MenuItem value="10">サービス業</MenuItem>
                <MenuItem value="11">教育・研究機関</MenuItem>
                <MenuItem value="12">病院・医療機関</MenuItem>
                <MenuItem value="13">官公庁・自治体</MenuItem>
                <MenuItem value="14">法人団体</MenuItem>
                <MenuItem value="15">その他の業種</MenuItem>
              </Select>
              <FormHelperText>{businessTypeErrText}</FormHelperText>
            </FormControl>
            {/* <FormControl
              required
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={loading}
              error={capitalErrText !== ""}
            >
              <InputLabel htmlFor="capital">資本金</InputLabel>
              <OutlinedInput
                id="capital"
                name="capital"
                label="資本金"
                endAdornment={<InputAdornment position="end">円</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <FormHelperText>{capitalErrText}</FormHelperText>
            </FormControl> */}
          </Box>
          <Typography component="h2" variant="h6" sx={h2Style}>
            代表取締役情報
          </Typography>
          <Box sx={boxStyle}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                id="daihyo_sei"
                label="氏名（姓）"
                margin="normal"
                name="daihyo_sei"
                required
                helperText={daihyoSeiErrText}
                disabled={loading}
                error={daihyoSeiErrText !== ""}
              ></TextField>
              <TextField
                id="daihyo_mei"
                label="氏名（名）"
                margin="normal"
                name="daihyo_mei"
                helperText={daihyoMeiErrText}
                disabled={loading}
                error={daihyoMeiErrText !== ""}
              ></TextField>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                id="daihyo_sei_kana"
                label="シメイ（セイ）"
                margin="normal"
                name="daihyo_sei_kana"
                helperText={daihyoSeiKanaErrText}
                disabled={loading}
                error={daihyoSeiKanaErrText !== ""}
              ></TextField>
              <TextField
                id="daihyo_mei_kana"
                label="シメイ（メイ）"
                margin="normal"
                name="daihyo_mei_kana"
                helperText={daihyoMeiKanaErrText}
                disabled={loading}
                error={daihyoMeiKanaErrText !== ""}
              ></TextField>
            </Box>
          </Box>
          <Typography component="h2" variant="h6" sx={h2Style}>
            担当者情報
          </Typography>
          <Box sx={boxStyle}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                id="username_sei"
                label="氏名（姓）"
                margin="normal"
                name="username_sei"
                required
                helperText={usernameSeiErrText}
                disabled={loading}
                error={usernameSeiErrText !== ""}
              ></TextField>
              <TextField
                id="username_mei"
                label="氏名（名）"
                margin="normal"
                name="username_mei"
                required
                helperText={usernameMeiErrText}
                disabled={loading}
                error={usernameMeiErrText !== ""}
              ></TextField>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                id="username_sei_kana"
                label="シメイ（セイ）"
                margin="normal"
                name="username_sei_kana"
                required
                helperText={usernameSeiKanaErrText}
                disabled={loading}
                error={usernameSeiKanaErrText !== ""}
              ></TextField>
              <TextField
                id="username_mei_kana"
                label="シメイ（メイ）"
                margin="normal"
                name="username_mei_kana"
                required
                helperText={usernameMeiKanaErrText}
                disabled={loading}
                error={usernameMeiKanaErrText !== ""}
              ></TextField>
            </Box>
            <TextField
              fullWidth
              id="role"
              label="役職"
              margin="normal"
              name="role"
              required
              helperText={roleErrText}
              disabled={loading}
              error={roleErrText !== ""}
            ></TextField>
            <TextField
              fullWidth
              id="email"
              label="メールアドレス"
              margin="normal"
              name="email"
              required
              helperText={emailErrText}
              disabled={loading}
              error={emailErrText !== ""}
            ></TextField>
            <Box sx={{ my: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button variant="contained" component="label" disabled={loading}>
                  名刺アップロード
                  <input
                    hidden
                    accept="image/*"
                    multiple="false"
                    type="file"
                    name="nameCard"
                    required
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                    error={nameCardErrText}
                  />
                </Button>
                {!!file?.name && <Box sx={{ ml: "1rem" }}> {file?.name} </Box>}
              </Box>
              <Box sx={{ mt: 1, marginLeft: "14px", display: "flex", flexDirection: "column" }}>
                <Typography component="body1">
                  ※ご本人様確認のために、名刺の写真をアップロードいただいております。
                </Typography>
                {!!nameCardErrText && (
                  <Typography
                    component="body1"
                    sx={{
                      fontSize: "0.75rem",
                      color: "#d32f2f",
                      lineHeight: "1",
                      mt: 1,
                    }}
                  >
                    {nameCardErrText}
                  </Typography>
                )}
              </Box>
            </Box>
            <FormControl
              required
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={loading}
              error={membershipErrText !== ""}
            >
              <FormLabel id="membership-label">会員種別</FormLabel>
              <Typography component="body1">
                ※現在は一般会員のみの募集となります。
              </Typography>
              <RadioGroup
                row
                aria-labelledby="membership-label"
                name="membership"
                onChange={handleMembershipChange}
                sx={{ marginLeft: "14px" }}
              >
                <FormControlLabel value="fullMember" control={<Radio />} label="正会員" disabled />
                <FormControlLabel value="generalMember" control={<Radio />} label="一般会員" />
                <FormControlLabel
                  value="observerMember"
                  control={<Radio />}
                  label="オブザーバー会員"
                  disabled
                />
              </RadioGroup>
              <FormHelperText>{membershipErrText}</FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              id="applicationReason"
              label="申請動機"
              margin="normal"
              name="applicationReason"
              required
              multiline
              minRows={3}
              maxRows={5}
              helperText={applicationReasonErrText}
              disabled={loading}
              error={applicationReasonErrText !== ""}
            />
          </Box>
          <Box sx={{ mt: 4, mb: 2 }}>
            <FormControlLabel
              label={
                <Box sx={{ display: "flex" }}>
                  <Typography
                    component="a"
                    href="/accept_agreement"
                    target="blank"
                    color="primary"
                    sx={{ display: "flex" }}
                  >
                    反社会的勢力ではないことの表明・確約に関する同意
                    <OpenInNewIcon />
                  </Typography>
                  <Typography>　に同意します</Typography>
                </Box>
              }
              control={
                <Checkbox
                  checked={agreement}
                  onChange={(event) => {
                    setAgreement(event.target.checked);
                  }}
                />
              }
            />
          </Box>
          <Box sx={boxStyle}>
            <LoadingButton
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              type="submit"
              loading={loading}
              color="primary"
              variant="contained"
              disabled={agreement ? loading : true}
              size="large"
            >
              送信
            </LoadingButton>
            <Box>{error}</Box>
          </Box>
        </Box>
      </Box>
      <Dialog open={observer} onClose={observerClose}>
        <DialogTitle>オブザーバー会員について</DialogTitle>
        <DialogContent>
          <p>オブザーバー会員は、地方公共団体等や大学等の方のみ選択可能です。</p>
          <p>ご選択内容にお間違えありませんか？</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={observerClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Accept;
