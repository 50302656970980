import React from "react";
import { Box, Typography } from "@mui/material";
import { Button, Link } from "@mui/material";
import acceptHero from "../../assets/imgs/accept_hero.jpg";
import nccc_logo_symbol from "../../assets/imgs/NCCC_logo_symbol.png";
import "../../assets/css/Global.css";

const generalH3 = {
  fontSize: "1.5rem",
  paddingLeft: "1rem",
  borderLeft: "solid 5px #00695f",
};
const AcceptComplete = () => {
  return (
    <Box>
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, .2),  rgba(0, 0, 0, .2)),url(${acceptHero})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "250px",
          display: "flex",
          alignItems: "center",
          padding: "0px 0px 0px 100px",
        }}
      >
        <Typography variant="h4" color="white.main">
          入会受付が完了しました
        </Typography>
      </Box>

      <Box sx={{ margin: "0 auto", padding: "20px", maxWidth: "1024px" }}>
        <Box
          className="bodyText"
          sx={{
            padding: "50px 0px  ",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              padding: "50px 20px",
              margin: "0 20px",
              backgroundColor: "#ffffff",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ pb: 5 }}>
              <img src={nccc_logo_symbol} />
            </Box>
            この度、入会受付フォームをご入力いただき、ありがとうございました。
            <br />
            結果の通知まで2~3日程を頂戴しております。
            <br />
            メールにて結果をお知らせいたしますので、今しばらくお待ちください。
          </Box>
          <Box sx={{ mt: 5 }}>
            <Button variant="contained" color="primary" href="/" size="large">
              TOPへ戻る
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AcceptComplete;
