import React from "react";
import { Box, Typography } from "@mui/material";
import bgImg from "../../assets/imgs/subpage_title_bgimg.jpg";
import { Link } from "react-router-dom";

export const SubpageHeader = (props) => {
  return (
    <>
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, .2),  rgba(0, 0, 0, .2)),url(${bgImg})`,
          backgroundSize: "cover",
          height: { xs: "120px", md: "350px" },
          display: "flex",
          alignItems: "center",
          padding: "0px 0px 0px 17%",
        }}
      >
        <Typography
          variant="h4"
          color="white.main"
          sx={{
            color: "#FFF",
            fontFamily: "source-han-sans-japanese",
            fontSize: { xs: "24px", md: "32px" },
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            letterSpacing: "9px",
          }}
        >
          {props.title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: { xs: "30px", md: "54px" },
          alignItems: "center",
          padding: { xs: "0px 0px 0px 15px", md: "0px 0px 0px 75px" },
          borderBottom: "1px solid #D1D1D1",
        }}
      >
        <Box
          sx={{
            maxWidth: "50%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            color: "#818181",
            fontFamily: "source-han-sans-japanese",
            fontSize: { xs: "8px", md: "10px" },
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "4px",
          }}
        >
          {props.breadcrumb.map((item, index) => (
            <span key={index}>
              <Link to="/" style={{ color: "#818181" }}>
                HOME
              </Link>
              /{" "}
              <Link to={item.link} style={{ color: "#818181" }}>
                {item.name}
              </Link>
            </span>
          ))}
        </Box>
      </Box>
    </>
  );
};
