import React, { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
    Box,
    TextField,
    Typography,
} from "@mui/material"

import uploadImg from "../utils/uploadImg";

export const TextFieldInputWithImg = ({ fieldData }) => {
    // 親コンポーネントは FormProvider でラップされていること
    const { control, setValue } = useFormContext()
    console.log(control)
    const textFieldRef = useRef(null)

    // 画像アップロード処理 + 文字列の埋め込み
    const upload = async (file) => {
        const textarea = textFieldRef.current
        const sentence = textarea.value
        const len = sentence.length
        const pos = textarea.selectionStart
        const after = sentence.substring(pos, len)

        try {
            // const [downloadUrl] = await uploadImg(file, ["project", fieldData.projectNo, fieldData.name]) // タイムスタンプの方がいいかも
            const downloadUrl = await uploadImg(file, ["project", "tmp", fieldData.name]) // タイムスタンプの方がいいかも
            const imageUrl = '![image](' + downloadUrl + ')\n![caption]()'
            const text = textarea.value.substring(0, textarea.selectionStart) + imageUrl
            setValue(fieldData.name, text + after) // 注意点5と同じ
            textarea.setSelectionRange(text.length, text.length) // 注意点6と同じ
        } catch (error) {
            console.log(error)
        }
    }

    const handlePaste = async (e) => {
        e.preventDefault() // 注意点3: 画像名がペーストされてしまうので、イベントを止める
        const { items } = e.clipboardData
        const file = items[0].getAsFile()
        const textarea = textFieldRef.current
        const sentence = textarea.value
        const len = sentence.length
        const pos = textarea.selectionStart
        const after = sentence.substring(pos, len)

        if (!file) { // 注意点4: 画像じゃなく文字列をペーストしたら、通常のペースト処理
            const text = textarea.value.substring(0, textarea.selectionStart) + e.clipboardData.getData('text')

            setValue(fieldData.name, text + after) // 注意点5: テキストエリアに文字列を更新する
            textarea.setSelectionRange(text.length, text.length) // 注意点6: setValueを使うとカーソルが最後尾に行くので、ペーストしたテキストの最後に行くようにする
            return true
        }

        upload(file)
    }

    const handleDrop = async (e) => {
        e.preventDefault()
        const { files } = e.dataTransfer // ペーストの時は、clipboardDataを使う
        const file = files[0]
        if (!file) return

        upload(file)
    }

    return (
        <Box mt={3}>
            {fieldData.title
                && <Typography variant="subtitle1" gutterBottom>{fieldData.title}</Typography>
            }
            {fieldData.explanation
                && <Typography variant="subtitle2" gutterBottom>{fieldData.explanation}</Typography>
            }
            <Controller
                name={fieldData.name}
                control={control}
                rules={fieldData.validation}
                render={({ field, fieldState }) => (
                    <TextField
                        {...field}
                        required={fieldData.required}
                        fullWidth
                        id={fieldData.name}
                        multiline={fieldData.multiline}
                        rows={fieldData.rows}
                        type={fieldData.type}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        inputRef={textFieldRef}
                        onDrop={handleDrop}
                        onPaste={handlePaste}
                        onChange={(e) => {
                            field.onChange(e)
                            // handleCheck()
                        }}
                    />
                )}
            />
        </Box>
    )
}
