import axiosClient from "./axiosClient";

const examinationApi = {
  register: (params) => axiosClient.post("examination/register", params),
  upsert: (params) => axiosClient.post("examination/upsert", params),
  getById: (params) => axiosClient.post("examination/getById", params),
  getWithQuery: (params) => axiosClient.post("examination/getWithQuery", params),
  sendMail: (params) => axiosClient.post("send/mail", params),
  getList: (params) => axiosClient.get("examination/getList", params),
  getListByCode: (params) => axiosClient.post("examination/getListByCode", params),
};

export default examinationApi;
