import axiosClient from "./axiosClient";

const mailApi = {
  proposal: (params) => axiosClient.post("mail/proposal", params),
  returnpost: (params) => axiosClient.post("mail/returnpost", params),
  apply: (params) => axiosClient.post("mail/apply", params),
  businessCard: (params) => axiosClient.post("mail/businessCard", params),
};

export default mailApi;
