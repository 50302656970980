import { Typography } from "@mui/material";
import React from "react";
import { Container } from "@mui/system";
import { Box } from "@mui/system";
import ScrollToTopOnMount from "../../../components/common/utils/ScrollToTopOnMount";
import { SubpageHeader } from "../../../components/homepage/LoginedSubpageHeader";

export const LoginedConstitution = () => {
  return (
    <>
      <Box>
        <SubpageHeader
          breadcrumb={[{ name: "会員規約", link: "/member/hp/constitution" }]}
        />
      </Box>
      <Container>
        <Box sx={{ margin: "100px 20px" }}>
          <Typography variant="h4" textAlign="center">
            会員規約
          </Typography>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            1. 目的
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会員規約は、一般社団法人ナチュラルキャピタルクレジットコンソーシアム（以下「本会」という）の会員の権利義務等について規定する。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            2. 本会事務局の所在地
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会事務局は福岡市西区元岡744九州大学都市研究センター（工学研究院内）に置く。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            3. 本会の事業内容
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会は、本会定款第３条記載の目的のために同第４条記載の事業を行う。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            4. 会員
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会の会員は、以下の３種類に分類される。
            <ol>
              <li>
                正会員
                本会の設立目的及び事業内容に賛同し、本会の主要な構成員として、本会が提供するカーボンクレジット認証プログラムの利用及びカーボンクレジットの売買が認められる会員をいう。
                正会員は、本会の社員に限られる。
              </li>
              <li>
                一般会員
                本会の社員ではないが、本会の事業内容に賛同し、自らの事業のために、本会が提供するカーボンクレジット認証プログラムの利用が認められる会員をいう。
              </li>
              <li>
                オブザーバー会員
                本会の社員ではないが、本会の事業に公的な立場又は調査・研究等の目的から賛助を希望する会員をいう。
              </li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            5. 入会方法
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <li>
              入会を希望する団体又は個人（以下「申込者」という）は、本会の理事会宛に、所定の入会申込書を提出して、その承認を受ける。その際には、前項に規定される会員種別のうち、いずれの会員としての入会を希望するか明記する。
            </li>
            <li>
              申込者が団体である場合には、同時に団体の代表者又は団体を代理して行為する役職員を届け出るものとする。
            </li>
            <li>入会の可否は、本会より、個別に申込者に通知するものとする。</li>
            <li>
              申込者は、入会が承認され、次項に規定する入会金及び年会費の支払いが完了した時点で、本会の会員となるものとする。なお、正会員は、同時に本会の一般社団法人及び一般財団法人に関する法律（以下「法人法」という）上の社員となるものとする。
            </li>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            6. 会費
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会の会費は、以下とする。
            <table style={{ margin: "20px" }}>
              <tr>
                <th width="200px">会員種別</th>
                <th width="200px">入会金</th>
                <th width="200px">年会費</th>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>正会員</td>
                <td style={{ textAlign: "center" }}>100万円</td>
                <td style={{ textAlign: "center" }}>120万円</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>一般会員</td>
                <td style={{ textAlign: "center" }}>10万円</td>
                <td style={{ textAlign: "center" }}>12万円</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>オブザーバー会員</td>
                <td style={{ textAlign: "center" }}>なし</td>
                <td style={{ textAlign: "center" }}>なし</td>
              </tr>
            </table>
            <ul>
              <li>
                初回の年会費は、入会時に入会金とともに第1号規定の金額を支払うものとし、年会費の日割又は月割計算は行わない。
              </li>
              <li>
                会費の支払は、銀行振り込み又は銀行口座からの口座振替によるものとし、銀行手数料は会員の負担とする。
              </li>
              <li>会員の退会時には、入会金及び年会費の返還はしない。</li>
              <li>会費の変更は、理事会の決議を経て行うものとする。</li>
            </ul>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            7. 会員の権利
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <ol>
              <li>
                正会員及び一般会員は、本会が提供するカーボンクレジット認証等のサービスを利用することができる。ただし、サービスの内容については、本会の判断で変更できるものとする。
              </li>
              <li>
                正会員は、本会の支部を設立すること及びその支部長となることにつき、本会の理事会にその旨を申請し、承認を求める権利を有する。
              </li>
              <li>
                正会員は、本会定款の規定をみたし、社員総会の承認を経て理事になることができる。
              </li>
              <li>
                会員は、秘密保持義務、知的財産権の保護又は個人情報の保護等に反しない範囲において、その会員の種類に応じて、本会の活動状況について報告を受けることができる。
              </li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            8. 退会
          </Typography>
          <Box sx={{ padding: "20px" }}>
            会員は、理事会に退会届を提出することで、いつでも退会できる。ただし、退会日を含む事業年度の年会費が支払済みであることが条件となる。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            9. 会員資格の喪失
          </Typography>
          <Box sx={{ padding: "20px" }}>
            会員は、次のいずれかに該当するに至ったときは、その資格を喪失する。
            <ol>
              <li>退会届の提出をしたとき</li>
              <li>個人の会員本人が死亡したとき又は失踪宣告を受けたとき</li>
              <li>
                団体たる会員が解散し、又は破産、会社更生、民事再生若しくは特別清算手続開始の決定がなされたとき
              </li>
              <li>会費の支払義務を1年間履行しなかったとき</li>
              <li>入会後、２年以上に亘り連絡が取れないとき</li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            10. 除名
          </Typography>
          <Box sx={{ padding: "20px" }}>
            会員が次のいずれかに該当するに至ったときは、社員総会の決議で除名することができる。
            この場合、当該会員に対し、当該社員総会開催日の１週間前までに除名する旨を通知し、かつ、社員総会において弁明する機会を与えるものとする。
            <ol>
              <li>会員が、本会定款、規則又は社員総会の決議に違反したとき</li>
              <li>本会の名誉を傷つけ、又は本会の目的に反する行為をしたとき</li>
              <li>暴力団員等の反社会的勢力であること、又は関係があると判明したこと</li>
              <li>
                入会の申込みに際し、申告すべき事項の重要な部分について虚偽の申告を行ったことが判明し、会員資格を認めることが著しく妥当性を欠くとき
              </li>
              <li>その他除名すべき正当な事由があるとき</li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            11. 本会の機関
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <ol>
              <li>
                本会の社員総会は、すべての正会員によって構成され、各正会員は1個の議決権を有する。少なくとも年一回の定時社員総会を開催する。
              </li>
              <li>
                本会の理事は、３名以上３０名以下の範囲で、社員総会の決議をもって、社員の中から選任される。その任期は、選任後２年以内に終了する事業年度のうち最終のものに関する定時社員総会の終結の時までとする。但し、再任を妨げない。
              </li>
              <li>
                理事への就任を希望する正会員は、他の５名の正会員の推薦を得て、社員総会の選任議案において自らを候補者として指名するよう、理事会に求めることができる。なお、正会員が団体の場合には、当該団体は予め理事候補者をその役職員の中から１名選び、同人の同意を得たうえで、理事会に推薦するものとする。
              </li>
              <li>
                理事会は、すべての理事により構成され、理事の中から1名の理事長を選定する。また、必要に応じて、副理事長3名以内及び専務理事1名を選定することができる。理事長は本会を代表し、副理事長及び専務理事が選定された場合には、理事会の決議に従い業務執行を行う。
              </li>
              <li>
                本会の監事は、３名の範囲内で、社員総会の決議をもって選任される。但し、監事は、社員であることを要しないものとする。その任期は、選任後４年以内に終了する事業年度のうち最終のものに関する定時社員総会の終結の時までとする。但し、再任を妨げない。
              </li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            12. 事業年度
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会の事業年度は、毎年４月１日から翌年３月３１日までとする。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            13. 計算書類等
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会の事業報告及び計算書類は、監事の監査を受け、社員総会に報告されて、その承認を受けなければならない。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            14. 会員規約の変更
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会員規約を変更するには、理事会の承認を得なければならない。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            15. 効力発生日
          </Typography>
          <Box sx={{ padding: "20px" }}>本会員規約は、令和５年４月１０日から有効となる。 以上</Box>
        </Box>
      </Container>
      <ScrollToTopOnMount />
    </>
  );
};
