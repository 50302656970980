import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../config/firebaseConfig.js";

// const getHash = async (text) => {
//   const uint8 = new TextEncoder().encode(text);
//   const digest = await crypto.subtle.digest("SHA-256", uint8);
//   return Array.from(new Uint8Array(digest))
//     .map((v) => v.toString(16).padStart(2, "0"))
//     .join("");
// };

// ex) uploadImg(file, [dir1, dir2] -> path: /dir1/dir2/file.name)
const uploadImg = async (file, pathAry) => {
  // const seconds = new Date().getTime();
  // const hash = await getHash(seconds + file.name + file.size);
  if (!file.size) return "";

  const uuid = crypto.randomUUID();
  const fileType = file.name?.split(".").pop();
  const fileName = uuid + "." + fileType;

  return new Promise((resolve, reject) => {
    const path = "/" + (pathAry.length ? pathAry.join("/") + "/" : "") + fileName;
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        reject(error);
      },
      async () => {
        const imgURL = await getDownloadURL(uploadTask.snapshot.ref);
        // console.log("uploaded image: " + imgURL);
        resolve(imgURL);
      }
    );
  });
};

export default uploadImg;
