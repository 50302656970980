import React, { useEffect } from "react";


const ScrollToTopOnMount = () => {
  useEffect(() => {
    // ページのトップにスクロール
    window.scrollTo(0, 0);
  }, []);

  // 何もレンダリングしない
  return null;
};

export default ScrollToTopOnMount;
