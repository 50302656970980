import axiosClient from "./axiosClient";

const userApi = {
  getTargetList: (params) => axiosClient.get("user/getTargetList", params),
  getCompany: (params) => axiosClient.post("auth/company", params),
  getByOne: (params) => axiosClient.get("user/getByOne", params),
  getDirectors: (params) => axiosClient.get("user/directors", params),
  getByMemberships: (params) => axiosClient.get("user/getByMemberships", params),
  getList: (params) => axiosClient.get("user/list", params),
  getById: (id) => axiosClient.get(`user/getById?id=${id}`),
  applyer: (params) => axiosClient.post("user/applyer", params),
  getMypageInfo: (params) => axiosClient.get("user/getMypageInfo", params),
  updateMypage: (params) => axiosClient.post("user/updateMypage", params),
  changePassword: (params) => axiosClient.post("mail/passwordPost", params),
};

export default userApi;
