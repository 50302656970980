import React, { useEffect } from "react";
import userApi from "../../../api/userApi";
import proposalApi from "../../../api/proposalApi";
import examinationApi from "../../../api/examinationApi";
import chatApi from "../../../api/chatApi";
import mailApi from "../../../api/mailApi";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import Badge from "@mui/material/Badge";
import CircularIndeterminate from "../../../components/common/Loading";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";

export const ProposalDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.user.value);
  const navigate = useNavigate();
  const [proposal, setProposal] = React.useState([]);
  const [applyer, setApplyer] = React.useState([]);
  const [approvableUsers, setApprovableUsers] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const [isReply, setIsReply] = React.useState(false);
  const [mainTopicId, setMainTopicId] = React.useState("");
  const [text, setText] = React.useState("");
  const [chatList, setChatList] = React.useState([]);
  const [isUpdateChat, setIsUpadteChat] = React.useState(false);
  const [isApprovaledMe, setIsApprovaledMe] = React.useState(false);
  const [beforeSenderId, setBeforeSenderId] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const MENU_NUMBER = 1000;
  const ary = [...Array(MENU_NUMBER)].map(() => false);
  const [isOpenMenu, setIsOpenMenu] = React.useState(ary);

  const getCategryName = (proposalCategory) => {
    switch (proposalCategory) {
      case 10:
        return "購入";
      case 11:
        return "購入(月額費用)";
      case 12:
        return "購入(年額費用)";
      case 20:
        return "貸与";
      case 30:
        return "外注";
      case 40:
        return "理事会開催";
      case 50:
        return "その他";
      default:
        return "不明";
    }
  };

  const getChatList = async () => {
    const chatRes = await chatApi.getByPkId({ pkId: id });
    const chatList = chatRes.chats;
    let resultChatList = [];
    for (let i = 0; i < chatList.length; i++) {
      if (chatList[i].chatType === 0) {
        const list = chatList.reduce((acc, cur) => {
          if (cur.mainTopicId === chatList[i]._id) {
            acc.push(cur);
          }
          return acc;
        }, []);
        const notReadChat = list.filter((item) => item.readUsers.includes(user._id) === false);
        resultChatList.push({ ...chatList[i], reply: list, notReadChat: notReadChat });
      }
    }
    setChatList(resultChatList);
    setIsUpadteChat(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const proposalRes = await proposalApi.getById({ id });
      setProposal(proposalRes);

      const uname = await fetchName(proposalRes.from);
      setUserName(uname);

      // 承認可能なユーザーを取得
      // 10: 理事長, 11: 理事会, 12: 事務局
      const approvableUsersRes = await userApi.applyer({
        from: proposalRes.from,
        to: proposalRes.to,
      });
      const excludeIds = [];
      const createdDate = new Date(proposalRes.created);
      const baseDate1 = new Date("2023/08/31");
      // 日付と新規理事ユーザで承認対象外データの場合、承認除外として切り出す
      approvableUsersRes.forEach((user) => {
        if (createdDate < baseDate1 && user.directorCode === "director06") {
          excludeIds.push(user._id)
        }
      });
      const allApprovableUserIds = approvableUsersRes.map((user) => user._id);
      // 承認除外ユーザを理事全体から除外する
      let approvableUserIds = [];
      if (excludeIds !== []) {
        approvableUserIds = allApprovableUserIds.filter((userId) => !excludeIds.includes(userId));
      } else {
        approvableUserIds = allApprovableUserIds
      }
      // 承認除外ユーザが除外されたリストを設定する
      setApprovableUsers(approvableUserIds);

      // 稟議書の承認一覧を取得
      // (ここでのidはcompanyIdではなくproposalId)
      const examinations = await examinationApi.getWithQuery({
        query: { companyId: id, examinationCode: 1 },
      });

      const approvalResult = [];
      for await (let item of examinations) {
        if (item.approvalBy === user._id) setIsApprovaledMe(true);
        const approvalUserName = await fetchName(item.approvalBy);
        approvalResult.push({ ...item, approvalBy: approvalUserName });
      }
      setApplyer(approvalResult);
      getChatList();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isUpdateChat) {
      getChatList();
    }
  }, [isUpdateChat]);

  const toggleMenu = async (num, reply) => {
    if (reply.length >= 1) {
      for await (let item of reply) {
        if (item.readUsers) {
          await chatApi.update({ id: item._id, readUsers: [...item.readUsers, user._id] });
        } else {
          await chatApi.update({ id: item._id, readUsers: [user._id] });
        }
      }
    }
    setIsOpenMenu((prevState) => prevState.map((value, index) => (index === num ? !value : value)));
  };

  const approval = async () => {
    const requestData = {
      companyId: proposal._id, // companyIdにproposalIdをpkとして登録
      approvalBy: user._id,
      status: 0, // 0: 承認, 1: 否認
      examinationCode: 1, // 0: 入会受付審査, 1: 稟議審査
    };
    await examinationApi.upsert(requestData);

    const examinations = await examinationApi.getWithQuery({
      query: { companyId: id, examinationCode: 1 },
    });

    // 審査対象者が全員審査した時の処理
    if (approvableUsers.length <= examinations.length) {
      const isApproved = (exam) => exam.status === 0;
      const approvedByAllUsers = examinations.every(isApproved);

      if (approvedByAllUsers) {
        // proposalコレクションのステータス変更(10 -> 20)
        await proposalApi.updateStatus({
          id: proposal._id,
          status: 20,
        });

        // メール通知
        const requestData = {
          to: Number(proposal.to),
          userId: proposal.from,
          proposalId: proposal._id,
          mailid: "20",
        };
        const mailRes = await mailApi.proposal(requestData);
      }
    }
    dispatch(setUpdateBadgeFlg(true));
    alert("承認しました。");
    navigate("/member/proposal");
  };

  const denial = async () => {
    const requestData = {
      companyId: proposal._id, // companyIdにproposalIdをpkとして登録
      approvalBy: user._id,
      status: 1, // 0: 承認, 1: 否認
      examinationCode: 1, // 0: 入会受付審査, 1: 稟議審査
    };
    const res = await examinationApi.upsert(requestData);
    dispatch(setUpdateBadgeFlg(true));
    alert("否認しました。");
    navigate("/member/proposal");
  };

  const fetchName = async (id) => {
    const res = await userApi.getByOne({
      params: {
        _id: id,
      },
    });
    return res.user.username || res.user.name;
  };
  const td1 = {
    width: "30%",
    padding: "10px 20px",
    textAlign: "left",
    borderTop: "1px solid #ebebeb",
    borderBottom: "1px solid #ebebeb",
    "@media screen and (max-width:480px) ": {
      width: "100%",
      display: "block",
    },
  };
  const td2 = {
    width: "70%",
    padding: "10px 20px",
    borderTop: "1px solid #ebebeb",
    borderBottom: "1px solid #ebebeb",
    "@media screen and (max-width:480px) ": {
      width: "100%",
      display: "block",
    },
  };
  const td3 = {
    width: "130px",
    fontSize: "12px",
  };
  const trAll = {
    padding: "10px 20px",
    border: "",
  };
  const approvalStyle = {
    fontSize: "10px",
    borderRadius: "50%",
    width: "130px",
    height: "130px",
    position: "relative",
    margin: "auto",
  };
  const stampStatusStyle = {
    position: "absolute",
    top: "25px",
    left: "0",
    right: "0",
    margin: "auto",
    width: "80%",
    lineHeight: "1",
    paddingBottom: "3px",
  };
  const stampSpanStyle = {
    display: "inline-block",
    width: "100%",
    position: "absolute",
    top: "50px",
    left: "0",
    right: "0",
    textAlign: "center",
  };
  const stampSpanStyle2 = {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    top: "80px",
    left: "0",
    right: "0",
    margin: "auto",
    width: "80%",
    paddingTop: "2px",
    lineHeight: 1,
  };

  const chatSubmit = async () => {
    setLoading(true);
    if (text === "" || text === null) {
      alert("メッセージを入力してください。");
      return;
    }
    const req = {
      pkId: proposal._id,
      senderId: user._id,
      senderName: user.username || user.name,
      mainTopicId: isReply ? mainTopicId : "",
      chatType: isReply ? 1 : 0,
      text: text,
    };
    const res = await chatApi.create(req);
    if (res.chat) {
      const requestData = {
        to: Number(proposal.to),
        userId: isReply ? beforeSenderId : proposal.from,
        proposalId: proposal._id,
        chatReq: {
          text: text,
          senderName: user.username || user.name,
          created: res.chat.created,
        },
        isReply: isReply,
        mailid: "21",
      };

      const mailRes = await mailApi.proposal(requestData);
    }
    setText("");
    setLoading(false);
    setIsReply(setIsReply(false));
    alert("送信しました。");
    setIsUpadteChat(true);
  };
  const replyChat = (id, senderId) => {
    setMainTopicId(id);
    setBeforeSenderId(senderId);
    setIsReply(isReply ? false : true);
  };
  const deleteTopic = async (chatId) => {
    var result = window.confirm("このトピックを削除しますか？※削除後は復元できません");

    if (result) {
      const req = {
        id: chatId,
      };
      const response = await chatApi.deleteById(req);
      if (response === "Delete Success") {
        setIsReply(false);
        setIsUpadteChat(true);
        alert("削除しました。");
      }
    }
  };

  return (
    <>
      <div>稟議書詳細</div>
      {!!approvableUsers.includes(user._id) && proposal.status === 10 && (
        <Box sx={{ m: 2, display: "flex", gap: 2 }}>
          <>
            <Button onClick={approval} variant="contained" disabled={isApprovaledMe}>
              承認
            </Button>
            <Button onClick={denial} variant="contained" color="danger" disabled={isApprovaledMe}>
              否認
            </Button>
          </>
        </Box>
      )}
      {/* {user.membership === "boardOfDirectors" && proposal.from !== user._id && (
        <Box sx={{ m: 2, display: "flex", gap: 2 }}>
          <>
            <Button onClick={approval} variant="contained" disabled={isApprovaledMe}>
              承認
            </Button>
            <Button onClick={denial} variant="contained" color="danger" disabled={isApprovaledMe}>
              否認
            </Button>
          </>
        </Box>
      )} */}
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            webkitBoxDhadow: "0 10px 6px -6px #777",
            mozBoxShadow: "0 10px 6px -6px #777",
            boxShadow: "0 10px 6px 2px rgba(0.2, 0.5, 0.5, 0.5)",
            maxWidth: "1024px",
            padding: "50px 50px",
            margin: "50px 0px",
          }}
        >
          <Box sx={{ padding: "20px 0px" }}>
            <Box sx={{ mb: 7 }}>
              <Typography variant="caption" component="div" gutterBottom align="right">
                稟議ID: {proposal._id}
              </Typography>
              <Typography variant="h6" component="div" gutterBottom align="center">
                稟議書
              </Typography>
              <Typography variant="h5" component="div" gutterBottom align="center">
                {proposal.subject}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <div>
                <table style={{ borderCollapse: "collapse" }} align="right">
                  <tr>
                    <td style={td1} valign="top">
                      起案日時
                    </td>
                    <td style={td2}>
                      {dayjs(proposal.proposalDate).format("YYYY年MM月DD日 HH時mm分")}
                    </td>
                  </tr>
                  <tr>
                    <td style={td1} valign="top">
                      決裁希望日
                    </td>
                    <td style={{ ...td2, color: "red" }}>
                      {dayjs(proposal.requiredAprrovalDate).format("YYYY年MM月DD日")}
                    </td>
                  </tr>
                  <tr>
                    <td style={td1} valign="top">
                      起案者
                    </td>
                    <td style={td2}>{userName}</td>
                  </tr>
                </table>
              </div>
              <div style={{ clear: "both" }} />
            </Box>
          </Box>
          <Box
            sx={{
              mt: 3,
              mb: 3,
              p: 2,
            }}
          >
            <div>■ 件名</div>
            <Box
              sx={{
                fontWeight: "bold",
                p: 2,
                mt: 2,
                borderTop: "1px solid #ebebeb",
                borderBottom: "1px solid #ebebeb",
              }}
            >
              {proposal.subject}
            </Box>
          </Box>
          <Box
            sx={{
              mt: 3,
              mb: 3,
              p: 2,
            }}
          >
            <div>■ 稟議内容</div>
            <Box>
              <Box
                sx={{
                  p: 2,
                  mt: 2,
                  overflowY: "auto",
                  maxHeight: "400px",
                  borderTop: "1px solid #ebebeb",
                  borderBottom: "1px solid #ebebeb",
                }}
              >
                <p
                  style={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-all",
                  }}
                >
                  {proposal.contents}
                </p>
              </Box>
              <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
                <tr>
                  <td style={td1} valign="top">
                    承認グループ
                  </td>
                  <td style={td2}>
                    {proposal.to === 10 ? "理事長" : proposal.to === 11 ? "理事会" : "事務局"}
                  </td>
                </tr>
                <tr>
                  <td style={td1} valign="top">
                    申請区分
                  </td>
                  <td style={td2}>{getCategryName(proposal.proposalCategory)}</td>
                </tr>
                <tr>
                  <td style={td1} valign="top">
                    申請状況
                  </td>
                  <td style={td2}>
                    <Chip
                      label={
                        proposal.status === 10
                          ? "申請中"
                          : proposal.status === 20
                          ? "承認済"
                          : "却下"
                      }
                      color={
                        proposal.status === 10
                          ? "warning"
                          : proposal.status === 20
                          ? "success"
                          : "error"
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td style={td1} valign="top">
                    金額（税込）
                  </td>
                  <td style={td2}>
                    {Number(proposal.amount * proposal.num * 1.1).toLocaleString() + " 円"}
                  </td>
                </tr>
                <tr>
                  <td style={{ ...td1, paddingLeft: "20px" }}>内訳(単価/数量)</td>
                  <td style={{ ...td2, paddingLeft: "20px" }}>
                    ({Number(proposal.amount).toLocaleString() + " 円"} / {proposal.num})
                  </td>
                </tr>
              </table>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 3,
              mb: 3,
              p: 2,
              borderTop: "1px solid #ebebeb",
              borderBottom: "1px solid #ebebeb",
            }}
          >
            <div>■ 添付書類</div>
            <Box sx={{ display: "flex", gap: 2 }}>
              {proposal.fileUrl?.map((item, index) => (
                <a href={item} target="_blank">
                  添付書類{index + 1}
                </a>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 3,
            border: "1px solid #ebebeb",
            borderRadius: "10px",
            padding: 3,
            width: "fit-content",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-15px",
              left: "30px",
              backgroundColor: "#ffffff",
              padding: "0 20px",
            }}
          >
            承認欄
          </div>
          <table>
            {(function () {
              const list = [];
              for (let i = 0; i < approvableUsers.length; i++) {
                list.push(<th style={td3}>承認者{String(i + 1)}</th>);
              }
              return <tr>{list}</tr>;
            })()}
            <tr>
              {applyer.map((item, index) => (
                <th>
                  <Box
                    sx={
                      item.status
                        ? { ...approvalStyle, color: "red", border: "3px double red" }
                        : { ...approvalStyle, color: "green", border: "3px double green" }
                    }
                  >
                    <span
                      // style={{ borderBottom: item.status ? "1px solid red" : "1px solid green" }}
                      style={
                        item.status
                          ? { ...stampStatusStyle, borderBottom: "1px solid red" }
                          : { ...stampStatusStyle, borderBottom: "1px solid green" }
                      }
                    >
                      {item.status ? "否認" : "承認"}
                    </span>
                    <span style={stampSpanStyle}>{item.approvalBy}</span>
                    <span
                      style={
                        item.status
                          ? { ...stampSpanStyle2, borderTop: "1px solid red" }
                          : { ...stampSpanStyle2, borderTop: "1px solid green" }
                      }
                    >
                      {dayjs(item.updated).format("YYYY/MM/DD H:mm")}
                    </span>
                  </Box>
                </th>
              ))}
            </tr>
          </table>
        </Box>

        <Box sx={{ mt: 2 }}>
          <div>コメント欄</div>
          <Grid container>
            <Grid item xs={9}>
              <Box sx={{ p: 3, maxHeight: "500px", overflowY: "auto" }}>
                {chatList?.map((chat, index) => (
                  <>
                    <ListItemButton onClick={() => toggleMenu(index, chat.reply)}>
                      {chat.notReadChat.length >= 1 && (
                        <Badge
                          color="warning"
                          sx={{ mr: 2 }}
                          badgeContent={chat.notReadChat.length}
                        />
                      )}

                      <ListItemText
                        primary={chat.text}
                        secondary={
                          "投稿者:" +
                          chat.senderName +
                          " / 投稿日:" +
                          dayjs(chat.created)?.format("YYYY/MM/DD HH:mm") +
                          " / 返信数:" +
                          chat.reply.length
                        }
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      />
                      <IconButton
                        color="secondary"
                        onClick={() => replyChat(chat._id, chat.senderId)}
                      >
                        <ReplyIcon />
                      </IconButton>
                      {chat.reply.length <= 0 && (
                        <IconButton color="danger" onClick={() => deleteTopic(chat._id)}>
                          <DeleteIcon />
                        </IconButton>
                      )}

                      {isOpenMenu[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                    <Collapse
                      in={isOpenMenu[index]}
                      timeout="auto"
                      unmountOnExit
                      sx={{ pt: 1, pb: 2, pl: 3 }}
                    >
                      {chat.reply.map((reply) => (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              pr: 3,
                            }}
                          >
                            {chat.notReadChat.includes(user._id) && (
                              <>
                                <Badge color="warning" variant="dot" sx={{ mr: 2 }} />
                              </>
                            )}
                            <ListItemText
                              primary={reply.text}
                              secondary={
                                "投稿者:" +
                                reply.senderName +
                                " / 投稿日:" +
                                dayjs(reply.created)?.format("YYYY/MM/DD HH:mm")
                              }
                              sx={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                              }}
                            />
                            {/* <IconButton
                              color="secondary"
                              onClick={() => {
                                setIsReply(isReply ? false : true);
                              }}
                            >
                              <ReplyIcon />
                            </IconButton> */}
                          </Box>
                        </>
                      ))}
                    </Collapse>
                  </>
                ))}
              </Box>
              <Divider />
              <Grid container style={{ padding: "20px" }}>
                <Grid item xs={11}>
                  <TextField
                    id="chatText"
                    label={isReply ? "返信内容の入力" : "メイントピックを作成"}
                    fullWidth
                    multiline
                    rows={4}
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                </Grid>
                <Grid xs={1} align="right">
                  {loading ? (
                    <Box sx={{ ml: 5 }}>
                      <CircularIndeterminate />
                    </Box>
                  ) : (
                    <IconButton color="secondary" onClick={chatSubmit}>
                      <SendIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
