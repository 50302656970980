import {
  Box,
  Chip,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import eventApi from "../../api/eventApi";
import taskApi from "../../api/taskApi";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import jaLocale from "@fullcalendar/core/locales/ja";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useAuthUserContext, RoleType } from "../../providers/auth";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

export const MemberHome = () => {
  const [fetchedOriginalEvent, setFetchedOriginalEvent] = React.useState([]);
  const [event, setEvent] = React.useState([]);
  const [isEventTagFocus, setIsEventTagFocus] = React.useState(false);
  const [isEventTagFocus2, setIsEventTagFocus2] = React.useState(false);
  const [isEventTagAll, setIsEventTagAll] = React.useState(true);
  const [newTask, setNewTask] = React.useState([]);
  const [myTask, setMyTask] = React.useState([]);
  const user = useSelector((state) => state.user.value);
  const authUser = useAuthUserContext();

  useEffect(() => {
    async function fetchEventDetails() {
      const response = await eventApi.getList();
      const resultEventArr = [];
      for (let i = 0; i < response.events.length; i++) {
        const event = response.events[i];
        const startDate = moment(event.startDate).format("YYYY-MM-DD");
        const endDate = moment(event.endDate).format("YYYY-MM-DD");
        const eventObject = {
          title: event.eventName,
          start: startDate,
          end: endDate,
          ...event,
        };
        // 下書きは表示しない
        if (!event.isDraft) {
          resultEventArr.push(eventObject);
        }
      }
      setEvent(resultEventArr);
      setFetchedOriginalEvent(resultEventArr);
    }
    fetchEventDetails();
    fetchUpdateTaskList();
    fetchMyTaskList();
  }, []);

  async function fetchUpdateTaskList() {
    const isBoardOfDirector = user.membership === "boardOfDirectors" ? true : "";
    const task = await taskApi.getListByHome({
      params: {
        // 表示件数を指定
        filterCount: 6,
        // ログインユーザーに共有されているタスクを表示
        userId: user._id,
        // 理事会のタスク表示用
        isBoardOfDirector: isBoardOfDirector,
      },
    });
    setNewTask(task.items);
  }

  async function fetchMyTaskList() {
    const tasks = await taskApi.getList({
      params: {
        searchCondition: {
          milestoneAssigned: user._id,
        },
      },
    });

    let myTasks = [];
    for (let i = 0; i < tasks.items.length; i++) {
      const task = tasks.items[i];
      const myTask = {};
      myTask._id = task._id;
      myTask.priority = task.priority;
      myTask.title = task.title;
      myTask.taskStatus = task.taskStatus;
      myTask.milestoneStatus = task.milestoneStatus;
      // 期限日がnullの場合の対応
      myTask.taskLimit = !task.taskLimit ? dayjs("9999-12-31") : dayjs(task.taskLimit);
      myTask.milestoneLimit = !task.milestoneLimit
        ? dayjs("9999-12-31")
        : dayjs(task.milestoneLimit);
      myTasks.push(myTask);
    }

    const sortedTask = myTasks.sort((a, b) => {
      // マイル期限日で並び替え
      if (!a.milestoneLimit.isSame(b.milestoneLimit)) {
        if (a.milestoneLimit.isAfter(b.milestoneLimit)) return 1;
        if (a.milestoneLimit.isBefore(b.milestoneLimit)) return -1;
      }
      // タスク期限日で並び替え
      if (!a.taskLimit.isSame(b.taskLimit)) {
        if (a.taskLimit.isAfter(b.taskLimit)) return 1;
        if (a.taskLimit.isBefore(b.taskLimit)) return -1;
      }
      // 優先度順で並び替え
      if (!a.priority !== b.priority) {
        if (a.priority < b.priority) return 1;
        if (a.priority > b.priority) return -1;
      }
      return 0;
    });
    // 仮で設定した日付を空にする
    sortedTask.forEach((sortedTask) => {
      sortedTask.milestoneLimit = sortedTask.milestoneLimit.isSame(dayjs("9999-12-31"))
        ? null
        : sortedTask.milestoneLimit;
      sortedTask.taskLimit = sortedTask.taskLimit.isSame(dayjs("9999-12-31"))
        ? null
        : sortedTask.taskLimit;
    });

    setMyTask(sortedTask);
  }

  function renderEventContent(eventInfo) {
    const transUrl = "/member/eventdetail/" + eventInfo.event.extendedProps._id;
    return (
      <Link to={transUrl}>
        <Box
          sx={{
            color: "white",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            fontSize: "8px",
            p: 1,
          }}
        >
          {eventInfo.event.title.substr(0, 9) + "..."}
        </Box>
      </Link>
    );
  }

  const handleEventFuture = () => {
    if (!isEventTagFocus) {
      const futureEvent = fetchedOriginalEvent.filter((item) => {
        const now = moment();
        const eventDate = moment(item.start);
        if (eventDate.isAfter(now)) {
          return item;
        }
      });
      setEvent(futureEvent);
    } else {
      setEvent(fetchedOriginalEvent);
    }
    setIsEventTagFocus(!isEventTagFocus);
    setIsEventTagFocus2(false);
    setIsEventTagAll(false);
  };
  const handleEventPast = () => {
    if (!isEventTagFocus2) {
      const pastEvent = fetchedOriginalEvent.filter((item) => {
        const now = moment();
        const eventDate = moment(item.start);
        if (eventDate.isBefore(now)) {
          return item;
        }
      });
      setEvent(pastEvent);
    } else {
      setEvent(fetchedOriginalEvent);
    }
    setIsEventTagFocus2(!isEventTagFocus2);
    setIsEventTagFocus(false);
    setIsEventTagAll(false);
  };

  const handleEventAll = () => {
    if (!isEventTagAll) {
      setEvent(fetchedOriginalEvent);
    }
    setIsEventTagFocus2(false);
    setIsEventTagFocus(false);
    setIsEventTagAll(!isEventTagAll);
  };

  const taskActionName = (task) => {
    if (task.updatedUser) {
      return "更新";
    } else {
      return "作成";
    }
  };

  const getLimitColor = (day, status) => {
    const today = dayjs().format("YYYY/MM/DD");
    const limitDay = dayjs(day).format("YYYY/MM/DD");
    if (limitDay < today && [1, 2].includes(status)) {
      return {color: "red"};
    } else {
      return {};
    }
  };

  const getPriorityIcon = (priority) => {
    switch (priority) {
      case 2:
        return <KeyboardDoubleArrowUpIcon sx={{ color: "#8b0000", paddingRight: "3px" }} />;
      case 1:
        return <KeyboardArrowUpIcon sx={{ color: "#ff0000", paddingRight: "5px" }} />;
      case 0:
        return <HorizontalRuleIcon sx={{ color: "#008000", paddingRight: "6px" }} />;
      case -1:
        return <KeyboardArrowDownIcon sx={{ color: "#4169e1", paddingRight: "5px" }} />;
      case -2:
        return <KeyboardDoubleArrowDownIcon sx={{ color: "#00008b", paddingRight: "3px" }} />;
      default:
        return <HorizontalRuleIcon sx={{ color: "#008000", paddingRight: "6px" }} />;
    }
  };

  const getStatusName = (status) => {
    if (status === 0) {
      return "---";
    } else if (status === 1) {
      return "未対応";
    } else if (status === 2) {
      return "処理中";
    } else if (status === 3) {
      return "処理済み";
    } else if (status === 99) {
      return "完了";
    }
    return "";
  };

  const taskColumns = [
    {
      field: "priority",
      headerName: "優先度",
      width: 80,
      renderCell: (params) => {
        return getPriorityIcon(params.value);
      },
    },
    {
      field: "title",
      headerName: "件名",
      width: 500,
      renderCell: (params) => {
        return (
          <Link to={`/member/task/detail/${params.id}`} onClick={() => window.scrollTo(0, 0)}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "taskStatus",
      headerName: "タスク状態",
      width: 100,
      renderCell: (params) => {
        return <div>{getStatusName(params.value)}</div>;
      },
    },
    {
      field: "milestoneStatus",
      headerName: "マイル状態",
      width: 100,
      renderCell: (params) => {
        return <div>{getStatusName(params.value)}</div>;
      },
    },
    {
      field: "taskLimit",
      headerName: "タスク期限日",
      width: 100,
      renderCell: (params) => {
        return (
          <div style={ getLimitColor(params.value, params.row.taskStatus) }>
            {params.value && dayjs(params.value).format("YYYY/MM/DD")}
          </div>
        );
      },
    },
    {
      field: "milestoneLimit",
      headerName: "マイル期限日",
      width: 100,
      renderCell: (params) => {
        return (
          <div style={ getLimitColor(params.value, params.row.milestoneStatus) }>
            {params.value && dayjs(params.value).format("YYYY/MM/DD")}
          </div>
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const pageSizeOptions = [5, 10, 20];

  return (
    <div>
      {/* <Box sx={{ p: 3 }}>
        <h4>プロジェクト情報</h4>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowX: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            gap: "20px",
          }}
        >
          <Card sx={{ minWidth: "300px", maxWidth: "300px", color: "#ffffff" }}>
            <CardMedia sx={{ height: 160 }} image={NCCC_KV} title="event" />
            <CardContent>
              <Box sx={{ fontWeight: "600" }}>国東市 早世桐植林プロジェクト</Box>
              <Box sx={{ mt: 1, mb: 1 }}>
                <Typography variant="caption">テキスト</Typography>
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ minWidth: "300px", maxWidth: "300px", color: "#ffffff" }}>
            <CardMedia sx={{ height: 160 }} image={NCCC_KV} title="event" />
            <CardContent>
              <Box sx={{ fontWeight: "600" }}>国東市 早世桐植林プロジェクト</Box>
              <Box sx={{ mt: 1, mb: 1 }}>
                <Typography variant="caption">テキスト</Typography>
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ minWidth: "300px", maxWidth: "300px", color: "#ffffff" }}>
            <CardMedia sx={{ height: 160 }} image={NCCC_KV} title="event" />
            <CardContent>
              <Box sx={{ fontWeight: "600" }}>国東市 早世桐植林プロジェクト</Box>
              <Box sx={{ mt: 1, mb: 1 }}>
                <Typography variant="caption">テキスト</Typography>
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ minWidth: "300px", maxWidth: "300px", color: "#ffffff" }}>
            <CardMedia sx={{ height: 160 }} image={NCCC_KV} title="event" />
            <CardContent>
              <Box sx={{ fontWeight: "600" }}>国東市 早世桐植林プロジェクト</Box>
              <Box sx={{ mt: 1, mb: 1 }}>
                <Typography variant="caption">テキスト</Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box> */}
      {[RoleType.BoardOfDirectors, RoleType.Admin].includes(authUser?.roleType) && (
        <>
          <Typography sx={{ mt: 3, mb: 1, fontWeight: "bold" }} color="text.secondary">
            最近のトピック・タスク更新
          </Typography>
          <div>
            <Box sx={{ display: "flex", gap: "20px", mt: 1, flexWrap: "wrap" }}>
              <Card
                sx={{
                  width: `calc("100vh" - 200)`,
                  padding: 1,
                  backgroundColor: "#ffffff",
                  overflow: "auto",
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "center",
                      flexDirection: "column",
                      fontSize: "12px",
                    }}
                  >
                    {newTask?.map((task, index) => {
                      return (
                        <div key={index}>
                          <span>{dayjs(task.updated).format("YYYY/MM/DD HH:mm")}</span>
                          <span style={{ marginLeft: "20px" }}>
                            {task.updatedUser
                              ? task.updatedUser.username
                              : task.createdUser
                              ? task.createdUser.username
                              : ""}{" "}
                            さんが
                          </span>
                          <span style={{ marginLeft: "10px" }}>
                            <Link
                              to={`/member/task/detail/${task._id}`}
                              onClick={() => window.scrollTo(0, 0)}
                            >
                              「{task.title}」
                            </Link>
                            を{taskActionName(task)}しました。
                          </span>
                        </div>
                      );
                    })}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </div>
          <Typography sx={{ mt: 3, mb: 1, fontWeight: "bold" }} color="text.secondary">
            自分が担当者のトピック・タスク
          </Typography>
          <Box sx={{ display: "flex", padding: "5px" }}>
            <div style={{ height: "max-content", width: "100%" }}>
              <DataGrid
                rows={myTask}
                columns={taskColumns}
                getRowId={(list) => list._id}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={pageSizeOptions}
                autoHeight
              />
            </div>
          </Box>
        </>
      )}
      <Box sx={{ p: 3, padding: { xs: "24px 0", md: "24px" } }}>
        <h4>セミナー情報</h4>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 5, flexWrap: "wrap" }}>
          <Box sx={{ maxWidth: "600px" }}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              locales={[jaLocale]}
              locale="ja"
              contentHeight={"auto"}
              events={event}
              eventContent={renderEventContent}
              isSelected={true}
            />
          </Box>
          <Box>
            <h4>開催情報・アーカイブ一覧</h4>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "5px 0" }}>
              <Chip
                label="ALL"
                sx={{ mr: 1 }}
                color={isEventTagAll ? "info" : "default"}
                onClick={handleEventAll}
              />
              <Chip
                label="これから開催のイベント"
                sx={{ mr: 1 }}
                color={isEventTagFocus ? "info" : "default"}
                onClick={handleEventFuture}
              />
              <Chip
                label="過去のイベント"
                sx={{ mr: 1 }}
                color={isEventTagFocus2 ? "info" : "default"}
                onClick={handleEventPast}
              />
            </Box>
            <List sx={{ width: "100%", maxWidth: 400 }}>
              {event.map((item, index) => (
                <>
                  <ListItemButton
                    alignItems="flex-start"
                    component={Link}
                    to={"/member/eventdetail/" + item._id}
                  >
                    <ListItemAvatar>
                      <img src={item.coverImgUrl} width="100px" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.eventName}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            開催日：{moment(item.startDate).format("YYYY年MM月DD日 HH:mm")}
                          </Typography>
                          {item.eventDesc.substr(0, 15) + "..."}
                        </React.Fragment>
                      }
                      sx={{ ml: 3 }}
                    />
                  </ListItemButton>
                  <Divider variant="inset" component="li" />
                </>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const textTrim = (text) => {
  // テキストをトリミングする要素
  var selector = text;

  // 制限する文字数
  var wordCount = 57;

  // 文末に追加したい文字
  var clamp = "…";

  for (var i = 0; i < selector.length; i++) {
    // 文字数を超えたら
    if (selector[i].innerText.length > wordCount) {
      var str = selector[i].innerText; // 文字数を取得
      str = str.substr(0, wordCount - 1); // 1文字削る
      selector[i].innerText = str + clamp; // 文末にテキストを足す
    }
  }
};
