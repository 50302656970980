import React from "react";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuthUserContext, RoleType } from "../../providers/auth";
import memberApi from "../../api/member";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { Box, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/system";
// import authApi from "../../api/authApi";
import logo from "../../assets/imgs/NCCC_logo_symbol.png";

export const MemberLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location ", location);
  const fromPathName = location.state?.from.pathname || "/member";
  const authUser = useAuthUserContext();

  const [loading, setLoading] = useState(false);
  const [emailErrText, setEmailErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");
  const [form, setForm] = useState({ email: "", password: "" });

  const handleChange = (input) => (e) => {
    setForm({ ...form, [input]: e.target.value });
  };

  const handleSubmit2 = async (e) => {
    const res = await memberApi.signin({
      email: form.email,
      password: form.password,
    });
    // console.log("res -> ", res);

    // setCookie(null, "token", res.token, { maxAge: 30 * 24 * 60 * 60 });
    // setCookie(null, "roleType", res.user.membership, { maxAge: 30 * 24 * 60 * 60 });
    authUser.signin(res.user, () => {
      navigate(fromPathName, { replace: true });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailErrText("");
    setPasswordErrText("");

    const data = new FormData(e.target);
    const email = data.get("email").trim();
    const password = data.get("password").trim();

    let error = false;

    if (email === "") {
      error = true;
      setEmailErrText("ログインIDを入力してください。");
    }
    if (password === "") {
      error = true;
      setPasswordErrText("パスワードを入力してください。");
    }

    if (error) return;

    //ローディング開始
    setLoading(true);

    //ログイン用APIを叩く
    try {
      const res = await memberApi.signin({ email, password });
      setLoading(false);

      authUser.signin(res.user, () => {
        navigate(fromPathName, { replace: true });
      });

      // localStorage.setItem("token", res.token);
    } catch (err) {
      const errors = err?.data?.errors;
      if (errors?.length) {
        errors.forEach((e) => {
          if (e.param === "email") {
            setEmailErrText(e.msg);
          }
          if (e.param === "password") {
            setPasswordErrText(e.msg);
          }
        });
      } else {
        console.error(err);
      }
      setLoading(false);
    }
  };

  const getAuth = async () => {
    const cookies = parseCookies();
    const token = cookies.token;
    //    console.log("token -> ", token);
    const res = await memberApi.auth({ token });
    //    console.log("res -> ", res);
  };

  const register = async () => {
    try {
      const res = await memberApi.register({
        companyId: "6466b9ba897810a474d80693",
        name: "オブザーバー会員",
        nameKana: "オブザーバーカイイン",
        email: "observerMember@nccc.test",
        password: "test1234",
        membership: "observerMember",
      });
      //      console.log("res -> ", res);
    } catch (err) {
      console.log("err -> ", err);
    }
  };

  return (
    <>
      {/* <Box sx={{ margin: "2rem" }}>
        <h3>Login</h3>
        <Box sx={{ display: "flex", margin: "2rem 0", gap: 2 }}>
          <TextField
            label="email"
            name="email"
            value={form.email}
            onChange={handleChange("email")}
          ></TextField>
          <TextField
            label="password"
            name="password"
            value={form.password}
            onChange={handleChange("password")}
          ></TextField>
          <Button onClick={handleSubmit}>サインイン</Button>
        </Box>
        <button onClick={() => setForm({ email: "admin@nccc.test", password: "test1234" })}>
          管理者
        </button>
        <button
          onClick={() => setForm({ email: "boardOfDirectors@nccc.test", password: "test1234" })}
        >
          理事会
        </button>
        <button
          onClick={() => setForm({ email: "thirdPartyCommittee@nccc.test", password: "test1234" })}
        >
          第三者委員会
        </button>
        <button onClick={() => setForm({ email: "fullMember@nccc.test", password: "test1234" })}>
          正会員
        </button>
        <button onClick={() => setForm({ email: "generalMember@nccc.test", password: "test1234" })}>
          一般会員
        </button>
        <button
          onClick={() => setForm({ email: "observerMember@nccc.test", password: "test1234" })}
        >
          オブザーバー会員
        </button>
      </Box> */}

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <img src={logo} alt="logo" />
        </Box>
        <Box component="form" sx={{ mt: 1 }} noValidate onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            disabled={loading}
            required
            error={emailErrText !== ""}
            helperText={emailErrText}
          />
          <TextField
            margin="normal"
            fullWidth
            id="password"
            label="パスワード"
            name="password"
            type="password"
            disabled={loading}
            required
            error={passwordErrText !== ""}
            helperText={passwordErrText}
          />
          <LoadingButton
            sx={{ mt: 3, mb: 2 }}
            variant="outlined"
            fullWidth
            color="primary"
            type="submit"
            loading={loading}
          >
            ログイン
          </LoadingButton>
        </Box>
      </Container>
    </>
  );
};
