import axiosClient from "./axiosClient";

const taskApi = {
  getList: (params) => axiosClient.get("task/getList", params),
  // getCompany: (params) => axiosClient.post("auth/company", params),
  // getByOne: (params) => axiosClient.get("user/getByOne", params),
  // getDirectors: (params) => axiosClient.get("user/directors", params),
  // getByMemberships: (params) => axiosClient.get("user/getByMemberships", params),
  // getList: () => axiosClient.get("user/list"),
  getById: (params) => axiosClient.get("task/getById", params),
  register: (params) => axiosClient.post("task/register", params),

  detailUpdate: (params) => axiosClient.post("task/detailUpdate", params),
  taskStatusUpdate: (params) => axiosClient.post("task/taskStatusUpdate", params),
  milestoneStatusUpdate: (params) => axiosClient.post("task/milestoneStatusUpdate", params),
  taskLimitUpdate: (params) => axiosClient.post("task/taskLimitUpdate", params),
  milestoneLimitUpdate: (params) => axiosClient.post("task/milestoneLimitUpdate", params),
  assignedMemberUpdate: (params) => axiosClient.post("task/assignedMemberUpdate", params),
  taskUpdate: (params) => axiosClient.post("task/taskUpdate", params),
  getListByHome: (params) => axiosClient.get("task/getListByHome", params),
};

export default taskApi;
