import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import logo from "../assets/imgs/NCCC_logo_symbol.png";
import eventApi from "../api/eventApi";

const Form = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectNumOfPeoples, setNumOfPeoples] = React.useState(1);
  const [agreement, setAgreement] = React.useState(false);
  let error = false;
  const [errorText, setErrorText] = useState([""]);
  const [isSendOnlineMtg, setIsSendOnlineMtg] = useState("未連携");

  const selectChange = (event) => {
    setNumOfPeoples(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorText([]);
    const data = new FormData(e.target);
    const attendance = data.get("attendance").trim();
    const numOfJoin = selectNumOfPeoples;
    const attendancePeoples = [];

    if (attendance === "local" || attendance === "non") {
      setIsSendOnlineMtg("不要");
    }
    if (attendance === "online") {
      setIsSendOnlineMtg("未連携");
    }
    for (let i = 0; i < numOfJoin; i++) {
      const username_sei = data.get("username_sei" + i).trim();
      const username_mei = data.get("username_mei" + i).trim();
      const username_sei_kana = data.get("username_sei_kana" + i).trim();
      const username_mei_kana = data.get("username_mei_kana" + i).trim();
      if (username_sei === "") {
        error = true;
        setErrorText((arr) => [...arr, i + 1 + "人目の" + "ご出席者名（姓）を入力してください。"]);
      }
      if (username_mei === "") {
        error = true;
        setErrorText((arr) => [...arr, i + 1 + "人目の" + "ご出席者名（名）を入力してください。"]);
      }
      if (username_sei_kana === "") {
        error = true;
        setErrorText((arr) => [
          ...arr,
          i + 1 + "人目の" + "ご出席者名（セイ）を入力してください。",
        ]);
      }
      if (username_mei_kana === "") {
        error = true;
        setErrorText((arr) => [
          ...arr,
          i + 1 + "人目の" + "ご出席者名（メイ）を入力してください。",
        ]);
      }
      attendancePeoples.push({
        username_sei: username_sei,
        username_mei: username_mei,
        username_sei_kana: username_sei_kana,
        username_mei_kana: username_mei_kana,
      });
    }

    if (attendance === "") {
      error = true;
      setErrorText((arr) => [...arr, "出席の有無を選択してください。"]);
    }
    if (numOfJoin === "") {
      error = true;
      setErrorText((arr) => [...arr, "参加人数を選択してください。"]);
    }

    if (error) return;

    const requestData = {
      id: slug,
      isJoin: attendance,
      numOfJoin,
      attendancePeoples,
      isSendOnlineMtg,
      surveyStatus: "未送信",
    };
    const res = await eventApi.updateById(requestData);
    if (res.message === "completed") {
      navigate(`/form-complete`);
    }
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          padding: "40px",
          "@media only screen and (max-device-width: 480px)": {
            padding: "40px 20px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "50px",
            "@media only screen and (max-device-width: 480px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <img src={logo} alt="logo" style={{ maxWidth: "76px" }} />
        </Box>

        <Box sx={{ textAlign: "end", margin: "20px" }}>
          一般社団法人ナチュラルキャピタルクレジットコンソーシアム 理事長　馬奈木俊介
        </Box>
        <Box
          sx={{
            backgroundColor: "#EAEAEA",
            textAlign: "left",
            fontSize: "36px",
            padding: "20px",
            margin: "70px 0",
            "@media only screen and (max-device-width: 480px)": {
              fontSize: "24px",
              margin: "10px 0",
            },
          }}
        >
          一般社団法人ナチュラルキャピタルクレジットコンソーシアム
          設立総会・CO2削減に取り組むコンシャス企業に向けた説明会開催のお知らせ
        </Box>

        <Box sx={{ margin: "40px 0px" }}>
          <Typography variant="h5">開催概要</Typography>
          <Box sx={{ margin: "20px 0px" }}>
            謹啓　春暖の候ますますご清栄のこととお喜び申し上げます。平素は格別のご高配を賜り、厚く御礼申し上げます。
            2022年12月15日にテクノロジーを活用して地方創生とカーボンクレジット取引活性化の両立を目指し「ナチュラルキャピタルクレジットコンソーシアム（任意団体）」を立ち上げ、カーボンクレジット市場の活性化を促進して参りました。
            さらにSDG'sを原動力とし、特に脱炭素価値の評価、分析、認証及び助言を通じて、ネイチャーポジティブ、グリーンインフラストラクチャ―等に関する地域創生事業に係ることで、二酸化炭素の削減に寄与する事を目指し、官民連携を推し進める一般社団法人全国古民家再生協会の参画により公益性を高めた地域密着の組織として、このたび法人化（一般社団法人）をおこないました。
            下記のとおり設立総会を開催させていただきますので、ご多忙のことと存じますが、何卒ご臨席を賜りますようお願い申し上げます。
            <Box sx={{ textAlign: "right" }}>謹白</Box>
          </Box>
        </Box>

        <h2>参加可否について</h2>
        <Box sx={{ padding: "0px", color: "red", fontSize: "14px" }}>
          {errorText.map((text) => {
            return (
              <Box key={text}>
                <p>{text}</p>
              </Box>
            );
          })}
        </Box>
        <Box component="form" sx={{ mt: 1 }} noValidate onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel id="attendance-radio-buttons-group-label">
              <h3>
                出欠のご確認<span>*</span>
              </h3>
            </FormLabel>
            <RadioGroup
              id="attendance"
              name="attendance"
              aria-labelledby="attendance-radio-buttons-group-label"
              defaultValue="local"
            >
              <FormControlLabel value="local" control={<Radio />} label="現地参加" />
              <FormControlLabel value="online" control={<Radio />} label="オンライン参加" />
              <FormControlLabel value="non" control={<Radio />} label="不参加" />
            </RadioGroup>
          </FormControl>
          <h3>
            出席者人数<span>*</span>
          </h3>
          <FormControl fullWidth>
            <InputLabel id="numOfJoin">人数</InputLabel>
            <Select
              labelId="numOfJoin"
              id="selectNumOfPeoples"
              label="人数"
              value={selectNumOfPeoples}
              onChange={selectChange}
            >
              <MenuItem value={1}>１名</MenuItem>
              <MenuItem value={2}>２名</MenuItem>
              <MenuItem value={3}>３名</MenuItem>
              <MenuItem value={4}>４名</MenuItem>
              <MenuItem value={5}>５名</MenuItem>
            </Select>
          </FormControl>
          <h3>
            ご出席者様氏名<span>*</span>
          </h3>

          <Box>
            {[...Array(selectNumOfPeoples)].map((_, i) => {
              return (
                <Box key={i}>
                  <p>{i + 1}人目のご出席者様氏名を入力してください。</p>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      id={"username_sei" + i}
                      label="ご出席者名（姓）"
                      margin="normal"
                      name={"username_sei" + i}
                      required
                    ></TextField>
                    <TextField
                      id={"username_mei" + i}
                      label="ご出席者名（名）"
                      margin="normal"
                      name={"username_mei" + i}
                      required
                    ></TextField>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      id={"username_sei_kana" + i}
                      label="ご出席者名（セイ）"
                      margin="normal"
                      name={"username_sei_kana" + i}
                      required
                    ></TextField>
                    <TextField
                      id={"username_mei_kana" + i}
                      label="ご出席者名（メイ）"
                      margin="normal"
                      name={"username_mei_kana" + i}
                      required
                    ></TextField>
                  </Box>
                </Box>
              );
            })}

            <InputLabel id="demo-simple-select-label">
              <Checkbox
                onChange={(event) => {
                  setAgreement(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <a href="/privacypolicy" target="blank">
                プライバシーポリシー
              </a>
              に同意する
            </InputLabel>

            <LoadingButton
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              type="submit"
              loading={loading}
              color="primary"
              variant="contained"
              disabled={!agreement}
              size="large"
            >
              送信
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Form;
