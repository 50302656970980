import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import authUtils from "../../utils/authUtils";
import AppBar from "../common/Appbar";
import "../../assets/css/Global.css";
import "../../assets/css/layout/style.css";

const RegisterLayout = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      //ページ切り替える度に認証チェック(トークン持ってるかどうか確認)
      //ここで404notfoud
      const isAuth = await authUtils.isAuthenticated();
      if (!isAuth) {
        setLoading(false);
      } else {
        navigate("/");
      }
    };
    checkAuth();
  }, [navigate]);
  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <AppBar />
      <Box
        sx={{
          paddingTop: 20,
          paddingBottom: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Typography variant="h4" component="h3">
          申請フォーム
        </Typography>
      </Box>
      <Container component="main" maxWidth="lg" sx={{ backgroundColor: "white" }}>
        <Outlet />
      </Container>
    </div>
  );
};

export default RegisterLayout;
