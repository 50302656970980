import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Input,
  Select,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tooltip,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  Chip,
} from "@mui/material";
import { DataGrid, useGridApiRef, GridActionsCellItem } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { MuiFileInput } from "mui-file-input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditIcon from '@mui/icons-material/Edit';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import dayjs from "dayjs";
import lodash from 'lodash';
import { MathJax } from "better-react-mathjax"
import projectApi from "../../../api/projectApi";
import programApi from "../../../api/programApi";
import branchApi from "../../../api/branchApi";
import authApi from "../../../api/authApi";
import mailApi from "../../../api/mailApi";
import categoryApi from "../../../api/categoryApi";
import counterApi from "../../../api/counterApi";

import uploadImg from "../../../components/common/utils/uploadImg";
import uploadFile from "../../../components/common/utils/firebase/uploadFile";
import uploadPdfWithConvertImg from "../../../components/common/utils/firebase/uploadPdfWithConvertImg"
import getFileList from "../../../components/common/utils/firebase/getFileList";
import CategoryDropdown from "../../../components/common/categoryDropdown";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, option, theme) {
  return {
    fontWeight:
      option.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const ProjectEdit = () => {
  const navigate = useNavigate();
  const storedUserInfo = useSelector((state) => state.user);
  const { id } = useParams();
  const [company, setCompany] = useState({});
  const [programs, setPrograms] = useState([]);
  const [branches, setBranches] = useState([]);
  const [tmpSaveProjectData, setTmpSaveProjectData] = useState({});
  const [processing, setProcessing] = useState(false);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const programId = urlParams.get("programId");
  const branchId = urlParams.get("branchId");

  const steps = [
    {
      label: "基本情報"
    },
    {
      label: "プロジェクト概要"
    },
    {
      label: "プロジェクト詳細"
    },
    {
      label: "プロジェクトの適格性"
    },
    {
      label: "環境および社会的配慮"
    },
    {
      label: "実施状況"
    },
    {
      label: "プロジェクト期間と推定GHG排出量"
    },
    {
      label: "方法論の摘要"
    },
    {
      label: "GHG排出削減量と除去量の計算"
    },
    {
      label: "モニタリング"
    },
    {
      label: "GHG排出削減と除去の定量化"
    },
    {
      label: "参考文献"
    },
    {
      label: "補足事項等"
    },
  ]

  const fetchprogramList = async () => {
    // 承認済みのプログラム
    const approvedPrograms = await programApi.getApprovedList();
    setPrograms(approvedPrograms);

    // 承認済みの支部
    const approvedBranches = await branchApi.getApprovedList();
    const filterdBranches = approvedBranches.filter(
      (branch) => branch.branchMasterId === storedUserInfo.value._id
    );
    setBranches(filterdBranches);

    const companyData = await authApi.getCompany({
      id: storedUserInfo.value.companyId,
    });
    setCompany(companyData.company);
  };

  const fetchProject = async () => {
    setProcessing(true)
    const projectData = await projectApi.getById({
      params: { _id: id },
    });
    const tmpData = projectData.project
    setTmpSaveProjectData(tmpData)
    setValue("createdCompanyText", tmpData?.creator?.createdCompanyText || company.groupName)
    setValue("createdUserText", tmpData?.creator?.createdUserText || storedUserInfo?.value.username)
    setValue("projectName", tmpData.projectName)
    setValue("projectDesc", tmpData.projectDesc)
    setValue("branchId", tmpData.branchId._id)
    setProjectCategory(tmpData.projectCategory)
    setValue("projectCategory", tmpData.projectCategory)
    tmpData.audit && setAuditHistoryRows(addIdForDataGridRows(tmpData.audit))
    setProjectDesignCategory(tmpData.projectDesignCategory)
    setValue("actExplain", tmpData.actExplain)
    // setValue("projectPlace", tmpData.projectPlace)
    tmpData.projectPlace && setProjectPlaceRows(addIdForDataGridRows(tmpData.projectPlace))
    // if (!tmpData.projectPlaceFile) { setValue("projectPlaceFile", tmpData.projectPlaceFile) }
    if(tmpData.projectPlaceFile && tmpData.projectPlaceFile.length > 0) setProjectPlaceFileList(tmpData.projectPlaceFile)
    setValue("proposerTel", tmpData.proposer.tel)
    setValue("refOtherCompany", tmpData.refOtherCompany)
    setValue("projectOwnership", tmpData.projectOwnership)
    if(tmpData.projectOwnershipFile.length > 0) setProjectOwnershipFileList(tmpData.projectOwnershipFile)
    setValue("projectEligibility", tmpData.projectEligibility)
    if(tmpData.projectEligibilityFileList && tmpData.projectEligibilityFileList.length > 0) setProjectEligibilityFileList(tmpData.projectEligibilityFileList)
    setValue("beforeStartingProject", tmpData.beforeStartingProject)
    if(tmpData.beforeStartingProjectFileList && tmpData.beforeStartingProjectFileList.length > 0) setBeforeStartingProjectFileList(tmpData.beforeStartingProjectFileList)
    setValue("legalCompliance", tmpData.legalCompliance)
    if(tmpData.legalComplianceFileList && tmpData.legalComplianceFileList.length > 0) setLegalComplianceFileList(tmpData.legalComplianceFileList)
    setDuplicatePublishCheck(tmpData.noDuplicatePublish.check)
    setValue("noDuplicatePublish", tmpData.noDuplicatePublish.evidence)
    setDuplicateClaimCheck(tmpData.noDuplicateClaim.check)
    setValue("noDuplicateClaim", tmpData.noDuplicateClaim.evidence)
    tmpData.stakeholderDescription && setStakeholdersDescriptionRows(addIdForDataGridRows(tmpData.stakeholderDescription))
    setValue("stakeholderRegulations", tmpData.stakeholderRegulations)
    // if (!tmpData.stakeholderRegulationsFile) { setValue("stakeholderRegulationsFile", tmpData.stakeholderRegulationsFile) }
    if(tmpData.stakeholderRegulationsFile && tmpData.stakeholderRegulationsFile.length > 0) setStakeholderRegulationsFileList(tmpData.stakeholderRegulationsFile)
    setValue("envRisk", tmpData.envRisk)
    // if (!tmpData.envRiskFile) { setValue("envRiskFile", tmpData.envRiskFile) }
    if(tmpData.envRiskFile && tmpData.envRiskFile.length > 0) setEnvRiskFileList(tmpData.envRiskFile)
    setValue("fairness", tmpData.fairness)
    // if (!tmpData.fairnessFile) { setValue("fairnessFile", tmpData.fairnessFile) }
    if(tmpData.fairnessFile && tmpData.fairnessFile.length > 0) setFairnessFileList(tmpData.fairnessFile)
    setSoundnessACheck(tmpData.soundnessA.soundnessACheck)
    setValue("soundnessA1", tmpData.soundnessA.soundnessA1)
    setValue("soundnessA2", tmpData.soundnessA.soundnessA2)
    setSoundnessBCheck(tmpData.soundnessB.soundnessBCheck)
    setValue("soundnessB1", tmpData.soundnessB.soundnessB1)
    setValue("soundnessB2", tmpData.soundnessB.soundnessB2)
    setSoundnessCCheck(tmpData.soundnessC.soundnessCCheck)
    setValue("soundnessC1", tmpData.soundnessC.soundnessC1)
    setValue("soundnessC2", tmpData.soundnessC.soundnessC2)
    setSoundnessDCheck(tmpData.soundnessD.soundnessDCheck)
    setValue("soundnessD1", tmpData.soundnessD.soundnessD1)
    setValue("soundnessD2", tmpData.soundnessD.soundnessD2)
    // if (!tmpData.soundnessFile) { setValue("soundnessFile", tmpData.soundnessFile) }
    if(tmpData.soundnessFile && tmpData.soundnessFile.length > 0) setSoundnessFileList(tmpData.soundnessFile)
    setValue("sdContribution", tmpData.sdContribution)
    setValue("projectAddInfo", tmpData.projectAddInfo)
    setValue("implementationStatus", tmpData.implementationStatus)
    setValue("deviation", tmpData.deviation)
    setValue("groupedProject", tmpData.groupedProject)
    setValue("baselineReassessment", tmpData.baselineReassessment)
    // setProjectStartDate(dayjs(tmpData.projectStartDate.date))
    tmpData.projectStartDate && setProjectStartDateRows(addIdForDataGridRows(tmpData.projectStartDate))
    setValue("startDateRightfulness", tmpData.startDateRightfulness)
    setValue("creditTerm", tmpData.projectCreditTerm.creditTerm)
    setCreditStartDate(dayjs(tmpData.projectCreditTerm.creditStartDate))
    setCreditEndDate(dayjs(tmpData.projectCreditTerm.creditEndDate))
    tmpData.estimatedValue && setEstimatedValue1Rows(recoverEstimatedValue1Rows(tmpData.estimatedValue))
    setValue("estimatedERR", tmpData.estimatedERR)
    setValue("totalYears", tmpData.totalYears)
    setValue("aveYearlyERR", tmpData.aveYearlyERR)
    tmpData.programs && setMethodologyRows(addIdForDataGridRows(tmpData.programs))
    setValue("programApplicability", tmpData.programApplicability)
    tmpData.projectBoundary && setProjectBoundaryRows(addIdForDataGridRows(tmpData.projectBoundary))
    // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
    // if (!tmpData.projectBoundaryFile) { setValue("projectBoundaryFile", "") }
    if(tmpData.projectBoundaryFile && tmpData.projectBoundaryFile.length > 0) setProjectBoundaryFileList(tmpData.projectBoundaryFile)
    setValue("baselineScenario", tmpData.baselineScenario)
    setValue("additionality", tmpData.additionality)
    setValue("deviateProgram", tmpData.deviateProgram)
    setValue("baselineEmissions", tmpData.baselineEmissions)
    setBaselineEmissions(tmpData.baselineEmissions)
    setValue("projectEmissions", tmpData.projectEmissions)
    // if (!tmpData.projectEmissionsFile) { setValue("projectEmissionsFile", tmpData.projectEmissionsFile) }
    if(tmpData.projectEmissionsFile && tmpData.projectEmissionsFile.length > 0) setProjectEmissionsFileList(tmpData.projectEmissionsFile)
    setProjectEmissions(tmpData.projectEmissions)
    setValue("leakageEmissions", tmpData.leakageEmissions)
    setLeakageEmissions(tmpData.leakageEmissions)
    setValue("nonPermanentRiskEvaluation", tmpData.nonPermanentRiskEvaluation)
    setNonPermanentRiskReportCheck(tmpData.nonPermanentRiskReportCheck)
    setValue("projectLTA", tmpData.projectLTA)
    setUpdateLtaCheck(tmpData.updateLta.check)
    setValue("NonUpdateLtaReason", tmpData.updateLta.NonUpdateLtaReason)
    setValue("expectGhgEmissions", tmpData.expectGhgEmissions)
    setGhgCreditCheck(tmpData.belowLta.check)
    setValue("NotBelowLtaReason", tmpData.belowLta.NotBelowLtaReason)
    tmpData.emissions.detail && setProjectCreditTermRows(recoverProjectCreditTermRows(tmpData.emissions.detail))
    tmpData.availableData && setMonitoring1Rows(addIdForDataGridRows(tmpData.availableData))
    tmpData.monitoringData && setMonitoring2Rows(addIdForDataGridRows(tmpData.monitoringData))
    if(tmpData.monitoringDataSource1FileList && tmpData.monitoringDataSource1FileList.length > 0) setMonitoringDataSource1FileList(tmpData.monitoringDataSource1FileList)
    if(tmpData.monitoringDataSource2FileList && tmpData.monitoringDataSource2FileList.length > 0) setMonitoringDataSource2FileList(tmpData.monitoringDataSource2FileList)
    setValue("monitoringPlan", tmpData.monitoringPlan)
    // if (!tmpData.monitoringPlanFile) { setValue("monitoringPlanFile", tmpData.monitoringPlanFile) }
    tmpData.quantifyBaselineEmissions && setQuantifyBaselineEmissions(tmpData.quantifyBaselineEmissions)
    tmpData.quantifyProjectEmissions && setQuantifyProjectEmissions(tmpData.quantifyProjectEmissions)
    tmpData.quantifyLeakageEmissions && setQuantifyLeakageEmissions(tmpData.quantifyLeakageEmissions)
    setValue("quantifyNonPermanentRiskEvaluation", tmpData.quantifyNonPermanentRiskEvaluation)
    setValue("quantifyMonitoring", tmpData.quantifyMonitoring)
    if(tmpData.monitoringPlanFile && tmpData.monitoringPlanFile.length > 0) setMonitoringPlanFileList(tmpData.monitoringPlanFile)
    setValue("references", tmpData.references)
    tmpData.secret && setSecretInfoRows(addIdForDataGridRows(tmpData.secret))
    setValue("otherInfo", tmpData.otherInfo)

    // 序盤でsetしている項目がセットされないことがあるので再度setさせる
    // TODO:１回のsetにする
    setValue("createdCompanyText", tmpData?.creator?.createdCompanyText || company.groupName)
    setValue("createdUserText", tmpData?.creator?.createdUserText || storedUserInfo?.value.username)
    setValue("projectName", tmpData.projectName)
    setValue("projectDesc", tmpData.projectDesc)
    setValue("branchId", tmpData.branchId._id)
    setProjectCategory(tmpData.projectCategory)
    setValue("projectCategory", tmpData.projectCategory)
    // tmpData.audit && setAuditHistoryRows(addIdForDataGridRows(tmpData.audit))
    setProjectDesignCategory(tmpData.projectDesignCategory)
    setValue("actExplain", tmpData.actExplain)
    // setValue("projectPlace", tmpData.projectPlace)
    // tmpData.projectPlace && setProjectPlaceRows(addIdForDataGridRows(tmpData.projectPlace))
    // if (!tmpData.projectPlaceFile) { setValue("projectPlaceFile", tmpData.projectPlaceFile) }
    if(tmpData.projectPlaceFile && tmpData.projectPlaceFile.length > 0) setProjectPlaceFileList(tmpData.projectPlaceFile)
    setValue("proposerTel", tmpData.proposer.tel)
    setValue("refOtherCompany", tmpData.refOtherCompany)
    setValue("projectOwnership", tmpData.projectOwnership)
    if(tmpData.projectOwnershipFile.length > 0) setProjectOwnershipFileList(tmpData.projectOwnershipFile)
    setValue("projectEligibility", tmpData.projectEligibility)
    if(tmpData.projectEligibilityFile && tmpData.projectEligibilityFile.length > 0) setProjectEligibilityFileList(tmpData.projectEligibilityFile)
    setValue("beforeStartingProject", tmpData.beforeStartingProject)
    if(tmpData.beforeStartingProjectFile && tmpData.beforeStartingProjectFile.length > 0) setBeforeStartingProjectFileList(tmpData.beforeStartingProjectFile)
    setValue("legalCompliance", tmpData.legalCompliance)
    if(tmpData.legalComplianceFile && tmpData.legalComplianceFile.length > 0) setLegalComplianceFileList(tmpData.legalComplianceFile)
    setDuplicatePublishCheck(tmpData.noDuplicatePublish.check)
    setValue("noDuplicatePublish", tmpData.noDuplicatePublish.evidence)
    setDuplicateClaimCheck(tmpData.noDuplicateClaim.check)
    setValue("noDuplicateClaim", tmpData.noDuplicateClaim.evidence)
    setValue("stakeholderRegulations", tmpData.stakeholderRegulations)
    // if (!tmpData.stakeholderRegulationsFile) { setValue("stakeholderRegulationsFile", tmpData.stakeholderRegulationsFile) }
    if(tmpData.stakeholderRegulationsFile && tmpData.stakeholderRegulationsFile.length > 0) setStakeholderRegulationsFileList(tmpData.stakeholderRegulationsFile)
    setValue("envRisk", tmpData.envRisk)
    // if (!tmpData.envRiskFile) { setValue("envRiskFile", tmpData.envRiskFile) }
    if(tmpData.envRiskFile && tmpData.envRiskFile.length > 0) setEnvRiskFileList(tmpData.envRiskFile)
    setValue("fairness", tmpData.fairness)
    // if (!tmpData.fairnessFile) { setValue("fairnessFile", tmpData.fairnessFile) }
    if(tmpData.fairnessFile && tmpData.fairnessFile.length > 0) setFairnessFileList(tmpData.fairnessFile)
    setSoundnessACheck(tmpData.soundnessA.soundnessACheck)
    setValue("soundnessA1", tmpData.soundnessA.soundnessA1)
    setValue("soundnessA2", tmpData.soundnessA.soundnessA2)
    setSoundnessBCheck(tmpData.soundnessB.soundnessBCheck)
    setValue("soundnessB1", tmpData.soundnessB.soundnessB1)
    setValue("soundnessB2", tmpData.soundnessB.soundnessB2)
    setSoundnessCCheck(tmpData.soundnessC.soundnessCCheck)
    setValue("soundnessC1", tmpData.soundnessC.soundnessC1)
    setValue("soundnessC2", tmpData.soundnessC.soundnessC2)
    setSoundnessDCheck(tmpData.soundnessD.soundnessDCheck)
    setValue("soundnessD1", tmpData.soundnessD.soundnessD1)
    setValue("soundnessD2", tmpData.soundnessD.soundnessD2)
    // if (!tmpData.soundnessFile) { setValue("soundnessFile", tmpData.soundnessFile) }
    if(tmpData.soundnessFile && tmpData.soundnessFile.length > 0) setSoundnessFileList(tmpData.soundnessFile)
    setValue("sdContribution", tmpData.sdContribution)
    setValue("projectAddInfo", tmpData.projectAddInfo)
    // setProjectStartDate(dayjs(tmpData.projectStartDate.date))
    // tmpData.projectStartDate && setProjectStartDateRows(addIdForDataGridRows(tmpData.projectStartDate))
    setValue("startDateRightfulness", tmpData.startDateRightfulness)
    setValue("creditTerm", tmpData.projectCreditTerm.creditTerm)
    setCreditStartDate(dayjs(tmpData.projectCreditTerm.creditStartDate))
    setCreditEndDate(dayjs(tmpData.projectCreditTerm.creditEndDate))
    // tmpData.estimatedValue && setEstimatedValue1Rows(recoverEstimatedValue1Rows(tmpData.estimatedValue))
    setValue("estimatedERR", tmpData.estimatedERR)
    setValue("totalYears", tmpData.totalYears)
    setValue("aveYearlyERR", tmpData.aveYearlyERR)
    // tmpData.programs && setMethodologyRows(addIdForDataGridRows(tmpData.programs))
    setValue("programApplicability", tmpData.programApplicability)
    // tmpData.projectBoundary && setProjectBoundaryRows(addIdForDataGridRows(tmpData.projectBoundary))
    // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
    // if (!tmpData.projectBoundaryFile) { setValue("projectBoundaryFile", "") }
    if(tmpData.projectBoundaryFile && tmpData.projectBoundaryFile.length > 0) setProjectBoundaryFileList(tmpData.projectBoundaryFile)
    setValue("baselineScenario", tmpData.baselineScenario)
    setValue("additionality", tmpData.additionality)
    setValue("deviateProgram", tmpData.deviateProgram)
    setValue("baselineEmissions", tmpData.baselineEmissions)
    setBaselineEmissions(tmpData.baselineEmissions)
    setValue("projectEmissions", tmpData.projectEmissions)
    // if (!tmpData.projectEmissionsFile) { setValue("projectEmissionsFile", tmpData.projectEmissionsFile) }
    if(tmpData.projectEmissionsFile && tmpData.projectEmissionsFile.length > 0) setProjectEmissionsFileList(tmpData.projectEmissionsFile)
    setProjectEmissions(tmpData.projectEmissions)
    setValue("leakageEmissions", tmpData.leakageEmissions)
    setLeakageEmissions(tmpData.leakageEmissions)
    setValue("nonPermanentRiskEvaluation", tmpData.nonPermanentRiskEvaluation)
    setNonPermanentRiskReportCheck(tmpData.nonPermanentRiskReportCheck)
    setValue("projectLTA", tmpData.projectLTA)
    setUpdateLtaCheck(tmpData.updateLta.check)
    setValue("NonUpdateLtaReason", tmpData.updateLta.NonUpdateLtaReason)
    setValue("expectGhgEmissions", tmpData.expectGhgEmissions)
    setGhgCreditCheck(tmpData.belowLta.check)
    setValue("NotBelowLtaReason", tmpData.belowLta.NotBelowLtaReason)
    // tmpData.emissions.detail && setProjectCreditTermRows(recoverProjectCreditTermRows(tmpData.emissions.detail))
    // tmpData.availableData && setMonitoring1Rows(addIdForDataGridRows(tmpData.availableData))
    // tmpData.monitoringData && setMonitoring2Rows(addIdForDataGridRows(tmpData.monitoringData))
    if(tmpData.monitoringDataSource1File && tmpData.monitoringDataSource1File.length > 0) setMonitoringDataSource1FileList(tmpData.monitoringDataSource1File)
    if(tmpData.monitoringDataSource2File && tmpData.monitoringDataSource2File.length > 0) setMonitoringDataSource2FileList(tmpData.monitoringDataSource2File)
    setValue("monitoringPlan", tmpData.monitoringPlan)
    // if (!tmpData.monitoringPlanFile) { setValue("monitoringPlanFile", tmpData.monitoringPlanFile) }
    if(tmpData.monitoringPlanFile && tmpData.monitoringPlanFile.length > 0) setMonitoringPlanFileList(tmpData.monitoringPlanFile)
    setValue("references", tmpData.references)
    // tmpData.secret && setSecretInfoRows(addIdForDataGridRows(tmpData.secret))
    setValue("otherInfo", tmpData.otherInfo)

    // setProjectBoundaryFileList(await getFileList(`/project/${tmpData.projectNo}/projectBoundaryFile`))
    // setProjectOwnershipFileList(await getFileList(`/project/${tmpData.projectNo}/projectOwnershipFile`))
    setProcessing(false)
  }

  const sendApplyMail = async (data, planDocuments, additionalDocuments, projectId) => {
    const user = storedUserInfo.value;
    const program = await programApi.getProgram(data.programId);
    const branch = await branchApi.getBranch(data.branchId);

    const replaceWords = {
      applyUserName: user.username,
      groupName: company.groupName,
      projectName: data.projectName,
      programName: program.programName,
      branchName: branch.branchName,
      projectDesc: data.projectDesc,
      projectStart: new Date(data.startDate),
      projectEnd: new Date(data.endDate),
      planDocuments,
      additionalDocuments,
      url: "/member/projects/apply/" + projectId,
    };

    const reqData = { mailId: "22", replaceWords };
    await mailApi.apply(reqData);
  };

  // validation
  const { control, getValues, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      historyNo: 0,
      catgory: "",
      companyId: "",
      projectName: "",
      programId: programId ?? "",
      suitabilityReason: "",
      branchId: branchId ?? "",
      applicationDocuments: "",
      startDate: "",
      endDate: "",
      projectTimeLine: [],
      planDocuments: "",
      additionalDocuments: "",
      // 基本情報
      createdCompanyText: "",
      createdUserText: "",
      // 2.1 プロジェクト概要
      projectDesc: "",
      // 2.3 監査履歴
      auditType: "",
      auditHistoryTerm: "",
      auditHistoryProgram: "",
      auditHistoryName: "",
      auditHistoryYears: "",
      // 3.2 プロジェクト活動の説明
      actExplain: "",
      // 3.3 プロジェクト所在地
      projectPlace: "",
      projectPlaceFile: "",
      // 3.4 プロジェクト提案者
      proposerCompany: "",
      proposerStaff: "",
      proposerRole: "",
      proposerAddress: "",
      proposerEmail: "",
      proposerTel: "",
      // 3.5 プロジェクトに関与するその他の団体
      refOtherCompany: "",
      // 3.6 所有権
      projectOwnershipFile: "",
      projectOwnership: "",
      // 4.1 プロジェクトの適格性
      projectEligibility: "",
      projectEligibilityFile: "",
      // 4.2 プロジェクト開始前の状況
      beforeStartingProject: "",
      beforeStartingProjectFile: "",
      // 4.3 法律、法令、その他の規制枠組みの遵守
      legalCompliance: "",
      legalComplianceFile: "",
      // 4.4 二重請求と他のGHGプログラムへの参加
      noDuplicatePublish: "",
      // 4.5 二重請求、他の形態のクレジット、スコープ3排出量
      noDuplicateClaim: "",
      // 5.1 ステークホルダーの参画と協議
      stakeholderDescriptionItem: "",
      stakeholderDescriptionContents: "",
      stakeholderRegulations: "",
      stakeholderRegulationsFile: "",
      // 5.2 ステークホルダーと環境へのリスク
      envRisk: "",
      envRiskFile: "",
      // 5.3 人権と公平性の尊重
      fairness: "",
      fairnessFile: "",
      // 5.4 生態系の健全性
      soundnessA1: "",
      soundnessA2: "",
      soundnessB1: "",
      soundnessB2: "",
      soundnessC1: "",
      soundnessC2: "",
      soundnessD1: "",
      soundnessD2: "",
      soundnessFile: "",
      // 5.5 持続可能な開発への貢献
      sdContribution: "",
      // 5.6 プロジェクトに関連する追加情報
      projectAddInfo: "",
      // 6.1 プロジェクト開始日
      startDateRightfulness: "",
      // 6.2 プロジェクトのクレジット期間
      creditTerm: "",
      // 6.3 プロジェクト規模とGHG排出削減量または除去量の推定値
      estimatedValue1: "",
      estimatedERR: "",
      totalYears: "",
      aveYearlyERR: "",
      // 7.1 方法論のタイトルと参考文献
      program: "",
      methodologyType: "",
      methodologyRefId: "",
      methodologyTitle: "",
      methodologyVersion: "",
      // 7.2 方法論の適用性
      programApplicability: "",
      // 7.3 プロジェクト境界
      baselineOrSource: "",
      gasInclude: "",
      boundaryExplain: "",
      boundaryExtraExplain: "",
      projectBoundaryFile: "",
      // 7.4 ベースライン・シナリオ
      baselineScenario: "",
      // 7.5 追加性
      additionality: "",
      // 7.6 方法論の逸脱
      deviateProgram: "",
      // 8.1 ベースライン排出量
      baselineEmissions: "",
      // 8.2 プロジェクト排出量
      projectEmissions: "",
      projectEmissionsFile: "",
      // 8.3 リーケージ排出量
      leakageEmissions: "",
      // 8.4 GHG排出削減量と二酸化炭素除去量の推定値
      nonPermanentRiskEvaluation: "",
      projectLTA: "",
      NonUpdateLtaReason: "",
      expectGhgEmissions: "",
      NotBelowLtaReason: "",
      estimatedBaselineEmissions: "",
      estimatedProjectEmissions: "",
      estimatedLeakageEmissions: "",
      estimatedReductionCredit: "",
      estimatedRemoveCredit: "",
      estimatedTotalCredit: "",
      // 9.1
      monitoringDataOrParam1: "",
      monitoringDataUnit1: "",
      monitoringDataContents1: "",
      monitoringDataSource1: "",
      monitoringApplicableValue1: "",
      monitoringExplain1: "",
      monitoringPurpose1: "",
      monitoringComment1: "",
      // 9.2
      monitoringDataOrParam2: "",
      monitoringDataUnit2: "",
      monitoringDataContents2: "",
      monitoringDataSource2: "",
      monitoringExplain2: "",
      monitoringFrequency: "",
      monitoringApplicableValue2: "",
      monitoringDevice: "",
      monitoringQaQcProcedure: "",
      monitoringPurpose2: "",
      howToCalc: "",
      monitoringComment2: "",
      // 9.3 モニタリング計画
      monitoringPlan: "",
      monitoringPlanFile: "",
      // 10 GHG排出削減と除去の定量化
      // 10.1 ベースライン排出量
      quantifyBaselineEmissions: "",
      // 10.2 プロジェクト排出量
      quantifyProjectEmissions: "",
      // 10.3 リーケージ排出量
      quantifyLeakageEmissions: "",
      // 10.4 GHG排出削減量と二酸化炭素除去量_非永続的リスク評価
      quantifyNonPermanentRiskEvaluation: "",
      // 10.4 GHG排出削減量と二酸化炭素除去量_モニタリング
      quantifyMonitoring: "",
      // 10 参考文献
      references: "",
      // 11.1 補足事項等(商業上の機密情報)
      secretSection: "",
      secretInfo: "",
      secretJustification: "",
      // 11.2 その他・補足事項等
      otherInfo: "",
    },
  });

  const validationRules = {
    branchId: {
      required: "支部名を選択してください",
    },
    projectName: {
      required: "プロジェクト名を入力してください。",
      maxLength: { value: 20, message: "20文字以内で入力してください" },
    },
    programId: {
      required: "適応するプログラムを選択してください",
    },
    projectDesc: {
      required: "プロジェクト概要を入力してください",
      maxLength: { value: 800, message: "800文字以内で入力してください" },
    },
    startDate: {
      required: "プロジェクト開始日を入力してください",
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!dayjs(formatDate).isValid()) {
          return "日付形式が間違っています";
        }
        // 相関チェック　日付の前後チェック
        const endDate = getValues("endDate");
        if (value > endDate) {
          return "開始日は終了日以前の日付を指定してください。";
        }
      },
    },
    endDate: {
      required: "プロジェクト終了日を入力してください",
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!dayjs(formatDate).isValid()) {
          return "日付形式が間違っています";
        }
      },
    },
    projectPlace: {
      required: "実施場所を入力してください",
    },
    planDocuments: {
      required: "申請書類をアップロードしてください",
      validate: {
        isPdf: (value) => value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
    additionalDocuments: {
      validate: {
        isPdf: (value) =>
          !value || value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => !value || value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
  };

  const zeroPadding = (len, num) => {
    return ( Array(len).join('0') + num ).slice( -len )
  }

  const transNewlineCode = (text) => {
    const lines = text.split(/\n/)
    return (
      <>
        {lines.map((line, index) => {
          return (
            <React.Fragment key={index}>
              {line ? <MathJax>{line}</MathJax> : <br />}
              {/* {index + 1 !== lines.length && <br />} */}
            </React.Fragment>
          )
        })}
      </>
    )
  }

  // 関数とかこれにまとめると便利そうだからまとめただけ
  // 同じようなフォームが多いので、共通化したときに名称で呼び出せるようにしている
  const cluster = {
    baselineEmissions: {
      title: "ベースライン排出量",
      handleOpen: () => handleOpenBaselineEmissionsDialog(),
      handleClose: () => handleCloseBaselineEmissionsDialog(),
      add: null,
      update: () => updateQuantifyBaselineEmissions(),
      delete: null,
    },
    quantifyBaselineEmissions: {
      title: "ベースライン排出量",
      handleOpen: () => handleOpenQuantifyBaselineEmissionsDialog(),
      handleClose: () => handleCloseQuantifyBaselineEmissionsDialog(),
      add: null,
      update: () => updateQuantifyBaselineEmissions(),
      delete: null,
    },
    quantifyProjectEmissions: {
      title: "プロジェクト排出量",
      handleOpen: () => handleOpenQuantifyProjectEmissionsDialog(),
      handleClose: () => handleCloseQuantifyProjectEmissionsDialog(),
      add: null,
      update: () => updateQuantifyProjectEmissions(),
      delete: null,
    },
    quantifyLeakageEmissions: {
      title: "リーケージ排出量",
      handleOpen: () => handleOpenQuantifyLeakageEmissionsDialog(),
      handleClose: () => handleCloseQuantifyLeakageEmissionsDialog(),
      add: null,
      update: () => updateQuantifyLeakageEmissions(),
      delete: null,
    }
  }

  const FormulaInputForm = ({name, contents, onClick, isOpen}) => {
    return (
      <>
        <Box sx={{ marginBottom: "2rem" }}>
          <Box
            sx={{
              marginTop: "1rem",
              padding: 2,
              border: "1px solid",
              borderColor: "#d3d3d3",
              borderRadius: "6px",
              maxHeight: "500px",
              overflow: "auto",
            }}
            onClick={onClick}
          >
            {transNewlineCode(contents) ?? "未記載"}
          </Box>
        </Box>
        <Dialog
          maxWidth="lg"
          open={isOpen}
          onClose={cluster[name].handleClose}
        >
          <DialogTitle>{cluster[name].title}</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              LaTeX の記述方式で数式が使用できます。　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name={name}
                  control={control}
                  rules={validationRules[name]}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id={name}
                      multiline
                      rows={25}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={cluster[name].handleClose}>戻る</Button>
            <Button
              onClick={cluster[name].update}
              // disabled={true}
            >
              更新
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const makeProjectSerialId = (seqA, branch, category, ) => {
    const partBranch = zeroPadding(4, branch)
    const partCategory = zeroPadding(2, category)
    const partCreateSeq = zeroPadding(6, seqA)
    return "NCCC-" + partBranch + "-" + partCategory + "-" + partCreateSeq
  }

  const addIdForDataGridRows = (data) => {
    let newRows = []
    if (data === []) return newRows
    let id = 0
    data.forEach((v) => {
      id += 1
      v.id = id
      newRows.push(v)
    })
    return newRows
  }

  const onSubmit = async (data) => {
    if (window.confirm("申請します。よろしいですか？")) {
      setProcessing(true)
      try {
        // const planDocs = data.planDocuments;
        // const additionalDocs = data.additionalDocuments;

        // const uploadTasks = [uploadImg(planDocs, ["planDocuments"])];
        // if (additionalDocs) uploadTasks.push(uploadImg(additionalDocs, ["additionalDocuments"]));
        // const [planUrl, additionalUrl] = await Promise.all(uploadTasks);

        const timeline = [new Date(data.startDate), new Date(data.endDate)];
        // const program = await programApi.getProgram(data.programId);

        const reqData = {
          _id: id,
          creator: {
            createdCompanyId: company._id,
            createdUserId: storedUserInfo?.value._id,
            createdCompanyText: getValues("createdCompanyText") || company.groupName,
            createdUserText: getValues("createdUserText") || storedUserInfo?.value.username,
          },
          // additionalDocuments: additionalDocs ? additionalUrl : "",
          applyUserId: storedUserInfo.value._id,
          companyId: storedUserInfo.value.companyId,
          // planDocuments: planUrl,
          programId: data.programId,
          projectTimeLine: timeline,
          place: data.projectPlace || "",
          // program: {
          //   id: data.programId,
          //   historyNo: program.historyNo,
          //   suitabilityReason: data.suitabilityReason || "",
          // },
          //上は前までのやつ 
          projectName: getValues("projectName") || "",
          projectStatus: "10",
          branchId: getValues("branchId") || "",
          projectDesc: data.projectDesc || "",
          projectCategory: projectCategory || "",
          audit: getAuditHistoryRows() || [],
          projectDesign: projectDesignCategory || "",
          actExplain: data.actExplain || "",
          projectPlace: getProjectPlaceRows() || [],
          projectPlaceFile: projectPlaceFileList.length > 0 ? projectPlaceFileList : [],
          proposer: {
            company: company?.groupName,
            staff: storedUserInfo?.value.username,
            role: storedUserInfo?.value.role,
            address: company?.address,
            email: storedUserInfo?.value.email,
            tel: data.proposerTel || "",
          },
          refOtherCompany: data.refOtherCompany || "",
          projectOwnershipFile: projectOwnershipFileList.length > 0 ? projectOwnershipFileList : [],
          projectOwnership: data.projectOwnership || "",
          projectEligibility: data.projectEligibility || "",
          projectEligibilityFile: projectEligibilityFileList.length > 0 ? projectEligibilityFileList : [],
          beforeStartingProject: data.beforeStartingProject || "",
          beforeStartingProjectFile: beforeStartingProjectFileList.length > 0 ? beforeStartingProjectFileList : [],
          legalCompliance: data.legalCompliance || "",
          legalComplianceFile: legalComplianceFileList.length > 0 ? legalComplianceFileList : [],
          noDuplicatePublish: {
            check: duplicatePublishCheck,
            evidence: data.noDuplicatePublish || "",
            file: noDuplicatePublishFileList.length > 0 ? noDuplicatePublishFileList : [],
          },
          noDuplicateClaim: {
            check: duplicateClaimCheck,
            evidence: data.noDuplicateClaim || "",
            file: noDuplicateClaimFileList.length > 0 ? noDuplicateClaimFileList : [],
          },
          stakeholderRegulations: data.stakeholderRegulations || "",
          stakeholderRegulationsFile: stakeholderRegulationsFileList.length > 0 ? stakeholderRegulationsFileList : [],
          envRisk: data.envRisk || "",
          envRiskFile: envRiskFileList.length > 0 ? envRiskFileList : [],
          fairness: data.fairness || "",
          fairnessFile: fairnessFileList.length > 0 ? fairnessFileList : [],
          soundnessA: {
            soundnessACheck: soundnessACheck,
            soundnessA1: data.soundnessA1 || "",
            soundnessA2: data.soundnessA2 || "",
          },
          soundnessB: {
            soundnessBCheck: soundnessBCheck,
            soundnessB1: data.soundnessB1 || "",
            soundnessB2: data.soundnessB2 || "",
          },
          soundnessC: {
            soundnessCCheck: soundnessCCheck,
            soundnessC1: data.soundnessC1 || "",
            soundnessC2: data.soundnessC2 || "",
          },
          soundnessD: {
            soundnessDCheck: soundnessDCheck,
            soundnessD1: data.soundnessD1 || "",
            soundnessD2: data.soundnessD2 || "",
          },
          soundnessFile: soundnessFileList.length > 0 ? soundnessFileList : [],
          sdContribution: data.sdContribution || "",
          projectAddInfo: data.projectAddInfo || "",
          implementationStatus: data.implementationStatus || "",
          deviation: data.deviation || "",
          groupedProject: data.groupedProject || "",
          baselineReassessment: data.baselineReassessment || "",
          // projectStartDate: projectStartDate,
          projectStartDate: getProjectStartDateRows() || [],
          startDateRightfulness: data.startDateRightfulness || "",
          projectCreditTerm: {
            creditTerm: data.creditTerm || "",
            creditStartDate: creditStartDate,
            creditEndDate: creditEndDate,
          },
          estimatedValue: getEstimatedValue1Rows() || [],
          estimatedERR: data.estimatedERR || "",
          totalYears: data.totalYears || "",
          aveYearlyERR: data.aveYearlyERR || "",
          programs: getMethodologyRows() || [],
          programApplicability: data.programApplicability || "",
          projectBoundary: getProjectBoundaryRows() || [],
          // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
          projectBoundaryFile: projectBoundaryFileList.length > 0 ? projectBoundaryFileList : [],
          baselineScenario: data.baselineScenario || "",
          additionality: data.additionality || "",
          deviateProgram: data.deviateProgram || "",
          baselineEmissions: data.baselineEmissions || "",
          projectEmissions: data.projectEmissions || "",
          projectEmissionsFile: projectEmissionsFileList.length > 0 ? projectEmissionsFileList : [],
          leakageEmissions: data.leakageEmissions || "",
          nonPermanentRiskEvaluation: data.nonPermanentRiskEvaluation || "",
          nonPermanentRiskReportCheck: nonPermanentRiskReportCheck,
          projectLTA: data.projectLTA || "",
          updateLta: {
            check: updateLtaCheck,
            NonUpdateLtaReason: data.NonUpdateLtaReason || "",
          },
          expectGhgEmissions: data.expectGhgEmissions || "",
          belowLta: {
            check: ghgCreditCheck,
            NotBelowLtaReason: data.NotBelowLtaReason || "",
          },
          emissions: {
            detail: getEmissionsDetailRows(),
            total: {
              estimatedBaselineEmissionsTotal: estimatedBaselineEmissionsTotal || "",
              estimatedProjectEmissionsTotal: estimatedProjectEmissionsTotal || "",
              estimatedLeakageEmissionsTotal: estimatedLeakageEmissionsTotal || "",
              estimatedReductionCreditTotal: estimatedReductionCreditTotal || "",
              estimatedRemoveCreditTotal: estimatedRemoveCreditTotal || "",
              estimatedTotalCreditTotal: estimatedTotalCreditTotal || "",
            },
          },
          availableData: getAvailableDataRows() || [],
          monitoringData: getMonitoringDataRows() || [],
          monitoringDataSource1File: monitoringDataSource1FileList.length > 0 ? monitoringDataSource1FileList : [],
          monitoringDataSource2File: monitoringDataSource2FileList.length > 0 ? monitoringDataSource2FileList : [],
          monitoringPlan: data.monitoringPlan || "",
          monitoringPlanFile: monitoringPlanFileList.length > 0 ? monitoringPlanFileList : [],
          references: data.references || "",
          secret: getSecretRows() || [],
          otherInfo: data.otherInfo || "",
        }

        let projectSerialNo = {};
        let serialSeqNumber = 0;
        projectSerialNo = await counterApi.upsert({ key: "projectSerialNo" });
        serialSeqNumber = projectSerialNo.seq;
        reqData.serialId = makeProjectSerialId(serialSeqNumber, "0", projectDesignCategory);

        const projectBoundaryFile = getValues("projectBoundaryFile") || ""
        // console.log(projectBoundaryFile)
        if (projectBoundaryFile || projectBoundaryFile.length !== 0) {
          const file = await fileUploadWithConvertPng(projectBoundaryFile, "projectBoundaryFile")
          reqData.projectBoundaryFile.push(file)
        }

        const projectOwnershipFile = getValues("projectOwnershipFile") || ""
        if (projectOwnershipFile || projectOwnershipFile.length !== 0) {
          const file = await fileUploadWithConvertPng(projectOwnershipFile, "projectOwnershipFile")
          reqData.projectOwnershipFile.push(file)
        }

        const projectPlaceFile = getValues("projectPlaceFile") || ""
        if (projectPlaceFile || projectPlaceFile.length !== 0) {
          const file = await fileUploadWithConvertPng(projectPlaceFile, "projectPlaceFile")
          reqData.projectPlaceFile.push(file)
        }

        const projectEligibilityFile = getValues("projectEligibilityFile") || ""
        if (projectEligibilityFile || projectEligibilityFile.length !== 0) {
          const file = await fileUploadWithConvertPng(projectEligibilityFile, "projectEligibilityFile")
          reqData.projectEligibilityFile.push(file)
        }

        const beforeStartingProjectFile = getValues("beforeStartingProjectFile") || ""
        if (beforeStartingProjectFile || beforeStartingProjectFile.length !== 0) {
          const file = await fileUploadWithConvertPng(beforeStartingProjectFile, "beforeStartingProjectFile")
          reqData.beforeStartingProjectFile.push(file)
        }

        const legalComplianceFile = getValues("legalComplianceFile") || ""
        if (legalComplianceFile || legalComplianceFile.length !== 0) {
          const file = await fileUploadWithConvertPng(legalComplianceFile, "legalComplianceFile")
          reqData.legalComplianceFile.push(file)
        }

        const noDuplicatePublishFile = getValues("noDuplicatePublishFile") || ""
        if (noDuplicatePublishFile || noDuplicatePublishFile.length !== 0) {
          const file = await fileUploadWithConvertPng(noDuplicatePublishFile, "noDuplicatePublishFile")
          reqData.noDuplicatePublishFile.push(file)
        }

        const noDuplicateClaimFile = getValues("noDuplicateClaimFile") || ""
        if (noDuplicateClaimFile || noDuplicateClaimFile.length !== 0) {
          const file = await fileUploadWithConvertPng(noDuplicateClaimFile, "noDuplicateClaimFile")
          reqData.noDuplicateClaimFile.push(file)
        }

        const stakeholderRegulationsFile = getValues("stakeholderRegulationsFile") || ""
        if (stakeholderRegulationsFile || stakeholderRegulationsFile.length !== 0) {
          const file = await fileUploadWithConvertPng(stakeholderRegulationsFile, "stakeholderRegulationsFile")
          reqData.stakeholderRegulationsFile.push(file)
        }

        const envRiskFile = getValues("envRiskFile") || ""
        if (envRiskFile || envRiskFile.length !== 0) {
          const file = await fileUploadWithConvertPng(envRiskFile, "envRiskFile")
          reqData.envRiskFile.push(file)
        }

        const fairnessFile = getValues("fairnessFile") || ""
        if (fairnessFile || fairnessFile.length !== 0) {
          const file = await fileUploadWithConvertPng(fairnessFile, "fairnessFile")
          reqData.fairnessFile.push(file)
        }

        const soundnessFile = getValues("soundnessFile") || ""
        if (soundnessFile || soundnessFile.length !== 0) {
          const file = await fileUploadWithConvertPng(soundnessFile, "soundnessFile")
          reqData.soundnessFile.push(file)
        }

        const projectEmissionsFile = getValues("projectEmissionsFile") || ""
        if (projectEmissionsFile || projectEmissionsFile.length !== 0) {
          const file = await fileUploadWithConvertPng(projectEmissionsFile, "projectEmissionsFile")
          reqData.projectEmissionsFile.push(file)
        }

        const monitoringPlanFile = getValues("monitoringPlanFile") || ""
        if (monitoringPlanFile || monitoringPlanFile.length !== 0) {
          const file = await fileUploadWithConvertPng(monitoringPlanFile, "monitoringPlanFile")
          reqData.monitoringPlanFile.push(file)
        }

        if (monitoringDataSource1Files || monitoringDataSource1Files.length !== 0) {
          reqData.monitoringDataSource1File = await multiFileUpload(monitoringDataSource1Files, "monitoringDataSource1File")
        }

        if (monitoringDataSource2Files || monitoringDataSource2Files.length !== 0) {
          reqData.monitoringDataSource2File = await multiFileUpload(monitoringDataSource2Files, "monitoringDataSource2File")
        }

        projectApi
          .update(reqData)
          .then(async (res) => {
            const projectId = res.project._id;
            // 一旦メールはコメントアウト
            // return sendApplyMail(
            //   data,
            //   reqData.planDocuments,
            //   reqData.additionalDocuments,
            //   projectId
            // );
          })
          .then(() => {
            alert("申請しました");
            setProcessing(false)
            navigate("/member/projects");
          })
          .catch((e) => {
            alert("申請に失敗しました。運営にお問合せください。");
            setProcessing(false)
            console.error(e);
          });
      } catch (error) {
        alert("申請に失敗しました。運営にお問合せください。");
        setProcessing(false)
        console.error(error);
      }
    }
  };

  ///////////////////////
  // file type指定テスト //
  ///////////////////////

  // const ref = useRef(null);
  // const handleChange = (e) => {
  //   if (!e.target.files) return;
  //   const files = Array.from(e.target.files);
  //   setFile(files);
  // };
  // const getFileNames = () =>
  //   file?.reduce(
  //     (fileNames, file) =>
  //       `${fileNames} ${fileNames !== "" ? "," : ""} ${file.name}`,
  //     ""
  //   ) || "";

  ///////////////////////

  // 共通利用の変数や関数等
  // 単ファイルアップロード
  const fileUpload = async (file, lastPath) => {
    const uploadTasks = [uploadFile(file, ["project", tmpSaveProjectData.projectNo, lastPath])];
    const [url] = await Promise.all(uploadTasks);
    return url
  }

  // 複ファイルアップロード
  const multiFileUpload = async (files, lastPath) => {
    let uploadTasks = []
    files.forEach((file) => {
      uploadTasks.push(uploadFile(file, ["project", tmpSaveProjectData.projectNo, lastPath]));
    })
    const urls = await Promise.all(uploadTasks);
    return urls
  }

  // 単ファイルアップロード & png変換
  const fileUploadWithConvertPng = async (file, lastPath) => {
    const uploadTasks = [uploadPdfWithConvertImg(file, ["project", tmpSaveProjectData.projectNo, lastPath])];
    const [url] = await Promise.all(uploadTasks)
    return url
  }

  // ファイル添付用の関数コンポーネント
  const FileUploadInput = ({ name }) => {
    return (
      <Box sx={{ marginBottom: "2rem" }}>
        <Controller
          name={name}
          control={control}
          rules={validationRules[name]}
          render={({ field, fieldState }) => (
            <MuiFileInput
              type="file"
              fullWidth
              {...field}
              error={fieldState.invalid}
              // helperText={
              //   fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
              // }
            />
          )}
        />
      </Box>
    )
  }

  const [projectPlaceFileList, setProjectPlaceFileList] = useState([])
  const [projectOwnershipFileList, setProjectOwnershipFileList] = useState([])
  const [projectBoundaryFileList, setProjectBoundaryFileList] = useState([])
  const [stakeholderRegulationsFileList, setStakeholderRegulationsFileList] = useState([])
  const [envRiskFileList, setEnvRiskFileList] = useState([])
  const [fairnessFileList, setFairnessFileList] = useState([])
  const [soundnessFileList, setSoundnessFileList] = useState([])
  const [projectEmissionsFileList, setProjectEmissionsFileList] = useState([])
  const [monitoringPlanFileList, setMonitoringPlanFileList] = useState([])
  const [projectEligibilityFileList, setProjectEligibilityFileList] = useState([])
  const [beforeStartingProjectFileList, setBeforeStartingProjectFileList] = useState([])
  const [legalComplianceFileList, setLegalComplianceFileList] = useState([])
  const [monitoringDataSource1FileList, setMonitoringDataSource1FileList] = useState([])
  const [monitoringDataSource2FileList, setMonitoringDataSource2FileList] = useState([])
  const [noDuplicatePublishFileList, setNoDuplicatePublishFileList] = useState([])
  const [noDuplicateClaimFileList, setNoDuplicateClaimFileList] = useState([])
  
  
  // 添付済みファイルの表示用コンポーネント
  const AttachmentFiles = ({ fileList, setList }) => {
    const handleDeleteFile = (index) => {
      const cloneArray = lodash.cloneDeep(fileList)
      cloneArray.splice(index, 1)
      setList(cloneArray)
    }
    return (
      <Box m={2}>
        <Stack direction="row" spacing={1}>
          {fileList.map((file, index) => {
            return (
              <Chip
                key={index}
                icon={<AttachFileIcon />}
                label={file.name}
                // onClick={handleClick}
                onDelete={() => handleDeleteFile(index)}
              />
            )
          })}
        </Stack>
      </Box>
    )
  }

  /**
   * react hook form の公式文書の内容に従って記載しても
   * 一部フォームのみのresetが実現できなそうなためコメントアウトしsetValueで対応する
   * https://react-hook-form.com/docs/useform/reset
   */
  // const [formResetTarget, setFormResetTarget] = useState({})
  // useEffect(() => {
  //   console.log(formResetTarget)
  //   if (formResetTarget) {
  //     reset({ values: formResetTarget },
  //       // { keepValues: true }
  //     )
  //   }
  //   setProcessing(false)
  // }, [formResetTarget])

  // 2.2 セクターの範囲とプロジェクトの種類に関する変数等
  const [projectCategory, setProjectCategory] = useState("");
  const handleCategoryChange = (event) => {
    setProjectCategory(event.target.value);
    // dispatch(task_setCategory(event.target.value));
  };

  // 2.3 監査履歴に関する変数等
  const [auditHistoryRows, setAuditHistoryRows] = React.useState([]);
  const apiRefForAuditHistory = useGridApiRef();
  const auditHistoryColumns = [
    { field: "auditType", headerName: "監査タイプ" },//バリデーション or 検証
    { field: "auditHistoryTerm", headerName: "期間" },
    { field: "auditHistoryProgram", headerName: "プログラム" },
    { field: "auditHistoryName", headerName: "バリデーション名／検証機関名" },
    { field: "auditHistoryYears", headerName: "年数" },
  ];
  const addAuditHistoryRows = () => {
    const id = (auditHistoryRows.length === 0) ? 1 : Math.max(...auditHistoryRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const auditTypeValue = getValues("auditType")
    const auditHistoryTermValue = getValues("auditHistoryTerm")
    const auditHistoryProgramValue = getValues("auditHistoryProgram")
    const auditHistoryNameValue = getValues("auditHistoryName")
    const auditHistoryYearsValue = getValues("auditHistoryYears")
    const newValue = {
      id: id,
      auditType: auditTypeValue,
      auditHistoryTerm: auditHistoryTermValue,
      auditHistoryProgram: auditHistoryProgramValue,
      auditHistoryName: auditHistoryNameValue,
      auditHistoryYears: auditHistoryYearsValue,
    };
    setAuditHistoryRows([...auditHistoryRows, newValue]);
    // reset({
    //   auditType: "",
    //   auditHistoryTerm: "",
    //   auditHistoryProgram: "",
    //   auditHistoryName: "",
    //   auditHistoryYears: "",
    // })
    // setFormResetTarget({
    //   auditType: "",
    //   auditHistoryTerm: "",
    //   auditHistoryProgram: "",
    //   auditHistoryName: "",
    //   auditHistoryYears: "",
    // })
    setValue("auditType", "")
    setValue("auditHistoryTerm", "")
    setValue("auditHistoryProgram", "")
    setValue("auditHistoryName", "")
    setValue("auditHistoryYears", "")
  }

  const delAuditHistoryRows = () => {
    const delAuditHistoryRow = apiRefForAuditHistory.current.getSelectedRows();
    if (auditHistoryRows.length === 0 || delAuditHistoryRow.size === 0) return;
    delAuditHistoryRow.forEach(v => {
      apiRefForAuditHistory.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newAuditHistoryRows = apiRefForAuditHistory.current.getSortedRows();
    if (newAuditHistoryRows !== null) {
      setAuditHistoryRows(Object.keys(newAuditHistoryRows).length === 0 ? [] : newAuditHistoryRows);
    }
  }

  const getAuditHistoryRows = () => {
    if (!apiRefForAuditHistory.current || Object.keys(apiRefForAuditHistory.current).length === 0) {
      return auditHistoryRows || null
    }
    const allAuditHistoryRows = apiRefForAuditHistory.current?.getSortedRows();
    return allAuditHistoryRows
  }

  // 3.1 プロジェクト・デザインに関する変数等
  const [projectDesignCategory, setProjectDesignCategory] = useState("");
  const handleProjectDesignCategoryChange = (event) => {
    setProjectDesignCategory(event.target.value);
  };

  // 3.3 プロジェクト所在地に関する変数等
  const [isOpenProjectPlaceDialog, setIsOpenProjectPlaceDialog] = React.useState(false);
  const [projectPlaceRows, setProjectPlaceRows] = React.useState([]);
  const handleOpenProjectPlaceDialog = () => {
    setIsOpenProjectPlaceDialog(true);
  };
  const handleOpenProjectPlaceDialogForEdit = (params) => {
    setIsOpenProjectPlaceDialog(true);
    setCurrentId(params.row.id)
    setValue("businessLocation", params.row.businessLocation)
    setValue("district", params.row.district)
    setValue("geoLocation", params.row.geoLocation)
    setValue("cadastral", params.row.cadastral)
    setValue("affectedArea", params.row.affectedArea)
    setValue("treePlantArea", params.row.treePlantArea)
  };
  const handleCloseProjectPlaceDialog = () => {
    setIsOpenProjectPlaceDialog(false);
    setCurrentId(0)
  };

  const apiRefForProjectPlace = useGridApiRef();

  const projectPlaceColumns = [
    { field: "businessLocation", headerName: "事業地" },
    { field: "district", headerName: "地区" },
    { field: "geoLocation", headerName: "地理的位置\n(GPS 緯度, 軽度)" },
    { field: "cadastral", headerName: "地籍" },
    { field: "affectedArea", headerName: "影響を受けた面積(ha)" },
    { field: "treePlantArea", headerName: "植生面積(ha)" },
    // TODO: 詳細や編集ボタンをつける
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<EditIcon />}
          onClick={() => handleOpenProjectPlaceDialogForEdit(params)}
        />,
      ]
    },
  ];
  const addProjectPlaceRows = () => {
    const id = (projectPlaceRows.length === 0) ? 1 : Math.max(...projectPlaceRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const businessLocationValue = getValues("businessLocation")
    const districtValue = getValues("district")
    const geoLocationValue = getValues("geoLocation")
    const cadastralValue = getValues("cadastral")
    const affectedAreaValue = getValues("affectedArea")
    const treePlantAreaValue = getValues("treePlantArea")

    const newValue = {
      id: id,
      businessLocation: businessLocationValue,
      district: districtValue,
      geoLocation: geoLocationValue,
      cadastral: cadastralValue,
      affectedArea: affectedAreaValue,
      treePlantArea: treePlantAreaValue,
    };
    setProjectPlaceRows([...projectPlaceRows, newValue]);
    setValue("businessLocation", "")
    setValue("district", "")
    setValue("geoLocation", "")
    setValue("cadastral", "")
    setValue("affectedArea", "")
    setValue("treePlantArea", "")
    handleCloseProjectPlaceDialog()
  }

  const changeProjectPlaceRows = (row) => {
    let newRows = lodash.cloneDeep(projectPlaceRows)
    let idx = 0
    for (let i = 0; i < newRows.length; i++) {
      if (currentId === newRows[i]["id"]) {
        idx = i
        break
      }
    }
    newRows[idx]["businessLocation"] = getValues("businessLocation")
    newRows[idx]["district"] = getValues("district")
    newRows[idx]["geoLocation"] = getValues("geoLocation")
    newRows[idx]["cadastral"] = getValues("cadastral")
    newRows[idx]["affectedArea"] = getValues("affectedArea")
    newRows[idx]["treePlantArea"] = getValues("treePlantArea")

    setProjectPlaceRows(newRows);
    setValue("businessLocation", "")
    setValue("district", "")
    setValue("geoLocation", "")
    setValue("cadastral", "")
    setValue("affectedArea", "")
    setValue("treePlantArea", "")
    setCurrentId(0)
    handleCloseProjectPlaceDialog()
  }

  const delProjectPlaceRows = () => {
    const delProjectPlaceRow = apiRefForProjectPlace.current.getSelectedRows();
    if (projectPlaceRows.length === 0 || delProjectPlaceRow.size === 0) return;
    delProjectPlaceRow.forEach(v => {
      apiRefForProjectPlace.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectPlaceRows = apiRefForProjectPlace.current.getSortedRows();
    if (newProjectPlaceRows !== null) {
      setProjectPlaceRows(Object.keys(newProjectPlaceRows).length === 0 ? [] : newProjectPlaceRows);
    }
  }

  const getProjectPlaceRows = () => {
    if (!apiRefForProjectPlace.current || Object.keys(apiRefForProjectPlace.current).length === 0) {
      return projectPlaceRows || null
    }
    const allProjectPlaceRows = apiRefForProjectPlace.current?.getSortedRows();
    return allProjectPlaceRows
  }

  // 4.4 二重請求と他のGHGプログラムへの参加に関する変数等
  const [duplicatePublishCheck, setDuplicatePublishCheck] = useState(false);
  const handleChangeDuplicatePublishCheck = (event) => {
    if (event.target.value === "1") {
      setDuplicatePublishCheck(true)
    } else {
      setDuplicatePublishCheck(false)
    }
  }

  // 4.5 二重請求、他の形態のクレジット、スコープ3排出量に関する変数等
  const [duplicateClaimCheck, setDuplicateClaimCheck] = useState(false);
  const handleChangeDuplicateClaimCheck = (event) => {
    if (event.target.value === "1") {
      setDuplicateClaimCheck(true)
    } else {
      setDuplicateClaimCheck(false)
    }
  }

  // 5.4 生態系の健全性に関する変数等
  const [soundnessACheck, setSoundnessACheck] = useState(false);
  const [soundnessBCheck, setSoundnessBCheck] = useState(false);
  const [soundnessCCheck, setSoundnessCCheck] = useState(false);
  const [soundnessDCheck, setSoundnessDCheck] = useState(false);
  const handleChangeSoundnessACheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessACheck(true)
    } else {
      setSoundnessACheck(false)
    }
  }
  const handleChangeSoundnessBCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessBCheck(true)
    } else {
      setSoundnessBCheck(false)
    }
  }
  const handleChangeSoundnessCCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessCCheck(true)
    } else {
      setSoundnessCCheck(false)
    }
  }
  const handleChangeSoundnessDCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessDCheck(true)
    } else {
      setSoundnessDCheck(false)
    }
  }

  // 3.2 プロジェクトの活動と説明
  const [isOpenActExplainDialog, setIsOpenActExplainDialog] = useState(false);
  const handleOpenActExplainDialog = () => {
    setIsOpenActExplainDialog(true);
  };
  const handleCloseActExplainDialog = () => {
    setIsOpenActExplainDialog(false);
  };

  // 5.1 ステークホルダーの参画と協議
  const [isOpenStakeholdersDescriptionDialog, setIsOpenStakeholdersDescriptionDialog] = useState(false);
  const [stakeholdersDescriptionRows, setStakeholdersDescriptionRows] = useState([]);
  const [stakeholdersDescriptionCurrentId, setStakeholdersDescriptionCurrentId] = useState(0);
  const handleOpenIsStakeholdersDescriptionDialog = () => {
    setIsOpenStakeholdersDescriptionDialog(true);
  };
  const handleCloseIsStakeholdersDescriptionDialog = () => {
    setIsOpenStakeholdersDescriptionDialog(false);
    setValue("stakeholderDescriptionItem", "")
    setValue("stakeholderDescriptionContents", "")
  };
  const handleOpenStakeholdersDescriptionForEdit = (params) => {
    handleOpenIsStakeholdersDescriptionDialog()
    setStakeholdersDescriptionCurrentId(params.row.id)
    setValue("stakeholderDescriptionItem", params.row.item)
    setValue("stakeholderDescriptionContents", params.row.contents)
  };
  const apiRefForStakeholdersDescription = useGridApiRef();

  const stakeholdersDescriptionColumns = [
    { field: "item", headerName: "項目名" },
    { field: "contents", headerName: "内容" },
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<EditIcon />}
          onClick={() => handleOpenStakeholdersDescriptionForEdit(params)}
        />,
      ]
    },
  ]

  const addStakeholdersDescriptionRows = async () => {
    const id = (stakeholdersDescriptionRows.length === 0) ? 1 : Math.max(...stakeholdersDescriptionRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const stakeholderDescriptionItemValue = getValues("stakeholderDescriptionItem")
    const stakeholderDescriptionContentsValue = getValues("stakeholderDescriptionContents")
    
    const newValue = {
      id: id,
      item: stakeholderDescriptionItemValue,
      contents: stakeholderDescriptionContentsValue,
    };
    setStakeholdersDescriptionRows([...stakeholdersDescriptionRows, newValue]);
    handleCloseIsStakeholdersDescriptionDialog()
  }

  const changeStakeholdersDescriptionRows = async (row) => {
    let newRows = lodash.cloneDeep(stakeholdersDescriptionRows)
    let idx = 0
    for (let i = 0; i < newRows.length; i++) {
      if (currentId === newRows[i]["id"]) {
        idx = i
        break
      }
    }
    newRows[idx]["item"] = getValues("stakeholderDescriptionItem")
    newRows[idx]["contents"] = getValues("stakeholderDescriptionContents")

    setStakeholdersDescriptionRows(newRows);
    handleCloseIsStakeholdersDescriptionDialog()
    setStakeholdersDescriptionCurrentId(0)
  }

  const delStakeholdersDescriptionRows = () => {
    const delStakeholdersDescriptionRow = apiRefForStakeholdersDescription.current.getSelectedRows();
    if (stakeholdersDescriptionRows.length === 0 || delStakeholdersDescriptionRow.size === 0) return;
    delStakeholdersDescriptionRow.forEach(v => {
      apiRefForStakeholdersDescription.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newStakeholdersDescriptionRows = apiRefForStakeholdersDescription.current.getSortedRows();
    if (newStakeholdersDescriptionRows !== null) {
      setStakeholdersDescriptionRows(Object.keys(newStakeholdersDescriptionRows).length === 0 ? [] : newStakeholdersDescriptionRows);
    }
  }

  const getStakeholdersDescriptionRows = () => {
    if (!apiRefForStakeholdersDescription.current || Object.keys(apiRefForStakeholdersDescription.current).length === 0) {
      return stakeholdersDescriptionRows || null
    }
    const allStakeholdersDescriptionRows = apiRefForStakeholdersDescription.current?.getSortedRows();
    return allStakeholdersDescriptionRows
  }

  // 6.1 プロジェクト開始日に関する変数等
  const [isOpenProjectStartDateDialog, setIsOpenProjectStartDateDialog] = useState(false);
  const [projectStartDate, setProjectStartDate] = useState();
  const [projectStartDateRows, setProjectStartDateRows] = React.useState([]);
  const handleChangeProjectStartDate = (newValue) => {
    setProjectStartDate(newValue);
  };
  const handleOpenIsProjectStartDateDialog = () => {
    setIsOpenProjectStartDateDialog(true);
  };
  const handleCloseIsProjectStartDateDialog = () => {
    setIsOpenProjectStartDateDialog(false);
  };
  const apiRefForProjectStartDate = useGridApiRef();

  const projectStartDateColumns = [
    { field: "label", headerName: "ラベル" },
    {
      field: "date",
      headerName: "プロジェクト開始日",
      renderCell: (params) => {
        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: "20px",
            }}
          >
            {params.value}
          </Grid>
        );
      },
    },
    // TODO: 詳細や編集ボタンをつける
  ];
  const addProjectStartDateRows = () => {
    const id = (projectStartDateRows.length === 0) ? 1 : Math.max(...projectStartDateRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const projectLabelValue = getValues("projectLabel")
    const projectStartDateValue = dayjs(projectStartDate).format("YYYY/MM/DD")

    const newValue = {
      id: id,
      label: projectLabelValue,
      date: projectStartDateValue,
      rowDate: projectStartDate,
    };
    setProjectStartDateRows([...projectStartDateRows, newValue]);
    setValue("projectLabel", "")
    setValue("projectStartDate", null)
    handleCloseIsProjectStartDateDialog()
  }

  const delProjectStartDateRows = () => {
    const delProjectStartDateRow = apiRefForProjectStartDate.current.getSelectedRows();
    if (projectStartDateRows.length === 0 || delProjectStartDateRow.size === 0) return;
    delProjectStartDateRow.forEach(v => {
      apiRefForProjectStartDate.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectStartDateRows = apiRefForProjectStartDate.current.getSortedRows();
    if (newProjectStartDateRows !== null) {
      setProjectStartDateRows(Object.keys(newProjectStartDateRows).length === 0 ? [] : newProjectStartDateRows);
    }
  }

  const getProjectStartDateRows = () => {
    if (!apiRefForProjectStartDate.current || Object.keys(apiRefForProjectStartDate.current).length === 0) {
      return projectStartDateRows || null
    }
    const allProjectStartDateRows = apiRefForProjectStartDate.current?.getSortedRows();
    return allProjectStartDateRows
  }

  // 6.2 プロジェクトのクレジット期間に関する変数等
  const [creditStartDate, setCreditStartDate] = useState();
  const [creditEndDate, setCreditEndDate] = useState();
  const handleChangeCreditStartDate = (newValue) => {
    setCreditStartDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeCreditEndDate = (newValue) => {
    setCreditEndDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };

  // 6.3 プロジェクト規模とGHG排出削減量または除去量の推定値に関する変数等
  const [estimatedValue1Rows, setEstimatedValue1Rows] = React.useState([]);
  const [creditTimeStart, setCreditTimeStart] = useState();
  const [creditTimeEnd, setCreditTimeEnd] = useState();
  const [estimatedValue1CurrentId, setEstimatedValue1CurrentId] = useState(0)
  const handleChangeCreditTimeStart = (newValue) => {
    setCreditTimeStart(newValue);
  };
  const handleChangeCreditTimeEnd = (newValue) => {
    setCreditTimeEnd(newValue);
  };
  const handleOpenEstimatedValue1DialogForEdit = (params) => {
    setEstimatedValue1CurrentId(params.row.id)
    setValue("projectLabelSelect", params.row.label)
    setCreditTimeStart(params.row.creditTimeStart)
    setCreditTimeEnd(params.row.creditTimeEnd)
    setValue("estimatedValue1", params.row.estimatedValue1)
  };
  const apiRefForEstimatedValue1 = useGridApiRef();
  const estimatedValue1Columns = [
    { field: "label", headerName: "ラベル" },
    { field: "creditTime", headerName: "プロジェクト期間の暦年", width: "50rem" },
    { field: "estimatedValue1", headerName: "GHG排出削減量または除去量の推定値（tCO2e）", width: "30rem" },
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<EditIcon />}
          onClick={() => handleOpenEstimatedValue1DialogForEdit(params)}
        />,
      ]
    },
  ];
  const addEstimatedValue1Rows = () => {
    const id = (estimatedValue1Rows.length === 0) ? 1 : Math.max(...estimatedValue1Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const projectLabel = getValues("projectLabelSelect")
    const estimatedValue1 = getValues("estimatedValue1")
    const creditTime = `${dayjs(creditTimeStart).format("YYYY/MM/DD")}〜${dayjs(creditTimeEnd).format("YYYY/MM/DD")}`

    const newValue = {
      id: id,
      label: projectLabel,
      creditTime: creditTime,
      estimatedValue1: estimatedValue1,
      creditTimeStart: creditTimeStart,
      creditTimeEnd: creditTimeEnd,
    };
    setEstimatedValue1Rows([...estimatedValue1Rows, newValue]);
    setValue("projectLabelSelect", "")
    setValue("estimatedValue1", "")
    setCreditTimeStart(null)
    setCreditTimeEnd(null)
  }

  const changeEstimatedValue1Rows = (row) => {
    let newRows = lodash.cloneDeep(estimatedValue1Rows)
    let idx = 0
    for (let i = 0; i < newRows.length; i++) {
      if (currentId === newRows[i]["id"]) {
        idx = i
        break
      }
    }
    const creditTime = `${dayjs(creditTimeStart).format("YYYY/MM/DD")}〜${dayjs(creditTimeEnd).format("YYYY/MM/DD")}`
    newRows[idx]["label"] = getValues("projectLabelSelect")
    newRows[idx]["creditTime"] = creditTime
    newRows[idx]["estimatedValue1"] = getValues("estimatedValue1")
    newRows[idx]["creditTimeStart"] = creditTimeStart
    newRows[idx]["creditTimeEnd"] = creditTimeEnd

    setEstimatedValue1Rows(newRows);
    setValue("projectLabelSelect", "")
    setValue("estimatedValue1", "")
    setCreditTimeStart(null)
    setCreditTimeEnd(null)
    setEstimatedValue1CurrentId(0)
  }

  const delEstimatedValue1Rows = () => {
    const delEstimatedValue1Row = apiRefForEstimatedValue1.current.getSelectedRows();
    if (estimatedValue1Rows.length === 0 || delEstimatedValue1Row.size === 0) return;
    delEstimatedValue1Row.forEach(v => {
      apiRefForEstimatedValue1.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newEstimatedValue1Rows = apiRefForEstimatedValue1.current.getSortedRows();
    if (newEstimatedValue1Rows !== null) {
      setEstimatedValue1Rows(Object.keys(newEstimatedValue1Rows).length === 0 ? [] : newEstimatedValue1Rows);
    }
  }

  const getEstimatedValue1Rows = () => {
    if (!apiRefForEstimatedValue1.current || Object.keys(apiRefForEstimatedValue1.current).length === 0) {
      return estimatedValue1Rows || null
    }
    const allEstimatedValue1Rows = apiRefForEstimatedValue1.current?.getSortedRows();
    if (!allEstimatedValue1Rows) {
      return estimatedValue1Rows || null
    }
    let estimatedValues = []
    allEstimatedValue1Rows.forEach((row) => {
      let value = {
        label: "",
        creditTimeStart: "",
        creditTimeEnd: "",
        estimatedValue1: "",
      }
      value.label = row.label
      value.creditTimeStart = row.creditTimeStart
      value.creditTimeEnd = row.creditTimeEnd
      value.estimatedValue1 = row.estimatedValue1
      estimatedValues.push(value)
    })
    return estimatedValues
  }

  const recoverEstimatedValue1Rows = (data) => {
    let newRows = []
    if (data === []) return newRows
    let id = 0
    data.forEach((item) => {
      id += 1
      const projectLabel = item.label
      const estimatedValue1 = item.estimatedValue1
      const creditTime = `${dayjs(item.creditTimeStart).format("YYYY/MM/DD")}〜${dayjs(item.creditTimeEnd).format("YYYY/MM/DD")}`
      const creditTimeStart = dayjs(item.creditTimeStart)
      const creditTimeEnd = dayjs(item.creditTimeEnd)

      const newValue = {
        id: id,
        label: projectLabel,
        creditTime: creditTime,
        estimatedValue1: estimatedValue1,
        creditTimeStart: creditTimeStart,
        creditTimeEnd: creditTimeEnd,
      };
      newRows.push(newValue)
    })
    return newRows
  }

  // 7.1 方法論のタイトルと参考文献に関する変数等
  const [methodologyCheck, setMethodologyCheck] = useState(false);
  const [methodologyRows, setMethodologyRows] = React.useState([]);
  const [methodologyTypeCategory, setMethodologyTypeCategory] = useState("");
  const apiRefForMethodology = useGridApiRef();
  const handleChangeMethodologyCheck = () => {
    setMethodologyCheck(!methodologyCheck)
  }

  const handleMethodologyTypeChange = (event) => {
    setMethodologyTypeCategory(event.target.value);
  };

  const methodologyColumns = [
    { field: "methodologyTypeText", headerName: "タイプ" },
    { field: "methodologyRefId", headerName: "参照ID" },
    { field: "methodologyTitle", headerName: "タイトル" },
    { field: "methodologyVersion", headerName: "Version" },
  ];
  const addMethodologyRows = async () => {
    const methodologyTypeCategoryList = await categoryApi.get({
      params: {
        name: "methodologyType"
      }
    })
    const id = (methodologyRows.length === 0) ? 1 : Math.max(...methodologyRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const methodologyTypeValue = methodologyTypeCategoryList?.list[Number(methodologyTypeCategory)-1]?.text
    const methodologyRefIdValue = getValues("methodologyRefId")
    const methodologyTitleValue = getValues("methodologyTitle")
    const methodologyVersionValue = getValues("methodologyVersion")
    
    const newValue = {
      id: id,
      ncccProgramId: null,
      methodologyTypeId: methodologyTypeCategory,
      methodologyTypeText: methodologyTypeValue,
      methodologyRefId: methodologyRefIdValue,
      methodologyTitle: methodologyTitleValue,
      methodologyVersion: methodologyVersionValue,
    };
    setMethodologyRows([...methodologyRows, newValue]);
    // reset({
    //   // methodologyType: "",
    //   methodologyRefId: "",
    //   methodologyTitle: "",
    //   methodologyVersion: "",
    // })
    // setFormResetTarget({
    //   // methodologyType: "",
    //   methodologyRefId: "",
    //   methodologyTitle: "",
    //   methodologyVersion: "",
    // })
    setValue("methodologyRefId", "")
    setValue("methodologyTitle", "")
    setValue("methodologyVersion", "")
    setMethodologyTypeCategory("")
  }

  const addNcccMethodologyRows = async () => {
    const programValue = getValues("program")
    if (!programValue) return
    const methodologyProgram = await programApi.getProgram(programValue)
    const id = (methodologyRows.length === 0) ? 1 : Math.max(...methodologyRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const methodologyRefIdValue = methodologyProgram.programNo
    const methodologyTitleValue = methodologyProgram.programName
    const methodologyVersionValue = methodologyProgram.version
    const newValue = {
      id: id,
      ncccProgramId: methodologyProgram._id,
      methodologyTypeId: "0",
      methodologyTypeText: "NCCC方法論",
      methodologyRefId: methodologyRefIdValue,
      methodologyTitle: methodologyTitleValue,
      methodologyVersion: methodologyVersionValue,
    };
    setMethodologyRows([...methodologyRows, newValue]);
  }

  const delMethodologyRows = () => {
    const delMethodologyRow = apiRefForMethodology.current.getSelectedRows();
    if (methodologyRows.length === 0 || delMethodologyRow.size === 0) return;
    delMethodologyRow.forEach(v => {
      apiRefForMethodology.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMethodologyRows = apiRefForMethodology.current.getSortedRows();
    if (newMethodologyRows !== null) {
      setMethodologyRows(Object.keys(newMethodologyRows).length === 0 ? [] : newMethodologyRows);
    }
  }

  const getMethodologyRows = () => {
    if (!apiRefForMethodology.current || Object.keys(apiRefForMethodology.current).length === 0) {
      return methodologyRows || null
    }
    const allMethodologyRows = apiRefForMethodology.current?.getSortedRows();
    return allMethodologyRows
  }

  // 7.3 プロジェクト境界に関する変数等
  // const [gasIncludeCheck, setGasIncludeCheck] = useState(false);
  const [projectBoundaryRows, setProjectBoundaryRows] = React.useState([]);
  const [projectBoundaryCurrentId, setProjectBoundaryCurrentId] = React.useState(0);
  const [gasCategory, setGasCategory] = useState("");
  const apiRefForProjectBoundary = useGridApiRef();
  // const handleChangeGasIncludeCheck = () => {
  //   setGasIncludeCheck(!gasIncludeCheck)
  // }

  const handleGasCategoryChange = (event) => {
    setGasCategory(event.target.value);
  };
  const handleOpenProjectBoundaryDialogForEdit = (params) => {
    setProjectBoundaryCurrentId(params.row.id)
    setValue("baselineOrSource", params.row.baselineOrSource)
    // categoryDropdownコンポーネントにイベントを渡す必要がある
    // setValue("gasType", params.row.gasType)
    setValue("gasInclude", params.row.gasInclude)
    setValue("boundaryExplain", params.row.boundaryExplain)
  };

  const projectBoundaryColumns = [
    { field: "baselineOrSource", headerName: "ベースライン／ソース" },
    { field: "gasType", headerName: "Gas" },
    { field: "gasInclude", headerName: "含まれる？" },
    { field: "boundaryExplain", headerName: "正当性／説明" },
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<EditIcon />}
          onClick={() => handleOpenProjectBoundaryDialogForEdit(params)}
        />,
      ]
    },
  ];
  const addProjectBoundaryRows = async () => {
    const gasCategoryList = await categoryApi.get({
      params: {
        name: "gas"
      }
    })
    const id = (projectBoundaryRows.length === 0) ? 1 : Math.max(...projectBoundaryRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const gasTypeValue = gasCategoryList?.list[Number(gasCategory)-1]?.text
    const baselineOrSourceValue = getValues("baselineOrSource")
    const gasIncludeValue = getValues("gasInclude")
    const boundaryExplainValue = getValues("boundaryExplain")
    
    const newValue = {
      id: id,
      gasType: gasTypeValue,
      baselineOrSource: baselineOrSourceValue,
      gasInclude: gasIncludeValue,
      boundaryExplain: boundaryExplainValue,
    };
    setProjectBoundaryRows([...projectBoundaryRows, newValue]);
    // reset({
    //   // methodologyType: "",
    //   baselineOrSource: "",
    //   gasInclude: "",
    //   boundaryExplain: "",
    // })
    setValue("baselineOrSource", "")
    setValue("gasInclude", "")
    setValue("boundaryExplain", "")
    // categoryDropdownコンポーネントにイベントを渡す必要がある
    // reduxがちゃんと使えていれば楽だが…
    // setGasCategory(null)
  }

  const changeProjectBoundaryRows = async (row) => {
    let newRows = lodash.cloneDeep(projectBoundaryRows)
    let idx = 0
    for (let i = 0; i < newRows.length; i++) {
      if (currentId === newRows[i]["id"]) {
        idx = i
        break
      }
    }
    const gasCategoryList = await categoryApi.get({
      params: {
        name: "gas"
      }
    })
    newRows[idx]["baselineOrSource"] = getValues("baselineOrSource")
    newRows[idx]["gasType"] = gasCategoryList?.list[Number(gasCategory)-1]?.text
    newRows[idx]["gasInclude"] = getValues("gasInclude")
    newRows[idx]["boundaryExplain"] = getValues("boundaryExplain")

    setProjectBoundaryRows(newRows);
    setValue("baselineOrSource", "")
    setValue("gasInclude", "")
    setValue("boundaryExplain", "")
    setProjectBoundaryCurrentId(0)
  }

  const delProjectBoundaryRows = () => {
    const delProjectBoundaryRow = apiRefForProjectBoundary.current.getSelectedRows();
    if (projectBoundaryRows.length === 0 || delProjectBoundaryRow.size === 0) return;
    delProjectBoundaryRow.forEach(v => {
      apiRefForProjectBoundary.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectBoundaryRows = apiRefForProjectBoundary.current.getSortedRows();
    if (newProjectBoundaryRows !== null) {
      setProjectBoundaryRows(Object.keys(newProjectBoundaryRows).length === 0 ? [] : newProjectBoundaryRows);
    }
  }

  const getProjectBoundaryRows = () => {
    if (!apiRefForProjectBoundary.current || Object.keys(apiRefForProjectBoundary.current).length === 0) {
      return projectBoundaryRows || null
    }
    const allProjectBoundaryRows = apiRefForProjectBoundary.current?.getSortedRows();
    return allProjectBoundaryRows
  }

  // 8.1 ベースライン排出量に関する変数等
  const [baselineEmissions, setBaselineEmissions] = useState("");
  const [isOpenBaselineEmissionsDialog, setIsOpenBaselineEmissionsDialog] = React.useState(false);
  const handleOpenBaselineEmissionsDialog = () => {
    setIsOpenBaselineEmissionsDialog(true);
  };
  const handleCloseBaselineEmissionsDialog = () => {
    setIsOpenBaselineEmissionsDialog(false);
    // ポップアップを閉じたときに自動で保存するのであれば以下コメントアウトを解除
    // setBaselineEmissions(text)
  };
  const updateBaselineEmissions = () => {
    const text = getValues("baselineEmissions")
    setBaselineEmissions(text)
    handleCloseBaselineEmissionsDialog()
  }

  // 8.2 プロジェクト排出量に関する変数等
  const [projectEmissions, setProjectEmissions] = useState("");
  const [isOpenProjectEmissionsDialog, setIsOpenProjectEmissionsDialog] = React.useState(false);
  const handleOpenProjectEmissionsDialog = () => {
    setIsOpenProjectEmissionsDialog(true);
  };
  const handleCloseProjectEmissionsDialog = () => {
    setIsOpenProjectEmissionsDialog(false);
    // ポップアップを閉じたときに自動で保存するのであれば以下コメントアウトを解除
    // setProjectEmissions(text)
  };
  const updateProjectEmissions = () => {
    const text = getValues("projectEmissions")
    setProjectEmissions(text)
    handleCloseProjectEmissionsDialog()
  }

  // 8.3 リーケージ排出量に関する変数等
  const [leakageEmissions, setLeakageEmissions] = useState("");
  const [isOpenLeakageEmissionsDialog, setIsOpenLeakageEmissionsDialog] = React.useState(false);
  const handleOpenLeakageEmissionsDialog = () => {
    setIsOpenLeakageEmissionsDialog(true);
  };
  const handleCloseLeakageEmissionsDialog = () => {
    setIsOpenLeakageEmissionsDialog(false);
    // ポップアップを閉じたときに自動で保存するのであれば以下コメントアウトを解除
    // setLeakageEmissions(text)
  };
  const updateLeakageEmissions = () => {
    const text = getValues("leakageEmissions")
    setLeakageEmissions(text)
    handleCloseLeakageEmissionsDialog()
  }

  // 8.4 GHG排出削減量と二酸化炭素除去量の推定値に関する変数等
  const [nonPermanentRiskReportCheck, setNonPermanentRiskReportCheck] = useState(true);
  const [updateLtaCheck, setUpdateLtaCheck] = useState(true);
  const [ghgCreditCheck, setGhgCreditCheck] = useState(true);
  const [isOpenProjectCreditTermDialog, setIsOpenProjectCreditTermDialog] = React.useState(false);
  const [projectCreditTermRows, setProjectCreditTermRows] = React.useState([]);
  const [projectCreditTermStart, setProjectCreditTermStart] = useState();
  const [projectCreditTermEnd, setProjectCreditTermEnd] = useState();
  const [estimatedBaselineEmissionsTotal, setEstimatedBaselineEmissionsTotal] = useState(0)
  const [estimatedProjectEmissionsTotal, setEstimatedProjectEmissionsTotal] = useState(0)
  const [estimatedLeakageEmissionsTotal, setEstimatedLeakageEmissionsTotal] = useState(0)
  const [estimatedReductionCreditTotal, setEstimatedReductionCreditTotal] = useState(0)
  const [estimatedRemoveCreditTotal, setEstimatedRemoveCreditTotal] = useState(0)
  const [estimatedTotalCreditTotal, setEstimatedTotalCreditTotal] = useState(0)
  const handleChangeNonPermanentRiskReportCheck = (event) => {
    if (event.target.value === "1") {
      setNonPermanentRiskReportCheck(true)
    } else {
      setNonPermanentRiskReportCheck(false)
    }
  }
  const handleChangeUpdateLtaCheck = (event) => {
    if (event.target.value === "1") {
      setUpdateLtaCheck(true)
    } else {
      setUpdateLtaCheck(false)
    }
  }
  const handleChangeGhgCreditCheck = (event) => {
    if (event.target.value === "1") {
      setGhgCreditCheck(true)
    } else {
      setGhgCreditCheck(false)
    }
  }
  const handleChangeProjectCreditTermStart = (newValue) => {
    setProjectCreditTermStart(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeProjectCreditTermEnd = (newValue) => {
    setProjectCreditTermEnd(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleOpenProjectCreditTermDialog = () => {
    setIsOpenProjectCreditTermDialog(true);
  };
  const handleOpenProjectCreditTermDialogForEdit = (params) => {
    setCurrentId(params.row.id)
    setValue("estimatedBaselineEmissions", params.row.estimatedBaselineEmissions)
    setValue("estimatedProjectEmissions", params.row.estimatedProjectEmissions)
    setValue("estimatedLeakageEmissions", params.row.estimatedLeakageEmissions)
    setValue("estimatedReductionCredit", params.row.estimatedReductionCredit)
    setValue("estimatedRemoveCredit", params.row.estimatedRemoveCredit)
    setValue("estimatedTotalCredit", params.row.estimatedTotalCredit)
    setProjectCreditTermStart(params.row.projectCreditTermStart)
    setProjectCreditTermEnd(params.row.projectCreditTermEnd)
    setIsOpenProjectCreditTermDialog(true)
  };
  const handleCloseProjectCreditTermDialog = () => {
    setIsOpenProjectCreditTermDialog(false);
    setValue("estimatedBaselineEmissions", "")
    setValue("estimatedProjectEmissions", "")
    setValue("estimatedLeakageEmissions", "")
    setValue("estimatedReductionCredit", "")
    setValue("estimatedRemoveCredit", "")
    setValue("estimatedTotalCredit", "")
    setProjectCreditTermStart(null)
    setProjectCreditTermEnd(null)
    setCurrentId(0)
  };
  const apiRefForProjectCreditTerm = useGridApiRef();
  const projectCreditTermColumns = [
    { field: "projectTerm", headerName: "プロジェクト期間" },
    { field: "estimatedBaselineEmissions", headerName: "推定ベースライン排出量" },
    { field: "estimatedProjectEmissions", headerName: "推定プロジェクト排出量" },
    { field: "estimatedLeakageEmissions", headerName: "推定リーケージ排出量" },
    { field: "estimatedReductionCredit", headerName: "推定削減クレジット" },
    { field: "estimatedRemoveCredit", headerName: "推定除去クレジット" },
    { field: "estimatedTotalCredit", headerName: "推定合計クレジット" },
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<EditIcon />}
          onClick={() => handleOpenProjectCreditTermDialogForEdit(params)}
        />,
      ]
    },
  ];
  const addProjectCreditTermRows = () => {
    setCurrentId(0)
    const id = (projectCreditTermRows.length === 0) ? 1 : Math.max(...projectCreditTermRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const estimatedBaselineEmissions = getValues("estimatedBaselineEmissions")
    const estimatedProjectEmissions = getValues("estimatedProjectEmissions")
    const estimatedLeakageEmissions = getValues("estimatedLeakageEmissions")
    const estimatedReductionCredit = getValues("estimatedReductionCredit")
    const estimatedRemoveCredit = getValues("estimatedRemoveCredit")
    const estimatedTotalCredit = getValues("estimatedTotalCredit")
    const projectTerm = `${dayjs(projectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(projectCreditTermEnd).format("YYYY/MM/DD")}`

    const newValue = {
      id: id,
      projectTerm: projectTerm,
      estimatedBaselineEmissions: estimatedBaselineEmissions !== "" ? estimatedBaselineEmissions : "0",
      estimatedProjectEmissions: estimatedProjectEmissions !== "" ? estimatedProjectEmissions : "0",
      estimatedLeakageEmissions: estimatedLeakageEmissions !== "" ? estimatedLeakageEmissions : "0",
      estimatedReductionCredit: estimatedReductionCredit !== "" ? estimatedReductionCredit : "0",
      estimatedRemoveCredit: estimatedRemoveCredit !== "" ? estimatedRemoveCredit : "0",
      estimatedTotalCredit: estimatedTotalCredit !== "" ? estimatedTotalCredit : "0",
      projectCreditTermStart: projectCreditTermStart,
      projectCreditTermEnd: projectCreditTermEnd,
    };
    setProjectCreditTermRows([...projectCreditTermRows, newValue]);
    handleCloseProjectCreditTermDialog()
  }

  const changeProjectCreditTermRows = async (row) => {
    let newRows = lodash.cloneDeep(projectCreditTermRows)
    let idx = 0
    for (let i = 0; i < newRows.length; i++) {
      if (currentId === newRows[i]["id"]) {
        idx = i
        break
      }
    }

    const projectTerm = `${dayjs(projectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(projectCreditTermEnd).format("YYYY/MM/DD")}`
    newRows[idx]["projectTerm"] = projectTerm
    newRows[idx]["estimatedBaselineEmissions"] = getValues("estimatedBaselineEmissions")
    newRows[idx]["estimatedProjectEmissions"] = getValues("estimatedProjectEmissions")
    newRows[idx]["estimatedLeakageEmissions"] = getValues("estimatedLeakageEmissions")
    newRows[idx]["estimatedReductionCredit"] = getValues("estimatedReductionCredit")
    newRows[idx]["estimatedRemoveCredit"] = getValues("estimatedRemoveCredit")
    newRows[idx]["estimatedTotalCredit"] = getValues("estimatedTotalCredit")
    newRows[idx]["projectCreditTermStart"] = projectCreditTermStart
    newRows[idx]["projectCreditTermEnd"] = projectCreditTermEnd

    setProjectCreditTermRows(newRows)
    handleCloseProjectCreditTermDialog()
    setCurrentId(0)
  }

  const delProjectCreditTermRows = () => {
    const delProjectCreditTermRow = apiRefForProjectCreditTerm.current.getSelectedRows();
    if (projectCreditTermRows.length === 0 || delProjectCreditTermRow.size === 0) return;
    delProjectCreditTermRow.forEach(v => {
      apiRefForProjectCreditTerm.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectCreditTermRows = apiRefForProjectCreditTerm.current.getSortedRows();
    if (newProjectCreditTermRows !== null) {
      setProjectCreditTermRows(Object.keys(newProjectCreditTermRows).length === 0 ? [] : newProjectCreditTermRows);
    }
  }

  const totalProjectCreditTermRows = () => {
    let allRows = []
    setEstimatedBaselineEmissionsTotal(parseFloat("0"))
    setEstimatedProjectEmissionsTotal(parseFloat("0"))
    setEstimatedLeakageEmissionsTotal(parseFloat("0"))
    setEstimatedReductionCreditTotal(parseFloat("0"))
    setEstimatedRemoveCreditTotal(parseFloat("0"))
    setEstimatedTotalCreditTotal(parseFloat("0"))
    if (Object.keys(apiRefForProjectCreditTerm?.current).length !== 0) {
      allRows = apiRefForProjectCreditTerm.current.getSortedRows()
    } else {
      allRows = projectCreditTermRows
    }
    allRows.forEach((row) => {
      setEstimatedBaselineEmissionsTotal(parseFloat(estimatedBaselineEmissionsTotal) + parseFloat(row.estimatedBaselineEmissions))
      setEstimatedProjectEmissionsTotal(parseFloat(estimatedProjectEmissionsTotal) + parseFloat(row.estimatedProjectEmissions))
      setEstimatedLeakageEmissionsTotal(parseFloat(estimatedLeakageEmissionsTotal) + parseFloat(row.estimatedLeakageEmissions))
      setEstimatedReductionCreditTotal(parseFloat(estimatedReductionCreditTotal) + parseFloat(row.estimatedReductionCredit))
      setEstimatedRemoveCreditTotal(parseFloat(estimatedRemoveCreditTotal) + parseFloat(row.estimatedRemoveCredit))
      setEstimatedTotalCreditTotal(parseFloat(estimatedTotalCreditTotal) + parseFloat(row.estimatedTotalCredit))
    })
  }

  useEffect(() => {
    if (projectCreditTermRows.length !== 0) {
      totalProjectCreditTermRows()
    }
  }, [projectCreditTermRows])

  const getEmissionsDetailRows = () => {
    if (!apiRefForProjectCreditTerm.current || Object.keys(apiRefForProjectCreditTerm.current).length === 0) {
      return projectCreditTermRows || null
    }
    const allProjectCreditTermRows = projectCreditTermRows || apiRefForProjectCreditTerm.current?.getSortedRows();
    if (!allProjectCreditTermRows) return projectCreditTermRows || null
    let estimatedDetailValues = []
    allProjectCreditTermRows.forEach((row) => {
      let value = {
        projectCreditTermStart: "",
        projectCreditTermEnd: "",
        estimatedBaselineEmissions: "",
        estimatedProjectEmissions: "",
        estimatedLeakageEmissions: "",
        estimatedReductionCredit: "",
        estimatedRemoveCredit: "",
        estimatedTotalCredit: "",
      }
      value.projectCreditTermStart = row.projectCreditTermStart
      value.projectCreditTermEnd = row.projectCreditTermEnd
      value.estimatedBaselineEmissions = row.estimatedBaselineEmissions
      value.estimatedProjectEmissions = row.estimatedProjectEmissions
      value.estimatedLeakageEmissions = row.estimatedLeakageEmissions
      value.estimatedReductionCredit = row.estimatedReductionCredit
      value.estimatedRemoveCredit = row.estimatedRemoveCredit
      value.estimatedTotalCredit = row.estimatedTotalCredit
      estimatedDetailValues.push(value)
    })
    return estimatedDetailValues
  }

  const recoverProjectCreditTermRows = (data) => {
    let newRows = []
    if (data === []) return newRows
    let id = 0
    data.forEach((item) => {
      id += 1
      const estimatedBaselineEmissions = item.estimatedBaselineEmissions
      const estimatedProjectEmissions = item.estimatedProjectEmissions
      const estimatedLeakageEmissions = item.estimatedLeakageEmissions
      const estimatedReductionCredit = item.estimatedReductionCredit
      const estimatedRemoveCredit = item.estimatedRemoveCredit
      const estimatedTotalCredit = item.estimatedTotalCredit
      const projectTerm = `${dayjs(item.projectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(item.projectCreditTermEnd).format("YYYY/MM/DD")}`
      const start = dayjs(item.projectCreditTermStart)
      const end = dayjs(item.projectCreditTermEnd)

      const newValue = {
        id: id,
        projectTerm: projectTerm,
        estimatedBaselineEmissions: estimatedBaselineEmissions !== "" ? estimatedBaselineEmissions : "0",
        estimatedProjectEmissions: estimatedProjectEmissions !== "" ? estimatedProjectEmissions : "0",
        estimatedLeakageEmissions: estimatedLeakageEmissions !== "" ? estimatedLeakageEmissions : "0",
        estimatedReductionCredit: estimatedReductionCredit !== "" ? estimatedReductionCredit : "0",
        estimatedRemoveCredit: estimatedRemoveCredit !== "" ? estimatedRemoveCredit : "0",
        estimatedTotalCredit: estimatedTotalCredit !== "" ? estimatedTotalCredit : "0",
        projectCreditTermStart: start,
        projectCreditTermEnd: end,
      };
      newRows.push(newValue)
    })
    return newRows
  }

  // 9.1 検証時に利用可能なデータとパラメータ
  const [isOpenMonitoringDialog1, setIsOpenMonitoringDialog1] = React.useState(false);
  const [monitoring1Rows, setMonitoring1Rows] = React.useState([]);
  const [monitoringDataSource1Files, setMonitoringDataSource1Files] = React.useState([]);
  const handleOpenMonitoringDialog1 = () => {
    setIsOpenMonitoringDialog1(true);
  };
  const handleOpenMonitoringDialog1ForEdit = (params) => {
    setIsOpenMonitoringDialog1(true);
    setCurrentId(params.row.id)
    setValue("monitoringDataOrParam1", params.row.monitoringDataOrParam1)
    setValue("monitoringDataUnit1", params.row.monitoringDataUnit1)
    setValue("monitoringDataContents1", params.row.monitoringDataContents1)
    setValue("monitoringDataSource1", params.row.monitoringDataSource1)
    setValue("monitoringApplicableValue1", params.row.monitoringApplicableValue1)
    setValue("monitoringExplain1", params.row.monitoringExplain1)
    setValue("monitoringPurpose1", params.row.monitoringPurpose1)
    setValue("monitoringComment1", params.row.monitoringComment1)
  };
  const handleCloseMonitoringDialog1 = () => {
    setIsOpenMonitoringDialog1(false);
    setCurrentId(0)
  };

  const apiRefForMonitoring1 = useGridApiRef();

  const monitoring1Columns = [
    { field: "monitoringDataOrParam1", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit1", headerName: "データ単位" },
    { field: "monitoringDataContents1", headerName: "データ内容" },
    { field: "monitoringDataSource1", headerName: "データソース" },
    { field: "monitoringApplicableValue1", headerName: "適用値" },
    { field: "monitoringExplain1", headerName: "データ選択の正当性、または適用した測定方法と手順の説明" },
    { field: "monitoringPurpose1", headerName: "データの目的" },
    { field: "monitoringComment1", headerName: "コメント" },
    // TODO: 詳細や編集ボタンをつける
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<EditIcon />}
          onClick={() => handleOpenMonitoringDialog1ForEdit(params)}
        />,
      ]
    },
  ];
  const addMonitoring1Rows = () => {
    const id = (monitoring1Rows.length === 0) ? 1 : Math.max(...monitoring1Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const monitoringDataOrParam1Value = getValues("monitoringDataOrParam1")
    const monitoringDataUnit1Value = getValues("monitoringDataUnit1")
    const monitoringDataContents1Value = getValues("monitoringDataContents1")
    const monitoringDataSource1Value = getValues("monitoringDataSource1")
    const monitoringApplicableValue1Value = getValues("monitoringApplicableValue1")
    const monitoringExplain1Value = getValues("monitoringExplain1")
    const monitoringPurpose1Value = getValues("monitoringPurpose1")
    const monitoringComment1Value = getValues("monitoringComment1")
    const monitoringDataSource1File = getValues("monitoringDataSource1File") || ""

    const newValue = {
      id: id,
      monitoringDataOrParam1: monitoringDataOrParam1Value,
      monitoringDataUnit1: monitoringDataUnit1Value,
      monitoringDataContents1: monitoringDataContents1Value,
      monitoringDataSource1: monitoringDataSource1Value,
      monitoringApplicableValue1: monitoringApplicableValue1Value,
      monitoringExplain1: monitoringExplain1Value,
      monitoringPurpose1: monitoringPurpose1Value,
      monitoringComment1: monitoringComment1Value,
    };
    if (monitoringDataSource1File !== "") {
      setMonitoringDataSource1Files([...monitoringDataSource1Files, monitoringDataSource1File])
    }
    setMonitoring1Rows([...monitoring1Rows, newValue]);
    // reset({
    //   monitoringDataOrParam1: "",
    //   monitoringDataUnit1: "",
    //   monitoringDataContents1: "",
    //   monitoringDataSource1: "",
    //   monitoringApplicableValue1: "",
    //   monitoringExplain1: "",
    //   monitoringPurpose1: "",
    //   monitoringComment1: "",
    // })
    setValue("monitoringDataOrParam1", "")
    setValue("monitoringDataUnit1", "")
    setValue("monitoringDataContents1", "")
    setValue("monitoringDataSource1", "")
    setValue("monitoringApplicableValue1", "")
    setValue("monitoringExplain1", "")
    setValue("monitoringPurpose1", "")
    setValue("monitoringComment1", "")
    setValue("monitoringDataSource1File", "")
    handleCloseMonitoringDialog1()
  }

  const changeMonitoring1Rows = (row) => {
    let newRows = lodash.cloneDeep(monitoring1Rows)
    // const id = monitoring2Rows.findIndex(v => v.id === row.id)
    let idx = 0
    for (let i = 0; i < newRows.length; i++) {
      if (currentId === newRows[i]["id"]) {
        idx = i
        break
      }
    }
    newRows[idx]["monitoringDataOrParam1"] = getValues("monitoringDataOrParam1")
    newRows[idx]["monitoringDataUnit1"] = getValues("monitoringDataUnit1")
    newRows[idx]["monitoringDataContents1"] = getValues("monitoringDataContents1")
    newRows[idx]["monitoringDataSource1"] = getValues("monitoringDataSource1")
    newRows[idx]["monitoringApplicableValue1"] = getValues("monitoringApplicableValue1")
    newRows[idx]["monitoringExplain1"] = getValues("monitoringExplain1")
    newRows[idx]["monitoringPurpose1"] = getValues("monitoringPurpose1")
    newRows[idx]["monitoringComment1"] = getValues("monitoringComment1")
    const monitoringDataSource1File = getValues("monitoringDataSource1File") || ""
    if (monitoringDataSource1File !== "") {
      setMonitoringDataSource1Files([...monitoringDataSource1Files, monitoringDataSource1File])
    }

    setMonitoring1Rows(newRows);
    setValue("monitoringDataOrParam1", "")
    setValue("monitoringDataUnit1", "")
    setValue("monitoringDataContents1", "")
    setValue("monitoringDataSource1", "")
    setValue("monitoringApplicableValue1", "")
    setValue("monitoringExplain1", "")
    setValue("monitoringPurpose1", "")
    setValue("monitoringComment1", "")
    setValue("monitoringDataSource1File", "")
    setCurrentId(0)
    handleCloseMonitoringDialog1()
  }

  const delMonitoring1Rows = () => {
    const delMonitoringRow = apiRefForMonitoring1.current.getSelectedRows();
    if (monitoring1Rows.length === 0 || delMonitoringRow.size === 0) return;
    delMonitoringRow.forEach(v => {
      apiRefForMonitoring1.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMonitoring1Rows = apiRefForMonitoring1.current.getSortedRows();
    if (newMonitoring1Rows !== null) {
      setMonitoring1Rows(Object.keys(newMonitoring1Rows).length === 0 ? [] : newMonitoring1Rows);
    }
  }

  const getAvailableDataRows = () => {
    if (!apiRefForMonitoring1.current || Object.keys(apiRefForMonitoring1.current).length === 0) {
      return monitoring1Rows || null
    }
    const allMonitoring1Rows = apiRefForMonitoring1.current?.getSortedRows();
    return allMonitoring1Rows
  }

  // 9.2 モニタリングされるデータとパラメータ
  const [isOpenMonitoringDialog2, setIsOpenMonitoringDialog2] = React.useState(false);
  const [monitoring2Rows, setMonitoring2Rows] = React.useState([]);
  const [monitoringDataSource2Files, setMonitoringDataSource2Files] = React.useState([]);
  const [currentId, setCurrentId] = React.useState(0);
  const handleOpenMonitoringDialog2 = () => {
    setIsOpenMonitoringDialog2(true);
  };
  const handleOpenMonitoringDialog2ForEdit = (params) => {
    setIsOpenMonitoringDialog2(true);
    setCurrentId(params.row.id)
    setValue("monitoringDataOrParam2", params.row.monitoringDataOrParam2)
    setValue("monitoringDataUnit2", params.row.monitoringDataUnit2)
    setValue("monitoringDataContents2", params.row.monitoringDataContents2)
    setValue("monitoringDataSource2", params.row.monitoringDataSource2)
    setValue("monitoringExplain2", params.row.monitoringExplain2)
    setValue("monitoringFrequency", params.row.monitoringFrequency)
    setValue("monitoringApplicableValue2", params.row.monitoringApplicableValue2)
    setValue("monitoringDevice", params.row.monitoringDevice)
    setValue("monitoringQaQcProcedure", params.row.monitoringQaQcProcedure)
    setValue("monitoringPurpose2", params.row.monitoringPurpose2)
    setValue("howToCalc", params.row.howToCalc)
    setValue("monitoringComment2", params.row.monitoringComment2)
  };
  const handleCloseMonitoringDialog2 = () => {
    setIsOpenMonitoringDialog2(false);
    setCurrentId(0)
  };

  const apiRefForMonitoring2 = useGridApiRef();

  const monitoring2Columns = [
    { field: "monitoringDataOrParam2", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit2", headerName: "データ単位" },
    { field: "monitoringDataContents2", headerName: "データ内容" },
    { field: "monitoringDataSource2", headerName: "データソース" },
    { field: "monitoringExplain2", headerName: "適用される測定方法と手順の説明" },
    { field: "monitoringFrequency", headerName: "モニタリング／記録の頻度" },
    { field: "monitoringApplicableValue2", headerName: "適用値" },
    { field: "monitoringDevice", headerName: "モニタリング機器" },
    { field: "monitoringQaQcProcedure", headerName: "適用されるQA/QC手順" },
    { field: "monitoringPurpose2", headerName: "データの目的" },
    { field: "howToCalc", headerName: "計算方法" },
    { field: "monitoringComment2", headerName: "コメント" },
    // TODO: 詳細や編集ボタンをつける
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<EditIcon />}
          onClick={() => handleOpenMonitoringDialog2ForEdit(params)}
        />,
      ]
    },
  ];
  const addMonitoring2Rows = () => {
    const id = (monitoring2Rows.length === 0) ? 1 : Math.max(...monitoring2Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const monitoringDataOrParam2Value = getValues("monitoringDataOrParam2")
    const monitoringDataUnit2Value = getValues("monitoringDataUnit2")
    const monitoringDataContents2Value = getValues("monitoringDataContents2")
    const monitoringDataSource2Value = getValues("monitoringDataSource2")
    const monitoringExplain2Value = getValues("monitoringExplain2")
    const monitoringFrequencyValue = getValues("monitoringFrequency")
    const monitoringApplicableValue2Value = getValues("monitoringApplicableValue2")
    const monitoringDeviceValue = getValues("monitoringDevice")
    const monitoringQaQcProcedureValue = getValues("monitoringQaQcProcedure")
    const monitoringPurpose2Value = getValues("monitoringPurpose2")
    const howToCalcValue = getValues("howToCalc")
    const monitoringComment2Value = getValues("monitoringComment2")
    const monitoringDataSource2File = getValues("monitoringDataSource2File") || ""

    const newValue = {
      id: id,
      monitoringDataOrParam2: monitoringDataOrParam2Value,
      monitoringDataUnit2: monitoringDataUnit2Value,
      monitoringDataContents2: monitoringDataContents2Value,
      monitoringDataSource2: monitoringDataSource2Value,
      monitoringExplain2: monitoringExplain2Value,
      monitoringFrequency: monitoringFrequencyValue,
      monitoringApplicableValue2: monitoringApplicableValue2Value,
      monitoringDevice: monitoringDeviceValue,
      monitoringQaQcProcedure: monitoringQaQcProcedureValue,
      monitoringPurpose2: monitoringPurpose2Value,
      howToCalc: howToCalcValue,
      monitoringComment2: monitoringComment2Value,
    };
    if (monitoringDataSource2File !== "") {
      setMonitoringDataSource2Files([...monitoringDataSource2Files, monitoringDataSource2File])
    }
    setMonitoring2Rows([...monitoring2Rows, newValue]);
    // reset({
    //   monitoringDataOrParam2: "",
    //   monitoringDataUnit2: "",
    //   monitoringDataContents2: "",
    //   monitoringDataSource2: "",
    //   monitoringExplain2: "",
    //   monitoringFrequency: "",
    //   monitoringApplicableValue2: "",
    //   monitoringDevice: "",
    //   monitoringQaQcProcedure: "",
    //   monitoringPurpose2: "",
    //   howToCalc: "",
    //   monitoringComment2: "",
    // })
    setValue("monitoringDataOrParam2", "")
    setValue("monitoringDataUnit2", "")
    setValue("monitoringDataContents2", "")
    setValue("monitoringDataSource2", "")
    setValue("monitoringExplain2", "")
    setValue("monitoringFrequency", "")
    setValue("monitoringApplicableValue2", "")
    setValue("monitoringDevice", "")
    setValue("monitoringQaQcProcedure", "")
    setValue("monitoringPurpose2", "")
    setValue("howToCalc", "")
    setValue("monitoringComment2", "")
    setValue("monitoringDataSource2File", "")
    handleCloseMonitoringDialog2()
  }

  const changeMonitoring2Rows = (row) => {
    let newRows = lodash.cloneDeep(monitoring2Rows)
    // const id = monitoring2Rows.findIndex(v => v.id === row.id)
    let idx = 0
    for (let i = 0; i < newRows.length; i++) {
      if (currentId === newRows[i]["id"]) {
        idx = i
        break
      }
    }
    newRows[idx]["monitoringDataOrParam2"] = getValues("monitoringDataOrParam2")
    newRows[idx]["monitoringDataUnit2"] = getValues("monitoringDataUnit2")
    newRows[idx]["monitoringDataContents2"] = getValues("monitoringDataContents2")
    newRows[idx]["monitoringDataSource2"] = getValues("monitoringDataSource2")
    newRows[idx]["monitoringExplain2"] = getValues("monitoringExplain2")
    newRows[idx]["monitoringFrequency"] = getValues("monitoringFrequency")
    newRows[idx]["monitoringApplicableValue2"] = getValues("monitoringApplicableValue2")
    newRows[idx]["monitoringDevice"] = getValues("monitoringDevice")
    newRows[idx]["monitoringQaQcProcedure"] = getValues("monitoringQaQcProcedure")
    newRows[idx]["monitoringPurpose2"] = getValues("monitoringPurpose2")
    newRows[idx]["howToCalc"] = getValues("howToCalc")
    newRows[idx]["monitoringComment2"] = getValues("monitoringComment2")
    const monitoringDataSource2File = getValues("monitoringDataSource2File") || ""
    if (monitoringDataSource2File !== "") {
      setMonitoringDataSource2Files([...monitoringDataSource2Files, monitoringDataSource2File])
    }

    setMonitoring2Rows(newRows);
    setValue("monitoringDataOrParam2", "")
    setValue("monitoringDataUnit2", "")
    setValue("monitoringDataContents2", "")
    setValue("monitoringDataSource2", "")
    setValue("monitoringExplain2", "")
    setValue("monitoringFrequency", "")
    setValue("monitoringApplicableValue2", "")
    setValue("monitoringDevice", "")
    setValue("monitoringQaQcProcedure", "")
    setValue("monitoringPurpose2", "")
    setValue("howToCalc", "")
    setValue("monitoringComment2", "")
    setValue("monitoringDataSource2File", "")
    setCurrentId(0)
    handleCloseMonitoringDialog2()
  }

  const delMonitoring2Rows = () => {
    const delMonitoring2Row = apiRefForMonitoring2.current.getSelectedRows();
    if (monitoring2Rows.length === 0 || delMonitoring2Row.size === 0) return;
    delMonitoring2Row.forEach(v => {
      apiRefForMonitoring2.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMonitoring2Rows = apiRefForMonitoring2.current.getSortedRows();
    if (newMonitoring2Rows !== null) {
      setMonitoring2Rows(Object.keys(newMonitoring2Rows).length === 0 ? [] : newMonitoring2Rows);
    }
  }

  const getMonitoringDataRows = () => {
    if (!apiRefForMonitoring2.current || Object.keys(apiRefForMonitoring2.current).length === 0) {
      return monitoring2Rows || null
    }
    const allMonitoring2Rows = apiRefForMonitoring2.current?.getSortedRows();
    return allMonitoring2Rows
  }

  // 10.1 ベースライン排出量に関する変数等
  const [quantifyBaselineEmissions, setQuantifyBaselineEmissions] = useState("");
  const [isOpenQuantifyBaselineEmissionsDialog, setIsOpenQuantifyBaselineEmissionsDialog] = React.useState(false);
  const handleOpenQuantifyBaselineEmissionsDialog = () => {
    setIsOpenQuantifyBaselineEmissionsDialog(true);
  };
  const handleCloseQuantifyBaselineEmissionsDialog = () => {
    setIsOpenQuantifyBaselineEmissionsDialog(false);
    // ポップアップを閉じたときに自動で保存するのであれば以下コメントアウトを解除
    // setQuantifyBaselineEmissions(text)
  };
  const updateQuantifyBaselineEmissions = () => {
    const text = getValues("quantifyBaselineEmissions")
    setQuantifyBaselineEmissions(text)
    handleCloseQuantifyBaselineEmissionsDialog()
  }

  // 10.2 プロジェクト排出量に関する変数等
  const [quantifyProjectEmissions, setQuantifyProjectEmissions] = useState("");
  const [isOpenQuantifyProjectEmissionsDialog, setIsOpenQuantifyProjectEmissionsDialog] = React.useState(false);
  const handleOpenQuantifyProjectEmissionsDialog = () => {
    setIsOpenQuantifyProjectEmissionsDialog(true);
  };
  const handleCloseQuantifyProjectEmissionsDialog = () => {
    setIsOpenQuantifyProjectEmissionsDialog(false);
    // ポップアップを閉じたときに自動で保存するのであれば以下コメントアウトを解除
    // setQuantifyProjectEmissions(text)
  };
  const updateQuantifyProjectEmissions = () => {
    const text = getValues("quantifyProjectEmissions")
    setQuantifyProjectEmissions(text)
    handleCloseQuantifyProjectEmissionsDialog()
  }

  // 10.3 リーケージ排出量に関する変数等
  const [quantifyLeakageEmissions, setQuantifyLeakageEmissions] = useState("");
  const [isOpenQuantifyLeakageEmissionsDialog, setIsOpenQuantifyLeakageEmissionsDialog] = React.useState(false);
  const handleOpenQuantifyLeakageEmissionsDialog = () => {
    setIsOpenQuantifyLeakageEmissionsDialog(true);
  };
  const handleCloseQuantifyLeakageEmissionsDialog = () => {
    setIsOpenQuantifyLeakageEmissionsDialog(false);
    // ポップアップを閉じたときに自動で保存するのであれば以下コメントアウトを解除
    // setQuantifyLeakageEmissions(text)
  };
  const updateQuantifyLeakageEmissions = () => {
    const text = getValues("quantifyLeakageEmissions")
    setQuantifyLeakageEmissions(text)
    handleCloseQuantifyLeakageEmissionsDialog()
  }

  // 10.4 GHG排出削減量と二酸化炭素除去量に関する変数等
  const [quantifyNonPermanentRiskReportCheck, setQuantifyNonPermanentRiskReportCheck] = useState(true);
  const [quantifyUpdateLtaCheck, setQuantifypdateLtaCheck] = useState(true);
  const [quantifyGhgCreditCheck, setQuantifyGhgCreditCheck] = useState(true);
  const [isOpenQuantifyProjectCreditTermDialog, setIsOpenQuantifyProjectCreditTermDialog] = React.useState(false);
  const [quantifyProjectCreditTermRows, setQuantifyProjectCreditTermRows] = React.useState([]);
  const [quantifyProjectCreditTermStart, setQuantifyProjectCreditTermStart] = useState();
  const [quantifyProjectCreditTermEnd, setQuantifyProjectCreditTermEnd] = useState();
  const [quantifyEstimatedBaselineEmissionsTotal, setQuantifyEstimatedBaselineEmissionsTotal] = useState(0)
  const [quantifyEstimatedProjectEmissionsTotal, setQuantifyEstimatedProjectEmissionsTotal] = useState(0)
  const [quantifyEstimatedLeakageEmissionsTotal, setQuantifyEstimatedLeakageEmissionsTotal] = useState(0)
  const [quantifyEstimatedReductionCreditTotal, setQuantifyEstimatedReductionCreditTotal] = useState(0)
  const [quantifyEstimatedRemoveCreditTotal, setQuantifyEstimatedRemoveCreditTotal] = useState(0)
  const [quantifyEstimatedTotalCreditTotal, setQuantifyEstimatedTotalCreditTotal] = useState(0)
  const handleChangeQuantifyNonPermanentRiskReportCheck = (event) => {
  if (event.target.value === "1") {
      setQuantifyNonPermanentRiskReportCheck(true)
    } else {
      setQuantifyNonPermanentRiskReportCheck(false)
    }
  }
  const handleChangeQuantifyUpdateLtaCheck = (event) => {
    if (event.target.value === "1") {
      setQuantifypdateLtaCheck(true)
    } else {
      setQuantifypdateLtaCheck(false)
    }
  }
  const handleChangeQuantifyGhgCreditCheck = (event) => {
    if (event.target.value === "1") {
      setQuantifyGhgCreditCheck(true)
    } else {
      setQuantifyGhgCreditCheck(false)
    }
  }
  const handleChangeQuantifyProjectCreditTermStart = (newValue) => {
    setQuantifyProjectCreditTermStart(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeQuantifyProjectCreditTermEnd = (newValue) => {
    setQuantifyProjectCreditTermEnd(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleOpenQuantifyProjectCreditTermDialog = () => {
    isOpenQuantifyProjectCreditTermDialog(true);
  };
  const handleOpenQuantifyProjectCreditTermDialogForEdit = (params) => {
    setCurrentId(params.row.id)
    setValue("quantifyEstimatedBaselineEmissions", params.row.quantifyEstimatedBaselineEmissions)
    setValue("quantifyEstimatedProjectEmissions", params.row.quantifyEstimatedProjectEmissions)
    setValue("quantifyEstimatedLeakageEmissions", params.row.quantifyEstimatedLeakageEmissions)
    setValue("quantifyEstimatedReductionCredit", params.row.quantifyEstimatedReductionCredit)
    setValue("quantifyEstimatedRemoveCredit", params.row.quantifyEstimatedRemoveCredit)
    setValue("quantifyEstimatedTotalCredit", params.row.quantifyEstimatedTotalCredit)
    setQuantifyProjectCreditTermStart(params.row.quantifyProjectCreditTermStart)
    setQuantifyProjectCreditTermEnd(params.row.quantifyProjectCreditTermEnd)
    isOpenQuantifyProjectCreditTermDialog(true)
  };
  const handleCloseQuantifyProjectCreditTermDialog = () => {
    isOpenQuantifyProjectCreditTermDialog(false);
    setValue("quantifyEstimatedBaselineEmissions", "")
    setValue("quantifyEstimatedProjectEmissions", "")
    setValue("quantifyEstimatedLeakageEmissions", "")
    setValue("quantifyEstimatedReductionCredit", "")
    setValue("quantifyEstimatedRemoveCredit", "")
    setValue("quantifyEstimatedTotalCredit", "")
    setQuantifyProjectCreditTermStart(null)
    setQuantifyProjectCreditTermEnd(null)
    setCurrentId(0)
  };
  const apiRefForQuantifyProjectCreditTerm = useGridApiRef();
  const quantifyProjectCreditTermColumns = [
    { field: "quantifyProjectTerm", headerName: "プロジェクト期間" },
    { field: "quantifyEstimatedBaselineEmissions", headerName: "推定ベースライン排出量" },
    { field: "quantifyEstimatedProjectEmissions", headerName: "推定プロジェクト排出量" },
    { field: "quantifyEstimatedLeakageEmissions", headerName: "推定リーケージ排出量" },
    { field: "quantifyEstimatedReductionCredit", headerName: "推定削減クレジット" },
    { field: "quantifyEstimatedRemoveCredit", headerName: "推定除去クレジット" },
    { field: "quantifyEstimatedTotalCredit", headerName: "推定合計クレジット" },
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<EditIcon />}
          onClick={() => handleOpenQuantifyProjectCreditTermDialogForEdit(params)}
        />,
      ]
    },
  ];
  const addQuantifyProjectCreditTermRows = () => {
    setCurrentId(0)
    const id = (quantifyProjectCreditTermRows.length === 0) ? 1 : Math.max(...quantifyProjectCreditTermRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const quantifyEstimatedBaselineEmissions = getValues("quantifyEstimatedBaselineEmissions")
    const quantifyEstimatedProjectEmissions = getValues("quantifyEstimatedProjectEmissions")
    const quantifyEstimatedLeakageEmissions = getValues("quantifyEstimatedLeakageEmissions")
    const quantifyEstimatedReductionCredit = getValues("quantifyEstimatedReductionCredit")
    const quantifyEstimatedRemoveCredit = getValues("quantifyEstimatedRemoveCredit")
    const quantifyEstimatedTotalCredit = getValues("quantifyEstimatedTotalCredit")
    const quantifyProjectCreditTermRows = `${dayjs(quantifyProjectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(quantifyProjectCreditTermEnd).format("YYYY/MM/DD")}`

    const newValue = {
      id: id,
      quantifyProjectCreditTermRows: quantifyProjectCreditTermRows,
      quantifyEstimatedBaselineEmissions: quantifyEstimatedBaselineEmissions !== "" ? quantifyEstimatedBaselineEmissions : "0",
      quantifyEstimatedProjectEmissions: quantifyEstimatedProjectEmissions !== "" ? quantifyEstimatedProjectEmissions : "0",
      quantifyEstimatedLeakageEmissions: quantifyEstimatedLeakageEmissions !== "" ? quantifyEstimatedLeakageEmissions : "0",
      quantifyEstimatedReductionCredit: quantifyEstimatedReductionCredit !== "" ? quantifyEstimatedReductionCredit : "0",
      quantifyEstimatedRemoveCredit: quantifyEstimatedRemoveCredit !== "" ? quantifyEstimatedRemoveCredit : "0",
      quantifyEstimatedTotalCredit: quantifyEstimatedTotalCredit !== "" ? quantifyEstimatedTotalCredit : "0",
      quantifyProjectCreditTermStart: quantifyProjectCreditTermStart,
      quantifyProjectCreditTermEnd: quantifyProjectCreditTermEnd,
    };
    setQuantifyProjectCreditTermRows([...quantifyProjectCreditTermRows, newValue]);
    handleCloseQuantifyProjectCreditTermDialog()
  }

  const changeQuantifyProjectCreditTermRows = async (row) => {
    let newRows = lodash.cloneDeep(quantifyProjectCreditTermRows)
    let idx = 0
    for (let i = 0; i < newRows.length; i++) {
      if (currentId === newRows[i]["id"]) {
        idx = i
        break
      }
    }

    const quantifyProjectCreditTermRows = `${dayjs(quantifyProjectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(quantifyProjectCreditTermEnd).format("YYYY/MM/DD")}`
    newRows[idx]["quantifyProjectCreditTermRows"] = quantifyProjectCreditTermRows
    newRows[idx]["quantifyEstimatedBaselineEmissions"] = getValues("quantifyEstimatedBaselineEmissions")
    newRows[idx]["quantifyEstimatedProjectEmissions"] = getValues("quantifyEstimatedProjectEmissions")
    newRows[idx]["quantifyEstimatedLeakageEmissions"] = getValues("quantifyEstimatedLeakageEmissions")
    newRows[idx]["quantifyEstimatedReductionCredit"] = getValues("quantifyEstimatedReductionCredit")
    newRows[idx]["quantifyEstimatedRemoveCredit"] = getValues("quantifyEstimatedRemoveCredit")
    newRows[idx]["quantifyEstimatedTotalCredit"] = getValues("quantifyEstimatedTotalCredit")
    newRows[idx]["quantifyProjectCreditTermStart"] = quantifyProjectCreditTermStart
    newRows[idx]["quantifyProjectCreditTermEnd"] = quantifyProjectCreditTermEnd

    setQuantifyProjectCreditTermRows(newRows)
    handleCloseQuantifyProjectCreditTermDialog()
    setCurrentId(0)
  }

  const delQuantifyProjectCreditTermRows = () => {
    const delProjectCreditTermRow = apiRefForQuantifyProjectCreditTerm.current.getSelectedRows();
    if (quantifyProjectCreditTermRows.length === 0 || delProjectCreditTermRow.size === 0) return;
    delProjectCreditTermRow.forEach(v => {
      apiRefForQuantifyProjectCreditTerm.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectCreditTermRows = apiRefForQuantifyProjectCreditTerm.current.getSortedRows();
    if (newProjectCreditTermRows !== null) {
      setQuantifyProjectCreditTermRows(Object.keys(newProjectCreditTermRows).length === 0 ? [] : newProjectCreditTermRows);
    }
  }

  const totalQuantifyProjectCreditTermRows = () => {
    let allRows = []
    setQuantifyEstimatedBaselineEmissionsTotal(parseFloat("0"))
    setQuantifyEstimatedProjectEmissionsTotal(parseFloat("0"))
    setQuantifyEstimatedLeakageEmissionsTotal(parseFloat("0"))
    setQuantifyEstimatedReductionCreditTotal(parseFloat("0"))
    setQuantifyEstimatedRemoveCreditTotal(parseFloat("0"))
    setQuantifyEstimatedTotalCreditTotal(parseFloat("0"))
    if (Object.keys(apiRefForQuantifyProjectCreditTerm?.current).length !== 0) {
      allRows = apiRefForQuantifyProjectCreditTerm.current.getSortedRows()
    } else {
      allRows = quantifyProjectCreditTermRows
    }
    allRows.forEach((row) => {
      setQuantifyEstimatedBaselineEmissionsTotal(parseFloat(quantifyEstimatedBaselineEmissionsTotal) + parseFloat(row.quantifyEstimatedBaselineEmissions))
      setQuantifyEstimatedProjectEmissionsTotal(parseFloat(quantifyEstimatedProjectEmissionsTotal) + parseFloat(row.quantifyEstimatedProjectEmissions))
      setQuantifyEstimatedLeakageEmissionsTotal(parseFloat(quantifyEstimatedLeakageEmissionsTotal) + parseFloat(row.quantifyEstimatedLeakageEmissions))
      setQuantifyEstimatedReductionCreditTotal(parseFloat(quantifyEstimatedReductionCreditTotal) + parseFloat(row.quantifyEstimatedReductionCredit))
      setQuantifyEstimatedRemoveCreditTotal(parseFloat(quantifyEstimatedRemoveCreditTotal) + parseFloat(row.quantifyEstimatedRemoveCredit))
      setQuantifyEstimatedTotalCreditTotal(parseFloat(quantifyEstimatedTotalCreditTotal) + parseFloat(row.quantifyEstimatedTotalCredit))
    })
  }

  useEffect(() => {
    if (quantifyProjectCreditTermRows.length !== 0) {
      totalQuantifyProjectCreditTermRows()
    }
  }, [quantifyProjectCreditTermRows])

  const getQuantifyEmissionsDetailRows = () => {
    if (!apiRefForQuantifyProjectCreditTerm.current || Object.keys(apiRefForQuantifyProjectCreditTerm.current).length === 0) {
      return quantifyProjectCreditTermRows || null
    }
    const allProjectCreditTermRows = quantifyProjectCreditTermRows || apiRefForQuantifyProjectCreditTerm.current?.getSortedRows();
    if (!allProjectCreditTermRows) return quantifyProjectCreditTermRows || null
    let estimatedDetailValues = []
    allProjectCreditTermRows.forEach((row) => {
      let value = {
        quantifyProjectCreditTermStart: "",
        quantifyProjectCreditTermEnd: "",
        quantifyEstimatedBaselineEmissions: "",
        quantifyEstimatedProjectEmissions: "",
        quantifyEstimatedLeakageEmissions: "",
        quantifyEstimatedReductionCredit: "",
        quantifyEstimatedRemoveCredit: "",
        quantifyEstimatedTotalCredit: "",
      }
      value.quantifyProjectCreditTermStart = row.quantifyProjectCreditTermStart
      value.quantifyProjectCreditTermEnd = row.quantifyProjectCreditTermEnd
      value.quantifyEstimatedBaselineEmissions = row.quantifyEstimatedBaselineEmissions
      value.quantifyEstimatedProjectEmissions = row.quantifyEstimatedProjectEmissions
      value.quantifyEstimatedLeakageEmissions = row.quantifyEstimatedLeakageEmissions
      value.quantifyEstimatedReductionCredit = row.quantifyEstimatedReductionCredit
      value.quantifyEstimatedRemoveCredit = row.quantifyEstimatedRemoveCredit
      value.quantifyEstimatedTotalCredit = row.quantifyEstimatedTotalCredit
      estimatedDetailValues.push(value)
    })
    return estimatedDetailValues
  }

  const recoverQuantifyProjectCreditTermRows = (data) => {
    let newRows = []
    if (data === []) return newRows
    let id = 0
    data.forEach((item) => {
      id += 1
      const quantifyEstimatedBaselineEmissions = item.quantifyEstimatedBaselineEmissions
      const quantifyEstimatedProjectEmissions = item.quantifyEstimatedProjectEmissions
      const quantifyEstimatedLeakageEmissions = item.quantifyEstimatedLeakageEmissions
      const quantifyEstimatedReductionCredit = item.quantifyEstimatedReductionCredit
      const quantifyEstimatedRemoveCredit = item.quantifyEstimatedRemoveCredit
      const quantifyEstimatedTotalCredit = item.quantifyEstimatedTotalCredit
      const quantifyProjectCreditTermRows = `${dayjs(item.quantifyProjectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(item.quantifyProjectCreditTermEnd).format("YYYY/MM/DD")}`
      const start = dayjs(item.quantifyProjectCreditTermStart)
      const end = dayjs(item.quantifyProjectCreditTermEnd)

      const newValue = {
        id: id,
        quantifyProjectCreditTermRows: quantifyProjectCreditTermRows,
        quantifyEstimatedBaselineEmissions: quantifyEstimatedBaselineEmissions !== "" ? quantifyEstimatedBaselineEmissions : "0",
        quantifyEstimatedProjectEmissions: quantifyEstimatedProjectEmissions !== "" ? quantifyEstimatedProjectEmissions : "0",
        quantifyEstimatedLeakageEmissions: quantifyEstimatedLeakageEmissions !== "" ? quantifyEstimatedLeakageEmissions : "0",
        quantifyEstimatedReductionCredit: quantifyEstimatedReductionCredit !== "" ? quantifyEstimatedReductionCredit : "0",
        quantifyEstimatedRemoveCredit: quantifyEstimatedRemoveCredit !== "" ? quantifyEstimatedRemoveCredit : "0",
        quantifyEstimatedTotalCredit: quantifyEstimatedTotalCredit !== "" ? quantifyEstimatedTotalCredit : "0",
        quantifyProjectCreditTermStart: start,
        quantifyProjectCreditTermEnd: end,
      };
      newRows.push(newValue)
    })
    return newRows
  }

  // 11.1 商業上の機密情報を表示するテーブルに関する変数等
  const [secretInfoRows, setSecretInfoRows] = React.useState([]);
  const apiRefForSecretInfo = useGridApiRef();
  const secretInfoColumns = [
    { field: "section", headerName: "セクション" },
    { field: "info", headerName: "情報" },
    { field: "justification", headerName: "正当化" },
  ];
  const addSecretInfoRows = () => {
    const id = (secretInfoRows.length === 0) ? 1 : Math.max(...secretInfoRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const secretSectionValue = getValues("secretSection")
    const secretInfoValue = getValues("secretInfo")
    const secretJustificationValue = getValues("secretJustification")
    const newValue = {
      id: id,
      section: secretSectionValue,
      info: secretInfoValue,
      justification: secretJustificationValue,
    };
    setSecretInfoRows([...secretInfoRows, newValue]);
    // reset({
    //   secretSection: "",
    //   secretInfo: "",
    //   secretJustification: "",
    // })
    setValue("secretSection", "")
    setValue("secretInfo", "")
    setValue("secretJustification", "")
  }

  const delSecretInfoRows = () => {
    const delSecretInfoRow = apiRefForSecretInfo.current.getSelectedRows();
    if (secretInfoRows.length === 0 || delSecretInfoRow.size === 0) return;
    delSecretInfoRow.forEach(v => {
      apiRefForSecretInfo.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newSecretInfoRows = apiRefForSecretInfo.current.getSortedRows();
    if (newSecretInfoRows !== null) {
      setSecretInfoRows(Object.keys(newSecretInfoRows).length === 0 ? [] : newSecretInfoRows);
    }
  }

  const getSecretRows = () => {
    if (!apiRefForSecretInfo.current || Object.keys(apiRefForSecretInfo.current).length === 0) {
      return secretInfoRows || null
    }
    const allSecretInfoRows = apiRefForSecretInfo.current?.getSortedRows();
    return allSecretInfoRows
  }

  // select
  const theme = useTheme();
  const [option, setOption] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleJump = (n) => {
    setActiveStep(n);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // 一時保存時は各入力値の値をチェックせずに保存する
  const tmpSave = async () => {
    setProcessing(true)
    try {
      // TODO:編集前後データを比較して、変更のあったもののみをreqDataに含める
      // 変更後が空文字等になっている場合は、警告を表示する。
      const reqData = {
        _id: id,
        creator: {
          createdCompanyId: company._id,
          createdUserId: storedUserInfo?.value._id,
          createdCompanyText: getValues("createdCompanyText") || company.groupName,
          createdUserText: getValues("createdUserText") || storedUserInfo?.value.username,
        },
        projectName: getValues("projectName"),
        projectStatus: "50",
        branchId: getValues("branchId"),
        projectDesc: getValues("projectDesc") || "",
        projectCategory: projectCategory || "",
        audit: getAuditHistoryRows() || [],
        projectDesign: projectDesignCategory || "",
        actExplain: getValues("actExplain") || "",
        projectPlace: getProjectPlaceRows() || [],
        projectPlaceFile: projectPlaceFileList.length ? projectPlaceFileList : [],
        proposer: {
          company: tmpSaveProjectData.proposer.company ?? company?.groupName,
          staff: tmpSaveProjectData.proposer.staff ?? storedUserInfo?.value.username,
          role: tmpSaveProjectData.proposer.role ?? storedUserInfo?.value.role,
          address: tmpSaveProjectData.proposer.address ?? company?.address,
          email: tmpSaveProjectData.proposer.email ?? storedUserInfo?.value.email,
          tel: getValues("proposerTel") || "",
        },
        refOtherCompany: getValues("refOtherCompany") || "",
        projectOwnershipFile: tmpSaveProjectData.projectOwnershipFile.length ? tmpSaveProjectData.projectOwnershipFile : [],
        projectOwnership: getValues("projectOwnership") || "",
        projectEligibility: getValues("projectEligibility") || "",
        projectEligibilityFile: tmpSaveProjectData.projectEligibilityFile.length ? tmpSaveProjectData.projectEligibilityFile : [],
        beforeStartingProject: getValues("beforeStartingProject") || "",
        beforeStartingProjectFile: tmpSaveProjectData.beforeStartingProjectFile.length ? tmpSaveProjectData.beforeStartingProjectFile : [],
        legalCompliance: getValues("legalCompliance") || "",
        legalComplianceFile: legalComplianceFileList.length ? legalComplianceFileList : [],
        noDuplicatePublish: {
          check: duplicatePublishCheck,
          evidence: getValues("noDuplicatePublish") || "",
          file: [],
        },
        noDuplicateClaim: {
          check: duplicateClaimCheck,
          evidence: getValues("noDuplicateClaim") || "",
          file: [],
        },
        stakeholderDescription: getStakeholdersDescriptionRows() || [],
        stakeholderRegulations: getValues("stakeholderRegulations") || "",
        stakeholderRegulationsFile: stakeholderRegulationsFileList.length ? stakeholderRegulationsFileList : [],
        envRisk: getValues("envRisk") || "",
        envRiskFile: envRiskFileList.length ? envRiskFileList : [],
        fairness: getValues("fairness") || "",
        fairnessFile: fairnessFileList.length ? fairnessFileList : [],
        soundnessA: {
          soundnessACheck: soundnessACheck,
          soundnessA1: getValues("soundnessA1") || "",
          soundnessA2: getValues("soundnessA2") || "",
        },
        soundnessB: {
          soundnessBCheck: soundnessBCheck,
          soundnessB1: getValues("soundnessB1") || "",
          soundnessB2: getValues("soundnessB2") || "",
        },
        soundnessC: {
          soundnessCCheck: soundnessCCheck,
          soundnessC1: getValues("soundnessC1") || "",
          soundnessC2: getValues("soundnessC2") || "",
        },
        soundnessD: {
          soundnessDCheck: soundnessDCheck,
          soundnessD1: getValues("soundnessD1") || "",
          soundnessD2: getValues("soundnessD2") || "",
        },
        soundnessFile: soundnessFileList.length ? soundnessFileList : [],
        sdContribution: getValues("sdContribution") || "",
        projectAddInfo: getValues("projectAddInfo") || "",
        implementationStatus: getValues("implementationStatus") || "",
        deviation: getValues("deviation") || "",
        groupedProject: getValues("groupedProject") || "",
        baselineReassessment: getValues("baselineReassessment") || "",
        // projectStartDate: projectStartDate,
        projectStartDate: getProjectStartDateRows() || [],
        startDateRightfulness: getValues("startDateRightfulness") || "",
        projectCreditTerm: {
          creditTerm: getValues("creditTerm") || "",
          creditStartDate: creditStartDate,
          creditEndDate: creditEndDate,
        },
        estimatedValue: getEstimatedValue1Rows() || [],
        estimatedERR: getValues("estimatedERR") || "",
        totalYears: getValues("totalYears") || "",
        aveYearlyERR: getValues("aveYearlyERR") || "",
        programs: getMethodologyRows() || [],
        programApplicability: getValues("programApplicability") || "",
        projectBoundary: getProjectBoundaryRows() || [],
        // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
        projectBoundaryFile: projectBoundaryFileList.length ? projectBoundaryFileList : [],
        baselineScenario: getValues("baselineScenario") || "",
        additionality: getValues("additionality") || "",
        deviateProgram: getValues("deviateProgram") || "",
        baselineEmissions: getValues("baselineEmissions") || "",
        projectEmissions: getValues("projectEmissions") || "",
        projectEmissionsFile: projectEmissionsFileList.length ? projectEmissionsFileList : [],
        leakageEmissions: getValues("leakageEmissions") || "",
        nonPermanentRiskEvaluation: getValues("nonPermanentRiskEvaluation") || "",
        nonPermanentRiskReportCheck: nonPermanentRiskReportCheck,
        projectLTA: getValues("projectLTA") || "",
        updateLta: {
          check: updateLtaCheck,
          NonUpdateLtaReason: getValues("NonUpdateLtaReason") || "",
        },
        expectGhgEmissions: getValues("expectGhgEmissions") || "",
        belowLta: {
          check: ghgCreditCheck,
          NotBelowLtaReason: getValues("NotBelowLtaReason") || "",
        },
        emissions: {
          detail: getEmissionsDetailRows() || [],
          total: {
            estimatedBaselineEmissionsTotal: estimatedBaselineEmissionsTotal || "0",
            estimatedProjectEmissionsTotal: estimatedProjectEmissionsTotal || "0",
            estimatedLeakageEmissionsTotal: estimatedLeakageEmissionsTotal || "0",
            estimatedReductionCreditTotal: estimatedReductionCreditTotal || "0",
            estimatedRemoveCreditTotal: estimatedRemoveCreditTotal || "0",
            estimatedTotalCreditTotal: estimatedTotalCreditTotal || "0",
          },
        },
        availableData: getAvailableDataRows() || [],
        monitoringData: getMonitoringDataRows() || [],
        monitoringDataSource1Files: [],
        monitoringDataSource2Files: [],
        monitoringPlan: getValues("monitoringPlan") || "",
        monitoringPlanFile: monitoringPlanFileList.length ? monitoringPlanFileList : [],
        quantifyBaselineEmissions: getValues("quantifyBaselineEmissions") || "",
        quantifyProjectEmissions: getValues("quantifyProjectEmissions") || "",
        quantifyLeakageEmissions: getValues("quantifyLeakageEmissions") || "",
        quantifyNonPermanentRiskEvaluation: getValues("quantifyNonPermanentRiskEvaluation") || "",
        quantifyMonitoring: getValues("quantifyMonitoring") || "",
        references: getValues("references") || "",
        secret: getSecretRows() || [],
        otherInfo: getValues("otherInfo") || "",
      }

      const projectBoundaryFile = getValues("projectBoundaryFile") || ""
      if (projectBoundaryFile || projectBoundaryFile.length !== 0) {
        const file = await fileUploadWithConvertPng(projectBoundaryFile, "projectBoundaryFile")
        reqData.projectBoundaryFile.push(file)
      }

      const projectOwnershipFile = getValues("projectOwnershipFile") || ""
      if (projectOwnershipFile || projectOwnershipFile.length !== 0) {
        const file = await fileUploadWithConvertPng(projectOwnershipFile, "projectOwnershipFile")
        reqData.projectOwnershipFile.push(file)
      }

      const projectPlaceFile = getValues("projectPlaceFile") || ""
      if (projectPlaceFile || projectPlaceFile.length !== 0) {
        const file = await fileUploadWithConvertPng(projectPlaceFile, "projectPlaceFile")
        reqData.projectPlaceFile.push(file)
      }

      const projectEligibilityFile = getValues("projectEligibilityFile") || ""
      if (projectEligibilityFile || projectEligibilityFile.length !== 0) {
        const file = await fileUploadWithConvertPng(projectEligibilityFile, "projectEligibilityFile")
        reqData.projectEligibilityFile.push(file)
      }

      const beforeStartingProjectFile = getValues("beforeStartingProjectFile") || ""
      if (beforeStartingProjectFile || beforeStartingProjectFile.length !== 0) {
        const file = await fileUploadWithConvertPng(beforeStartingProjectFile, "beforeStartingProjectFile")
        reqData.beforeStartingProjectFile.push(file)
      }

      const legalComplianceFile = getValues("legalComplianceFile") || ""
      if (legalComplianceFile || legalComplianceFile.length !== 0) {
        const file = await fileUploadWithConvertPng(legalComplianceFile, "legalComplianceFile")
        reqData.legalComplianceFile.push(file)
      }

      const noDuplicatePublishFile = getValues("noDuplicatePublishFile") || ""
      if (noDuplicatePublishFile || noDuplicatePublishFile.length !== 0) {
        const file = await fileUploadWithConvertPng(noDuplicatePublishFile, "noDuplicatePublishFile")
        reqData.noDuplicatePublishFile.push(file)
      }

      const noDuplicateClaimFile = getValues("noDuplicateClaimFile") || ""
      if (noDuplicateClaimFile || noDuplicateClaimFile.length !== 0) {
        const file = await fileUploadWithConvertPng(noDuplicateClaimFile, "noDuplicateClaimFile")
        reqData.noDuplicateClaimFile.push(file)
      }

      const stakeholderRegulationsFile = getValues("stakeholderRegulationsFile") || ""
      if (stakeholderRegulationsFile || stakeholderRegulationsFile.length !== 0) {
        const file = await fileUploadWithConvertPng(stakeholderRegulationsFile, "stakeholderRegulationsFile")
        reqData.stakeholderRegulationsFile.push(file)
      }

      const envRiskFile = getValues("envRiskFile") || ""
      if (envRiskFile || envRiskFile.length !== 0) {
        const file = await fileUploadWithConvertPng(envRiskFile, "envRiskFile")
        reqData.envRiskFile.push(file)
      }

      const fairnessFile = getValues("fairnessFile") || ""
      if (fairnessFile || fairnessFile.length !== 0) {
        const file = await fileUploadWithConvertPng(fairnessFile, "fairnessFile")
        reqData.fairnessFile.push(file)
      }

      const soundnessFile = getValues("soundnessFile") || ""
      if (soundnessFile || soundnessFile.length !== 0) {
        const file = await fileUploadWithConvertPng(soundnessFile, "soundnessFile")
        reqData.soundnessFile.push(file)
      }

      const projectEmissionsFile = getValues("projectEmissionsFile") || ""
      if (projectEmissionsFile || projectEmissionsFile.length !== 0) {
        const file = await fileUploadWithConvertPng(projectEmissionsFile, "projectEmissionsFile")
        reqData.projectEmissionsFile.push(file)
      }

      const monitoringPlanFile = getValues("monitoringPlanFile") || ""
      if (monitoringPlanFile || monitoringPlanFile.length !== 0) {
        const file = await fileUploadWithConvertPng(monitoringPlanFile, "monitoringPlanFile")
        reqData.monitoringPlanFile.push(file)
      }

      if (monitoringDataSource1Files || monitoringDataSource1Files.length !== 0) {
        reqData.monitoringDataSource1File = await multiFileUpload(monitoringDataSource1Files, "monitoringDataSource1File")
      }

      if (monitoringDataSource2Files || monitoringDataSource2Files.length !== 0) {
        reqData.monitoringDataSource2File = await multiFileUpload(monitoringDataSource2Files, "monitoringDataSource2File")
      }

      projectApi
        .update(reqData)
        .then(async (res) => {
          // const projectId = res.project._id;
          // return sendApplyMail(
          //   data,
          //   reqData.planDocuments,
          //   reqData.additionalDocuments,
          //   projectId
          // );
        })
        .then(() => {
          setProcessing(false)
          alert("一時保存しました");
          navigate("/member/projects");
        })
        .catch((e) => {
          alert("一時保存に失敗しました。運営にお問合せください。");
          setProcessing(false)
          console.error(e);
        });
      } catch (error) {
        alert("一時保存に失敗しました。運営にお問合せください。");
        setProcessing(false)
        console.error(error);
    }
  }

  useEffect(() => {
    fetchprogramList();
    fetchProject()
  }, []);

  return (
    <div>
      <h3>プロジェクト設計書修正</h3>
      {/* 本システムは無償にて作成されているためテスト実施がされておりません */}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {/* step1 基本情報 */}
          <Step key={1}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(0)}
            >
              {steps[0].label}
            </StepLabel>
            <StepContent>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box mt={1} mb={2}>
                    <Typography>作成会社名：</Typography>
                    <Controller
                      name="createdCompanyText"
                      control={control}
                      rules={validationRules.createdCompanyText}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box mb={2}>
                    <Typography>作成者名：</Typography>
                    <Controller
                      name="createdUserText"
                      control={control}
                      rules={validationRules.createdUserText}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Typography>作成支部名：</Typography>
                    <Controller
                      name="branchId"
                      control={control}
                      rules={validationRules.branchId}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth error={fieldState.invalid}>
                          <InputLabel>作成支部名</InputLabel>
                          <Select
                            {...field}
                            input={<OutlinedInput label="作成支部名" />}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">
                              {branches.length ? "未選択" : "支部が存在しません"}
                            </MenuItem>
                            {branches.map((item) => (
                              <MenuItem
                                key={item._id}
                                value={item._id}
                                style={getStyles(item._id, option, theme)}
                              >
                                {item.branchName}
                              </MenuItem>
                            ))}
                          </Select>
                          {fieldState.invalid && (
                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Box>
                  <Box mb={2}>
                    プロジェクトID：システムにより自動決定
                  </Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Controller
                      name="projectName"
                      control={control}
                      rules={validationRules.projectName}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          label="プロジェクト名"
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {0 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step2 プロジェクト概要 */}
          <Step key={2}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(1)}
            >
              {steps[1].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>2.1 プロジェクトの概要説明</Typography>
              <Typography>プロジェクトの性質とその実施を理解できるよう、プロジェクトの概要を説明してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectDesc"
                  control={control}
                  rules={validationRules.projectDesc}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectDesc"
                      label="プロジェクトの概要説明"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.2 セクターの範囲とプロジェクトの種類</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectCategory"
                  control={control}
                  // rules={validationRules.priority}
                  render={({ field, fieldState }) => (
                    <CategoryDropdown
                      categoryName="project"
                      categoryLabel=""
                      field={field}
                      fieldState={fieldState}
                      onChange={handleCategoryChange}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.3 監査履歴</Typography>
              <Typography>
                もしあれば、記載をしてください。<br />
                ※表内に表示されているデータのみが登録対象となります。追加ボタンの押し忘れにご注意ください。
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="auditType"
                        control={control}
                        rules={validationRules.auditType}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="監査タイプ"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={10}>
                    <Box>
                      <Controller
                        name="auditHistoryTerm"
                        control={control}
                        rules={validationRules.auditHistoryTerm}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="期間"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="auditHistoryProgram"
                        control={control}
                        rules={validationRules.auditHistoryProgram}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="プログラム"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="auditHistoryName"
                        control={control}
                        rules={validationRules.auditHistoryName}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="バリデーション／検証機関名"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <Controller
                        name="auditHistoryYears"
                        control={control}
                        rules={validationRules.auditHistoryYears}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="年数"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addAuditHistoryRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delAuditHistoryRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForAuditHistory}
                  columns={auditHistoryColumns}
                  rows={auditHistoryRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {1 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step3 */}
          <Step key={3}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(2)}
            >
              {steps[2].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.1 プロジェクトデザイン</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectDesignCategory"
                  control={control}
                  // rules={validationRules.priority}
                  render={({ field, fieldState }) => (
                    <CategoryDropdown
                      categoryName="projectDesign"
                      categoryLabel=""
                      field={field}
                      fieldState={fieldState}
                      onChange={handleProjectDesignCategoryChange}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.2 プロジェクト活動の説明</Typography>
              <Typography>
                プロジェクトの活動と、それがどのようにGHG排出削減または二酸化炭素除去を達成するかを記載してください。<br />
                プロジェクト活動の実施スケジュールを記載してください。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="actExplain"
                  control={control}
                  rules={validationRules.actExplain}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="actExplain"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                      onClick={handleOpenActExplainDialog}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.3 プロジェクト所在地</Typography>
              <Typography>測地座標を含むプロジェクトの位置と地理的境界（該当する場合）を記載してください。</Typography>
              {/* <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectPlace"
                  control={control}
                  // rules={validationRules.projectPlace}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      label="プロジェクト所在地(KML)"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box> */}
              <Box sx={{display: "flex", alignItems: "center" }}>
                <Box sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={handleOpenProjectPlaceDialog}>追加</Button>
                </Box>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delProjectPlaceRows}>削除</Button>
                </Box>
              </Box>
              <DataGrid
                apiRef={apiRefForProjectPlace}
                columns={projectPlaceColumns}
                rows={projectPlaceRows}
                autoHeight
                checkboxSelection
              />
              <Box sx={{ marginTop: 1 }}>
                { projectPlaceFileList.length < 1
                  ? <FileUploadInput name="projectPlaceFile" />
                  : <AttachmentFiles fileList={projectPlaceFileList} setList={setProjectPlaceFileList} />
                }
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.4 プロジェクト提案者</Typography>
              <Typography>プロジェクト提案者の連絡先を記入してください。</Typography>
              {/* システム側から取る。
              TODO:「作成者と提案者が異なる」ボタンを作成して、修正が必要な場合はPOPUP内で編集可能とする。 */}
              <Box sx={{ marginBottom: "2rem", mt: 2 }}>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>組織名: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{tmpSaveProjectData?.proposer?.company || company?.groupName}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>担当者名: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{tmpSaveProjectData?.proposer?.staff || storedUserInfo?.value.username}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>役職: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{tmpSaveProjectData?.proposer?.role || storedUserInfo?.value.role}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>住所: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{tmpSaveProjectData?.proposer?.address || company?.address}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>Eメール: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{tmpSaveProjectData?.proposer?.email || storedUserInfo?.value.email}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>電話番号: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>
                      <Controller
                        name="proposerTel"
                        control={control}
                        rules={validationRules.proposerTel}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="ハイフン無し"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.5 プロジェクトに関与するその他の団体</Typography>
              <Typography>もしあれば、プロジェクトの開発に関与した他の団体の情報を記入してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="refOtherCompany"
                  control={control}
                  rules={validationRules.refOtherCompany}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="refOtherCompany"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.6 所有権</Typography>
              <Typography>プロジェクトのオーナーシップの証拠を入力してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectOwnership"
                  control={control}
                  rules={validationRules.projectOwnership}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectOwnership"
                      multiline
                      rows={2}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                { projectOwnershipFileList.length < 1
                  ? <FileUploadInput name="projectOwnershipFile" />
                  : <AttachmentFiles fileList={projectOwnershipFileList} setList={setProjectOwnershipFileList} />
                }
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {2 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step4 */}
          <Step key={4}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(3)}
            >
              {steps[3].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.1 プロジェクトの適格性</Typography>
              <Typography>
                プロジェクト活動がNCCCプログラムの範囲に含まれることを正当化すること。<br />
                適用される方法論がNCCCプログラムに適格であることを証明すること。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectEligibility"
                  control={control}
                  rules={validationRules.projectEligibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectEligibility"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              { projectEligibilityFileList.length < 1
                ? <FileUploadInput name="projectEligibilityFile" />
                : <AttachmentFiles fileList={projectEligibilityFileList} setList={setProjectEligibilityFileList} />
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.2 プロジェクト開始前の状況</Typography>
              <Typography>
              プロジェクト開始前の状況を記述し、その後の削減、除去、破壊を目的としたGHG排出を発生させるためにプロジェクトが実施されていないことを証明する。
              </Typography>
              {/* TODO: 画像の添付とかが必要かも */}
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="beforeStartingProject"
                  control={control}
                  rules={validationRules.beforeStartingProject}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="beforeStartingProject"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              { beforeStartingProjectFileList.length < 1
                ? <FileUploadInput name="beforeStartingProjectFile" />
                : <AttachmentFiles fileList={beforeStartingProjectFileList} setList={setBeforeStartingProjectFileList} />
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.3 法律、法令、その他の規制枠組みの遵守</Typography>
              <Typography>
              地元、地域、国のすべての関連法、法令、規制の枠組みを特定し、プロジェクトが遵守していることを証明する。
              </Typography>
              {/* TODO: 画像の添付とかが必要かも */}
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="legalCompliance"
                  control={control}
                  rules={validationRules.legalCompliance}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="legalCompliance"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              { legalComplianceFileList.length < 1
                ? <FileUploadInput name="legalComplianceFile" />
                : <AttachmentFiles fileList={legalComplianceFileList} setList={setLegalComplianceFileList} />
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.4 二重請求と他のプログラムへの参加</Typography>
              <Typography>
              プロジェクトは、他のプログラムに登録されているか、あるいはクレジットを受けたり求めたりしていますか？
              </Typography>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={duplicatePublishCheck} onChange={handleChangeDuplicatePublishCheck} value={1} />
                    }
                    label="はい"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!duplicatePublishCheck} onChange={handleChangeDuplicatePublishCheck} value={0} />
                    }
                    label="いいえ"
                  />
                </FormGroup>
              </FormControl>
              {
                duplicatePublishCheck &&
                <Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Typography>二重発行とならないことの必要な証拠を提出すること。</Typography>
                    <Controller
                      name="noDuplicatePublish"
                      control={control}
                      rules={validationRules.noDuplicatePublish}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          id="noDuplicatePublish"
                          multiline
                          rows={4}
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  { noDuplicatePublishFileList.length < 1
                    ? <FileUploadInput name="noDuplicatePublishFile" />
                    : <AttachmentFiles fileList={noDuplicatePublishFileList} setList={setNoDuplicatePublishFileList} />
                  }
                </Box>
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.5 二重請求、他の形態のクレジット、スコープ3排出量</Typography>
              <Typography>
              プロジェクト活動は、他の排出量取引プログラム、拘束力のある排出制限、または他のスコープ3プログラムに含まれますか？
              </Typography>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={duplicateClaimCheck} onChange={handleChangeDuplicateClaimCheck} value={1} />
                    }
                    label="はい"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!duplicateClaimCheck} onChange={handleChangeDuplicateClaimCheck} value={0} />
                    }
                    label="いいえ"
                  />
                </FormGroup>
              </FormControl>
              {
                duplicateClaimCheck &&
                <Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Typography>二重請求がないことを示す必要な証拠をすべて提出すること。</Typography>
                    <Controller
                      name="noDuplicateClaim"
                      control={control}
                      rules={validationRules.noDuplicateClaim}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          id="noDuplicateClaim"
                          multiline
                          rows={4}
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  { noDuplicateClaimFileList.length < 1
                    ? <FileUploadInput name="noDuplicateClaimFile" />
                    : <AttachmentFiles fileList={noDuplicateClaimFileList} setList={setNoDuplicateClaimFileList} />
                  }
                </Box>
              }
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {3 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step5 */}
          <Step key={5}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(4)}
            >
              {steps[4].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.1 ステークホルダーの参画と協議</Typography>
              <Typography>
              ステークホルダーの関与と協議に関する特別な規定がある場合は、それを記載すること。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                  <Box sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={handleOpenIsStakeholdersDescriptionDialog}>追加</Button>
                  </Box>
                  <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={delStakeholdersDescriptionRows}>削除</Button>
                  </Box>
                </Box>
                <Box mb={2}>
                  <DataGrid
                    apiRef={apiRefForStakeholdersDescription}
                    columns={stakeholdersDescriptionColumns}
                    rows={stakeholdersDescriptionRows}
                    autoHeight
                    checkboxSelection
                  />
                </Box>
                <Typography>追加の補足事項があれば記載ください。</Typography>
                <Controller
                  name="stakeholderRegulations"
                  control={control}
                  rules={validationRules.stakeholderRegulations}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="stakeholderRegulations"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              
              { stakeholderRegulationsFileList.length < 1
                ? <FileUploadInput name="stakeholderRegulationsFile" />
                : <AttachmentFiles fileList={stakeholderRegulationsFileList} setList={setStakeholderRegulationsFileList} />
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.2 ステークホルダーと環境へのリスク</Typography>
              <Typography>
              ステークホルダーや環境に対するリスクについて特別な処方がある場合は、その内容を記載すること。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="envRisk"
                  control={control}
                  rules={validationRules.envRisk}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="envRisk"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              { envRiskFileList.length < 1
                ? <FileUploadInput name="envRiskFile" />
                : <AttachmentFiles fileList={envRiskFileList} setList={setEnvRiskFileList} />
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.3 人権と公平性の尊重</Typography>
              <Typography>
              人権と公平性の尊重に関する特別な処方がある場合は、その内容を記載する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="fairness"
                  control={control}
                  rules={validationRules.fairness}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="fairness"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              { fairnessFileList.length < 1
                ? <FileUploadInput name="fairnessFile" />
                : <AttachmentFiles fileList={fairnessFileList} setList={setFairnessFileList} />
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.4 生態系の健全性</Typography>
              <Typography>
                環境に対するあらゆるリスクと、それを軽減するために講じた措置を特定し、要約する。
                {/* リスクが特定されない場合は、最初の欄に「リスクは特定されない」と記入し、2番目の欄にその理由を記入する。 */}
              </Typography>
              <Typography sx={{ mt: "1rem" }}>生物多様性と生態系への影響</Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={soundnessACheck} onChange={handleChangeSoundnessACheck} value={1} />
                    }
                    label="リスクあり"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!soundnessACheck} onChange={handleChangeSoundnessACheck} value={0} />
                    }
                    label="リスクなし"
                  />
                </FormGroup>
              </FormControl>
              {
                soundnessACheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>特定されたリスク</Typography>
                  <Controller
                    name="soundnessA1"
                    control={control}
                    rules={validationRules.soundnessA1}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="soundnessA1"
                        multiline
                        rows={2}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  {soundnessACheck ? "講じられた緩和策または予防策" : "理由"}
                </Typography>
                <Controller
                  name="soundnessA2"
                  control={control}
                  rules={validationRules.soundnessA2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundnessA2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: "1rem" }}>土壌劣化と土壌侵食</Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={soundnessBCheck} onChange={handleChangeSoundnessBCheck} value={1} />
                    }
                    label="リスクあり"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!soundnessBCheck} onChange={handleChangeSoundnessBCheck} value={0} />
                    }
                    label="リスクなし"
                  />
                </FormGroup>
              </FormControl>
              {
                soundnessBCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>特定されたリスク</Typography>
                  <Controller
                    name="soundnessB1"
                    control={control}
                    rules={validationRules.soundnessB1}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="soundnessB1"
                        multiline
                        rows={2}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  {soundnessBCheck ? "講じられた緩和策または予防策" : "理由"}
                </Typography>
                <Controller
                  name="soundnessB2"
                  control={control}
                  rules={validationRules.soundnessB2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundnessB2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: "1rem" }}>水の消費とストレス</Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={soundnessCCheck} onChange={handleChangeSoundnessCCheck} value={1} />
                    }
                    label="リスクあり"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!soundnessCCheck} onChange={handleChangeSoundnessCCheck} value={0} />
                    }
                    label="リスクなし"
                  />
                </FormGroup>
              </FormControl>
              {
                soundnessCCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>特定されたリスク</Typography>
                  <Controller
                    name="soundnessC1"
                    control={control}
                    rules={validationRules.soundnessC1}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="soundnessC1"
                        multiline
                        rows={2}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  {soundnessCCheck ? "講じられた緩和策または予防策" : "理由"}
                </Typography>
                <Controller
                  name="soundnessC2"
                  control={control}
                  rules={validationRules.soundnessC2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundnessC2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: "1rem" }}>肥料の使用</Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={soundnessDCheck} onChange={handleChangeSoundnessDCheck} value={1} />
                    }
                    label="リスクあり"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!soundnessDCheck} onChange={handleChangeSoundnessDCheck} value={0} />
                    }
                    label="リスクなし"
                  />
                </FormGroup>
              </FormControl>
              {
                soundnessDCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>特定されたリスク</Typography>
                  <Controller
                    name="soundnessD1"
                    control={control}
                    rules={validationRules.soundnessD1}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="soundnessD1"
                        multiline
                        rows={2}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  {soundnessDCheck ? "講じられた緩和策または予防策" : "理由"}
                </Typography>
                <Controller
                  name="soundnessD2"
                  control={control}
                  rules={validationRules.soundnessD2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundnessD2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography>
                  生態系の健全性に関して、説明を補足する資料があれば添付してください。
                </Typography>
                { soundnessFileList.length < 1
                  ? <FileUploadInput name="soundnessFile" />
                  : <AttachmentFiles fileList={soundnessFileList} setList={setSoundnessFileList} />
                }
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.5 持続可能な開発への貢献</Typography>
              <Typography>
                持続可能な開発（SD）に貢献するプロジェクト活動の概要（実施した技術／対策、活動場所など）を含む簡単な説明を記入する
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="sdContribution"
                  control={control}
                  rules={validationRules.sdContribution}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="sdContribution"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.6 プロジェクトに関連する追加情報</Typography>
              <Typography>
              プロジェクトに関連する追加情報があれば記載する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo"
                  control={control}
                  rules={validationRules.projectAddInfo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {4 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step6 */}
          <Step key={6}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(5)}
            >
              {steps[5].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.1 プロジェクト活動の実施状況</Typography>
              <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Controller
                  name="implementationStatus"
                  control={control}
                  rules={validationRules.implementationStatus}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="implementationStatus"
                      multiline
                      rows={5}
                      maxRows={10}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.2 逸脱</Typography>
              <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Controller
                  name="deviation"
                  control={control}
                  rules={validationRules.deviation}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="deviation"
                      multiline
                      rows={5}
                      maxRows={10}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.3 グループ化されたプロジェクト</Typography>
              <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Controller
                  name="groupedProject"
                  control={control}
                  rules={validationRules.groupedProject}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="groupedProject"
                      multiline
                      rows={5}
                      maxRows={10}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.4 ベースライン再評価</Typography>
              <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Controller
                  name="baselineReassessment"
                  control={control}
                  rules={validationRules.baselineReassessment}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="baselineReassessment"
                      multiline
                      rows={5}
                      maxRows={10}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {6 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step7 */}
          <Step key={7}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(6)}
            >
              {steps[6].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.1 プロジェクト開始日</Typography>
              <Typography>
              日、月、年を明記し、プロジェクト開始日の正当性を示すこと。
              </Typography>
              <Box sx={{display: "flex", alignItems: "center" }}>
                <Box sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={handleOpenIsProjectStartDateDialog}>追加</Button>
                </Box>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delProjectStartDateRows}>削除</Button>
                </Box>
              </Box>
              <DataGrid
                apiRef={apiRefForProjectStartDate}
                columns={projectStartDateColumns}
                rows={projectStartDateRows}
                autoHeight
                checkboxSelection
              />
              <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Typography>
                  妥当性<br />
                  プロジェクトの開始日が NCCC プログラムの要件にどのように適合するかを説明すること。
                </Typography>
                <Controller
                  name="startDateRightfulness"
                  control={control}
                  rules={validationRules.startDateRightfulness}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="startDateRightfulness"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.2 プロジェクトのクレジット期間</Typography>
              <Typography>
              開始日、終了日、合計年数について、日、月、年を指定することにより、プロジェクトのクレジット期間を示す。
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>クレジット期間： </Typography>
                <Controller
                  name="creditTerm"
                  control={control}
                  rules={validationRules.creditTerm}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="creditTerm"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>年</Typography>
              </Box>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ mr: 1 }}>初回または固定クレジット期間の開始日と終了日</Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DatePicker
                      id="creditStartDate"
                      // label="プロジェクト開始日"
                      value={creditStartDate}
                      onChange={handleChangeCreditStartDate}
                      inputFormat="dd/MM/yyyy"
                      mask="__/__/____"
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                    <DatePicker
                      id="creditEndDate"
                      // label="プロジェクト開始日"
                      value={creditEndDate}
                      onChange={handleChangeCreditEndDate}
                      inputFormat="dd/MM/yyyy"
                      mask="__/__/____"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </Box>
              </LocalizationProvider>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.3 プロジェクト規模とGHG排出削減量または除去量の推定値</Typography>
              <Typography>
              初回（更新可能な場合）または固定クレジット期間について記入する
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={3}>
                    <Box sx={{ marginBottom: "2rem" }}>
                      <Typography sx={{ mr: 1 }}>ラベル</Typography>
                      <Controller
                        name="projectLabelSelect"
                        control={control}
                        rules={validationRules.projectSelect}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth error={fieldState.invalid}>
                            <InputLabel>ラベル</InputLabel>
                            <Select
                              {...field}
                              input={<OutlinedInput label="ラベル" />}
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="">
                                {projectStartDateRows.length ? "未選択" : "選択可能なラベルが存在しません"}
                              </MenuItem>
                              {projectStartDateRows.map((data) => (
                                <MenuItem
                                  key={data._id}
                                  value={data.label}
                                  style={getStyles(data._id, option, theme)}
                                >
                                  {data.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState.invalid && (
                              <FormHelperText>{fieldState.error?.message}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                      <Box sx={{ marginBottom: "1rem" }}>
                        <Typography sx={{ mr: 1 }}>クレジット期間の暦年</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <DatePicker
                            id="creditTimeStart"
                            // label="プロジェクト開始日"
                            value={creditTimeStart}
                            onChange={handleChangeCreditTimeStart}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                          <DatePicker
                            id="creditTimeEnd"
                            // label="プロジェクト開始日"
                            value={creditTimeEnd}
                            onChange={handleChangeCreditTimeEnd}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={11} lg={4}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>GHG排出削減量または除去量の推定値（tCO2e）</Typography>
                      <Controller
                        name="estimatedValue1"
                        control={control}
                        rules={validationRules.estimatedValue1}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={1} lg={1}>
                    <Box sx={{ marginTop: "2rem" }}>
                      <Button
                        variant="contained"
                        onClick={estimatedValue1CurrentId === 0 ? addEstimatedValue1Rows : changeEstimatedValue1Rows}
                      >
                        {estimatedValue1CurrentId === 0 ? "追加" : "更新"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delEstimatedValue1Rows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForEstimatedValue1}
                  columns={estimatedValue1Columns}
                  rows={estimatedValue1Rows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>初回または固定クレジット期間中の推定ERR： </Typography>
                <Controller
                  name="estimatedERR"
                  control={control}
                  rules={validationRules.estimatedERR}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="estimatedERR"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>合計年数： </Typography>
                <Controller
                  name="totalYears"
                  control={control}
                  rules={validationRules.totalYears}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="totalYears"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>年</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>平均年間ERR： </Typography>
                <Controller
                  name="aveYearlyERR"
                  control={control}
                  rules={validationRules.aveYearlyERR}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="aveYearlyERR"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {5 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step7 */}
          <Step key={8}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(7)}
            >
              {steps[7].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.1 方法論のタイトルと参考文献</Typography>
              <Typography>
              プロジェクトに適用された方法論、ツール、モジュール（該当する場合）について、以下の情報のタイトル、参照先、バージョン番号を記入すること。
              </Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={methodologyCheck} onChange={handleChangeMethodologyCheck} />
                    }
                    label="NCCCの方法論以外を使用する"
                  />
                </FormGroup>
              </FormControl>
              {
                methodologyCheck
                ? <Box sx={{ mt: 2, mb: 3 }}>
                  <Grid container spacing={4}>
                    <Grid xs={12} lg={2}>
                      <Box>
                        <Controller
                          name="methodologyType"
                          control={control}
                          // rules={validationRules.methodologyType}
                          render={({ field, fieldState }) => (
                            <CategoryDropdown
                              categoryName="methodologyType"
                              categoryLabel="タイプ"
                              field={field}
                              fieldState={fieldState}
                              onChange={handleMethodologyTypeChange}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={4}>
                      <Box>
                        <Controller
                          name="methodologyRefId"
                          control={control}
                          rules={validationRules.methodologyRefId}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="参照ID"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={7}>
                      <Box>
                        <Controller
                          name="methodologyTitle"
                          control={control}
                          rules={validationRules.methodologyTitle}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="タイトル"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={2}>
                      <Box>
                        <Controller
                          name="methodologyVersion"
                          control={control}
                          rules={validationRules.methodologyVersion}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="Version"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={1}>
                      <Box>
                        <Button variant="contained" onClick={addMethodologyRows}>追加</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                : <Box sx={{ marginBottom: "2rem" }}>
                  <Grid container>
                    <Grid xs={12} lg={11}>
                      <Controller
                        name="program"
                        control={control}
                        // rules={validationRules.program}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth error={fieldState.invalid}>
                            <InputLabel>適応するプログラム*</InputLabel>
                            <Select
                              {...field}
                              input={<OutlinedInput label="適応するプログラム" />}
                              MenuProps={MenuProps}
                            >
                              {programs.map((item) => (
                                <MenuItem
                                  key={item._id}
                                  value={item._id}
                                  style={getStyles(item._id, option, theme)}
                                >
                                  {item.programName}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState.invalid && (
                              <FormHelperText>{fieldState.error?.message}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid xs={12} lg={1}>
                      <Box sx={{ ml: 2 }}>
                        <Button variant="contained" onClick={addNcccMethodologyRows}>追加</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={delMethodologyRows}>削除</Button>
                  </Box>
                <DataGrid
                  apiRef={apiRefForMethodology}
                  columns={methodologyColumns}
                  rows={methodologyRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.2 方法論の適用性</Typography>
              <Typography>
              プロジェクトの活動が、プロジェクトが適用する方法論、ツール、モジュール（該当する場合）の各適用条件をどのように満たしているかを実証し、正当化すること。各適用条件を個別に取り上げてください。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="programApplicability"
                  control={control}
                  rules={validationRules.programApplicability}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="programApplicability"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.3 プロジェクト境界</Typography>
              <Typography>
              プロジェクトのバウンダリーを定義し、プロジェクト及びベースラインシナリオに関連するGHG発生源、吸収源、貯留層を特定する（該当する場合はリークも含む）。
              {/* 必要に応じて行を追加する。 */}
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="baselineOrSource"
                        control={control}
                        rules={validationRules.baselineOrSource}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="ベースライン／ソース"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="gas"
                        control={control}
                        // rules={validationRules.gas}
                        render={({ field, fieldState }) => (
                          <CategoryDropdown
                            categoryName="gas"
                            categoryLabel="Gas"
                            field={field}
                            fieldState={fieldState}
                            onChange={handleGasCategoryChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="gasInclude"
                        control={control}
                        rules={validationRules.gasInclude}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="含まれる？"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                    {/* <Box>
                      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox checked={methodologyCheck} onChange={handleChangeMethodologyCheck} />
                            }
                            label="含まれる?"
                          />
                        </FormGroup>
                      </FormControl>
                    </Box> */}
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="boundaryExplain"
                        control={control}
                        rules={validationRules.boundaryExplain}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="正当性／説明"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button
                        variant="contained"
                        onClick={projectBoundaryCurrentId === 0 ? addProjectBoundaryRows : changeProjectBoundaryRows}
                      >
                        {projectBoundaryCurrentId === 0 ? "追加" : "更新"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delProjectBoundaryRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForProjectBoundary}
                  columns={projectBoundaryColumns}
                  rows={projectBoundaryRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  上表について追加の説明や情報があれば、それを記載する。
                </Typography>
                <Controller
                  name="boundaryExtraExplain"
                  control={control}
                  rules={validationRules.boundaryExtraExplain}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="boundaryExtraExplain"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography>
              上記プロジェクト活動の説明に記載された説明に基づき、プロジェクト活動の一環として行われる様々な設置または管理活動の物理的位置を明確に示す、プロジェクト境界の図または地図を提出する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                {projectBoundaryFileList.length < 1
                  ? <FileUploadInput name="projectBoundaryFile" />
                  : <AttachmentFiles fileList={projectBoundaryFileList} setList={setProjectBoundaryFileList} />
                }
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.4 ベースライン・シナリオ</Typography>
              <Typography>
              適用される方法論及び関連するツールに定められた手順に従って、ベースラインシナリオを特定し、正当化する。適用される方法論の手順が複数のステップを含む場合は、各ステップの適用方法を記述し、各ステップの結果を明確に文書化する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="baselineScenario"
                  control={control}
                  rules={validationRules.baselineScenario}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="baselineScenario"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.5 追加性</Typography>
              <Typography>
              適用された方法論および関連するツールに従い、プロジェクトの追加性を実証し、評価する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="additionality"
                  control={control}
                  rules={validationRules.additionality}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="additionality"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.6 方法論の逸脱</Typography>
              <Typography>
                適用された方法論の逸脱（過去の逸脱を含む）について説明し、その正当性を示すこと。以下を実証する証拠を含めること：<br />
                •	この乖離は、GHG排出削減・除去の定量化の保守性に悪影響を与えない。<br />
                •	この逸脱は、モニタリングまたは測定の基準と手順のみに関連し、方法論の他のいかなる部分にも関連しない。<br />
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="deviateProgram"
                  control={control}
                  rules={validationRules.deviateProgram}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="deviateProgram"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {6 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step8 */}
          <Step key={9}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(8)}
            >
              {steps[8].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>8.1 ベースライン排出量</Typography>
              <Typography>
                ベースライン排出量及び/又は炭素蓄積量の変化の定量化の手順を、適用された方法論に従って記述する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Box
                  sx={{
                    marginTop: "1rem",
                    padding: 2,
                    border: "1px solid",
                    borderColor: "#d3d3d3",
                    borderRadius: "6px",
                    maxHeight: "500px",
                    overflow: "auto",
                  }}
                  onClick={handleOpenBaselineEmissionsDialog}
                >
                  {transNewlineCode(baselineEmissions) ?? "未記載"}
                </Box>
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>8.2 プロジェクト排出量</Typography>
              <Typography>
                適用される方法論に従って、プロジェクトの排出量及び／又は炭素蓄積量の変化を定量化する手順を記述する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Box
                  sx={{
                    marginTop: "1rem",
                    padding: 2,
                    border: "1px solid",
                    borderColor: "#d3d3d3",
                    borderRadius: "6px",
                    maxHeight: "500px",
                    overflow: "auto",
                  }}
                  onClick={handleOpenProjectEmissionsDialog}
                >
                  {transNewlineCode(projectEmissions) ?? "未記載"}
                </Box>
              </Box>
              
              {projectEmissionsFileList.length < 1
                ? <FileUploadInput name="projectEmissionsFile" />
                : <AttachmentFiles fileList={projectEmissionsFileList} setList={setProjectEmissionsFileList} />
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>8.3 リーケージ排出量</Typography>
              <Typography>
                適用される方法論に従って、漏洩排出量を定量化する手順を記述する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Box
                  sx={{
                    marginTop: "1rem",
                    padding: 2,
                    border: "1px solid",
                    borderColor: "#d3d3d3",
                    borderRadius: "6px",
                    maxHeight: "500px",
                    overflow: "auto",
                  }}
                  onClick={handleOpenLeakageEmissionsDialog}
                >
                  {transNewlineCode(leakageEmissions) ?? "未記載"}
                </Box>
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>8.4 GHG排出削減量と二酸化炭素除去量の推定値</Typography>
              <Typography>
                推定される温室効果ガス排出削減量（削減量）と二酸化炭素除去量（除去量）の定量化の手順を記述する。すべての関連式を含む。
              </Typography>
              <Typography>
                以下の、要求された情報を提供してください。
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>非永続的リスク評価(%)： </Typography>
                <Controller
                  name="nonPermanentRiskEvaluation"
                  control={control}
                  rules={validationRules.nonPermanentRiskEvaluation}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="nonPermanentRiskEvaluation"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>％</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>非永続的リスク報告書は付録または別文書として添付されていますか？： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={nonPermanentRiskReportCheck} onChange={handleChangeNonPermanentRiskReportCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!nonPermanentRiskReportCheck} onChange={handleChangeNonPermanentRiskReportCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Typography sx={{ mr: 1 }}>伐採を伴うARR(植林、再植林、緑化)およびIFM(改善された森林管理)プロジェクトについては、長期平均(LTA)をtCO2e単位で記載する： </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                <Controller
                  name="projectLTA"
                  control={control}
                  rules={validationRules.projectLTA}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="projectLTA"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ ml: 1 }}>tCO2e</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>該当する場合、モニタリングデータに基づいてLTAを更新したか： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={updateLtaCheck} onChange={handleChangeUpdateLtaCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!updateLtaCheck} onChange={handleChangeUpdateLtaCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              {
                !updateLtaCheck &&
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography sx={{ mr: 1 }}>モニタリングデータに基づいて更新しなかった理由： </Typography>
                  <Controller
                    name="NonUpdateLtaReason"
                    control={control}
                    rules={validationRules.NonUpdateLtaReason}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        multiline
                        rows={3}
                        id="NonUpdateLtaReason"
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Typography sx={{ mr: 1 }}>現在までに期待される温室効果ガス排出総量をtCO2e単位で明記する： </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                <Controller
                  name="expectGhgEmissions"
                  control={control}
                  rules={validationRules.expectGhgEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="expectGhgEmissions"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ ml: 1 }}>tCO2e</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>発行されたGHGクレジットの数がLTAを下回っているか： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={ghgCreditCheck} onChange={handleChangeGhgCreditCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!ghgCreditCheck} onChange={handleChangeGhgCreditCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              {
                !ghgCreditCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ mr: 1 }}>発行されたGHGクレジットの数がLTAを下回らない理由： </Typography>
                  <Controller
                    name="NotBelowLtaReason"
                    control={control}
                    rules={validationRules.NotBelowLtaReason}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        multiline
                        rows={3}
                        id="NotBelowLtaReason"
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Typography>
                プロジェクトのクレジット期間について、記入してください。
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                  <Box sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={handleOpenProjectCreditTermDialog}>追加</Button>
                  </Box>
                  <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={delProjectCreditTermRows}>削除</Button>
                  </Box>
                </Box>
                <DataGrid
                  apiRef={apiRefForProjectCreditTerm}
                  columns={projectCreditTermColumns}
                  rows={projectCreditTermRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ marginBottom: "2rem", mt: 2 }}>
                <Typography sx={{ mr: 1 }}>合計</Typography>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定ベースライン排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedBaselineEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定プロジェクト排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedProjectEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定リーケージ排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedLeakageEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定削減クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedReductionCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定除去クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedRemoveCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定合計クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedTotalCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {7 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step9 モニタリング */}
          <Step key={10}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(9)}
            >
              {steps[9].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>9.1 検証時に利用可能なデータとパラメータ</Typography>
              <Typography>
              検証時（バリデーション時）に決定された、または入手可能で、プロジェクトのクレジット対象期間を通じて固定されているすべてのデータおよびパラメータについて、以下の表に追加する。
              </Typography>
              <Box sx={{display: "flex", alignItems: "center" }}>
                <Box sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={handleOpenMonitoringDialog1}>追加</Button>
                </Box>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delMonitoring1Rows}>削除</Button>
                </Box>
              </Box>
              <DataGrid
                apiRef={apiRefForMonitoring1}
                columns={monitoring1Columns}
                rows={monitoring1Rows}
                autoHeight
                checkboxSelection
              />
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>9.2 モニタリングされるデータとパラメータ</Typography>
              <Typography>
              プロジェクトのクレジット対象期間中にモニタリングされるすべてのデータとパラメータについて、以下の表に記入する（各データ／パラメータについて、必要に応じて表をコピーする）。提供された値は、プロジェクトのクレジット対象期間における削減量と除去量の推定値を定量化するために使用されます。
              </Typography>
              <Box sx={{display: "flex", alignItems: "center" }}>
                <Box sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={handleOpenMonitoringDialog2}>追加</Button>
                </Box>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delMonitoring2Rows}>削除</Button>
                </Box>
              </Box>
              <DataGrid
                apiRef={apiRefForMonitoring2}
                columns={monitoring2Columns}
                rows={monitoring2Rows}
                autoHeight
                checkboxSelection
              />
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>9.3 モニタリング計画</Typography>
              <Typography>
                上記セクション9.2（モニタリングされるデータ及びパラメータ）に記載されたモニタリングされるデータ及びパラメータの取得、記録、集計及び分析のためのプロセス及びスケジュールを記載すること。以下の詳細を含む：<br />
                •	モニタリングされたデータ及びパラメータの測定、記録、保存、集計、照合及び報告の方法。関連する場合、モニタリング機器の校正手順を含む。<br />
                •	モニタリング活動を実施する職員の組織構造、責任、能力。<br />
                •	内部監査およびQA/QCの手順。<br />
                •	検証されたモニタリング計画に対する不適合の処理手順。<br />
                •	目標精度レベル、サンプルサイズ、サンプル採取場所、層別化、測定頻度、QA/QC 手順など、使用されるサンプリング手法。<br />
                適切な場合には、GHGデータ収集・管理システムを表示するための線図を含める。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="monitoringPlan"
                  control={control}
                  rules={validationRules.monitoringPlan}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="monitoringPlan"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              {monitoringPlanFileList.length < 1
                ? <FileUploadInput name="monitoringPlanFile" />
                : <AttachmentFiles fileList={monitoringPlanFileList} setList={setMonitoringPlanFileList} />
              }
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {8 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step10 GHG排出削減と除去の定量化 */}
          <Step key={11}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(10)}
            >
              {steps[10].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>10.1 ベースライン排出量</Typography>
              <FormulaInputForm
                name="quantifyBaselineEmissions"
                contents={quantifyBaselineEmissions}
                onClick={cluster["quantifyBaselineEmissions"].handleOpen}
                isOpen={isOpenQuantifyBaselineEmissionsDialog}
              />
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>10.2 プロジェクト排出量</Typography>
              <FormulaInputForm
                name="quantifyProjectEmissions"
                contents={quantifyProjectEmissions}
                onClick={cluster["quantifyProjectEmissions"].handleOpen}
                isOpen={isOpenQuantifyProjectEmissionsDialog}
              />
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>10.3 リーケージ排出量</Typography>
              <FormulaInputForm
                name="quantifyLeakageEmissions"
                contents={quantifyLeakageEmissions}
                onClick={cluster["quantifyLeakageEmissions"].handleOpen}
                isOpen={isOpenQuantifyLeakageEmissionsDialog}
              />
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>10.4 GHG排出削減量と二酸化炭素除去量</Typography>
              <Typography>
                以下の、要求された情報を提供してください。
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>非永続的リスク評価(%)： </Typography>
                <Controller
                  name="quantifyNonPermanentRiskEvaluation"
                  control={control}
                  rules={validationRules.quantifyNonPermanentRiskEvaluation}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="quantifyNonPermanentRiskEvaluation"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>％</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>非永続的リスク報告書は付録または別文書として添付されていますか？： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={quantifyNonPermanentRiskReportCheck} onChange={handleChangeQuantifyNonPermanentRiskReportCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!quantifyNonPermanentRiskReportCheck} onChange={handleChangeQuantifyNonPermanentRiskReportCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Typography sx={{ mr: 1 }}>伐採を伴うARR(植林、再植林、緑化)およびIFM(改善された森林管理)プロジェクトについては、長期平均(LTA)をtCO2e単位で記載する： </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                <Controller
                  name="quantifyProjectLTA"
                  control={control}
                  rules={validationRules.quantifyProjectLTA}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="quantifyProjectLTA"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ ml: 1 }}>tCO2e</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>該当する場合、モニタリングデータに基づいてLTAを更新したか： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={quantifyUpdateLtaCheck} onChange={handleChangeQuantifyUpdateLtaCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!quantifyUpdateLtaCheck} onChange={handleChangeQuantifyUpdateLtaCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              {
                !quantifyUpdateLtaCheck &&
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography sx={{ mr: 1 }}>モニタリングデータに基づいて更新しなかった理由： </Typography>
                  <Controller
                    name="quantifyquantifyNonUpdateLtaReason"
                    control={control}
                    rules={validationRules.quantifyNonUpdateLtaReason}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        multiline
                        rows={3}
                        id="quantifyNonUpdateLtaReason"
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Typography sx={{ mr: 1 }}>現在までに期待される温室効果ガス排出総量をtCO2e単位で明記する： </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                <Controller
                  name="quantifyExpectGhgEmissions"
                  control={control}
                  rules={validationRules.quantifyExpectGhgEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="quantifyExpectGhgEmissions"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ ml: 1 }}>tCO2e</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>発行されたGHGクレジットの数がLTAを下回っているか： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={quantifyGhgCreditCheck} onChange={handleChangeQuantifyGhgCreditCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!quantifyGhgCreditCheck} onChange={handleChangeQuantifyGhgCreditCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              {
                !quantifyGhgCreditCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ mr: 1 }}>発行されたGHGクレジットの数がLTAを下回らない理由： </Typography>
                  <Controller
                    name="quantifyquantifyNotBelowLtaReason"
                    control={control}
                    rules={validationRules.quantifyNotBelowLtaReason}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        multiline
                        rows={3}
                        id="quantifyNotBelowLtaReason"
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Typography>
                プロジェクトのクレジット期間について、記入してください。
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Box sx={{display: "flex", alignItems: "center" }}>
                  <Box sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={handleOpenQuantifyProjectCreditTermDialog}>追加</Button>
                  </Box>
                  <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={delQuantifyProjectCreditTermRows}>削除</Button>
                  </Box>
                </Box>
                <DataGrid
                  apiRef={apiRefForQuantifyProjectCreditTerm}
                  columns={quantifyProjectCreditTermColumns}
                  rows={quantifyProjectCreditTermRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ marginBottom: "2rem", mt: 2 }}>
                <Typography sx={{ mr: 1 }}>合計</Typography>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定ベースライン排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{quantifyEstimatedBaselineEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定プロジェクト排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{quantifyEstimatedProjectEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定リーケージ排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{quantifyEstimatedLeakageEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定削減クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{quantifyEstimatedReductionCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定除去クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{quantifyEstimatedRemoveCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定合計クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{quantifyEstimatedTotalCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {7 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step10 */}
          <Step key={12}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(11)}
            >
              {steps[11].label}
            </StepLabel>
            <StepContent>
              <Typography>参考文献がある場合は、そのリストを提出してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="references"
                  control={control}
                  rules={validationRules.references}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="references"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {9 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step11 */}
          <Step key={13}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(12)}
            >
              {steps[12].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>11.1 商業上の機密情報</Typography>
              <Typography>プロジェクトの説明文に含まれる商業上機微な情報な情報のうち、一般公開版では除外するものについて説明してください。</Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="secretSection"
                        control={control}
                        rules={validationRules.secretSection}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="セクション"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <Controller
                        name="secretInfo"
                        control={control}
                        rules={validationRules.secretInfo}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="情報"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="secretJustification"
                        control={control}
                        rules={validationRules.secretJustification}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="正当化"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addSecretInfoRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delSecretInfoRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForSecretInfo}
                  columns={secretInfoColumns}
                  rows={secretInfoRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>11.2 その他・補足事項</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="otherInfo"
                  control={control}
                  rules={validationRules.otherInfo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      id="otherInfo"
                      label="その他・補足事項等"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={onSubmit}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    申請
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
              {/*

              
              <h4>プロジェクトの適格性</h4>
              <div>所有権</div>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="branchId"
                  control={control}
                  rules={validationRules.branchId}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth error={fieldState.invalid}>
                      <InputLabel>支部名*</InputLabel>
                      <Select
                        {...field}
                        input={<OutlinedInput label="支部名" />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          {branches.length ? "未選択" : "支部が存在しません"}
                        </MenuItem>
                        {branches.map((item) => (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                            style={getStyles(item._id, option, theme)}
                          >
                            {item.branchName}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.invalid && (
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
              <Typography mb={2}>実施期間*</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="startDate"
                  control={control}
                  rules={validationRules.startDate}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                        <DatePicker
                          label="開始日"
                          views={["year", "month", "day"]}
                          format="YYYY/MM/DD"
                          onChange={(event) => {
                            onChange(event);
                          }}
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            display: "block",
                            color: "#f44336",
                            margin: "3px 14px 0",
                          }}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="endDate"
                  control={control}
                  rules={validationRules.endDate}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                        <DatePicker
                          label="終了日"
                          views={["year", "month", "day"]}
                          format="YYYY/MM/DD"
                          onChange={(event) => {
                            onChange(event);
                          }}
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            display: "block",
                            color: "#f44336",
                            margin: "3px 14px 0",
                          }}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Grid>
            <Grid xs={12} lg={4}>
              <Typography mb={2}>プロジェクト情報</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="programId"
                  control={control}
                  rules={validationRules.programId}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth error={fieldState.invalid}>
                      <InputLabel>適応するプログラム*</InputLabel>
                      <Select
                        {...field}
                        input={<OutlinedInput label="適応するプログラム" />}
                        MenuProps={MenuProps}
                      >
                        {programs.map((item) => (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                            style={getStyles(item._id, option, theme)}
                          >
                            {item.programName}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.invalid && (
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="suitabilityReason"
                  control={control}
                  // rules={validationRules.suitabilityReason}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="suitabilityReason"
                      label="プログラム適合理由"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid xs={12} lg={4}>
              <Typography mb={2}>プロジェクト見積書類</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="planDocuments"
                  control={control}
                  rules={validationRules.planDocuments}
                  render={({ field, fieldState }) => (
                    <MuiFileInput
                      type="file"
                      fullWidth
                      {...field}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                      }
                    />
                  )}
                />
              </Box>
              <Typography mb={2}>追加書類（契約書、権利書等）</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="additionalDocuments"
                  control={control}
                  rules={validationRules.additionalDocuments}
                  render={({ field, fieldState }) => (
                    <MuiFileInput
                      type="file"
                      fullWidth
                      {...field}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                      }
                    />
                  )}
                />
              </Box>
              <Button
                // disabled={!formState.isDirty || !formState.isValid}
                variant="contained"
                fullWidth
                sx={{ color: "#fff" }}
                type="submit"
              >
                申請
              </Button>
            </Grid>
          </Grid> */}
        </Stepper>
        {/* 3.2 プロジェクト活動の説明 追加・編集ダイアログ */}
        <Dialog
          maxWidth="lg"
          open={isOpenActExplainDialog}
          onClose={handleCloseActExplainDialog}
        >
          <DialogTitle>プロジェクト活動の説明</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              プロジェクトの活動と、それがどのようにGHG排出削減または二酸化炭素除去を達成するかを記載してください。<br />
                プロジェクト活動の実施スケジュールを記載してください。
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="actExplain"
                  control={control}
                  rules={validationRules.actExplain}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="actExplain"
                      multiline
                      rows={25}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseActExplainDialog}>戻る</Button>
            {/* <Button
              onClick={addProjectStartDateRows}
              // disabled={true}
            >
              追加
            </Button> */}
          </DialogActions>
        </Dialog>
        {/* 3.3 プロジェクト所在地 追加・編集ダイアログ */}
        <Dialog
          maxWidth="lg"
          open={isOpenProjectPlaceDialog}
          onClose={handleCloseProjectPlaceDialog}
        >
          <DialogTitle>プロジェクト所在地</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              測地座標を含むプロジェクトの位置と地理的境界（該当する場合）を記載してください。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>事業地</Typography>
                    <Controller
                    name="businessLocation"
                    control={control}
                    rules={validationRules.businessLocation}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        label=""
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>地区</Typography>
                    <Controller
                    name="district"
                    control={control}
                    rules={validationRules.district}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        label=""
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>地理的位置(GPS 緯度, 軽度)</Typography>
                    <Controller
                    name="geoLocation"
                    control={control}
                    rules={validationRules.geoLocation}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        label=""
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>地籍</Typography>
                    <Controller
                    name="cadastral"
                    control={control}
                    rules={validationRules.cadastral}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        label=""
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>影響を受けた面積(ha)</Typography>
                    <Controller
                    name="affectedArea"
                    control={control}
                    rules={validationRules.affectedArea}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        label=""
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>植生面積(ha)</Typography>
                    <Controller
                    name="treePlantArea"
                    control={control}
                    rules={validationRules.treePlantArea}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        label=""
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseProjectPlaceDialog}>戻る</Button>
            <Button
              onClick={currentId === 0 ? addProjectPlaceRows : changeProjectPlaceRows}
              // disabled={true}
            >
              {currentId === 0 ? "追加" : "更新"}
            </Button>
          </DialogActions>
        </Dialog>
        {/* 6.1 プロジェクト開始日 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog
          maxWidth="lg"
          open={isOpenProjectStartDateDialog}
          onClose={handleCloseIsProjectStartDateDialog}
        >
          <DialogTitle>プロジェクト開始日</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              プロジェクトの開始日を入力してください。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>ラベル</Typography>
                      <Tooltip placement="top-start" title="プロジェクトを細分化したときに各項目が判別できるようラベルを付けてください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="projectLabel"
                      control={control}
                      rules={validationRules.projectLabel}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                      <Typography sx={{ mr: 1 }}>プロジェクト開始日</Typography>
                      <DatePicker
                        id="projectStartDate"
                        // label="プロジェクト開始日"
                        value={projectStartDate}
                        onChange={handleChangeProjectStartDate}
                        inputFormat="dd/MM/yyyy"
                        mask="__/__/____"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseIsProjectStartDateDialog}>戻る</Button>
            <Button
              onClick={addProjectStartDateRows}
              // disabled={true}
            >
              追加
            </Button>
          </DialogActions>
        </Dialog>
        {/* 5.1 ステークホルダーの参画と協議 追加・編集ダイアログ */}
        <Dialog
          maxWidth="lg"
          open={isOpenStakeholdersDescriptionDialog}
          onClose={handleCloseIsStakeholdersDescriptionDialog}
        >
          <DialogTitle>ステークホルダーの参画と協議</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              ステークホルダーの関与と協議に関する特別な規定がある場合は、それを記載すること。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>項目名</Typography>
                      {/* <Tooltip placement="top-start" title="プロジェクトを細分化したときに各項目が判別できるようラベルを付けてください">
                        <HelpOutlineIcon />
                      </Tooltip> */}
                    </Box>
                    <Controller
                      name="stakeholderDescriptionItem"
                      control={control}
                      rules={validationRules.stakeholderDescriptionItem}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>内容</Typography>
                      {/* <Tooltip placement="top-start" title="プロジェクトを細分化したときに各項目が判別できるようラベルを付けてください">
                        <HelpOutlineIcon />
                      </Tooltip> */}
                    </Box>
                    <Controller
                      name="stakeholderDescriptionContents"
                      control={control}
                      rules={validationRules.stakeholderDescriptionContents}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={5}
                          maxRows={15}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseIsStakeholdersDescriptionDialog}>戻る</Button>
            <Button
              onClick={
                stakeholdersDescriptionCurrentId === 0
                ? addStakeholdersDescriptionRows
                : changeStakeholdersDescriptionRows
              }
            >
              {stakeholdersDescriptionCurrentId === 0 ? "追加" : "更新"}
            </Button>
          </DialogActions>
        </Dialog>
        {/* 9.1 検証時に利用可能なデータとパラメータ 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog
          maxWidth="lg"
          open={isOpenMonitoringDialog1}
          onClose={handleCloseMonitoringDialog1}
        >
          <DialogTitle>検証時に利用可能なデータとパラメータ</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              検証時（バリデーション時）に決定された、または入手可能で、プロジェクトのクレジット対象期間を通じて固定されているすべてのデータおよびパラメータについて入力してください。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>データ／パラメータ</Typography>
                      <Controller
                      name="monitoringDataOrParam1"
                      control={control}
                      rules={validationRules.monitoringDataOrParam1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ単位</Typography>
                      <Tooltip placement="top-start" title="単位を示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataUnit1"
                      control={control}
                      rules={validationRules.monitoringDataUnit1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ内容</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータの簡単な説明を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataContents1"
                      control={control}
                      rules={validationRules.monitoringDataContents1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データソース</Typography>
                      <Tooltip placement="top-start" title="データソースを示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataSource1"
                      control={control}
                      rules={validationRules.monitoringDataSource1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginLeft: "2rem" }}>
                    <Typography>必要があればデータソースに関連するファイルを添付してください。</Typography>
                    <FileUploadInput name="monitoringDataSource1File" />
                    {monitoringPlanFileList.length < 1
                      ? <FileUploadInput name="monitoringPlanFile" />
                      : <AttachmentFiles fileList={monitoringPlanFileList} setList={setMonitoringPlanFileList} />
                    }
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用値</Typography>
                      <Tooltip placement="top-start" title="適用される値を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringApplicableValue1"
                      control={control}
                      rules={validationRules.monitoringApplicableValue1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ選択の正当性、または適用した測定方法と手順の説明</Typography>
                      <Tooltip placement="top-start" title="データ源の選択を正当化し、必要に応じて参考文献を示す。値が測定に基づく場合は、適用された測定方法と手順（例えば、どのような基準またはプロトコルに従ったのか）の説明を含め、測定を行った責任者／団体、測定日、測定結果を示す。付録として、より詳細な情報を記載することもできる。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringExplain1"
                      control={control}
                      rules={validationRules.monitoringExplain1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={4}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データの目的</Typography>
                      <Tooltip
                        placement="top-start"
                        title={
                          <>
                            以下のいずれかを示す：<br />
                            •	ベースラインシナリオの決定（AFOLUプロジェクトのみ）<br />
                            •	ベースライン排出量の算定<br />
                            •	プロジェクト排出量の算定<br />
                            •	リーケージの算定
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringPurpose1"
                      control={control}
                      rules={validationRules.monitoringPurpose1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>コメント</Typography>
                      <Tooltip placement="top-start" title="その他コメントがあればご記入ください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringComment1"
                      control={control}
                      rules={validationRules.monitoringComment1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseMonitoringDialog1}>戻る</Button>
            <Button
              onClick={currentId === 0 ? addMonitoring1Rows : changeMonitoring1Rows}
              // disabled={true}
            >
              {currentId === 0 ? "追加" : "更新"}
            </Button>
          </DialogActions>
        </Dialog>
        {/* 8.1 ベースライン排出量　更新ダイアログ */}
        <Dialog
          maxWidth="lg"
          open={isOpenBaselineEmissionsDialog}
          onClose={handleCloseBaselineEmissionsDialog}
        >
          <DialogTitle>ベースライン排出量</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              ベースライン排出量及び/又は炭素蓄積量の変化の定量化の手順を、適用された方法論に従って記述する。<br />
              数式の入力は LaTeX の記述方式が使用できます。
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="baselineEmissions"
                  control={control}
                  rules={validationRules.baselineEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="baselineEmissions"
                      multiline
                      rows={25}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseBaselineEmissionsDialog}>戻る</Button>
            <Button
              onClick={updateBaselineEmissions}
              // disabled={true}
            >
              更新
            </Button>
          </DialogActions>
        </Dialog>
        {/* 8.2 ベースライン排出量　更新ダイアログ */}
        <Dialog
          maxWidth="lg"
          open={isOpenProjectEmissionsDialog}
          onClose={handleCloseProjectEmissionsDialog}
        >
          <DialogTitle>プロジェクト排出量</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              適用される方法論に従って、プロジェクトの排出量及び／又は炭素蓄積量の変化を定量化する手順を記述する。<br />
              数式の入力は LaTeX の記述方式が使用できます。
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectEmissions"
                  control={control}
                  rules={validationRules.projectEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectEmissions"
                      multiline
                      rows={25}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseProjectEmissionsDialog}>戻る</Button>
            <Button
              onClick={updateProjectEmissions}
              // disabled={true}
            >
              更新
            </Button>
          </DialogActions>
        </Dialog>
        {/* 8.3 リーケージ排出量　更新ダイアログ */}
        <Dialog
          maxWidth="lg"
          open={isOpenLeakageEmissionsDialog}
          onClose={handleCloseLeakageEmissionsDialog}
        >
          <DialogTitle>リーケージ排出量</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              適用される方法論に従って、漏洩排出量を定量化する手順を記述する。　　　　　　　　　　<br />
              数式の入力は LaTeX の記述方式が使用できます。
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="leakageEmissions"
                  control={control}
                  rules={validationRules.leakageEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="leakageEmissions"
                      multiline
                      rows={25}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseLeakageEmissionsDialog}>戻る</Button>
            <Button
              onClick={updateLeakageEmissions}
              // disabled={true}
            >
              更新
            </Button>
          </DialogActions>
        </Dialog>
        {/* 8.4 GHG排出削減量と二酸化炭素除去量の推定値 クレジット期間　更新ダイアログ */}
        <Dialog
          maxWidth="lg"
          open={isOpenProjectCreditTermDialog}
          onClose={handleCloseProjectCreditTermDialog}
        >
          <DialogTitle>プロジェクトクレジット期間</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              プロジェクトのクレジット期間について、記入してください。　　　　　　　　　　　　　　　<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>クレジット期間</Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DatePicker
                          id="projectCreditTermStart"
                          // label="プロジェクト開始日"
                          value={projectCreditTermStart}
                          onChange={handleChangeProjectCreditTermStart}
                          inputFormat="dd/MM/yyyy"
                          mask="__/__/____"
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                        <DatePicker
                          id="projectCreditTermEnd"
                          // label="プロジェクト終了日"
                          value={projectCreditTermEnd}
                          onChange={handleChangeProjectCreditTermEnd}
                          inputFormat="dd/MM/yyyy"
                          mask="__/__/____"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Box>
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定ベースライン排出量</Typography>
                    <Controller
                      name="estimatedBaselineEmissions"
                      control={control}
                      rules={validationRules.estimatedBaselineEmissions}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定プロジェクト排出量</Typography>
                    <Controller
                      name="estimatedProjectEmissions"
                      control={control}
                      rules={validationRules.estimatedProjectEmissions}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定リーケージ排出量</Typography>
                    <Controller
                      name="estimatedLeakageEmissions"
                      control={control}
                      rules={validationRules.estimatedLeakageEmissions}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定削減クレジット</Typography>
                    <Controller
                      name="estimatedReductionCredit"
                      control={control}
                      rules={validationRules.estimatedReductionCredit}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定除去クレジット</Typography>
                    <Controller
                      name="estimatedRemoveCredit"
                      control={control}
                      rules={validationRules.estimatedRemoveCredit}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定合計クレジット</Typography>
                    <Controller
                      name="estimatedTotalCredit"
                      control={control}
                      rules={validationRules.estimatedTotalCredit}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Typography sx={{ mr: 1, color: "#808080" }}>※単位はすべて tCO2e</Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseProjectCreditTermDialog}>戻る</Button>
            <Button
              onClick={currentId === 0 ? addProjectCreditTermRows : changeProjectCreditTermRows}
              // disabled={true}
            >
              {currentId === 0 ? "追加" : "更新"}
            </Button>
          </DialogActions>
        </Dialog>
        {/* 9.2 モニタリングされるデータとパラメータ 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog
          maxWidth="lg"
          open={isOpenMonitoringDialog2}
          onClose={handleCloseMonitoringDialog2}
        >
          <DialogTitle>モニタリングされるデータとパラメータ</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              プロジェクトのクレジット対象期間中にモニタリングされるすべてのデータとパラメータについて入力してください。<br />
              提供された値は、プロジェクトのクレジット対象期間における削減量と除去量の推定値を定量化するために使用されます。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>データ／パラメータ</Typography>
                      <Controller
                      name="monitoringDataOrParam2"
                      control={control}
                      rules={validationRules.monitoringDataOrParam2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ単位</Typography>
                      <Tooltip placement="top-start" title="測定単位を示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataUnit2"
                      control={control}
                      rules={validationRules.monitoringDataUnit2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ内容</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータの簡単な説明を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataContents2"
                      control={control}
                      rules={validationRules.monitoringDataContents2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データソース</Typography>
                      <Tooltip placement="top-start" title="データソースを示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataSource2"
                      control={control}
                      rules={validationRules.monitoringDataSource2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginLeft: "2rem" }}>
                    <Typography>必要があればデータソースに関連するファイルを添付してください。</Typography>
                    {monitoringDataSource2FileList.length < 1
                      ? <FileUploadInput name="monitoringDataSource2File" />
                      : <AttachmentFiles fileList={monitoringDataSource2FileList} setList={setMonitoringDataSource2FileList} />
                    }
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用される測定方法と手順の説明</Typography>
                      <Tooltip placement="top-start" title="測定方法及び手順、従うべき標準又はプロトコル、及び測定に責任を有する人／組織を明記する。測定の精度に関する関連情報（例えば、計器装置または実験室試験に関連する精度）を含む。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringExplain2"
                      control={control}
                      rules={validationRules.monitoringExplain2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>モニタリング／記録の頻度</Typography>
                      <Tooltip placement="top-start" title="測定と記録の頻度を指定する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringFrequency"
                      control={control}
                      rules={validationRules.monitoringFrequency}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={2}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用値</Typography>
                      <Tooltip placement="top-start" title="適用される値を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringApplicableValue2"
                      control={control}
                      rules={validationRules.monitoringApplicableValue2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>モニタリング機器</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータをモニターするために使用される機器を特定すること。必要に応じて、機器のタイプ、精度クラス、シリアル番号を含む">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDevice"
                      control={control}
                      rules={validationRules.monitoringDevice}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用されるQA/QC手順</Typography>
                      <Tooltip placement="top-start" title="適用される品質保証及び品質管理（QA/QC）手順（該当する場合は校正手順を含む）を記載すること。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringQaQcProcedure"
                      control={control}
                      rules={validationRules.monitoringQaQcProcedure}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データの目的</Typography>
                      <Tooltip
                        placement="top-start"
                        title={
                          <>
                            以下のいずれかを示すこと：<br />
                            •	ベースライン排出量の計算<br />
                            •	プロジェクト排出量の計算<br />
                            •	リーケージの計算
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringPurpose2"
                      control={control}
                      rules={validationRules.monitoringPurpose2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>計算方法</Typography>
                      <Tooltip placement="top-start" title="該当する場合、データ／パラメータを確定するために使用した計算方法（方程式を含む）を提示すること。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="howToCalc"
                      control={control}
                      rules={validationRules.howToCalc}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={5}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>コメント</Typography>
                      <Tooltip placement="top-start" title="その他コメントがあればご記入ください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringComment2"
                      control={control}
                      rules={validationRules.monitoringComment2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseMonitoringDialog2}>戻る</Button>
            <Button
              onClick={currentId === 0 ? addMonitoring2Rows : changeMonitoring2Rows}
              // disabled={true}
            >
              {currentId === 0 ? "追加" : "更新"}
            </Button>
          </DialogActions>
        </Dialog>
        {/* 10.4 GHG排出削減量と二酸化炭素除去量 クレジット期間　更新ダイアログ */}
        <Dialog
          maxWidth="lg"
          open={isOpenQuantifyProjectCreditTermDialog}
          onClose={handleCloseQuantifyProjectCreditTermDialog}
        >
          <DialogTitle>プロジェクトクレジット期間</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              プロジェクトのクレジット期間について、記入してください。　　　　　　　　　　　　　　　<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>クレジット期間</Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DatePicker
                          id="quantifyProjectCreditTermStart"
                          // label="プロジェクト開始日"
                          value={quantifyProjectCreditTermStart}
                          onChange={handleChangeProjectCreditTermStart}
                          inputFormat="dd/MM/yyyy"
                          mask="__/__/____"
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                        <DatePicker
                          id="quantifyProjectCreditTermEnd"
                          // label="プロジェクト終了日"
                          value={quantifyProjectCreditTermEnd}
                          onChange={handleChangeQuantifyProjectCreditTermEnd}
                          inputFormat="dd/MM/yyyy"
                          mask="__/__/____"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Box>
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定ベースライン排出量</Typography>
                    <Controller
                      name="quantifyEstimatedBaselineEmissions"
                      control={control}
                      rules={validationRules.quantifyEstimatedBaselineEmissions}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定プロジェクト排出量</Typography>
                    <Controller
                      name="quantifyEstimatedProjectEmissions"
                      control={control}
                      rules={validationRules.quantifyEstimatedProjectEmissions}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定リーケージ排出量</Typography>
                    <Controller
                      name="quantifyEstimatedLeakageEmissions"
                      control={control}
                      rules={validationRules.quantifyEstimatedLeakageEmissions}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定削減クレジット</Typography>
                    <Controller
                      name="quantifyEstimatedReductionCredit"
                      control={control}
                      rules={validationRules.quantifyEstimatedReductionCredit}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定除去クレジット</Typography>
                    <Controller
                      name="quantifyEstimatedRemoveCredit"
                      control={control}
                      rules={validationRules.quantifyEstimatedRemoveCredit}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={11} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>推定合計クレジット</Typography>
                    <Controller
                      name="quantifyEstimatedTotalCredit"
                      control={control}
                      rules={validationRules.quantifyEstimatedTotalCredit}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Typography sx={{ mr: 1, color: "#808080" }}>※単位はすべて tCO2e</Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseQuantifyProjectCreditTermDialog}>戻る</Button>
            <Button
              onClick={currentId === 0 ? addQuantifyProjectCreditTermRows : changeQuantifyProjectCreditTermRows}
              // disabled={true}
            >
              {currentId === 0 ? "追加" : "更新"}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
