import React from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import "../../../assets/css/layout/style.css";
import about01 from "../../../assets/imgs/about_nccc01.png";
import kominkaLogo from "../../../assets/imgs/kominka_logo.jpg";
import berrybestLogo from "../../../assets/imgs/berrybest_logo.png";
import ekakashiImg from "../../../assets/imgs/ekimg.jpg";
import { useTranslation } from "react-i18next";
import { SubpageHeader } from "../../../components/homepage/SubpageHeader";
import ScrollToTopOnMount from "../../../components/common/utils/ScrollToTopOnMount";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const LoginedAbout = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <SubpageHeader title="About" breadcrumb={[{ name: "活動について", link: "/member/hp/about" }]} />

      <Box sx={{ margin: "0 auto", padding: "20px", maxWidth: "1024px" }}>
        <Box>
        <Button
          style={btnStyle01}
        >
          <FileDownloadIcon sx={{ color: "#f3f3f3", paddingRight: "5px" }} />
          <Link href={t("WhatWeDo.url.aboutNccc")} target="_blank" underline="none" download sx={{ color: "#f3f3f3" }}>
            {t("WhatWeDo.labels.downloadButton")}
          </Link>
        </Button>
          <h2>{t("WhatWeDo.labels.label1")}</h2>
        </Box>
        <Box sx={{ padding: "0px 20px" }}>
          <Box sx={{ padding: "10px 20px" }}>
            <img src={about01} alt="about" width="100%" />
          </Box>
          <Box sx={{ mt: 2 }}>{t("WhatWeDo.contents.content1")}</Box>
        </Box>
      </Box>

      <Box sx={{ margin: "0 auto", padding: "20px", maxWidth: "1024px" }}>
        <Box>
          <h2>{t("WhatWeDo.labels.label2")}</h2>
        </Box>
        <Box sx={{ padding: "0px 20px" }}>
          {t("WhatWeDo.contents.content2")}
          <br />
          <br />
          {t("WhatWeDo.contents.content3")}
          <br />
          <br />
          {t("WhatWeDo.contents.content4")}
          <br />
          <Typography variant="caption" color="red">
            {t("WhatWeDo.contents.content5")}
          </Typography>
          <Box
            sx={{
              marginTop: "30px",
              padding: "10px",
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            <img src={kominkaLogo} alt="kominkaLogo" width="300px" />
            <img src={berrybestLogo} alt="berrybestLogo" width="300px" />
          </Box>
          <Box sx={{ margin: "10px 0px", borderBottom: "1px solid #3a3a3a" }}>
            <Typography variant="h6">{t("WhatWeDo.labels.label4")}</Typography>
          </Box>
          {t("WhatWeDo.contents.content6")}
          <Box sx={{ margin: "10px 0px", borderBottom: "1px solid #3a3a3a" }}>
            <Typography variant="h6">{t("WhatWeDo.labels.label5")}</Typography>
          </Box>
          {t("WhatWeDo.contents.content7")}
        </Box>
      </Box>

      <Box sx={{ margin: "0 auto", padding: "20px", maxWidth: "1024px" }}>
        <Box>
          <h2>{t("WhatWeDo.labels.label3")}</h2>
        </Box>
        <Box sx={{ padding: "0px 20px" }}>
          <Box
            sx={{
              marginTop: "30px",
              padding: "10px",
              display: "flex",
              gap: "20px",
            }}
          >
            <img src={ekakashiImg} alt="ekakashiImg" style={{ maxWidth: "100%" }} />
          </Box>
          <Box sx={{ margin: "10px 0px", borderBottom: "1px solid #3a3a3a" }}>
            <Typography variant="h6">{t("WhatWeDo.labels.label6")}</Typography>
          </Box>
          <Box>
            {t("WhatWeDo.contents.content8")}
            <ol>
              <li>{t("WhatWeDo.contents.content9")}</li>
              <li>{t("WhatWeDo.contents.content10")}</li>
              <li>{t("WhatWeDo.contents.content11")}</li>
              <li>{t("WhatWeDo.contents.content12")}</li>
            </ol>
          </Box>
        </Box>
      </Box>
      <ScrollToTopOnMount />
    </Box>
  );
};

const btnStyle01 = {
  display: "inline-flex",
  height: "46px",
  padding: "5px 18px 5px 18px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  borderRadius: "10px",
  background: "linear-gradient(124deg, #00695F 0%, #083A75 100%)",
};
