import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    Box,
    Button,
    Modal,
    Tab,
    Typography,
    TextField,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import RefreshIcon from '@mui/icons-material/Refresh';

import ja from 'dayjs/locale/ja'
import dayjs from 'dayjs'

import companyApi from "../../../../api/companyApi";
import invoiceApi from "../../../../api/invoiceApi";
import StatusChip from "../statusChip";
import CreateStatusChip from "../createStatusChip";
import PdfIcon from "../pdfIcon";

const targetYear = "2024"

const AnnualFeeList = () => {
    const [isUpadte, setIsUpdate] = useState(false)
    // 年会費一覧の操作に関する変数や関数
    const [tab, setTab] = useState("1");
    const [rows, setRows] = useState([]);
    const apiRef = useGridApiRef();
    const columns = [
        { field: "companyName", headerName: "会社名", width: "350" },
        {
            field: "membership",
            headerName: "会員区分",
            width: "90",
            renderCell: (params) => {
                return params.value === "fullMember" ? "正会員" : "一般会員";
            },
        },
        {
            field: "createStatus",
            headerName: "作成状況",
            width: "100",
            renderCell: (params) => {
                return <CreateStatusChip status={params.value} />;
            },
        },
        {
            field: "status",
            headerName: "送付状況",
            width: "100",
            renderCell: (params) => {
                return <StatusChip status={params.value} />;
            },
        },
        { field: "fee", headerName: "請求金額", width: "120", },
        {
            field: "sendDate",
            headerName: "請求送信日",
            width: "130",
            renderCell: (params) => {
                // return <ControlDatePicker date={params.value} />;
                return (
                    <Typography>
                        {params.value
                            ? dayjs(params.value).format("YYYY/MM/DD")
                            : ""
                        }
                    </Typography>
                )
            },
        },
        {
            field: "paymentDate",
            headerName: "入金日",
            width: "130",
            renderCell: (params) => {
                // return <ControlDatePicker date={params.value} />;
                return (
                    <Typography>
                        {params.value
                            ? dayjs(params.value).format("YYYY/MM/DD")
                            : ""
                        }
                    </Typography>
                )
            },
        },
        {
            field: "pdfUrl",
            headerName: "",
            width: "120",
            renderCell: (params) => {
                return <PdfIcon params={params} />;
            },
        },
    ];
    const getRowId = (row) => {
        return row._id
    }

    const changeTab = (event, newValue) => {
        setTab(newValue);
        fetchRows(newValue);
    };

    const refresh = () => {
        // isUpadteがtrueのとき、useEffectでいい感じに処理しているのでここはisUpdateをtrueにするだけ
        setIsUpdate(true)
    }

    const create = () => {
        if (window.confirm("選択した企業宛の請求書を発行します。\n処理に時間がかかりますので、しばらくお待ちいただいてから更新ボタンを押下ください。\n送信はされません。\nまた、再作成の場合、送付状況が「未送信」となります。")) {
            const selectedRows = apiRef.current.getSelectedRows();
            let companyData = []
            selectedRows.forEach(v => {
                companyData.push(v)
            })
            invoiceApi.annualFeePdfCreator({
                send: false,
                year: targetYear,
                membership: tab === "1" ? "fullMember" : "generalMember",
                data: companyData,
            })
        }
    }

    const createAndSend = () => {
        if (window.confirm("選択した企業宛の請求書を発行し送付します。\n処理に時間がかかりますので、しばらくお待ちいただいてから更新ボタンを押下ください。\n再作成の場合でも、再送信されます。")) {
            const selectedRows = apiRef.current.getSelectedRows();
            let companyData = []
            selectedRows.forEach(v => {
                companyData.push(v)
            })
            invoiceApi.annualFeePdfCreator({
                send: true,
                year: targetYear,
                membership: tab === "1" ? "fullMember" : "generalMember",
                data: companyData,
            })
        }
    }

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [paymentList, setPaymentList] = useState([])
    const handleModalClose = () => {
        setIsOpenModal(false)
    }
    const openPaymentDateModal = () => {
        setIsOpenModal(true)
        const selectedRows = apiRef.current.getSelectedRows();
        let selectedList = []
        selectedRows.forEach((map) => {
            selectedList.push(map)
        })
        setPaymentList(selectedList)
    }

    const InputPaymentDateModal = () => {
        const { control, getValues } = useForm({})
        const updatePaymentDate = async (paymentList) => {
            const membership = tab === "1" ? "fullMember" : "generalMember";
            const [current] = await invoiceApi.get({
                params: {
                    conditions: {
                        year: targetYear,
                    }
                }
            })
            const paymentDate = getValues("date")
            // 選択企業の入金日を一括更新する
            const newData = []
            current[membership].forEach((company) => {
                paymentList.forEach((target) => {
                    if (company.companyId !== target.companyId) {
                        newData.push(company)
                    } else {
                        let newCompany = company
                        newCompany.paymentDate = paymentDate
                        newCompany.status = 2
                        newData.push(newCompany)
                    }
                })
            })
            await invoiceApi.upsert({
                year: targetYear,
                fullMember: membership === "fullMember"
                    ? newData
                    : current["fullMember"],
                generalMember: membership === "generalMember"
                    ? newData
                    : current["generalMember"]
            })
            handleModalClose()
            refresh()
        }
        const modalStyle = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#ffffff",
            border: "2px solid #000",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
        };

        return (
            <Modal
                open={isOpenModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="caption" component="h4">
                        入金日を入力してください。
                    </Typography>
                    <Controller
                        name="date"
                        control={control}
                        rules={{
                        validate: (value) => {
                            const formatDate = dayjs(value).format('YYYY/MM/DD')
                            if (!dayjs(formatDate).isValid()) {
                            return '日付形式が間違っています'
                            }
                        }
                        }}
                        render={({ field, formState: { errors } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                                <DatePicker
                                    {...field}
                                    disableMaskedInput
                                    inputFormat={'YYYY/MM/DD'}
                                    label=""
                                    // value={date || null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: dayjs().format('YYYY/M/DD')
                                            }}
                                            label="Date"
                                            error={errors.date ? true : false}
                                            helperText={errors.date?.message}
                                        />
                                    )}
                                    sx={{ border: "none" }}
                                />
                            </LocalizationProvider>
                        )}
                    />
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Typography id="modal-list" variant="caption">
                                設定ボタンを押下すると以下の適用対象企業に入金日が設定されます。
                            </Typography>
                        </Box>
                        {paymentList.length !== 0 && paymentList.map((company, index) => {
                            return (
                                <Box key={index}>
                                    <Typography id="modal-company" variant="caption">
                                        {company.companyName}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            onClick={() => updatePaymentDate(paymentList)}
                        >
                            設定
                        </Button>
                    </Box>
                </Box>
            </Modal>
        )
    }

    const makeCompanyIdList = async (current) => {
        let currentIds = []
        current.forEach((company) => {
            currentIds.push(company.companyId)
        })
        return currentIds
    }

    // 前回表示から追加になった会社がないかを確認する
    const checkAddCompany = async (membership, current) => {
        const companyList = await companyApi.get({
            params: {
                conditions: {
                    membership: membership,
                    companyStatus: 3,
                }
            }
        })

        const isExist = current && current[membership].length !== 0
        // DB登録データと件数が変わらなければ追加なしでreturn
        if (isExist && current[membership].length === companyList.length) {
            setIsUpdate(false)
            return
        }

        const currentIds = isExist ? await makeCompanyIdList(current[membership]) : []
        let newData = isExist
            ? current[membership]
                ? current[membership]
                : []
            : []
        companyList.forEach(async (company) => {
            if (!currentIds.includes(company._id)) {
                newData = [...newData, {
                    companyId: company._id,
                    companyName: company.groupName,
                    membership: company.membership,
                    createStatus: 0,
                    status: 0,
                    fee: company.membership === "fullMember" ? "1,200,000" : "120,000",
                    pdfUrl: "",
                }]
            }
        });

        await invoiceApi.upsert({
            year: targetYear,
            fullMember: membership === "fullMember"
                ? newData
                : current // isExist を使用して判定すると後半の条件が余計でfalseになってしまうためcurrentを指定
                    ? current["fullMember"]
                    : [], // 初回表示のみこちらに入る
            generalMember: membership === "generalMember"
                ? newData
                : current
                    ? current["generalMember"]
                    : [],
        })

        setIsUpdate(true)
    }

    const fetchRows = async (tab) => {
        const membership = tab === "1" ? "fullMember" : "generalMember";
        const [current] = await invoiceApi.get({
            params: {
                conditions: {
                    year: targetYear,
                }
            }
        })
        current && setRows(current[membership])
        await checkAddCompany(membership, current)
    }

    useEffect(() => {
        fetchRows("1")
    }, [])

    useEffect(() => {
        isUpadte && tab && fetchRows(tab)
    }, [isUpadte])

    return (
        <>
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={changeTab} aria-label="lab API tabs example">
                        <Tab label="正会員" value="1" />
                        <Tab label="一般会員" value="2" />
                    </TabList>
                </Box>
                <TabPanel value={tab}>
                    <Box>
                        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                            <Button variant="contained" onClick={create}>作成のみ</Button>
                            <Button variant="contained" onClick={createAndSend} sx={{ ml: 2 }}>作成 & 送信</Button>
                            <Button variant="contained" onClick={openPaymentDateModal} sx={{ ml: 2, backgroundColor: "#ffd700" }}>入金日入力</Button>
                            <Button
                                onClick={refresh}
                                sx={{ ml: 5, textAlign: 'right' }}
                            >
                                <RefreshIcon />
                            </Button>
                        </Box>
                        <DataGrid
                            apiRef={apiRef}
                            columns={columns}
                            rows={rows}
                            getRowId={getRowId}
                            autoHeight
                            checkboxSelection
                        />
                    </Box>
                </TabPanel>
            </TabContext>
            <InputPaymentDateModal />
        </>
    )
}

export default AnnualFeeList
