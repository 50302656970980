import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";

export const AdminHome = () => {
  return (
    <div>
      <h3>審査状況</h3>
      <Box sx={{ display: "flex", gap: "20px" }}>
        <Card sx={{ minWidth: 200, maxWidth: 300, padding: 1 }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              一次審査（受付）中
            </Typography>
            <Typography textAlign="right">
              <Link to="#">
                <span
                  style={{
                    padding: "20px",
                    fontSize: "24px",
                    color: "#3BCAFF",
                  }}
                >
                  2
                </span>
              </Link>
              件
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200, maxWidth: 300, padding: 1 }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              二次審査（受付）中
            </Typography>
            <Typography textAlign="right">
              <Link to="#">
                <span
                  style={{
                    padding: "20px",
                    fontSize: "24px",
                    color: "#3BCAFF",
                  }}
                >
                  2
                </span>
              </Link>
              件
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 200, maxWidth: 300, padding: 1 }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              入金確認中
            </Typography>
            <Typography textAlign="right">
              <Link to="#">
                <span
                  style={{
                    padding: "20px",
                    fontSize: "24px",
                    color: "#3BCAFF",
                  }}
                >
                  2
                </span>
              </Link>
              件
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};
