import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import categoryApi from "../../api/categoryApi";

const CategoryDropdown = (param, field, fieldState) => {
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");

  // 初期表示
  useEffect(() => {
    fetchCategoryList();
  }, []);

  useEffect(() => {
    setCategory(param?.field?.value);
  }, [param?.field?.value]);

  const fetchCategoryList = async () => {
    const category = await categoryApi.get({
      params: {
        name: param.categoryName
      }
    })
    setCategoryList(category.list);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    param.onChange(event)
    // dispatch(task_setCategory(event.target.value));
  };

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <FormControl fullWidth error={fieldState?.invalid}>
      <InputLabel>{param.categoryLabel}</InputLabel>
      <Select
        {...field}
        input={<OutlinedInput label={param.categoryLabel} />}
        value={category}
        MenuProps={MenuProps}
        onChange={handleCategoryChange}
      >
        {categoryList &&
          categoryList.map((item) => (
            <MenuItem key={item.key} value={item.value} style={menuItemStyle}>
              {item.text}
            </MenuItem>
          ))}
      </Select>
      {fieldState?.invalid && <FormHelperText>{fieldState?.error?.message}</FormHelperText>}
    </FormControl>
  );
};

export default CategoryDropdown;
