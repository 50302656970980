import axios from "axios";

const pathname = window.location.hostname;

const BASE_URL = (() => {
  if (pathname === "singapore.nccc.earth" || pathname === "nccc-singapore-fe-aqio6i5zoa-an.a.run.app") {
    return "https://nccc-singapore-be-aqio6i5zoa-an.a.run.app/api/v1";
  } else if (pathname === "nccc-singapore-fe-develop-aqio6i5zoa-an.a.run.app") {
    return "https://nccc-singapore-be-develop-aqio6i5zoa-an.a.run.app/api/v1";
  } else {
    return "http://localhost:5000/api/v1";
  }
})();

const getToken = () => localStorage.getItem("token");

const axiosClient = axios.create({ baseURL: BASE_URL });

// APIを叩く前に前処理を行う
axiosClient.interceptors.request.use(async (config) => {
  return {
    ...config,
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${getToken()}`, // request headerにJWT tokenを追加
    },
  };
});

// APIを叩いた後に後処理を行う
axiosClient.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    throw error.response;
  }
);

export default axiosClient;
