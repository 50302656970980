import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MathJaxContext } from "better-react-mathjax"
import Login from "./pages/Login";
import Form from "./pages/Form";
import Accept from "./pages/homepage/Accept";
import AcceptGuide from "./pages/homepage/AcceptGuide";
import AcceptComplete from "./pages/homepage/AcceptComplete";
import RegisterComplete from "./pages/homepage/RegisterComplete";
import Register from "./pages/Register";
import Questionnaire from "./pages/Questionnaire";
import AuthLayout from "./components/layout/AuthLayout";
import RegisterLayout from "./components/layout/RegisterLayout";
import FormLayout from "./components/layout/FormLayout";
import HomepageLayout from "./components/layout/HomepageLayout";
import DefaultLayout from "./components/layout/DefaultLayout";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdminHome } from "./pages/admin/AdminHome";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { NonAntisocialForcesAgreement } from "./pages/NonAntisocialForcesAgreement";
import { FormAdmin } from "./pages/admin/FormAdmin";
import { QuestionnaireSendRegister } from "./pages/admin/QuestionnaireSendRegister";
import { QuestionnaireSummary } from "./pages/admin/QuestionnaireSummary";
import { FormCompleted } from "./pages/FormCompleted";
// import { Homepage } from "./pages/homepage/Homepage";
import { Top } from "./pages/homepage/Top";
import { Organization } from "./pages/homepage/Organization";
import { Observer } from "./pages/homepage/Observer";
import { InternationalStatus } from "./pages/homepage/InternationalStatus";
import { Constitution } from "./pages/homepage/Constitution";
import { NewsList } from "./pages/homepage/NewsList";
import { NewsDetails } from "./pages/homepage/NewsDetails";
import { ReleaseDetails } from "./pages/homepage/ReleaseDetails";
import { PrimaryPass } from "./pages/admin/applicationflow/PrimaryPass";
import { PrimaryPassDetails } from "./pages/admin/applicationflow/PrimaryPassDetails";
import { Members } from "./pages/homepage/Members";
import { About } from "./pages/homepage/About";
import { AboutManagi } from "./pages/homepage/AboutManagi";
import "./i18n/configs.js";
import { Comingsoon } from "./pages/homepage/Comingsoon";
// admin
import AnnualFeeList from "./pages/admin/invoice/annualFee/list";
//programs
// import { MemberProgram } from './pages/member/programs/index'
import { MemberProgramApply } from "./pages/member/programs/apply";
import { MemberProgramOffer } from "./pages/member/programs/offer";
import { ProgramExamination } from "./pages/member/programs/ProgramExamination";
import { ProgramOfferExamination } from "./pages/member/programs/ProgramOfferExamination";
import { ProgramList } from "./pages/member/programs/ProgramList";

//branches
// import { MemberBranch } from './pages/member/branches/index'
import { MemberBranchCompose } from "./pages/member/branches/compose";
import { MemberBranchJoin } from "./pages/member/branches/join";
import { BranchApply } from "./pages/member/branches/BranchApply";
import { BranchUserApply } from "./pages/member/branches/BranchUserApply";
// import { BranchApplyList } from "./pages/member/branches/BranchApplyList";
import { BranchList } from "./pages/member/branches/BranchList";
import { BranchJoinList } from "./pages/member/branches/BranchJoinList";

//projects
import { ParticipantsList } from "./pages/member/projects/ParticipantsList";
// import { ProjectApplication } from "./pages/member/projects/ProjectApplication";
import { ProjectList } from "./pages/member/projects/ProjectList";
// import { ProjectApplyList } from "./pages/member/projects/ProjectApplyList";
import { ProjectDetail } from "./pages/member/projects/ProjectDetail";
import { ProjectEdit } from "./pages/member/projects/edit";
import { MemberProjectClose } from "./pages/member/projects/close";
import { MemberProjectResultInput } from "./pages/member/projects/resultInput";
import { ProjectManage } from "./pages/member/projects/ProjectManage";
import { ParticipantsApply } from "./pages/member/projects/ParticipantsApply";
import { ProjectApply } from "./pages/member/projects/ProjectApply";
import { ProjectProgress } from "./pages/member/projects/ProjectProgress";
import { ProjectProgressDetail } from "./pages/member/projects/ProjectProgressDetail";
// import { MemberProject } from './pages/member/projects/index'
import { MemberProjectApply } from "./pages/member/projects/apply";
import { MemberProjectJoin } from "./pages/member/projects/join";
import { ProjectTaskAdd } from "./pages/member/projects/task/add";
import { ProjectTaskDetail } from "./pages/member/projects/task/detail";
import { CreateValidationReport } from "./pages/member/projects/createValidationReport";
import { CreateVerificationReport } from "./pages/member/projects/createVerificationReport";
import { CreatePdd } from "./pages/member/projects/projectDesignDocument/craete";

// member
import MemberLayout from "./components/layout/MemberLayout";
import { MemberHome } from "./pages/member/Home";
import { MemberLogin } from "./pages//member/MemberLogin";
import MemberEventDetails from "./pages/member/event/details";

//proposal
import { ProposalList } from "./pages/member/proposal/index";
import { ProposalCreate } from "./pages/member/proposal/create";
import { ProposalDetail } from "./pages/member/proposal/details";
// businessCard
import { BusinessCardCreate } from "./pages/member/businessCard/createForm";
//event

import { EventRegister } from "./pages/admin/event/EventRegister";
import { EventList } from "./pages/admin/event/EventList";
import { WebinarForm } from "./pages/admin/event/WebinarForm";
import { EventDetails } from "./pages/admin/event/EventDetails";

// task
import { TaskList } from "./pages/member/task/list";
import { TaskDetail } from "./pages/member/task/detail";
import { TaskAdd } from "./pages/member/task/add";

//manual
import { Manual } from "./pages/member/manual";
import { MemberList } from "./pages/MemberList";

// auth
import { RouteAuthGuard } from "./components/RouteAuthGuard";
import { RoleType, AuthUserProvider } from "./providers/auth";



// mypage
// 先方都合で仕様変更となり、リリース延期となったためコメントアウトで対応する
// import { Mypage } from "./pages/mypage";

// HPメニューをログイン後画面に表示する暫定対応用
import { LoginedAbout } from "./pages/member/tmp/About"
import { LoginedAboutManagi } from "./pages/member/tmp/AboutManagi";
import { LoginedConstitution } from "./pages/member/tmp/Constitution"
import { LoginedInternationalStatus } from "./pages/member/tmp/InternationalStatus"
import { LoginedMembers } from "./pages/member/tmp/Members"
import { LoginedNewsDetails } from "./pages/member/tmp/NewsDetails"
import { LoginedNewsList } from "./pages/member/tmp/NewsList"
import { LoginedObserver } from "./pages/member/tmp/Observer"
import { LoginedOrganization } from "./pages/member/tmp/Organization"
import { LoginedReleaseDetails } from "./pages/member/tmp/ReleaseDetails"
import { LoginedPrivacyPolicy } from "./pages/member/tmp/PrivacyPolicy"

function App() {
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#004d40",
        navi: "#78B94F",
      },
      secondary: {
        main: "#00b0ff",
      },
      danger: {
        main: "#ff1744",
      },
      white: {
        main: "#ffffff",
      },
    },
    typography: {
      fontFamily: [
        "source-han-sans-japanese",
        "neue-haas-grotesk-text",
        "neue-haas-grotesk-display",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <AuthUserProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MathJaxContext>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route path="comingsoon" element={<Comingsoon />} />
            </Route>
            <Route path="/" element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
            </Route>
            <Route path="/" element={<FormLayout />}>
              <Route path="webinar-form/:slug" element={<WebinarForm />} />
              <Route path="form/:slug" element={<Form />} />
              <Route path="form-complete" element={<FormCompleted />} />
              <Route path="questionnaire/:companyId" element={<Questionnaire />} />
            </Route>
            {/* <Route path="/" element={<AcceptLayout />}>
            <Route path="accept" element={<Accept />} />
          </Route> */}
            <Route path="/" element={<RegisterLayout />}>
              <Route path="register/:companyId" element={<Register />} />
            </Route>

            {/* <Route path="/project" element={<AppLayout />}>
            <Route path="application" element={<ProjectApplication />} />
            <Route path="list" element={<ProjectList />} />
            <Route path="participants" element={<ParticipantsList />} />
            <Route path="detail/:slug" element={<ProjectDetail />} />
            <Route path="participantsApply/:id" element={<ParticipantsApply />} />
            <Route path="projectApply/:id" element={<ProjectApply />} />
          </Route>
          <Route path="/program" element={<AppLayout />}>
            <Route path="list" element={<ProgramList />} />
          </Route>
          <Route path="/branch" element={<AppLayout />}>
            <Route path="list" element={<BranchList />} />
            <Route path="apply/:id" element={<BranchApply />} />
          </Route> */}

            {/* <Route path="/admin" element={<AppLayout />}> */}
            <Route
              path="/admin"
              element={
                <RouteAuthGuard
                  component={<MemberLayout />}
                  redirect="/member"
                  allowroles={[RoleType.Admin, RoleType.BoardOfDirectors]}
                />
              }
            >
              <Route path="" element={<AdminHome />} />
              <Route path="primary-pass" element={<PrimaryPass />} />
              <Route path="primary-pass/details/:slug" element={<PrimaryPassDetails />} />
              <Route path="form-admin" element={<FormAdmin />} />
              <Route path="questionnaire_sendregister" element={<QuestionnaireSendRegister />} />
              <Route path="questionnaire_summary" element={<QuestionnaireSummary />} />
              <Route path="event_list" element={<EventList />} />
              <Route path="event_detail/:slug" element={<EventDetails />} />
              <Route path="event_register" element={<EventRegister />} />
              {/* 請求管理 */}
              <Route path="annual-fee" element={<AnnualFeeList />} />
            </Route>
            <Route path="/" element={<DefaultLayout />}>
              <Route index element={<Top />} />
              <Route path="news" element={<NewsList />} />
              <Route path="news/:id" element={<NewsDetails />} />
              <Route path="release/:id" element={<ReleaseDetails />} />
              <Route path="organization" element={<Organization />} />
              <Route path="constitution" element={<Constitution />} />
              <Route path="privacypolicy" element={<PrivacyPolicy />} />
              <Route path="accept_agreement" element={<NonAntisocialForcesAgreement />} />
              <Route path="members" element={<Members />} />
              <Route path="observer" element={<Observer />} />
              <Route path="status" element={<InternationalStatus />} />
              <Route path="about" element={<About />} />
              <Route path="shunsuke-managi" element={<AboutManagi />} />
            </Route>
            <Route path="/" element={<HomepageLayout />}>

              <Route path="accept_guide" element={<AcceptGuide />} />
              <Route path="accept" element={<Accept />} />
              <Route path="accept_complete" element={<AcceptComplete />} />
              <Route path="register_complete" element={<RegisterComplete />} />
            </Route>
            <Route path="/member/login" element={<MemberLogin />} />
            <Route
              path="/member"
              element={<RouteAuthGuard component={<MemberLayout />} redirect="/member/login" />}
            >
              <Route index element={<MemberHome />} />
              <Route path="members">
                <Route
                  path="list"
                  element={
                    <RouteAuthGuard
                      component={<MemberList />}
                      redirect="/member"
                      allowroles={[RoleType.Admin, RoleType.BoardOfDirectors]}
                    />
                  }
                />
              </Route>

              <Route path="proposal">
                <Route index element={<ProposalList />} />
                <Route path="create" element={<ProposalCreate />} />
                <Route path="detail/:id" element={<ProposalDetail />} />
              </Route>

              {/* タスク のルーティング */}
              <Route path="task">
                <Route path="list" element={<TaskList />} />
                <Route path="detail/:id" element={<TaskDetail />} />
                <Route path="add" element={<TaskAdd />} />
              </Route>

              {/* 名刺作成のルーティング */}
              <Route path="businessCard">
                <Route path="create" element={<BusinessCardCreate />} />
              </Route>

              <Route path="members">
                <Route
                  path="list"
                  element={
                    <RouteAuthGuard
                      component={<MemberList />}
                      redirect="/member"
                      allowroles={[RoleType.Admin, RoleType.BoardOfDirectors]}
                    />
                  }
                />
              </Route>
              {/* 先方都合で仕様変更となり、リリース延期となったためコメントアウトで対応する */}
              {/* <Route 
                path="mypage"
                element={
                  <RouteAuthGuard
                    component={<Mypage />}
                    redirect="/member"
                    allowroles={[RoleType.Admin, RoleType.FullMember, RoleType.GeneralMember, RoleType.ObserverMember]}
                  />
                }
              /> */}

              <Route path="programs">
                <Route index element={<ProgramList />} />
                <Route path=":id" element={<ProgramExamination />} />
                <Route path="offer/:id" element={<ProgramOfferExamination />} />
                <Route
                  path="create"
                  element={
                    <RouteAuthGuard
                      component={<MemberProgramApply />}
                      redirect="/member"
                      allowroles={[RoleType.Admin, RoleType.ThirdPartyCommittee]}
                    />
                  }
                />
                <Route
                  path="offer"
                  element={
                    <RouteAuthGuard
                      component={<MemberProgramOffer />}
                      redirect="/member"
                      allowroles={[
                        RoleType.Admin,
                        RoleType.FullMember,
                        RoleType.GeneralMember,
                        RoleType.ObserverMember,
                      ]}
                    />
                  }
                />
              </Route>
              <Route path="branches">
                <Route index element={<BranchList />} />
                {/* <Route path="apply/list" element={<BranchApplyList />} /> */}
                <Route path=":id" element={<BranchApply />} />
                <Route path="joinApply/:id" element={<BranchUserApply />} />
                <Route
                  path=":id/join"
                  element={
                    <RouteAuthGuard
                      component={<MemberBranchJoin />}
                      redirect="/member"
                      allowroles={[
                        RoleType.GeneralMember,
                        RoleType.FullMember,
                        RoleType.ObserverMember,
                      ]}
                    />
                  }
                />
                <Route
                  path="joinList"
                  element={
                    <RouteAuthGuard
                      component={<BranchJoinList />}
                      redirect="/member"
                      allowroles={[
                        RoleType.Admin,
                        RoleType.BoardOfDirectors,
                        RoleType.FullMember,
                        RoleType.GeneralMember,
                        RoleType.ObserverMember,
                      ]}
                    />
                  }
                />
                <Route
                  path="create"
                  element={
                    <RouteAuthGuard
                      component={<MemberBranchCompose />}
                      redirect="/member"
                      allowroles={[RoleType.Admin, RoleType.FullMember]}
                    />
                  }
                />
              </Route>
              
                <Route path="projects">
                  <Route index element={<ProjectList />} />
                  {/* <Route path="apply/list" element={<ProjectApplyList />} /> */}
                  <Route path=":id" element={<ProjectDetail />} />
                  <Route path="apply/:id" element={<ProjectApply />} />
                  <Route path=":id/join" element={<MemberProjectJoin />} />
                  <Route path=":id/edit" element={<ProjectEdit />} />
                  <Route path=":id/close" element={<MemberProjectClose />} />
                  <Route path="result" element={<MemberProjectResultInput />} />
                  <Route path=":id/:workId/join" element={<MemberProjectJoin />} />
                  <Route path="create" element={<MemberProjectApply />} />
                  <Route path="verificationReport/create" element={<CreateVerificationReport />} />
                  <Route path="validationReport/create" element={<CreateValidationReport />} />
                  {/* プロジェクトタスク */}
                  <Route path="task/add" element={<ProjectTaskAdd />} />
                  <Route path="task/detail/:id" element={<ProjectTaskDetail />} />
                  {/* // TODO 仮 */}
                  <Route path="manage/:slug" element={<ProjectManage />} />
                  <Route path="progress/:id" element={<ProjectProgress />} />
                  <Route path="progress/detail/:id" element={<ProjectProgressDetail />} />
                  <Route path="pdd/create" element={<CreatePdd />} />
                </Route>
              {/* <Route
                path="project_applicants"
                element={
                  <RouteAuthGuard
                    component={<ParticipantsList />}
                    redirect="/member"
                    allowroles={[RoleType.Admin, RoleType.BoardOfDirectors]}
                  />
                }
              >
                <Route index element={<ParticipantsList />} />
                <Route path=":id" element={<ParticipantsApply />} />
              </Route> */}
              <Route
                path="project_applicants"
                element={
                  <RouteAuthGuard
                    component={<ParticipantsList />}
                    redirect="/member"
                    allowroles={[
                      RoleType.Admin,
                      RoleType.BoardOfDirectors,
                      RoleType.FullMember,
                      RoleType.GeneralMember,
                      RoleType.ObserverMember,
                    ]}
                  />
                }
              />
              <Route
                path="project_applicants/:id"
                element={
                  <RouteAuthGuard
                    component={<ParticipantsApply />}
                    redirect="/member"
                    allowroles={[
                      RoleType.Admin,
                      RoleType.BoardOfDirectors,
                      RoleType.FullMember,
                      RoleType.GeneralMember,
                      RoleType.ObserverMember,
                    ]}
                  />
                }
              />
              <Route
                path="manual"
                element={
                  <RouteAuthGuard
                    component={<Manual />}
                    redirect="/member"
                    allowroles={[RoleType.Admin, RoleType.BoardOfDirectors]}
                  />
                }
              />
              <Route
                path="eventdetail/:id"
                element={
                  <RouteAuthGuard
                    component={<MemberEventDetails />}
                    redirect="/member"
                    allowroles={[
                      RoleType.Admin,
                      RoleType.BoardOfDirectors,
                      RoleType.FullMember,
                      RoleType.GeneralMember,
                      RoleType.ThirdPartyCommittee,
                      RoleType.ObserverMember,
                    ]}
                  />
                }
              />
              <Route path="hp" >
                <Route path="news" element={<LoginedNewsList />} />
                <Route path="news/:id" element={<LoginedNewsDetails />} />
                <Route path="release/:id" element={<LoginedReleaseDetails />} />
                <Route path="organization" element={<LoginedOrganization />} />
                <Route path="constitution" element={<LoginedConstitution />} />
                <Route path="privacypolicy" element={<LoginedPrivacyPolicy />} />
                <Route path="members" element={<LoginedMembers />} />
                <Route path="observer" element={<LoginedObserver />} />
                <Route path="status" element={<LoginedInternationalStatus />} />
                <Route path="about" element={<LoginedAbout />} />
                <Route path="shunsuke-managi" element={<LoginedAboutManagi />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        </MathJaxContext>
      </ThemeProvider>
    </AuthUserProvider>
  );
}

export default App;
