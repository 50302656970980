import axiosClient from "./axiosClient";

const projectApi = {
  approval: (params) => axiosClient.post("project/approval", params),
  nonApproval: (params) => axiosClient.post("project/nonApproval", params),
  getList: (params) => axiosClient.get("project/list", params),
  getBranchList: () => axiosClient.get("project/branchList"),
  getById: (params) => axiosClient.get("project/getById", params),
  updateWork: (params) => axiosClient.post("project/updateWork", params),
  close: (params) => axiosClient.post("project/close", params),
  getWorkListForManage: (params) => axiosClient.get("project/workListForManage", params),
  getByProgress: (params) => axiosClient.get("project/getByProgress", params),
  getAuth: (params) => axiosClient.get("project/getAuth", params),
  remand: (params) => axiosClient.post("project/remand", params),

  // PDDに関するAPI
  register: (params) => axiosClient.post("project/register", params),
  update: (params) => axiosClient.post("project/update", params),
  delete: (params) => axiosClient.post("project/delete", params),

  // プロジェクトタスクに関するAPI
  getProjectTask: (params) => axiosClient.get("project/getProjectTask", params),
  addProjectTask: (params) => axiosClient.post("project/addProjectTask", params),
};

export default projectApi;
