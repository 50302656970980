import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import eventApi from "../../../api/eventApi";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { Chip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { yellow, amber } from '@mui/material/colors';
import { useSelector } from "react-redux";

const theme = createTheme({
  palette: {
    secondary: amber,
  },
});

export const EventList = () => {
  const TODAY = dayjs();
  const [eventList, setEventList] = useState([]);
  const storedUserInfo = useSelector((state) => state.user);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 250,
      renderCell: (params) => (
        <Link to={`/admin/event_detail/${params.value}`}>{params.value}</Link>
      ),
    },
    { field: "eventName", headerName: "イベント名", width: 200 },
    {
      field: "startDate",
      headerName: "開始日時",
      width: 250,
      renderCell: (params) => (
        <>
          <ThemeProvider theme={theme}>
            <Chip
              variant="outlined"
              color={params.row.isDraft ? "secondary" : dayjs(params.value).isBefore(TODAY) ? "error" : "success"}
              label={params.row.isDraft ? "下書き" : dayjs(params.value).isBefore(TODAY) ? "開催済" : "開催前"}
            />
          </ThemeProvider>        
          <span style={{ marginLeft: "5px" }}>
            {params.value && dayjs(params.value).format("YYYY/MM/DD HH:mm")}
          </span>
        </>
      ),
    },
    {
      field: "endDate",
      headerName: "終了日時",
      width: 200,
      valueFormatter: (params) => params.value && dayjs(params.value).format("YYYY/MM/DD HH:mm"),
    },
    {
      field: "startAvailableDate",
      headerName: "受付開始日",
      width: 200,
      valueFormatter: (params) => params.value && dayjs(params.value).format("YYYY/MM/DD HH:mm"),
    },
    {
      field: "endAvailableDate",
      headerName: "受付終了日",
      width: 200,
      valueFormatter: (params) => params.value && dayjs(params.value).format("YYYY/MM/DD HH:mm"),
    },
  ];
  useEffect(() => {
    async function fetchEventList() {
      const response = await eventApi.getList();
      // 下書きは作成したユーザーのみ表示可能
      const events = response.events.filter((event) =>
          (!event.isDraft || (event.isDraft && event.createdUserId === storedUserInfo.value._id))
      );
      setEventList(events);
    }
    fetchEventList();
  }, []);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const pageSizeOptions = [10, 25, 100];

  return (
    <>
      <Typography variant="h5">イベント一覧</Typography>
      <Box sx={{ height: 400, marginTop: "20px" }}>
        <DataGrid
          rows={eventList}
          columns={columns}
          getRowId={(row) => row._id}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={pageSizeOptions}
          autoHeight
        />
      </Box>
    </>
  );
};
