import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material"

import { MathJax } from "better-react-mathjax"

export const FormulaFieldInput = ({ fieldData }) => {
    // 親コンポーネントは FormProvider でラップされていること
    const { control, getValues } = useFormContext()
    const [isOpen, setIsOpen] = useState(false)
    const [inputText, setInputText] = useState("")

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    const update = () => {
        const text = getValues(fieldData.name)
        setInputText(text)
        handleClose()
    }

    const transNewlineCode = (text) => {
        const lines = text.split(/\n/)
        return (
            <>
            {lines.map((line, index) => {
                return (
                <React.Fragment key={index}>
                    {line ? <MathJax>{line}</MathJax> : <br />}
                    {/* {index + 1 !== lines.length && <br />} */}
                </React.Fragment>
                )
            })}
            </>
        )
    }

    return (
        <>
            <Box mt={3}>
                {fieldData.title
                    && <Typography variant="subtitle1" gutterBottom>{fieldData.title}</Typography>
                }
                {fieldData.explanation
                    && <Typography variant="subtitle2" gutterBottom>{fieldData.explanation}</Typography>
                }
                <Box
                    sx={{
                    marginTop: "1rem",
                    padding: 2,
                    border: "1px solid",
                    borderColor: "#d3d3d3",
                    borderRadius: "6px",
                    maxHeight: "500px",
                    overflow: "auto",
                    }}
                    onClick={handleOpen}
                >
                    {transNewlineCode(inputText) ?? "未記載"}
                </Box>
            </Box>
            {/* 入力用モーダル */}
            <Dialog
                maxWidth="lg"
                open={isOpen}
                onClose={handleClose}
                >
                <DialogTitle>{fieldData.label}</DialogTitle>
                <DialogContent
                    sx={{ bgcolor: "white" }}
                >
                    <DialogContentText>
                    <br />
                    LaTeX の記述方式で数式が使用できます。　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　<br />
                    </DialogContentText>
                    <Box sx={{ mt: 2, mb: 3 }}>
                        <Box sx={{ marginBottom: "2rem" }}>
                            <Controller
                                name={fieldData.name}
                                control={control}
                                rules={fieldData.validation}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        required={fieldData.required}
                                        fullWidth
                                        id={fieldData.name}
                                        multiline={fieldData.multiline}
                                        rows={fieldData.rows}
                                        type={fieldData.type}
                                        error={fieldState.invalid}
                                        helperText={fieldState.error?.message}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ bgcolor: "white" }}
                >
                    <Button onClick={handleClose}>戻る</Button>
                    <Button
                        onClick={update}
                    >
                    更新
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
