import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PDFIcon from "../../assets/icons/PDF.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import defaultEyecatch from "../../assets/imgs/homepage/default_eyecatch.png";
import { css } from "@emotion/react";

const News = ({ items, contentName }) => {
  let storeLng = useSelector((state) => state.language.value);

  return items.map((item) => (
    <Button
      key={item.createdAt}
      component={Link}
      to={"/member/hp/" + contentName + "/" + item.id}
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: { xs: "10px 0px", md: "10px 10px" },
        padding: "20px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px",
        maxWidth: "375px",
        color: "#292929",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <img
          src={item.eyecatch ? item.eyecatch.url : defaultEyecatch}
          style={{
            width: "339px",
            height: "200px",
            maxWidth: "100%",
            maxHeight: "200px",
            objectFit: "cover",
          }}
        ></img>
        <div style={newsTimeText}>{new Date(item.createdAt)?.toLocaleDateString()}</div>
        <div style={{ height: "49px" }}>
          <span style={newsTitleText}>
            {item.title.length >= 45 ? item.title.substring(0, 45) + "..." : item.title}
            {/* {storeLng === "ja"
              ? item.title.substring(0, 40) + "..."
              : item.title_english?.length
              ? item.title_english.substring(0, 40) + "..."
              : item.title.substring(0, 40) + "..."} */}
          </span>
          {item.isPDF && <img src={PDFIcon} alt="PDF File Icon" />}
        </div>
      </div>
    </Button>
  ));
};
const newsTimeText = {
  color: "#AAAAAA",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "3.5px",
};

const newsTitleText = {
  color: "#292929",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  letterSpacing: "1px",
};

export default News;
