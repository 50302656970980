import { Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import questionnaireCompanyApi from "../../api/questionnaireCompanyApi";

export const QuestionnaireSendRegister = () => {
  const [loading, setLoading] = useState(false);
  const [groupNameErrText, setGroupNameErrText] = useState("");
  const [emailErrText, setEmailErrText] = useState("");
  const [usernameErrText, setUsernameErrText] = useState("");
  let error = false;

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const groupName = data.get("groupname") ? data.get("groupname").trim() : "";
    const email = data.get("email") ? data.get("email").trim() : "";
    const userName = data.get("username") ? data.get("username").trim() : "";

    if (groupName === "") {
      error = true;
      setGroupNameErrText("企業名を入力してください。");
    }
    if (email === "") {
      error = true;
      setEmailErrText("送信先EMAILアドレスを入力してください。");
    }
    if (userName === "") {
      error = true;
      setUsernameErrText("送信先担当者名を入力してください。");
    }

    if (error) return;
    setLoading(true);

    const requestData = {
      groupName,
      email,
      userName,
    };

    try {
      questionnaireCompanyApi.register(requestData);
      setLoading(false);
      alert("アンケート送信先の登録が完了しました。");
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h5">アンケート送信先登録画面</Typography>
      <Box component="form" sx={{ mt: 1 }} noValidate onSubmit={handleSubmit}>
        <Box sx={{ margin: "30px 0px" }}>
          <TextField
            id="groupname"
            label="企業名"
            margin="normal"
            name="groupname"
            required
            fullWidth
            helperText={groupNameErrText}
            disabled={loading}
            error={groupNameErrText !== ""}
          ></TextField>
        </Box>
        <Box sx={{ margin: "30px 0px" }}>
          <TextField
            id="email"
            label="送信先EMAILアドレス"
            margin="normal"
            name="email"
            required
            fullWidth
            helperText={emailErrText}
            disabled={loading}
            error={emailErrText !== ""}
          ></TextField>
        </Box>
        <Box sx={{ margin: "30px 0px" }}>
          <TextField
            id="username"
            label="送信先担当者名"
            margin="normal"
            name="username"
            required
            fullWidth
            helperText={usernameErrText}
            disabled={loading}
            error={usernameErrText !== ""}
          ></TextField>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
          }}
        >
          <LoadingButton
            sx={{ mt: 3, mb: 2 }}
            type="submit"
            loading={loading}
            color="primary"
            variant="contained"
            size="large"
            fullWidth
          >
            送信
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
