import React from 'react'
import { useParams } from 'react-router-dom'
import eventApi from '../../../api/eventApi'
import { useEffect } from 'react'
import { Box, Button, Chip, Divider, IconButton, Paper, Typography } from '@mui/material'
import moment from "moment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from 'react-router-dom'

const MemberEventDetails = () => {
    const { id } = useParams()
    const [event, setEvent] = React.useState([])
    const [eventDetails, setEventDetails] = React.useState([])
    const [isBeforeEvent, setIsBeforeEvent] = React.useState(false)
    const [isExpand, setIsExpand] = React.useState(false)
    const TODAY = moment();

    useEffect(() => {
        async function fetchEventDetails() {
            const response = await eventApi.getById({ id: id });
            setEvent(response.event);
            setEventDetails(response.event.eventDesc.substr(0, 300))
            setIsBeforeEvent(moment(response.event.startDate).isBefore(TODAY))
        }
        fetchEventDetails();
    }, [])

    const changeTextLength = () => {
        setIsExpand(!isExpand)

        if (isExpand) {
            setEventDetails(event.eventDesc.substr(0, 300) + "...")
        } else {
            setEventDetails(event.eventDesc)
        }

    }

    return (
        <>
            <Paper sx={{ margin: "0 auto", maxWidth: 800, background: "white" }} elevation={3}>
                <Box sx={{ width: "100%" }}>
                    <img src={event.coverImgUrl} />
                </Box>
                <Box sx={{ p: 2, display: "flex", gap: 2, flexDirection: "column" }}>
                    {isBeforeEvent && (
                        <Chip label="開催済みのイベント" sx={{ mr: 2 }} color="warning" />
                    )}
                    <Box sx={{ p: 3 }}>

                        <Box sx={{ pb: 2 }}>
                            <Typography variant='caption'>開催日時</Typography>
                            <Box sx={{ fontSize: 14 }}>{moment(event.startDate).format("YYYY/MM/DD HH:mm")} ~ {moment(event.endDate).format("HH:mm")}</Box>
                        </Box>
                        <Typography variant='h4' gutterBottom>{event.eventName}</Typography>

                        <Divider variant="middle" />

                        <Box sx={{ mt: 3, mb: 2 }}>
                            <div>
                                <Typography variant='caption' sx={{
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                }}>{eventDetails}
                                </Typography>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <IconButton color="secondary" onClick={changeTextLength} >
                                    {isExpand ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </div>
                        </Box>
                        <Box sx={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                        }}>
                        </Box>
                        {!isBeforeEvent ? (
                            <>
                                <Box sx={{ textAlign: "center", color: "#ff4569", border: "1px solid #ff4569", p: 2, borderRadius: "5px" }}>
                                    <Typography variant='caption'>受付期間</Typography>
                                    <Box sx={{ fontSize: 14 }}>{moment(event.startDate).format("YYYY/MM/DD HH:mm")} ~ {moment(event.endDate).format("YYYY/MM/DD HH:mm")}</Box>
                                </Box>
                                <Button variant="contained" sx={{ mt: 3 }} color="primary" fullWidth component={Link} to={"/webinar-form/" + event._id}>受付フォームへ</Button>
                            </>
                        ) : (event.archiveUrl &&
                            <>
                                {"視聴" + event.archiveUrl?.substring(event.archiveUrl.indexOf("パスコード"))}
                                <Button variant="contained" sx={{ mt: 3 }} color="primary" fullWidth component="a" href={event.archiveUrl}>アーカイブ動画を視聴する</Button>
                            </>
                        )}
                    </Box>


                </Box>
            </Paper>
        </>
    )
}
export default MemberEventDetails