import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
    Box,
    TextField,
    Typography,
} from "@mui/material"

export const TextFieldInput = ({ fieldData }) => {
    // 親コンポーネントは FormProvider でラップされていること
    const { control } = useFormContext()
    console.log(control)

    return (
        <Box mt={3}>
            {fieldData.title
                && <Typography variant="subtitle1" gutterBottom>{fieldData.title}</Typography>
            }
            {fieldData.explanation
                && <Typography variant="subtitle2" gutterBottom>{fieldData.explanation}</Typography>
            }
            <Controller
                name={fieldData.name}
                control={control}
                rules={fieldData.validation}
                render={({ field, fieldState }) => (
                    <TextField
                        {...field}
                        required={fieldData.required}
                        fullWidth
                        id={fieldData.name}
                        multiline={fieldData.multiline}
                        rows={fieldData.rows}
                        type={fieldData.type}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                    />
                )}
            />
        </Box>
    )
}
