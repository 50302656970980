import axiosClient from "./axiosClient";

const chatApi = {
  create: (params) => axiosClient.post("chat/create", params),
  update: (params) => axiosClient.post("chat/update", params),
  deleteById: (params) => axiosClient.post("chat/deleteById", params),
  getById: (params) => axiosClient.post("chat/getById", params),
  getByPkId: (params) => axiosClient.post("chat/getByPkId", params),
  getList: (params) => axiosClient.get("chat/getList", params),
};

export default chatApi;
