import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import { task_setCategory } from "../../../redux/features/taskSlice";

const CategoryDropdown = (field, fieldState) => {
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const dispatch = useDispatch();

  // 初期表示
  useEffect(() => {
    fetchCategoryList();
  }, []);

  useEffect(() => {
    setCategory(field.field.value);
  }, [field.field.value]);

  const fetchCategoryList = async () => {
    setCategoryList([
      { key: "category1", text: "トピック", value: 1 },
      { key: "category2", text: "タスク", value: 2 },
    ]);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    dispatch(task_setCategory(event.target.value));
  };

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <FormControl fullWidth error={fieldState.invalid}>
        <InputLabel>種別</InputLabel>
        <Select
          {...field}
          input={<OutlinedInput label="種別" />}
          value={category}
          MenuProps={MenuProps}
          onChange={handleCategoryChange}
        >
          {categoryList &&
            categoryList.map((item) => (
              <MenuItem key={item.key} value={item.value} style={menuItemStyle}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
        {fieldState.invalid && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default CategoryDropdown;
