import React from "react";
import authApi from "../api/authApi";
import userApi from "../api/userApi";
import memberApi from "../api/member";
import { Box, Typography, TextField, Button, Badge, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import "../assets/css/Global.css";
import { useAuthUserContext, RoleType } from "../providers/auth";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUpdateBadgeFlg } from "../redux/features/sidebarSlice";
import dayjs from "dayjs";

export const MemberList = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [list, setList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const location = useLocation();
  const [filter, setFilter] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [daihyoValue, setDaihyoValue] = useState("");
  const [companyNameValue, setCompanyNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [tantosyaValue, setTantosyaValue] = useState("");
  const [tabValue, setTabValue] = useState("1");

  // 承認済みの会員リスト
  let approvedList = [];

  if (list) {
    approvedList = list.filter(
      (user) =>
        user.companyStatus === 3 &&
        (user.membership === "fullMember" ||
          user.membership === "generalMember" ||
          user.membership === "observerMember" ||
          user.membership === "boardFOfDirectors")
    );
  }

  async function fetchBranchList() {
    const res = await userApi.getList({
      params: {
        sort: {
          joinDay: -1,
        }
      }
    });

    const updatedList = res.users.map((user) => {
      const createdDate = new Date(user.created);
      const updatedUser = { ...user, created: createdDate.toLocaleDateString() };
      return updatedUser;
    });
    setList(updatedList);
  }

  const fetchDeleteList = async () => {
    const deleteCompanyList = await authApi.getCompanyList({
      params: {
        // 退会フラグON の会社のみを絞り込む
        conditions: { isDelete: true },
        sort: { updated: -1 },
      },
    });
    setDeleteList(deleteCompanyList.companys)
  };

  const getCompanyList = async () => {
    const checkedMemberMap = await memberApi.getMemberCheckLog({
      operateUserId: user._id
    });

    let checkedCompanyList = []
    checkedMemberMap.memberCheckLogList.map((checkedMember) => {
      checkedCompanyList.push(checkedMember.checkedCompanyId)
      setCompanyList(checkedCompanyList)
    });
  }

  useEffect(() => {
    if (location.state && location.state.updated) {
      fetchBranchList();
      fetchDeleteList()
      getCompanyList();
      setRole(authUser.roleType);
    }
  }, [location]);

  useEffect(() => {
    fetchBranchList();
    fetchDeleteList()
    getCompanyList();
    // 権限の設定
    setRole(authUser.roleType);
  }, []);

  const handleFilterAndSearch = (event) => {
    const value = event.target.value;
    if (value === "all") {
      // すべてのデータを表示
      setFilter(value);
      setSearchValue("");
      setEmailValue("");
      setCompanyNameValue("");
      setDaihyoValue("");
      setTantosyaValue("");
    } else {
      setFilter(value);
    }
  };

  const handleToDetail = (checkedCompanyId) => {
    // 未確認の場合のみ確認済みログを作成する
    if (!companyList.includes(checkedCompanyId)) {
      memberApi.createMemberCheckLog({
        operateUserId: user._id,
        checkedCompanyId: checkedCompanyId,
      });
      dispatch(setUpdateBadgeFlg(true));
    }
  };

  let filteredList = approvedList.filter((member) => {
    if (!companyList.includes(member.companyId)) {
      member.isMemberNotice = true;
    }

    if (filter === "all") {
      return true;
    } else {
      return member.membership === filter;
    }
  });

  filteredList = filteredList.filter((user) => {
    return user.username && user.username.toLowerCase().includes(searchValue.toLowerCase());
  });
  filteredList = filteredList.filter((user) => {
    return user.email && user.email.toLowerCase().includes(emailValue.toLowerCase());
  });
  filteredList = filteredList.filter((user) => {
    return user.groupName && user.groupName.toLowerCase().includes(companyNameValue.toLowerCase());
  });
  filteredList = filteredList.filter((user) => {
    return user.daihyo && user.daihyo.toLowerCase().includes(daihyoValue.toLowerCase());
  });
  filteredList = filteredList.filter((user) => {
    return user.tantosya && user.tantosya.toLowerCase().includes(tantosyaValue.toLowerCase());
  });

  const columns = [
    {
      field: "isMemberNotice",
      headerName: "",
      width: 5,
      renderCell: (params) => {
        return <Box>{params.value && <Badge color="warning" variant="dot"></Badge>}</Box>;
      },
    },
    {
      field: "companyId",
      headerName: "詳細",
      renderCell: (params) => {
        return (
          <>
            <Link to={"/admin/primary-pass/details/" + params.value}>
              <Button size="small" variant="contained" sx={{ fontSize: "5px", fontWeight: 600 }} onClick={() => handleToDetail(params.value)}>
                詳細へ
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "groupName",
      headerName: "会社名",
      width: 300,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      width: 300,
    },
    {
      field: "daihyo",
      headerName: "代表者名",
      width: 200,
    },
    {
      field: "tantosya",
      headerName: "担当者名",
      width: 200,
    },
    {
      field: "membership",
      headerName: "会員区分",
      width: 150,
      renderCell: (params) => {
        const membership = params.row.membership;
        let statusText = "";
        switch (membership) {
          case "fullMember":
            statusText = "正会員";
            break;
          case "generalMember":
            statusText = "一般会員";
            break;
          case "observerMember":
            statusText = "オブザーバー会員";
            break;
          case "boardOfDirectors":
            statusText = "理事会";
            break;
          case "admin":
            statusText = "管理者";
            break;
          default:
            statusText = "";
        }
        return (
          <Box sx={{ p: 1 }}>
            <Typography component="body2">{statusText}</Typography>
          </Box>
        );
      },
    },
    {
      field: "joinDay",
      headerName: "入会日",
      width: 200,
      valueFormatter: (params) => dayjs(params.value).format('YYYY/MM/DD'),
    },
  ];

  const deleteListColumns = [
    {
      field: "_id",
      headerName: "詳細",
      renderCell: (params) => {
        return (
          <>
            <Link to={"/admin/primary-pass/details/" + params.value}>
              <Button size="small" variant="contained" sx={{ fontSize: "5px", fontWeight: 600 }}>
                詳細へ
              </Button>
            </Link>
          </>
        );
      },
    },
    { field: "groupName", headerName: "会社名", width: 250 },
    {
      field: "joinDay",
      headerName: "入会日",
      width: 200,
      valueFormatter: (params) => dayjs(params.value).format('YYYY/MM/DD'),
    },
    {
      field: "deleteDay",
      headerName: "退会日",
      width: 200,
      valueFormatter: (params) => dayjs(params.value).format('YYYY/MM/DD'),
    },
    {
      field: "deleteReason",
      headerName: "退会理由",
      width: 250,
      renderCell: (params) => {
        if (params && params.value) {
          return (
            <Grid
              container
              justifyContent="left"
              alignItems="center"
              sx={{
                borderRadius: "20px",
              }}
            >
              {params.value}
            </Grid>
          );
        }
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const pageSizeOptions = [25, 50, 100];

  const changeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          会員一覧
        </Typography>
        <div style={{ marginBottom: "20px" }}>
          <Typography variant="body1" gutterBottom>
            会員区分：
            <select value={filter} onChange={handleFilterAndSearch}>
              <option value="all">すべて</option>
              <option value="fullMember">正会員</option>
              <option value="generalMember">一般会員</option>
              <option value="observerMember">オブザーバー会員</option>
            </select>
          </Typography>
        </div>
        <Box sx={{ display: "flex", padding: "5px" }}>
          <div style={{ height: "max-content", width: "100%" }}>
            <TextField
              label="会員名検索"
              value={searchValue}
              style={{ height: "max-content", width: "180px", margin: "10px" }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <TextField
              label="メールアドレス検索"
              value={emailValue}
              style={{ height: "max-content", width: "280px", margin: "10px" }}
              onChange={(e) => setEmailValue(e.target.value)}
            />
            <TextField
              label="会社名検索"
              value={companyNameValue}
              style={{ height: "max-content", width: "280px", margin: "10px" }}
              onChange={(e) => setCompanyNameValue(e.target.value)}
            />
            <TextField
              label="代表者名検索"
              value={daihyoValue}
              style={{ height: "max-content", width: "180px", margin: "10px" }}
              onChange={(e) => setDaihyoValue(e.target.value)}
            />
            <TextField
              label="担当者名検索"
              value={tantosyaValue}
              style={{ height: "max-content", width: "180px", margin: "10px" }}
              onChange={(e) => setTantosyaValue(e.target.value)}
            />
          </div>
        </Box>
      </div>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={changeTab} aria-label="lab API tabs example">
            <Tab label="一覧" value="1" />
            <Tab label="退会一覧" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{ width: "100%", typography: "body1" }}>
            {(role === RoleType.BoardOfDirectors || role === RoleType.Admin) && (
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={filteredList}
                    columns={columns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box sx={{ width: "100%", typography: "body1" }}>
            {(role === RoleType.BoardOfDirectors || role === RoleType.Admin) && (
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={deleteList}
                    columns={deleteListColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            )}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
