import axiosClient from "./axiosClient";

const workDocumentApi = {
  register: (params) => axiosClient.post("workDocument/register", params),
  getById: (id) => axiosClient.get(`workDocument/getById?_id=${id}`),
  examination: (params) => axiosClient.post("workDocument/examination", params),
  nonApproval: (params) => axiosClient.post("workDocument/nonApproval", params),
  getList: () => axiosClient.get("workDocument/list"),
};

export default workDocumentApi;
