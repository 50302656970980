import axiosClient from "./axiosClient";

const attendanceApi = {
  register: (params) => axiosClient.post("attendance/register", params),
  getList: (params) => axiosClient.get("attendance/list", params),
  // updateById: (params) => axiosClient.put('event/updateById', params),
  getById: (params) => axiosClient.post("attendance/getById", params),
  getByAttendanceId: (params) => axiosClient.post("attendance/getByAttendanceId", params),
  sendMail: (params) => axiosClient.post("mail/postEventmail", params),
};

export default attendanceApi;
