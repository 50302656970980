import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateBadgeFlg: false,
  updateProposalBadgeFlg: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setUpdateBadgeFlg: (state, action) => {
      state.updateBadgeFlg = action.payload;
    },
  },
});

export const { setUpdateBadgeFlg } = sidebarSlice.actions;
export default sidebarSlice.reducer;
