import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { MuiFileInput } from "mui-file-input";
import userApi from "../../../api/userApi";
import actWorkApi from "../../../api/actWorkApi";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuthUserContext, RoleType, AuthUserProvider } from "../../../providers/auth";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import uploadImg from "../../../components/common/utils/uploadImg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, option, theme) {
  return {
    fontWeight:
      option.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const MemberProjectResultInput = () => {
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState("");
  const [programName, setProgramName] = useState([]);
  const [projects, setProjects] = useState([]);
  const [works, setWorks] = useState([]);
  const [programId, setProgramId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [workId, setWorkId] = useState("");
  const [actWorkId, setActWorkId] = useState("");
  const [workContent, setWorkContent] = useState("");
  const [period, setPeriod] = useState([]);
  const [progress, setProgress] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [projectWorks, setProjectWorks] = useState([]);
  const [projectWorkGroups, setProjectWorkGroups] = useState([]);
  const [reDisplay, setReDisplay] = useState(false);

  const progressList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const storedUserInfo = useSelector((state) => state.user);

  async function fetchCompany() {
    // 会社情報取得
    const company = await userApi.getCompany({
      id: storedUserInfo.value.companyId,
    });
    setGroupName(company.company.groupName);
  }

  async function fetchProjectJoinList() {
    // プロジェクト参加情報取得
    let actWork = await actWorkApi.getListByUserId({
      params: { userId: storedUserInfo.value._id, status: "1" },
    });
    let actWorks = [];
    actWorks = actWork.actWorks;

    let projectList = [];
    let workList = [];
    let projectwork = {};
    let projectWorkList = [];
    let prevProjectId = "";
    let projectWorkGroupList = [];
    for (let i = 0; i < actWorks.length + 1; i++) {
      // 前回処理したレコードのプロジェクトIDと今回処理するプロジェクトIDが異なる場合
      if (i > 0 && (i === actWorks.length || prevProjectId !== actWorks[i]?.projectId)) {
        projectWorkGroupList.push(projectwork);
        // 初期化
        projectwork = {};
        projectWorkList = [];
      }
      // 件数に達していたらループを抜ける
      if (i === actWorks.length) {
        break;
      }
      // 作業IDに紐づく情報を設定（選択リスト切り替え時に使用）
      const workDetail = {
        actWorkId: actWorks[i]._id,
        workId: actWorks[i].workId,
        workDetails: [actWorks[i].workContent, actWorks[i].period, actWorks[i].progress, actWorks[i].documentName, actWorks[i].documentUrl],
      };
      workList.push(workDetail);
      // プロジェクトに紐づく作業を設定（選択リスト切り替え時に使用）
      let projectWorkInfo = {};
      projectWorkInfo.workId = actWorks[i].workId;
      projectWorkInfo.workName = actWorks[i].workName;
      projectWorkList.push(projectWorkInfo);
      projectwork.projectId = actWorks[i].projectId;
      projectwork.workIds = projectWorkList;
      projectwork.programName = actWorks[i].programName;
      const project = {};
      project._id = actWorks[i].projectId;
      project.name = actWorks[i].projectName;
      if (!projectList.find((p) => p._id === actWorks[i].projectId)) {
        projectList.push(project);
      }
      // 現在処理中のprojectIdを設定
      prevProjectId = actWorks[i].projectId;
    }
    setProjects(projectList);
    setProjectWorks(workList);
    setProjectWorkGroups(projectWorkGroupList);
    // 初期表示は最新日時のものを取得
    actWorks.sort((a, b) => {
      if (a.updated > b.updated) return -1;
      if (a.updated < b.updated) return 1;
      return 0;
    });
    setProgramId(actWorks[0].programId);
    setProgramName(actWorks[0].programName);
    setProjectId(actWorks[0].projectId);
    // プロジェクトに紐づく作業を設定
    const projectWorks = projectWorkGroupList.find((p) => p.projectId === actWorks[0].projectId);
    setWorks(projectWorks.workIds);
    setWorkId(actWorks[0].workId);
    setWorkContent(actWorks[0].workContent);
    setPeriod(actWorks[0].period);
    setActWorkId(actWorks[0]._id);
    setProgress(actWorks[0].progress);
    setDocumentUrl(actWorks[0].documentUrl);
    setDocumentName(actWorks[0].documentName);
  }

  useEffect(() => {
    fetchCompany();
    fetchProjectJoinList();
  }, [reDisplay]);

  // validation
  const { control, formState, getValues, handleSubmit } = useForm({
    defaultValues: {
      programId: programId ?? "",
      programName: "",
      projectId: projectId ?? "",
      projectName: "",
      workId: workId ?? "",
      workName: "",
      progress: progress ?? "",
      resultMaterials: "",
      resultMaterialsname: "",
      userId: "",
    },
  });

  const validationRules = {
    projectId: {
      validate: () => {
        if (!projectId) {
          return "プロジェクト名を入力してください。";
        }
      },
    },
    workId: {
      validate: () => {
        if (!workId) {
          return "作業工程名を入力してください。";
        }
      },
    },
    progress: {
      validate: () => {
        if (!progress || progress === "0") {
          return "進捗状況を入力してください。";
        }
      },
    },
    resultMaterials: {
      validate: (value) => {
        if (!documentUrl && !value) {
          return "成果物をアップロードしてください";
        }
        if (value && value.type !== "application/pdf") {
          return "ファイル形式が間違っています";
        }
        if (value && value.size > 5242880) {
          return "ファイルサイズが大きすぎます";
        }
      },
    },
  };

  const handleProjectChange = (event) => {
    const projectId = event.target.value;
    setProjectId(projectId);
    setWorkId("");
    setWorkContent("");
    setProgress("");
    setPeriod("");
    setDocumentUrl("");
    setDocumentName("");

    // プロジェクトに紐づく作業を再設定
    const projectWorks = projectWorkGroups.find((p) => p.projectId === projectId);
    setWorks(projectWorks.workIds);
    // プロジェクトに紐づくプログラム名を設定
    setProgramName(projectWorks.programName);
  };
  const handleWorkChange = (event) => {
    const workId = event.target.value;
    setWorkId(workId);
    // 作業に紐づく情報を再設定
    const workDetail = projectWorks.find((w) => w.workId === workId);
    setActWorkId(workDetail.actWorkId);
    setWorkContent(workDetail.workDetails[0]);
    setPeriod(workDetail.workDetails[1]);
    setProgress(workDetail.workDetails[2]);
    setDocumentName(workDetail.workDetails[3]);
    setDocumentUrl(workDetail.workDetails[4]);
  };
  const handleProgressChange = (event) => {
    setProgress(event.target.value);
  };

  const onSubmit = async (data) => {
    if (window.confirm("送信します。よろしいですか？")) {
      try {
        const inputForm = document.getElementById("inputForm");
        const inputFormData = new FormData(inputForm);
        const resultMaterialsname = data.resultMaterials.name;
        const resultMaterialsUrl = await uploadImg(data.resultMaterials, ["resultMaterials"]);
        const reqData = {
          _id: inputFormData.get("actWorkId"),
          userId: storedUserInfo.value._id,
          resultMaterialsname: resultMaterialsname,
          resultMaterials: resultMaterialsUrl,
          progress: inputFormData.get("progress"),
        };
        await actWorkApi.updateProgress(reqData);
        alert("送信しました");
        setReDisplay(true);
      } catch (error) {
        console.error(error);
        alert("送信に失敗しました");
      }
    }
  };

  // select
  const theme = useTheme();
  const [option, setOption] = useState([]);

  return (
    <div>
      <h3>プロジェクト成果入力</h3>
      <form id="inputForm" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid xs={12} lg={4}>
            <Typography mb={2}>
              実施会員：{groupName}({storedUserInfo.value.username})
            </Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Typography mb={2}>プログラム名： {programName}</Typography>
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="projectId"
                control={control}
                rules={validationRules.projectId}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel>プロジェクト名</InputLabel>
                    <Select
                      {...field}
                      input={<OutlinedInput label="プロジェクト名" />}
                      value={projectId}
                      onChange={handleProjectChange}
                      MenuProps={MenuProps}
                    >
                      {projects.map((item) => (
                        <MenuItem
                          key={item._id}
                          value={item._id}
                          style={getStyles(item._id, option, theme)}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.invalid && (
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="workId"
                control={control}
                rules={validationRules.workId}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel>作業工程名</InputLabel>
                    <Select
                      {...field}
                      input={<OutlinedInput label="作業工程名" />}
                      value={workId}
                      onChange={handleWorkChange}
                      MenuProps={MenuProps}
                    >
                      {works.map((item) => (
                        <MenuItem
                          key={item.workId}
                          value={item.workId}
                          style={getStyles(item.workId, option, theme)}
                        >
                          {item.workName}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.invalid && (
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <input hidden name="actWorkId" value={actWorkId} />
            </Box>
            <Typography mb={1}>作業概要：</Typography>
            {workContent && (
              <Typography
                sx={{
                  marginBottom: "2rem",
                  marginLeft: "14px",
                  fontWeight: "bold",
                  opacity: "0.7",
                }}
              >
                {workContent}
              </Typography>
            )}
            <Typography mb={1}>マイルストーン：</Typography>
            {period && (
              <Typography
                sx={{
                  marginBottom: "2rem",
                  marginLeft: "14px",
                  fontWeight: "bold",
                  opacity: "0.7",
                }}
              >
                {moment(period[0]).format("YYYY/MM/DD")} ～ {moment(period[1]).format("YYYY/MM/DD")}
              </Typography>
            )}
          </Grid>
          <Grid xs={12} lg={4}>
            <Typography mb={1}>成果入力</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="progress"
                control={control}
                rules={validationRules.progress}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel>進捗状況</InputLabel>
                    <Select
                      {...field}
                      input={<OutlinedInput label="進捗状況" />}
                      value={progress}
                      MenuProps={MenuProps}
                      onChange={handleProgressChange}
                    >
                      {progressList.map((item) => (
                        <MenuItem key={item} value={item} style={getStyles(item, option, theme)}>
                          {item}%
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.invalid && (
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
            <Typography mb={2}>ドキュメントアップロード</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="resultMaterials"
                control={control}
                rules={validationRules.resultMaterials}
                render={({ field, fieldState }) => (
                  <MuiFileInput
                    type="file"
                    fullWidth
                    {...field}
                    error={fieldState.invalid}
                    helperText={
                      fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                    }
                  />
                )}
              />
              {documentUrl && (
                <>
                  アップロード済みドキュメント：
                  <a href={documentUrl} target="_blank" rel="noopener noreferrer">
                    {documentName}
                  </a>
                </>
              )}
            </Box>
            <Button
              // disabled={!formState.isDirty || !formState.isValid}
              variant="contained"
              fullWidth
              sx={{ color: "#fff" }}
              type="submit"
            >
              送信
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
