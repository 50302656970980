import axiosClient from "./axiosClient";

const programApi = {
  getList: (params) => axiosClient.get("program/list", params),
  getProgram: (id) => axiosClient.get(`program?id=${id}`),
  getApprovedList: () => axiosClient.get("program/approvedList"),
  getProgramExaminationInfo: (params) => axiosClient.get("program/programExaminationInfo", params),
  examination: (params) => axiosClient.post("program/examination", params),
  apply: (params) => axiosClient.post("program/register", params),
  modify: (params) => axiosClient.post("program/update", params),
  sendApplicationMail: (params) => axiosClient.post("mail/programpost", params),
  createProgramCheckLog: (params) => axiosClient.post("program/createProgramCheckLog", params),
  getProgramCheckLog: (params) => axiosClient.get("program/getProgramCheckLog", params),
};

export default programApi;
