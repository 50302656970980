import { Typography } from "@mui/material";
import React from "react";
import { Container } from "@mui/system";
import { Box } from "@mui/system";
import ScrollToTopOnMount from "../../components/common/utils/ScrollToTopOnMount";
import { SubpageHeader } from "../../components/homepage/SubpageHeader";

export const Constitution = () => {
  return (
    <>
      <Box>
        <SubpageHeader
          breadcrumb={[{ name: "会員規約", link: "/constitution" }]}
        />
      </Box>
      <Container>
        <Box sx={{ margin: "100px 20px" }}>
          <Typography variant="h4" textAlign="center">
            会員規約
          </Typography>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            1. 目的
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本規約は、ナチュラルキャピタルクレジットコンソーシアム・シンガポール（以下「本会」という）の会員の権利義務等について規定する。  
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            2. 本会事務局の所在地
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会事務局は Tsujita SG に置く。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            3. 本会の目的
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会は、ＳＤＧｓを原動力とし、特に脱炭素価値の評価、分析、認証及び助言を通じた二酸化炭素の削減や、ネイチャーポジティブ、グリーンインフラストラクチャー等に関する事業等に係ることで、シンガポール共和国を含む東南アジア地域における二酸化炭素の削減等に寄与する事を目的とする。本会は、特定の企業の利益の誘導を図ることを目的としない。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            4. 本会の活動内容
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <ol>
              <li>
                本会は、３．の目的を達成するため、日系企業（本邦企業又は本邦企業が出資する会社）のみならず、広く東南アジア地域の企業や政府機関への入会を呼びかけつつ、一般社団法人ナチュラルキャピタルクレジットコンソーシアム（以下「NCCC」という）の助言を受けながら、４．に定める本会の会員としての取組により得られた二酸化炭素吸収量のクレジットの認証及び売買並びにそれを用いたカーボンオフセットの事業化のために必要となる知見その他の二酸化炭素の削減等に寄与する情報を広く会員同士が共有する場を提供する。
              </li>
              <li>
                本会は、当面の間、任意団体として前項の活動を行う。
              </li>
              <li>
                本会は、将来的に本会自体がカーボンクレジット認証プログラム等のサービスを提供し、会員がその利用することを通じて３．の目的を達成することを図るため、シンガポール共和国における法人格の取得等のNCCCに倣った組織体制を構築するための検討及び準備を行う。
              </li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            5. 会員
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <ol>
              <li>
                本会の会員（以下「会員」という。）は、３．の目的及び４．の活動内容に賛同し、自らの事業のために、将来においてカーボンクレジット認証プログラムの利用又はカーボンクレジットの売買を行うための情報収集及び調査・研究を行い、東南アジア地域においてその具体化を図るための検討を行う。
              </li>
              <li>
                会員が、NCCCの提供するカーボンクレジット認証プログラムの利用又はカーボンクレジットの売買を行うためには、NCCC会員規約４．（１）又は（２）に定める会員資格を取得することを要する。
              </li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            6. 入会方法
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <ol>
              <li>
                入会を希望する団体又は個人（以下「申込者」という）は、本会の事務局宛に、名称、シンガポール共和国における活動拠点となる住所その他の所定の入会申込書に定める事項を届け出る。
              </li>
              <li>
                申込者が団体である場合には、同時に団体の代表者又は団体を代理して行為する役職員を届け出るものとする。 
              </li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            7. 会費
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会は、その運営に関し必要な経費が生じた場合には、会員総会において、会員の過半数の合意により、会員より必要な会費を徴収することができる。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            8. 会員の権利
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <ol>
              <li>
                会員は、５．（１）に定める活動を行うために必要な助言をNCCCに求めることができる。 
              </li>
              <li>
                会員は、秘密保持義務、知的財産権の保護又は個人情報の保護等に反しない範囲において、本会の活動状況について報告を受けることができる。
              </li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            9. 退会
          </Typography>
          <Box sx={{ padding: "20px" }}>
            会員は、理事会に退会届を提出することで、いつでも退会できる。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            10. 会員資格の喪失
          </Typography>
          <Box sx={{ padding: "20px" }}>
            会員は、次のいずれかに該当するに至ったときは、その資格を喪失する。
            <ol>
              <li>退会届の提出をしたとき</li>
              <li>個人の会員本人が死亡したとき又は失踪宣告を受けたとき、若しくは類似の手続きを経たとき</li>
              <li>
                団体たる会員が解散し、又は破産、会社更生、民事再生、特別清算手続開始若しくは類似の手続きの決定がなされたとき
              </li>
              <li>会費の支払義務を1年間履行しなかったとき</li>
              <li>入会後、２年以上に亘り連絡が取れないとき</li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            11. 除名
          </Typography>
          <Box sx={{ padding: "20px" }}>
            会員が次のいずれかに該当するに至ったときは、会員総会の決議により除名することができる。 この場合、当該会員に対し、事前に除名する旨を通知し、かつ、会員総会において弁明する機会を与えるものとする。
            <ol>
              <li>本会の名誉を傷つけ、又は本会の目的に反する行為をしたとき</li>
              <li>暴力団員等の反社会的勢力であること、又は関係があると判明したこと</li>
              <li>
                入会の申込みに際し、申告すべき事項の重要な部分について虚偽の申告を行ったことが判明し、会員資格を認めることが著しく妥当性を欠くとき
              </li>
              <li>その他除名すべき正当な事由があるとき</li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            12. 本会の機関
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <ol>
              <li>
                本会の意思決定は、すべての会員により構成される会員総会を招集し、過半数の会員が出席し、出席した会員の過半数によりなされるものとする。
              </li>
              <li>
                各会員は、会員総会において1個の議決権を有する。
              </li>
              <li>
                本会は、３名以内を限度として、会員総会の決議をもって理事を選任することができる。理事の任期は、選任後２年以内に終了する事業年度のうち最終のものに関する定時会員総会の終結の時までとする。但し、再任を妨げない。
              </li>
              <li>
                本会の理事が複数選任された場合には、そのうち１名を代表理事とし、理事の互選により選定する。代表理事は、本会の業務執行を行い、本会を代表する。本会の理事が1名の場合には、当該理事が代表理事となる。
              </li>
              <li>
                代表理事は、少なくとも年1回の定時会員総会及び必要に応じて臨時会員総会を招集する。
              </li>
              <li>
                代表理事は、本会の経理及び財産管理を司り、定時会員総会において、直近に終了した事業年度の決算報告を行い、会員総会の承認を受けるものとする。
              </li>
              <li>
                本会には、代表理事の業務執行を補佐するために事務局を置くことができる     。
              </li>
            </ol>
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            13. 事業年度
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本会の事業年度は、毎年４月１日から翌年３月３１日までとする。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            14. 規約の変更
          </Typography>
          <Box sx={{ padding: "20px" }}>
            本規約は、会員総会の承認の同意をもって変更することができる。
          </Box>
          <Typography variant="h5" sx={{ margin: "20px" }}>
            15. 効力発生日
          </Typography>
          <Box sx={{ padding: "20px" }}>本規約は、令和６年６月７日から有効となる。      以上</Box>
        </Box>
      </Container>
      <ScrollToTopOnMount />
    </>
  );
};
