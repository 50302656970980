import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import CategoryDropdown from "../../../components/common/categoryDropdown";
import BasicDropdown from "../../../components/common/basicDropdown";

import {
    Box,
    TextField,
    Typography,
} from "@mui/material"

export const DropdownInput = ({ fieldData }) => {
    // 親コンポーネントは FormProvider でラップされていること
    const { control } = useFormContext()
    console.log(control)

    const [value, setValue] = useState("");
    const handleChange = (event) => {
        setValue(event.target.value);
        // dispatch(task_setCategory(event.target.value));
    };

    return (
        <Box mt={3}>
            {fieldData.title
                && <Typography variant="subtitle1" gutterBottom>{fieldData.title}</Typography>
            }
            {fieldData.explanation
                && <Typography variant="subtitle2" gutterBottom>{fieldData.explanation}</Typography>
            }
            {/* 条件分岐してカテゴリドロップダウンと固定ドロップダウンに表示分けする */}
            <Controller
                name={fieldData.name}
                control={control}
                rules={fieldData.validation}
                render={({ field, fieldState }) => {
                    fieldData.category !== ""
                    ? <CategoryDropdown
                        categoryName={fieldData.category}
                        categoryLabel=""
                        field={field}
                        fieldState={fieldState}
                        onChange={handleChange}
                    />
                    : <BasicDropdown
                        label=""
                        list={fieldData.itemList}
                        field={field}
                        fieldState={fieldState}
                        onChange={handleChange}
                    />
                }}
            />
        </Box>
    )
}
