import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import branchApi from "../../../api/branchApi";
import branchUserApi from "../../../api/branchUserApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const MemberBranchJoin = () => {
  const navigate = useNavigate();
  const storedUserInfo = useSelector((state) => state.user);
  const [branch, setBranch] = useState({});
  const [branchUser, setBranchUser] = useState({});
  const { id } = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const branchUserId = urlParams.get("branchUserId");

  useEffect(() => {
    async function fetchBranch() {
      const branchData = await branchApi.getById({
        params: { _Id: id },
      });
      setBranch(branchData.branch);
    }
    async function fetchBranchUser() {
      if (branchUserId) {
        const res = await branchUserApi.getTargetOne({ params: { _id: branchUserId } });
        setBranchUser(res.branchUser);
      }
    }
    fetchBranch();
    fetchBranchUser();
  }, []);

  useEffect(() => {
    setValue("activityPurpose", branchUser.activityPurpose);
  }, [branchUser.activityPurpose]);

  // validation
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      companyId: "",
      userId: "",
      branchId: "",
      branchMaster: "",
      activityPurpose: "",
    },
  });

  const validationRules = {
    activityPurpose: {
      required: "申請動機を入力してください",
      maxLength: { value: 800, message: "800文字以内で入力してください" },
    },
  };

  const onSubmit = (data) => {
    if (window.confirm("申請します。よろしいですか？")) {
      // 差し戻されたものを再修正した場合
      if (branchUserId && branchUser.applyflg === 9) {
        data._id = branchUserId;
        data.applyflg = 0;
        branchUserApi.modify(data);
        alert("再申請しました");
      } else {
        data.branchId = id;
        data.userId = storedUserInfo.value._id;
        data.companyId = storedUserInfo.value.companyId;
        data.branchMaster = branch.branchMasterId;
        branchUserApi.register(data);
        alert("申請しました");
      }
      navigate("/member/branches");
    }
  };

  return (
    <div>
      <h3>支部参加申請</h3>
      {branchUser.applyflg === 9 && (
        <Typography mb={2} style={{ color: "#f44336" }}>
          差し戻しコメント：{branchUser.approvalComment}
        </Typography>
      )}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid xs={12} lg={4}>
            <Typography mb={2}>申請者情報</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="userId"
                control={control}
                render={() => (
                  <Box sx={{ marginBottom: "2rem" }}>
                    申請者名：{storedUserInfo?.value?.username || ""}
                  </Box>
                )}
              />
            </Box>

            <Typography mb={2}>支部情報</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="branchId"
                control={control}
                render={() => (
                  <Box sx={{ marginBottom: "2rem" }}>申請支部名：{branch?.branchName || ""}</Box>
                )}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="activityPurpose"
                control={control}
                rules={validationRules.activityPurpose}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="activityPurpose"
                    label="申請動機"
                    multiline
                    rows={6}
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Button variant="contained" fullWidth sx={{ color: "#fff" }} type="submit">
              申請
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
