import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import logo from "../assets/imgs/NCCC_logo_symbol.png";
import questionnaireApi from "../api/questionnaireApi";

function convertNullToZero(target) {
  if (!target || target === "") {
    return 0;
  }
  return target;
}

const Questionnaire = () => {
  const { companyId } = useParams();
  let [groupName, setGroupName] = useState("");
  const [company, setCompany] = useState("");
  const [ncccUnderstandingTextDisplay, setNcccUnderstandingTextDisplay] = useState("none");
  const [learningKnowledgeTextDisplay, setLearningKnowledgeText] = useState("none");
  const [followExpectationsTextDisplay, setFollowExpectationsText] = useState("none");
  const [againParticipationTextDisplay, setAgainParticipationText] = useState("none");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let error = false;
  const [errorText, setErrorText] = useState([""]);
  const [participationReasonErrText, setParticipationReasonErrText] = useState("");
  const [ncccUnderstandingRadioErrText, setNcccUnderstandingRadioErrText] = useState("");
  const [ncccUnderstandingTextErrText, setNcccUnderstandingTextErrText] = useState("");
  const [learningKnowledgeRadioErrText, setLearningKnowledgeRadioErrText] = useState("");
  const [learningKnowledgeTextErrText, setLearningKnowledgeTextErrText] = useState("");
  const [followExpectationsRadioErrText, setFollowExpectationsRadioErrText] = useState("");
  const [followExpectationsTextErrText, setFollowExpectationsTextErrText] = useState("");
  const [againParticipationRadioErrText, setAgainParticipationRadioErrText] = useState("");
  const [againParticipationTextErrText, setAgainParticipationTextErrText] = useState("");

  // 企業名の取得
  useEffect(() => {
    async function fetchCompany() {
      // console.log("companyId :", companyId);
      const res = await authApi.getCompany({ id: companyId });
      setCompany(res.company);
      // console.log("res.company.groupName :", res.company.groupName);
      if (res.company) {
        setGroupName(res.company.groupName);
      }
    }
    fetchCompany();
  }, []);

  const ncccUnderstandingNonRadio = () => {
    setNcccUnderstandingTextDisplay("contents");
  };
  const learningKnowledgeNonRadio = () => {
    setLearningKnowledgeText("contents");
  };
  const followExpectationsNonRadio = () => {
    setFollowExpectationsText("contents");
  };
  const againParticipationNonRadio = () => {
    setAgainParticipationText("contents");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setParticipationReasonErrText("");
    setNcccUnderstandingRadioErrText("");
    setNcccUnderstandingTextErrText("");
    setLearningKnowledgeRadioErrText("");
    setLearningKnowledgeTextErrText("");
    setFollowExpectationsRadioErrText("");
    setFollowExpectationsTextErrText("");
    setAgainParticipationRadioErrText("");
    setAgainParticipationTextErrText("");

    const data = new FormData(e.target);
    const participationReason = data.get("participation-reason");
    const ncccUnderstandingRadio = data.get("nccc-understanding-radio");
    const ncccUnderstandingText = data.get("nccc-understanding-text");
    const learningKnowledgeRadio = data.get("learning-knowledge-radio");
    const learningKnowledgeText = data.get("learning-knowledge-text");
    const followExpectationsRadio = data.get("follow-expectations-radio");
    const followExpectationsText = data.get("follow-expectations-text");
    const againParticipationRadio = data.get("again-participation-radio");
    const againParticipationText = data.get("again-participation-text");

    // if (participationReason === "") {
    //   error = true;
    //   setParticipationReasonErrText("説明会に参加した理由を入力してください。");
    // }
    if (ncccUnderstandingRadio && ncccUnderstandingRadio === "2" && ncccUnderstandingText === "") {
      error = true;
      setNcccUnderstandingTextErrText("理解できなかった理由を入力してください。");
    }
    if (learningKnowledgeRadio && learningKnowledgeRadio === "2" && learningKnowledgeText === "") {
      error = true;
      setNcccUnderstandingTextErrText("この説明会で得たかった事を入力してください。");
    }
    if (
      followExpectationsRadio &&
      followExpectationsRadio === "2" &&
      followExpectationsText === ""
    ) {
      error = true;
      setNcccUnderstandingTextErrText("期待していた事を入力してください。");
    }
    if (
      againParticipationRadio &&
      againParticipationRadio === "2" &&
      againParticipationText === ""
    ) {
      error = true;
      setNcccUnderstandingTextErrText("参画したくない理由を入力してください。");
    }

    if (error) return;
    setLoading(true);

    const questions = [
      convertNullToZero(participationReason),
      convertNullToZero(ncccUnderstandingRadio),
      convertNullToZero(ncccUnderstandingText),
      convertNullToZero(learningKnowledgeRadio),
      convertNullToZero(learningKnowledgeText),
      convertNullToZero(followExpectationsRadio),
      convertNullToZero(followExpectationsText),
      convertNullToZero(againParticipationRadio),
      convertNullToZero(againParticipationText),
    ];

    const requestGroupName = data.get("groupName");
    // console.log("requestgroupName :", requestGroupName);

    const requestData = {
      companyId: companyId,
      groupName: requestGroupName,
      question: questions,
      questionnaireId: "questionnaire1",
      createUser: "作成者A",
    };
    // console.log("requestData :", requestData);
    try {
      const res = await questionnaireApi.register(requestData);
      setLoading(false);
      navigate(`/form-complete`);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          padding: "40px",
          "@media only screen and (max-device-width: 480px)": {
            padding: "40px 20px",
          },
        }}
      >
        <Box
          sx={{
            // display: "flex",
            // flexDirection: "row",
            gap: "50px",
            // alignItems: "center",
          }}
        >
          <Box>
            <img src={logo} alt="logo" style={{ maxWidth: "76px" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: "left",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                fontWeight: "bold",
                fontSize: "2.0rem",
                color: "#E53935",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* { groupName }　御中 */}
            </Box>
            <br />
            <Box
              sx={{
                display: "flex",
                fontSize: "1.0rem",
                justifyContent: "right",
                // alignItems: "flex-start",
              }}
            >
              一般社団法人ナチュラルキャピタルクレジットコンソーシアム
              <br />
              理事長　馬奈木俊介
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "#EAEAEA",
            textAlign: "left",
            fontSize: "24px",
            fontWeight: "bold",
            padding: "20px",
            margin: "70px 0",
            "@media only screen and (max-device-width: 480px)": {
              fontSize: "24px",
              margin: "10px 0",
            },
          }}
        >
          一般社団法人ナチュラルキャピタルクレジットコンソーシアム設立総会・CO2削減に取り組むコンシャス企業に向けた説明会後のアンケートにご協力ください。
        </Box>

        <h2>一般社団法人ナチュラルキャピタルクレジットコンソーシアム説明会　事後アンケート</h2>
        <br />
        <Box component="form" sx={{ mt: 1 }} noValidate onSubmit={handleSubmit}>
          <Box>
            <h3>★説明会に参加した理由</h3>
            <TextField
              fullWidth
              id="participation-reason"
              name="participation-reason"
              label=""
              margin="normal"
              multiline
              rows={2}
              helperText={participationReasonErrText}
              error={participationReasonErrText !== ""}
            ></TextField>
          </Box>
          <br />
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={loading}
            error={ncccUnderstandingRadioErrText !== ""}
          >
            <FormLabel id="nccc-understanding-radio-buttons-group-label">
              <h3>★一般社団法人NCCCの取り組みについて、理解出来ましたか？</h3>
            </FormLabel>
            <RadioGroup
              row
              id="nccc-understanding-radio"
              name="nccc-understanding-radio"
              aria-labelledby="nccc-understanding-radio-buttons-group-label"
              defaultValue=""
            >
              <FormControlLabel value="1" control={<Radio />} label="はい、理解できた" />
              <FormControlLabel
                value="2"
                control={<Radio onChange={ncccUnderstandingNonRadio} />}
                label="いいえ、あまり理解できなかった"
              />
            </RadioGroup>
            <FormHelperText>{ncccUnderstandingRadioErrText}</FormHelperText>
          </FormControl>
          <br />
          <Box
            sx={{
              display: { ncccUnderstandingTextDisplay },
            }}
          >
            <h4>
              →あまり理解できなかったとお答えの方へ
              <br />
              その理由
            </h4>
            <TextField
              fullWidth
              id="nccc-understanding-text"
              name="nccc-understanding-text"
              label=""
              margin="normal"
              multiline
              rows={2}
            ></TextField>
          </Box>
          <br />
          <FormControl>
            <FormLabel id="learning-knowledge-radio-buttons-group-label">
              <h3>★この説明会に参加して、新しい情報や知識を得られましたか？</h3>
            </FormLabel>
            <RadioGroup
              row
              id="learning-knowledge-radio"
              name="learning-knowledge-radio"
              aria-labelledby="learning-knowledge-radio-buttons-group-label"
              defaultValue=""
            >
              <FormControlLabel value="1" control={<Radio />} label="はい、得られた" />
              <FormControlLabel
                value="2"
                control={<Radio onChange={learningKnowledgeNonRadio} />}
                label="いいえ、得られなかった"
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: { learningKnowledgeTextDisplay },
            }}
          >
            <h4>
              →得られなかったとお答えの方へ
              <br />
              この説明会で得たかった事
            </h4>
            <TextField
              fullWidth
              id="learning-knowledge-text"
              name="learning-knowledge-text"
              label=""
              margin="normal"
              multiline
              rows={2}
            ></TextField>
          </Box>
          <br />
          <FormControl>
            <FormLabel id="follow-expectations-radio-buttons-group-label">
              <h3>★この説明会の内容は、あなたの期待に沿っていましたか？</h3>
            </FormLabel>
            <RadioGroup
              row
              id="follow-expectations-radio"
              name="follow-expectations-radio"
              aria-labelledby="follow-expectations-radio-buttons-group-label"
              defaultValue=""
            >
              <FormControlLabel value="1" control={<Radio />} label="はい、期待通りだった" />
              <FormControlLabel
                value="2"
                control={<Radio onChange={followExpectationsNonRadio} />}
                label="いいえ、期待通りではなかった"
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: { followExpectationsTextDisplay },
            }}
          >
            <h4>
              →期待通りではなかったとお答えの方へ
              <br />
              期待していた事
            </h4>
            <TextField
              fullWidth
              id="follow-expectations-text"
              name="follow-expectations-text"
              label=""
              margin="normal"
              multiline
              rows={2}
            ></TextField>
          </Box>
          <br />
          <FormControl>
            <FormLabel id="again-participation-radio-buttons-group-label">
              <h3>★今後、一般社団法人NCCC参画に参加したいと思いますか？</h3>
            </FormLabel>
            <RadioGroup
              row
              id="again-participation-radio"
              name="again-participation-radio"
              aria-labelledby="again-participation-radio-buttons-group-label"
              defaultValue=""
            >
              <FormControlLabel value="1" control={<Radio />} label="はい、参加したい" />
              <FormControlLabel
                value="2"
                control={<Radio onChange={againParticipationNonRadio} />}
                label="いいえ、参加したくない"
              />
              <FormControlLabel value="3" control={<Radio />} label="わからない" />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: { againParticipationTextDisplay },
            }}
          >
            <h4>
              →参画したくないと答えた方へ
              <br />
              その理由
            </h4>
            <TextField
              fullWidth
              id="again-participation-text"
              name="again-participation-text"
              label=""
              margin="normal"
              multiline
              rows={2}
            ></TextField>
          </Box>
          <Box>
            <h3>★今後、一般社団法人NCCCに期待すること</h3>
            <TextField
              fullWidth
              id="nccc-expectations"
              name="nccc-expectations"
              label=""
              margin="normal"
              multiline
              rows={2}
            ></TextField>
          </Box>
          <Box>
            <h3>★その他感想</h3>
            <TextField
              fullWidth
              id="other"
              name="other"
              label=""
              margin="normal"
              multiline
              rows={2}
            ></TextField>
          </Box>
          <Box>
            <LoadingButton
              sx={{ mt: 3, mb: 2, alignItems: "center" }}
              type="submit"
              loading={loading}
              color="primary"
              variant="contained"
              size="large"
            >
              回答する
            </LoadingButton>
          </Box>
          {/* 仮対応 */}
          <Box sx={{ display: "none" }}>
            <TextField
              fullWidth
              id="groupName"
              name="groupName"
              value={groupName}
              margin="normal"
              multiline
              rows={2}
            ></TextField>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Questionnaire;
