import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import logo from "../../../assets/imgs/NCCC_logo_symbol.png";
import eventApi from "../../../api/eventApi";
import attendanceApi from "../../../api/attendanceApi";
import moment from "moment";
import NumberField from "../../../components/common/NumberField";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RoleType } from "../../../providers/auth";
import validator from "validator";

export const WebinarForm = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = React.useState(false);
  let error = false;
  const [errorText, setErrorText] = useState([""]);
  const [event, setEvent] = useState();
  const [eventName, setEventName] = useState("");
  const [eventDesc, setEventDesc] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [formType, setFormType] = useState();
  const [joinNum, setJoinNum] = useState(1);
  const storedUserInfo = useSelector((state) => state.user);

  useEffect(() => {
    async function fetchEvent() {
      const response = await eventApi.getById({ id: slug });
      // console.log(response);
      if (response.event) {
        // 開催済みの場合、Thanks画面に遷移
        if (RoleType.Admin !== storedUserInfo.value.membership && dayjs(response.event.startDate).isBefore(dayjs())) {
          navigate("/form-complete?status=closed");
        }
        setEvent(response.event);
        setEventName(response.event.eventName);
        setEventDesc(response.event.eventDesc);
        const formatedEventDate =
          moment(response.event.startDate).format("YYYY年MM月DD日 HH時mm分") +
          "〜" +
          moment(response.event.endDate).format("HH時mm分");
        setEventDate(formatedEventDate);
        setFormType(response.event.formType);
      }
    }
    fetchEvent();
  }, [slug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorText([]);
    const data = new FormData(e.target);
    // 必須チェック
    const usernameSei = data.get("username_sei");
    const usernameMei = data.get("username_mei");
    const usernameSeiKana = data.get("username_sei_kana");
    const usernameMeiKana = data.get("username_mei_kana");
    const email = data.get("email");
    const companyName = data.get("companyName");
    if (usernameSei === "") {
      error = true;
      setErrorText((arr) => [...arr, "氏名（姓）を入力してください。"]);
    }
    if (usernameMei === "") {
      error = true;
      setErrorText((arr) => [...arr, "氏名（名）を入力してください。"]);
    }
    if (usernameSeiKana === "") {
      error = true;
      setErrorText((arr) => [...arr, "氏名（セイ）を入力してください。"]);
    }
    if (usernameMeiKana === "") {
      error = true;
      setErrorText((arr) => [...arr, "氏名（メイ）を入力してください。"]);
    }
    if (email === "") {
      error = true;
      setErrorText((arr) => [...arr, "メールアドレスを入力してください。"]);
    } else if (!validator.isEmail(email)) {
      error = true;
      setErrorText((arr) => [...arr, "正しいメールアドレスを入力してください。"]);
    }
    if (companyName === "") {
      error = true;
      setErrorText((arr) => [...arr, "会社名を入力してください。"]);
    }
    if (error) {
      window.scrollTo(0, 700);
      return;
    }

    setLoading(true);

    const requestData = {
      id: slug,
      eventId: slug,
      username_sei: usernameSei,
      username_mei: usernameMei,
      username_sei_kana: usernameSeiKana,
      username_mei_kana: usernameMeiKana,
      email: email,
      companyName: companyName,
      devisionName: data.get("devisionName"),
      businessRole: data.get("businessRole"),
      joinNum: joinNum,
      freeTextJoinPeoples: data.get("freeTextJoinPeoples"),
      paymentMethod: data.get("paymentMethod"),
      receiptAddress: data.get("receiptAddress"),
      reasonOfJoin: data.get("reasonOfJoin"),
      others: data.get("others"),
    };
    const mailRequestData = {
      eventId: slug,
      mailId: formType === 0 ? "14" : "29",
      username_sei: data.get("username_sei"),
      username_mei: data.get("username_mei"),
      email: data.get("email"),
      companyName: data.get("companyName"),
    };

    const res = await attendanceApi.register(requestData);
    const mailResponse = await attendanceApi.sendMail(mailRequestData);

    if (mailResponse) {
      console.log("mail sent");
      setLoading(false);
      navigate("/form-complete");
    } else {
      setLoading(false);
      console.log("mail not sent");
    }
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          minWidth: { md: "1024px" },
          padding: "40px",
          zIndex: "15",
          "@media only screen and (max-device-width: 480px)": {
            padding: "40px 20px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "50px",
            justifyContent: "center",
            "@media only screen and (max-device-width: 480px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <img src={logo} alt="logo" style={{ maxWidth: "76px" }} />
        </Box>

        <Box sx={{ textAlign: "end", marginTop: "40px" }}>
          一般社団法人ナチュラルキャピタルクレジットコンソーシアム
        </Box>
        <Box
          sx={{
            backgroundColor: "#EAEAEA",
            textAlign: "left",
            fontSize: "36px",
            padding: "20px",
            margin: "70px 0",
            "@media only screen and (max-device-width: 480px)": {
              fontSize: "24px",
              margin: "10px 0",
            },
          }}
        >
          {eventName}
        </Box>

        <Box
          sx={{
            margin: "40px 20px",
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "left",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ pt: 1, pb: 5 }}>
            開催概要
          </Typography>
          <Box sx={{ margin: "20px 20px" }}>
            <Box>
              開催日時：
              {eventDate}
            </Box>
            <Box
              sx={{
                mt: 2,
                maxWidth: "100%",
              }}
            >
              <p
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-all",
                }}
              >
                {eventDesc}
              </p>
            </Box>
          </Box>
        </Box>

        <Box sx={{ padding: "0px", color: "red", fontSize: "14px" }}>
          {errorText.map((text) => {
            return (
              <Box key={text}>
                <p>{text}</p>
              </Box>
            );
          })}
        </Box>
        <Box component="form" sx={{ mt: 1 }} noValidate onSubmit={handleSubmit}>
          <Box sx={{ maxWidth: "800px", margin: "0 auto", textAlign: "center" }}>
            <Typography variant="h5" sx={{ pt: 5, pb: 5 }}>
              受付フォーム
            </Typography>

            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                id={"username_sei"}
                label="氏名（姓）"
                margin="normal"
                name={"username_sei"}
                required
                fullWidth
              ></TextField>
              <TextField
                id={"username_mei"}
                label="氏名（名）"
                margin="normal"
                name={"username_mei"}
                required
                fullWidth
              ></TextField>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                id={"username_sei_kana"}
                label="氏名（セイ）"
                margin="normal"
                name={"username_sei_kana"}
                required
                fullWidth
              ></TextField>
              <TextField
                id={"username_mei_kana"}
                label="氏名（メイ）"
                margin="normal"
                name={"username_mei_kana"}
                required
                fullWidth
              ></TextField>
            </Box>

            <Box>
              <TextField
                id={"email"}
                label="メールアドレス"
                margin="normal"
                name={"email"}
                required
                fullWidth
              ></TextField>
            </Box>

            <Box>
              <TextField
                id={"companyName"}
                label="会社名"
                margin="normal"
                name={"companyName"}
                required
                fullWidth
              ></TextField>
            </Box>

            <Box>
              <TextField
                id={"devisionName"}
                label="部署名"
                margin="normal"
                name={"devisionName"}
                fullWidth
              ></TextField>
            </Box>

            <Box>
              <TextField
                id={"businessRole"}
                label="役職"
                margin="normal"
                name={"businessRole"}
                fullWidth
              ></TextField>
            </Box>

            {formType === 1 ? (
              // タイプBのフォームを表示
              <>
                <Box>
                  <NumberField
                    id={"joinNum"}
                    label="参加人数"
                    margin="normal"
                    name={"joinNum"}
                    state={joinNum}
                    onChange={(resultValue) => {
                      setJoinNum(resultValue);
                    }}
                    fullWidth
                  ></NumberField>
                </Box>
                {joinNum > 1 ? (
                  <>
                    <TextField
                      id={"freeTextJoinPeoples"}
                      label="複数名でご参加の場合、同行の方のお名前をご記載ください。(自由記入)"
                      margin="normal"
                      name={"freeTextJoinPeoples"}
                      multiline
                      rows={4}
                      fullWidth
                    ></TextField>
                  </>
                ) : null}
                {/* 支払方法 */}
                <Box sx={{ mt: 2, mb: 2 }}>
                  <FormControl component="fieldset">
                    <FormLabel>支払方法</FormLabel>
                    <RadioGroup
                      row
                      aria-label="paymentMethod"
                      name="paymentMethod"
                      defaultValue="1"
                    >
                      <FormControlLabel value="1" control={<Radio />} label="事前振込" />
                      <FormControlLabel value="2" control={<Radio />} label="当日現金払い" />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <TextField
                  id={"receiptAddress"}
                  label="領収書の宛名"
                  margin="normal"
                  name={"receiptAddress"}
                  helperText="※複数名でご参加の場合、必要な枚数と、1枚当たりの金額をご記載ください。"
                  multiline
                  rows={4}
                  fullWidth
                ></TextField>
                <TextField
                  id={"reasonOfJoin"}
                  label="参加希望理由"
                  margin="normal"
                  name={"reasonOfJoin"}
                  multiline
                  rows={4}
                  fullWidth
                ></TextField>
                <TextField
                  id={"others"}
                  label="備考"
                  margin="normal"
                  name={"others"}
                  multiline
                  rows={4}
                  fullWidth
                ></TextField>
              </>
            ) : null}

            <InputLabel id="demo-simple-select-label">
              <Checkbox
                onChange={(event) => {
                  setAgreement(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <a href="/privacypolicy" target="blank">
                プライバシーポリシー
              </a>
              に同意する
            </InputLabel>

            <LoadingButton
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              type="submit"
              loading={loading}
              color="primary"
              variant="contained"
              disabled={!agreement}
              size="large"
            >
              送信
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
