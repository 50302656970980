import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, List, ListItemButton } from "@mui/material";
import Logo from "./Logo";
import HierarchyIcon from "../../assets/icons/hierarchy.svg";
import ApplyIcon from "../../assets/icons/apply.svg";
import DocumentIcon from "../../assets/icons/document.svg";
import EllipsisIcon from "../../assets/icons/ellipsis.svg";
import { useTranslation } from "react-i18next";

import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import logo from "../../assets/imgs/NCCC_logo_symbol.png";
import PublicIcon from "@mui/icons-material/Public";
import { setLng } from "../../redux/features/languageSlice";
import { useDispatch, useSelector } from "react-redux";

const HomepageSideBar = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  let storeLng = useSelector((state) => state.language.value);

  const toggleMenu = (newOpen) => {
    setIsOpen(newOpen);
  };

  const location = useLocation();
  useEffect(() => {
    if (!storeLng) {
      storeLng = "ja";
    }
    setIsOpen(false);
  }, [location, storeLng]);

  const [openOrganization, setOpenOrganization] = useState(false);
  const [openOther, setOpenOther] = useState(false);
  const handleClickOrganization = () => {
    setOpenOrganization(!openOrganization);
  };
  const handleClickOther = () => {
    setOpenOther(!openOther);
  };
  const changeLanguage = (event) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng);
    dispatch(setLng(lng));
  };
  const menuDrawer = (
    <>
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={() => toggleMenu(false)}
        sx={{ width: 250, height: "100vh" }}
      >
        <Box
          sx={{
            padding: "20px",
            backgroundColor: "#78B94F",
          }}
        >
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <List
          sx={{
            width: 250,
            height: "100vh",
            backgroundColor: "#78B94F",
            color: "#fff",
          }}
        >
          <Box sx={{ padding: "10px" }} />
          <ListItemButton onClick={handleClickOrganization}>
            <ListItemIcon>
              <img src={HierarchyIcon} alt="組織情報のアイコン" />
            </ListItemIcon>
            <ListItemText primary={t("home.navi.aboutNCCC.title")} />
            {openOrganization ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openOrganization} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/organization">
                <ListItemButton sx={{ pl: 4, color: "#fff" }}>
                  <ListItemIcon>
                    <ArrowCircleRightOutlinedIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText primary={t("home.navi.aboutNCCC.submenu.aboutPurpose")} />
                </ListItemButton>
              </Link>
              <Link to="/about">
                <ListItemButton sx={{ pl: 4, color: "#fff" }}>
                  <ListItemIcon>
                    <ArrowCircleRightOutlinedIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText primary={t("home.navi.aboutNCCC.submenu.general")} />
                </ListItemButton>
              </Link>
              {storeLng === "ja" ? (
                <Link to="/constitution">
                  <ListItemButton sx={{ pl: 4, color: "#fff" }}>
                    <ListItemIcon>
                      <ArrowCircleRightOutlinedIcon sx={{ color: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText primary={t("home.navi.aboutNCCC.submenu.constitution")} />
                  </ListItemButton>
                </Link>
              ) : (
                <Link to="/comingsoon">
                  <ListItemButton sx={{ pl: 4, color: "#fff" }}>
                    <ListItemIcon>
                      <ArrowCircleRightOutlinedIcon sx={{ color: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText primary={t("home.navi.aboutNCCC.submenu.constitution")} />
                  </ListItemButton>
                </Link>
              )}
              <Link to="/members">
                <ListItemButton sx={{ pl: 4, color: "#fff" }}>
                  <ListItemIcon>
                    <ArrowCircleRightOutlinedIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText primary={t("home.navi.aboutNCCC.submenu.members")} />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
          <Link to="/news">
            <ListItemButton sx={{ color: "#fff" }}>
              <ListItemIcon>
                <img src={DocumentIcon} alt="お知らせのアイコン" />
              </ListItemIcon>
              <ListItemText primary={t("home.navi.info.title")} />
            </ListItemButton>
          </Link>
          <Link to="/status">
            <ListItemButton sx={{ color: "#fff" }}>
              <ListItemIcon>
                <PublicIcon sx={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary={t("home.navi.newsOfCC.title")} />
            </ListItemButton>
          </Link>
          <Link to="/accept_guide">
            <ListItemButton sx={{ color: "#fff" }}>
              <ListItemIcon>
                <img src={ApplyIcon} alt={t("home.navi.accept.title")} />
              </ListItemIcon>
              <ListItemText primary={t("home.navi.accept.title")} />
            </ListItemButton>
          </Link>
          {storeLng === "ja" ? (
            <>
              <ListItemButton onClick={handleClickOther}>
                <ListItemIcon>
                  <img src={EllipsisIcon} alt="その他のアイコン" />
                </ListItemIcon>
                <ListItemText primary={t("home.navi.other.title")} />
                {openOther ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openOther} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/privacypolicy">
                    <ListItemButton sx={{ pl: 4, color: "#fff" }}>
                      <ListItemIcon>
                        <ArrowCircleRightOutlinedIcon sx={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary={t("home.navi.other.submenu.privacypolicy")} />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
            </>
          ) : (
            <>
              <ListItemButton onClick={handleClickOther}>
                <ListItemIcon>
                  <img src={EllipsisIcon} alt="その他のアイコン" />
                </ListItemIcon>
                <ListItemText primary={t("home.navi.other.title")} />
                {openOther ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openOther} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/comingsoon">
                    <ListItemButton sx={{ pl: 4, color: "#fff" }}>
                      <ListItemIcon>
                        <ArrowCircleRightOutlinedIcon sx={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText primary={t("home.navi.other.submenu.privacypolicy")} />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
            </>
          )}
        </List>
        <Box sx={{ display: "flex", flexDirection: "column", color: "white" }}>
          <InputLabel id="demo-simple-select-label">言語/Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={storeLng}
            label="言語/Language"
            onChange={changeLanguage}
          >
            <MenuItem value="ja">日本語</MenuItem>
            <MenuItem value="en">English</MenuItem>
          </Select>
        </Box>
      </Drawer>
    </>
  );

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        height: "60px",
        background: "#122c44",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px",
        zIndex: 1000,
      }}
    >
      <Box sx={{ width: "32px" }}></Box>
      <Box>
        <Link to="/">
          <img src={logo} alt="logo" width="28px" />
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "32px",
          height: "32px",
          borderRadius: "32px",
          color: "#fff",
          cursor: "pointer",
          zIndex: 1000,
        }}
        onClick={() => toggleMenu(true)}
      >
        <MenuIcon></MenuIcon>
      </Box>
      {menuDrawer}
    </Box>
  );
};

export default HomepageSideBar;
