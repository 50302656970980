import axiosClient from "./axiosClient";

const invoiceApi = {
  get: (params) => axiosClient.get("invoice/get", params),
  create: (params) => axiosClient.post("invoice/create", params),
  update: (params) => axiosClient.post("invoice/update", params),
  upsert: (params) => axiosClient.post("invoice/upsert", params),

  annualFeePdfCreator: (params) => axiosClient.post("invoice/annualFeePdfCreator", params),
  annualFeePdfSender: (params) => axiosClient.post("invoice/annualFeePdfSender", params),
};

export default invoiceApi;
