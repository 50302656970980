import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tooltip,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { MuiFileInput } from "mui-file-input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import ja from "dayjs/locale/ja";
import dayjs from "dayjs";
import projectApi from "../../../api/projectApi";
import programApi from "../../../api/programApi";
import branchApi from "../../../api/branchApi";
import authApi from "../../../api/authApi";
import mailApi from "../../../api/mailApi";
import counterApi from "../../../api/counterApi";
import categoryApi from "../../../api/categoryApi";

import uploadImg from "../../../components/common/utils/uploadImg";
import CategoryDropdown from "../../../components/common/categoryDropdown";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, option, theme) {
  return {
    fontWeight:
      option.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const CreateVerificationReport = () => {
  const navigate = useNavigate();
  const storedUserInfo = useSelector((state) => state.user);
  const [company, setCompany] = useState({});
  const [programs, setPrograms] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isRevalidation, setIsRevalidation] = useState(false);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const programId = urlParams.get("programId");
  const branchId = urlParams.get("branchId");

  const steps = [
    {
      label: "基本情報",
    },
    {
      label: "プロジェクト概要",
    },
    {
      label: "検証プロセス",
    },
    {
      label: "検証結果①",
    },
    {
      label: "検証結果②",
    },
    {
      label: "検証の意見",
    },
    {
      label: "検証機関の委員リスト",
    },
    {
      label: "商業上の機密情報",
    },
    {
      label: "補足事項等",
    },
  ];

  const fetchprogramList = async () => {
    // 承認済みのプログラム
    const approvedPrograms = await programApi.getApprovedList();
    setPrograms(approvedPrograms);

    // 承認済みの支部
    const approvedBranches = await branchApi.getApprovedList();
    const filterdBranches = approvedBranches.filter(
      (branch) => branch.branchMasterId === storedUserInfo.value._id
    );
    setBranches(filterdBranches);

    const companyData = await authApi.getCompany({
      id: storedUserInfo.value.companyId,
    });
    setCompany(companyData.company);
  };

  const fetchProjectList = async () => {
    const projects = await projectApi.getList({
      params: {
        projectStatus: "20",
      },
    });
    setProjectList(projects.projects);
  };

  const sendApplyMail = async (data, planDocuments, additionalDocuments, projectId) => {
    const user = storedUserInfo.value;
    const program = await programApi.getProgram(data.programId);
    const branch = await branchApi.getBranch(data.branchId);

    const replaceWords = {
      applyUserName: user.username,
      groupName: company.groupName,
      projectName: data.projectName,
      programName: program.programName,
      branchName: branch.branchName,
      projectDesc: data.projectDesc,
      projectStart: new Date(data.startDate),
      projectEnd: new Date(data.endDate),
      planDocuments,
      additionalDocuments,
      url: "/member/projects/apply/" + projectId,
    };

    const reqData = { mailId: "22", replaceWords };
    await mailApi.apply(reqData);
  };

  useEffect(() => {
    fetchprogramList();
    fetchProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // validation
  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      historyNo: 0,
      // 1 基本情報
      projectSelect: "",
      validationCheckWoker: "",
      overview: "",
      // 2.1 目的
      validationIssue: "",
      // 2.2 範囲と基準
      validationRangeAndStandard: "",
      // 2.3 前提条件の妥当性
      validationPremise: "",
      // 2.4 プロジェクトの概要説明
      projectOverview: "",
      // 3.1 方法と基準
      methodAndStandard: "",
      // 3.2 書類審査
      docExamination: "",
      // 3.3 インタビュー
      interview: "",
      // 3.4 現地視察
      inspection: "",
      // 3.5 発見事項の解決
      resolveFindings: "",
      // 3.6 バリデーション活動への参加資格
      eligibilityJoinValidation: "",
      // 4 プロジェクトの詳細
      projectConclusion: "",
      auditHistory: "",
      sectorRange: "",
      projectAfoluCategory: "",
      projectCategory: "",
      projectEligibility: "",
      projectAfoluEligibility: "",
      transferEligibility: "",
      projectDesign: "",
      projectOwnership: "",
      projectStartDate: "",
      projectCreditTerm: "",
      projectScale: "",
      removeAmountPossibility: "",
      techAndMeans: "",
      projectSchedule: "",
      projectPlace: "",
      beforeStartingProject: "",
      legalCompliance: "",
      noDuplicatePublish: "",
      noDuplicateClaim1: "",
      noDuplicateClaim2: "",
      noDuplicateClaim3: "",
      sdContribution: "",
      projectAddInfo: "",
      // 5.1 ステークホルダーの参画と協議
      stakeholderRegulations: "",
      // 5.2 ステークホルダーと環境へのリスク
      envRisk: "",
      // 5.3 人権と公平性の尊重
      fairness: "",
      // 5.4 生態系の健全性
      soundness: "",
      // 5.5	持続可能な開発への貢献
      sdContribution2: "",
      // 5.6	プロジェクトに関連する追加情報
      projectAddInfo2: "",
      // 6.1 方法論のタイトルと参考文献
      program: "",
      methodologyType: "",
      methodologyRefId: "",
      methodologyTitle: "",
      methodologyVersion: "",
      // 6.2 方法論の適用性
      programApplicability: "",
      // 6.3 プロジェクト境界
      baselineOrSource: "",
      gasInclude: "",
      boundaryExplain: "",
      projectBoundaryFile: "",
      // 6.4 ベースライン・シナリオ
      baselineScenario: "",
      // 6.5 追加性
      additionality: "",
      // 6.6 GHG排出削減量と二酸化炭素除去量の定量化
      quantification: "",
      // 6.7 方法論の逸脱
      deviateProgram: "",
      // 6.8 モニタリング計画
      monitoringPlan: "",
      // 6.9 非永続的リスク分析
      nonPersistentRiskAnalysis: "",
      // 7.1 検証の概要
      verificationOverview: "",
      // 7.2 検証の結論
      verificationConclusion: "",
      // 8.2 プロジェクト排出量
      projectEmissions: "",
      // 8.3 リーケージ排出量
      leakageEmissions: "",
      // 7.2 GHG排出削減量と二酸化炭素除去量の推定値
      nonPermanentRiskEvaluation: "",
      updateLtaDoc: "",
      // projectLTA: "",
      // NonUpdateLtaReason: "",
      // expectGhgEmissions: "",
      // NotBelowLtaReason: "",
      estimatedBaselineEmissions: "",
      estimatedProjectEmissions: "",
      estimatedLeakageEmissions: "",
      estimatedReductionCredit: "",
      estimatedRemoveCredit: "",
      estimatedTotalCredit: "",
      // 9.1
      monitoringDataOrParam1: "",
      monitoringDataUnit1: "",
      monitoringDataContents1: "",
      monitoringDataSource1: "",
      monitoringApplicableValue1: "",
      monitoringExplain1: "",
      monitoringPurpose1: "",
      monitoringComment1: "",
      // 9.2
      monitoringDataOrParam2: "",
      monitoringDataUnit2: "",
      monitoringDataContents2: "",
      monitoringDataSource2: "",
      monitoringExplain2: "",
      monitoringFrequency: "",
      monitoringApplicableValue2: "",
      monitoringDevice: "",
      monitoringQaQcProcedure: "",
      monitoringPurpose2: "",
      howToCalc: "",
      monitoringComment2: "",
      // 9 補足事項等(商業上の機密情報)
      secretSection: "",
      secretInfo: "",
      secretJustification: "",
      secretConclusion: "",
      // 10 その他・補足事項等
      otherInfo: "",
    },
  });

  const validationRules = {
    branchId: {
      required: "支部名を選択してください",
    },
    projectName: {
      required: "プロジェクト名を入力してください。",
      maxLength: { value: 20, message: "20文字以内で入力してください" },
    },
    programId: {
      required: "適応するプログラムを選択してください",
    },
    projectDesc: {
      required: "プロジェクト概要を入力してください",
      maxLength: { value: 800, message: "800文字以内で入力してください" },
    },
    startDate: {
      required: "プロジェクト開始日を入力してください",
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!dayjs(formatDate).isValid()) {
          return "日付形式が間違っています";
        }
        // 相関チェック　日付の前後チェック
        const endDate = getValues("endDate");
        if (value > endDate) {
          return "開始日は終了日以前の日付を指定してください。";
        }
      },
    },
    endDate: {
      required: "プロジェクト終了日を入力してください",
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!dayjs(formatDate).isValid()) {
          return "日付形式が間違っています";
        }
      },
    },
    projectPlace: {
      required: "実施場所を入力してください",
    },
    planDocuments: {
      required: "申請書類をアップロードしてください",
      validate: {
        isPdf: (value) => value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
    additionalDocuments: {
      validate: {
        isPdf: (value) =>
          !value || value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => !value || value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
  };

  const onSubmit = async (data) => {
    if (window.confirm("申請します。よろしいですか？")) {
      try {
        const planDocs = data.planDocuments;
        const additionalDocs = data.additionalDocuments;

        const uploadTasks = [uploadImg(planDocs, ["planDocuments"])];
        if (additionalDocs) uploadTasks.push(uploadImg(additionalDocs, ["additionalDocuments"]));
        const [planUrl, additionalUrl] = await Promise.all(uploadTasks);

        const timeline = [new Date(data.startDate), new Date(data.endDate)];
        const program = await programApi.getProgram(data.programId);

        const reqData = {
          additionalDocuments: additionalDocs ? additionalUrl : "",
          applyUserId: storedUserInfo.value._id,
          companyId: storedUserInfo.value.companyId,
          planDocuments: planUrl,
          programId: data.programId,
          projectTimeLine: timeline,
          place: data.projectPlace,
          program: {
            id: data.programId,
            historyNo: program.historyNo,
            suitabilityReason: data.suitabilityReason,
          },
          //上は前までのやつ
          projectName: data.projectName,
          projectStatus: "10",
          branchId: data.branchId,
          projectDesc: data.projectDesc,
          projectCategory: projectCategory,
          audit: getAuditHistoryRows(),
          projectDesign: projectDesignCategory,
          actExplain: data.actExplain,
          projectPlace: data.projectPlace,
          proposer: {
            company: company?.groupName,
            staff: storedUserInfo?.value.username,
            role: storedUserInfo?.value.role,
            address: company?.address,
            email: storedUserInfo?.value.email,
            tel: data.proposerTel,
          },
          refOtherCompany: data.refOtherCompany,
          projectOwnership: data.projectOwnership,
          projectEligibility: data.projectEligibility,
          beforeStartingProject: data.beforeStartingProject,
          legalCompliance: data.legalCompliance,
          noDuplicatePublish: {
            check: duplicatePublishCheck,
            evidence: data.noDuplicatePublish,
          },
          noDuplicateClaim: {
            check: duplicateClaimCheck,
            evidence: data.noDuplicateClaim,
          },
          stakeholderRegulations: data.stakeholderRegulations,
          envRisk: data.envRisk,
          fairness: data.fairness,
          soundnessA: {
            soundnessACheck: soundnessACheck,
            soundnessA1: data.soundnessA1,
            soundnessA2: data.soundnessA2,
          },
          soundnessB: {
            soundnessBCheck: soundnessBCheck,
            soundnessB1: data.soundnessB1,
            soundnessB2: data.soundnessB2,
          },
          soundnessC: {
            soundnessCCheck: soundnessCCheck,
            soundnessC1: data.soundnessC1,
            soundnessC2: data.soundnessC2,
          },
          soundnessD: {
            soundnessDCheck: soundnessDCheck,
            soundnessD1: data.soundnessD1,
            soundnessD2: data.soundnessD2,
          },
          sdContribution: data.sdContribution,
          projectAddInfo: data.projectAddInfo,
          projectStartDate: projectStartDate,
          startDateRightfulness: data.startDateRightfulness,
          projectCreditTerm: {
            creditTerm: data.creditTerm,
            creditStartDate: creditStartDate,
            creditEndDate: creditEndDate,
          },
          estimatedValue: getEstimatedValue1Rows(),
          estimatedERR: data.estimatedERR,
          totalYears: data.totalYears,
          aveYearlyERR: data.aveYearlyERR,
          programs: getMethodologyRows(),
          programApplicability: data.programApplicability,
          projectBoundary: getProjectBoundaryRows(),
          // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
          projectBoundaryFile: "",
          baselineScenario: data.baselineScenario,
          additionality: data.additionality,
          deviateProgram: data.deviateProgram,
          baselineEmissions: data.baselineEmissions,
          projectEmissions: data.projectEmissions,
          leakageEmissions: data.leakageEmissions,
          nonPermanentRiskEvaluation: data.nonPermanentRiskEvaluation,
          nonPermanentRiskReportCheck: nonPermanentRiskReportCheck,
          projectLTA: data.projectLTA,
          updateLta: {
            check: updateLtaCheck,
            NonUpdateLtaReason: data.NonUpdateLtaReason,
          },
          expectGhgEmissions: data.expectGhgEmissions,
          belowLta: {
            check: ghgCreditCheck,
            NotBelowLtaReason: data.NotBelowLtaReason,
          },
          emissions: {
            detail: getEmissionsDetailRows(),
            total: {
              estimatedBaselineEmissionsTotal: estimatedBaselineEmissionsTotal,
              estimatedProjectEmissionsTotal: estimatedProjectEmissionsTotal,
              estimatedLeakageEmissionsTotal: estimatedLeakageEmissionsTotal,
              estimatedReductionCreditTotal: estimatedReductionCreditTotal,
              estimatedRemoveCreditTotal: estimatedRemoveCreditTotal,
              estimatedTotalCreditTotal: estimatedTotalCreditTotal,
            },
          },
          availableData: getAvailableDataRows(),
          monitoringData: getMonitoringDataRows(),
          monitoringPlan: data.monitoringPlan,
          references: data.references,
          secret: getSecretRows(),
          otherInfo: data.otherInfo,
        };

        let projectNo = {};
        let seqNumber = 0;
        projectNo = await counterApi.upsert({ key: "projectNo" });
        seqNumber = projectNo.seq;
        reqData.projectNo = seqNumber;
        // if(!task?.seqNo) {
        //   projectNo = await counterApi.upsert({ key: "projectNo" });
        //   seqNumber = projectNo.seq;
        //   reqData.seqNo = seqNumber;
        // } else {
        //   seqNumber = task.seqNo
        // }

        projectApi
          .registerTest(reqData)
          .then(async (res) => {
            const projectId = res.project._id;
            // 一旦メールはコメントアウト
            // return sendApplyMail(
            //   data,
            //   reqData.planDocuments,
            //   reqData.additionalDocuments,
            //   projectId
            // );
          })
          .then(() => {
            alert("申請しました");
            navigate("/member/projects");
          })
          .catch((e) => {
            alert("申請に失敗しました。運営にお問合せください。");
            console.error(e);
          });
      } catch (error) {
        alert("申請に失敗しました。運営にお問合せください。");
        console.error(error);
      }
    }
  };

  ///////////////////////
  // file type指定テスト //
  ///////////////////////

  // const ref = useRef(null);
  // const handleChange = (e) => {
  //   if (!e.target.files) return;
  //   const files = Array.from(e.target.files);
  //   setFile(files);
  // };
  // const getFileNames = () =>
  //   file?.reduce(
  //     (fileNames, file) =>
  //       `${fileNames} ${fileNames !== "" ? "," : ""} ${file.name}`,
  //     ""
  //   ) || "";

  ///////////////////////

  // 1 基本情報に関する変数等
  const [projectList, setProjectList] = useState([]);

  // 2.2 セクターの範囲とプロジェクトの種類に関する変数等
  const [projectCategory, setProjectCategory] = useState("");
  const handleCategoryChange = (event) => {
    setProjectCategory(event.target.value);
    // dispatch(task_setCategory(event.target.value));
  };

  // 2.3 監査履歴に関する変数等
  const [auditHistoryRows, setAuditHistoryRows] = React.useState([]);
  const apiRefForAuditHistory = useGridApiRef();
  const auditHistoryColumns = [
    { field: "auditType", headerName: "監査タイプ" }, //バリデーション or 検証
    { field: "auditHistoryTerm", headerName: "期間" },
    { field: "auditHistoryProgram", headerName: "プログラム" },
    { field: "auditHistoryName", headerName: "バリデーション名／検証機関名" },
    { field: "auditHistoryYears", headerName: "年数" },
  ];
  const addAuditHistoryRows = () => {
    const id =
      auditHistoryRows.length === 0
        ? 1
        : Math.max(...auditHistoryRows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const auditTypeValue = getValues("auditType");
    const auditHistoryTermValue = getValues("auditHistoryTerm");
    const auditHistoryProgramValue = getValues("auditHistoryProgram");
    const auditHistoryNameValue = getValues("auditHistoryName");
    const auditHistoryYearsValue = getValues("auditHistoryYears");
    const newValue = {
      id: id,
      auditType: auditTypeValue,
      auditHistoryTerm: auditHistoryTermValue,
      auditHistoryProgram: auditHistoryProgramValue,
      auditHistoryName: auditHistoryNameValue,
      auditHistoryYears: auditHistoryYearsValue,
    };
    setAuditHistoryRows([...auditHistoryRows, newValue]);
    reset({
      auditType: "",
      auditHistoryTerm: "",
      auditHistoryProgram: "",
      auditHistoryName: "",
      auditHistoryYears: "",
    });
  };

  const delAuditHistoryRows = () => {
    const delAuditHistoryRow = apiRefForAuditHistory.current.getSelectedRows();
    if (auditHistoryRows.length === 0 || delAuditHistoryRow.size === 0) return;
    delAuditHistoryRow.forEach((v) => {
      apiRefForAuditHistory.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newAuditHistoryRows = apiRefForAuditHistory.current.getSortedRows();
    if (newAuditHistoryRows !== null) {
      setAuditHistoryRows(
        Object.keys(newAuditHistoryRows).length === 0 ? [] : [newAuditHistoryRows]
      );
    }
  };

  const getAuditHistoryRows = () => {
    if (!apiRefForAuditHistory.current || Object.keys(apiRefForAuditHistory.current).length === 0)
      return null;
    const allAuditHistoryRows = apiRefForAuditHistory.current?.getSortedRows();
    return allAuditHistoryRows;
  };

  // 3.1 プロジェクト・デザインに関する変数等
  const [projectDesignCategory, setProjectDesignCategory] = useState("");
  const handleProjectDesignCategoryChange = (event) => {
    setProjectDesignCategory(event.target.value);
  };

  // 4.4 二重請求と他のGHGプログラムへの参加に関する変数等
  const [duplicatePublishCheck, setDuplicatePublishCheck] = useState(false);
  const handleChangeDuplicatePublishCheck = (event) => {
    if (event.target.value === "1") {
      setDuplicatePublishCheck(true);
    } else {
      setDuplicatePublishCheck(false);
    }
  };

  // 4.5 二重請求、他の形態のクレジット、スコープ3排出量に関する変数等
  const [duplicateClaimCheck, setDuplicateClaimCheck] = useState(false);
  const handleChangeDuplicateClaimCheck = (event) => {
    if (event.target.value === "1") {
      setDuplicateClaimCheck(true);
    } else {
      setDuplicateClaimCheck(false);
    }
  };

  // 5.4 生態系の健全性に関する変数等
  const [soundnessACheck, setSoundnessACheck] = useState(false);
  const [soundnessBCheck, setSoundnessBCheck] = useState(false);
  const [soundnessCCheck, setSoundnessCCheck] = useState(false);
  const [soundnessDCheck, setSoundnessDCheck] = useState(false);
  const handleChangeSoundnessACheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessACheck(true);
    } else {
      setSoundnessACheck(false);
    }
  };
  const handleChangeSoundnessBCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessBCheck(true);
    } else {
      setSoundnessBCheck(false);
    }
  };
  const handleChangeSoundnessCCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessCCheck(true);
    } else {
      setSoundnessCCheck(false);
    }
  };
  const handleChangeSoundnessDCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessDCheck(true);
    } else {
      setSoundnessDCheck(false);
    }
  };

  // 6.1 プロジェクト開始日に関する変数等
  const [projectStartDate, setProjectStartDate] = useState();
  const handleChangeProjectStartDate = (newValue) => {
    setProjectStartDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };

  // 6.2 プロジェクトのクレジット期間に関する変数等
  const [creditStartDate, setCreditStartDate] = useState();
  const [creditEndDate, setCreditEndDate] = useState();
  const handleChangeCreditStartDate = (newValue) => {
    setCreditStartDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeCreditEndDate = (newValue) => {
    setCreditEndDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };

  // 6.3 プロジェクト規模とGHG排出削減量または除去量の推定値に関する変数等
  const [estimatedValue1Rows, setEstimatedValue1Rows] = React.useState([]);
  const [creditTimeStart, setCreditTimeStart] = useState();
  const [creditTimeEnd, setCreditTimeEnd] = useState();
  const handleChangeCreditTimeStart = (newValue) => {
    setCreditTimeStart(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeCreditTimeEnd = (newValue) => {
    setCreditTimeEnd(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const apiRefForEstimatedValue1 = useGridApiRef();
  const estimatedValue1Columns = [
    { field: "creditTime", headerName: "プロジェクト期間の暦年", width: "50rem" },
    {
      field: "estimatedValue1",
      headerName: "GHG排出削減量または除去量の推定値（tCO2e）",
      width: "50rem",
    },
  ];
  const addEstimatedValue1Rows = () => {
    const id =
      estimatedValue1Rows.length === 0
        ? 1
        : Math.max(...estimatedValue1Rows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const estimatedValue1 = getValues("estimatedValue1");
    const creditTime = `${dayjs(creditTimeStart).format("YYYY/MM/DD")}〜${dayjs(
      creditTimeEnd
    ).format("YYYY/MM/DD")}`;

    const newValue = {
      id: id,
      creditTime: creditTime,
      estimatedValue1: estimatedValue1,
    };
    setEstimatedValue1Rows([...estimatedValue1Rows, newValue]);
    reset({
      estimatedValue1: "",
    });
    setCreditTimeStart(null);
    setCreditTimeEnd(null);
  };

  const delEstimatedValue1Rows = () => {
    const delEstimatedValue1Row = apiRefForEstimatedValue1.current.getSelectedRows();
    if (estimatedValue1Rows.length === 0 || delEstimatedValue1Row.size === 0) return;
    delEstimatedValue1Row.forEach((v) => {
      apiRefForEstimatedValue1.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newEstimatedValue1Rows = apiRefForEstimatedValue1.current.getSortedRows();
    if (newEstimatedValue1Rows !== null) {
      setEstimatedValue1Rows(
        Object.keys(newEstimatedValue1Rows).length === 0 ? [] : [newEstimatedValue1Rows]
      );
    }
  };

  const getEstimatedValue1Rows = () => {
    if (
      !apiRefForEstimatedValue1.current ||
      Object.keys(apiRefForEstimatedValue1.current).length === 0
    )
      return null;
    const allEstimatedValue1Rows = apiRefForEstimatedValue1.current?.getSortedRows();
    if (!allEstimatedValue1Rows) return null;
    let estimatedValues = [];
    allEstimatedValue1Rows.forEach((row) => {
      let value = {
        creditTimeStart: "",
        creditTimeEnd: "",
        estimatedValue1: "",
      };
      value.creditTimeStart = row.creditTime.split("〜")[0];
      value.creditTimeEnd = row.creditTime.split("〜")[1];
      value.estimatedValue1 = row.estimatedValue1;
      estimatedValues.push(value);
    });
    return estimatedValues;
  };

  // 7.1 方法論のタイトルと参考文献に関する変数等
  const [methodologyCheck, setMethodologyCheck] = useState(false);
  const [methodologyRows, setMethodologyRows] = React.useState([]);
  const [methodologyTypeCategory, setMethodologyTypeCategory] = useState("");
  const apiRefForMethodology = useGridApiRef();
  const handleChangeMethodologyCheck = () => {
    setMethodologyCheck(!methodologyCheck);
  };

  const handleMethodologyTypeChange = (event) => {
    setMethodologyTypeCategory(event.target.value);
  };

  const methodologyColumns = [
    { field: "methodologyTypeText", headerName: "タイプ" },
    { field: "methodologyRefId", headerName: "参照ID" },
    { field: "methodologyTitle", headerName: "タイトル" },
    { field: "methodologyVersion", headerName: "Version" },
  ];
  const addMethodologyRows = async () => {
    const methodologyTypeCategoryList = await categoryApi.get({
      params: {
        name: "methodologyType",
      },
    });
    const id =
      methodologyRows.length === 0
        ? 1
        : Math.max(...methodologyRows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const methodologyTypeValue =
      methodologyTypeCategoryList?.list[Number(methodologyTypeCategory) - 1]?.text;
    const methodologyRefIdValue = getValues("methodologyRefId");
    const methodologyTitleValue = getValues("methodologyTitle");
    const methodologyVersionValue = getValues("methodologyVersion");

    const newValue = {
      id: id,
      ncccProgramId: null,
      methodologyTypeId: methodologyTypeCategory,
      methodologyTypeText: methodologyTypeValue,
      methodologyRefId: methodologyRefIdValue,
      methodologyTitle: methodologyTitleValue,
      methodologyVersion: methodologyVersionValue,
    };
    setMethodologyRows([...methodologyRows, newValue]);
    reset({
      // methodologyType: "",
      methodologyRefId: "",
      methodologyTitle: "",
      methodologyVersion: "",
    });
    setMethodologyTypeCategory("");
  };

  const addNcccMethodologyRows = async () => {
    const programValue = getValues("program");
    if (!programValue) return;
    const methodologyProgram = await programApi.getProgram(programValue);
    const id =
      methodologyRows.length === 0
        ? 1
        : Math.max(...methodologyRows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const methodologyRefIdValue = methodologyProgram.programNo;
    const methodologyTitleValue = methodologyProgram.programName;
    const methodologyVersionValue = methodologyProgram.version;
    const newValue = {
      id: id,
      ncccProgramId: methodologyProgram._id,
      methodologyTypeId: "0",
      methodologyTypeText: "NCCC方法論",
      methodologyRefId: methodologyRefIdValue,
      methodologyTitle: methodologyTitleValue,
      methodologyVersion: methodologyVersionValue,
    };
    setMethodologyRows([...methodologyRows, newValue]);
  };

  const delMethodologyRows = () => {
    const delMethodologyRow = apiRefForMethodology.current.getSelectedRows();
    if (methodologyRows.length === 0 || delMethodologyRow.size === 0) return;
    delMethodologyRow.forEach((v) => {
      apiRefForMethodology.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMethodologyRows = apiRefForMethodology.current.getSortedRows();
    if (newMethodologyRows !== null) {
      setMethodologyRows(Object.keys(newMethodologyRows).length === 0 ? [] : [newMethodologyRows]);
    }
  };

  const getMethodologyRows = () => {
    if (!apiRefForMethodology.current || Object.keys(apiRefForMethodology.current).length === 0)
      return null;
    const allMethodologyRows = apiRefForMethodology.current?.getSortedRows();
    return allMethodologyRows;
  };

  // 7.3 プロジェクト境界に関する変数等
  // const [gasIncludeCheck, setGasIncludeCheck] = useState(false);
  const [projectBoundaryRows, setProjectBoundaryRows] = React.useState([]);
  const [gasCategory, setGasCategory] = useState("");
  const apiRefForProjectBoundary = useGridApiRef();
  // const handleChangeGasIncludeCheck = () => {
  //   setGasIncludeCheck(!gasIncludeCheck)
  // }

  const handleGasCategoryChange = (event) => {
    setGasCategory(event.target.value);
  };

  const projectBoundaryColumns = [
    { field: "baselineOrSource", headerName: "ベースライン／ソース" },
    { field: "gasType", headerName: "Gas" },
    { field: "gasInclude", headerName: "含まれる？" },
    { field: "boundaryExplain", headerName: "正当性／説明" },
  ];
  const addProjectBoundaryRows = async () => {
    const gasCategoryList = await categoryApi.get({
      params: {
        name: "gas",
      },
    });
    const id =
      projectBoundaryRows.length === 0
        ? 1
        : Math.max(...projectBoundaryRows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const gasTypeValue = gasCategoryList?.list[Number(gasCategory) - 1]?.text;
    const baselineOrSourceValue = getValues("baselineOrSource");
    const gasIncludeValue = getValues("gasInclude");
    const boundaryExplainValue = getValues("boundaryExplain");

    const newValue = {
      id: id,
      gasType: gasTypeValue,
      baselineOrSource: baselineOrSourceValue,
      gasInclude: gasIncludeValue,
      boundaryExplain: boundaryExplainValue,
    };
    setProjectBoundaryRows([...projectBoundaryRows, newValue]);
    reset({
      // methodologyType: "",
      baselineOrSource: "",
      gasInclude: "",
      boundaryExplain: "",
    });
    // setGasCategory(null)
  };

  const delProjectBoundaryRows = () => {
    const delProjectBoundaryRow = apiRefForProjectBoundary.current.getSelectedRows();
    if (projectBoundaryRows.length === 0 || delProjectBoundaryRow.size === 0) return;
    delProjectBoundaryRow.forEach((v) => {
      apiRefForProjectBoundary.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectBoundaryRows = apiRefForProjectBoundary.current.getSortedRows();
    if (newProjectBoundaryRows !== null) {
      setProjectBoundaryRows(
        Object.keys(newProjectBoundaryRows).length === 0 ? [] : [newProjectBoundaryRows]
      );
    }
  };

  const getProjectBoundaryRows = () => {
    if (
      !apiRefForProjectBoundary.current ||
      Object.keys(apiRefForProjectBoundary.current).length === 0
    )
      return null;
    const allProjectBoundaryRows = apiRefForProjectBoundary.current?.getSortedRows();
    return allProjectBoundaryRows;
  };

  // 8.4 GHG排出削減量と二酸化炭素除去量の推定値に関する変数等
  const [nonPermanentRiskReportCheck, setNonPermanentRiskReportCheck] = useState(true);
  const [updateLtaCheck, setUpdateLtaCheck] = useState(true);
  const [ghgCreditCheck, setGhgCreditCheck] = useState(true);
  const [projectCreditTermRows, setProjectCreditTermRows] = React.useState([]);
  const [projectCreditTermStart, setProjectCreditTermStart] = useState();
  const [projectCreditTermEnd, setProjectCreditTermEnd] = useState();
  const [estimatedBaselineEmissionsTotal, setEstimatedBaselineEmissionsTotal] = useState(0);
  const [estimatedProjectEmissionsTotal, setEstimatedProjectEmissionsTotal] = useState(0);
  const [estimatedLeakageEmissionsTotal, setEstimatedLeakageEmissionsTotal] = useState(0);
  const [estimatedReductionCreditTotal, setEstimatedReductionCreditTotal] = useState(0);
  const [estimatedRemoveCreditTotal, setEstimatedRemoveCreditTotal] = useState(0);
  const [estimatedTotalCreditTotal, setEstimatedTotalCreditTotal] = useState(0);
  const handleChangeNonPermanentRiskReportCheck = (event) => {
    if (event.target.value === "1") {
      setNonPermanentRiskReportCheck(true);
    } else {
      setNonPermanentRiskReportCheck(false);
    }
  };
  const handleChangeUpdateLtaCheck = (event) => {
    if (event.target.value === "1") {
      setUpdateLtaCheck(true);
    } else {
      setUpdateLtaCheck(false);
    }
  };
  const handleChangeGhgCreditCheck = (event) => {
    if (event.target.value === "1") {
      setGhgCreditCheck(true);
    } else {
      setGhgCreditCheck(false);
    }
  };
  const handleChangeProjectCreditTermStart = (newValue) => {
    setProjectCreditTermStart(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeProjectCreditTermEnd = (newValue) => {
    setProjectCreditTermEnd(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const apiRefForProjectCreditTerm = useGridApiRef();
  const projectCreditTermColumns = [
    { field: "projectTerm", headerName: "検証期間" },
    { field: "estimatedBaselineEmissions", headerName: "ベースライン排出量" },
    { field: "estimatedProjectEmissions", headerName: "プロジェクト排出量" },
    { field: "estimatedLeakageEmissions", headerName: "リーケージ排出量" },
    { field: "estimatedLeakageEmissions", headerName: "バッファープール割当" },
    { field: "estimatedReductionCredit", headerName: "削減クレジット" },
    { field: "estimatedRemoveCredit", headerName: "除去クレジット" },
    { field: "estimatedTotalCredit", headerName: "合計クレジット" },
  ];
  const addProjectCreditTermRows = () => {
    const id =
      projectCreditTermRows.length === 0
        ? 1
        : Math.max(...projectCreditTermRows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const estimatedBaselineEmissions = getValues("estimatedBaselineEmissions");
    const estimatedProjectEmissions = getValues("estimatedProjectEmissions");
    const estimatedLeakageEmissions = getValues("estimatedLeakageEmissions");
    const estimatedReductionCredit = getValues("estimatedReductionCredit");
    const estimatedRemoveCredit = getValues("estimatedRemoveCredit");
    const estimatedTotalCredit = getValues("estimatedTotalCredit");
    const projectTerm = `${dayjs(projectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(
      projectCreditTermEnd
    ).format("YYYY/MM/DD")}`;

    const newValue = {
      id: id,
      projectTerm: projectTerm,
      estimatedBaselineEmissions:
        estimatedBaselineEmissions !== "" ? estimatedBaselineEmissions : "0",
      estimatedProjectEmissions: estimatedProjectEmissions !== "" ? estimatedProjectEmissions : "0",
      estimatedLeakageEmissions: estimatedLeakageEmissions !== "" ? estimatedLeakageEmissions : "0",
      estimatedReductionCredit: estimatedReductionCredit !== "" ? estimatedReductionCredit : "0",
      estimatedRemoveCredit: estimatedRemoveCredit !== "" ? estimatedRemoveCredit : "0",
      estimatedTotalCredit: estimatedTotalCredit !== "" ? estimatedTotalCredit : "0",
    };
    setProjectCreditTermRows([...projectCreditTermRows, newValue]);
    reset({
      estimatedBaselineEmissions: "",
      estimatedProjectEmissions: "",
      estimatedLeakageEmissions: "",
      estimatedReductionCredit: "",
      estimatedRemoveCredit: "",
      estimatedTotalCredit: "",
    });
    setProjectCreditTermStart(null);
    setProjectCreditTermEnd(null);
  };

  const delProjectCreditTermRows = () => {
    const delProjectCreditTermRow = apiRefForProjectCreditTerm.current.getSelectedRows();
    if (projectCreditTermRows.length === 0 || delProjectCreditTermRow.size === 0) return;
    delProjectCreditTermRow.forEach((v) => {
      apiRefForProjectCreditTerm.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectCreditTermRows = apiRefForProjectCreditTerm.current.getSortedRows();
    if (newProjectCreditTermRows !== null) {
      setProjectCreditTermRows(
        Object.keys(newProjectCreditTermRows).length === 0 ? [] : [newProjectCreditTermRows]
      );
    }
  };

  const totalProjectCreditTermRows = () => {
    const allRows = apiRefForProjectCreditTerm.current.getSortedRows();
    allRows.forEach((row) => {
      setEstimatedBaselineEmissionsTotal(
        parseFloat(estimatedBaselineEmissionsTotal) + parseFloat(row.estimatedBaselineEmissions)
      );
      setEstimatedProjectEmissionsTotal(
        parseFloat(estimatedProjectEmissionsTotal) + parseFloat(row.estimatedProjectEmissions)
      );
      setEstimatedLeakageEmissionsTotal(
        parseFloat(estimatedLeakageEmissionsTotal) + parseFloat(row.estimatedLeakageEmissions)
      );
      setEstimatedReductionCreditTotal(
        parseFloat(estimatedReductionCreditTotal) + parseFloat(row.estimatedReductionCredit)
      );
      setEstimatedRemoveCreditTotal(
        parseFloat(estimatedRemoveCreditTotal) + parseFloat(row.estimatedRemoveCredit)
      );
      setEstimatedTotalCreditTotal(
        parseFloat(estimatedTotalCreditTotal) + parseFloat(row.estimatedTotalCredit)
      );
    });
  };

  useEffect(() => {
    if (projectCreditTermRows.length !== 0) {
      totalProjectCreditTermRows();
    }
  }, [projectCreditTermRows]);

  const getEmissionsDetailRows = () => {
    if (
      !apiRefForProjectCreditTerm.current ||
      Object.keys(apiRefForProjectCreditTerm.current).length === 0
    )
      return null;
    const allProjectCreditTermRows = apiRefForProjectCreditTerm.current?.getSortedRows();
    if (!allProjectCreditTermRows) return null;
    let estimatedDetailValues = [];
    allProjectCreditTermRows.forEach((row) => {
      let value = {
        projectCreditTermStart: "",
        projectCreditTermEnd: "",
        estimatedBaselineEmissions: "",
        estimatedProjectEmissions: "",
        estimatedLeakageEmissions: "",
        estimatedReductionCredit: "",
        estimatedRemoveCredit: "",
        estimatedTotalCredit: "",
      };
      value.projectCreditTermStart = row.projectTerm.split("〜")[0];
      value.projectCreditTermEnd = row.projectTerm.split("〜")[1];
      value.estimatedBaselineEmissions = row.estimatedBaselineEmissions;
      value.estimatedProjectEmissions = row.estimatedProjectEmissions;
      value.estimatedLeakageEmissions = row.estimatedLeakageEmissions;
      value.estimatedReductionCredit = row.estimatedReductionCredit;
      value.estimatedRemoveCredit = row.estimatedRemoveCredit;
      value.estimatedTotalCredit = row.estimatedTotalCredit;
      estimatedDetailValues.push(value);
    });
    return estimatedDetailValues;
  };

  // 9.1 検証時に利用可能なデータとパラメータ
  const [isOpenMonitoringDialog1, setIsOpenMonitoringDialog1] = React.useState(false);
  const [monitoring1Rows, setMonitoring1Rows] = React.useState([]);
  const handleOpenMonitoringDialog1 = () => {
    setIsOpenMonitoringDialog1(true);
  };
  const handleCloseMonitoringDialog1 = () => {
    setIsOpenMonitoringDialog1(false);
  };

  const apiRefForMonitoring1 = useGridApiRef();

  const monitoring1Columns = [
    { field: "monitoringDataOrParam1", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit1", headerName: "データ単位" },
    { field: "monitoringDataContents1", headerName: "データ内容" },
    { field: "monitoringDataSource1", headerName: "データソース" },
    { field: "monitoringApplicableValue1", headerName: "適用値" },
    {
      field: "monitoringExplain1",
      headerName: "データ選択の正当性、または適用した測定方法と手順の説明",
    },
    { field: "monitoringPurpose1", headerName: "データの目的" },
    { field: "monitoringComment1", headerName: "コメント" },
    // TODO: 詳細や編集ボタンをつける
  ];
  const addMonitoring1Rows = () => {
    const id =
      monitoring1Rows.length === 0
        ? 1
        : Math.max(...monitoring1Rows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const monitoringDataOrParam1Value = getValues("monitoringDataOrParam1");
    const monitoringDataUnit1Value = getValues("monitoringDataUnit1");
    const monitoringDataContents1Value = getValues("monitoringDataContents1");
    const monitoringDataSource1Value = getValues("monitoringDataSource1");
    const monitoringApplicableValue1Value = getValues("monitoringApplicableValue1");
    const monitoringExplain1Value = getValues("monitoringExplain1");
    const monitoringPurpose1Value = getValues("monitoringPurpose1");
    const monitoringComment1Value = getValues("monitoringComment1");

    const newValue = {
      id: id,
      monitoringDataOrParam1: monitoringDataOrParam1Value,
      monitoringDataUnit1: monitoringDataUnit1Value,
      monitoringDataContents1: monitoringDataContents1Value,
      monitoringDataSource1: monitoringDataSource1Value,
      monitoringApplicableValue1: monitoringApplicableValue1Value,
      monitoringExplain1: monitoringExplain1Value,
      monitoringPurpose1: monitoringPurpose1Value,
      monitoringComment1: monitoringComment1Value,
    };
    setMonitoring1Rows([...monitoring1Rows, newValue]);
    reset({
      monitoringDataOrParam1: "",
      monitoringDataUnit1: "",
      monitoringDataContents1: "",
      monitoringDataSource1: "",
      monitoringApplicableValue1: "",
      monitoringExplain1: "",
      monitoringPurpose1: "",
      monitoringComment1: "",
    });
    handleCloseMonitoringDialog1();
  };

  const delMonitoring1Rows = () => {
    const delMonitoringRow = apiRefForMonitoring1.current.getSelectedRows();
    if (monitoring1Rows.length === 0 || delMonitoringRow.size === 0) return;
    delMonitoringRow.forEach((v) => {
      apiRefForMonitoring1.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMonitoring1Rows = apiRefForMonitoring1.current.getSortedRows();
    if (newMonitoring1Rows !== null) {
      setMonitoring1Rows(Object.keys(newMonitoring1Rows).length === 0 ? [] : [newMonitoring1Rows]);
    }
  };

  const getAvailableDataRows = () => {
    if (!apiRefForMonitoring1.current || Object.keys(apiRefForMonitoring1.current).length === 0)
      return null;
    const allMonitoring1Rows = apiRefForMonitoring1.current?.getSortedRows();
    return allMonitoring1Rows;
  };

  // 9.2 モニタリングされるデータとパラメータ
  const [isOpenMonitoringDialog2, setIsOpenMonitoringDialog2] = React.useState(false);
  const [monitoring2Rows, setMonitoring2Rows] = React.useState([]);
  const handleOpenMonitoringDialog2 = () => {
    setIsOpenMonitoringDialog2(true);
  };
  const handleCloseMonitoringDialog2 = () => {
    setIsOpenMonitoringDialog2(false);
  };

  const apiRefForMonitoring2 = useGridApiRef();

  const monitoring2Columns = [
    { field: "monitoringDataOrParam2", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit2", headerName: "データ単位" },
    { field: "monitoringDataContents2", headerName: "データ内容" },
    { field: "monitoringDataSource2", headerName: "データソース" },
    { field: "monitoringExplain2", headerName: "適用される測定方法と手順の説明" },
    { field: "monitoringFrequency", headerName: "モニタリング／記録の頻度" },
    { field: "monitoringApplicableValue2", headerName: "適用値" },
    { field: "monitoringDevice", headerName: "モニタリング機器" },
    { field: "monitoringQaQcProcedure", headerName: "適用されるQA/QC手順" },
    { field: "monitoringPurpose2", headerName: "データの目的" },
    { field: "howToCalc", headerName: "計算方法" },
    { field: "monitoringComment2", headerName: "コメント" },
    // TODO: 詳細や編集ボタンをつける
  ];
  const addMonitoring2Rows = () => {
    const id =
      monitoring2Rows.length === 0
        ? 1
        : Math.max(...monitoring2Rows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const monitoringDataOrParam2Value = getValues("monitoringDataOrParam2");
    const monitoringDataUnit2Value = getValues("monitoringDataUnit2");
    const monitoringDataContents2Value = getValues("monitoringDataContents2");
    const monitoringDataSource2Value = getValues("monitoringDataSource2");
    const monitoringExplain2Value = getValues("monitoringExplain2");
    const monitoringFrequencyValue = getValues("monitoringFrequency");
    const monitoringApplicableValue2Value = getValues("monitoringApplicableValue2");
    const monitoringDeviceValue = getValues("monitoringDevice");
    const monitoringQaQcProcedureValue = getValues("monitoringQaQcProcedure");
    const monitoringPurpose2Value = getValues("monitoringPurpose2");
    const howToCalcValue = getValues("howToCalc");
    const monitoringComment2Value = getValues("monitoringComment2");

    const newValue = {
      id: id,
      monitoringDataOrParam2: monitoringDataOrParam2Value,
      monitoringDataUnit2: monitoringDataUnit2Value,
      monitoringDataContents2: monitoringDataContents2Value,
      monitoringDataSource2: monitoringDataSource2Value,
      monitoringExplain2: monitoringExplain2Value,
      monitoringFrequency: monitoringFrequencyValue,
      monitoringApplicableValue2: monitoringApplicableValue2Value,
      monitoringDevice: monitoringDeviceValue,
      monitoringQaQcProcedure: monitoringQaQcProcedureValue,
      monitoringPurpose2: monitoringPurpose2Value,
      howToCalc: howToCalcValue,
      monitoringComment2: monitoringComment2Value,
    };
    setMonitoring2Rows([...monitoring2Rows, newValue]);
    reset({
      monitoringDataOrParam2: "",
      monitoringDataUnit2: "",
      monitoringDataContents2: "",
      monitoringDataSource2: "",
      monitoringExplain2: "",
      monitoringFrequency: "",
      monitoringApplicableValue2: "",
      monitoringDevice: "",
      monitoringQaQcProcedure: "",
      monitoringPurpose2: "",
      howToCalc: "",
      monitoringComment2: "",
    });
    handleCloseMonitoringDialog2();
  };

  const delMonitoring2Rows = () => {
    const delMonitoring2Row = apiRefForMonitoring2.current.getSelectedRows();
    if (monitoring2Rows.length === 0 || delMonitoring2Row.size === 0) return;
    delMonitoring2Row.forEach((v) => {
      apiRefForMonitoring2.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMonitoring2Rows = apiRefForMonitoring2.current.getSortedRows();
    if (newMonitoring2Rows !== null) {
      setMonitoring2Rows(Object.keys(newMonitoring2Rows).length === 0 ? [] : [newMonitoring2Rows]);
    }
  };

  const getMonitoringDataRows = () => {
    if (!apiRefForMonitoring2.current || Object.keys(apiRefForMonitoring2.current).length === 0)
      return null;
    const allMonitoring2Rows = apiRefForMonitoring2.current?.getSortedRows();
    return allMonitoring2Rows;
  };

  // 8.1 検証機関の委員リストに関する変数等
  const [committeeListRows, setCommitteeListRows] = React.useState([]);
  const apiRefForCommitteeList = useGridApiRef();
  const committeeListColumns = [
    { field: "committeeName", headerName: "名前" },
    { field: "committeeBelong", headerName: "所属" },
    { field: "committeeApproval", headerName: "検証への同意（する／しない／棄権）" },
  ];
  const addCommitteeListRows = () => {
    const id =
      committeeListRows.length === 0
        ? 1
        : Math.max(...committeeListRows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const committeeNameValue = getValues("committeeName");
    const committeeBelongValue = getValues("committeeBelong");
    const committeeApprovalValue = getValues("committeeApproval");
    const newValue = {
      id: id,
      committeeName: committeeNameValue,
      committeeBelong: committeeBelongValue,
      committeeApproval: committeeApprovalValue,
    };
    setCommitteeListRows([...committeeListRows, newValue]);
    reset({
      committeeName: "",
      committeeBelong: "",
      committeeApproval: "",
    });
  };

  const delCommitteeListRows = () => {
    const delCommitteeListRow = apiRefForCommitteeList.current.getSelectedRows();
    if (committeeListRows.length === 0 || delCommitteeListRow.size === 0) return;
    delCommitteeListRow.forEach((v) => {
      apiRefForCommitteeList.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newCommitteeListRows = apiRefForCommitteeList.current.getSortedRows();
    if (newCommitteeListRows !== null) {
      setCommitteeListRows(
        Object.keys(newCommitteeListRows).length === 0 ? [] : [newCommitteeListRows]
      );
    }
  };

  const getCommitteeListRows = () => {
    if (!apiRefForCommitteeList.current || Object.keys(apiRefForCommitteeList.current).length === 0)
      return null;
    const allCommitteeListRows = apiRefForCommitteeList.current?.getSortedRows();
    return allCommitteeListRows;
  };

  // 9.1 商業上の機密情報を表示するテーブルに関する変数等
  const [secretInfoRows, setSecretInfoRows] = React.useState([]);
  const apiRefForSecretInfo = useGridApiRef();
  const secretSectionValue = getValues("secretSection");
  const secretInfoValue = getValues("secretInfo");
  const secretJustificationValue = getValues("secretJustification");
  const secretConclusionValue = getValues("secretConclusion");
  const secretInfoColumns = [
    { field: "section", headerName: "セクション" },
    { field: "info", headerName: "情報" },
    { field: "justification", headerName: "正当化" },
    { field: "secretConclusion", headerName: "評価方法と結論" },
  ];
  const addSecretInfoRows = () => {
    const id =
      secretInfoRows.length === 0
        ? 1
        : Math.max(...secretInfoRows.map((v) => v.id)) +
          1; /* 配列内のオブジェクトidの最大値を求めて+1 */

    const newValue = {
      id: id,
      section: secretSectionValue,
      info: secretInfoValue,
      justification: secretJustificationValue,
      conclusion: secretConclusionValue,
    };
    setSecretInfoRows([...secretInfoRows, newValue]);
    reset({
      secretSection: "",
      secretInfo: "",
      secretJustification: "",
      secretConclusion: "",
    });
  };

  const delSecretInfoRows = () => {
    const delSecretInfoRow = apiRefForSecretInfo.current.getSelectedRows();
    if (secretInfoRows.length === 0 || delSecretInfoRow.size === 0) return;
    delSecretInfoRow.forEach((v) => {
      apiRefForSecretInfo.current.updateRows([{ id: v.id, _action: "delete" }]); // _action: 'delete'で指定idのデータを削除
    });
    const newSecretInfoRows = apiRefForSecretInfo.current.getSortedRows();
    if (newSecretInfoRows !== null) {
      setSecretInfoRows(Object.keys(newSecretInfoRows).length === 0 ? [] : [newSecretInfoRows]);
    }
  };

  const getSecretRows = () => {
    if (!apiRefForSecretInfo.current || Object.keys(apiRefForSecretInfo.current).length === 0)
      return null;
    const allSecretInfoRows = apiRefForSecretInfo.current?.getSortedRows();
    return allSecretInfoRows;
  };

  // select
  const theme = useTheme();
  const [option, setOption] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleJump = (n) => {
    setActiveStep(n);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // 一時保存時は各入力値の値をチェックせずに保存する
  const tmpSave = async () => {
    try {
      const reqData = {
        historyNo: 0,
        createdCompanyId: company._id,
        createdUserId: storedUserInfo?.value._id,
        projectName: getValues("projectName"),
        projectStatus: "50",
        branchId: getValues("branchId"),
        companyId: company._id,
        projectDesc: getValues("projectDesc"),
        projectCategory: projectCategory,
        audit: getAuditHistoryRows(),
        projectDesign: projectDesignCategory,
        actExplain: getValues("actExplain"),
        projectPlace: getValues("projectPlace"),
        proposer: {
          company: company?.groupName,
          staff: storedUserInfo?.value.username,
          role: storedUserInfo?.value.role,
          address: company?.address,
          email: storedUserInfo?.value.email,
          tel: getValues("proposerTel"),
        },
        refOtherCompany: getValues("refOtherCompany"),
        projectOwnership: getValues("projectOwnership"),
        projectEligibility: getValues("projectEligibility"),
        beforeStartingProject: getValues("beforeStartingProject"),
        legalCompliance: getValues("legalCompliance"),
        noDuplicatePublish: {
          check: duplicatePublishCheck,
          evidence: getValues("noDuplicatePublish"),
        },
        noDuplicateClaim: {
          check: duplicateClaimCheck,
          evidence: getValues("noDuplicateClaim"),
        },
        stakeholderRegulations: getValues("stakeholderRegulations"),
        envRisk: getValues("envRisk"),
        fairness: getValues("fairness"),
        soundnessA: {
          soundnessACheck: soundnessACheck,
          soundnessA1: getValues("soundnessA1"),
          soundnessA2: getValues("soundnessA2"),
        },
        soundnessB: {
          soundnessBCheck: soundnessBCheck,
          soundnessB1: getValues("soundnessB1"),
          soundnessB2: getValues("soundnessB2"),
        },
        soundnessC: {
          soundnessCCheck: soundnessCCheck,
          soundnessC1: getValues("soundnessC1"),
          soundnessC2: getValues("soundnessC2"),
        },
        soundnessD: {
          soundnessDCheck: soundnessDCheck,
          soundnessD1: getValues("soundnessD1"),
          soundnessD2: getValues("soundnessD2"),
        },
        sdContribution: getValues("sdContribution"),
        projectAddInfo: getValues("projectAddInfo"),
        projectStartDate: projectStartDate,
        startDateRightfulness: getValues("startDateRightfulness"),
        projectCreditTerm: {
          creditTerm: getValues("creditTerm"),
          creditStartDate: creditStartDate,
          creditEndDate: creditEndDate,
        },
        estimatedValue: getEstimatedValue1Rows(),
        estimatedERR: getValues("estimatedERR"),
        totalYears: getValues("totalYears"),
        aveYearlyERR: getValues("aveYearlyERR"),
        programs: getMethodologyRows(),
        programApplicability: getValues("programApplicability"),
        projectBoundary: getProjectBoundaryRows(),
        // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
        projectBoundaryFile: "",
        baselineScenario: getValues("baselineScenario"),
        additionality: getValues("additionality"),
        deviateProgram: getValues("deviateProgram"),
        baselineEmissions: getValues("baselineEmissions"),
        projectEmissions: getValues("projectEmissions"),
        leakageEmissions: getValues("leakageEmissions"),
        nonPermanentRiskEvaluation: getValues("nonPermanentRiskEvaluation"),
        nonPermanentRiskReportCheck: nonPermanentRiskReportCheck,
        projectLTA: getValues("projectLTA"),
        updateLta: {
          check: updateLtaCheck,
          NonUpdateLtaReason: getValues("NonUpdateLtaReason"),
        },
        expectGhgEmissions: getValues("expectGhgEmissions"),
        belowLta: {
          check: ghgCreditCheck,
          NotBelowLtaReason: getValues("NotBelowLtaReason"),
        },
        emissions: {
          detail: getEmissionsDetailRows(),
          total: {
            estimatedBaselineEmissionsTotal: estimatedBaselineEmissionsTotal,
            estimatedProjectEmissionsTotal: estimatedProjectEmissionsTotal,
            estimatedLeakageEmissionsTotal: estimatedLeakageEmissionsTotal,
            estimatedReductionCreditTotal: estimatedReductionCreditTotal,
            estimatedRemoveCreditTotal: estimatedRemoveCreditTotal,
            estimatedTotalCreditTotal: estimatedTotalCreditTotal,
          },
        },
        availableData: getAvailableDataRows(),
        monitoringData: getMonitoringDataRows(),
        monitoringPlan: getValues("monitoringPlan"),
        references: getValues("references"),
        secret: getSecretRows(),
        otherInfo: getValues("otherInfo"),
      };

      let projectNo = {};
      let seqNumber = 0;
      projectNo = await counterApi.upsert({ key: "projectNo" });
      seqNumber = projectNo.seq;
      reqData.projectNo = seqNumber;
      // if(!task?.seqNo) {
      //   projectNo = await counterApi.upsert({ key: "projectNo" });
      //   seqNumber = projectNo.seq;
      //   reqData.seqNo = seqNumber;
      // } else {
      //   seqNumber = task.seqNo
      // }

      const projectBoundaryFile = getValues("projectBoundaryFile");
      if (projectBoundaryFile || projectBoundaryFile.length !== 0) {
        const uploadTasks = [uploadImg(projectBoundaryFile, ["projectBoundaryFile", seqNumber])];
        const [projectBoundaryFileUrl] = await Promise.all(uploadTasks);
        reqData.projectBoundaryFile = projectBoundaryFileUrl;
      }

      projectApi
        .registerTest(reqData)
        .then(async (res) => {
          // const projectId = res.project._id;
          // return sendApplyMail(
          //   data,
          //   reqData.planDocuments,
          //   reqData.additionalDocuments,
          //   projectId
          // );
        })
        .then(() => {
          alert("一時保存しました");
          navigate("/member/projects");
        })
        .catch((e) => {
          alert("一時保存に失敗しました。運営にお問合せください。");
          console.error(e);
        });
    } catch (error) {
      alert("一時保存に失敗しました。運営にお問合せください。");
      console.error(error);
    }
  };

  return (
    <div>
      <h3>検証報告書作成</h3>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {/* step1 基本情報 */}
          <Step key={1}>
            <StepLabel optional={null} onClick={() => handleJump(0)}>
              {steps[0].label}
            </StepLabel>
            <StepContent>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  {/* <Typography mb={2}>代表者情報</Typography> */}
                  <Box mb={2}>
                    作成会社名：<strong>{company.groupName}</strong>
                  </Box>
                  {/* <Box sx={{ marginBottom: "2rem" }}> */}
                  <Box mb={2}>
                    作成者名：<strong>{storedUserInfo?.value.username}</strong>
                  </Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Controller
                      name="projectSelect"
                      control={control}
                      rules={validationRules.projectSelect}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth error={fieldState.invalid}>
                          <InputLabel>プロジェクト名</InputLabel>
                          <Select
                            {...field}
                            input={<OutlinedInput label="プロジェクト名" />}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">
                              {projectList.length
                                ? "未選択"
                                : "作成可能なプロジェクトが存在しません"}
                            </MenuItem>
                            {projectList.map((project) => (
                              <MenuItem
                                key={project._id}
                                value={project._id}
                                style={getStyles(project._id, option, theme)}
                              >
                                {project.projectName}
                              </MenuItem>
                            ))}
                          </Select>
                          {fieldState.invalid && (
                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Box>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>本検証を実施した個人</Typography>
                    <Controller
                      name="validationCheckWoker"
                      control={control}
                      rules={validationRules.validationCheckWoker}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          id="validationCheckWoker"
                          label="作業実施者"
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Typography sx={{ mr: 1 }}>
                      以下の内容を簡潔にまとめること：
                      <br />
                      ・プロジェクトの検証に関する説明
                      <br />
                      ・検証の目的と範囲
                      <br />
                      ・モニタリング期間
                      <br />
                      ・検証の方法と基準
                      <br />
                      ・検証中に指摘された事項の数
                      <br />
                      ・検証に関連する不確実性
                      <br />
                      ・検証の結論の概要
                      <br />
                    </Typography>
                    <Controller
                      name="overview"
                      control={control}
                      rules={validationRules.overview}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          id="overview"
                          label="概要"
                          multiline
                          rows={6}
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {0 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step2 はじめに */}
          <Step key={2}>
            <StepLabel optional={null} onClick={() => handleJump(1)}>
              {steps[1].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>2.1 目的</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="validationIssue"
                  control={control}
                  rules={validationRules.validationIssue}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="validationIssue"
                      label="検証の目的を説明する。"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.2 範囲と基準</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="validationRangeAndStandard"
                  control={control}
                  rules={validationRules.validationRangeAndStandard}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="validationRangeAndStandard"
                      label="検証の範囲と基準を説明する。"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.3 保証レベル</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="validationPremise"
                  control={control}
                  rules={validationRules.validationPremise}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="validationPremise"
                      label="検証の保証レベルを示す。"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.4 プロジェクトの概要説明</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectOverview"
                  control={control}
                  rules={validationRules.projectOverview}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectOverview"
                      label="プロジェクトの概要を記述する"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {1 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                  <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step3 検証プロセス */}
          <Step key={3}>
            <StepLabel optional={null} onClick={() => handleJump(2)}>
              {steps[2].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.1 方法と基準</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  検証を実施するために使用した証拠収集計画を含む方法および基準を記述する。証拠収集計画が検証の一部として使用される場合、証拠収集手法、重要な前提条件、選択した手法の
                  正当化の説明を含める。
                  <br />
                  主要なマイルストーン（キックオフ会議、デスクレビュー、現地訪問など）とそれに対応する
                  日程を含む検証スケジュールを記載する。
                </Typography>
                <Controller
                  name="methodAndStandard"
                  control={control}
                  rules={validationRules.methodAndStandard}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="methodAndStandard"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.2 書類審査</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  プロジェクト設計書、モニタリング報告書、およびあらゆる裏付け文書をレビューし、クロスチェックし、特定され記載された要件と比較する監査として、検証がどのように実施されたかを記述する。
                </Typography>
                <Controller
                  name="docExamination"
                  control={control}
                  rules={validationRules.docExamination}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="docExamination"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.3 インタビュー</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  インタビュープロセスを記述し、インタビューおよび／またはプロジェクト説明書、モニタリング報告書および補足文書に記載された以外の情報を提供した担当者を、その役割も含めて特定する。
                </Typography>
                <Controller
                  name="interview"
                  control={control}
                  rules={validationRules.interview}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="interview"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.4 現地視察</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  現地視察の方法と目的を記述すること。説明には、訪問したすべての施設及び／又はプロジェクト区域の詳細、評価されたプロジェ
                  クトの物理的及び組織的側面、並びに当該サイト訪問が実施された日付を含めること。
                  <br />
                  現地訪問が不要と判断された場合、バリデーション／検証機関は、その判断の根拠を正当化
                  し、文書化しなければならない。
                  <br />
                  モニタリング期間終了前に現地訪問が実施された場合は、実施された追加的な証拠収集
                  活動を記述し、妥当なレベルの保証が達成されたことを実証する。
                </Typography>
                <Controller
                  name="inspection"
                  control={control}
                  rules={validationRules.inspection}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="inspection"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.5 発見事項の解決</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  検証中にバリデーション／検証機関により提起された指摘事項（是正処置、明確化要求、前倒し
                  処置要求、その他の指摘事項）、および該当する場合は、バリデーションまたは前回の検証から
                  の未解決の前倒し処置要求の解決プロセスを記述する。
                  <br />
                  検証中に提起された是正処置要求、明確化要求、転送処置要求、およびその他の所見の総件数を記載する。
                  <br />
                  提起された問題点、プロジェクト提唱者から提供された回答、最終的な結論およびその結果としてのプロジェクト文書の変更を含む、各所見の概要を記載する。
                </Typography>
                <Controller
                  name="resolveFindings"
                  control={control}
                  rules={validationRules.resolveFindings}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="resolveFindings"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                3.6 バリデーション活動への参加資格
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  バリデーション／検証機関が検証の一環としてバリデーション活動を実施し、関連するセクターの範囲についてバリデーションの認定を受けていない場合は、NCCCプログラムガイドに定める適格性要件を満たしている証拠を提出する。
                  <br />
                  バリデーション／検証機関がNCCCプログラムまたは承認されたGHGプログラムのもとでバリデーションを行った登録プロジェクト5件の名称と参照番号を含める。
                </Typography>
                <Controller
                  name="eligibilityJoinValidation"
                  control={control}
                  rules={validationRules.eligibilityJoinValidation}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="eligibilityJoinValidation"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {2 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                  <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step4 検証結果 */}
          <Step key={4}>
            <StepLabel optional={null} onClick={() => handleJump(3)}>
              {steps[3].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.1 方法論の逸脱</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  今回のモニタリング期間中にプロジェクトに適用された方法論の逸脱を特定し、それぞれの逸脱を検証するために取られた手順を記述すること。以下がどのように評価されたかに関する情報を含むこと：
                  <br />
                  • その逸脱が、許容される方法論逸脱の基準と仕様に合致しているかどうか。
                  <br />
                  •
                  その乖離がGHG排出削減量または除去量の定量化の保守性に悪影響を与えるかどうか（精度を高める結果をもたらす場合を除く）。
                  <br />
                  プロジェクトに適用された方法論の逸脱が有効であるかどうかについて、全体的な結論を示すこと。
                  <br />
                  以前に検証された方法論の逸脱を列挙すること。各検証報告書には、プロジェクトに適用されたすべての方法論の逸脱を網羅的に記載すること。
                </Typography>
                <Controller
                  name="projectConclusion"
                  control={control}
                  rules={validationRules.projectConclusion}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectConclusion"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                  4.2 プロジェクト記述の逸脱
                </Typography>
                <Typography>
                  プロジェクトに適用されるプロジェクト記述の逸脱を特定し、それぞれの逸脱を検証するために取られた手順を記述する。
                  <br />
                  提案された逸脱が以下のいずれかに影響するかどうかを評価し、それぞれの評価を個別に文書化する：
                  <br />
                  • 方法論の適用性
                  <br />
                  • 追加性
                  <br />
                  • ベースラインシナリオの妥当性
                  <br />
                  逸脱が適切に説明され、正当化されているかどうか、また、プロジェクトがNCCC規則に適合しているかどうかの評価を行う。
                  <br />
                  プロジェクトの逸脱が有効であるかどうかについて、全体的な結論を提示すること。
                </Typography>
                <Controller
                  name="auditHistory"
                  control={control}
                  rules={validationRules.auditHistory}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="auditHistory"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                  4.3 グループ化されたプロジェクトの新しいプロジェクト活動事例
                </Typography>
                <Typography>
                  新しいプロジェクト活動事例を（グループ化された）プロジェクトに含めることを検証するために取られた手順について、以下を含めて記述する：
                  <br />
                  • 新しいプロジェクト活動事例の検証のための証拠収集プロセス。
                  <br />
                  • この検証期間中にプロジェクトに追加された新しいプロジェクト活動の事例数。
                  <br />
                  • 新規プロジェクトの活動事例に関する証拠、データ、文書の質と完全性。
                  <br />
                  • 新規プロジェクトの活動事例が、プロジェクト概要に定められた適格性基準および NCCC
                  プログラムの要件に適合していること。
                  <br />
                  新しいプロジェクト活動の事例を含めることが妥当かどうかについて、全体的な結論を示す。
                </Typography>
                <Controller
                  name="sectorRange"
                  control={control}
                  rules={validationRules.sectorRange}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="sectorRange"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.4 ベースラインの再評価</Typography>
                <Typography>
                  モニタリング期間中に、プロジェクトはベースラインの再評価を受けましたか？
                </Typography>
                <FormControl>
                  <RadioGroup
                    id="radioRevalidateBaseline"
                    name="radioRevalidateBaseline"
                    aria-labelledby="radio-revalidate-baseline"
                    onChange={(e) => {
                      setIsRevalidation(e.target.value);
                    }}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="はい" />
                    <FormControlLabel value="false" control={<Radio />} label="いいえ" />
                  </RadioGroup>
                </FormControl>

                {isRevalidation === "true" && (
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Typography>
                      プロジェクトのベースライン再評価を検証するために講じた措置について、以下を含めて記述してください。
                      <br />
                      • 承認された最新版の方法論、またはそれに代わる方法の適用可能性。
                      <br />
                      •
                      ベースラインシナリオが有効か無効かを判断するために提供された証拠、データ、仮定、正当化の質と完全性。
                      <br />
                      •
                      プロジェクト説明の更新された部分（例：プロジェクトの適格性、ベースラインシナリオの決定、ベースライン排出量の定量化、事前決定されたデータ／パラメータ）で提供された証拠、データ、仮定、正当性の質と完全性。
                      <br />•
                      新たな関連する国及び／又はセクターの政策及び状況がベースラインシナリオの妥当性に及ぼす影響の評価に提供された証拠書類、データ及び正当性の質と完全性。{" "}
                      <br />
                      •
                      更新されたプロジェクトの説明の中で提供された、土地利用変化の関連する割合とパターン、およびベースライン排出量の改訂された見積もりの妥当性。
                      <br />
                      • ALM プロジェクトの場合：
                      ALMプロジェクトの場合：該当する場合、プロジェクト地域の現在の一般的慣行を反映するために更新されたプロジェクト・ベースラインの妥当性。
                      <br />
                      • その後のベースライン再評価期間における事前のベースライン予測の妥当性。
                      <br />
                      ベースラインの再評価とその結果のベースラインシナリオが正確で、現実的で、妥当であるかどうかに関する全体的な結論を示すこと。
                    </Typography>
                    <Controller
                      name="projectAfoluCategory"
                      control={control}
                      rules={validationRules.projectAfoluCategory}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          id="projectAfoluCategory"
                          multiline
                          rows={3}
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
              {/* <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト活動の種類</Typography>
                <Controller
                  name="projectCategory"
                  control={control}
                  rules={validationRules.projectCategory}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectCategory"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>NCCC プログラムに参加するプロジェクトの一般的な適格性</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  • 当該プロジェクトがNCCCスタンダードの表2.1に基づき除外されないかどうか。
                  <br />
                  •
                  プロジェクトが、パイプラインのリストアップ期限、バリデーション/検証機関（VVB）とのオープニングミーティング、およびバリデーション期限に関する要件を満たしているかどうか。
                  <br />
                  • 適用される方法論が NCCC
                  プログラムにおいて適格であるかどうか、また、その方法論にスケー
                  ルおよび/または容量の制限がある場合。
                  <br />•
                  そのプロジェクトが、そのような制限を超えるような大規模なプロジェクトや活動の断片的な一部でないかどうか。{" "}
                  <br />
                  • その他関連する資格情報
                  <br />
                </Typography>
                <Controller
                  name="projectEligibility"
                  control={control}
                  rules={validationRules.projectEligibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectEligibility"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>AFOLUプロジェクトの適格性（該当する場合）</Typography>
                <Typography>
                  回答には以下が含まれるべきである：
                  <br />
                  •
                  選択されたAFOLUプロジェクト・カテゴリーが適切であり、関連するカテゴリー要件がすべて満たされているかどうか。
                  <br />
                  •
                  下記4.4.4.3項のGHGクレジットを生成するために、原生生態系が転換、開墾、排水、劣化されていないことを証明する十分な証拠があるかどうか。ARR、ALM、WRC、またはAcoGSプロジェクト地域については、以下の4.4.4.3項で、プロジェクト開始日から10年以内に皆伐や転換が行われていないことを証明する十分な証拠があるかどうか。
                  <br />
                </Typography>
                <Controller
                  name="projectAfoluEligibility"
                  control={control}
                  rules={validationRules.projectAfoluEligibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectAfoluEligibility"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>該当する場合、プロジェクトの適格性を移転する</Typography>
                <Controller
                  name="transferEligibility"
                  control={control}
                  rules={validationRules.transferEligibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="transferEligibility"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトの設計</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />•
                  グループ化されたプロジェクトの新規事例の適格性基準が、該当する場合、NCCC
                  プログラムの要件に適合しているかどうか。
                </Typography>
                <Controller
                  name="projectDesign"
                  control={control}
                  rules={validationRules.projectDesign}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectDesign"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトの設計</Typography>
                <Controller
                  name="projectOwnership"
                  control={control}
                  rules={validationRules.projectOwnership}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectOwnership"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト開始日</Typography>
                <Controller
                  name="projectStartDate"
                  control={control}
                  rules={validationRules.projectStartDate}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectStartDate"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトのクレジット期間</Typography>
                <Controller
                  name="projectCreditTerm"
                  control={control}
                  rules={validationRules.projectCreditTerm}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectCreditTerm"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトの規模</Typography>
                <Controller
                  name="projectScale"
                  control={control}
                  rules={validationRules.projectScale}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectScale"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>推定GHG排出削減・除去量の達成可能性</Typography>
                <Controller
                  name="removeAmountPossibility"
                  control={control}
                  rules={validationRules.removeAmountPossibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="removeAmountPossibility"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト活動で実施される技術・手段 </Typography>
                <Controller
                  name="techAndMeans"
                  control={control}
                  rules={validationRules.techAndMeans}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="techAndMeans"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト活動の実施スケジュール </Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />• プロジェクト活動の実施状況の評価
                </Typography>
                <Controller
                  name="projectSchedule"
                  control={control}
                  rules={validationRules.projectSchedule}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectSchedule"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトの場所 </Typography>
                <Controller
                  name="projectPlace"
                  control={control}
                  rules={validationRules.projectPlace}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectPlace"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト開始前の状況</Typography>
                <Controller
                  name="beforeStartingProject"
                  control={control}
                  rules={validationRules.beforeStartingProject}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="beforeStartingProject"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  適用される法律、法令、その他の規制枠組みへのプロジェクトの適合性
                </Typography>
                <Controller
                  name="legalCompliance"
                  control={control}
                  rules={validationRules.legalCompliance}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="legalCompliance"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>他のGHGプログラムとの二重算定と参加</Typography>
                <Controller
                  name="noDuplicatePublish"
                  control={control}
                  rules={validationRules.noDuplicatePublish}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicatePublish"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>他のGHGプログラムとの二重算定と参加</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •
                  プロジェクトが、他のGHGプログラムのもとでのプロジェクト活動による削減や除去のクレジットを受けている、または求めている場合、二重発行がないことの証拠をNCCCスタンダードの要求事項に照らして評価する。
                  <br />
                  •
                  プロジェクトが、他の温室効果ガスプログラムに登録されているか、または登録を希望しているかについて、必要とされるすべての情報を提供しているかどうか。
                  <br />•
                  プロジェクトが他のGHGプログラムで却下されたかどうかについて、必要なすべての情報を提供しているかどうか。
                </Typography>
                <Controller
                  name="noDuplicatePublish"
                  control={control}
                  rules={validationRules.noDuplicatePublish}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicatePublish"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>排出量取引制度や拘束力のある排出制限との二重請求の禁止 </Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />•
                  プロジェクトの削減・除去またはプロジェクト活動が、排出量取引プログラムまたは拘束力のある排出量制限にも含まれる場合、NCCCスタンダードの要求事項に照らして、二重請求がないことの証拠を評価する。
                </Typography>
                <Controller
                  name="noDuplicateClaim1"
                  control={control}
                  rules={validationRules.noDuplicateClaim1}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicateClaim1"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>他の環境クレジットとの二重請求の禁止</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />•
                  プロジェクト活動が他の温室効果ガス関連環境クレジット制度に基づくクレジットを要求、受領、または受領を計画している場合、NCCCスタンダードの要求事項に照らして、二重クレームがないことの証拠を評価する。
                </Typography>
                <Controller
                  name="noDuplicateClaim2"
                  control={control}
                  rules={validationRules.noDuplicateClaim2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicateClaim2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>サプライチェーン（スコープ3）排出量の二重計上</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •
                  プロジェクト提案者または正式な代理人が、プロジェクト活動によって排出量フットプリントが変化する製品の買い手または売り手であるかどうか。
                  <br />•
                  もしそうであれば、プロジェクト提案者またはその代理人が、NCCCプログラムの要求事項に従い、ウェブサイト上に公式声明を掲載しているかどうか。
                </Typography>
                <Controller
                  name="noDuplicateClaim3"
                  control={control}
                  rules={validationRules.noDuplicateClaim3}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicateClaim3"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>持続可能な開発への貢献</Typography>
                <Controller
                  name="sdContribution"
                  control={control}
                  rules={validationRules.sdContribution}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="sdContribution"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトに関連する追加情報</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •
                  リーケージ管理計画またはリーケージおよびリスク軽減措置の実施の適切性の評価（該当する場合）。
                  <br />
                  •
                  プロジェクト文書の一般公開版から除外された商業上機微な情報が、除外されうる情報に関するNCCCプログラムの要求事項に適合しているかどうかの評価。付録
                  1 に詳細を記載すること。
                  <br />
                  •
                  プロジェクトの適格性、削減量または除去量、あるいはプロジェクトの削減量または除去量の定量化に関係する可能性のある追加的な関連情報の評価。
                  <br />
                </Typography>
                <Controller
                  name="projectAddInfo"
                  control={control}
                  rules={validationRules.projectAddInfo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectAddInfo"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box> */}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {3 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                  <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step5 検証結果② */}
          <Step key={5}>
            <StepLabel optional={null} onClick={() => handleJump(4)}>
              {steps[4].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.1 監査履歴</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="stakeholderRegulations"
                  control={control}
                  rules={validationRules.stakeholderRegulations}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="stakeholderRegulations"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.2 他のGHGプログラムとの二重計上と参加
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="envRisk"
                  control={control}
                  rules={validationRules.envRisk}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="envRisk"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.3 排出量取引制度や拘束力のある排出制限との二重請求なし{" "}
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="fairness"
                  control={control}
                  rules={validationRules.fairness}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="fairness"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.4 他の形態の環境クレジットとの二重請求なし
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>特定されたリスク</Typography>
                <Controller
                  name="soundness"
                  control={control}
                  rules={validationRules.soundness}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundness"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.5 サプライチェーン（スコープ3）排出量の二重計上
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="sdContribution2"
                  control={control}
                  rules={validationRules.sdContribution2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="sdContribution2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.6 持続可能な開発への貢献</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.7 プロジェクトに関連する追加情報
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.8 ステークホルダーの参画と協議
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.9 ステークホルダーと環境へのリスク
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.10 人権と公平性の尊重</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.11 生態系の健全性</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.12 削減量と除去量の計算精度
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  GHG排出削減量と二酸化炭素除去量の算出に使用したデータとパラメータを特定し、それぞれについて以下のように評価するためにとった手順を記述する：
                  <br />
                  •
                  表計算ソフトの計算式、変換、集計の正確さ、データとパラメータの一貫した使用など、削減と除去の正確さ。
                  <br />
                  •
                  ベースライン排出量、プロジェクト排出量、リーケージ排出量の計算方法と計算式が、プロジェクト説明書に記載されているとおりに行われているかどうか。
                  <br />
                  •
                  モニタリング報告書で使用されるデフォルト値の妥当性、およびそれらがNCCCプログラム規則に適合しているかどうか。
                  <br />
                  データセット間で手動による転記ミスが発生したかどうかを評価するために取られた手順を記述する。
                  <br />
                  このプロジェクトのGHG報告書に記載された削減量と除去量が、モニタリング計画と適用された方法論に従って正しく定量化されたかどうかについて、全体的な結論を示すこと。
                </Typography>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>

              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                5.13 削減量と除去量を決定する証拠の質
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  GHG排出削減量と二酸化炭素除去量を決定するために使用した証拠を特定し、証拠の量の十分性、質の適切性を評価するためにとった手順を記述する。
                  <br />
                  報告されたデータに対して実施されたクロスチェックの詳細と、以下をどのように評価したかを含める：
                  <br />•
                  証拠の信頼性、および減額または除外を決定するための証拠の出所と性質（外部または内部、口頭、または文書）。{" "}
                  <br />
                  •
                  データの作成と集計から、記録、計算、モニタリング報告書への最終的な転記までの情報の流れ。
                  <br />
                  •
                  プロジェクト明細書にモニタリング機器の校正頻度が明記されていない場合、実施された校正頻度の妥当性。
                  <br />
                  削減と除去を決定するために使用された証拠の量の十分性、および質の適切性に関して、全体的な結論の記述文を提出すること。
                </Typography>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.14 非永続的リスク分析</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  関連する場合、プロジェクト提案者によって決定された非永続的リスク評価を評価するために取られたステップを記述する。
                  <br />
                  各リスク要因について、以下を記載する：
                  <br />
                  • リスクスコアを裏付けるために使用されたすべての根拠、仮定、正当化の評価。
                  <br />
                  • リスクスコアを裏付けるために提供された文書とデータの質の評価。
                  <br />
                  • リスク格付けの妥当性に関する結論。 <br />
                  総合的なリスク評価の決定値に関する結論を提示する。
                </Typography>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {4 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                  <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step6 検証の意見 */}
          <Step key={6}>
            <StepLabel optional={null} onClick={() => handleJump(5)}>
              {steps[5].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>7.1 検証の概要</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  GHG計算書がプロジェクト提案者の責任であること、プロジェクトがプロジェクトの検証基準に適合しているかどうか、GHG排出削減量または二酸化炭素除去量について、除外事項や修正事項があるかどうか、除外事項や修正事項の理由の説明（除外事項や修正事項については、その理由を明記すること）を明確に記載する。
                  <br />
                  プロジェクトがプロジェクト説明書に従って実施され、その後に検証された変動があることを確認する。
                  <br />
                  審査に妥当性確認活動が含まれている場合は、プロジェクトがプロジェクトの妥当性確認基準に適合しているかどうかを、除外事項や修正事項を含めて明確に記載する。
                  <br />
                  国際認定機関フォーラム認定機関が承認した妥当性確認／検証機関の意見書には、GHG
                  声明の妥当性確認及び／又は検証が ISO14064-3
                  に従って実施されたという宣言を含めなければなりません。適用される ISO
                  のバージョンが含まれていなければならない（例：ISO 14064-3: 2019）。
                  <br />
                  <br />
                </Typography>
                <Controller
                  name="verificationOverview"
                  control={control}
                  rules={validationRules.verificationOverview}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="verificationOverview"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>7.2 検証の結論</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  プロジェクトのGHG計算書に記載されている、検証期間中にプロジェクトが達成したGHG排出削減量と二酸化炭素除去量（tCO2換算）の保証レベルを記載する。
                  <br />
                  検証期間内の暦年ごとの削減量と除去量の確認と内訳を含める。
                  <br />
                  プロジェクトが削減量と除去量を別々に報告する場合、これらは別々に検証されなければならない。
                  <br />
                  検証期間： 検証期間：[DD-Month-YYYYY]から[DD-Month-YYYYY]まで。
                  <br />
                  上記の検証期間において検証されたGHG排出削減量および二酸化炭素除去量
                </Typography>
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                永続性リスクの評価が必要なプロジェクトの場合、下記を記載する
              </Typography>
              <Typography>以下の、要求された情報を提供してください。</Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>非永続的リスク評価(%)： </Typography>
                <Controller
                  name="nonPermanentRiskEvaluation"
                  control={control}
                  rules={validationRules.nonPermanentRiskEvaluation}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="nonPermanentRiskEvaluation"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>％</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={{ mr: 1 }}>
                  該当する場合、長期平均（LTA）、それが適切に更新されているかどうか、またそれに達しているかどうか。
                </Typography>
                <Controller
                  name="updateLtaDoc"
                  control={control}
                  rules={validationRules.updateLtaDoc}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      multiline
                      rows={3}
                      id="updateLtaDoc"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={{ mr: 1 }}>
                  該当する場合には、NCCC プログラム規則に従い、損失が適切に計上されているかどうか。
                </Typography>
                <Controller
                  name="updateLtaDoc"
                  control={control}
                  rules={validationRules.updateLtaDoc}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      multiline
                      rows={3}
                      id="updateLtaDoc"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography>プロジェクトの各検証期間について、下記データ登録を1データ毎に実施してください。</Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                      <Box sx={{ marginBottom: "1rem" }}>
                        <Typography sx={{ mr: 1 }}>検証期間</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <DatePicker
                            id="projectCreditTermStart"
                            // label="プロジェクト開始日"
                            value={projectCreditTermStart}
                            onChange={handleChangeProjectCreditTermStart}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                          <DatePicker
                            id="projectCreditTermEnd"
                            // label="プロジェクト終了日"
                            value={projectCreditTermEnd}
                            onChange={handleChangeProjectCreditTermEnd}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid xs={11} lg={2}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>ベースライン排出量 (tCO2e)</Typography>
                      <Controller
                        name="estimatedBaselineEmissions"
                        control={control}
                        rules={validationRules.estimatedBaselineEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={2}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>プロジェクト排出量（tCO2e）</Typography>
                      <Controller
                        name="estimatedProjectEmissions"
                        control={control}
                        rules={validationRules.estimatedProjectEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={2}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>リーケージ排出量（tCO2e）</Typography>
                      <Controller
                        name="estimatedLeakageEmissions"
                        control={control}
                        rules={validationRules.estimatedLeakageEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid xs={11} lg={2}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>バッファープール割当(tCO2e)</Typography>
                      <Controller
                        name="estimatedReductionCredit"
                        control={control}
                        rules={validationRules.estimatedReductionCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={2}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>削減クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedRemoveCredit"
                        control={control}
                        rules={validationRules.estimatedRemoveCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={2}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>除去クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedRemoveCredit"
                        control={control}
                        rules={validationRules.estimatedRemoveCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={2}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>合計クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedTotalCredit"
                        control={control}
                        rules={validationRules.estimatedTotalCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={1} lg={1}>
                    <Box sx={{ marginTop: "2rem" }}>
                      <Button variant="contained" onClick={addProjectCreditTermRows}>
                        追加
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component="div" sx={{ p: 2, textAlign: "left" }}>
                  <Button variant="contained" onClick={delProjectCreditTermRows}>
                    削除
                  </Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForProjectCreditTerm}
                  columns={projectCreditTermColumns}
                  rows={projectCreditTermRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ marginBottom: "2rem", mt: 2 }}>
                <Typography sx={{ mr: 1 }}>合計</Typography>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>ベースライン排出量 : </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedBaselineEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>プロジェクト排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedProjectEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>リーケージ排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedLeakageEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>削減クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedReductionCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>除去クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedRemoveCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定合計クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedTotalCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {7 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                  <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step8 検証機関の委員リスト */}
          <Step key={7}>
            <StepLabel optional={null} onClick={() => handleJump(6)}>
              {steps[6].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>検証機関の委員リスト</Typography>
              <Typography>以下のリストは機密事項であり、公表版からは削除されます。</Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="committeeName"
                        control={control}
                        rules={validationRules.secretSection}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="名前"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <Controller
                        name="committeeBelong"
                        control={control}
                        rules={validationRules.secretInfo}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="所属"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="committeeApproval"
                        control={control}
                        rules={validationRules.secretJustification}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="検証への同意(する／しない／棄権)"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addCommitteeListRows}>
                        追加
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component="div" sx={{ p: 2, textAlign: "left" }}>
                  <Button variant="contained" onClick={delCommitteeListRows}>
                    削除
                  </Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForCommitteeList}
                  columns={committeeListColumns}
                  rows={committeeListRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {8 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                  <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step9 商業上の機密情報*/}
          <Step key={8}>
            <StepLabel optional={null} onClick={() => handleJump(7)}>
              {steps[7].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>8.1 商業上の機密情報</Typography>
              <Typography>
                プロジェクトの説明文に含まれる商業上機微な情報な情報のうち、一般公開版では除外するものについて説明してください。
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="secretSection"
                        control={control}
                        rules={validationRules.secretSection}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="セクション"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <Controller
                        name="secretInfo"
                        control={control}
                        rules={validationRules.secretInfo}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="情報"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="secretJustification"
                        control={control}
                        rules={validationRules.secretJustification}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="正当化"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={11}>
                    <Box>
                      <Controller
                        name="secretConclusion"
                        control={control}
                        rules={validationRules.secretConclusion}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="評価方法と結論"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addSecretInfoRows}>
                        追加
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component="div" sx={{ p: 2, textAlign: "left" }}>
                  <Button variant="contained" onClick={delSecretInfoRows}>
                    削除
                  </Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForSecretInfo}
                  columns={secretInfoColumns}
                  rows={secretInfoRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {8 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                  <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step key={9}>
            <StepLabel optional={null} onClick={() => handleJump(8)}>
              {steps[8].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>11.2 その他・補足事項</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="otherInfo"
                  control={control}
                  rules={validationRules.otherInfo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      id="otherInfo"
                      label="その他・補足事項等"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {9 === steps.length - 1 ? "完了" : "次へ"}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一次保存
                  </Button>
                  <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
        {/* 9.1 検証時に利用可能なデータとパラメータ 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog maxWidth="lg" open={isOpenMonitoringDialog1} onClose={handleCloseMonitoringDialog1}>
          <DialogTitle>検証時に利用可能なデータとパラメータ</DialogTitle>
          <DialogContent sx={{ bgcolor: "white" }}>
            <DialogContentText>
              <br />
              検証時（バリデーション時）に決定された、または入手可能で、プロジェクトのクレジット対象期間を通じて固定されているすべてのデータおよびパラメータについて入力してください。
              <br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>データ／パラメータ</Typography>
                      <Controller
                        name="monitoringDataOrParam1"
                        control={control}
                        rules={validationRules.monitoringDataOrParam1}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ単位</Typography>
                      <Tooltip placement="top-start" title="単位を示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataUnit1"
                      control={control}
                      rules={validationRules.monitoringDataUnit1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ内容</Typography>
                      <Tooltip
                        placement="top-start"
                        title="データ／パラメータの簡単な説明を記入する"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataContents1"
                      control={control}
                      rules={validationRules.monitoringDataContents1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データソース</Typography>
                      <Tooltip placement="top-start" title="データソースを示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataSource1"
                      control={control}
                      rules={validationRules.monitoringDataSource1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用値</Typography>
                      <Tooltip placement="top-start" title="適用される値を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringApplicableValue1"
                      control={control}
                      rules={validationRules.monitoringApplicableValue1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>
                        データ選択の正当性、または適用した測定方法と手順の説明
                      </Typography>
                      <Tooltip
                        placement="top-start"
                        title="データ源の選択を正当化し、必要に応じて参考文献を示す。値が測定に基づく場合は、適用された測定方法と手順（例えば、どのような基準またはプロトコルに従ったのか）の説明を含め、測定を行った責任者／団体、測定日、測定結果を示す。付録として、より詳細な情報を記載することもできる。"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringExplain1"
                      control={control}
                      rules={validationRules.monitoringExplain1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={4}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データの目的</Typography>
                      <Tooltip
                        placement="top-start"
                        title={
                          <>
                            以下のいずれかを示す：
                            <br />
                            • ベースラインシナリオの決定（AFOLUプロジェクトのみ）
                            <br />
                            • ベースライン排出量の算定
                            <br />
                            • プロジェクト排出量の算定
                            <br />• リーケージの算定
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringPurpose1"
                      control={control}
                      rules={validationRules.monitoringPurpose1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>コメント</Typography>
                      <Tooltip placement="top-start" title="その他コメントがあればご記入ください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringComment1"
                      control={control}
                      rules={validationRules.monitoringComment1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ bgcolor: "white" }}>
            <Button onClick={handleCloseMonitoringDialog1}>戻る</Button>
            <Button
              onClick={addMonitoring1Rows}
              // disabled={true}
            >
              追加
            </Button>
          </DialogActions>
        </Dialog>
        {/* 9.2 モニタリングされるデータとパラメータ 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog maxWidth="lg" open={isOpenMonitoringDialog2} onClose={handleCloseMonitoringDialog2}>
          <DialogTitle>モニタリングされるデータとパラメータ</DialogTitle>
          <DialogContent sx={{ bgcolor: "white" }}>
            <DialogContentText>
              <br />
              プロジェクトのクレジット対象期間中にモニタリングされるすべてのデータとパラメータについて入力してください。
              <br />
              提供された値は、プロジェクトのクレジット対象期間における削減量と除去量の推定値を定量化するために使用されます。
              <br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>データ／パラメータ</Typography>
                      <Controller
                        name="monitoringDataOrParam2"
                        control={control}
                        rules={validationRules.monitoringDataOrParam2}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ単位</Typography>
                      <Tooltip placement="top-start" title="測定単位を示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataUnit2"
                      control={control}
                      rules={validationRules.monitoringDataUnit2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ内容</Typography>
                      <Tooltip
                        placement="top-start"
                        title="データ／パラメータの簡単な説明を記入する"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataContents2"
                      control={control}
                      rules={validationRules.monitoringDataContents2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データソース</Typography>
                      <Tooltip placement="top-start" title="データソースを示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataSource2"
                      control={control}
                      rules={validationRules.monitoringDataSource2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用される測定方法と手順の説明</Typography>
                      <Tooltip
                        placement="top-start"
                        title="測定方法及び手順、従うべき標準又はプロトコル、及び測定に責任を有する人／組織を明記する。測定の精度に関する関連情報（例えば、計器装置または実験室試験に関連する精度）を含む。"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringExplain2"
                      control={control}
                      rules={validationRules.monitoringExplain2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>モニタリング／記録の頻度</Typography>
                      <Tooltip placement="top-start" title="測定と記録の頻度を指定する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringFrequency"
                      control={control}
                      rules={validationRules.monitoringFrequency}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用値</Typography>
                      <Tooltip placement="top-start" title="適用される値を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringApplicableValue2"
                      control={control}
                      rules={validationRules.monitoringApplicableValue2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>モニタリング機器</Typography>
                      <Tooltip
                        placement="top-start"
                        title="データ／パラメータをモニターするために使用される機器を特定すること。必要に応じて、機器のタイプ、精度クラス、シリアル番号を含む"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDevice"
                      control={control}
                      rules={validationRules.monitoringDevice}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用されるQA/QC手順</Typography>
                      <Tooltip
                        placement="top-start"
                        title="適用される品質保証及び品質管理（QA/QC）手順（該当する場合は校正手順を含む）を記載すること。"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringQaQcProcedure"
                      control={control}
                      rules={validationRules.monitoringQaQcProcedure}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データの目的</Typography>
                      <Tooltip
                        placement="top-start"
                        title={
                          <>
                            以下のいずれかを示すこと：
                            <br />
                            • ベースライン排出量の計算
                            <br />
                            • プロジェクト排出量の計算
                            <br />• リーケージの計算
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringPurpose2"
                      control={control}
                      rules={validationRules.monitoringPurpose2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>計算方法</Typography>
                      <Tooltip
                        placement="top-start"
                        title="該当する場合、データ／パラメータを確定するために使用した計算方法（方程式を含む）を提示すること。"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="howToCalc"
                      control={control}
                      rules={validationRules.howToCalc}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={5}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>コメント</Typography>
                      <Tooltip placement="top-start" title="その他コメントがあればご記入ください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringComment2"
                      control={control}
                      rules={validationRules.monitoringComment2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ bgcolor: "white" }}>
            <Button onClick={handleCloseMonitoringDialog2}>戻る</Button>
            <Button
              onClick={addMonitoring2Rows}
              // disabled={true}
            >
              追加
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};
