import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import businessCardApi from "../../../api/businessCardApi";
import mailApi from "../../../api/mailApi";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";
import businessCardImg from "../../../assets/imgs/nccc_namecard_sample.jpg";

export const BusinessCardCreate = () => {
  const dispatch = useDispatch();
  const [familyName, setFamilyName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [familyNameKana, setFamilyNameKana] = React.useState("");
  const [lastNameKana, setLastNameKana] = React.useState("");
  const [mailAdress, setMailAdress] = React.useState("");
  const [isPublishChecked, setIsPublishChecked] = React.useState(true);
  const [tel, setTel] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [billToName, setBillToName] = React.useState("");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);

  const submitBusinessCard = async () => {
    setLoading(true);
    if (familyName === "") {
      alert("苗字を入力してください。");
      setLoading(false);
      return;
    }
    if (lastName === "") {
      alert("名前を入力してください。");
      setLoading(false);
      return;
    }
    if (familyNameKana === "") {
      alert("苗字（カナ）を入力してください。");
      setLoading(false);
      return;
    }
    if (lastNameKana === "") {
      alert("名前（カナ）を入力してください。");
      setLoading(false);
      return;
    }
    if (mailAdress === "") {
      alert("メールアドレスを入力してください。");
      setLoading(false);
      return;
    }
    // if (tel === "") {
    //   alert("連絡先（電話番号）を入力してください。");
    //   setLoading(false);
    //   return;
    // }
    if (quantity === "") {
      alert("数量を選択してください。");
      setLoading(false);
      return;
    }
    if (address === "") {
      alert("郵送先住所を入力してください。")
      setLoading(false);
      return;
    }
    if (billToName === "") {
      alert("請求先名を入力してください。")
      setLoading(false);
      return;
    }

    const businessCard = {
      familyName: familyName,
      lastName: lastName,
      familyNameKana: familyNameKana, 
      lastNameKana: lastNameKana,
      mailAdress: mailAdress,
      isMailPublish: isPublishChecked, // true ならメールアドレスの掲載あり
      tel: tel,
      quantity: quantity,
      address: address,
      billToName: billToName,
      // 以降は問い合わせ時に登録したユーザを特定できるようにするための項目
      companyId: user.companyId,
      username: user.username,
    };

    await businessCardApi
      .create(businessCard)
      .then(async (res) => {
        if (res.businessCard) {
          sendMailBusinessCard(res.businessCard);
          sendMailSystem(res.businessCard);
          setLoading(false);
          dispatch(setUpdateBadgeFlg(true));
          alert("作成依頼を受け付けました。入力いただいたメールアドレス宛に入力内容を送信しました。");
          navigate("/member");
        } else {
          setLoading(false);
          alert("申請に失敗しました。");
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        alert("申請に失敗しました。");
      });
  };

  const sendMailBusinessCard = async (businessCard) => {
    const requestData = {
      // to: mailAdress,
      replaceWords: businessCard,
      mailId: "30",
    };
    await mailApi.businessCard(requestData);
  };

  const sendMailSystem = async (businessCard) => {
    const requestData = {
      // to: mailAdress,
      replaceWords: businessCard,
      mailId: "31",
    };
    await mailApi.businessCard(requestData);
  };

  const handleChangeIsNoPublishChecked = (event) => {
    setIsPublishChecked(event.target.checked);
  };


  return (
    <Box sx={{ ml: 2 }}>
      <h3>名刺作成</h3>
      <Typography>
        会員の皆様へ<br />
        名刺作成と関連する費用についてのご案内<br />
        <br />
        当社団NCCCでは、名刺の作成サービスを提供しております。<br />
        名刺をご希望の会員の皆様には、以下の手順に従って必要な情報をご提供いただき、名刺の制作を進めていただけます。<br />
        また、名刺のデザインについては、入力フォーム下部に掲載されているデザイン案をご参照いただけます。<br />
        <br />
        名刺作成代金：2,200円/100枚あたり（税込、送料込）<br />
        納期：入力フォーム送信後、約1週間程度<br />
        <br />
        お支払いについては、受付が完了次第、ご請求書を添付したメールにてご案内差し上げます。<br />
        何かご質問やご不明点がございましたら、どうぞお気軽にお知らせください。<br />
        <br />
        名刺の作成をご希望の方は、以下の入力フォームより必要情報をご提供いただき、送信ボタンを押してください。<br />
      </Typography>
      <Box sx={{ width: "100%", maxWidth: "800px" }}>
        <Box sx={{ mt: 2, mb: 2, display: "column", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="氏"
                variant="outlined"
                margin="dense"
                onChange={(e) => setFamilyName(e.target.value)}
                value={familyName}
                required
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="名"
                variant="outlined"
                margin="dense"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="氏（ローマ字）"
                variant="outlined"
                margin="dense"
                value={familyNameKana}
                onChange={(e) => setFamilyNameKana(e.target.value)}
                required
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="名（ローマ字）"
                variant="outlined"
                margin="dense"
                value={lastNameKana}
                onChange={(e) => setLastNameKana(e.target.value)}
                required
              />
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="メールアドレス"
                variant="outlined"
                margin="dense"
                value={mailAdress}
                onChange={(e) => setMailAdress(e.target.value)}
                required
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={isPublishChecked}
                    onChange={handleChangeIsNoPublishChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="メールアドレスを名刺に載せない場合はOFFにしてください"
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="連絡先（電話番号）"
                variant="outlined"
                margin="dense"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                helperText="※名刺に表示したい場合は入力してください"
              />
            </FormControl>
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <FormControl fullWidth required>
              <InputLabel id="businessCardQuantity">数量</InputLabel>
              <Select
                labelId="quantity"
                id="quantity"
                value={quantity}
                label="数量"
                onChange={(e) => setQuantity(e.target.value)}
              >
                <MenuItem value="100">100枚</MenuItem>
                <MenuItem value="200">200枚</MenuItem>
                <MenuItem value="300">300枚</MenuItem>
                <MenuItem value="400">400枚</MenuItem>
                <MenuItem value="500">500枚</MenuItem>
                <MenuItem value="600">600枚</MenuItem>
                <MenuItem value="700">700枚</MenuItem>
                <MenuItem value="800">800枚</MenuItem>
                <MenuItem value="900">900枚</MenuItem>
                <MenuItem value="1000">1000枚</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="郵送先住所"
                variant="outlined"
                margin="dense"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                helperText="※名刺の郵送先住所を記載してください"
                required
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", gap: 2, mt: 1, mb: 2 }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="請求先名"
                variant="outlined"
                margin="dense"
                value={billToName}
                onChange={(e) => setBillToName(e.target.value)}
                helperText="※請求先の宛名として記載する名称を記載ください"
                required
              />
            </FormControl>
          </Box>


          <LoadingButton variant="contained" onClick={submitBusinessCard} loading={loading}>
            送信する
          </LoadingButton>
        </Box>
        <Typography sx={{ mt: 8 }}>
          名刺サンプル
        </Typography>
        <img
          alt="jpeg"
          title="NCCC BusinessCard Sample"
          src={businessCardImg}
          width="84%"
          height="84%"
        />
      </Box>
    </Box>
  );
};
