import React, { useState, useEffect } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';

export const InputSideHeaderTable = ({ fieldData }) => {
    // 親コンポーネントは FormProvider でラップされていること
    const { control, getValues, register } = useFormContext()
    const values = useWatch({
        control, name: fieldData.name
    })
    const { fields, append, remove } = useFieldArray({
        control, name: fieldData.name
    })
    const { header, sideHeader } = fieldData

    const makeDefaultField = () => {
        const field = {}
        header.forEach((column) => {
            field[column.id] = column.default
        })
        field.actions = ""
        return field
    }
    const defaultField = makeDefaultField()

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        const test = getValues(fieldData.name)
        console.log(values)
    };

    const handleAddRow = () => {
        append(defaultField)
    }

    const inputTagProvider = (v, id, idx) => {
        switch (v.type){
            case "text":
                return (
                    <TextField
                        required={fieldData.required}
                        fullWidth
                        id={id}
                        multiline={true}
                        maxRows={5}
                        type="text"
                        disabled={v.disabled}
                        {...register(`${fieldData.name}.${idx}.${v.id}`)}
                    />
                )
            case "numeric":
                return (
                    <TextField
                        required={fieldData.required}
                        fullWidth
                        id={id}
                        type="number"
                        {...register(`${fieldData.name}.${idx}.${v.id}`)}
                    />
                )
            case "actions":
                return (
                    <Button
                        id={id}
                        onClick={() => {remove(idx)}}
                    >
                        <DeleteIcon />
                    </Button>
                )
            default:
                return ""
        }
    }

    const fetchRows = async () => {
        sideHeader.forEach(() => {
            handleAddRow()
        })
    }
    const init = async () => {
        await fetchRows()
    }
    useEffect(() => {
        init()
    }, [])

    return (
        <Box mt={3}>
            {fieldData.title
                && <Typography variant="subtitle1" gutterBottom>{fieldData.title}</Typography>
            }
            {fieldData.explanation
                && <Typography variant="subtitle2" gutterBottom>{fieldData.explanation}</Typography>
            }
            <Button
                type="button"
                variant="contained"
                onClick={handleAddRow}
                // disabled={xxx < sideHeader.length + 1}
            >
                行追加
            </Button>
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 2, bgcolor: "#fffacd" }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {header.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        sx={{ bgcolor: "#fffacd" }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow tabIndex={-1} key={row.id} sx={{ bgcolor: "white" }}>
                                        <TableCell>
                                            {sideHeader[index]}
                                        </TableCell>
                                        {header.map((column) => {
                                        // const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} padding="none" width={column.width}>
                                                {/* {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value} */}
                                                    {inputTagProvider(column, row.id, index)}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={fields.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ bgcolor: "white" }}
                />
            </Paper>
            {/* {fieldData.caption
                && <Typography variant="subtitle2" gutterBottom>{fieldData.caption}</Typography>
            } */}
        </Box>
    )
}
