import React from "react";
import kuwaeWebp from "../../assets/imgs/observer/kuwae.webp";
import kuwaeJpg from "../../assets/imgs/observer/kuwae.jpg";
import matsuokaWebp from "../../assets/imgs/observer/matsuoka.webp";
import matsuokaJpg from "../../assets/imgs/observer/matsuoka.jpg";
import nishiharaWebp from "../../assets/imgs/observer/nishihara.webp";
import nishiharaJpg from "../../assets/imgs/observer/nishihara.jpg";
import hayabuchiWebp from "../../assets/imgs/observer/hayabuchi.webp";
import hayabuchiJpg from "../../assets/imgs/observer/hayabuchi.jpg";
import head_bg from "../../assets/imgs/observer/head_bg.jpg";
import { SubpageHeader } from "../../components/homepage/SubpageHeader";
import ScrollToTopOnMount from "../../components/common/utils/ScrollToTopOnMount";

const styled = {
  sp: {
    display: "none",
  },
  p: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    color: "#000000de",
  },
  cssGeaalz: {
    margin: "0px auto",
    padding: "20px",
    maxWidth: "1024px",
  },
  cssObyu64: {
    margin: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    fontSize: "2.125rem",
    lineHeight: "1.235",
    letterSpacing: "0.00735em",
    color: "rgb(255, 255, 255)",
  },
  cssH2cita: {
    background: "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(" + head_bg + ")",
    height: "250px",
    display: "flex",
    webkitBoxAlign: "center",
    alignItems: "center",
    padding: "0px 0px 0px 100px",
  },
  intro: {
    display: "flex",
    marginBottom: "3%",
  },
  name: {
    position: "relative",
    fontSize: "1.6em",
    fontWeight: "700",
    marginBottom: "5%",
    paddingBottom: "5%",
  },
  nameAfter: {
    content: "",
    position: "absolute",
    left: "0",
    bottom: "0%",
    display: "inline-block",
    width: "40px",
    height: "2px",
    backgroundColor: "#78b94f",
  },
  en: {
    fontSize: "1.1rem",
    fontWeight: "400",
    display: "block",
  },
  affiliation: {
    marginBottom: "5%",
    fontSize: "1rem",
    color: "#47b800",
  },
  info: {
    marginBottom: "3%",
  },
  option: {
    fontSize: "1rem",
  },
  member_wrap: {
    marginTop: "10%",
  },
};
export const Observer = () => {
  return (
    <div class="MuiBox-root css-1dch20z">
      <div class="MuiBox-root css-0">
        <SubpageHeader
          title="Partner"
          breadcrumb={[{ name: "学術パートナー研究員", link: "/observer" }]}
        />

        <div class="MuiBox-root" style={styled.cssGeaalz}>
          <div style={styled.member_wrap}>
            <div style={{ marginBottom: "10%" }}>
              <div style={styled.intro}>
                <picture
                  style={{
                    display: "block",
                    maxWidth: "240px",
                    width: "100%",
                    height: "300px",
                    marginRight: "6%",
                  }}
                >
                  <source srcset={kuwaeWebp} type="image/webp" />
                  <img
                    src={kuwaeJpg}
                    alt="桑江教授"
                    width="239"
                    height="300"
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      border: "5px solid #77a15d2b",
                      boxSizing: "border-box",
                    }}
                  />
                </picture>
                <div class="name_wrap">
                  <p style={styled.name}>
                    桑江 朝比呂<span style={styled.en}>Tomohiro Kuwae</span>
                  </p>
                  <div
                    style={{
                      display: "block",
                      width: "40px",
                      height: "2px",
                      backgroundColor: "#78b94f",
                      marginTop: "-30px",
                      marginBottom: "20px",
                    }}
                  />
                  <p style={styled.affiliation}>港湾空港技術研究所 沿岸環境研究領域長</p>
                  <p>
                    1995年3月 京都大学大学院農学研究科修了
                    <br />
                    1995年4月 運輸省港湾技術研究所 研究官
                    <br />
                    2001年10月 港湾空港技術研究所 主任研究官
                    <br />
                    2001年11月 博士（農学）取得
                    <br />
                    2016年4月 港湾空港技術研究所 沿岸環境研究グループ グループ長
                    <br />
                    2020年7月－現在 ジャパンブルーエコノミー技術研究組合（JBE） 理事長
                    <br />
                    2022年10月－現在 港湾空港技術研究所 沿岸環境研究領域長
                  </p>
                </div>
              </div>
              <div class="txt_wrap">
                <p style={styled.info}>
                  国内におけるブルーカーボンの先駆者として，論文，書籍や新聞等紙上掲載多数（下記参照）．専門はブルーカーボンに関する科学，政策，実践に加え，沿岸生態系，環境工学，気候変動
                  対策，環境価値の定量化，など
                </p>
                <p style={styled.option}>
                  【論文】（
                  <a
                    href="https://scholar.google.co.jp/citations?user=QLLdMBIAAAAJ&amp;hl=ja&amp;oi=sra"
                    target="_blank"
                    rel="noopener"
                  >
                    https://scholar.google.co.jp/citations?user=QLLdMBIAAAAJ&amp;hl=ja&amp;oi=sra
                  </a>
                  ）
                  <br />
                  【書籍】「ブルーカーボン：浅海における CO2 隔離・貯留とその活用」
                  <br />
                  【書籍】「沿岸域における環境価値の定量化ハンドブック」
                  <br />
                  【書籍】"Blue Carbon in Shallow Coastal Ecosystems" Springer Nature
                </p>
              </div>
            </div>
            <div style={{ marginBottom: "10%" }}>
              <div style={styled.intro}>
                <picture
                  style={{
                    display: "block",
                    maxWidth: "240px",
                    width: "100%",
                    height: "300px",
                    marginRight: "6%",
                  }}
                >
                  <source srcset={matsuokaWebp} type="image/webp" />
                  <img
                    src={matsuokaJpg}
                    alt="松岡教授"
                    width="100"
                    height="131"
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      border: "5px solid #77a15d2b",
                      boxSizing: "border-box",
                    }}
                  />
                </picture>
                <div class="name_wrap">
                  <p style={styled.name}>
                    松岡 茂<span style={styled.en}>Shigeru Matsuoka</span>
                  </p>
                  <div
                    style={{
                      display: "block",
                      width: "40px",
                      height: "2px",
                      backgroundColor: "#78b94f",
                      marginTop: "-30px",
                      marginBottom: "20px",
                    }}
                  />

                  <p style={styled.affiliation}>
                    大分大学 医学部 臨床薬理学講座 特任教授
                    <br />
                    Albius Sciences Ltd. Co-founder &amp; Chief Technology Officer
                  </p>
                  <p>
                    1997年 東京大学理学部化学科卒業
                    <br />
                    1999年 同大学院修士課程修了
                    <br />
                    2003年 大阪大学大学院博士後期課程修了（理学博士）
                  </p>
                </div>
              </div>
              <div class="txt_wrap">
                <p style={styled.info}>
                  米国ワシントン大学セントルイス校博士研究員、東京大学薬学部助教、大阪大学理学部特任准教授、東京大学医学部特任准教授などを経て現在に至る。平成23年度日本薬学会奨励賞、第4回天然物化学談話会奨励賞。大分大学では、AMED支援のもとに開発した新規創薬手法を実践し、大学研究を基に特許を取得。自ら創業者の一人として起業した海外スタートアップ企業Albius
                  Scieces
                  Ltc.にライセンスアウトし、日本、香港、シンガポールを拠点に抗ウイルス化合物や抗線維化化合物の医薬品開発を推進する。東京大学理学部での卒業研究/修士研究のテーマは底生性渦鞭毛藻が生産する毒の単離と構造解析。渦鞭毛藻の採取で訪れた沖縄の海でサンゴの白化を目の当たりにして海洋生物多様性の保全に長年関心を持っている。
                </p>
              </div>
            </div>
            <div style={{ marginBottom: "10%" }}>
              <div style={styled.intro}>
                <picture
                  style={{
                    display: "block",
                    maxWidth: "240px",
                    width: "100%",
                    height: "300px",
                    marginRight: "6%",
                  }}
                >
                  <source srcset={nishiharaWebp} type="image/webp" />
                  <img
                    src={nishiharaJpg}
                    alt="ニシハラ グレゴリーナオキ"
                    width="135"
                    height="180"
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      border: "5px solid #77a15d2b",
                      boxSizing: "border-box",
                    }}
                  />
                </picture>
                <div class="name_wrap">
                  <p style={styled.name}>
                    ニシハラ グレゴリーナオキ<span style={styled.en}>Nishihara Gregory Naoki</span>
                  </p>
                  <div
                    style={{
                      display: "block",
                      width: "40px",
                      height: "2px",
                      backgroundColor: "#78b94f",
                      marginTop: "-30px",
                      marginBottom: "20px",
                    }}
                  />

                  <p style={styled.affiliation}>長崎大学 海洋未来イノベーション機構 教授</p>
                  <p>
                    2010年度 - 2011年度：長崎大学, 環東シナ海環境資源研究センター, 助教
                    <br />
                    2011年度：長崎大学, 水産・環境科学総合研究科, 助教
                    <br />
                    2012年度：長崎大学,
                    大学院・水産環境科学総合研究科附属環東シナ海環境資源研究センター, 准教授
                    <br />
                    2012年度 - 2013年度：長崎大学, 水産・環境科学総合研究科, 准教授
                    <br />
                    2014年度：長崎大学, 水産・環境科学総合研究科(水産), 准教授
                    <br />
                    2012年度 - 2015年度：長崎大学, その他の研究科, 准教授
                    <br />
                    2014年度 - 2015年度：長崎大学, 水産・環境科学総合研究科(環シナ), 准教授
                    <br />
                    2015年度：長崎大学,
                    大学院水産・環境科学総合研究科附属環東シナ海環境資源研究センター, 准教授
                    <br />
                    2016年度 - 2019年度：長崎大学, 海洋未来イノベーション機構, 准教授
                  </p>
                </div>
              </div>
              <div class="txt_wrap">
                <p style={styled.option}>
                  【主な研究課題】
                  <br />
                  2013年 - 2015年　天然藻場における生態系一次生産の評価研究
                  <br />
                  2017年 - 2019年　捕食圧に対応できる藻場創成
                  <br />
                  2020年 - 2022年　Mitigating climate change through seaweed aquaculture
                </p>
              </div>
            </div>
            <div style={{ marginBottom: "10%" }}>
              <div style={styled.intro}>
                <picture
                  style={{
                    display: "block",
                    maxWidth: "240px",
                    width: "100%",
                    height: "300px",
                    marginRight: "6%",
                  }}
                >
                  <source srcset={hayabuchiWebp} type="image/webp" />
                  <img
                    src={hayabuchiJpg}
                    alt="早渕　百合子"
                    width="157"
                    height="224"
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      border: "5px solid #77a15d2b",
                      boxSizing: "border-box",
                    }}
                  />
                </picture>
                <div class="name_wrap">
                  <p style={styled.name}>
                    早渕　百合子<span style={styled.en}>Hayabuchi Yuriko</span>
                  </p>
                  <div
                    style={{
                      display: "block",
                      width: "40px",
                      height: "2px",
                      backgroundColor: "#78b94f",
                      marginTop: "-30px",
                      marginBottom: "20px",
                    }}
                  />

                  <p style={styled.affiliation}>
                    九州大学 グローバルイノベーションセンター アドバンストプロジェクト部門 準教授
                  </p>
                  <p>
                    【研究テーマ】
                    <br />
                    持続可能な低炭素社会の実現に向けた社会システムデザインに関する研究と評価
                    <br />
                    【社会貢献・国際連携活動】
                    <br />
                    議定書批准国が毎年提出する温室効果ガス排出・吸収量目録の審査への参加
                  </p>
                </div>
              </div>
              <div class="txt_wrap">
                <p style={styled.option}>
                  【国内, 国際政策形成, 及び学術振興等への寄与活動】
                  <br />
                  2022.09, GHG inventory centralized review of Luxembourg, Malta and the United
                  States of America （温室効果ガス排出量の審査）,
                  UNFCCC（国連気候変動枠組条約事務局）.
                  <br />
                  2021.07, GHG inventory centralized review of Iceland, Lithuania, Malta and Turkey
                  （温室効果ガス排出量の審査）, UNFCCC（国連気候変動枠組条約事務局）.
                  <br />
                  2020.07, GHG inventory centralized review of Belgium, Croatia and
                  Portugal（温室効果ガス排出量の審査）, UNFCCC（国連気候変動枠組条約事務局）.
                  <br />
                  2022.04～2024.03, 福津市環境審議会/委員, 福津市.
                  <br />
                  2022.07, GHG inventory centralized review of Malta, Luxembourg and
                  USA（温室効果ガス排出量の審査）, UNFCCC（国連気候変動枠組み条約事務局）.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTopOnMount />
    </div>
  );
};
