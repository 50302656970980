import React from "react";
import branchApi from "../../../api/branchApi";
import branchUserApi from "../../../api/branchUserApi";
import mailApi from "../../../api/mailApi";
import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
//import { Link, Navigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import "../../../assets/css/Global.css";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { useAuthUserContext, RoleType } from "../../../providers/auth";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";

const itemHeaderStyle = {
  color: "#FFFFFF",
};

export const BranchUserApply = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [history, setHistory] = useState([]);
  const { id } = useParams();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [role, setRole] = useState("");
  const authUser = useAuthUserContext();
  const [title, setTitle] = useState("");
  const [commentErrText, setCommentErrText] = useState("");
  const userInfo = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //ボタンの連続押下と非同期処理の完了待ちのための待機
  const handleSubmit = async (buttonValue) => {
    let examinationForm = document.getElementById("examinationForm");
    const data = new FormData(examinationForm);
    const approvalComment = data.get("approvalComment");
    setCommentErrText("");
    if (buttonValue === 9 && approvalComment === "") {
      setCommentErrText("差し戻しコメントを入力してください。");
      return;
    }

    let comment = "";
    if (buttonValue === 1) {
      comment = "承認";
    } else if (buttonValue === 2) {
      comment = "否認";
    } else {
      comment = "差し戻し";
    }

    if (window.confirm(comment + "します。よろしいですか？")) {
      const requestData = {
        id: id,
        examinationId: userInfo.value._id,
        applyflg: buttonValue,
        approvalComment: approvalComment,
      };
      try {
        //承認／否認　処理
        await branchUserApi.examination(requestData);
        //承認時、支部に会員を追加
        if (buttonValue === 1) {
          const userData = {
            _id: list.branchId,
            userId: id,
          };
          await branchApi.userAdd(userData);
        }
        // 差し戻しの場合、通知を送信
        if (buttonValue === 9) {
          const email = list.userId ? list.userId.email : "";
          const mailRequestData = {
            applicantName: list.userId.username,
            targetName: list.branchId.branchName,
            targetGenre: "支部参加",
            remandReason: approvalComment,
            url: "/member/branches/joinList",
            // TODO 固定値を後で定数クラス等を作成して管理するか検討
            mailid: "18",
            mailaddress: email,
          };
          await mailApi.returnpost(mailRequestData);
        }

        // サイドメニューの通知バッチ表示判定
        dispatch(setUpdateBadgeFlg(true));

        navigate("/member/branches/joinList", { state: { updated: true } });
      } catch (err) {
        console.error(err);
      }
    }
  };

  async function examination(applyflg) {
    let examinationForm = document.getElementById("examinationForm");
    const data = new FormData(examinationForm);
    const approvalComment = data.get("approvalComment");
    const requestData = {
      id: id,
      examinationId: userInfo.value._id,
      applyflg: applyflg,
      approvalComment: approvalComment,
    };
    try {
      await branchUserApi.examination(requestData);
      //承認時、支部に会員を追加
      if (applyflg === 1) {
        const userData = {
          _id: list.branchId,
          userId: id,
        };
        await branchApi.userAdd(userData);
      }
      // 差し戻しの場合、通知を送信
      if (applyflg === 9) {
        const email = list.userId ? list.userId.email : "";
        const mailRequestData = {
          applicantName: list.userId.username,
          targetName: list.branchId.branchName,
          targetGenre: "支部参加",
          remandReason: approvalComment,
          url: "/member/branches/joinList",
          // TODO 固定値を後で定数クラス等を作成して管理するか検討
          mailid: "18",
          mailaddress: email,
        };
        await mailApi.returnpost(mailRequestData);
      }
    } catch (err) {
      console.error(err);
      return;
    }

    navigate("/member/branches/joinList", { state: { updated: true } });
  }

  useEffect(() => {
    async function fetchBranch() {
      const res = await branchUserApi.getTargetOne({
        params: { _id: id },
      });
      setList(res.branchUser);
      setHistory(res.examinationHistories);

      // タイトルの設定
      let pageTitle = "";
      if (res.branchUser.applyflg === 0) {
        pageTitle = "支部参加申請審査／承認";
      } else {
        pageTitle = "支部参加者詳細";
      }
      setTitle(pageTitle);
    }
    fetchBranch();

    // 権限の設定
    setRole(authUser.roleType);
  }, [id, navigate]);

  const historyColumns = [
    {
      field: "created",
      headerName: "審査日時",
      width: 200,
      renderCell: (params) => {
        return (
          params.value && (
            <div style={{ flexBasis: "200px" }}>{moment(params.value).format("YYYY/MM/DD")}</div>
          )
        );
      },
    },
    {
      field: "examinationId",
      headerName: "審査者",
      width: 200,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "200px" }}>{params.value.username}</div>;
      },
    },
    {
      field: "result",
      headerName: "審査結果",
      width: 200,
    },
    {
      field: "comment",
      headerName: "コメント",
      width: 400,
    },
  ];

  return (
    <Box component="form" id="examinationForm" noValidate>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      </div>

      <div style={{ marginBottom: "20px" }}></div>

      <ul
        style={{
          listStyleType: "none",
          paddingLeft: 0,
          borderBottom: "2px solid #cccccc",
          paddingBottom: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {list.companyId && list.companyId.groupName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                企業名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.companyId.groupName}
              </div>
            </div>
          )}
          {list.userId && list.userId.username && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                申請者名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.userId.username}
              </div>
            </div>
          )}
          {list.branchId && list.branchId.branchName && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                支部名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.branchId.branchName}
              </div>
            </div>
          )}
          {list.activityPurpose && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                申請動機
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.activityPurpose}
              </div>
            </div>
          )}
        </div>
        {role === RoleType.FullMember && list.applyflg === 0 && (
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "20px",
                width: "750px",
              }}
            >
              <TextField
                fullWidth
                id="approvalComment"
                label="コメント"
                margin="normal"
                name="approvalComment"
                multiline
                minRows={3}
                maxRows={5}
                error={commentErrText !== ""}
                helperText={commentErrText}
              />
            </Box>
          </div>
        )}

        {list.branchMaster === userInfo.value._id && history && history.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography sx={itemHeaderStyle}>審査履歴</Typography>
            <div style={{ height: "max-content", width: "100%" }}>
              <DataGrid
                rows={history}
                columns={historyColumns}
                getRowId={(history) => history._id}
                autoHeight
              />
            </div>
          </Box>
        )}
        {role === RoleType.FullMember && !list.applyflg && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "20px",
            }}
          >
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#FF69B4",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                handleSubmit(2);
              }}
            >
              否認
            </LoadingButton>
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#008000",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                handleSubmit(9);
              }}
            >
              差し戻し
            </LoadingButton>
            <LoadingButton
              sx={{
                width: "150px",
                color: "white",
                background: "#1976D2",
              }}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                handleSubmit(1);
              }}
            >
              承認
            </LoadingButton>
          </Box>
        )}
      </ul>
    </Box>
  );
};
