import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import projectApi from "../../../api/projectApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

export const MemberProjectClose = () => {
  const navigate = useNavigate();
  const storedUserInfo = useSelector((state) => state.user);
  const [project, setProject] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    async function fetchProject() {
      const res = await projectApi.getById({
        params: { _id: id },
      });
      setProject(res.project);
    }
    fetchProject();
  }, []);

  // validation
  const { control, formState, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      projectCloseReason: "",
      resultReport: "",
    },
  });

  const validationRules = {
    projectCloseReason: {
      required: "プロジェクトクローズ理由を入力してください。",
    },
    resultReport:{}
  };

  const handleCloseClicks = async (data) => {
    if (window.confirm("プロジェクトをクローズしますか？")) {
      try {
        const res = await projectApi.close({
          _id: id,
          projectCloseReason: data.projectCloseReason,
          resultReport: data.resultReport,
        });
        alert("プロジェクトをクローズしました");
        const url = "/member/projects";
        navigate(url);
      } catch (error) {
        alert("プロジェクトのクローズに失敗しました");
        console.error(error);
      }
    }
  };
  
  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          プロジェクトクローズ
        </Typography>
      </div>
      <div style={{ marginBottom: "20px" }}></div>
      <form noValidate onSubmit={handleSubmit(handleCloseClicks)}>
        <ul
          style={{
            listStyleType: "none",
            paddingLeft: 0,
            borderBottom: "2px solid #cccccc",
            paddingBottom: "10px",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {project.programId && project.programId.programName && (
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "black",
                    border: "1px solid ",
                    padding: "5px",
                    width: "300px",
                  }}
                >
                  プログラム名
                </div>
                <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                  {project.programId.programName}
                </div>
              </div>
            )}
            {project.branchId && project.branchId.branchName && (
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "black",
                    border: "1px solid ",
                    padding: "5px",
                    width: "300px",
                  }}
                >
                  支部名
                </div>
                <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                  {project.branchId.branchName}
                </div>
              </div>
            )}
            {project.projectName && (
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "black",
                    border: "1px solid ",
                    padding: "5px",
                    width: "300px",
                  }}
                >
                  プロジェクト名
                </div>
                <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                  {project.projectName}
                </div>
              </div>
            )}
            {project.projectDesc && (
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "black",
                    border: "1px solid ",
                    padding: "5px",
                    width: "300px",
                  }}
                >
                  プロジェクト概要
                </div>
                <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                  {project.projectDesc}
                </div>
              </div>
            )}
            {project.projectTimeLine && (
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "black",
                    border: "1px solid ",
                    padding: "5px",
                    width: "300px",
                  }}
                >
                  期間
                </div>
                <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                  {moment(project.projectTimeLine[0]).format("YYYY/MM/DD")} -{" "}
                  {moment(project.projectTimeLine[1]).format("YYYY/MM/DD")}
                </div>
              </div>
            )}
            {project.planDocuments && (
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "black",
                    border: "1px solid ",
                    padding: "5px",
                    width: "300px",
                  }}
                >
                  プロジェクト見積書類
                </div>
                <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                  <a
                    href={project.planDocuments}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ width: "400px" }}
                  >
                    プロジェクト見積書類
                  </a>
                </div>
              </div>
            )}
            {project.additionalDocuments && (
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "black",
                    border: "1px solid ",
                    padding: "5px",
                    width: "300px",
                  }}
                >
                  追加書類
                </div>
                <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                  <a
                    href={project.additionalDocuments}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ width: "400px" }}
                  >
                    追加書類
                  </a>
                </div>
              </div>
            )}
            <div style={{ marginBottom: "20px" }}></div>
            <Controller
              name="projectCloseReason"
              control={control}
              rules={validationRules.projectCloseReason}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  id="projectCloseReason"
                  label="プロジェクトクローズ理由"
                  multiline
                  rows={2}
                  type="text"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <div style={{ marginBottom: "10px" }}></div>
            <Controller
              name="resultReport"
              control={control}
              rules={validationRules.resultReport}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  // required
                  id="resultReport"
                  label="成果報告"
                  multiline
                  rows={2}
                  type="text"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              // onClick={handleCloseClick}
              style={{ marginLeft: "10px" }}
              type="submit"
            >
              クローズ
            </Button>
          </div>
        </ul>
      </form>
    </Box>
  );
};
