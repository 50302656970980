import React, { useEffect } from "react";
import eventApi from "../../../api/eventApi";
import attendanceApi from "../../../api/attendanceApi";
import { useParams } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

export const EventDetails = () => {
  const navigate = useNavigate();

  const { slug } = useParams();

  const [eventDetails, setEventDetails] = React.useState({});
  const [attendanceList, setAttendanceList] = React.useState({});
  const [csvData, setCsvData] = React.useState([]);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [isEventHeld, setIsEventHeld] = React.useState(false);
  const [modalprops, setModalProps] = React.useState({});
  const [columns, setColumns] = React.useState([
    {
      field: "_id",
      headerName: "ID",
      width: 70,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={async () => {
                const response = await attendanceApi.getByAttendanceId({ id: params.value });
                const attendance = response.attendance[0];
                setModalProps(attendance);
                setIsOpenModal(true);
              }}
            >
              詳細
            </Button>
          </>
        );
      },
    },
    { field: "username_sei", headerName: "氏名（姓）" },
    { field: "username_mei", headerName: "氏名（名）" },
    { field: "username_sei_kana", headerName: "氏名（カナ）" },
    { field: "username_mei_kana", headerName: "氏名（カナ）" },
    { field: "email", headerName: "メールアドレス", width: 200 },
    { field: "companyName", headerName: "会社名" },
    { field: "devisionName", headerName: "部署" },
    { field: "businessRole", headerName: "役職" },
  ]);

  let csvHeaders = [
    "氏名（姓）",
    "氏名（名）",
    "氏名（カナ）",
    "氏名（カナ）",
    "メールアドレス",
    "会社名",
    "部署",
    "役職",
  ];

  async function fetchEventDetails(eventData) {
    setEventDetails(eventData.event);
    setIsEventHeld(dayjs(eventData.event.startDate).isBefore(dayjs()) ? true : false);
    if (eventData.event.formType === 1) {
      setColumns([
        ...columns,
        { field: "joinNum", headerName: "参加人数" },
        {
          field: "freeTextJoinPeoples",
          headerName: "同行の方のお名前記載欄",
          width: 300,
        },
        { field: "paymentMethod", headerName: "支払方法" },
        { field: "receiptAddress", headerName: "領収書の宛名", width: 300 },
        { field: "reasonOfJoin", headerName: "参加希望理由", width: 300 },
        { field: "others", headerName: "備考", width: 300 },
      ]);
    }
  }

  async function fetchAttendance(eventData) {
    if (eventData.event.formType === 1) {
      csvHeaders.push(
        "参加人数",
        "同行の方のお名前記載欄",
        "支払方法",
        "領収書の宛名",
        "参加希望理由",
        "備考",
      );
    }
    const attendanceResponse = await attendanceApi.getById({ eventId: slug });
    setAttendanceList(attendanceResponse.attendance);
    const csvFormat = attendanceResponse.attendance.map((attendance) => {
      let csvItem = [
        attendance.username_sei,
        attendance.username_mei,
        attendance.username_sei_kana,
        attendance.username_mei_kana,
        attendance.email,
        attendance.companyName,
        attendance.devisionName,
        attendance.businessRole,
      ];
      if (eventData.event.formType === 1) {
        csvItem.push(
          attendance.joinNum,
          attendance.freeTextJoinPeoples,
          attendance.paymentMethod,
          attendance.receiptAddress,
          attendance.reasonOfJoin,
          attendance.others,
        )
      }
      return csvItem
    });
    setCsvData([csvHeaders, ...csvFormat]);
  }

  useEffect(() => {
    (async () => {
      const response = await eventApi.getById({ id: slug });
      fetchEventDetails(response);
      fetchAttendance(response);
    })()
  }, [slug]);

  const handleOpen = () => setIsOpenModal(true);
  const handleClose = () => setIsOpenModal(false);

  const onSubmit = async () => {
    if (eventDetails === {}) {
      // 必要であれば処理を記載
      // Seleniumなどで自動化したなどの速度でなければ発生しない想定
    }
    const params = {
      id: slug,
      eventName: eventDetails.eventName,
      eventDesc: eventDetails.eventDesc,
      startDate: eventDetails.startDate,
      endDate: eventDetails.endDate,
      startAvailableDate: eventDetails.startAvailableDate,
      endAvailableDate: eventDetails.endAvailableDate,
      webinarUrl: eventDetails.webinarUrl,
      formType: eventDetails.formType,
      coverImgUrl: eventDetails.coverImgUrl,
      archiveUrl: eventDetails.archiveUrl,
      isDraft: eventDetails.isDraft,
    };
    navigate("/admin/event_register", { state: { params } });
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={isOpenModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            参加者詳細
          </Typography>
          <hr />
          <Box sx={{ padding: 1, display: "flex", gap: 2 }}>
            <Box>
              <Typography variant="caption" color="primary">
                氏名
              </Typography>
              <Typography variant="body1">
                {modalprops.username_sei} {modalprops.username_mei}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="primary">
                フリガナ
              </Typography>
              <Typography variant="body1">
                {modalprops.username_sei_kana} {modalprops.username_mei_kana}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ padding: 1, display: "flex", gap: 2 }}>
            <Box>
              <Typography variant="caption" color="primary">
                会社名
              </Typography>
              <Typography variant="body1">{modalprops.companyName}</Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="primary">
                部署
              </Typography>
              <Typography variant="body1">{modalprops.devisionName}</Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="primary">
                役職
              </Typography>
              <Typography variant="body1">{modalprops.businessRole}</Typography>
            </Box>
          </Box>

          <Box sx={{ padding: 1 }}>
            <Typography variant="caption" color="primary">
              Email
            </Typography>
            <Typography variant="body1">{modalprops.email}</Typography>
          </Box>

          <Box sx={{ padding: 1 }}>
            <Typography variant="caption" color="primary">
              参加人数
            </Typography>
            <Typography variant="body1">{modalprops.joinNum}</Typography>
          </Box>
          <Box sx={{ padding: 1 }}>
            <Typography variant="caption" color="primary">
              同行者のお名前
            </Typography>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {modalprops.freeTextJoinPeoples}
            </Typography>
          </Box>
          <Box sx={{ padding: 1 }}>
            <Typography variant="caption" color="primary">
              お支払方法
            </Typography>
            <Typography variant="body1"></Typography>
            {modalprops.paymentMethod === 1 ? "事前振込" : modalprops.paymentMethod === 2 ? "当日現金払い" : ""}
          </Box>
          <Box sx={{ padding: 1 }}>
            <Typography variant="caption" color="primary">
              領収書宛名
            </Typography>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {modalprops.receiptAddress}
            </Typography>
          </Box>
          <Box sx={{ padding: 1 }}>
            <Typography variant="caption" color="primary">
              備考
            </Typography>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {modalprops.others}
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="h5">イベント詳細</Typography>
        <div style={{ flexGrow: 1 }}></div>
        <Button variant="contained" onClick={onSubmit} disabled={isEventHeld}>
          編集
        </Button>
      </Grid>
      <Box>
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #ebebeb",
            margin: "20px",
            borderRadius: "10px",
            maxWidth: "100%",
          }}
        >
          <Typography variant="h6">イベント基本情報</Typography>
          <Box>
            <img src={eventDetails.coverImgUrl} style={{ objectFit: "cover", maxWidth: "300px" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography variant="subtitle1">イベント名</Typography>
            <Typography variant="body1">{eventDetails.eventName}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            <Typography variant="subtitle1">イベント概要</Typography>
            <Typography variant="body1">{eventDetails.eventDesc}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "25px",
              padding: "20px",
            }}
          >
            <Box>
              <Typography variant="subtitle1">開催日時</Typography>
              <Typography variant="body1">
                {eventDetails.startDate && moment(eventDetails.startDate).format("YYYY年MM月DD日 HH時mm分")}~
                {eventDetails.endDate && moment(eventDetails.endDate).format("HH時mm分")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1">受付期間</Typography>
              <Typography variant="body1">
                {eventDetails.startAvailableDate && moment(eventDetails.startAvailableDate).format("YYYY年MM月DD日 HH時mm分")}~
                {eventDetails.endAvailableDate && moment(eventDetails.endAvailableDate).format("YYYY年MM月DD日 HH時mm分")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "25px",
              padding: "20px",
            }}
          >
            <Typography variant="subtitle1">受付フォームURL</Typography>
            {!eventDetails.isDraft && (
              <Link to={"/webinar-form/" + eventDetails._id}>
              https://singapore.nccc.earth/webinar-form/{eventDetails._id}
              </Link>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "25px",
              padding: "20px",
            }}
          >
            <Typography variant="subtitle1">Zoom Webinar URL</Typography>
            <a href={eventDetails.webinarUrl}>{eventDetails.webinarUrl}</a>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "25px",
              padding: "20px",
            }}
          >
            <Typography variant="subtitle1">アーカイブURL</Typography>
            <a href={eventDetails.archiveUrl}>{eventDetails.archiveUrl}</a>
          </Box>
        </Box>
      </Box>
      <CSVLink
        data={csvData}
        filename={"attendance.csv"}
        className="btn btn-primary"
        target="_blank"
      >
        出席者CSVをダウロード
      </CSVLink>
      <Box sx={{ height: 400, width: "100%", maxWidth: "80vw" }}>
        <DataGrid
          rows={attendanceList}
          columns={columns}
          getRowId={(row) => row._id}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
          density="comfortable"
          disableExtendRowFullWidth={true}
        />
      </Box>
    </>
  );
};
