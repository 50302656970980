import { Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import ScrollToTopOnMount from "../components/common/utils/ScrollToTopOnMount";

const BoxStyle = {
  margin: "30px 0",
};
export const PrivacyPolicy = () => {
  return (
    <Box sx={{ maxWidth: "1024px", margin: "110px auto", padding: "20px" }}>
      <Box sx={{ padding: "30px", backgroundColor: "primary.main" }}>
        <Typography variant="h4" color="white.main">
          プライバシーポリシー
        </Typography>
      </Box>
      <Box sx={BoxStyle}>
        <Typography variant="h5">個人情報管理について</Typography>
        <Typography variant="body1">
          一般社団法人ナチュラルキャピタルクレジットコンソーシア（以下：当団体）は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
          また、安全対策は定期的に見直し、不備があれば是正を行います。
        </Typography>
      </Box>

      <Box sx={BoxStyle}>
        <Typography variant="h5">個人情報の取り扱いについて</Typography>
        <Typography variant="body1">
          利用者の皆様から取得した個人情報は以下の目的のために利用させていただきます。
          <ul>
            <li>提供するサービスの利便性を向上させるためのシステム運営</li>
            <li>重要なご連絡など、本サービスを運用する上で必要な皆様へのお知らせ</li>
            <li>提供するサービスが利用者の皆様に有用だと思われるメールマガジン、お知らせの配信</li>
            <li>提供するサービスへのご質問、お問い合わせなどに関する回答</li>
            <li>提供するサービス上で、個人を特定できない範囲においての統計情報の作成および利用</li>
            <li>当団体の他サービスへの情報の連携・情報の共有</li>
            <li>提供するサービスの新規開発に必要なデータの解析や分析</li>
          </ul>
        </Typography>
      </Box>

      <Box sx={BoxStyle}>
        <Typography variant="h5">個人情報の第三者への開示・提供の禁止</Typography>
        <Typography variant="body1">
          当団体は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
          <ul>
            <li>お客さまの同意がある場合</li>
            <li>
              お客さまが希望されるサービスを行なうために当団体が業務を委託する業者に対して開示する場合
            </li>
            <li>法令に基づき開示することが必要である場合</li>
          </ul>
        </Typography>
      </Box>

      <Box sx={BoxStyle}>
        <Typography variant="h5">個人情報の訂正削除について</Typography>
        <Typography variant="body1">
          お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
        </Typography>
      </Box>

      <Box sx={BoxStyle}>
        <Typography variant="h5">Cookie、アクセスログについて</Typography>
        <Typography variant="body1">
          当団体では、本サービスへの利便性をあげるために、Cookie・アクセス解析・統計データなどを保存、利用します。これらには個人情報は含まれません。
        </Typography>
      </Box>

      <Box sx={BoxStyle}>
        <Typography variant="h5">法令、規範の遵守と見直し</Typography>
        <Typography variant="body1">
          当団体は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
        </Typography>
      </Box>

      <Box sx={BoxStyle}>
        <Typography variant="h5">プライバシーポリシーに対するお問い合わせ</Typography>
        <Typography variant="body1">
          当団体のプライバシーポリシーに関するお問い合わせは、お問合せフォームからお願いいたします。
        </Typography>
      </Box>
      <ScrollToTopOnMount />
    </Box>
  );
};
