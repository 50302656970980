import React from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { 
  task_setTaskLimitStart,
  task_setMilestoneLimitStart,
  task_setCreatedStart,
  task_setUpdatedStart,
  task_setTaskLimitEnd,
  task_setMilestoneLimitEnd,
  task_setCreatedEnd,
  task_setUpdatedEnd,
} from "../../../redux/features/taskSlice";
import dayjs from "dayjs";

const TermInput = ({termId, category}) => {
  const dispatch = useDispatch();

  // strat Date
  const [startDateVal, setStartDateVal] = React.useState();
  const handleChangeStartDate = (newValue) => {
    setStartDateVal(newValue);
    if (category === "task") {
      dispatch(task_setTaskLimitStart(dayjs(newValue)));
    } else if (category === "milestone") {
      dispatch(task_setMilestoneLimitStart(dayjs(newValue)));
    } else if (category === "created") {
      dispatch(task_setCreatedStart(dayjs(newValue)));
    } else if (category === "updated") {
      dispatch(task_setUpdatedStart(dayjs(newValue)));
    }
  };

  // end Date
  const [endDateVal, setEndDateVal] = React.useState();
  const handleChangeEndDate = (newValue) => {
    setEndDateVal(newValue);
    if (category === "task") {
      dispatch(task_setTaskLimitEnd(dayjs(newValue)));
    } else if (category === "milestone") {
      dispatch(task_setMilestoneLimitEnd(dayjs(newValue)));
    } else if (category === "created") {
      dispatch(task_setCreatedEnd(dayjs(newValue)));
    } else if (category === "updated") {
      dispatch(task_setUpdatedEnd(dayjs(newValue)));
    }
  };

  const styleCss = {
    boxStyle: {
      display: "flex",
      gap: "20px",
      flexDirection: "column",
      margin: "8px 0px",
    },
  };

  return (
    <>
      <Box sx={styleCss.boxStyle}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <Box>
                <DatePicker
                  id={termId + "From"}
                  // label="開始日"
                  value={startDateVal}
                  onChange={handleChangeStartDate}
                  inputFormat="yyyy/MM/dd"
                  mask="____/__/__"
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box sx={{ marginLeft: "1rem", marginRight: "1rem" }}> 〜 </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <Box>
                <DatePicker
                  id={termId + "To"}
                  // label="終了日"
                  value={endDateVal}
                  onChange={handleChangeEndDate}
                  inputFormat="yyyy/MM/dd"
                  mask="____/__/__"
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TermInput;
