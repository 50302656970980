import React from "react";
import { Grid } from "@mui/material";

const StatusChip = ({ status }) => {
  const ColorChip = ({ label, variant, color, bgColor }) => {
    const outlined = variant === "outlined" ? true : false;
    const border = outlined ? `1px solid ${color}` : `1px solid ${bgColor}`;
    const backgroundColor = outlined ? "#ffffff" : bgColor;
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          border: border,
          borderRadius: "20px",
          color: color,
          backgroundColor: backgroundColor,
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        {label}
      </Grid>
    );
  };

  /**
   * ステータス値によって各チップに振り分ける
   * @param {*} status
   * 0: 未送信
   * 1: 送信済み
   * 2: 完了（振込確認済み）
   * @returns
   */
  const chipProvider = (status) => {
    switch (status) {
      case 0:
        return (
          <ColorChip label="未送信" variant="outlined" color="#E53935" />
        );
      case 1:
        return (
          <ColorChip label="送信済み" variant="outlined" color="#0000cd" />
        );
      case 2:
        return (
          <ColorChip label="振込済み" color="#ffffff" bgColor="#388E3C" />
        );
      default:
        return <ColorChip label="エラー" variant="outlined" color="#000000" />;
    }
  };

  return <>{chipProvider(status)}</>;
};

export default StatusChip;
