import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "",
  priority: "",
  taskStatus: "",
  milestoneStatus: "",
  sharedUser: [],
  title: "",
  body: "",
  taskLimit: "",
  taskLimitStart: "",
  taskLimitEnd: "",
  milestoneLimit: "",
  milestoneLimitStart: "",
  milestoneLimitEnd: "",
  createdStart: "",
  createdEnd: "",
  updatedStart: "",
  updatedEnd: "",
  milestoneAssigned: "",
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    task_setCategory: (state, action) => {
      state.category = action.payload;
    },
    task_setPriority: (state, action) => {
      state.priority = action.payload;
    },
    task_setTaskStatus: (state, action) => {
      state.taskStatus = action.payload;
    },
    task_setMilestoneStatus: (state, action) => {
      state.milestoneStatus = action.payload;
    },
    task_setSharedUser: (state, action) => {
      state.sharedUser = action.payload;
    },
    task_setTitle: (state, action) => {
      state.title = action.payload;
    },
    task_setBody: (state, action) => {
      state.body = action.payload;
    },
    task_setTaskLimit: (state, action) => {
      state.taskLimit = action.payload;
    },
    task_setTaskLimitStart: (state, action) => {
      state.taskLimitStart = action.payload;
    },
    task_setTaskLimitEnd: (state, action) => {
      state.taskLimitEnd = action.payload;
    },
    task_setMilestoneLimit: (state, action) => {
      state.milestoneLimit = action.payload;
    },
    task_setMilestoneLimitStart: (state, action) => {
      state.milestoneLimitStart = action.payload;
    },
    task_setMilestoneLimitEnd: (state, action) => {
      state.milestoneLimitEnd = action.payload;
    },
    task_setCreatedStart: (state, action) => {
      state.createdStart = action.payload;
    },
    task_setCreatedEnd: (state, action) => {
      state.createdEnd = action.payload;
    },
    task_setUpdatedStart: (state, action) => {
      state.updatedStart = action.payload;
    },
    task_setUpdatedEnd: (state, action) => {
      state.updatedEnd = action.payload;
    },
    task_setMilestoneAssigned: (state, action) => {
      state.milestoneAssigned = action.payload;
    },
    task_setInitialState: (state, action) => {
      state.category = initialState.category;
      state.priority = initialState.priority;
      state.taskStatus = initialState.taskStatus;
      state.milestoneStatus = initialState.milestoneStatus;
      state.sharedUser = initialState.sharedUser;
      state.title = initialState.title;
      state.body = initialState.body;
      state.taskLimit = initialState.taskLimit;
      state.taskLimitStart = initialState.taskLimitStart;
      state.taskLimitEnd = initialState.taskLimitEnd;
      state.milestoneLimit = initialState.milestoneLimit;
      state.milestoneLimitStart = initialState.milestoneLimitStart;
      state.milestoneLimitEnd = initialState.milestoneLimitEnd;
      state.createdStart = initialState.createdStart;
      state.createdEnd = initialState.createdEnd;
      state.updatedStart = initialState.updatedStart;
      state.updatedEnd = initialState.updatedEnd;
      state.milestoneAssigned = initialState.milestoneAssigned;
    }
  },
});

export const {
  task_setCategory,
  task_setPriority,
  task_setTaskStatus,
  task_setMilestoneStatus,
  task_setSharedUser,
  task_setTitle,
  task_setBody,
  task_setTaskLimit,
  task_setTaskLimitStart,
  task_setTaskLimitEnd,
  task_setMilestoneLimit,
  task_setMilestoneLimitStart,
  task_setMilestoneLimitEnd,
  task_setCreatedStart,
  task_setCreatedEnd,
  task_setUpdatedStart,
  task_setUpdatedEnd,
  task_setMilestoneAssigned,
  task_setInitialState,
} = taskSlice.actions;
export default taskSlice.reducer;
