import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import eventApi from "../../api/eventApi";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

function createData(membersId, membersName, isJoin, numOfJoin, isSendOnlineMtg, surveyStatus) {
  return {
    membersId,
    membersName,
    isJoin,
    numOfJoin,
    isSendOnlineMtg,
    surveyStatus,
  };
}

export const FormAdmin = () => {
  const [open, setOpen] = React.useState(false);
  const [eventRow, setEventRow] = React.useState([]);
  let eventList = [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const groupName = data.get("groupName").trim();
    const email = data.get("email").trim();
    const memberName = data.get("memberName").trim();
    const requestData = {
      groupName,
      memberName,
      email,
      isJoin: "未送信",
      numOfJoin: 0,
      attendancePeoples: [],
      isSendOnlineMtg: "-",
      surveyStatus: "-",
    };
    eventApi.register(requestData);
    handleClose();
  };

  useEffect(() => {
    async function fetchEventList() {
      const res = await eventApi.getList();
      eventList = res.events;
      for (let i = 0; i < eventList.length; i++) {
        setEventRow((arr) => [
          ...arr,
          createData(
            eventList[i]._id,
            eventList[i].groupName,
            eventList[i].isJoin,
            eventList[i].numOfJoin,
            eventList[i].isSendOnlineMtg,
            eventList[i].surveyStatus
          ),
        ]);
      }
    }
    fetchEventList();
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"新規招待者用フォーム発行"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ padding: "20px" }}>
              <TextField margin="normal" fullWidth id="groupName" label="団体名" name="groupName" />
              <TextField
                margin="normal"
                fullWidth
                id="memberName"
                label="招待者名"
                name="memberName"
              />
              <TextField
                fullWidth
                id="email"
                label="メールアドレス"
                margin="normal"
                name="email"
                required
              ></TextField>
              <DialogActions>
                <Button onClick={handleClose}>キャンセル</Button>
                <Button type="submit" autoFocus>
                  発行
                </Button>
              </DialogActions>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Typography variant="h5">
        一般社団法人ナチュラルキャピタルクレジットコンソーシアム設立総会・CO2削減に取り組むコンシャス企業に向けた説明会開催のお知らせ
      </Typography>

      <Box sx={{ margin: "30px 0px" }}>
        <Button variant="contained" onClick={handleClickOpen}>
          新規招待者用フォーム発行
        </Button>
        <Typography variant="h5" sx={{ margin: "20px 0px" }}>
          招待者一覧
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">フォームID</TableCell>
                <TableCell align="left">団体名</TableCell>
                <TableCell align="left">参加</TableCell>
                <TableCell align="left">参加人数</TableCell>
                <TableCell align="left">オンラインURL連携</TableCell>
                <TableCell align="left">アンケート</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventRow.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left">{row.membersId}</TableCell>
                  <TableCell align="left">{row.membersName}</TableCell>
                  <TableCell align="left">
                    {row.isJoin === "local" ? "現地参加" : ""}
                    {row.isJoin === "online" ? "オンライン参加" : ""}
                    {row.isJoin === "non" ? "不参加" : ""}
                  </TableCell>
                  <TableCell align="left">{row.numOfJoin}名</TableCell>
                  <TableCell align="left">{row.isSendOnlineMtg}</TableCell>
                  <TableCell align="left">{row.surveyStatus}</TableCell>
                  <TableCell align="left">
                    <Button variant="contained" href="/admin/form/details">
                      詳細情報
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
