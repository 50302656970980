import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Chip,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import dayjs from "dayjs";

import PriorityDropdown from "../../../../components/member/task/priorityDropdown";
import CategoryDropdown from "../../../../components/common/categoryDropdown";
import userApi from "../../../../api/userApi";
import taskApi from "../../../../api/taskApi";
import projectApi from "../../../../api/projectApi";
import counterApi from "../../../../api/counterApi";
import uploadFile from "../../../../components/common/utils/firebase/uploadFile";
import getMetadata from "../../../../components/common/utils/getMetadata";

import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import {
  task_setCategory,
  task_setPriority,
  task_setInitialState,
  task_setMilestoneAssigned,
  task_setMilestoneLimit,
  task_setSharedUser,
  task_setTaskLimit,
} from "../../../../redux/features/taskSlice";


export const ProjectTaskAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { projectId, projectNo, historyNo } = location.state;
  const taskState = useSelector((state) => state.project);
  const userInfo = useSelector((state) => state.user);
  const [shareMemberOptionList, setShareMemberOptionList] = useState([]);
  const [assignedMemberOptionList, setAssignedMemberOptionList] = useState([]);
  const [assignedMemberId, setAssignedMemberId] = useState("");
  const [projectTask, setTask] = useState([]);
  
  const urlParams = new URLSearchParams(location.search);
  const taskId = urlParams.get("taskId");
  const [boardOfDirectorIds, setBoardOfDirectorIds ] = useState([]);
  const [sheredTaskList, setSheredTaskList ] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);

  // 種別を保持する
  const [category, setCategory] = useState([]);
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    dispatch(task_setCategory(event.target.value));
  };

  // 優先度を保持する
  const [priority, setPriority] = useState([]);
  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
    dispatch(task_setPriority(event.target.value));
  };

  // 共有範囲を保持する
  const [shareMember, setShareMember] = useState([]);
  const handleShareMemberChange = (event) => {
    setShareMember(event.target.value);
    dispatch(task_setSharedUser(event.target.value));
  };

  // タスク期限日を保持する
  const [taskLimit, setTaskLimit] = useState();
  const handleChangeTaskLimit = (newValue) => {
    setTaskLimit(newValue);
    dispatch(task_setTaskLimit(dayjs(newValue)));
  };

  // マイルストーン期限日を保持する
  const [mileLimit, setMileLimit] = useState();
  const handleChangeMileLimit = (newValue) => {
    setMileLimit(newValue);
    dispatch(task_setMilestoneLimit(dayjs(newValue)));
  };

  // マイルストーン担当者を保持する
  const [assignedMember, setAssignedMember] = useState("");
  const handleChangeAssignedMember = (newValue) => {
    setAssignedMember(newValue.target?.value);
    dispatch(task_setMilestoneAssigned(newValue.target?.value));
  };
  // 親タスクを保持する
  const [parentTaskId, setParentTaskId] = useState("");
  const handleChangeParentTask = (newValue) => {
    setParentTaskId(newValue.target?.value);
  };

  const getShareMemberIcon = (group) => {
    if (group) {
      return <GroupIcon sx={{ color: "#a9a9a9", paddingRight: "3px" }} />;
    } else {
      return <PersonIcon sx={{ color: "#a9a9a9", paddingRight: "5px" }} />;
    }
  };

  const dispTitle = (projectTask) => {
    if (projectTask) {
      return "作業の編集";
    } else {
      return "作業の追加";
    }
  };
  const dispButton = (projectTask) => {
    if (projectTask) {
      return "更新";
    } else {
      return "追加";
    }
  };

  // 共有範囲ドロップダウンの選択肢
  const fetchShareMemberOptionList = async () => {
    const optionList = [];
    optionList.push({ key: "boardOfDirectors", group: true, text: "理事会" });
    const response = await userApi.getByMemberships({
      params: {
        memberships: ["boardOfDirectors", "admin"],
      },
    });

    response.userList &&
      response.userList.forEach((user) => {
        optionList.push({
          key: user._id,
          group: false,
          text: user.username,
        });
      });

    setShareMemberOptionList(optionList);
  };

  // 担当者ドロップダウンの選択肢
  const fetchAssignedMemberOptionList = async () => {
    const assignedMemberOptionList = [];
    const response = await userApi.getByMemberships({
      params: {
        memberships: ["boardOfDirectors", "admin"],
      },
    });

    let boardOfDirectorIdList = [];
    response.userList &&
      response.userList.forEach((user) => {
        assignedMemberOptionList.push({
          key: user._id,
          group: false,
          text: user.username,
        });
        // 理事会のIDリストをセット(入力チェックで使用)
        if (user.membership === "boardOfDirectors") {
          boardOfDirectorIdList.push(user._id);
        }
      });

    setAssignedMemberOptionList(assignedMemberOptionList);
    setBoardOfDirectorIds(boardOfDirectorIdList);
  };

  const { control, formState, setValue, watch, handleSubmit, setDefaultValues } = useForm({
    defaultValues: {
      category: "",
      priority: "",
      sharedUser: [],
      title: "",
      body: "",
      taskLimit: "",
      assigned: "",
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // 初期表示
  useEffect(() => {
    fetchShareMemberOptionList();
    fetchAssignedMemberOptionList();
    fetchTask();
    fetchSheredTaskList();
  }, []);

  useEffect(() => {
    setValue("taskLimit", dayjs(taskLimit));
  }, [taskLimit]);

  const fetchTask = async () => {
    const projectTask = await taskApi.getById({
      params: { _id: taskId },
    });
    setTask(projectTask);
    if (projectTask) {
      setTaskLimit(projectTask.taskLimit && dayjs(projectTask.taskLimit));
      setShareMember(projectTask.sharedUser);
      setAssignedMember(projectTask.assigned?._id);
      setValue("title", projectTask.title);
      setValue("body", projectTask.body);
      setValue("priority", projectTask.priority);
      setValue("category", projectTask.category);
      setParentTaskId(projectTask.parentTaskId?._id);
      setFileUrl(projectTask.fileUrl)
    }
  };

  const fetchSheredTaskList = async () => {
    const res = await taskApi.getList({
      params: {
        searchCondition: {
          /**
           * 条件一覧
           * ・自分自身のユーザが指定されているもの
           * ・共有範囲が理事会のもの（理事会が設定されている場合のみ）
           * ・作成者が自分のもの
          */
          $or: userInfo.value.membership === "boardOfDirectors"
            ? [{ sharedUser: userInfo?.value?._id }, { sharedUser: "boardOfDirectors" }, { createdUser: userInfo?.value?._id }]
            : [{ sharedUser: userInfo?.value?._id }, { createdUser: userInfo?.value?._id }]
        }
      },
    });
    let tasks = res.items;
    // 編集の場合、自分自身のタスクは除去する
    if (taskId) {
      tasks = tasks.filter((projectTask) => projectTask._id !== taskId);
    }
    tasks.unshift({
      _id: '',
      title: '設定なし',
    })
    setSheredTaskList(tasks);
  };

  const validationRules = {
    // priority: {
    //   required: "名前を入力してください。",
    //   maxLength: { value: 20, message: "20文字以内で入力してください" },
    // },
    title: {
      required: "件名を入力してください。",
      maxLength: { value: 200, message: "200文字以内で入力してください" },
    },
    body: {
      //   required: "タスクの本文を入力してください",
      maxLength: { value: 1500, message: "1500文字以内で入力してください" },
    },

    sharedUser: {
      validate: () => {
        // 担当者が設定されいる場合
        if (assignedMember) {
          // 共有範囲に理事会が選択された場合
          if (shareMember.includes("boardOfDirectors") && 
            boardOfDirectorIds.includes(assignedMember)) {
            return;
          // 担当者が共有範囲に設定されていない場合
          } else if (shareMember.includes(assignedMember)) {
            return;
          } else {
            return "選択した担当者を共有範囲に設定してください";
          }
        }
      },
    },
  };

  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
  };

  const onSubmit = async (data) => {
    let taskNo = {};
    const parentTask = sheredTaskList.filter((taskList) => taskList._id === parentTaskId);

    const payload = {
      projectNo: projectNo,
      historyNo: historyNo,
      category: taskState.category || projectTask?.category,
      priority: taskState.priority || projectTask?.priority,
      sharedUser: !shareMember.length ? [userInfo?.value?._id] : shareMember,
      title: data.title,
      body: data.body,
      taskLimit: taskState.taskLimit || projectTask?.taskLimit,
      assigned: taskState.assigned || projectTask?.assigned?._id,
      createdUser: userInfo?.value?._id,
      username: userInfo?.value?.username,
      parentTaskId: parentTaskId || null,
      parentTaskTitle: parentTask.length && parentTask[0].title,
    };
    // console.log(payload)

    if (
      projectTask &&
      (projectTask.category !== payload.category ||
        projectTask.priority !== payload.priority ||
        projectTask.title !== payload.title ||
        projectTask.sharedUser !== payload.sharedUser ||
        projectTask.body !== payload.body ||
        projectTask.files ||
        (!projectTask.taskLimit && taskState.taskLimit) ||
        (projectTask.taskLimit && new Date(projectTask.taskLimit).getTime() !== new Date(payload.taskLimit).getTime()) ||
        (!projectTask.assigned && taskState.assigned) ||
        (projectTask.assigned && projectTask.assigned._id !== payload.assigned) ||
        projectTask.parentTaskId !== payload.parentTaskId)
    ) {
      payload.updatedUser = userInfo.value?._id;
      let seqNumber = 0;
      if(!projectTask?.seqNo) {
        taskNo = await counterApi.upsert({ key: projectId, funcName: "projectTask" });
        seqNumber = taskNo.seq;
        payload.seqNo = seqNumber;
      } else {
        seqNumber = projectTask.seqNo
      }
      const docUrls = await Promise.all(files.map((file) => uploadFile(file, ["projectTaskDocuments", seqNumber.toString()])));
      payload.fileUrl = [...fileUrl, ...docUrls];
      taskApi
        .taskUpdate({
          payload: payload,
          projectTask: projectTask,
        })
        .then(async (res) => {
          alert("更新が完了しました。");
          navigate(`/member/projects/${projectId}`);
        })
        .catch((e) => {
          alert("更新に失敗しました。");
          console.error(e);
        });
    } else if (!projectTask) {
      if(!projectTask?.seqNo) {
        taskNo = await counterApi.upsert({ key: projectId, funcName: "projectTask" });
        payload.seqNo = taskNo.seq;
      }
      const docUrls = await Promise.all(files.map((file) => uploadFile(file, ["projectTaskDocuments", taskNo.seq.toString()])));
      payload.fileUrl = [...fileUrl, ...docUrls];
      projectApi
        .addProjectTask(payload)
        .then(async (res) => {
          alert("登録が完了しました。");
          navigate(`/member/projects/${projectId}`);
        })
        .catch((e) => {
          alert("登録に失敗しました。");
          console.error(e);
        });
    } else {
      alert("変更されている項目が存在しません。");
    }

    dispatch(task_setInitialState());
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => {
      const updateFiles = [...prevFiles];
      updateFiles.splice(index, 1);
      return updateFiles;
    });
    // 取り消したファイルを再度選択できるようにする対応
    document.getElementById("attachedDocument").value = "";
  };

  const removeUploadedFile = (index) => {
    fileUrl.splice(index, 1);
    const updateFileUrl = [...fileUrl];
    setFileUrl(updateFileUrl);
  };

  const displayImgOnNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid xs={10} lg={11}>
            <h3>{dispTitle(projectTask)}</h3>
          </Grid>
          <Grid xs={2} lg={1}>
            <Button
              // disabled={!formState.isDirty || !formState.isValid}
              variant="contained"
              // fullWidth
              sx={{ color: "#fff" }}
              type="submit"
              //   onSubmit={handleSubmit(onSubmit)}
            >
              {dispButton(projectTask)}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* <Grid xs={12} lg={1}>
          <Typography mb={1}>優先度</Typography>
        </Grid> */}
          <Grid xs={12} lg={2}>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="category"
                control={control}
                onChange={handleCategoryChange}
                // rules={validationRules.priority}
                render={({ field, fieldState }) => (
                  <CategoryDropdown
                    categoryName="project"
                    categoryLabel="種別"
                    field={field}
                    fieldState={fieldState}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={2}>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="priority"
                control={control}
                onChange={handlePriorityChange}
                // rules={validationRules.priority}
                render={({ field, fieldState }) => (
                  <PriorityDropdown field={field} fieldState={fieldState} />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginBottom: "2rem" }}>
          <Controller
            name="sharedUser"
            control={control}
            rules={validationRules.sharedUser}
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={fieldState.invalid}>
                <InputLabel>共有範囲</InputLabel>
                <Select
                  {...field}
                  multiple
                  input={<OutlinedInput label="共有範囲" />}
                  value={shareMember}
                  MenuProps={MenuProps}
                  onChange={handleShareMemberChange}
                >
                  {shareMemberOptionList.length > 0 &&
                    shareMemberOptionList.map((item) => (
                      <MenuItem key={item.key} value={item.key} style={menuItemStyle}>
                        {getShareMemberIcon(item.group)}
                        {item.text}
                      </MenuItem>
                    ))}
                </Select>
                {fieldState.invalid && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Box>
        {/* <form noValidate> */}
        <Grid container spacing={2}>
          <Grid xs={12} lg={12}>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="title"
                control={control}
                rules={validationRules.title}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="title"
                    label="件名"
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={12}>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="body"
                control={control}
                rules={validationRules.body}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    multiline
                    minRows={25}
                    maxRows={25}
                    // required
                    fullWidth
                    id="body"
                    label="作業の詳細"
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 1, mb: 3 }}>
          <Button
            variant="outlined"
            component="label"
            //  onChange={handleInputFileChange}
            onChange={(e) => {
              setFiles([...files, e.target.files[0]]);
            }}
          >
            添付ファイルアップロード
            <input hidden multiple type="file" id="attachedDocument" />
          </Button>
          <Box sx={{ mt: 1, ml: 2, display: "flex", gap: 2 }}>
            {fileUrl &&
              fileUrl.map((url, index) => (
                // <li key={url._id} style={{ display: "flex", marginBottom: "5px" }}>
                //   <a href={url} target="_blank" rel="noopener noreferrer">
                //     {getMetadata(url, `topicDoc%2F${task.seqNo}%2F`)}
                //   </a>
                // </li>
                <>
                  <Chip
                    icon={<AttachFileIcon />}
                    label={`添付ファイル${index + 1}`}
                    onClick={() => displayImgOnNewTab(url)}
                    onDelete={() => removeUploadedFile(index)}
                  />
                </>
              ))}
          </Box>
          <Box sx={{ mt: 1, ml: 2, display: "flex", gap: 2 }}>
            {files.map(
              (file, index) =>
                file && (
                  <>
                    <Chip
                      icon={<AttachFileIcon />}
                      label={file.name}
                      onClick={() => displayImgOnNewTab(URL.createObjectURL(file))}
                      onDelete={() => removeFile(index)}
                    />
                  </>
                )
            )}
          </Box>
        </Grid>
        <Grid container spacing={1}>
          <Grid xs={12} lg={4}>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="parentTaskId"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel>親作業</InputLabel>
                    <Select
                      {...field}
                      input={<OutlinedInput label="親作業" />}
                      value={parentTaskId}
                      MenuProps={MenuProps}
                      onChange={handleChangeParentTask}
                    >
                      {sheredTaskList.length > 0 &&
                        sheredTaskList.map((item) => (
                          <MenuItem key={item._id} value={item._id} style={menuItemStyle}>
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                    {fieldState.invalid && (
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                <Box sx={{ marginBottom: "2rem" }}>
                  <DatePicker
                    id="taskLimit"
                    label="期限日"
                    value={taskLimit}
                    onChange={handleChangeTaskLimit}
                    inputFormat="yyyy/MM/dd"
                    mask="____/__/__"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="assigned"
                control={control}
                // rules={validationRules.title}
                render={({ field, fieldState }) => (
                  //   <TextField
                  //     {...field}
                  //     fullWidth
                  //     id="assigned"
                  //     label="担当者"
                  //     type="text"
                  //     error={fieldState.invalid}
                  //     helperText={fieldState.error?.message}
                  //   />
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel>担当者</InputLabel>
                    <Select
                      // id="assigned"
                      {...field}
                      input={<OutlinedInput label="担当者" />}
                      value={assignedMember}
                      MenuProps={MenuProps}
                      onChange={handleChangeAssignedMember}
                    >
                      {assignedMemberOptionList.length > 0 &&
                        assignedMemberOptionList.map((item) => (
                          <MenuItem key={item.key} value={item.key} style={menuItemStyle}>
                            {getShareMemberIcon(item.group)}
                            {item.text}
                          </MenuItem>
                        ))}
                    </Select>
                    {fieldState.invalid && (
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
          </Grid>
          <Grid xs={0} lg={8} />
        </Grid>
        <Grid container spacing={1}>
          <Grid xs={10} lg={11} />
          <Grid xs={2} lg={1}>
            <Button
              // disabled={!formState.isDirty || !formState.isValid}
              variant="contained"
              // fullWidth
              sx={{ color: "#fff" }}
              type="submit"
              //   onSubmit={handleSubmit(onSubmit)}
            >
              {dispButton(projectTask)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
