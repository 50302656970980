import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/Global.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useParams } from "react-router-dom";
import projectApi from "../../../api/projectApi";
import workApi from "../../../api/workApi";
import actWorkApi from "../../../api/actWorkApi";
import workDocumentApi from "../../../api/workDocumentApi";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useAuthUserContext, RoleType } from "../../../providers/auth";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

// 初期状態としてすべてのドキュメントを表示するように設定
const initialVisibility = {
  publicScope0: true,
  publicScope1: true,
  publicScope2: true,
  publicScope3: true,
};

export const ProjectProgressDetail = () => {
  const [list, setList] = useState([]);
  let [document, setDocument] = useState([]);
  const [task, setTask] = useState([]);
  const { id } = useParams();
  const storedUserInfo = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(initialVisibility);
  const [auth, setAuth] = useState({
    participants: false, //プロジェクト参加者
    manager: false, //作業担当者
    chief: false, //支部長
  });

  const toggleVisibility = (scope) => {
    setVisibility((prevState) => ({
      ...prevState,
      [scope]: !prevState[scope],
    }));
  };
  const authUser = useAuthUserContext();
  const [role, setRole] = useState("");

  useEffect(() => {
    async function fetchProject() {
      const res = await actWorkApi.getByProgress({
        params: { _id: id },
      });
      setList(res.actWork);
      setDocument(res.documentData);
      if (res.actWork.userId._id == storedUserInfo.value._id) {
        setAuth({ ...auth, manager: true });
      }
      const participants = await projectApi.getAuth({
        params: {
          _id: res.actWork.projectId._id,
          userId: storedUserInfo.value._id,
        },
      });
      if (participants.actWorks && participants.actWorks.length) {
        setAuth({ ...auth, participants: true });
      }
      if (participants.branches[0].branchId.branchMasterId == storedUserInfo.value._id) {
        setAuth({ ...auth, chief: true });
      }
    }
    fetchProject();
    setRole(authUser.roleType);
  }, [id]);

  const { control, formState, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      applyflg: 0,
      documentName: "",
      comment: "",
      publicScope: 0,
    },
  });

  useEffect(() => {
    setValue("applyflg", document.applyflg);
  }, [document.applyflg]);

  useEffect(() => {
    setValue("comment", document.comment);
  }, [document.comment]);

  useEffect(() => {
    setValue("publicScope", document.publicScope);
  }, [document.publicScope]);

  const validationRules = {
    // pack_file: {},
    comment: {
      maxLength: { value: 20, message: "20文字以内で入力してください" },
    },
  };

  const handleSelectChange = (e, index, fieldName) => {
    const { value } = e.target;
    const updatedSelect = [...document];
    updatedSelect[index][fieldName] = value;
    setDocument(updatedSelect);
  };

  const handleDocumentChange = (e, index, fieldName) => {
    const { value } = e.target;
    const updatedDocument = [...document];
    updatedDocument[index][fieldName] = value;
    setDocument(updatedDocument);
  };

  const isWorkDocumentDisplay = (workDocument) => {
    // 管理者、理事、第三者委員会
    if (role === RoleType.Admin || role === RoleType.BoardOfDirectors || role === RoleType.ThirdPartyCommittee) {
      return true;
    // ログインユーザーの作業ドキュメント
    } else if (workDocument.userId === storedUserInfo.value._id) {
      return true;
    // 公開範囲：全体公開
    } else if (workDocument.publicScope === 0 && visibility.publicScope0 && workDocument.applyflg === 1){
      return true;
    // 公開範囲：プロジェクト参加者
    } else if (workDocument.publicScope === 1 && visibility.publicScope1 && auth.participants && workDocument.applyflg === 1){
      return true;
    // 公開範囲：支部長のみ
    } else if (workDocument.publicScope === 2 && visibility.publicScope2 && auth.manager && workDocument.applyflg === 1){
      return true;
    }
    // 該当なし
    return false;
  };

  const onSubmit = async (data) => {
    if (window.confirm("送信します。よろしいですか？")) {
      try {
        for (const doc of document) {
          await workDocumentApi.examination(doc);
        }
        alert("送信しました");
      } catch (error) {
        console.log(error);
        // エラー処理
      }
    }
  };

  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          プロジェクト進捗詳細
        </Typography>
      </div>

      <div style={{ marginBottom: "20px" }}></div>

      <ul
        style={{
          listStyleType: "none",
          paddingLeft: 0,
          borderBottom: "2px solid #cccccc",
          paddingBottom: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {list.projectId && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プロジェクト名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.projectId.projectName}
              </div>
            </div>
          )}
          {list.workId && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                作業名
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.workId.workName}
              </div>
            </div>
          )}
          {list.userId && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                実施会員
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.userId.username}
              </div>
            </div>
          )}
          {list.purpose && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                作業概要
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.purpose}
              </div>
            </div>
          )}
          {list.period && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                期間
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {moment(list.period[0]).format("YYYY/MM/DD")} -{" "}
                {moment(list.period[1]).format("YYYY/MM/DD")}
              </div>
            </div>
          )}
          {list.progress && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                進捗状況
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                {list.progress}％
              </div>
            </div>
          )}
          {list.planDocuments && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                プロジェクト見積書類
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                <a
                  href={list.planDocuments}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "400px" }}
                >
                  プロジェクト見積書類
                </a>
              </div>
            </div>
          )}

          {list.additionalDocuments && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  border: "1px solid ",
                  padding: "5px",
                  width: "300px",
                }}
              >
                追加書類
              </div>
              <div style={{ border: "1px solid ", padding: "5px", width: "400px" }}>
                <a
                  href={list.additionalDocuments}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "400px" }}
                >
                  追加書類
                </a>
              </div>
            </div>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h5" gutterBottom>
            ドキュメント
          </Typography>
        </div>
        {!auth.chief && visibility.publicScope3 && (
          <div style={{ display: "flex", flexDirection: "column", width: "1000px" }}>
            <li style={{ display: "flex", marginBottom: "10px" }}>
              <div style={{ fontWeight: "bold", flexBasis: "200px" }}>ドキュメント</div>
              <div style={{ fontWeight: "bold", flexBasis: "200px" }}>支部長確認状況</div>
              <div style={{ fontWeight: "bold", flexBasis: "500px" }}>備考</div>
            </li>
            {document.map((document, index) => {
              if (isWorkDocumentDisplay(document)) {
                return (
                  <li key={document._id} style={{ display: "flex", marginBottom: "5px" }}>
                    <div style={{ flexBasis: "200px" }}>
                      <a key={index} href={document.url} target="_blank" rel="noopener noreferrer">
                        {document.documentName}
                      </a>
                    </div>
                    <div style={{ flexBasis: "200px" }}>
                      {document.applyflg === 0
                        ? "未審査"
                        : document.applyflg === 1
                        ? "承認"
                        : document.applyflg === 2
                        ? "否認"
                        : ""}
                    </div>
                    <div style={{ flexBasis: "500px" }}>{document.comment}</div>
                  </li>
                );
              } else {
                return null; // 表示しない場合は null を返す
              }
            })}
          </div>
        )}
        {auth.chief && visibility.publicScope3 && (
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
          >
            <div style={{ display: "flex", flexDirection: "column", width: "1050px" }}>
              <li style={{ display: "flex", marginBottom: "00px" }}>
                <div style={{ fontWeight: "bold", flexBasis: "250px" }}>ドキュメント</div>
                <div style={{ fontWeight: "bold", flexBasis: "150px" }}>確認状況</div>
                <div style={{ fontWeight: "bold", flexBasis: "400px" }}>備考</div>
                <div style={{ fontWeight: "bold", flexBasis: "250px" }}>公開範囲</div>
              </li>
              {document.map((document, index) => (
                <li key={document._id} style={{ display: "flex", marginBottom: "0px" }}>
                  <div style={{ border: "1px solid ", padding: "5px", flexBasis: "250px" }}>
                    <a key={index} href={document.url} target="_blank" rel="noopener noreferrer">
                      {document.documentName}
                    </a>
                  </div>
                  <div style={{ flexBasis: "150px" }}>
                    <Controller
                      name="applyflg"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth error={fieldState.invalid}>
                          <InputLabel>確認状況</InputLabel>
                          <Select
                            name={`document[${index}].applyflg`}
                            {...field}
                            input={<OutlinedInput label="確認状況" />}
                            value={document.applyflg}
                            style={{ width: 150 }}
                            defaultValue={document.applyflg}
                            onChange={(e) => handleDocumentChange(e, index, "applyflg")}
                          >
                            <MenuItem value={0}>未審査</MenuItem>
                            <MenuItem value={1}>承認</MenuItem>
                            <MenuItem value={2}>否認</MenuItem>
                          </Select>
                          {fieldState.invalid && (
                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </div>
                  <TextField
                    name={`document[${index}].comment`}
                    rules={validationRules.comment}
                    label="コメント"
                    variant="outlined"
                    fullWidth
                    style={{ width: 400 }}
                    value={document.comment || ""}
                    onChange={(e) => handleDocumentChange(e, index, "comment")}
                  />
                  <div style={{ flexBasis: "250px" }}>
                    <Controller
                      name="publicScope"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth error={fieldState.invalid}>
                          <InputLabel>公開範囲</InputLabel>
                          <Select
                            name={`document[${index}].publicScope`}
                            {...field}
                            input={<OutlinedInput label="公開範囲" />}
                            value={document.publicScope}
                            style={{ width: 250 }}
                            defaultValue={document.publicScope}
                            onChange={(e) => handleDocumentChange(e, index, "publicScope")}
                          >
                            <MenuItem value={0}>全体公開</MenuItem>
                            <MenuItem value={1}>プロジェクト参加者</MenuItem>
                            <MenuItem value={2}>支部長のみ</MenuItem>
                          </Select>
                          {fieldState.invalid && (
                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </div>
                </li>
              ))}
            </div>
            <div
              style={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {document && document.length > 0 &&  (
                <div style={{ width: "200px" }}>
                  <Button
                    // disabled={!formState.isDirty || !formState.isValid}
                    variant="contained"
                    fullWidth
                    sx={{ color: "#fff" }}
                    type="submit"
                  >
                    送信
                  </Button>
                </div>
              )}
            </div>
          </form>
        )}
      </ul>
    </Box>
  );
};
