import React from "react";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import chatApi from "../../api/examinationChatApi";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CircularIndeterminate from "../../components/common/Loading";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const ExaminationChat = (props) => {
  const [chatList, setChatList] = useState([]);
  const MENU_NUMBER = 1000;
  const ary = [...Array(MENU_NUMBER)].map(() => false);
  const [isOpenMenu, setIsOpenMenu] = useState(ary);
  const [mainTopicId, setMainTopicId] = useState("");
  const [beforeSenderId, setBeforeSenderId] = useState("");
  const [isReply, setIsReply] = useState(false);
  const [isUpdateChat, setIsUpadteChat] = useState(false);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const storedUserInfo = useSelector((state) => state.user);
  const id = props.id;
  const isDisabled = props.isDisabled;

  const deleteTopic = async (chatId) => {
    var result = window.confirm("このトピックを削除しますか？※削除後は復元できません");

    if (result) {
      const req = {
        id: chatId,
      };
      const response = await chatApi.deleteById(req);
      if (response === "Delete Success") {
        setIsReply(false);
        setIsUpadteChat(true);
        alert("削除しました。");
      }
    }
  };

  const chatSubmit = async () => {
    setLoading(true);
    if (text === "" || text === null) {
      alert("メッセージを入力してください。");
      return;
    }
    const req = {
      pkId: id,
      senderId: storedUserInfo.value._id,
      senderName: storedUserInfo.value.username || storedUserInfo.value.name,
      mainTopicId: isReply ? mainTopicId : "",
      chatType: isReply ? 1 : 0,
      text: text,
    };
    const res = await chatApi.create(req);
    setText("");
    setLoading(false);
    setIsReply(setIsReply(false));
    alert("コメントを記入しました。");
    setIsUpadteChat(true);
  };

  const toggleMenu = async (num, reply) => {
    if (reply.length >= 1) {
      for await (let item of reply) {
        if (item.readUsers) {
          await chatApi.update({
            id: item._id,
            readUsers: [...item.readUsers, storedUserInfo.value._id],
          });
        } else {
          await chatApi.update({ id: item._id, readUsers: [storedUserInfo.value._id] });
        }
      }
    }
    setIsOpenMenu((prevState) => prevState.map((value, index) => (index === num ? !value : value)));
  };
  const replyChat = (id, senderId) => {
    setMainTopicId(id);
    setBeforeSenderId(senderId);
    setIsReply(isReply ? false : true);
  };

  const getChatList = async () => {
    const chatRes = await chatApi.getByPkId({ pkId: id });
    const chatList = chatRes.chats;
    let resultChatList = [];
    for (let i = 0; i < chatList.length; i++) {
      if (chatList[i].chatType === 0) {
        const list = chatList.reduce((acc, cur) => {
          if (cur.mainTopicId === chatList[i]._id) {
            acc.push(cur);
          }
          return acc;
        }, []);
        const notReadChat = list.filter(
          (item) => item.readUsers.includes(storedUserInfo.value._id) === false
        );
        resultChatList.push({ ...chatList[i], reply: list, notReadChat: notReadChat });
      }
    }
    setChatList(resultChatList);
    setIsUpadteChat(false);
  };

  useEffect(() => {
    getChatList();
  }, []);

  useEffect(() => {
    if (isUpdateChat) {
      getChatList();
    }
  }, [isUpdateChat]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <div>コメント欄</div>
        <Grid container>
          <Grid item xs={9}>
            <Box sx={{ p: 3, maxHeight: "500px", overflowY: "auto" }}>
              {chatList?.map((chat, index) => (
                <>
                  <ListItemButton onClick={() => toggleMenu(index, chat.reply)}>
                    {chat.notReadChat.length >= 1 && (
                      <Badge
                        color="warning"
                        sx={{ mr: 2 }}
                        badgeContent={chat.notReadChat.length}
                      />
                    )}

                    <ListItemText
                      primary={chat.text}
                      secondary={
                        "投稿者:" +
                        chat.senderName +
                        " / 投稿日:" +
                        dayjs(chat.created)?.format("YYYY/MM/DD HH:mm") +
                        " / 返信数:" +
                        chat.reply.length
                      }
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    />
                    <IconButton
                      color="secondary"
                      onClick={() => replyChat(chat._id, chat.senderId)}
                    >
                      <ReplyIcon />
                    </IconButton>
                    {chat.reply.length <= 0 && (
                      <IconButton color="danger" disabled={isDisabled} onClick={() => deleteTopic(chat._id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}

                    {isOpenMenu[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  <Collapse
                    in={isOpenMenu[index]}
                    timeout="auto"
                    unmountOnExit
                    sx={{ pt: 1, pb: 2, pl: 3 }}
                  >
                    {chat.reply.map((reply) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            pr: 3,
                          }}
                        >
                          {chat.notReadChat.includes(storedUserInfo.value._id) && (
                            <>
                              <Badge color="warning" variant="dot" sx={{ mr: 2 }} />
                            </>
                          )}
                          <ListItemText
                            primary={reply.text}
                            secondary={
                              "投稿者:" +
                              reply.senderName +
                              " / 投稿日:" +
                              dayjs(reply.created)?.format("YYYY/MM/DD HH:mm")
                            }
                            sx={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                          />
                        </Box>
                      </>
                    ))}
                  </Collapse>
                </>
              ))}
            </Box>
            <Divider />
            <Grid container style={{ padding: "20px" }}>
              <Grid item xs={11}>
                <TextField
                  id="chatText"
                  label={isReply ? "返信内容の入力" : "メイントピックを作成"}
                  fullWidth
                  multiline
                  rows={4}
                  value={text}
                  disabled={isDisabled}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </Grid>
              <Grid xs={1} align="right">
                {loading ? (
                  <Box sx={{ ml: 5 }}>
                    <CircularIndeterminate />
                  </Box>
                ) : (
                  <IconButton color="secondary" disabled={isDisabled} onClick={chatSubmit}>
                    <SendIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
