import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat, NumericFormatCustom } from "react-number-format";
import React, { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import { jaJP } from "@mui/x-date-pickers";
import { useState } from "react";
import { useSelector } from "react-redux";
import proposalApi from "../../../api/proposalApi";
import authApi from "../../../api/authApi";
import mailApi from "../../../api/mailApi";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { setUpdateBadgeFlg } from "../../../redux/features/sidebarSlice";
import uploadImg from "../../../components/common/utils/uploadImg";

export const ProposalCreate = () => {
  const dispatch = useDispatch();
  const [proposalCategory, setProposalCategory] = React.useState("");
  const [amount, setAmount] = React.useState("0");
  const [num, setNum] = React.useState("0");
  const [contents, setContents] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");
  const navigate = useNavigate();
  const TODAY = dayjs();
  const FORMAT_TODAY = TODAY.format("YYYY年MM月DD日");
  const [selectedApprovalDate, setSelectedApprovalDate] = useState(FORMAT_TODAY);
  const user = useSelector((state) => state.user.value);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const id = urlParams.get("proposalId");
  const [fileUrl, setFileUrl] = useState([]);

  const handleChangeProposalCategory = (event) => {
    setProposalCategory(event.target.value);
  };

  const handleChangeAmount = (event) => {
    handleChangeAmount(amount);
  };
  const JP_TEXT = jaJP.components.MuiLocalizationProvider.defaultProps.localeText;

  const draftProposal = async () => {
    setLoading(true);
    const docUrls = await Promise.all(files.map((file) => uploadImg(file, ["proposaldoc"])));
    const proposal = {
      subject: subject,
      proposalDate: TODAY,
      to: Number(to), // 10: 理事長, 11: 理事会, 12: 事務局
      from: user._id,
      status: 40, // 10: 申請中, 20: 承認済み, 30: 却下, 40: 下書き
      amount: Number(amount),
      num: Number(num),
      contents: contents,
      requiredAprrovalDate: selectedApprovalDate !== FORMAT_TODAY ? selectedApprovalDate : null,
      proposalCategory: Number(proposalCategory),
      fileUrl: [...fileUrl, ...docUrls],
    };

    if (id) {
      proposal._id = id;
      await proposalApi
        .update(proposal)
        .then(async () => {
          setLoading(false);
          alert("一時保存が完了しました。");
          navigate("/member/proposal");
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
          alert("一時保存に失敗しました。");
        });
    } else {
      await proposalApi
        .create(proposal)
        .then(async () => {
          setLoading(false);
          alert("一時保存が完了しました。");
          navigate("/member/proposal");
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
          alert("一時保存に失敗しました。");
        });
    }
  };

  const submitProposal = async () => {
    setLoading(true);
    if (selectedApprovalDate < dayjs()) {
      alert("決裁希望日を明日以降の日付で入力してください。");
      setLoading(false);
      return;
    }
    if (subject === "") {
      alert("件名を入力してください。");
      setLoading(false);
      return;
    }
    if (contents === "") {
      alert("内容または目的を入力してください。");
      setLoading(false);
      return;
    }
    if (proposalCategory === "") {
      alert("区分を選択してください。");
      setLoading(false);
      return;
    }

    const docUrls = await Promise.all(files.map((file) => uploadImg(file, ["proposaldoc"])));
    const proposal = {
      subject: subject,
      proposalDate: TODAY,
      to: Number(to), // 10: 理事長, 11: 理事会, 12: 事務局
      from: user._id,
      status: 10, // 10: 申請中, 20: 承認済み, 30: 却下
      amount: Number(amount),
      num: Number(num),
      contents: contents,
      requiredAprrovalDate: selectedApprovalDate,
      proposalCategory: Number(proposalCategory),
      fileUrl: [...fileUrl, ...docUrls],
    };

    if (id) {
      proposal._id = id;
      await proposalApi
        .update(proposal)
        .then(async (res) => {
          if (res.proposal) {
            sendMailProposal(res.proposal);
            setLoading(false);
            dispatch(setUpdateBadgeFlg(true));
            alert("申請しました。");
            navigate("/member/proposal");
          } else {
            setLoading(false);
            alert("申請に失敗しました。");
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
          alert("申請に失敗しました。");
        });
    } else {
      await proposalApi
        .create(proposal)
        .then(async (res) => {
          if (res.proposal) {
            sendMailProposal(res.proposal);
            setLoading(false);
            dispatch(setUpdateBadgeFlg(true));
            alert("申請しました。");
            navigate("/member/proposal");
          } else {
            setLoading(false);
            alert("申請に失敗しました。");
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
          alert("申請に失敗しました。");
        });
    }
  };

  const sendMailProposal = async (proposal) => {
    const proposalId = proposal._id;
    const requestData = {
      to: Number(to),
      userId: user._id,
      companyId: user.companyId,
      proposalId: proposalId,
      mailid: "19",
    };
    await mailApi.proposal(requestData);
  };

  const fetchData = async () => {
    const proposalRes = await proposalApi.getById({ id });
    if (proposalRes) {
      setProposalCategory(proposalRes.proposalCategory);
      setAmount(proposalRes.amount);
      setNum(proposalRes.num);
      setContents(proposalRes.contents);
      setSubject(proposalRes.subject);
      setTo(proposalRes.to);
      if (proposalRes.requiredAprrovalDate) {
        setSelectedApprovalDate(dayjs(proposalRes.requiredAprrovalDate));
      }
      setFileUrl(proposalRes.fileUrl);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const removeFile = (index) => {
    setFiles((prevFiles) => {
      const updateFiles = [...prevFiles];
      updateFiles.splice(index, 1);
      return updateFiles;
    });
    // 取り消したファイルを再度選択できるようにする対応
    document.getElementById("attachedDocument").value = "";
  };

  const displayImgOnNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box sx={{ ml: 2 }}>
      <h3>稟議書作成</h3>
      <Box sx={{ width: "100%", maxWidth: "800px" }}>
        <Box sx={{ mt: 2, mb: 2, display: "column", gap: 2 }}>
          <Box>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="件名"
                variant="outlined"
                margin="dense"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="起案者"
                variant="outlined"
                margin="dense"
                value={user.username}
                onChange={(e) => setFrom(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="起案日時"
                variant="outlined"
                margin="dense"
                value={FORMAT_TODAY}
                disabled
              />
            </FormControl>
          </Box>

          <Box sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "column", gap: "5px" }}>
            <FormControl fullWidth>
              <InputLabel id="to">宛先</InputLabel>
              <Select
                labelId="to"
                id="toSelect"
                value={to}
                label="区分"
                onChange={(e) => setTo(e.target.value)}
              >
                <MenuItem value={10}>理事長</MenuItem>
                <MenuItem value={11}>理事会</MenuItem>
                <MenuItem value={12}>事務局</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mt: 2, mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="proposalCategory">区分</InputLabel>
              <Select
                labelId="proposalCategory"
                id="proposalCategorySelect"
                value={proposalCategory}
                label="区分"
                onChange={handleChangeProposalCategory}
              >
                <MenuItem value="10">購入</MenuItem>
                <MenuItem value="11">購入(月額費用)</MenuItem>
                <MenuItem value="12">購入(年額費用)</MenuItem>
                <MenuItem value="20">貸与</MenuItem>
                <MenuItem value="30">外注</MenuItem>
                <MenuItem value="40">理事会開催</MenuItem>
                <MenuItem value="50">その他</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              mt: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Typography variant="subtitle" component="div" gutterBottom>
              金額
            </Typography>
            <Box
              sx={{ display: "flex", gap: { xs: 2, md: 1 }, flexFlow: { xs: "column", md: "row" } }}
            >
              <NumericFormat
                value={amount}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                suffix="円"
                allowNegative={true}
                decimalScale={0}
                onValueChange={(values, sourceInfo) => {
                  setAmount(values.value);
                }}
                isAllowed={(values) => {
                  const numValue = Number(values.value);
                  if (isNaN(numValue)) return true;
                  return numValue >= 0 && numValue <= 9999999;
                }}
                customInput={TextField}
                size="small"
                label="単価"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{ width: "auto" }}
              />
              <NumericFormat
                value={num}
                thousandSeparator={true}
                customInput={TextField}
                size="small"
                label="数量"
                variant="outlined"
                isAllowed={(values) => {
                  const numValue = Number(values.value);
                  if (isNaN(numValue)) return true;
                  return numValue >= 0 && numValue <= 9999999;
                }}
                onValueChange={(values, sourceInfo) => {
                  setNum(values.value);
                }}
                InputLabelProps={{ shrink: true }}
                onChange={handleChangeAmount}
              />
              <Box sx={{ mt: 1, ml: 2 }}>
                {amount && num ? (amount * num * 1.1).toLocaleString() : 0} 円(税込み)
              </Box>
            </Box>
          </Box>

          <FormControl fullWidth>
            <TextField
              id="purpose"
              label="内容または目的"
              multiline
              rows={5}
              margin="dense"
              onChange={(e) => setContents(e.target.value)}
              value={contents}
            />
          </FormControl>

          <Box
            sx={{
              mt: 2,
              mb: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={ja}
                localeText={JP_TEXT}
              >
                <DatePicker
                  label="決裁希望日"
                  views={["year", "month", "day"]}
                  onChange={(date) => {
                    setSelectedApprovalDate(date);
                  }}
                  value={selectedApprovalDate}
                  slotProps={{
                    toolbar: { toolbarFormat: "MMMM DD日(ddd)", hidden: false },
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  format="YYYY 年 MM 月 DD日"
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box sx={{ mt: 2, mb: 3 }}>
            <Button
              variant="outlined"
              component="label"
              //  onChange={handleInputFileChange}
              onChange={(e) => {
                setFiles([...files, e.target.files[0]]);
              }}
            >
              添付書類アップロード
              <input hidden multiple type="file" id="attachedDocument" />
            </Button>
            <Box sx={{ marginBottom: "2rem" }}>
              {fileUrl &&
                fileUrl.map((url, index) => (
                  <li key={url._id} style={{ display: "flex", marginBottom: "5px" }}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      アップロード済み添付書類{index + 1}
                    </a>
                  </li>
                ))}
            </Box>
            <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
              {files.map(
                (file, index) =>
                  file && (
                    <>
                      <Chip
                        icon={<AttachFileIcon />}
                        label={file.name}
                        onClick={() => displayImgOnNewTab(URL.createObjectURL(file))}
                        onDelete={() => removeFile(index)}
                      />
                    </>
                  )
              )}
            </Box>
          </Box>

          <LoadingButton variant="contained" onClick={submitProposal} loading={loading}>
            送信する
          </LoadingButton>
          <LoadingButton
            sx={{ ml: 3, color: "white", background: "#1976D2" }}
            color="primary"
            variant="contained"
            onClick={draftProposal}
            loading={loading}
          >
            下書き保存
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
