import axiosClient from "./axiosClient";

const examinationChatApi = {
  create: (params) => axiosClient.post("examinationChat/create", params),
  update: (params) => axiosClient.post("examinationChat/update", params),
  deleteById: (params) => axiosClient.post("examinationChat/deleteById", params),
  getById: (params) => axiosClient.post("examinationChat/getById", params),
  getByPkId: (params) => axiosClient.post("examinationChat/getByPkId", params),
  getList: (params) => axiosClient.get("examinationChat/getList", params),
};

export default examinationChatApi;
