import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Input,
  Select,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tooltip,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { MuiFileInput } from "mui-file-input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import ja from "dayjs/locale/ja";
import dayjs from "dayjs";
import projectApi from "../../../api/projectApi";
import programApi from "../../../api/programApi";
import branchApi from "../../../api/branchApi";
import authApi from "../../../api/authApi";
import mailApi from "../../../api/mailApi";
import counterApi from "../../../api/counterApi";
import categoryApi from "../../../api/categoryApi";

import uploadImg from "../../../components/common/utils/uploadImg";
import CategoryDropdown from "../../../components/common/categoryDropdown";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, option, theme) {
  return {
    fontWeight:
      option.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const CreateValidationReport = () => {
  const navigate = useNavigate();
  const storedUserInfo = useSelector((state) => state.user);
  const [company, setCompany] = useState({});
  const [programs, setPrograms] = useState([]);
  const [branches, setBranches] = useState([]);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const programId = urlParams.get("programId");
  const branchId = urlParams.get("branchId");

  const steps = [
    {
      label: "基本情報"
    },
    {
      label: "プロジェクト概要"
    },
    {
      label: "プロジェクト詳細"
    },
    {
      label: "プロジェクトの適格性"
    },
    {
      label: "環境および社会的配慮"
    },
    {
      label: "方法論の適用"
    },
    {
      label: "妥当性確認意見"
    },
    {
      label: "検証機関の委員リスト"
    },
    {
      label: "商業上の機密情報"
    },
    {
      label: "補足事項等"
    },
  ]

  const fetchprogramList = async () => {
    // 承認済みのプログラム
    const approvedPrograms = await programApi.getApprovedList();
    setPrograms(approvedPrograms);

    // 承認済みの支部
    const approvedBranches = await branchApi.getApprovedList();
    const filterdBranches = approvedBranches.filter(
      (branch) => branch.branchMasterId === storedUserInfo.value._id
    );
    setBranches(filterdBranches);

    const companyData = await authApi.getCompany({
      id: storedUserInfo.value.companyId,
    });
    setCompany(companyData.company);
  };

  const fetchProjectList = async () => {
    const projects = await projectApi.getList({
      params: {
        projectStatus: "20"
      }
    })
    setProjectList(projects.projects)
  }

  const sendApplyMail = async (data, planDocuments, additionalDocuments, projectId) => {
    const user = storedUserInfo.value;
    const program = await programApi.getProgram(data.programId);
    const branch = await branchApi.getBranch(data.branchId);

    const replaceWords = {
      applyUserName: user.username,
      groupName: company.groupName,
      projectName: data.projectName,
      programName: program.programName,
      branchName: branch.branchName,
      projectDesc: data.projectDesc,
      projectStart: new Date(data.startDate),
      projectEnd: new Date(data.endDate),
      planDocuments,
      additionalDocuments,
      url: "/member/projects/apply/" + projectId,
    };

    const reqData = { mailId: "22", replaceWords };
    await mailApi.apply(reqData);
  };

  useEffect(() => {
    fetchprogramList();
    fetchProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // validation
  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      historyNo: 0,
      // 1 基本情報
      projectSelect: "",
      validationCheckWoker: "",
      overview: "",
      // 2.1 目的
      validationIssue: "",
      // 2.2 範囲と基準
      validationRangeAndStandard: "",
      // 2.3 前提条件の妥当性
      validationPremise: "",
      // 2.4 プロジェクトの概要説明
      projectOverview: "",
      // 3.1 方法と基準
      methodAndStandard: "",
      // 3.2 書類審査
      docExamination: "",
      // 3.3 インタビュー
      interview: "",
      // 3.4 現地視察
      inspection: "",
      // 3.5 発見事項の解決
      resolveFindings: "",
      // 4 プロジェクトの詳細
      projectConclusion: "",
      auditHistory: "",
      sectorRange: "",
      projectAfoluCategory: "",
      projectCategory: "",
      projectEligibility: "",
      projectAfoluEligibility: "",
      transferEligibility: "",
      projectDesign: "",
      projectOwnership: "",
      projectStartDate: "",
      projectCreditTerm: "",
      projectScale: "",
      removeAmountPossibility: "",
      techAndMeans: "",
      projectSchedule: "",
      projectPlace: "",
      beforeStartingProject: "",
      legalCompliance: "",
      noDuplicatePublish: "",
      noDuplicateClaim1: "",
      noDuplicateClaim2: "",
      noDuplicateClaim3: "",
      sdContribution: "",
      projectAddInfo: "",
      // 5.1 ステークホルダーの参画と協議
      stakeholderRegulations: "",
      // 5.2 ステークホルダーと環境へのリスク
      envRisk: "",
      // 5.3 人権と公平性の尊重
      fairness: "",
      // 5.4 生態系の健全性
      soundness: "",
      // 5.5	持続可能な開発への貢献
      sdContribution2: "",
      // 5.6	プロジェクトに関連する追加情報
      projectAddInfo2: "",
      // 6.1 方法論のタイトルと参考文献
      program: "",
      methodologyType: "",
      methodologyRefId: "",
      methodologyTitle: "",
      methodologyVersion: "",
      // 6.2 方法論の適用性
      programApplicability: "",
      // 6.3 プロジェクト境界
      baselineOrSource: "",
      gasInclude: "",
      boundaryExplain: "",
      projectBoundaryFile: "",
      // 6.4 ベースライン・シナリオ
      baselineScenario: "",
      // 6.5 追加性
      additionality: "",
      // 6.6 GHG排出削減量と二酸化炭素除去量の定量化
      quantification: "",
      // 6.7 方法論の逸脱
      deviateProgram: "",
      // 6.8 モニタリング計画
      monitoringPlan: "",
      // 6.9 非永続的リスク分析
      nonPersistentRiskAnalysis: "",
      // 7.1 検証の概要
      verificationOverview: "",
      // 7.2 検証の結論
      verificationConclusion: "",
      // 8.2 プロジェクト排出量
      projectEmissions: "",
      // 8.3 リーケージ排出量
      leakageEmissions: "",
      // 7.2 GHG排出削減量と二酸化炭素除去量の推定値
      nonPermanentRiskEvaluation: "",
      updateLtaDoc: "",
      // projectLTA: "",
      // NonUpdateLtaReason: "",
      // expectGhgEmissions: "",
      // NotBelowLtaReason: "",
      estimatedBaselineEmissions: "",
      estimatedProjectEmissions: "",
      estimatedLeakageEmissions: "",
      estimatedReductionCredit: "",
      estimatedRemoveCredit: "",
      estimatedTotalCredit: "",
      // 9.1
      monitoringDataOrParam1: "",
      monitoringDataUnit1: "",
      monitoringDataContents1: "",
      monitoringDataSource1: "",
      monitoringApplicableValue1: "",
      monitoringExplain1: "",
      monitoringPurpose1: "",
      monitoringComment1: "",
      // 9.2
      monitoringDataOrParam2: "",
      monitoringDataUnit2: "",
      monitoringDataContents2: "",
      monitoringDataSource2: "",
      monitoringExplain2: "",
      monitoringFrequency: "",
      monitoringApplicableValue2: "",
      monitoringDevice: "",
      monitoringQaQcProcedure: "",
      monitoringPurpose2: "",
      howToCalc: "",
      monitoringComment2: "",
      // 9 補足事項等(商業上の機密情報)
      secretSection: "",
      secretInfo: "",
      secretJustification: "",
      secretConclusion: "",
      // 10 その他・補足事項等
      otherInfo: "",
    },
  });

  const validationRules = {
    branchId: {
      required: "支部名を選択してください",
    },
    projectName: {
      required: "プロジェクト名を入力してください。",
      maxLength: { value: 20, message: "20文字以内で入力してください" },
    },
    programId: {
      required: "適応するプログラムを選択してください",
    },
    projectDesc: {
      required: "プロジェクト概要を入力してください",
      maxLength: { value: 800, message: "800文字以内で入力してください" },
    },
    startDate: {
      required: "プロジェクト開始日を入力してください",
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!dayjs(formatDate).isValid()) {
          return "日付形式が間違っています";
        }
        // 相関チェック　日付の前後チェック
        const endDate = getValues("endDate");
        if (value > endDate) {
          return "開始日は終了日以前の日付を指定してください。";
        }
      },
    },
    endDate: {
      required: "プロジェクト終了日を入力してください",
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!dayjs(formatDate).isValid()) {
          return "日付形式が間違っています";
        }
      },
    },
    projectPlace: {
      required: "実施場所を入力してください",
    },
    planDocuments: {
      required: "申請書類をアップロードしてください",
      validate: {
        isPdf: (value) => value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
    additionalDocuments: {
      validate: {
        isPdf: (value) =>
          !value || value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => !value || value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
  };

  const onSubmit = async (data) => {
    if (window.confirm("登録します。よろしいですか？")) {
      try {
        const planDocs = data.planDocuments;
        const additionalDocs = data.additionalDocuments;

        const uploadTasks = [uploadImg(planDocs, ["planDocuments"])];
        if (additionalDocs) uploadTasks.push(uploadImg(additionalDocs, ["additionalDocuments"]));
        const [planUrl, additionalUrl] = await Promise.all(uploadTasks);

        const timeline = [new Date(data.startDate), new Date(data.endDate)];
        const program = await programApi.getProgram(data.programId);

        const reqData = {
          historyNo: 0,
          projectId: data.projectSelect,
          validationCheckWoker: data.validationCheckWoker,
          overview: data.overview,
          validationIssue: data.validationIssue,
          validationRangeAndStandard: data.validationRangeAndStandard,
          validationPremise: data.validationPremise,
          projectOverview: data.projectOverview,
          methodAndStandard: data.methodAndStandard,
          docExamination: data.docExamination,
          interview: data.interview,
          inspection: data.inspection,
          resolveFindings: data.resolveFindings,
          projectConclusion: data.projectConclusion,
          auditHistory: data.auditHistory,
          sectorRange: data.sectorRange,
          projectAfoluCategory: data.projectAfoluCategory,
          projectCategory: data.projectCategory,
          projectEligibility: data.projectEligibility,
          projectAfoluEligibility: data.projectAfoluEligibility,
          transferEligibility: data.transferEligibility,
          projectDesign: data.projectDesign,
          projectOwnership: data.projectOwnership,
          projectStartDate: projectStartDate,
          projectCreditTerm: {
            creditTerm: data.creditTerm,
            creditStartDate: creditStartDate,
            creditEndDate: creditEndDate,
          },
          projectScale: data.projectScale,
          removeAmountPossibility: "",
          techAndMeans: data.techAndMeans,
          projectSchedule: data.projectSchedule,
          projectPlace: data.projectPlace,
          beforeStartingProject: data.beforeStartingProject,
          legalCompliance: data.legalCompliance,
          noDuplicatePublish: data.noDuplicatePublish,
          noDuplicateClaim1: data.noDuplicateClaim1,
          noDuplicateClaim2: data.noDuplicateClaim2,
          noDuplicateClaim3: data.noDuplicateClaim3,
          sdContribution: data.sdContribution,
          projectAddInfo: data.projectAddInfo,
          stakeholderRegulations: data.stakeholderRegulations,
          envRisk: data.envRisk,
          fairness: data.fairness,
          soundness: data.soundness,
          sdContribution2: data.sdContribution2,
          projectAddInfo2: data.projectAddInfo2,
          
          startDateRightfulness: data.startDateRightfulness,
          estimatedValue: getEstimatedValue1Rows(),
          estimatedERR: data.estimatedERR,
          totalYears: data.totalYears,
          aveYearlyERR: data.aveYearlyERR,
          programs: getMethodologyRows(),
          programApplicability: data.programApplicability,
          projectBoundary: getProjectBoundaryRows(),
          // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
          projectBoundaryFile: "",
          baselineScenario: data.baselineScenario,
          additionality: data.additionality,
          deviateProgram: data.deviateProgram,
          baselineEmissions: data.baselineEmissions,
          projectEmissions: data.projectEmissions,
          leakageEmissions: data.leakageEmissions,
          nonPermanentRiskEvaluation: data.nonPermanentRiskEvaluation,
          nonPermanentRiskReportCheck: nonPermanentRiskReportCheck,
          projectLTA: data.projectLTA,
          updateLta: {
            check: updateLtaCheck,
            NonUpdateLtaReason: data.NonUpdateLtaReason,
          },
          expectGhgEmissions: data.expectGhgEmissions,
          belowLta: {
            check: ghgCreditCheck,
            NotBelowLtaReason: data.NotBelowLtaReason,
          },
          emissions: {
            detail: getEmissionsDetailRows(),
            total: {
              estimatedBaselineEmissionsTotal: estimatedBaselineEmissionsTotal,
              estimatedProjectEmissionsTotal: estimatedProjectEmissionsTotal,
              estimatedLeakageEmissionsTotal: estimatedLeakageEmissionsTotal,
              estimatedReductionCreditTotal: estimatedReductionCreditTotal,
              estimatedRemoveCreditTotal: estimatedRemoveCreditTotal,
              estimatedTotalCreditTotal: estimatedTotalCreditTotal,
            },
          },
          availableData: getAvailableDataRows(),
          monitoringData: getMonitoringDataRows(),
          monitoringPlan: data.monitoringPlan,
          references: data.references,
          secret: getSecretRows(),
          otherInfo: data.otherInfo,
        }

        let projectNo = {};
        let seqNumber = 0;
        projectNo = await counterApi.upsert({ key: "projectNo" });
        seqNumber = projectNo.seq;
        reqData.projectNo = seqNumber;
        // if(!task?.seqNo) {
        //   projectNo = await counterApi.upsert({ key: "projectNo" });
        //   seqNumber = projectNo.seq;
        //   reqData.seqNo = seqNumber;
        // } else {
        //   seqNumber = task.seqNo
        // }

        projectApi
          .register(reqData)
          .then(async (res) => {
            const projectId = res.project._id;
            // 一旦メールはコメントアウト
            // return sendApplyMail(
            //   data,
            //   reqData.planDocuments,
            //   reqData.additionalDocuments,
            //   projectId
            // );
          })
          .then(() => {
            alert("申請しました");
            navigate("/member/projects");
          })
          .catch((e) => {
            alert("申請に失敗しました。運営にお問合せください。");
            console.error(e);
          });
      } catch (error) {
        alert("申請に失敗しました。運営にお問合せください。");
        console.error(error);
      }
    }
  };

  ///////////////////////
  // file type指定テスト //
  ///////////////////////

  // const ref = useRef(null);
  // const handleChange = (e) => {
  //   if (!e.target.files) return;
  //   const files = Array.from(e.target.files);
  //   setFile(files);
  // };
  // const getFileNames = () =>
  //   file?.reduce(
  //     (fileNames, file) =>
  //       `${fileNames} ${fileNames !== "" ? "," : ""} ${file.name}`,
  //     ""
  //   ) || "";

  ///////////////////////

  // 1 基本情報に関する変数等
  const [projectList, setProjectList] = useState([]);

  // 2.2 セクターの範囲とプロジェクトの種類に関する変数等
  const [projectCategory, setProjectCategory] = useState("");
  const handleCategoryChange = (event) => {
    setProjectCategory(event.target.value);
    // dispatch(task_setCategory(event.target.value));
  };

  // 3.1 プロジェクト・デザインに関する変数等
  const [projectDesignCategory, setProjectDesignCategory] = useState("");
  const handleProjectDesignCategoryChange = (event) => {
    setProjectDesignCategory(event.target.value);
  };

  // 4.4 二重請求と他のGHGプログラムへの参加に関する変数等
  const [duplicatePublishCheck, setDuplicatePublishCheck] = useState(false);
  const handleChangeDuplicatePublishCheck = (event) => {
    if (event.target.value === "1") {
      setDuplicatePublishCheck(true)
    } else {
      setDuplicatePublishCheck(false)
    }
  }

  // 4.5 二重請求、他の形態のクレジット、スコープ3排出量に関する変数等
  const [duplicateClaimCheck, setDuplicateClaimCheck] = useState(false);
  const handleChangeDuplicateClaimCheck = (event) => {
    if (event.target.value === "1") {
      setDuplicateClaimCheck(true)
    } else {
      setDuplicateClaimCheck(false)
    }
  }

  // 6.1 プロジェクト開始日に関する変数等
  const [projectStartDate, setProjectStartDate] = useState();
  const handleChangeProjectStartDate = (newValue) => {
    setProjectStartDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };

  // 6.2 プロジェクトのクレジット期間に関する変数等
  const [creditStartDate, setCreditStartDate] = useState();
  const [creditEndDate, setCreditEndDate] = useState();
  const handleChangeCreditStartDate = (newValue) => {
    setCreditStartDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeCreditEndDate = (newValue) => {
    setCreditEndDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };

  // 6.3 プロジェクト規模とGHG排出削減量または除去量の推定値に関する変数等
  const [estimatedValue1Rows, setEstimatedValue1Rows] = React.useState([]);
  const [creditTimeStart, setCreditTimeStart] = useState();
  const [creditTimeEnd, setCreditTimeEnd] = useState();
  const handleChangeCreditTimeStart = (newValue) => {
    setCreditTimeStart(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeCreditTimeEnd = (newValue) => {
    setCreditTimeEnd(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const apiRefForEstimatedValue1 = useGridApiRef();
  const estimatedValue1Columns = [
    { field: "creditTime", headerName: "プロジェクト期間の暦年", width: "50rem" },
    { field: "estimatedValue1", headerName: "GHG排出削減量または除去量の推定値（tCO2e）", width: "50rem" },
  ];
  const addEstimatedValue1Rows = () => {
    const id = (estimatedValue1Rows.length === 0) ? 1 : Math.max(...estimatedValue1Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const estimatedValue1 = getValues("estimatedValue1")
    const creditTime = `${dayjs(creditTimeStart).format("YYYY/MM/DD")}〜${dayjs(creditTimeEnd).format("YYYY/MM/DD")}`

    const newValue = {
      id: id,
      creditTime: creditTime,
      estimatedValue1: estimatedValue1,
    };
    setEstimatedValue1Rows([...estimatedValue1Rows, newValue]);
    reset({
      estimatedValue1: "",
    })
    setCreditTimeStart(null)
    setCreditTimeEnd(null)
  }

  const delEstimatedValue1Rows = () => {
    const delEstimatedValue1Row = apiRefForEstimatedValue1.current.getSelectedRows();
    if (estimatedValue1Rows.length === 0 || delEstimatedValue1Row.size === 0) return;
    delEstimatedValue1Row.forEach(v => {
      apiRefForEstimatedValue1.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newEstimatedValue1Rows = apiRefForEstimatedValue1.current.getSortedRows();
    if (newEstimatedValue1Rows !== null) {
      setEstimatedValue1Rows(Object.keys(newEstimatedValue1Rows).length === 0 ? [] : [newEstimatedValue1Rows]);
    }
  }

  const getEstimatedValue1Rows = () => {
    if (!apiRefForEstimatedValue1.current || Object.keys(apiRefForEstimatedValue1.current).length === 0) return null
    const allEstimatedValue1Rows = apiRefForEstimatedValue1.current?.getSortedRows();
    if (!allEstimatedValue1Rows) return null
    let estimatedValues = []
    allEstimatedValue1Rows.forEach((row) => {
      let value = {
        creditTimeStart: "",
        creditTimeEnd: "",
        estimatedValue1: "",
      }
      value.creditTimeStart = row.creditTime.split("〜")[0]
      value.creditTimeEnd = row.creditTime.split("〜")[1]
      value.estimatedValue1 = row.estimatedValue1
      estimatedValues.push(value)
    })
    return estimatedValues
  }

  // 7.1 方法論のタイトルと参考文献に関する変数等
  const [methodologyCheck, setMethodologyCheck] = useState(false);
  const [methodologyRows, setMethodologyRows] = React.useState([]);
  const [methodologyTypeCategory, setMethodologyTypeCategory] = useState("");
  const apiRefForMethodology = useGridApiRef();
  const handleChangeMethodologyCheck = () => {
    setMethodologyCheck(!methodologyCheck)
  }

  const handleMethodologyTypeChange = (event) => {
    setMethodologyTypeCategory(event.target.value);
  };

  const methodologyColumns = [
    { field: "methodologyTypeText", headerName: "タイプ" },
    { field: "methodologyRefId", headerName: "参照ID" },
    { field: "methodologyTitle", headerName: "タイトル" },
    { field: "methodologyVersion", headerName: "Version" },
  ];
  const addMethodologyRows = async () => {
    const methodologyTypeCategoryList = await categoryApi.get({
      params: {
        name: "methodologyType"
      }
    })
    const id = (methodologyRows.length === 0) ? 1 : Math.max(...methodologyRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const methodologyTypeValue = methodologyTypeCategoryList?.list[Number(methodologyTypeCategory)-1]?.text
    const methodologyRefIdValue = getValues("methodologyRefId")
    const methodologyTitleValue = getValues("methodologyTitle")
    const methodologyVersionValue = getValues("methodologyVersion")
    
    const newValue = {
      id: id,
      ncccProgramId: null,
      methodologyTypeId: methodologyTypeCategory,
      methodologyTypeText: methodologyTypeValue,
      methodologyRefId: methodologyRefIdValue,
      methodologyTitle: methodologyTitleValue,
      methodologyVersion: methodologyVersionValue,
    };
    setMethodologyRows([...methodologyRows, newValue]);
    reset({
      // methodologyType: "",
      methodologyRefId: "",
      methodologyTitle: "",
      methodologyVersion: "",
    })
    setMethodologyTypeCategory("")
  }

  const addNcccMethodologyRows = async () => {
    const programValue = getValues("program")
    if (!programValue) return
    const methodologyProgram = await programApi.getProgram(programValue)
    const id = (methodologyRows.length === 0) ? 1 : Math.max(...methodologyRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const methodologyRefIdValue = methodologyProgram.programNo
    const methodologyTitleValue = methodologyProgram.programName
    const methodologyVersionValue = methodologyProgram.version
    const newValue = {
      id: id,
      ncccProgramId: methodologyProgram._id,
      methodologyTypeId: "0",
      methodologyTypeText: "NCCC方法論",
      methodologyRefId: methodologyRefIdValue,
      methodologyTitle: methodologyTitleValue,
      methodologyVersion: methodologyVersionValue,
    };
    setMethodologyRows([...methodologyRows, newValue]);
  }

  const delMethodologyRows = () => {
    const delMethodologyRow = apiRefForMethodology.current.getSelectedRows();
    if (methodologyRows.length === 0 || delMethodologyRow.size === 0) return;
    delMethodologyRow.forEach(v => {
      apiRefForMethodology.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMethodologyRows = apiRefForMethodology.current.getSortedRows();
    if (newMethodologyRows !== null) {
      setMethodologyRows(Object.keys(newMethodologyRows).length === 0 ? [] : [newMethodologyRows]);
    }
  }

  const getMethodologyRows = () => {
    if (!apiRefForMethodology.current || Object.keys(apiRefForMethodology.current).length === 0) return null
    const allMethodologyRows = apiRefForMethodology.current?.getSortedRows();
    return allMethodologyRows
  }

  // 7.3 プロジェクト境界に関する変数等
  // const [gasIncludeCheck, setGasIncludeCheck] = useState(false);
  const [projectBoundaryRows, setProjectBoundaryRows] = React.useState([]);
  const [gasCategory, setGasCategory] = useState("");
  const apiRefForProjectBoundary = useGridApiRef();
  // const handleChangeGasIncludeCheck = () => {
  //   setGasIncludeCheck(!gasIncludeCheck)
  // }

  const handleGasCategoryChange = (event) => {
    setGasCategory(event.target.value);
  };

  const projectBoundaryColumns = [
    { field: "baselineOrSource", headerName: "ベースライン／ソース" },
    { field: "gasType", headerName: "Gas" },
    { field: "gasInclude", headerName: "含まれる？" },
    { field: "boundaryExplain", headerName: "正当性／説明" },
  ];
  const addProjectBoundaryRows = async () => {
    const gasCategoryList = await categoryApi.get({
      params: {
        name: "gas"
      }
    })
    const id = (projectBoundaryRows.length === 0) ? 1 : Math.max(...projectBoundaryRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const gasTypeValue = gasCategoryList?.list[Number(gasCategory)-1]?.text
    const baselineOrSourceValue = getValues("baselineOrSource")
    const gasIncludeValue = getValues("gasInclude")
    const boundaryExplainValue = getValues("boundaryExplain")
    
    const newValue = {
      id: id,
      gasType: gasTypeValue,
      baselineOrSource: baselineOrSourceValue,
      gasInclude: gasIncludeValue,
      boundaryExplain: boundaryExplainValue,
    };
    setProjectBoundaryRows([...projectBoundaryRows, newValue]);
    reset({
      // methodologyType: "",
      baselineOrSource: "",
      gasInclude: "",
      boundaryExplain: "",
    })
    // setGasCategory(null)
  }

  const delProjectBoundaryRows = () => {
    const delProjectBoundaryRow = apiRefForProjectBoundary.current.getSelectedRows();
    if (projectBoundaryRows.length === 0 || delProjectBoundaryRow.size === 0) return;
    delProjectBoundaryRow.forEach(v => {
      apiRefForProjectBoundary.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectBoundaryRows = apiRefForProjectBoundary.current.getSortedRows();
    if (newProjectBoundaryRows !== null) {
      setProjectBoundaryRows(Object.keys(newProjectBoundaryRows).length === 0 ? [] : [newProjectBoundaryRows]);
    }
  }

  const getProjectBoundaryRows = () => {
    if (!apiRefForProjectBoundary.current || Object.keys(apiRefForProjectBoundary.current).length === 0) return null
    const allProjectBoundaryRows = apiRefForProjectBoundary.current?.getSortedRows();
    return allProjectBoundaryRows
  } 

  // 8.4 GHG排出削減量と二酸化炭素除去量の推定値に関する変数等
  const [nonPermanentRiskReportCheck, setNonPermanentRiskReportCheck] = useState(true);
  const [updateLtaCheck, setUpdateLtaCheck] = useState(true);
  const [ghgCreditCheck, setGhgCreditCheck] = useState(true);
  const [projectCreditTermRows, setProjectCreditTermRows] = React.useState([]);
  const [projectCreditTermStart, setProjectCreditTermStart] = useState();
  const [projectCreditTermEnd, setProjectCreditTermEnd] = useState();
  const [estimatedBaselineEmissionsTotal, setEstimatedBaselineEmissionsTotal] = useState(0)
  const [estimatedProjectEmissionsTotal, setEstimatedProjectEmissionsTotal] = useState(0)
  const [estimatedLeakageEmissionsTotal, setEstimatedLeakageEmissionsTotal] = useState(0)
  const [estimatedReductionCreditTotal, setEstimatedReductionCreditTotal] = useState(0)
  const [estimatedRemoveCreditTotal, setEstimatedRemoveCreditTotal] = useState(0)
  const [estimatedTotalCreditTotal, setEstimatedTotalCreditTotal] = useState(0)
  const handleChangeNonPermanentRiskReportCheck = (event) => {
    if (event.target.value === "1") {
      setNonPermanentRiskReportCheck(true)
    } else {
      setNonPermanentRiskReportCheck(false)
    }
  }
  const handleChangeUpdateLtaCheck = (event) => {
    if (event.target.value === "1") {
      setUpdateLtaCheck(true)
    } else {
      setUpdateLtaCheck(false)
    }
  }
  const handleChangeGhgCreditCheck = (event) => {
    if (event.target.value === "1") {
      setGhgCreditCheck(true)
    } else {
      setGhgCreditCheck(false)
    }
  }
  const handleChangeProjectCreditTermStart = (newValue) => {
    setProjectCreditTermStart(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeProjectCreditTermEnd = (newValue) => {
    setProjectCreditTermEnd(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const apiRefForProjectCreditTerm = useGridApiRef();
  const projectCreditTermColumns = [
    { field: "projectTerm", headerName: "プロジェクト期間" },
    { field: "estimatedBaselineEmissions", headerName: "推定ベースライン排出量" },
    { field: "estimatedProjectEmissions", headerName: "推定プロジェクト排出量" },
    { field: "estimatedLeakageEmissions", headerName: "推定リーケージ排出量" },
    { field: "estimatedReductionCredit", headerName: "推定削減クレジット" },
    { field: "estimatedRemoveCredit", headerName: "推定除去クレジット" },
    { field: "estimatedTotalCredit", headerName: "推定合計クレジット" },
  ];
  const addProjectCreditTermRows = () => {
    const id = (projectCreditTermRows.length === 0) ? 1 : Math.max(...projectCreditTermRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const estimatedBaselineEmissions = getValues("estimatedBaselineEmissions")
    const estimatedProjectEmissions = getValues("estimatedProjectEmissions")
    const estimatedLeakageEmissions = getValues("estimatedLeakageEmissions")
    const estimatedReductionCredit = getValues("estimatedReductionCredit")
    const estimatedRemoveCredit = getValues("estimatedRemoveCredit")
    const estimatedTotalCredit = getValues("estimatedTotalCredit")
    const projectTerm = `${dayjs(projectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(projectCreditTermEnd).format("YYYY/MM/DD")}`

    const newValue = {
      id: id,
      projectTerm: projectTerm,
      estimatedBaselineEmissions: estimatedBaselineEmissions !== "" ? estimatedBaselineEmissions : "0",
      estimatedProjectEmissions: estimatedProjectEmissions !== "" ? estimatedProjectEmissions : "0",
      estimatedLeakageEmissions: estimatedLeakageEmissions !== "" ? estimatedLeakageEmissions : "0",
      estimatedReductionCredit: estimatedReductionCredit !== "" ? estimatedReductionCredit : "0",
      estimatedRemoveCredit: estimatedRemoveCredit !== "" ? estimatedRemoveCredit : "0",
      estimatedTotalCredit: estimatedTotalCredit !== "" ? estimatedTotalCredit : "0",
    };
    setProjectCreditTermRows([...projectCreditTermRows, newValue]);
    reset({
      estimatedBaselineEmissions: "",
      estimatedProjectEmissions: "",
      estimatedLeakageEmissions: "",
      estimatedReductionCredit: "",
      estimatedRemoveCredit: "",
      estimatedTotalCredit: "",
    })
    setProjectCreditTermStart(null)
    setProjectCreditTermEnd(null)
  }

  const delProjectCreditTermRows = () => {
    const delProjectCreditTermRow = apiRefForProjectCreditTerm.current.getSelectedRows();
    if (projectCreditTermRows.length === 0 || delProjectCreditTermRow.size === 0) return;
    delProjectCreditTermRow.forEach(v => {
      apiRefForProjectCreditTerm.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectCreditTermRows = apiRefForProjectCreditTerm.current.getSortedRows();
    if (newProjectCreditTermRows !== null) {
      setProjectCreditTermRows(Object.keys(newProjectCreditTermRows).length === 0 ? [] : [newProjectCreditTermRows]);
    }
  }

  const totalProjectCreditTermRows = () => {
    const allRows = apiRefForProjectCreditTerm.current.getSortedRows()
    allRows.forEach((row) => {
      setEstimatedBaselineEmissionsTotal(parseFloat(estimatedBaselineEmissionsTotal) + parseFloat(row.estimatedBaselineEmissions))
      setEstimatedProjectEmissionsTotal(parseFloat(estimatedProjectEmissionsTotal) + parseFloat(row.estimatedProjectEmissions))
      setEstimatedLeakageEmissionsTotal(parseFloat(estimatedLeakageEmissionsTotal) + parseFloat(row.estimatedLeakageEmissions))
      setEstimatedReductionCreditTotal(parseFloat(estimatedReductionCreditTotal) + parseFloat(row.estimatedReductionCredit))
      setEstimatedRemoveCreditTotal(parseFloat(estimatedRemoveCreditTotal) + parseFloat(row.estimatedRemoveCredit))
      setEstimatedTotalCreditTotal(parseFloat(estimatedTotalCreditTotal) + parseFloat(row.estimatedTotalCredit))
    })
  }

  useEffect(() => {
    if (projectCreditTermRows.length !== 0) {
      totalProjectCreditTermRows()
    }
  }, [projectCreditTermRows])

  const getEmissionsDetailRows = () => {
    if (!apiRefForProjectCreditTerm.current || Object.keys(apiRefForProjectCreditTerm.current).length === 0) return null
    const allProjectCreditTermRows = apiRefForProjectCreditTerm.current?.getSortedRows();
    if (!allProjectCreditTermRows) return null
    let estimatedDetailValues = []
    allProjectCreditTermRows.forEach((row) => {
      let value = {
        projectCreditTermStart: "",
        projectCreditTermEnd: "",
        estimatedBaselineEmissions: "",
        estimatedProjectEmissions: "",
        estimatedLeakageEmissions: "",
        estimatedReductionCredit: "",
        estimatedRemoveCredit: "",
        estimatedTotalCredit: "",
      }
      value.projectCreditTermStart = row.projectTerm.split("〜")[0]
      value.projectCreditTermEnd = row.projectTerm.split("〜")[1]
      value.estimatedBaselineEmissions = row.estimatedBaselineEmissions
      value.estimatedProjectEmissions = row.estimatedProjectEmissions
      value.estimatedLeakageEmissions = row.estimatedLeakageEmissions
      value.estimatedReductionCredit = row.estimatedReductionCredit
      value.estimatedRemoveCredit = row.estimatedRemoveCredit
      value.estimatedTotalCredit = row.estimatedTotalCredit
      estimatedDetailValues.push(value)
    })
    return estimatedDetailValues
  }

  // 9.1 検証時に利用可能なデータとパラメータ
  const [isOpenMonitoringDialog1, setIsOpenMonitoringDialog1] = React.useState(false);
  const [monitoring1Rows, setMonitoring1Rows] = React.useState([]);
  const handleOpenMonitoringDialog1 = () => {
    setIsOpenMonitoringDialog1(true);
  };
  const handleCloseMonitoringDialog1 = () => {
    setIsOpenMonitoringDialog1(false);
  };

  const apiRefForMonitoring1 = useGridApiRef();

  const monitoring1Columns = [
    { field: "monitoringDataOrParam1", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit1", headerName: "データ単位" },
    { field: "monitoringDataContents1", headerName: "データ内容" },
    { field: "monitoringDataSource1", headerName: "データソース" },
    { field: "monitoringApplicableValue1", headerName: "適用値" },
    { field: "monitoringExplain1", headerName: "データ選択の正当性、または適用した測定方法と手順の説明" },
    { field: "monitoringPurpose1", headerName: "データの目的" },
    { field: "monitoringComment1", headerName: "コメント" },
    // TODO: 詳細や編集ボタンをつける
  ];
  const addMonitoring1Rows = () => {
    const id = (monitoring1Rows.length === 0) ? 1 : Math.max(...monitoring1Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const monitoringDataOrParam1Value = getValues("monitoringDataOrParam1")
    const monitoringDataUnit1Value = getValues("monitoringDataUnit1")
    const monitoringDataContents1Value = getValues("monitoringDataContents1")
    const monitoringDataSource1Value = getValues("monitoringDataSource1")
    const monitoringApplicableValue1Value = getValues("monitoringApplicableValue1")
    const monitoringExplain1Value = getValues("monitoringExplain1")
    const monitoringPurpose1Value = getValues("monitoringPurpose1")
    const monitoringComment1Value = getValues("monitoringComment1")

    const newValue = {
      id: id,
      monitoringDataOrParam1: monitoringDataOrParam1Value,
      monitoringDataUnit1: monitoringDataUnit1Value,
      monitoringDataContents1: monitoringDataContents1Value,
      monitoringDataSource1: monitoringDataSource1Value,
      monitoringApplicableValue1: monitoringApplicableValue1Value,
      monitoringExplain1: monitoringExplain1Value,
      monitoringPurpose1: monitoringPurpose1Value,
      monitoringComment1: monitoringComment1Value,
    };
    setMonitoring1Rows([...monitoring1Rows, newValue]);
    reset({
      monitoringDataOrParam1: "",
      monitoringDataUnit1: "",
      monitoringDataContents1: "",
      monitoringDataSource1: "",
      monitoringApplicableValue1: "",
      monitoringExplain1: "",
      monitoringPurpose1: "",
      monitoringComment1: "",
    })
    handleCloseMonitoringDialog1()
  }

  const delMonitoring1Rows = () => {
    const delMonitoringRow = apiRefForMonitoring1.current.getSelectedRows();
    if (monitoring1Rows.length === 0 || delMonitoringRow.size === 0) return;
    delMonitoringRow.forEach(v => {
      apiRefForMonitoring1.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMonitoring1Rows = apiRefForMonitoring1.current.getSortedRows();
    if (newMonitoring1Rows !== null) {
      setMonitoring1Rows(Object.keys(newMonitoring1Rows).length === 0 ? [] : [newMonitoring1Rows]);
    }
  }

  const getAvailableDataRows = () => {
    if (!apiRefForMonitoring1.current || Object.keys(apiRefForMonitoring1.current).length === 0) return null
    const allMonitoring1Rows = apiRefForMonitoring1.current?.getSortedRows();
    return allMonitoring1Rows
  }

  // 9.2 モニタリングされるデータとパラメータ
  const [isOpenMonitoringDialog2, setIsOpenMonitoringDialog2] = React.useState(false);
  const [monitoring2Rows, setMonitoring2Rows] = React.useState([]);
  const handleOpenMonitoringDialog2 = () => {
    setIsOpenMonitoringDialog2(true);
  };
  const handleCloseMonitoringDialog2 = () => {
    setIsOpenMonitoringDialog2(false);
  };

  const apiRefForMonitoring2 = useGridApiRef();

  const monitoring2Columns = [
    { field: "monitoringDataOrParam2", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit2", headerName: "データ単位" },
    { field: "monitoringDataContents2", headerName: "データ内容" },
    { field: "monitoringDataSource2", headerName: "データソース" },
    { field: "monitoringExplain2", headerName: "適用される測定方法と手順の説明" },
    { field: "monitoringFrequency", headerName: "モニタリング／記録の頻度" },
    { field: "monitoringApplicableValue2", headerName: "適用値" },
    { field: "monitoringDevice", headerName: "モニタリング機器" },
    { field: "monitoringQaQcProcedure", headerName: "適用されるQA/QC手順" },
    { field: "monitoringPurpose2", headerName: "データの目的" },
    { field: "howToCalc", headerName: "計算方法" },
    { field: "monitoringComment2", headerName: "コメント" },
    // TODO: 詳細や編集ボタンをつける
  ];
  const addMonitoring2Rows = () => {
    const id = (monitoring2Rows.length === 0) ? 1 : Math.max(...monitoring2Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const monitoringDataOrParam2Value = getValues("monitoringDataOrParam2")
    const monitoringDataUnit2Value = getValues("monitoringDataUnit2")
    const monitoringDataContents2Value = getValues("monitoringDataContents2")
    const monitoringDataSource2Value = getValues("monitoringDataSource2")
    const monitoringExplain2Value = getValues("monitoringExplain2")
    const monitoringFrequencyValue = getValues("monitoringFrequency")
    const monitoringApplicableValue2Value = getValues("monitoringApplicableValue2")
    const monitoringDeviceValue = getValues("monitoringDevice")
    const monitoringQaQcProcedureValue = getValues("monitoringQaQcProcedure")
    const monitoringPurpose2Value = getValues("monitoringPurpose2")
    const howToCalcValue = getValues("howToCalc")
    const monitoringComment2Value = getValues("monitoringComment2")

    const newValue = {
      id: id,
      monitoringDataOrParam2: monitoringDataOrParam2Value,
      monitoringDataUnit2: monitoringDataUnit2Value,
      monitoringDataContents2: monitoringDataContents2Value,
      monitoringDataSource2: monitoringDataSource2Value,
      monitoringExplain2: monitoringExplain2Value,
      monitoringFrequency: monitoringFrequencyValue,
      monitoringApplicableValue2: monitoringApplicableValue2Value,
      monitoringDevice: monitoringDeviceValue,
      monitoringQaQcProcedure: monitoringQaQcProcedureValue,
      monitoringPurpose2: monitoringPurpose2Value,
      howToCalc: howToCalcValue,
      monitoringComment2: monitoringComment2Value,
    };
    setMonitoring2Rows([...monitoring2Rows, newValue]);
    reset({
      monitoringDataOrParam2: "",
      monitoringDataUnit2: "",
      monitoringDataContents2: "",
      monitoringDataSource2: "",
      monitoringExplain2: "",
      monitoringFrequency: "",
      monitoringApplicableValue2: "",
      monitoringDevice: "",
      monitoringQaQcProcedure: "",
      monitoringPurpose2: "",
      howToCalc: "",
      monitoringComment2: "",
    })
    handleCloseMonitoringDialog2()
  }

  const delMonitoring2Rows = () => {
    const delMonitoring2Row = apiRefForMonitoring2.current.getSelectedRows();
    if (monitoring2Rows.length === 0 || delMonitoring2Row.size === 0) return;
    delMonitoring2Row.forEach(v => {
      apiRefForMonitoring2.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMonitoring2Rows = apiRefForMonitoring2.current.getSortedRows();
    if (newMonitoring2Rows !== null) {
      setMonitoring2Rows(Object.keys(newMonitoring2Rows).length === 0 ? [] : [newMonitoring2Rows]);
    }
  }

  const getMonitoringDataRows = () => {
    if (!apiRefForMonitoring2.current || Object.keys(apiRefForMonitoring2.current).length === 0) return null
    const allMonitoring2Rows = apiRefForMonitoring2.current?.getSortedRows();
    return allMonitoring2Rows
  }

  // 8.1 検証機関の委員リストに関する変数等
  const [committeeListRows, setCommitteeListRows] = React.useState([]);
  const apiRefForCommitteeList = useGridApiRef();
  const committeeListColumns = [
    { field: "committeeName", headerName: "名前" },
    { field: "committeeBelong", headerName: "所属" },
    { field: "committeeApproval", headerName: "検証への同意（する／しない／棄権）" },
  ];
  const addCommitteeListRows = () => {
    const id = (committeeListRows.length === 0) ? 1 : Math.max(...committeeListRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const committeeNameValue = getValues("committeeName")
    const committeeBelongValue = getValues("committeeBelong")
    const committeeApprovalValue = getValues("committeeApproval")
    const newValue = {
      id: id,
      committeeName: committeeNameValue,
      committeeBelong: committeeBelongValue,
      committeeApproval: committeeApprovalValue,
    };
    setCommitteeListRows([...committeeListRows, newValue]);
    reset({
      committeeName: "",
      committeeBelong: "",
      committeeApproval: "",
    })
  }

  const delCommitteeListRows = () => {
    const delCommitteeListRow = apiRefForCommitteeList.current.getSelectedRows();
    if (committeeListRows.length === 0 || delCommitteeListRow.size === 0) return;
    delCommitteeListRow.forEach(v => {
      apiRefForCommitteeList.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newCommitteeListRows = apiRefForCommitteeList.current.getSortedRows();
    if (newCommitteeListRows !== null) {
      setCommitteeListRows(Object.keys(newCommitteeListRows).length === 0 ? [] : [newCommitteeListRows]);
    }
  }

  const getCommitteeListRows = () => {
    if (!apiRefForCommitteeList.current || Object.keys(apiRefForCommitteeList.current).length === 0) return null
    const allCommitteeListRows = apiRefForCommitteeList.current?.getSortedRows();
    return allCommitteeListRows
  }

  // 9.1 商業上の機密情報を表示するテーブルに関する変数等
  const [secretInfoRows, setSecretInfoRows] = React.useState([]);
  const apiRefForSecretInfo = useGridApiRef();
  const secretSectionValue = getValues("secretSection")
  const secretInfoValue = getValues("secretInfo")
  const secretJustificationValue = getValues("secretJustification")
  const secretConclusionValue = getValues("secretConclusion")
  const secretInfoColumns = [
    { field: "section", headerName: "セクション" },
    { field: "info", headerName: "情報" },
    { field: "justification", headerName: "正当化" },
    { field: "secretConclusion", headerName: "評価方法と結論" },
  ];
  const addSecretInfoRows = () => {
    const id = (secretInfoRows.length === 0) ? 1 : Math.max(...secretInfoRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    
    const newValue = {
      id: id,
      section: secretSectionValue,
      info: secretInfoValue,
      justification: secretJustificationValue,
      conclusion: secretConclusionValue,
    };
    setSecretInfoRows([...secretInfoRows, newValue]);
    reset({
      secretSection: "",
      secretInfo: "",
      secretJustification: "",
      secretConclusion: "",
    })
  }

  const delSecretInfoRows = () => {
    const delSecretInfoRow = apiRefForSecretInfo.current.getSelectedRows();
    if (secretInfoRows.length === 0 || delSecretInfoRow.size === 0) return;
    delSecretInfoRow.forEach(v => {
      apiRefForSecretInfo.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newSecretInfoRows = apiRefForSecretInfo.current.getSortedRows();
    if (newSecretInfoRows !== null) {
      setSecretInfoRows(Object.keys(newSecretInfoRows).length === 0 ? [] : [newSecretInfoRows]);
    }
  }

  const getSecretRows = () => {
    if (!apiRefForSecretInfo.current || Object.keys(apiRefForSecretInfo.current).length === 0) return null
    const allSecretInfoRows = apiRefForSecretInfo.current?.getSortedRows();
    return allSecretInfoRows
  }

  // select
  const theme = useTheme();
  const [option, setOption] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleJump = (n) => {
    setActiveStep(n);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // 一時保存時は各入力値の値をチェックせずに保存する
  const tmpSave = async () => {
    try {
      const reqData = {
        historyNo: 0,
        createdCompanyId: company._id,
        createdUserId: storedUserInfo?.value._id,
        projectName: getValues("projectName"),
        projectStatus: "50",
        branchId: getValues("branchId"),
        companyId: company._id,
        projectDesc: getValues("projectDesc"),
        projectCategory: projectCategory,
        auditHistory: getValues("auditHistory"),
        projectDesign: projectDesignCategory,
        actExplain: getValues("actExplain"),
        projectPlace: getValues("projectPlace"),
        proposer: {
          company: company?.groupName,
          staff: storedUserInfo?.value.username,
          role: storedUserInfo?.value.role,
          address: company?.address,
          email: storedUserInfo?.value.email,
          tel: getValues("proposerTel"),
        },
        refOtherCompany: getValues("refOtherCompany"),
        projectOwnership: getValues("projectOwnership"),
        projectEligibility: getValues("projectEligibility"),
        beforeStartingProject: getValues("beforeStartingProject"),
        legalCompliance: getValues("legalCompliance"),
        noDuplicatePublish: {
          check: duplicatePublishCheck,
          evidence: getValues("noDuplicatePublish"),
        },
        noDuplicateClaim: {
          check: duplicateClaimCheck,
          evidence: getValues("noDuplicateClaim"),
        },
        stakeholderRegulations: getValues("stakeholderRegulations"),
        envRisk: getValues("envRisk"),
        fairness: getValues("fairness"),
        soundness: getValues("soundness"),
        sdContribution: getValues("sdContribution"),
        projectAddInfo: getValues("projectAddInfo"),
        projectStartDate: projectStartDate,
        startDateRightfulness: getValues("startDateRightfulness"),
        projectCreditTerm: {
          creditTerm: getValues("creditTerm"),
          creditStartDate: creditStartDate,
          creditEndDate: creditEndDate,
        },
        estimatedValue: getEstimatedValue1Rows(),
        estimatedERR: getValues("estimatedERR"),
        totalYears: getValues("totalYears"),
        aveYearlyERR: getValues("aveYearlyERR"),
        programs: getMethodologyRows(),
        programApplicability: getValues("programApplicability"),
        projectBoundary: getProjectBoundaryRows(),
        // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
        projectBoundaryFile: "",
        baselineScenario: getValues("baselineScenario"),
        additionality: getValues("additionality"),
        deviateProgram: getValues("deviateProgram"),
        baselineEmissions: getValues("baselineEmissions"),
        projectEmissions: getValues("projectEmissions"),
        leakageEmissions: getValues("leakageEmissions"),
        nonPermanentRiskEvaluation: getValues("nonPermanentRiskEvaluation"),
        nonPermanentRiskReportCheck: nonPermanentRiskReportCheck,
        projectLTA: getValues("projectLTA"),
        updateLta: {
          check: updateLtaCheck,
          NonUpdateLtaReason: getValues("NonUpdateLtaReason"),
        },
        expectGhgEmissions: getValues("expectGhgEmissions"),
        belowLta: {
          check: ghgCreditCheck,
          NotBelowLtaReason: getValues("NotBelowLtaReason"),
        },
        emissions: {
          detail: getEmissionsDetailRows(),
          total: {
            estimatedBaselineEmissionsTotal: estimatedBaselineEmissionsTotal,
            estimatedProjectEmissionsTotal: estimatedProjectEmissionsTotal,
            estimatedLeakageEmissionsTotal: estimatedLeakageEmissionsTotal,
            estimatedReductionCreditTotal: estimatedReductionCreditTotal,
            estimatedRemoveCreditTotal: estimatedRemoveCreditTotal,
            estimatedTotalCreditTotal: estimatedTotalCreditTotal,
          },
        },
        availableData: getAvailableDataRows(),
        monitoringData: getMonitoringDataRows(),
        monitoringPlan: getValues("monitoringPlan"),
        references: getValues("references"),
        secret: getSecretRows(),
        otherInfo: getValues("otherInfo"),
      }

      let projectNo = {};
      let seqNumber = 0;
      projectNo = await counterApi.upsert({ key: "projectNo" });
      seqNumber = projectNo.seq;
      reqData.projectNo = seqNumber;
      // if(!task?.seqNo) {
      //   projectNo = await counterApi.upsert({ key: "projectNo" });
      //   seqNumber = projectNo.seq;
      //   reqData.seqNo = seqNumber;
      // } else {
      //   seqNumber = task.seqNo
      // }

      const projectBoundaryFile = getValues("projectBoundaryFile")
      if (projectBoundaryFile || projectBoundaryFile.length !== 0) {
        const uploadTasks = [uploadImg(projectBoundaryFile, ["projectBoundaryFile", seqNumber])];
        const [projectBoundaryFileUrl] = await Promise.all(uploadTasks);
        reqData.projectBoundaryFile = projectBoundaryFileUrl
      }

      projectApi
        .register(reqData)
        .then(async (res) => {
          // const projectId = res.project._id;
          // return sendApplyMail(
          //   data,
          //   reqData.planDocuments,
          //   reqData.additionalDocuments,
          //   projectId
          // );
        })
        .then(() => {
          alert("一時保存しました");
          navigate("/member/projects");
        })
        .catch((e) => {
          alert("一時保存に失敗しました。運営にお問合せください。");
          console.error(e);
        });
      } catch (error) {
        alert("一時保存に失敗しました。運営にお問合せください。");
        console.error(error);
    }
  }

  return (
    <div>
      <h3>妥当性報告書作成</h3>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {/* step1 基本情報 */}
          <Step key={1}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(0)}
            >
              {steps[0].label}
            </StepLabel>
            <StepContent>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  {/* <Typography mb={2}>代表者情報</Typography> */}
                  <Box mb={2}>
                    作成会社名：<strong>{company.groupName}</strong>
                  </Box>
                  {/* <Box sx={{ marginBottom: "2rem" }}> */}
                  <Box mb={2}>
                    作成者名：<strong>{storedUserInfo?.value.username}</strong>
                  </Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Controller
                      name="projectSelect"
                      control={control}
                      rules={validationRules.projectSelect}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth error={fieldState.invalid}>
                          <InputLabel>プロジェクト名</InputLabel>
                          <Select
                            {...field}
                            input={<OutlinedInput label="プロジェクト名" />}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">
                              {projectList.length ? "未選択" : "作成可能なプロジェクトが存在しません"}
                            </MenuItem>
                            {projectList.map((project) => (
                              <MenuItem
                                key={project._id}
                                value={project._id}
                                style={getStyles(project._id, option, theme)}
                              >
                                {project.projectName}
                              </MenuItem>
                            ))}
                          </Select>
                          {fieldState.invalid && (
                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Box>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Typography sx={{ mr: 1 }}>本妥当性確認を実施した個人</Typography>
                    <Controller
                      name="validationCheckWoker"
                      control={control}
                      rules={validationRules.validationCheckWoker}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          id="validationCheckWoker"
                          label="作業実施者"
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Typography sx={{ mr: 1 }}>
                      以下の内容を簡潔にまとめること：<br />
                      ・妥当性確認（バリデーション）とプロジェクトの説明<br />
                      ・妥当性確認の目的と範囲<br />
                      ・妥当性確認に使用した方法と基準<br />
                      ・妥当性確認中に発見された事項の数<br />
                      ・妥当性確認に関連する不確実性<br />
                      ・妥当性確認の結論の要約<br />
                    </Typography>
                    <Controller
                      name="overview"
                      control={control}
                      rules={validationRules.overview}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          id="overview"
                          label="概要"
                          multiline
                          rows={6}
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {0 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step2 はじめに */}
          <Step key={2}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(1)}
            >
              {steps[1].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>2.1 目的</Typography>
              <Typography>バリデーションの目的を説明する。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="validationIssue"
                  control={control}
                  rules={validationRules.validationIssue}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="validationIssue"
                      label="目的"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.2 範囲と基準</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="validationRangeAndStandard"
                  control={control}
                  rules={validationRules.validationRangeAndStandard}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="validationRangeAndStandard"
                      label="範囲と基準"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.3 前提条件の妥当性</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="validationPremise"
                  control={control}
                  rules={validationRules.validationPremise}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="validationPremise"
                      label="前提条件の妥当性"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.4 プロジェクトの概要説明</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectOverview"
                  control={control}
                  rules={validationRules.projectOverview}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectOverview"
                      label="プロジェクトの概要説明"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {1 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step3 検証プロセス */}
          <Step key={3}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(2)}
            >
              {steps[2].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.1 方法と基準</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  バリデーションの実施に使用した、証拠収集計画を含む方法と基準を記述する。エビデンス収集計画をバリデーションの一部として使用する場合は、エビデンス収集手法、重要な前提条件、選択した手法の正当性の説明を含める。<br />
                  主要なマイルストーン（キックオフミーティング、デスクレビュー、現地訪問など）とその日程など、バリデーションのスケジュールを記載する。
                </Typography>
                <Controller
                  name="methodAndStandard"
                  control={control}
                  rules={validationRules.methodAndStandard}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="methodAndStandard"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.2 書類審査</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  バリデーションがどのように監査として実施されたかを記述する。そこでは、プロジェクトの説明とあらゆる裏付け文書をレビューし、クロスチェックを行い、特定され記載された要求事項と比較した。
                </Typography>
                <Controller
                  name="docExamination"
                  control={control}
                  rules={validationRules.docExamination}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="docExamination"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.3 インタビュー</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  インタビューのプロセスを記述し、インタビューを受けた、および／またはプロジェクト説明書および補助書類で提供された以外の情報を提供した担当者を、その役割も含めて特定する。
                </Typography>
                <Controller
                  name="interview"
                  control={control}
                  rules={validationRules.interview}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="interview"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.4 現地視察</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  現地視察の方法と目的を記述すること。その説明には、訪問したすべての施設および／またはプロジェクト地域の詳細、評価したプロジェクトの物理的・組織的側面、およびそのような現地視察が実施された日付を含めること。
                </Typography>
                <Controller
                  name="inspection"
                  control={control}
                  rules={validationRules.inspection}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="inspection"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.5 発見事項の解決</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography sx={{ mr: 1 }}>
                  バリデーション中にバリデーション／検証機関により提起された指摘事項（是正処置、明確化要求、 前倒し処置要求、その他の指摘事項）、および該当する場合は、過去のバリデーションまたは 検証から未解決の前倒し処置要求の解決プロセスを記述する。<br />
                  バリデーション中に提起された是正処置要求、明確化要求、転送処置要求、その他の指摘事項の総件数を記載する。<br />
                  提起された問題、プロジェクト提唱者が行った対応、最終的な結論、その結果生じたプロジェクト文書の変更など、各所見の概要を記載する。<br />
                </Typography>
                <Controller
                  name="resolveFindings"
                  control={control}
                  rules={validationRules.resolveFindings}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="resolveFindings"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {2 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step4 プロジェクトの詳細 */}
          <Step key={4}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(3)}
            >
              {steps[3].label}
            </StepLabel>
            <StepContent>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  プロジェクトの説明が正確、完全であり、読者にプロジェクトの性質を理解させるものであるかどうかについて、全体的な結論を記述する。
                </Typography>
                <Controller
                  name="projectConclusion"
                  control={control}
                  rules={validationRules.projectConclusion}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectConclusion"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mb: 1 }}>
                次に、i) 各項目に関する証拠収集活動、ii) チェックした証拠、iii) プロジェクトが関連する NCCC プログラムの要求事項に適合しているかどうかの評価の結論を記述する。包括的ではないが、補足的なガイダンスをいくつか示す。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>監査履歴</Typography>
                <Controller
                  name="auditHistory"
                  control={control}
                  rules={validationRules.auditHistory}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="auditHistory"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>セクターの範囲</Typography>
                <Controller
                  name="sectorRange"
                  control={control}
                  rules={validationRules.sectorRange}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="sectorRange"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>AFOLUプロジェクトのカテゴリー（該当する場合</Typography>
                <Controller
                  name="projectAfoluCategory"
                  control={control}
                  rules={validationRules.projectAfoluCategory}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectAfoluCategory"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト活動の種類</Typography>
                <Controller
                  name="projectCategory"
                  control={control}
                  rules={validationRules.projectCategory}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectCategory"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>NCCC プログラムに参加するプロジェクトの一般的な適格性</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •	当該プロジェクトがNCCCスタンダードの表2.1に基づき除外されないかどうか。<br />
                  •	プロジェクトが、パイプラインのリストアップ期限、バリデーション/検証機関（VVB）とのオープニングミーティング、およびバリデーション期限に関する要件を満たしているかどうか。<br />
                  •	適用される方法論が NCCC プログラムにおいて適格であるかどうか、また、その方法論にスケー ルおよび/または容量の制限がある場合。<br />
                  •	そのプロジェクトが、そのような制限を超えるような大規模なプロジェクトや活動の断片的な一部でないかどうか。 <br />
                  •	その他関連する資格情報<br />
                </Typography>
                <Controller
                  name="projectEligibility"
                  control={control}
                  rules={validationRules.projectEligibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectEligibility"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>AFOLUプロジェクトの適格性（該当する場合）</Typography>
                <Typography>
                  回答には以下が含まれるべきである：<br />
                  •	選択されたAFOLUプロジェクト・カテゴリーが適切であり、関連するカテゴリー要件がすべて満たされているかどうか。<br />
                  •	下記4.4.4.3項のGHGクレジットを生成するために、原生生態系が転換、開墾、排水、劣化されていないことを証明する十分な証拠があるかどうか。ARR、ALM、WRC、またはAcoGSプロジェクト地域については、以下の4.4.4.3項で、プロジェクト開始日から10年以内に皆伐や転換が行われていないことを証明する十分な証拠があるかどうか。<br />
                </Typography>
                <Controller
                  name="projectAfoluEligibility"
                  control={control}
                  rules={validationRules.projectAfoluEligibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectAfoluEligibility"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>該当する場合、プロジェクトの適格性を移転する</Typography>
                <Controller
                  name="transferEligibility"
                  control={control}
                  rules={validationRules.transferEligibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="transferEligibility"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトの設計</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •	グループ化されたプロジェクトの新規事例の適格性基準が、該当する場合、NCCC プログラムの要件に適合しているかどうか。
                </Typography>
                <Controller
                  name="projectDesign"
                  control={control}
                  rules={validationRules.projectDesign}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectDesign"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトの設計</Typography>
                <Controller
                  name="projectOwnership"
                  control={control}
                  rules={validationRules.projectOwnership}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectOwnership"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト開始日</Typography>
                <Controller
                  name="projectStartDate"
                  control={control}
                  rules={validationRules.projectStartDate}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectStartDate"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトのクレジット期間</Typography>
                <Controller
                  name="projectCreditTerm"
                  control={control}
                  rules={validationRules.projectCreditTerm}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectCreditTerm"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトの規模</Typography>
                <Controller
                  name="projectScale"
                  control={control}
                  rules={validationRules.projectScale}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectScale"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>推定GHG排出削減・除去量の達成可能性</Typography>
                <Controller
                  name="removeAmountPossibility"
                  control={control}
                  rules={validationRules.removeAmountPossibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="removeAmountPossibility"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト活動で実施される技術・手段 </Typography>
                <Controller
                  name="techAndMeans"
                  control={control}
                  rules={validationRules.techAndMeans}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="techAndMeans"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト活動の実施スケジュール </Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •	プロジェクト活動の実施状況の評価
                </Typography>
                <Controller
                  name="projectSchedule"
                  control={control}
                  rules={validationRules.projectSchedule}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectSchedule"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトの場所 </Typography>
                <Controller
                  name="projectPlace"
                  control={control}
                  rules={validationRules.projectPlace}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectPlace"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクト開始前の状況</Typography>
                <Controller
                  name="beforeStartingProject"
                  control={control}
                  rules={validationRules.beforeStartingProject}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="beforeStartingProject"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>適用される法律、法令、その他の規制枠組みへのプロジェクトの適合性</Typography>
                <Controller
                  name="legalCompliance"
                  control={control}
                  rules={validationRules.legalCompliance}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="legalCompliance"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>他のGHGプログラムとの二重算定と参加</Typography>
                <Controller
                  name="noDuplicatePublish"
                  control={control}
                  rules={validationRules.noDuplicatePublish}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicatePublish"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>他のGHGプログラムとの二重算定と参加</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •	プロジェクトが、他のGHGプログラムのもとでのプロジェクト活動による削減や除去のクレジットを受けている、または求めている場合、二重発行がないことの証拠をNCCCスタンダードの要求事項に照らして評価する。<br />
                  •	プロジェクトが、他の温室効果ガスプログラムに登録されているか、または登録を希望しているかについて、必要とされるすべての情報を提供しているかどうか。<br />
                  •	プロジェクトが他のGHGプログラムで却下されたかどうかについて、必要なすべての情報を提供しているかどうか。
                </Typography>
                <Controller
                  name="noDuplicatePublish"
                  control={control}
                  rules={validationRules.noDuplicatePublish}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicatePublish"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>排出量取引制度や拘束力のある排出制限との二重請求の禁止 </Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •	プロジェクトの削減・除去またはプロジェクト活動が、排出量取引プログラムまたは拘束力のある排出量制限にも含まれる場合、NCCCスタンダードの要求事項に照らして、二重請求がないことの証拠を評価する。
                </Typography>
                <Controller
                  name="noDuplicateClaim1"
                  control={control}
                  rules={validationRules.noDuplicateClaim1}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicateClaim1"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>他の環境クレジットとの二重請求の禁止</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •	プロジェクト活動が他の温室効果ガス関連環境クレジット制度に基づくクレジットを要求、受領、または受領を計画している場合、NCCCスタンダードの要求事項に照らして、二重クレームがないことの証拠を評価する。
                </Typography>
                <Controller
                  name="noDuplicateClaim2"
                  control={control}
                  rules={validationRules.noDuplicateClaim2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicateClaim2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>サプライチェーン（スコープ3）排出量の二重計上</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •	プロジェクト提案者または正式な代理人が、プロジェクト活動によって排出量フットプリントが変化する製品の買い手または売り手であるかどうか。<br />
                  •	もしそうであれば、プロジェクト提案者またはその代理人が、NCCCプログラムの要求事項に従い、ウェブサイト上に公式声明を掲載しているかどうか。
                </Typography>
                <Controller
                  name="noDuplicateClaim3"
                  control={control}
                  rules={validationRules.noDuplicateClaim3}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="noDuplicateClaim3"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>持続可能な開発への貢献</Typography>
                <Controller
                  name="sdContribution"
                  control={control}
                  rules={validationRules.sdContribution}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="sdContribution"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>プロジェクトに関連する追加情報</Typography>
                <Typography>
                  回答には以下が含まれるべきである： <br />
                  •	リーケージ管理計画またはリーケージおよびリスク軽減措置の実施の適切性の評価（該当する場合）。<br />
                  •	プロジェクト文書の一般公開版から除外された商業上機微な情報が、除外されうる情報に関するNCCCプログラムの要求事項に適合しているかどうかの評価。付録 1 に詳細を記載すること。<br />
                  •	プロジェクトの適格性、削減量または除去量、あるいはプロジェクトの削減量または除去量の定量化に関係する可能性のある追加的な関連情報の評価。<br />
                </Typography>
                <Controller
                  name="projectAddInfo"
                  control={control}
                  rules={validationRules.projectAddInfo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectAddInfo"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {3 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step5 環境および社会的配慮 */}
          <Step key={5}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(4)}
            >
              {steps[4].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.1 ステークホルダーの参画と協議</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="stakeholderRegulations"
                  control={control}
                  rules={validationRules.stakeholderRegulations}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="stakeholderRegulations"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.2 ステークホルダーと環境へのリスク</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="envRisk"
                  control={control}
                  rules={validationRules.envRisk}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="envRisk"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.3 人権と公平性の尊重</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="fairness"
                  control={control}
                  rules={validationRules.fairness}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="fairness"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.4 生態系の健全性</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>特定されたリスク</Typography>
                <Controller
                  name="soundness"
                  control={control}
                  rules={validationRules.soundness}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundness"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.5 持続可能な開発への貢献</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="sdContribution2"
                  control={control}
                  rules={validationRules.sdContribution2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="sdContribution2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.6 プロジェクトに関連する追加情報</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo2"
                  control={control}
                  rules={validationRules.projectAddInfo2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo2"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {4 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step6 方法論の適用 */}
          <Step key={6}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(5)}
            >
              {steps[5].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.1 方法論のタイトルと参考文献</Typography>
              <Typography>
                適用された方法論とツールのタイトルと参考文献を記入すること。方法論とツール、およびプロジェクトが適用したそれらの特定のバージョンは、検証の時点で有効でなければならないことに留意すること。
              </Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={methodologyCheck} onChange={handleChangeMethodologyCheck} />
                    }
                    label="NCCCの方法論以外を使用する"
                  />
                </FormGroup>
              </FormControl>
              {
                methodologyCheck
                ? <Box sx={{ mt: 2, mb: 3 }}>
                  <Grid container spacing={4}>
                    <Grid xs={12} lg={2}>
                      <Box>
                        <Controller
                          name="methodologyType"
                          control={control}
                          // rules={validationRules.methodologyType}
                          render={({ field, fieldState }) => (
                            <CategoryDropdown
                              categoryName="methodologyType"
                              categoryLabel="タイプ"
                              field={field}
                              fieldState={fieldState}
                              onChange={handleMethodologyTypeChange}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={4}>
                      <Box>
                        <Controller
                          name="methodologyRefId"
                          control={control}
                          rules={validationRules.methodologyRefId}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="参照ID"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={7}>
                      <Box>
                        <Controller
                          name="methodologyTitle"
                          control={control}
                          rules={validationRules.methodologyTitle}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="タイトル"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={2}>
                      <Box>
                        <Controller
                          name="methodologyVersion"
                          control={control}
                          rules={validationRules.methodologyVersion}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="Version"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={1}>
                      <Box>
                        <Button variant="contained" onClick={addMethodologyRows}>追加</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                : <Box sx={{ marginBottom: "2rem" }}>
                  <Grid container>
                    <Grid xs={12} lg={11}>
                      <Controller
                        name="program"
                        control={control}
                        // rules={validationRules.program}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth error={fieldState.invalid}>
                            <InputLabel>適応するプログラム*</InputLabel>
                            <Select
                              {...field}
                              input={<OutlinedInput label="適応するプログラム" />}
                              MenuProps={MenuProps}
                            >
                              {programs.map((item) => (
                                <MenuItem
                                  key={item._id}
                                  value={item._id}
                                  style={getStyles(item._id, option, theme)}
                                >
                                  {item.programName}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState.invalid && (
                              <FormHelperText>{fieldState.error?.message}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid xs={12} lg={1}>
                      <Box sx={{ ml: 2 }}>
                        <Button variant="contained" onClick={addNcccMethodologyRows}>追加</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={delMethodologyRows}>削除</Button>
                  </Box>
                <DataGrid
                  apiRef={apiRefForMethodology}
                  columns={methodologyColumns}
                  rows={methodologyRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.2 方法論の適用性</Typography>
              <Typography>
                適用された方法論の適用可能条件それぞれについて、適用可能条件へのプロジェクトの適合性を評価するために講じた手順を記述する。各適用可能性条件に関する結論を示す。<br />
                同様に、適用された方法論が、プロジェクトにいくつかのツールまたはモジュールから選択することを提供する場合、適切なツールまたはモジュールが選択されたことを評価するために取られた手順を記述する。選択した各ツールまたはモジュールに関する結論を述べなさい。 
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="programApplicability"
                  control={control}
                  rules={validationRules.programApplicability}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="programApplicability"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.3 プロジェクト境界</Typography>
              <Typography>
                プロジェクトの境界を特定し、それを検証するためにとった手順を記述する。評価した文書（例：試運転報告書）及び現場視察中に行った観察の詳細を含める。<br />
                それぞれの温室効果ガスの発生源、吸収源、貯水池について、適用された方法論に従って正しく選択されていることを評価するために、以下の表で実施した手順を記述する。関連する排出源、吸収源、貯留層が選択されていないかどうかを評価するために行った手順を記述する。<br />
                プロジェクトのバウンダリーおよび選択された発生源、吸収源、貯水池が、プロジェクトにとっ て正当であるかどうかについて、全体的な結論を示す。<br />
              {/* 必要に応じて行を追加する。 */}
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="baselineOrSource"
                        control={control}
                        rules={validationRules.baselineOrSource}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="ベースライン／ソース"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="gas"
                        control={control}
                        // rules={validationRules.gas}
                        render={({ field, fieldState }) => (
                          <CategoryDropdown
                            categoryName="gas"
                            categoryLabel="Gas"
                            field={field}
                            fieldState={fieldState}
                            onChange={handleGasCategoryChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="gasInclude"
                        control={control}
                        rules={validationRules.gasInclude}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="含まれる？"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                    {/* <Box>
                      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox checked={methodologyCheck} onChange={handleChangeMethodologyCheck} />
                            }
                            label="含まれる?"
                          />
                        </FormGroup>
                      </FormControl>
                    </Box> */}
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="boundaryExplain"
                        control={control}
                        rules={validationRules.boundaryExplain}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="正当性／説明"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addProjectBoundaryRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delProjectBoundaryRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForProjectBoundary}
                  columns={projectBoundaryColumns}
                  rows={projectBoundaryRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.4 ベースライン・シナリオ</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  プロジェクトのために決定されたベースラインシナリオを特定し、それを検証するために取られた手順を記述する：<br />
                  •	ベースラインシナリオの特定に使用された仮定とデータが適切に正当化され、証拠に裏付けられ、合理的であると判断できる。<br />
                  •	ベースラインシナリオを決定する際に使用された文書化された証拠が適切であり、プロジェクトの説明の中で正しく引用され、解釈されている。<br />
                  •	関連する国および／またはセクターの政策と状況が考慮され、プロジェクトの説明に記載されている。<br />
                  •	ベースラインシナリオを特定する手続きは正しく行われ、特定されたシナリオは、プロジェクトがなかった場合に発生したであろうことを合理的に表している。<br />
                  ベースラインシナリオの特定に使用したデータをクロスチェックするために講じた手順の詳細（情報源を含む）を記載する。<br />
                  特定したベースラインシナリオが正当化されるかどうかについて、全体的な結論を示すこと。<br />
                </Typography>
                <Controller
                  name="baselineScenario"
                  control={control}
                  rules={validationRules.baselineScenario}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="baselineScenario"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.5 追加性</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  適用された方法論が追加性を実証するために使用した方法を特定する。方法論または参照ツールに記載されている）追加性の手順が正確かつ正確に行われていることを検証するために取られた手順を詳細に記述する。<br />
                  プロジェクトメソッドについては、（該当する場合）最低限、以下の評価方法に関する情報を含める：<br />
                  •	規制余剰要件の遵守。プロジェクトが非附属書1国にあり、法律の不施行による規制上の追加性を主張する場合、提供された証拠がその主張を裏付けるものであることを確認する。<br />
                  •	実現可能性・調査（フィージビリティ・スタディ）報告書からの引用を含め、財務計算や感度分析に使用されるデータやパラメータの妥当性。<br />
                  •	投資分析に使用したベンチマークの適合性。<br />
                  •	障壁分析で特定された各障壁の信頼性。<br />
                  •	一般的慣行分析で使用された地理的地域の妥当性。<br />
                  •	類似プロジェクトと提案されているプロジェクトとの本質的な違いを含む、共通実施分析で特定された類似プロジェクトに関する情報。<br />
                  •	追加性の実証に用いた仮定の妥当性。<br />
                  標準化された手法の場合、以下の評価方法に関する情報を最低限含める（該当する場合）：<br />
                  •	規制上の余剰要件への準拠。プロジェクトが非附属書1国であり、法律の不施行による規制上の追加性を主張する場合、提供された証拠がこの主張を裏付けていることを確認すること。<br />
                  •	実績手法については、選択した実績ベンチマークの妥当性と、そのベンチマークのレベルを達成するプロジェクトの能力。<br />
                  •	標準化された方法で定められたその他のすべての基準および手順の遵守。<br />
                  追加性の実証に使用したデータをクロスチェックするために講じた手順の詳細（情報源を含む）を示すこと。プロジェクトにおいて追加性が正当化されるかどうかに関する総合的な結論を示すこと。<br />
                </Typography>
                <Controller
                  name="additionality"
                  control={control}
                  rules={validationRules.additionality}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="additionality"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.6 GHG排出削減量と二酸化炭素除去量の定量化</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  プロジェクトのクレジット対象期間中にプロジェクトによって発生するGHG排出削減量と二酸化炭素除去量に使用される定量化方法を特定する。定量化方法を検証するために取られた手順を記述すること。これには、算定式に使用された全てのデータ及びパラメータ、使用された他のデータソースへの参照が含まれる。説明の中に、以下がどのように評価されたかに関する情報を含めること：<br />
                  •	ベースライン排出量の定量化<br />
                  •	プロジェクト排出量の定量化<br />
                  •	リーケージ（漏出）排出量の定量化<br />
                  •	GHG排出削減量または二酸化炭素除去量の概要<br />
                  •	排出量算定に伴う不確実性<br />
                  •	前提条件やデータ源の根拠となる文書<br />
                  <br />
                  プロジェクトの内容に関して、以下の点を評価すること：<br />
                  •	関連するすべての仮定とデータは、参照先と出典を含め、プロジェクトの説明に記載されている。<br />
                  •	プロジェクトの説明で使用された全てのデータ及びパラメータ値は、プロジェクトの文脈において妥当であり、NCCCプログラムの規則に適合していると考えられる。<br />
                  •	ベースライン排出量の全ての推定値は、プロジェクトの説明で提供されたデータとパラメータ値を用いて再現可能である。<br />
                  <br />
                  ベースライン排出量、プロジェクト排出量、リーケージ、削減・除去量の算定に方法論と参照したツール が正しく適用されたかどうかについて、全体的な結 論を記述すること。<br />
                </Typography>
                <Controller
                  name="quantification"
                  control={control}
                  rules={validationRules.quantification}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="quantification"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.7 方法論の逸脱</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  プロジェクトに適用された方法論の逸脱を特定し、それぞれの逸脱を検証するために取られた手順を記述すること。以下がどのように評価されたかに関する情報を含むこと：<br />
                  •	その逸脱が、許容される方法論逸脱の基準と仕様に合致しているかどうか。<br />
                  •	その逸脱がGHG排出削減量または除去量の定量化の保守性にマイナスの影響を与えるかどうか（精度の向上をもたらす場合を除く）。<br />
                  プロジェクトに適用された方法論の逸脱が妥当かどうかに関する全体的な結論を提示する。<br />
                </Typography>
                <Controller
                  name="deviateProgram"
                  control={control}
                  rules={validationRules.deviateProgram}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="deviateProgram"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>6.8 モニタリング計画</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  モニタリングされるパラメータを特定し、モニタリング機器及び手順の適合性及び適格性を検証するために講じた手順（モニタリングされたデータ及びパラメータを取得、記録、集計及び分析するためのプロセス及びスケジュール等）を記述すること。<br />
                  モニタリング計画が、適用された方法論および参照されたツールの要件に準拠していること に関する全体的な結論を示すこと。
                </Typography>
                <Controller
                  name="monitoringPlan"
                  control={control}
                  rules={validationRules.monitoringPlan}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="monitoringPlan"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>6.9 非永続的リスク分析</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  関連する場合、プロジェクト提案者によって決定された非永続的リスク評価を評価するために取られたステップを記述する。各リスクファクターについて、以下の情報を提供する：<br />
                  •	リスクスコアを裏付けるために使用されたすべての根拠、仮定、正当化の評価。<br />
                  •	リスクスコアを裏付けるために提供された文書及びデータの質の評価。<br />
                  •	リスクスコアの妥当性に関する結論。<br />
                  総合的なリスク評価の決定値に関する結論を提示する。<br />
                </Typography>
                <Controller
                  name="nonPersistentRiskAnalysis"
                  control={control}
                  rules={validationRules.nonPersistentRiskAnalysis}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="nonPersistentRiskAnalysis"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {6 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step7 妥当性確認意見 */}
          <Step key={7}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(6)}
            >
              {steps[6].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>7.1 検証の概要</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  GHG 記述がプロジェクト提案者の責任であること、そのプロジェクトが NCCC 第 4 版に規定されたプロ ジェクトの検証基準に適合しているかどうか、また除外事項や修正事項があればその旨を明記する。反対意見、否認意見、修正意見または適格意見には、バリデーション／検証機関の結論 の前に、その意見の理由の説明を含めなければならない。<br />
                  国際認定機関フォーラム認定機関が承認した妥当性確認／検証機関の意見書には、GHG 記述の妥当性確認及び／又は検証が ISO14064-3 に従って実施されたという宣言を含めなければならない。適用される ISO のバージョンが含まれていなければならない（例：ISO 14064-3; 2019）。<br />
                </Typography>
                <Controller
                  name="verificationOverview"
                  control={control}
                  rules={validationRules.verificationOverview}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="verificationOverview"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>7.2 検証の結論</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  GHG記述の主張を裏付けるデータや情報が、仮定のものであるか、予測のものであるか、過去のものであるかを記述する。将来の活動の結果に関する主張を裏付ける仮定、制限、方法の妥当性を述べ、見積りは変更される可能性のある仮定に基づいているため、実際の結果は異なる可能性があることを説明する。プロジェクトが、以下に記載する温室効果ガス排出削減量と二酸化炭素除去量の推定値を達成する可能性があるかどうかを結論づける。プロジェクトが除去量と削減量を別々に報告する場合は、これらも別々に検証しなければならない。<br />
                  クレジット算定期間： DD-Month-YYYY]から[DD-Month-YYYY]まで。<br />
                  プロジェクトのクレジット対象期間におけるGHG排出削減量と二酸化炭素除去量の推定値を検証する：<br />
                </Typography>
                <Controller
                  name="verificationConclusion"
                  control={control}
                  rules={validationRules.verificationConclusion}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="verificationConclusion"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>永続性リスクの評価が必要なプロジェクトの場合</Typography>
              <Typography>
                以下の、要求された情報を提供してください。
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>非永続的リスク評価(%)： </Typography>
                <Controller
                  name="nonPermanentRiskEvaluation"
                  control={control}
                  rules={validationRules.nonPermanentRiskEvaluation}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="nonPermanentRiskEvaluation"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>％</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography sx={{ mr: 1 }}>該当する場合、長期平均（LTA）、それが適切に更新されているかどうか、またそれに達しているかどうか： </Typography>
                <Controller
                  name="updateLtaDoc"
                  control={control}
                  rules={validationRules.updateLtaDoc}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      multiline
                      rows={3}
                      id="updateLtaDoc"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography>
                プロジェクトのクレジット期間について、記入してください。
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                      <Box sx={{ marginBottom: "1rem" }}>
                        <Typography sx={{ mr: 1 }}>クレジット期間</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <DatePicker
                            id="projectCreditTermStart"
                            // label="プロジェクト開始日"
                            value={projectCreditTermStart}
                            onChange={handleChangeProjectCreditTermStart}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                          <DatePicker
                            id="projectCreditTermEnd"
                            // label="プロジェクト終了日"
                            value={projectCreditTermEnd}
                            onChange={handleChangeProjectCreditTermEnd}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定ベースライン排出量（tCO2e）</Typography>
                      <Controller
                        name="estimatedBaselineEmissions"
                        control={control}
                        rules={validationRules.estimatedBaselineEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定プロジェクト排出量（tCO2e）</Typography>
                      <Controller
                        name="estimatedProjectEmissions"
                        control={control}
                        rules={validationRules.estimatedProjectEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定リーケージ排出量（tCO2e）</Typography>
                      <Controller
                        name="estimatedLeakageEmissions"
                        control={control}
                        rules={validationRules.estimatedLeakageEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定削減クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedReductionCredit"
                        control={control}
                        rules={validationRules.estimatedReductionCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定除去クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedRemoveCredit"
                        control={control}
                        rules={validationRules.estimatedRemoveCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定合計クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedTotalCredit"
                        control={control}
                        rules={validationRules.estimatedTotalCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={1} lg={1}>
                    <Box sx={{ marginTop: "2rem" }}>
                      <Button variant="contained" onClick={addProjectCreditTermRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delProjectCreditTermRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForProjectCreditTerm}
                  columns={projectCreditTermColumns}
                  rows={projectCreditTermRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ marginBottom: "2rem", mt: 2 }}>
                <Typography sx={{ mr: 1 }}>合計</Typography>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定ベースライン排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedBaselineEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定プロジェクト排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedProjectEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定リーケージ排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedLeakageEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定削減クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedReductionCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定除去クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedRemoveCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定合計クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedTotalCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {7 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step8 検証機関の委員リスト */}
          <Step key={8}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(7)}
            >
              {steps[7].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>検証機関の委員リスト</Typography>
              <Typography>以下のリストは機密事項であり、公表版からは削除されます。</Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="committeeName"
                        control={control}
                        rules={validationRules.secretSection}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="名前"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <Controller
                        name="committeeBelong"
                        control={control}
                        rules={validationRules.secretInfo}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="所属"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="committeeApproval"
                        control={control}
                        rules={validationRules.secretJustification}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="検証への同意(する／しない／棄権)"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addCommitteeListRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delCommitteeListRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForCommitteeList}
                  columns={committeeListColumns}
                  rows={committeeListRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {8 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step9 */}
          <Step key={9}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(8)}
            >
              {steps[8].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>8.1 商業上の機密情報</Typography>
              <Typography>プロジェクトの説明文に含まれる商業上機微な情報な情報のうち、一般公開版では除外するものについて説明してください。</Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="secretSection"
                        control={control}
                        rules={validationRules.secretSection}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="セクション"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <Controller
                        name="secretInfo"
                        control={control}
                        rules={validationRules.secretInfo}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="情報"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="secretJustification"
                        control={control}
                        rules={validationRules.secretJustification}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="正当化"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={11}>
                    <Box>
                      <Controller
                        name="secretConclusion"
                        control={control}
                        rules={validationRules.secretConclusion}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="評価方法と結論"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addSecretInfoRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delSecretInfoRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForSecretInfo}
                  columns={secretInfoColumns}
                  rows={secretInfoRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {8 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step key={10}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(9)}
            >
              {steps[9].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>11.2 その他・補足事項</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="otherInfo"
                  control={control}
                  rules={validationRules.otherInfo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      id="otherInfo"
                      label="その他・補足事項等"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {9 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={true}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
              {/*

              
              <h4>プロジェクトの適格性</h4>
              <div>所有権</div>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="branchId"
                  control={control}
                  rules={validationRules.branchId}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth error={fieldState.invalid}>
                      <InputLabel>支部名*</InputLabel>
                      <Select
                        {...field}
                        input={<OutlinedInput label="支部名" />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          {branches.length ? "未選択" : "支部が存在しません"}
                        </MenuItem>
                        {branches.map((item) => (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                            style={getStyles(item._id, option, theme)}
                          >
                            {item.branchName}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.invalid && (
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
              <Typography mb={2}>実施期間*</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="startDate"
                  control={control}
                  rules={validationRules.startDate}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                        <DatePicker
                          label="開始日"
                          views={["year", "month", "day"]}
                          format="YYYY/MM/DD"
                          onChange={(event) => {
                            onChange(event);
                          }}
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            display: "block",
                            color: "#f44336",
                            margin: "3px 14px 0",
                          }}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="endDate"
                  control={control}
                  rules={validationRules.endDate}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                        <DatePicker
                          label="終了日"
                          views={["year", "month", "day"]}
                          format="YYYY/MM/DD"
                          onChange={(event) => {
                            onChange(event);
                          }}
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            display: "block",
                            color: "#f44336",
                            margin: "3px 14px 0",
                          }}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Grid>
            <Grid xs={12} lg={4}>
              <Typography mb={2}>プロジェクト情報</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="programId"
                  control={control}
                  rules={validationRules.programId}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth error={fieldState.invalid}>
                      <InputLabel>適応するプログラム*</InputLabel>
                      <Select
                        {...field}
                        input={<OutlinedInput label="適応するプログラム" />}
                        MenuProps={MenuProps}
                      >
                        {programs.map((item) => (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                            style={getStyles(item._id, option, theme)}
                          >
                            {item.programName}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.invalid && (
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="suitabilityReason"
                  control={control}
                  // rules={validationRules.suitabilityReason}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="suitabilityReason"
                      label="プログラム適合理由"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid xs={12} lg={4}>
              <Typography mb={2}>プロジェクト見積書類</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="planDocuments"
                  control={control}
                  rules={validationRules.planDocuments}
                  render={({ field, fieldState }) => (
                    <MuiFileInput
                      type="file"
                      fullWidth
                      {...field}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                      }
                    />
                  )}
                />
              </Box>
              <Typography mb={2}>追加書類（契約書、権利書等）</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="additionalDocuments"
                  control={control}
                  rules={validationRules.additionalDocuments}
                  render={({ field, fieldState }) => (
                    <MuiFileInput
                      type="file"
                      fullWidth
                      {...field}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                      }
                    />
                  )}
                />
              </Box>
              <Button
                // disabled={!formState.isDirty || !formState.isValid}
                variant="contained"
                fullWidth
                sx={{ color: "#fff" }}
                type="submit"
              >
                申請
              </Button>
            </Grid>
          </Grid> */}
        </Stepper>
        {/* 9.1 検証時に利用可能なデータとパラメータ 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog
          maxWidth="lg"
          open={isOpenMonitoringDialog1}
          onClose={handleCloseMonitoringDialog1}
        >
          <DialogTitle>検証時に利用可能なデータとパラメータ</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              検証時（バリデーション時）に決定された、または入手可能で、プロジェクトのクレジット対象期間を通じて固定されているすべてのデータおよびパラメータについて入力してください。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>データ／パラメータ</Typography>
                      <Controller
                      name="monitoringDataOrParam1"
                      control={control}
                      rules={validationRules.monitoringDataOrParam1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ単位</Typography>
                      <Tooltip placement="top-start" title="単位を示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataUnit1"
                      control={control}
                      rules={validationRules.monitoringDataUnit1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ内容</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータの簡単な説明を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataContents1"
                      control={control}
                      rules={validationRules.monitoringDataContents1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データソース</Typography>
                      <Tooltip placement="top-start" title="データソースを示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataSource1"
                      control={control}
                      rules={validationRules.monitoringDataSource1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用値</Typography>
                      <Tooltip placement="top-start" title="適用される値を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringApplicableValue1"
                      control={control}
                      rules={validationRules.monitoringApplicableValue1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ選択の正当性、または適用した測定方法と手順の説明</Typography>
                      <Tooltip placement="top-start" title="データ源の選択を正当化し、必要に応じて参考文献を示す。値が測定に基づく場合は、適用された測定方法と手順（例えば、どのような基準またはプロトコルに従ったのか）の説明を含め、測定を行った責任者／団体、測定日、測定結果を示す。付録として、より詳細な情報を記載することもできる。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringExplain1"
                      control={control}
                      rules={validationRules.monitoringExplain1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={4}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データの目的</Typography>
                      <Tooltip
                        placement="top-start"
                        title={
                          <>
                            以下のいずれかを示す：<br />
                            •	ベースラインシナリオの決定（AFOLUプロジェクトのみ）<br />
                            •	ベースライン排出量の算定<br />
                            •	プロジェクト排出量の算定<br />
                            •	リーケージの算定
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringPurpose1"
                      control={control}
                      rules={validationRules.monitoringPurpose1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>コメント</Typography>
                      <Tooltip placement="top-start" title="その他コメントがあればご記入ください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringComment1"
                      control={control}
                      rules={validationRules.monitoringComment1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseMonitoringDialog1}>戻る</Button>
            <Button
              onClick={addMonitoring1Rows}
              // disabled={true}
            >
              追加
            </Button>
          </DialogActions>
        </Dialog>
        {/* 9.2 モニタリングされるデータとパラメータ 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog
          maxWidth="lg"
          open={isOpenMonitoringDialog2}
          onClose={handleCloseMonitoringDialog2}
        >
          <DialogTitle>モニタリングされるデータとパラメータ</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              プロジェクトのクレジット対象期間中にモニタリングされるすべてのデータとパラメータについて入力してください。<br />
              提供された値は、プロジェクトのクレジット対象期間における削減量と除去量の推定値を定量化するために使用されます。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>データ／パラメータ</Typography>
                      <Controller
                      name="monitoringDataOrParam2"
                      control={control}
                      rules={validationRules.monitoringDataOrParam2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ単位</Typography>
                      <Tooltip placement="top-start" title="測定単位を示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataUnit2"
                      control={control}
                      rules={validationRules.monitoringDataUnit2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ内容</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータの簡単な説明を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataContents2"
                      control={control}
                      rules={validationRules.monitoringDataContents2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データソース</Typography>
                      <Tooltip placement="top-start" title="データソースを示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataSource2"
                      control={control}
                      rules={validationRules.monitoringDataSource2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用される測定方法と手順の説明</Typography>
                      <Tooltip placement="top-start" title="測定方法及び手順、従うべき標準又はプロトコル、及び測定に責任を有する人／組織を明記する。測定の精度に関する関連情報（例えば、計器装置または実験室試験に関連する精度）を含む。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringExplain2"
                      control={control}
                      rules={validationRules.monitoringExplain2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>モニタリング／記録の頻度</Typography>
                      <Tooltip placement="top-start" title="測定と記録の頻度を指定する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringFrequency"
                      control={control}
                      rules={validationRules.monitoringFrequency}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用値</Typography>
                      <Tooltip placement="top-start" title="適用される値を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringApplicableValue2"
                      control={control}
                      rules={validationRules.monitoringApplicableValue2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>モニタリング機器</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータをモニターするために使用される機器を特定すること。必要に応じて、機器のタイプ、精度クラス、シリアル番号を含む">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDevice"
                      control={control}
                      rules={validationRules.monitoringDevice}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用されるQA/QC手順</Typography>
                      <Tooltip placement="top-start" title="適用される品質保証及び品質管理（QA/QC）手順（該当する場合は校正手順を含む）を記載すること。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringQaQcProcedure"
                      control={control}
                      rules={validationRules.monitoringQaQcProcedure}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データの目的</Typography>
                      <Tooltip
                        placement="top-start"
                        title={
                          <>
                            以下のいずれかを示すこと：<br />
                            •	ベースライン排出量の計算<br />
                            •	プロジェクト排出量の計算<br />
                            •	リーケージの計算
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringPurpose2"
                      control={control}
                      rules={validationRules.monitoringPurpose2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>計算方法</Typography>
                      <Tooltip placement="top-start" title="該当する場合、データ／パラメータを確定するために使用した計算方法（方程式を含む）を提示すること。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="howToCalc"
                      control={control}
                      rules={validationRules.howToCalc}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={5}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>コメント</Typography>
                      <Tooltip placement="top-start" title="その他コメントがあればご記入ください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringComment2"
                      control={control}
                      rules={validationRules.monitoringComment2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseMonitoringDialog2}>戻る</Button>
            <Button
              onClick={addMonitoring2Rows}
              // disabled={true}
            >
              追加
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};
