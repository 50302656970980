import React from "react";
import { Box } from "@mui/material";
import Sidebar from "../member/Sidebar";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import authUtils from "../../utils/authUtils";
import { useNavigate } from "react-router-dom";
// import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#78B94F",
      navi: "#78B94F",
    },
    secondary: {
      main: "#00b0ff",
    },
    background: { paper: "#78B94F" },
    danger: { main: "#ff1744" },
  },
});

const MemberLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      //ページ切り替える度に認証チェック(トークン持ってるかどうか確認)
      //ここで404notfoud
      const user = await authUtils.isAuthenticated();
      if (!user) {
        // navigate("/login");
      } else {
        dispatch(setUser(user));
      }
    };
    checkAuth();
  }, [dispatch, navigate]);
  return (
    <div>
      <ThemeProvider theme={darkTheme}>
        <Box
          sx={{
            display: "flex",
            padding: "20px",
            backgroundColor: "#ffffff",
            color: "#000000",
            overflow: "auto",
          }}
        >
          <Sidebar />
          <Box sx={{ flexGrow: 1, padding: { xs: "40px 0", md: "1vw" } }}>
            <Outlet />
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default MemberLayout;
