import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Input,
  Select,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tooltip,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { MuiFileInput } from "mui-file-input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import ja from "dayjs/locale/ja";
import dayjs from "dayjs";
import projectApi from "../../../api/projectApi";
import programApi from "../../../api/programApi";
import branchApi from "../../../api/branchApi";
import authApi from "../../../api/authApi";
import mailApi from "../../../api/mailApi";
import counterApi from "../../../api/counterApi";
import categoryApi from "../../../api/categoryApi";

import uploadImg from "../../../components/common/utils/uploadImg";
import CategoryDropdown from "../../../components/common/categoryDropdown";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, option, theme) {
  return {
    fontWeight:
      option.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const MemberProjectApply = () => {
  const navigate = useNavigate();
  const storedUserInfo = useSelector((state) => state.user);
  const [company, setCompany] = useState({});
  const [programs, setPrograms] = useState([]);
  const [branches, setBranches] = useState([]);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const programId = urlParams.get("programId");
  const branchId = urlParams.get("branchId");

  const steps = [
    {
      label: "基本情報"
    },
    {
      label: "プロジェクト概要"
    },
    {
      label: "プロジェクト詳細"
    },
    {
      label: "プロジェクトの適格性"
    },
    {
      label: "環境および社会的配慮"
    },
    {
      label: "プロジェクト期間と推定GHG排出量"
    },
    {
      label: "方法論の摘要"
    },
    {
      label: "GHG排出削減量と除去量の計算"
    },
    {
      label: "モニタリング"
    },
    {
      label: "参考文献"
    },
    {
      label: "補足事項等"
    },
  ]

  const fetchprogramList = async () => {
    // 承認済みのプログラム
    const approvedPrograms = await programApi.getApprovedList();
    setPrograms(approvedPrograms);

    // 承認済みの支部
    const approvedBranches = await branchApi.getApprovedList();
    const filterdBranches = approvedBranches.filter(
      (branch) => branch.branchMasterId === storedUserInfo.value._id
    );
    setBranches(filterdBranches);

    const companyData = await authApi.getCompany({
      id: storedUserInfo.value.companyId,
    });
    setCompany(companyData.company);
  };

  const sendApplyMail = async (data, planDocuments, additionalDocuments, projectId) => {
    const user = storedUserInfo.value;
    const program = await programApi.getProgram(data.programId);
    const branch = await branchApi.getBranch(data.branchId);

    const replaceWords = {
      applyUserName: user.username,
      groupName: company.groupName,
      projectName: data.projectName,
      programName: program.programName,
      branchName: branch.branchName,
      projectDesc: data.projectDesc,
      projectStart: new Date(data.startDate),
      projectEnd: new Date(data.endDate),
      planDocuments,
      additionalDocuments,
      url: "/member/projects/apply/" + projectId,
    };

    const reqData = { mailId: "22", replaceWords };
    await mailApi.apply(reqData);
  };

  useEffect(() => {
    fetchprogramList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // validation
  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      historyNo: 0,
      catgory: "",
      companyId: "",
      projectName: "",
      programId: programId ?? "",
      suitabilityReason: "",
      branchId: branchId ?? "",
      applicationDocuments: "",
      startDate: "",
      endDate: "",
      projectTimeLine: [],
      // projectPlace: "",
      planDocuments: "",
      additionalDocuments: "",
      // 基本情報
      createdCompanyText: "",
      createdUserText: "",
      // 2.1 プロジェクト概要
      projectDesc: "",
      // 2.3 監査履歴
      auditType: "",
      auditHistoryTerm: "",
      auditHistoryProgram: "",
      auditHistoryName: "",
      auditHistoryYears: "",
      // 3.2 プロジェクト活動の説明
      actExplain: "",
      // 3.3 プロジェクト所在地
      projectPlace: "",
      // 3.4 プロジェクト提案者
      proposerCompany: "",
      proposerStaff: "",
      proposerRole: "",
      proposerAddress: "",
      proposerEmail: "",
      proposerTel: "",
      // 3.5 プロジェクトに関与するその他の団体
      refOtherCompany: "",
      // 3.6 所有権
      projectOwnership: "",
      // 4.1 プロジェクトの適格性
      projectEligibility: "",
      // 4.2 プロジェクト開始前の状況
      beforeStartingProject: "",
      // 4.3 法律、法令、その他の規制枠組みの遵守
      legalCompliance: "",
      // 4.4 二重請求と他のGHGプログラムへの参加
      noDuplicatePublish: "",
      // 4.5 二重請求、他の形態のクレジット、スコープ3排出量
      noDuplicateClaim: "",
      // 5.1 ステークホルダーの参画と協議
      stakeholderRegulations: "",
      // 5.2 ステークホルダーと環境へのリスク
      envRisk: "",
      // 5.3 人権と公平性の尊重
      fairness: "",
      // 5.4 生態系の健全性
      soundnessA1: "",
      soundnessA2: "",
      soundnessB1: "",
      soundnessB2: "",
      soundnessC1: "",
      soundnessC2: "",
      soundnessD1: "",
      soundnessD2: "",
      // 5.5 持続可能な開発への貢献
      sdContribution: "",
      // 5.6 プロジェクトに関連する追加情報
      projectAddInfo: "",
      // 6.1 プロジェクト開始日
      startDateRightfulness: "",
      // 6.2 プロジェクトのクレジット期間
      creditTerm: "",
      // 6.3 プロジェクト規模とGHG排出削減量または除去量の推定値
      estimatedValue1: "",
      estimatedERR: "",
      totalYears: "",
      aveYearlyERR: "",
      // 7.1 方法論のタイトルと参考文献
      program: "",
      methodologyType: "",
      methodologyRefId: "",
      methodologyTitle: "",
      methodologyVersion: "",
      // 7.2 方法論の適用性
      programApplicability: "",
      // 7.3 プロジェクト境界
      baselineOrSource: "",
      gasInclude: "",
      boundaryExplain: "",
      projectBoundaryFile: "",
      // 7.4 ベースライン・シナリオ
      baselineScenario: "",
      // 7.5 追加性
      additionality: "",
      // 7.6 方法論の逸脱
      deviateProgram: "",
      // 8.1 ベースライン排出量
      baselineEmissions: "",
      // 8.2 プロジェクト排出量
      projectEmissions: "",
      // 8.3 リーケージ排出量
      leakageEmissions: "",
      // 8.4 GHG排出削減量と二酸化炭素除去量の推定値
      nonPermanentRiskEvaluation: "",
      projectLTA: "",
      NonUpdateLtaReason: "",
      expectGhgEmissions: "",
      NotBelowLtaReason: "",
      estimatedBaselineEmissions: "",
      estimatedProjectEmissions: "",
      estimatedLeakageEmissions: "",
      estimatedReductionCredit: "",
      estimatedRemoveCredit: "",
      estimatedTotalCredit: "",
      // 9.1
      monitoringDataOrParam1: "",
      monitoringDataUnit1: "",
      monitoringDataContents1: "",
      monitoringDataSource1: "",
      monitoringApplicableValue1: "",
      monitoringExplain1: "",
      monitoringPurpose1: "",
      monitoringComment1: "",
      // 9.2
      monitoringDataOrParam2: "",
      monitoringDataUnit2: "",
      monitoringDataContents2: "",
      monitoringDataSource2: "",
      monitoringExplain2: "",
      monitoringFrequency: "",
      monitoringApplicableValue2: "",
      monitoringDevice: "",
      monitoringQaQcProcedure: "",
      monitoringPurpose2: "",
      howToCalc: "",
      monitoringComment2: "",
      // 9.3 モニタリング計画
      monitoringPlan: "",
      // 10 参考文献
      references: "",
      // 11.1 補足事項等(商業上の機密情報)
      secretSection: "",
      secretInfo: "",
      secretJustification: "",
      // 11.2 その他・補足事項等
      otherInfo: "",
    },
  });

  const validationRules = {
    branchId: {
      required: "支部名を選択してください",
    },
    projectName: {
      required: "プロジェクト名を入力してください。",
      maxLength: { value: 20, message: "20文字以内で入力してください" },
    },
    programId: {
      required: "適応するプログラムを選択してください",
    },
    projectDesc: {
      required: "プロジェクト概要を入力してください",
      maxLength: { value: 800, message: "800文字以内で入力してください" },
    },
    startDate: {
      required: "プロジェクト開始日を入力してください",
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!dayjs(formatDate).isValid()) {
          return "日付形式が間違っています";
        }
        // 相関チェック　日付の前後チェック
        const endDate = getValues("endDate");
        if (value > endDate) {
          return "開始日は終了日以前の日付を指定してください。";
        }
      },
    },
    endDate: {
      required: "プロジェクト終了日を入力してください",
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!dayjs(formatDate).isValid()) {
          return "日付形式が間違っています";
        }
      },
    },
    projectPlace: {
      required: "実施場所を入力してください",
    },
    planDocuments: {
      required: "申請書類をアップロードしてください",
      validate: {
        isPdf: (value) => value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
    additionalDocuments: {
      validate: {
        isPdf: (value) =>
          !value || value?.type === "application/pdf" || "ファイル形式が間違っています",
        maxSize: (value) => !value || value?.size <= 5242880 || "ファイルサイズが大きすぎます",
      },
    },
  };

  const zeroPadding = (len, num) => {
    return ( Array(len).join('0') + num ).slice( -len )
  }

  const makeProjectSerialId = (seqA, branch, category, ) => {
    const partBranch = zeroPadding(4, branch)
    const partCategory = zeroPadding(2, category)
    const partCreateSeq = zeroPadding(6, seqA)
    return "NCCC-" + partBranch + "-" + partCategory + "-" + partCreateSeq
  }

  const onSubmit = async (data) => {
    if (window.confirm("申請します。よろしいですか？")) {
      try {
        const timeline = [new Date(data.startDate), new Date(data.endDate)];
        // const program = await programApi.getProgram(data.programId);

        const reqData = {
          creator: {
            createdCompanyId: company._id,
            createdUserId: storedUserInfo?.value._id,
            createdCompanyText: getValues("createdCompanyText") || company.groupName,
            createdUserText: getValues("createdUserText") || storedUserInfo?.value.username,
          },
          applyUserId: storedUserInfo.value._id,
          companyId: storedUserInfo.value.companyId,
          programId: data.programId,
          projectTimeLine: timeline || [],
          place: data.projectPlace || "",
          // program: {
          //   id: data.programId,
          //   historyNo: program.historyNo,
          //   suitabilityReason: data.suitabilityReason,
          // },
          //上は前までのやつ 
          projectName: getValues("projectName") || "",
          projectStatus: "10",
          branchId: getValues("branchId") || "",
          projectDesc: data.projectDesc || "",
          projectCategory: projectCategory || "",
          audit: getAuditHistoryRows() || [],
          projectDesign: projectDesignCategory || "",
          actExplain: data.actExplain || "",
          projectPlace: data.projectPlace || "",
          proposer: {
            company: company?.groupName,
            staff: storedUserInfo?.value.username,
            role: storedUserInfo?.value.role,
            address: company?.address,
            email: storedUserInfo?.value.email,
            tel: data.proposerTel,
          },
          refOtherCompany: data.refOtherCompany || "",
          projectOwnership: data.projectOwnership || "",
          projectEligibility: data.projectEligibility || "",
          beforeStartingProject: data.beforeStartingProject || "",
          legalCompliance: data.legalCompliance || "",
          noDuplicatePublish: {
            check: duplicatePublishCheck,
            evidence: data.noDuplicatePublish || "",
          },
          noDuplicateClaim: {
            check: duplicateClaimCheck,
            evidence: data.noDuplicateClaim || "",
          },
          stakeholderRegulations: data.stakeholderRegulations || "",
          envRisk: data.envRisk || "",
          fairness: data.fairness || "",
          soundnessA: {
            soundnessACheck: soundnessACheck,
            soundnessA1: data.soundnessA1 || "",
            soundnessA2: data.soundnessA2 || "",
          },
          soundnessB: {
            soundnessBCheck: soundnessBCheck,
            soundnessB1: data.soundnessB1 || "",
            soundnessB2: data.soundnessB2 || "",
          },
          soundnessC: {
            soundnessCCheck: soundnessCCheck,
            soundnessC1: data.soundnessC1 || "",
            soundnessC2: data.soundnessC2 || "",
          },
          soundnessD: {
            soundnessDCheck: soundnessDCheck,
            soundnessD1: data.soundnessD1 || "",
            soundnessD2: data.soundnessD2 || "",
          },
          sdContribution: data.sdContribution || "",
          projectAddInfo: data.projectAddInfo || "",
          projectStartDate: projectStartDate,
          startDateRightfulness: data.startDateRightfulness || "",
          projectCreditTerm: {
            creditTerm: data.creditTerm || "",
            creditStartDate: creditStartDate,
            creditEndDate: creditEndDate,
          },
          estimatedValue: getEstimatedValue1Rows() || [],
          estimatedERR: data.estimatedERR || "",
          totalYears: data.totalYears || "",
          aveYearlyERR: data.aveYearlyERR || "",
          programs: getMethodologyRows() || [],
          programApplicability: data.programApplicability || "",
          projectBoundary: getProjectBoundaryRows() || [],
          // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
          projectBoundaryFile: "" || "",
          baselineScenario: data.baselineScenario || "",
          additionality: data.additionality || "",
          deviateProgram: data.deviateProgram || "",
          baselineEmissions: data.baselineEmissions || "",
          projectEmissions: data.projectEmissions || "",
          leakageEmissions: data.leakageEmissions || "",
          nonPermanentRiskEvaluation: data.nonPermanentRiskEvaluation || "",
          nonPermanentRiskReportCheck: nonPermanentRiskReportCheck,
          projectLTA: data.projectLTA || "",
          updateLta: {
            check: updateLtaCheck,
            NonUpdateLtaReason: data.NonUpdateLtaReason || "",
          },
          expectGhgEmissions: data.expectGhgEmissions || "",
          belowLta: {
            check: ghgCreditCheck,
            NotBelowLtaReason: data.NotBelowLtaReason || "",
          },
          emissions: {
            detail: getEmissionsDetailRows() || [],
            total: {
              estimatedBaselineEmissionsTotal: estimatedBaselineEmissionsTotal || "0",
              estimatedProjectEmissionsTotal: estimatedProjectEmissionsTotal || "0",
              estimatedLeakageEmissionsTotal: estimatedLeakageEmissionsTotal || "0",
              estimatedReductionCreditTotal: estimatedReductionCreditTotal || "0",
              estimatedRemoveCreditTotal: estimatedRemoveCreditTotal || "0",
              estimatedTotalCreditTotal: estimatedTotalCreditTotal || "0",
            },
          },
          availableData: getAvailableDataRows() || [],
          monitoringData: getMonitoringDataRows() || [],
          monitoringPlan: data.monitoringPlan || "",
          references: data.references || "",
          secret: getSecretRows() || [],
          otherInfo: data.otherInfo || "",
        }

        let projectNo = {};
        let seqNumber = 0;
        projectNo = await counterApi.upsert({ key: "projectNo" });
        seqNumber = projectNo.seq;
        reqData.projectNo = seqNumber;

        let projectSerialNo = {};
        let serialSeqNumber = 0;
        projectSerialNo = await counterApi.upsert({ key: "projectSerialNo" });
        serialSeqNumber = projectSerialNo.seq;
        reqData.serialId = makeProjectSerialId(serialSeqNumber, "0", projectDesignCategory);

        const planDocs = data.planDocuments;
        const additionalDocs = data.additionalDocuments;

        const uploadTasks = []
        if (planDocs) uploadTasks.push([uploadImg(planDocs, ["planDocuments"])]);
        if (additionalDocs) uploadTasks.push(uploadImg(additionalDocs, ["additionalDocuments"]));
        const [planUrl, additionalUrl] = uploadTasks !== [] ? await Promise.all(uploadTasks) : ["", ""];

        reqData.additionalDocuments = additionalUrl
        reqData.planDocuments = planUrl

        const projectBoundaryFile = getValues("projectBoundaryFile") || ""
        // console.log(projectBoundaryFile)
        if (projectBoundaryFile || projectBoundaryFile.length !== 0) {
          const uploadTasks = [uploadImg(projectBoundaryFile, ["projectBoundaryFile", seqNumber])];
          const [projectBoundaryFileUrl] = await Promise.all(uploadTasks);
          reqData.projectBoundaryFile = projectBoundaryFileUrl
        }

        projectApi
          .register(reqData)
          .then(async (res) => {
            const projectId = res.project._id;
            // 一旦メールはコメントアウト
            // return sendApplyMail(
            //   data,
            //   reqData.planDocuments,
            //   reqData.additionalDocuments,
            //   projectId
            // );
          })
          .then(() => {
            alert("申請しました");
            navigate("/member/projects");
          })
          .catch((e) => {
            alert("申請に失敗しました。運営にお問合せください。");
            console.error(e);
          });
      } catch (error) {
        alert("申請に失敗しました。運営にお問合せください。");
        console.error(error);
      }
    }
  };

  ///////////////////////
  // file type指定テスト //
  ///////////////////////

  // const ref = useRef(null);
  // const handleChange = (e) => {
  //   if (!e.target.files) return;
  //   const files = Array.from(e.target.files);
  //   setFile(files);
  // };
  // const getFileNames = () =>
  //   file?.reduce(
  //     (fileNames, file) =>
  //       `${fileNames} ${fileNames !== "" ? "," : ""} ${file.name}`,
  //     ""
  //   ) || "";

  ///////////////////////

  // 2.2 セクターの範囲とプロジェクトの種類に関する変数等
  const [projectCategory, setProjectCategory] = useState("");
  const handleCategoryChange = (event) => {
    setProjectCategory(event.target.value);
    // dispatch(task_setCategory(event.target.value));
  };

  // 2.3 監査履歴に関する変数等
  const [auditHistoryRows, setAuditHistoryRows] = React.useState([]);
  const apiRefForAuditHistory = useGridApiRef();
  const auditHistoryColumns = [
    { field: "auditType", headerName: "監査タイプ" },//バリデーション or 検証
    { field: "auditHistoryTerm", headerName: "期間" },
    { field: "auditHistoryProgram", headerName: "プログラム" },
    { field: "auditHistoryName", headerName: "バリデーション名／検証機関名" },
    { field: "auditHistoryYears", headerName: "年数" },
  ];
  const addAuditHistoryRows = () => {
    const id = (auditHistoryRows.length === 0) ? 1 : Math.max(...auditHistoryRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const auditTypeValue = getValues("auditType")
    const auditHistoryTermValue = getValues("auditHistoryTerm")
    const auditHistoryProgramValue = getValues("auditHistoryProgram")
    const auditHistoryNameValue = getValues("auditHistoryName")
    const auditHistoryYearsValue = getValues("auditHistoryYears")
    const newValue = {
      id: id,
      auditType: auditTypeValue,
      auditHistoryTerm: auditHistoryTermValue,
      auditHistoryProgram: auditHistoryProgramValue,
      auditHistoryName: auditHistoryNameValue,
      auditHistoryYears: auditHistoryYearsValue,
    };
    setAuditHistoryRows([...auditHistoryRows, newValue]);
    reset({
      auditType: "",
      auditHistoryTerm: "",
      auditHistoryProgram: "",
      auditHistoryName: "",
      auditHistoryYears: "",
    })
  }

  const delAuditHistoryRows = () => {
    const delAuditHistoryRow = apiRefForAuditHistory.current.getSelectedRows();
    if (auditHistoryRows.length === 0 || delAuditHistoryRow.size === 0) return;
    delAuditHistoryRow.forEach(v => {
      apiRefForAuditHistory.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newAuditHistoryRows = apiRefForAuditHistory.current.getSortedRows();
    if (newAuditHistoryRows !== null) {
      setAuditHistoryRows(Object.keys(newAuditHistoryRows).length === 0 ? [] : [newAuditHistoryRows]);
    }
  }

  const getAuditHistoryRows = () => {
    if (!apiRefForAuditHistory.current || Object.keys(apiRefForAuditHistory.current).length === 0) return null
    const allAuditHistoryRows = apiRefForAuditHistory.current?.getSortedRows();
    return allAuditHistoryRows
  }

  // 3.1 プロジェクト・デザインに関する変数等
  const [projectDesignCategory, setProjectDesignCategory] = useState("");
  const handleProjectDesignCategoryChange = (event) => {
    setProjectDesignCategory(event.target.value);
  };

  // 4.4 二重請求と他のGHGプログラムへの参加に関する変数等
  const [duplicatePublishCheck, setDuplicatePublishCheck] = useState(false);
  const handleChangeDuplicatePublishCheck = (event) => {
    if (event.target.value === "1") {
      setDuplicatePublishCheck(true)
    } else {
      setDuplicatePublishCheck(false)
    }
  }

  // 4.5 二重請求、他の形態のクレジット、スコープ3排出量に関する変数等
  const [duplicateClaimCheck, setDuplicateClaimCheck] = useState(false);
  const handleChangeDuplicateClaimCheck = (event) => {
    if (event.target.value === "1") {
      setDuplicateClaimCheck(true)
    } else {
      setDuplicateClaimCheck(false)
    }
  }

  // 5.4 生態系の健全性に関する変数等
  const [soundnessACheck, setSoundnessACheck] = useState(false);
  const [soundnessBCheck, setSoundnessBCheck] = useState(false);
  const [soundnessCCheck, setSoundnessCCheck] = useState(false);
  const [soundnessDCheck, setSoundnessDCheck] = useState(false);
  const handleChangeSoundnessACheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessACheck(true)
    } else {
      setSoundnessACheck(false)
    }
  }
  const handleChangeSoundnessBCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessBCheck(true)
    } else {
      setSoundnessBCheck(false)
    }
  }
  const handleChangeSoundnessCCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessCCheck(true)
    } else {
      setSoundnessCCheck(false)
    }
  }
  const handleChangeSoundnessDCheck = (event) => {
    if (event.target.value === "1") {
      setSoundnessDCheck(true)
    } else {
      setSoundnessDCheck(false)
    }
  }

  // 6.1 プロジェクト開始日に関する変数等
  const [projectStartDate, setProjectStartDate] = useState();
  const handleChangeProjectStartDate = (newValue) => {
    setProjectStartDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };

  // 6.2 プロジェクトのクレジット期間に関する変数等
  const [creditStartDate, setCreditStartDate] = useState();
  const [creditEndDate, setCreditEndDate] = useState();
  const handleChangeCreditStartDate = (newValue) => {
    setCreditStartDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeCreditEndDate = (newValue) => {
    setCreditEndDate(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };

  // 6.3 プロジェクト規模とGHG排出削減量または除去量の推定値に関する変数等
  const [estimatedValue1Rows, setEstimatedValue1Rows] = React.useState([]);
  const [creditTimeStart, setCreditTimeStart] = useState();
  const [creditTimeEnd, setCreditTimeEnd] = useState();
  const handleChangeCreditTimeStart = (newValue) => {
    setCreditTimeStart(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeCreditTimeEnd = (newValue) => {
    setCreditTimeEnd(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const apiRefForEstimatedValue1 = useGridApiRef();
  const estimatedValue1Columns = [
    { field: "creditTime", headerName: "プロジェクト期間の暦年", width: "50rem" },
    { field: "estimatedValue1", headerName: "GHG排出削減量または除去量の推定値（tCO2e）", width: "50rem" },
  ];
  const addEstimatedValue1Rows = () => {
    const id = (estimatedValue1Rows.length === 0) ? 1 : Math.max(...estimatedValue1Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const estimatedValue1 = getValues("estimatedValue1")
    const creditTime = `${dayjs(creditTimeStart).format("YYYY/MM/DD")}〜${dayjs(creditTimeEnd).format("YYYY/MM/DD")}`

    const newValue = {
      id: id,
      creditTime: creditTime,
      estimatedValue1: estimatedValue1,
    };
    setEstimatedValue1Rows([...estimatedValue1Rows, newValue]);
    reset({
      estimatedValue1: "",
    })
    setCreditTimeStart(null)
    setCreditTimeEnd(null)
  }

  const delEstimatedValue1Rows = () => {
    const delEstimatedValue1Row = apiRefForEstimatedValue1.current.getSelectedRows();
    if (estimatedValue1Rows.length === 0 || delEstimatedValue1Row.size === 0) return;
    delEstimatedValue1Row.forEach(v => {
      apiRefForEstimatedValue1.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newEstimatedValue1Rows = apiRefForEstimatedValue1.current.getSortedRows();
    if (newEstimatedValue1Rows !== null) {
      setEstimatedValue1Rows(Object.keys(newEstimatedValue1Rows).length === 0 ? [] : [newEstimatedValue1Rows]);
    }
  }

  const getEstimatedValue1Rows = () => {
    if (!apiRefForEstimatedValue1.current || Object.keys(apiRefForEstimatedValue1.current).length === 0) return null
    const allEstimatedValue1Rows = apiRefForEstimatedValue1.current?.getSortedRows();
    if (!allEstimatedValue1Rows) return null
    let estimatedValues = []
    allEstimatedValue1Rows.forEach((row) => {
      let value = {
        creditTimeStart: "",
        creditTimeEnd: "",
        estimatedValue1: "",
      }
      value.creditTimeStart = row.creditTime.split("〜")[0]
      value.creditTimeEnd = row.creditTime.split("〜")[1]
      value.estimatedValue1 = row.estimatedValue1
      estimatedValues.push(value)
    })
    return estimatedValues
  }

  // 7.1 方法論のタイトルと参考文献に関する変数等
  const [methodologyCheck, setMethodologyCheck] = useState(false);
  const [methodologyRows, setMethodologyRows] = React.useState([]);
  const [methodologyTypeCategory, setMethodologyTypeCategory] = useState("");
  const apiRefForMethodology = useGridApiRef();
  const handleChangeMethodologyCheck = () => {
    setMethodologyCheck(!methodologyCheck)
  }

  const handleMethodologyTypeChange = (event) => {
    setMethodologyTypeCategory(event.target.value);
  };

  const methodologyColumns = [
    { field: "methodologyTypeText", headerName: "タイプ" },
    { field: "methodologyRefId", headerName: "参照ID" },
    { field: "methodologyTitle", headerName: "タイトル" },
    { field: "methodologyVersion", headerName: "Version" },
  ];
  const addMethodologyRows = async () => {
    const methodologyTypeCategoryList = await categoryApi.get({
      params: {
        name: "methodologyType"
      }
    })
    const id = (methodologyRows.length === 0) ? 1 : Math.max(...methodologyRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const methodologyTypeValue = methodologyTypeCategoryList?.list[Number(methodologyTypeCategory)-1]?.text
    const methodologyRefIdValue = getValues("methodologyRefId")
    const methodologyTitleValue = getValues("methodologyTitle")
    const methodologyVersionValue = getValues("methodologyVersion")
    
    const newValue = {
      id: id,
      ncccProgramId: null,
      methodologyTypeId: methodologyTypeCategory,
      methodologyTypeText: methodologyTypeValue,
      methodologyRefId: methodologyRefIdValue,
      methodologyTitle: methodologyTitleValue,
      methodologyVersion: methodologyVersionValue,
    };
    setMethodologyRows([...methodologyRows, newValue]);
    reset({
      // methodologyType: "",
      methodologyRefId: "",
      methodologyTitle: "",
      methodologyVersion: "",
    })
    setMethodologyTypeCategory("")
  }

  const addNcccMethodologyRows = async () => {
    const programValue = getValues("program")
    if (!programValue) return
    const methodologyProgram = await programApi.getProgram(programValue)
    const id = (methodologyRows.length === 0) ? 1 : Math.max(...methodologyRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const methodologyRefIdValue = methodologyProgram.programNo
    const methodologyTitleValue = methodologyProgram.programName
    const methodologyVersionValue = methodologyProgram.version
    const newValue = {
      id: id,
      ncccProgramId: methodologyProgram._id,
      methodologyTypeId: "0",
      methodologyTypeText: "NCCC方法論",
      methodologyRefId: methodologyRefIdValue,
      methodologyTitle: methodologyTitleValue,
      methodologyVersion: methodologyVersionValue,
    };
    setMethodologyRows([...methodologyRows, newValue]);
  }

  const delMethodologyRows = () => {
    const delMethodologyRow = apiRefForMethodology.current.getSelectedRows();
    if (methodologyRows.length === 0 || delMethodologyRow.size === 0) return;
    delMethodologyRow.forEach(v => {
      apiRefForMethodology.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMethodologyRows = apiRefForMethodology.current.getSortedRows();
    if (newMethodologyRows !== null) {
      setMethodologyRows(Object.keys(newMethodologyRows).length === 0 ? [] : [newMethodologyRows]);
    }
  }

  const getMethodologyRows = () => {
    if (!apiRefForMethodology.current || Object.keys(apiRefForMethodology.current).length === 0) return null
    const allMethodologyRows = apiRefForMethodology.current?.getSortedRows();
    return allMethodologyRows
  }

  // 7.3 プロジェクト境界に関する変数等
  // const [gasIncludeCheck, setGasIncludeCheck] = useState(false);
  const [projectBoundaryRows, setProjectBoundaryRows] = React.useState([]);
  const [gasCategory, setGasCategory] = useState("");
  const apiRefForProjectBoundary = useGridApiRef();
  // const handleChangeGasIncludeCheck = () => {
  //   setGasIncludeCheck(!gasIncludeCheck)
  // }

  const handleGasCategoryChange = (event) => {
    setGasCategory(event.target.value);
  };

  const projectBoundaryColumns = [
    { field: "baselineOrSource", headerName: "ベースライン／ソース" },
    { field: "gasType", headerName: "Gas" },
    { field: "gasInclude", headerName: "含まれる？" },
    { field: "boundaryExplain", headerName: "正当性／説明" },
  ];
  const addProjectBoundaryRows = async () => {
    const gasCategoryList = await categoryApi.get({
      params: {
        name: "gas"
      }
    })
    const id = (projectBoundaryRows.length === 0) ? 1 : Math.max(...projectBoundaryRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const gasTypeValue = gasCategoryList?.list[Number(gasCategory)-1]?.text
    const baselineOrSourceValue = getValues("baselineOrSource")
    const gasIncludeValue = getValues("gasInclude")
    const boundaryExplainValue = getValues("boundaryExplain")
    
    const newValue = {
      id: id,
      gasType: gasTypeValue,
      baselineOrSource: baselineOrSourceValue,
      gasInclude: gasIncludeValue,
      boundaryExplain: boundaryExplainValue,
    };
    setProjectBoundaryRows([...projectBoundaryRows, newValue]);
    reset({
      // methodologyType: "",
      baselineOrSource: "",
      gasInclude: "",
      boundaryExplain: "",
    })
    // setGasCategory(null)
  }

  const delProjectBoundaryRows = () => {
    const delProjectBoundaryRow = apiRefForProjectBoundary.current.getSelectedRows();
    if (projectBoundaryRows.length === 0 || delProjectBoundaryRow.size === 0) return;
    delProjectBoundaryRow.forEach(v => {
      apiRefForProjectBoundary.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectBoundaryRows = apiRefForProjectBoundary.current.getSortedRows();
    if (newProjectBoundaryRows !== null) {
      setProjectBoundaryRows(Object.keys(newProjectBoundaryRows).length === 0 ? [] : [newProjectBoundaryRows]);
    }
  }

  const getProjectBoundaryRows = () => {
    if (!apiRefForProjectBoundary.current || Object.keys(apiRefForProjectBoundary.current).length === 0) return null
    const allProjectBoundaryRows = apiRefForProjectBoundary.current?.getSortedRows();
    return allProjectBoundaryRows
  } 

  // 8.4 GHG排出削減量と二酸化炭素除去量の推定値に関する変数等
  const [nonPermanentRiskReportCheck, setNonPermanentRiskReportCheck] = useState(true);
  const [updateLtaCheck, setUpdateLtaCheck] = useState(true);
  const [ghgCreditCheck, setGhgCreditCheck] = useState(true);
  const [projectCreditTermRows, setProjectCreditTermRows] = React.useState([]);
  const [projectCreditTermStart, setProjectCreditTermStart] = useState();
  const [projectCreditTermEnd, setProjectCreditTermEnd] = useState();
  const [estimatedBaselineEmissionsTotal, setEstimatedBaselineEmissionsTotal] = useState(0)
  const [estimatedProjectEmissionsTotal, setEstimatedProjectEmissionsTotal] = useState(0)
  const [estimatedLeakageEmissionsTotal, setEstimatedLeakageEmissionsTotal] = useState(0)
  const [estimatedReductionCreditTotal, setEstimatedReductionCreditTotal] = useState(0)
  const [estimatedRemoveCreditTotal, setEstimatedRemoveCreditTotal] = useState(0)
  const [estimatedTotalCreditTotal, setEstimatedTotalCreditTotal] = useState(0)
  const handleChangeNonPermanentRiskReportCheck = (event) => {
    if (event.target.value === "1") {
      setNonPermanentRiskReportCheck(true)
    } else {
      setNonPermanentRiskReportCheck(false)
    }
  }
  const handleChangeUpdateLtaCheck = (event) => {
    if (event.target.value === "1") {
      setUpdateLtaCheck(true)
    } else {
      setUpdateLtaCheck(false)
    }
  }
  const handleChangeGhgCreditCheck = (event) => {
    if (event.target.value === "1") {
      setGhgCreditCheck(true)
    } else {
      setGhgCreditCheck(false)
    }
  }
  const handleChangeProjectCreditTermStart = (newValue) => {
    setProjectCreditTermStart(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const handleChangeProjectCreditTermEnd = (newValue) => {
    setProjectCreditTermEnd(newValue);
    // dispatch(task_setTaskLimit(dayjs(newValue)));
  };
  const apiRefForProjectCreditTerm = useGridApiRef();
  const projectCreditTermColumns = [
    { field: "projectTerm", headerName: "プロジェクト期間" },
    { field: "estimatedBaselineEmissions", headerName: "推定ベースライン排出量" },
    { field: "estimatedProjectEmissions", headerName: "推定プロジェクト排出量" },
    { field: "estimatedLeakageEmissions", headerName: "推定リーケージ排出量" },
    { field: "estimatedReductionCredit", headerName: "推定削減クレジット" },
    { field: "estimatedRemoveCredit", headerName: "推定除去クレジット" },
    { field: "estimatedTotalCredit", headerName: "推定合計クレジット" },
  ];
  const addProjectCreditTermRows = () => {
    const id = (projectCreditTermRows.length === 0) ? 1 : Math.max(...projectCreditTermRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const estimatedBaselineEmissions = getValues("estimatedBaselineEmissions")
    const estimatedProjectEmissions = getValues("estimatedProjectEmissions")
    const estimatedLeakageEmissions = getValues("estimatedLeakageEmissions")
    const estimatedReductionCredit = getValues("estimatedReductionCredit")
    const estimatedRemoveCredit = getValues("estimatedRemoveCredit")
    const estimatedTotalCredit = getValues("estimatedTotalCredit")
    const projectTerm = `${dayjs(projectCreditTermStart).format("YYYY/MM/DD")}〜${dayjs(projectCreditTermEnd).format("YYYY/MM/DD")}`

    const newValue = {
      id: id,
      projectTerm: projectTerm,
      estimatedBaselineEmissions: estimatedBaselineEmissions !== "" ? estimatedBaselineEmissions : "0",
      estimatedProjectEmissions: estimatedProjectEmissions !== "" ? estimatedProjectEmissions : "0",
      estimatedLeakageEmissions: estimatedLeakageEmissions !== "" ? estimatedLeakageEmissions : "0",
      estimatedReductionCredit: estimatedReductionCredit !== "" ? estimatedReductionCredit : "0",
      estimatedRemoveCredit: estimatedRemoveCredit !== "" ? estimatedRemoveCredit : "0",
      estimatedTotalCredit: estimatedTotalCredit !== "" ? estimatedTotalCredit : "0",
    };
    setProjectCreditTermRows([...projectCreditTermRows, newValue]);
    reset({
      estimatedBaselineEmissions: "",
      estimatedProjectEmissions: "",
      estimatedLeakageEmissions: "",
      estimatedReductionCredit: "",
      estimatedRemoveCredit: "",
      estimatedTotalCredit: "",
    })
    setProjectCreditTermStart(null)
    setProjectCreditTermEnd(null)
  }

  const delProjectCreditTermRows = () => {
    const delProjectCreditTermRow = apiRefForProjectCreditTerm.current.getSelectedRows();
    if (projectCreditTermRows.length === 0 || delProjectCreditTermRow.size === 0) return;
    delProjectCreditTermRow.forEach(v => {
      apiRefForProjectCreditTerm.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newProjectCreditTermRows = apiRefForProjectCreditTerm.current.getSortedRows();
    if (newProjectCreditTermRows !== null) {
      setProjectCreditTermRows(Object.keys(newProjectCreditTermRows).length === 0 ? [] : [newProjectCreditTermRows]);
    }
  }

  const totalProjectCreditTermRows = () => {
    const allRows = apiRefForProjectCreditTerm.current.getSortedRows()
    allRows.forEach((row) => {
      setEstimatedBaselineEmissionsTotal(parseFloat(estimatedBaselineEmissionsTotal) + parseFloat(row.estimatedBaselineEmissions))
      setEstimatedProjectEmissionsTotal(parseFloat(estimatedProjectEmissionsTotal) + parseFloat(row.estimatedProjectEmissions))
      setEstimatedLeakageEmissionsTotal(parseFloat(estimatedLeakageEmissionsTotal) + parseFloat(row.estimatedLeakageEmissions))
      setEstimatedReductionCreditTotal(parseFloat(estimatedReductionCreditTotal) + parseFloat(row.estimatedReductionCredit))
      setEstimatedRemoveCreditTotal(parseFloat(estimatedRemoveCreditTotal) + parseFloat(row.estimatedRemoveCredit))
      setEstimatedTotalCreditTotal(parseFloat(estimatedTotalCreditTotal) + parseFloat(row.estimatedTotalCredit))
    })
  }

  useEffect(() => {
    if (projectCreditTermRows.length !== 0) {
      totalProjectCreditTermRows()
    }
  }, [projectCreditTermRows])

  const getEmissionsDetailRows = () => {
    if (!apiRefForProjectCreditTerm.current || Object.keys(apiRefForProjectCreditTerm.current).length === 0) return null
    const allProjectCreditTermRows = apiRefForProjectCreditTerm.current?.getSortedRows();
    if (!allProjectCreditTermRows) return null
    let estimatedDetailValues = []
    allProjectCreditTermRows.forEach((row) => {
      let value = {
        projectCreditTermStart: "",
        projectCreditTermEnd: "",
        estimatedBaselineEmissions: "",
        estimatedProjectEmissions: "",
        estimatedLeakageEmissions: "",
        estimatedReductionCredit: "",
        estimatedRemoveCredit: "",
        estimatedTotalCredit: "",
      }
      value.projectCreditTermStart = row.projectTerm.split("〜")[0]
      value.projectCreditTermEnd = row.projectTerm.split("〜")[1]
      value.estimatedBaselineEmissions = row.estimatedBaselineEmissions
      value.estimatedProjectEmissions = row.estimatedProjectEmissions
      value.estimatedLeakageEmissions = row.estimatedLeakageEmissions
      value.estimatedReductionCredit = row.estimatedReductionCredit
      value.estimatedRemoveCredit = row.estimatedRemoveCredit
      value.estimatedTotalCredit = row.estimatedTotalCredit
      estimatedDetailValues.push(value)
    })
    return estimatedDetailValues
  }

  // 9.1 検証時に利用可能なデータとパラメータ
  const [isOpenMonitoringDialog1, setIsOpenMonitoringDialog1] = React.useState(false);
  const [monitoring1Rows, setMonitoring1Rows] = React.useState([]);
  const handleOpenMonitoringDialog1 = () => {
    setIsOpenMonitoringDialog1(true);
  };
  const handleCloseMonitoringDialog1 = () => {
    setIsOpenMonitoringDialog1(false);
  };

  const apiRefForMonitoring1 = useGridApiRef();

  const monitoring1Columns = [
    { field: "monitoringDataOrParam1", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit1", headerName: "データ単位" },
    { field: "monitoringDataContents1", headerName: "データ内容" },
    { field: "monitoringDataSource1", headerName: "データソース" },
    { field: "monitoringApplicableValue1", headerName: "適用値" },
    { field: "monitoringExplain1", headerName: "データ選択の正当性、または適用した測定方法と手順の説明" },
    { field: "monitoringPurpose1", headerName: "データの目的" },
    { field: "monitoringComment1", headerName: "コメント" },
    // TODO: 詳細や編集ボタンをつける
  ];
  const addMonitoring1Rows = () => {
    const id = (monitoring1Rows.length === 0) ? 1 : Math.max(...monitoring1Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const monitoringDataOrParam1Value = getValues("monitoringDataOrParam1")
    const monitoringDataUnit1Value = getValues("monitoringDataUnit1")
    const monitoringDataContents1Value = getValues("monitoringDataContents1")
    const monitoringDataSource1Value = getValues("monitoringDataSource1")
    const monitoringApplicableValue1Value = getValues("monitoringApplicableValue1")
    const monitoringExplain1Value = getValues("monitoringExplain1")
    const monitoringPurpose1Value = getValues("monitoringPurpose1")
    const monitoringComment1Value = getValues("monitoringComment1")

    const newValue = {
      id: id,
      monitoringDataOrParam1: monitoringDataOrParam1Value,
      monitoringDataUnit1: monitoringDataUnit1Value,
      monitoringDataContents1: monitoringDataContents1Value,
      monitoringDataSource1: monitoringDataSource1Value,
      monitoringApplicableValue1: monitoringApplicableValue1Value,
      monitoringExplain1: monitoringExplain1Value,
      monitoringPurpose1: monitoringPurpose1Value,
      monitoringComment1: monitoringComment1Value,
    };
    setMonitoring1Rows([...monitoring1Rows, newValue]);
    reset({
      monitoringDataOrParam1: "",
      monitoringDataUnit1: "",
      monitoringDataContents1: "",
      monitoringDataSource1: "",
      monitoringApplicableValue1: "",
      monitoringExplain1: "",
      monitoringPurpose1: "",
      monitoringComment1: "",
    })
    handleCloseMonitoringDialog1()
  }

  const delMonitoring1Rows = () => {
    const delMonitoringRow = apiRefForMonitoring1.current.getSelectedRows();
    if (monitoring1Rows.length === 0 || delMonitoringRow.size === 0) return;
    delMonitoringRow.forEach(v => {
      apiRefForMonitoring1.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMonitoring1Rows = apiRefForMonitoring1.current.getSortedRows();
    if (newMonitoring1Rows !== null) {
      setMonitoring1Rows(Object.keys(newMonitoring1Rows).length === 0 ? [] : [newMonitoring1Rows]);
    }
  }

  const getAvailableDataRows = () => {
    if (!apiRefForMonitoring1.current || Object.keys(apiRefForMonitoring1.current).length === 0) return null
    const allMonitoring1Rows = apiRefForMonitoring1.current?.getSortedRows();
    return allMonitoring1Rows
  }

  // 9.2 モニタリングされるデータとパラメータ
  const [isOpenMonitoringDialog2, setIsOpenMonitoringDialog2] = React.useState(false);
  const [monitoring2Rows, setMonitoring2Rows] = React.useState([]);
  const handleOpenMonitoringDialog2 = () => {
    setIsOpenMonitoringDialog2(true);
  };
  const handleCloseMonitoringDialog2 = () => {
    setIsOpenMonitoringDialog2(false);
  };

  const apiRefForMonitoring2 = useGridApiRef();

  const monitoring2Columns = [
    { field: "monitoringDataOrParam2", headerName: "データ／パラメータ" },
    { field: "monitoringDataUnit2", headerName: "データ単位" },
    { field: "monitoringDataContents2", headerName: "データ内容" },
    { field: "monitoringDataSource2", headerName: "データソース" },
    { field: "monitoringExplain2", headerName: "適用される測定方法と手順の説明" },
    { field: "monitoringFrequency", headerName: "モニタリング／記録の頻度" },
    { field: "monitoringApplicableValue2", headerName: "適用値" },
    { field: "monitoringDevice", headerName: "モニタリング機器" },
    { field: "monitoringQaQcProcedure", headerName: "適用されるQA/QC手順" },
    { field: "monitoringPurpose2", headerName: "データの目的" },
    { field: "howToCalc", headerName: "計算方法" },
    { field: "monitoringComment2", headerName: "コメント" },
    // TODO: 詳細や編集ボタンをつける
  ];
  const addMonitoring2Rows = () => {
    const id = (monitoring2Rows.length === 0) ? 1 : Math.max(...monitoring2Rows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const monitoringDataOrParam2Value = getValues("monitoringDataOrParam2")
    const monitoringDataUnit2Value = getValues("monitoringDataUnit2")
    const monitoringDataContents2Value = getValues("monitoringDataContents2")
    const monitoringDataSource2Value = getValues("monitoringDataSource2")
    const monitoringExplain2Value = getValues("monitoringExplain2")
    const monitoringFrequencyValue = getValues("monitoringFrequency")
    const monitoringApplicableValue2Value = getValues("monitoringApplicableValue2")
    const monitoringDeviceValue = getValues("monitoringDevice")
    const monitoringQaQcProcedureValue = getValues("monitoringQaQcProcedure")
    const monitoringPurpose2Value = getValues("monitoringPurpose2")
    const howToCalcValue = getValues("howToCalc")
    const monitoringComment2Value = getValues("monitoringComment2")

    const newValue = {
      id: id,
      monitoringDataOrParam2: monitoringDataOrParam2Value,
      monitoringDataUnit2: monitoringDataUnit2Value,
      monitoringDataContents2: monitoringDataContents2Value,
      monitoringDataSource2: monitoringDataSource2Value,
      monitoringExplain2: monitoringExplain2Value,
      monitoringFrequency: monitoringFrequencyValue,
      monitoringApplicableValue2: monitoringApplicableValue2Value,
      monitoringDevice: monitoringDeviceValue,
      monitoringQaQcProcedure: monitoringQaQcProcedureValue,
      monitoringPurpose2: monitoringPurpose2Value,
      howToCalc: howToCalcValue,
      monitoringComment2: monitoringComment2Value,
    };
    setMonitoring2Rows([...monitoring2Rows, newValue]);
    reset({
      monitoringDataOrParam2: "",
      monitoringDataUnit2: "",
      monitoringDataContents2: "",
      monitoringDataSource2: "",
      monitoringExplain2: "",
      monitoringFrequency: "",
      monitoringApplicableValue2: "",
      monitoringDevice: "",
      monitoringQaQcProcedure: "",
      monitoringPurpose2: "",
      howToCalc: "",
      monitoringComment2: "",
    })
    handleCloseMonitoringDialog2()
  }

  const delMonitoring2Rows = () => {
    const delMonitoring2Row = apiRefForMonitoring2.current.getSelectedRows();
    if (monitoring2Rows.length === 0 || delMonitoring2Row.size === 0) return;
    delMonitoring2Row.forEach(v => {
      apiRefForMonitoring2.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newMonitoring2Rows = apiRefForMonitoring2.current.getSortedRows();
    if (newMonitoring2Rows !== null) {
      setMonitoring2Rows(Object.keys(newMonitoring2Rows).length === 0 ? [] : [newMonitoring2Rows]);
    }
  }

  const getMonitoringDataRows = () => {
    if (!apiRefForMonitoring2.current || Object.keys(apiRefForMonitoring2.current).length === 0) return null
    const allMonitoring2Rows = apiRefForMonitoring2.current?.getSortedRows();
    return allMonitoring2Rows
  }

  // 11.1 商業上の機密情報を表示するテーブルに関する変数等
  const [secretInfoRows, setSecretInfoRows] = React.useState([]);
  const apiRefForSecretInfo = useGridApiRef();
  const secretInfoColumns = [
    { field: "section", headerName: "セクション" },
    { field: "info", headerName: "情報" },
    { field: "justification", headerName: "正当化" },
  ];
  const addSecretInfoRows = () => {
    const id = (secretInfoRows.length === 0) ? 1 : Math.max(...secretInfoRows.map(v => v.id)) + 1; /* 配列内のオブジェクトidの最大値を求めて+1 */
    const secretSectionValue = getValues("secretSection")
    const secretInfoValue = getValues("secretInfo")
    const secretJustificationValue = getValues("secretJustification")
    const newValue = {
      id: id,
      section: secretSectionValue,
      info: secretInfoValue,
      justification: secretJustificationValue,
    };
    setSecretInfoRows([...secretInfoRows, newValue]);
    reset({
      secretSection: "",
      secretInfo: "",
      secretJustification: "",
    })
  }

  const delSecretInfoRows = () => {
    const delSecretInfoRow = apiRefForSecretInfo.current.getSelectedRows();
    if (secretInfoRows.length === 0 || delSecretInfoRow.size === 0) return;
    delSecretInfoRow.forEach(v => {
      apiRefForSecretInfo.current.updateRows([{ id: v.id, _action: 'delete' }]); // _action: 'delete'で指定idのデータを削除
    });
    const newSecretInfoRows = apiRefForSecretInfo.current.getSortedRows();
    if (newSecretInfoRows !== null) {
      setSecretInfoRows(Object.keys(newSecretInfoRows).length === 0 ? [] : [newSecretInfoRows]);
    }
  }

  const getSecretRows = () => {
    if (!apiRefForSecretInfo.current || Object.keys(apiRefForSecretInfo.current).length === 0) return null
    const allSecretInfoRows = apiRefForSecretInfo.current?.getSortedRows();
    return allSecretInfoRows
  }

  // select
  const theme = useTheme();
  const [option, setOption] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleJump = (n) => {
    setActiveStep(n);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // 一時保存時は各入力値の値をチェックせずに保存する
  const tmpSave = async () => {
    try {
      const reqData = {
        historyNo: 0,
        creator: {
          createdCompanyId: company._id,
          createdUserId: storedUserInfo?.value._id,
          createdCompanyText: getValues("createdCompanyText") || company.groupName,
          createdUserText: getValues("createdUserText") || storedUserInfo?.value.username,
        },
        projectName: getValues("projectName") || "",
        projectStatus: "50",
        branchId: getValues("branchId") || "656acd906325c95d0b775650",
        companyId: company._id,
        projectDesc: getValues("projectDesc") || "",
        projectCategory: projectCategory || "",
        audit: getAuditHistoryRows() || [],
        projectDesign: projectDesignCategory || "",
        actExplain: getValues("actExplain") || "",
        projectPlace: getValues("projectPlace") || "",
        proposer: {
          company: company?.groupName,
          staff: storedUserInfo?.value.username,
          role: storedUserInfo?.value.role,
          address: company?.address,
          email: storedUserInfo?.value.email,
          tel: getValues("proposerTel") || "",
        },
        refOtherCompany: getValues("refOtherCompany") || "",
        projectOwnership: getValues("projectOwnership") || "",
        projectEligibility: getValues("projectEligibility") || "",
        beforeStartingProject: getValues("beforeStartingProject") || "",
        legalCompliance: getValues("legalCompliance") || "",
        noDuplicatePublish: {
          check: duplicatePublishCheck,
          evidence: getValues("noDuplicatePublish") || "",
        },
        noDuplicateClaim: {
          check: duplicateClaimCheck,
          evidence: getValues("noDuplicateClaim") || "",
        },
        stakeholderRegulations: getValues("stakeholderRegulations") || "",
        envRisk: getValues("envRisk") || "",
        fairness: getValues("fairness") || "",
        soundnessA: {
          soundnessACheck: soundnessACheck,
          soundnessA1: getValues("soundnessA1") || "",
          soundnessA2: getValues("soundnessA2") || "",
        },
        soundnessB: {
          soundnessBCheck: soundnessBCheck,
          soundnessB1: getValues("soundnessB1") || "",
          soundnessB2: getValues("soundnessB2") || "",
        },
        soundnessC: {
          soundnessCCheck: soundnessCCheck,
          soundnessC1: getValues("soundnessC1") || "",
          soundnessC2: getValues("soundnessC2") || "",
        },
        soundnessD: {
          soundnessDCheck: soundnessDCheck,
          soundnessD1: getValues("soundnessD1") || "",
          soundnessD2: getValues("soundnessD2") || "",
        },
        sdContribution: getValues("sdContribution") || "",
        projectAddInfo: getValues("projectAddInfo") || "",
        projectStartDate: projectStartDate,
        startDateRightfulness: getValues("startDateRightfulness") || "",
        projectCreditTerm: {
          creditTerm: getValues("creditTerm") || "",
          creditStartDate: creditStartDate,
          creditEndDate: creditEndDate,
        },
        estimatedValue: getEstimatedValue1Rows() || [],
        estimatedERR: getValues("estimatedERR") || "",
        totalYears: getValues("totalYears") || "",
        aveYearlyERR: getValues("aveYearlyERR") || "",
        programs: getMethodologyRows() || [],
        programApplicability: getValues("programApplicability") || "",
        projectBoundary: getProjectBoundaryRows() || [],
        // projectBoundaryFile: projectBoundaryFileUrl, ファイルがあれば後のステップで追加される
        projectBoundaryFile: "",
        baselineScenario: getValues("baselineScenario") || "",
        additionality: getValues("additionality") || "",
        deviateProgram: getValues("deviateProgram") || "",
        baselineEmissions: getValues("baselineEmissions") || "",
        projectEmissions: getValues("projectEmissions") || "",
        leakageEmissions: getValues("leakageEmissions") || "",
        nonPermanentRiskEvaluation: getValues("nonPermanentRiskEvaluation") || "",
        nonPermanentRiskReportCheck: nonPermanentRiskReportCheck,
        projectLTA: getValues("projectLTA") || "",
        updateLta: {
          check: updateLtaCheck,
          NonUpdateLtaReason: getValues("NonUpdateLtaReason") || "",
        },
        expectGhgEmissions: getValues("expectGhgEmissions") || "",
        belowLta: {
          check: ghgCreditCheck,
          NotBelowLtaReason: getValues("NotBelowLtaReason") || "",
        },
        emissions: {
          detail: getEmissionsDetailRows() || [],
          total: {
            estimatedBaselineEmissionsTotal: estimatedBaselineEmissionsTotal || "0",
            estimatedProjectEmissionsTotal: estimatedProjectEmissionsTotal || "0",
            estimatedLeakageEmissionsTotal: estimatedLeakageEmissionsTotal || "0",
            estimatedReductionCreditTotal: estimatedReductionCreditTotal || "0",
            estimatedRemoveCreditTotal: estimatedRemoveCreditTotal || "0",
            estimatedTotalCreditTotal: estimatedTotalCreditTotal || "0",
          },
        },
        availableData: getAvailableDataRows() || [],
        monitoringData: getMonitoringDataRows() || [],
        monitoringPlan: getValues("monitoringPlan") || "",
        references: getValues("references") || "",
        secret: getSecretRows() || [],
        otherInfo: getValues("otherInfo") || "",
      }

      let projectNo = {};
      let seqNumber = 0;
      projectNo = await counterApi.upsert({ key: "projectNo" });
      seqNumber = projectNo.seq;
      reqData.projectNo = seqNumber;

      const projectBoundaryFile = getValues("projectBoundaryFile") || ""
      if (projectBoundaryFile || projectBoundaryFile.length !== 0) {
        const uploadTasks = [uploadImg(projectBoundaryFile, ["projectBoundaryFile", seqNumber])];
        const [projectBoundaryFileUrl] = await Promise.all(uploadTasks);
        reqData.projectBoundaryFile = projectBoundaryFileUrl
      }

      projectApi
        .register(reqData)
        .then(async (res) => {
          // const projectId = res.project._id;
          // return sendApplyMail(
          //   data,
          //   reqData.planDocuments,
          //   reqData.additionalDocuments,
          //   projectId
          // );
        })
        .then(() => {
          alert("一時保存しました");
          navigate("/member/projects");
        })
        .catch((e) => {
          alert("一時保存に失敗しました。運営にお問合せください。");
          console.error(e);
        });
      } catch (error) {
        alert("一時保存に失敗しました。運営にお問合せください。");
        console.error(error);
    }
  }

  return (
    <div>
      <h3>プロジェクト設計書作成</h3>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {/* step1 基本情報 */}
          <Step key={1}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(0)}
            >
              {steps[0].label}
            </StepLabel>
            <StepContent>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  {/* <Typography mb={2}>代表者情報</Typography> */}
                  <Box mt={1} mb={2}>
                    <Typography>作成会社名：</Typography>
                    <Controller
                      name="createdCompanyText"
                      control={control}
                      rules={validationRules.createdCompanyText}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          value={company.groupName}
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  {/* <Box sx={{ marginBottom: "2rem" }}> */}
                  <Box mb={2}>
                    <Typography>作成者名：</Typography>
                    <Controller
                      name="createdUserText"
                      control={control}
                      rules={validationRules.createdUserText}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          value={storedUserInfo?.value.username}
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Typography>作成支部名：</Typography>
                    <Controller
                      name="branchId"
                      control={control}
                      rules={validationRules.branchId}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth error={fieldState.invalid}>
                          <InputLabel>作成支部名</InputLabel>
                          <Select
                            {...field}
                            input={<OutlinedInput label="支部名" />}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">
                              {branches.length ? "未選択" : "支部が存在しません"}
                            </MenuItem>
                            {branches.map((item) => (
                              <MenuItem
                                key={item._id}
                                value={item._id}
                                style={getStyles(item._id, option, theme)}
                              >
                                {item.branchName}
                              </MenuItem>
                            ))}
                          </Select>
                          {fieldState.invalid && (
                            <FormHelperText>{fieldState.error?.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Box>
                  <Box mb={2}>
                    プロジェクトID：システムにより自動決定
                  </Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Controller
                      name="projectName"
                      control={control}
                      rules={validationRules.projectName}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          label="プロジェクト名"
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {0 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step2 プロジェクト概要 */}
          <Step key={2}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(1)}
            >
              {steps[1].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>2.1 プロジェクトの概要説明</Typography>
              <Typography>プロジェクトの性質とその実施を理解できるよう、プロジェクトの概要を説明してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectDesc"
                  control={control}
                  rules={validationRules.projectDesc}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectDesc"
                      label="プロジェクトの概要説明"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.2 セクターの範囲とプロジェクトの種類</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectCategory"
                  control={control}
                  // rules={validationRules.priority}
                  render={({ field, fieldState }) => (
                    <CategoryDropdown
                      categoryName="project"
                      categoryLabel=""
                      field={field}
                      fieldState={fieldState}
                      onChange={handleCategoryChange}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>2.3 監査履歴</Typography>
              <Typography>
                もしあれば、記載をしてください。<br />
                ※表内に表示されているデータのみが登録対象となります。追加ボタンの押し忘れにご注意ください。
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="auditType"
                        control={control}
                        rules={validationRules.auditType}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="監査タイプ"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={10}>
                    <Box>
                      <Controller
                        name="auditHistoryTerm"
                        control={control}
                        rules={validationRules.auditHistoryTerm}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="期間"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="auditHistoryProgram"
                        control={control}
                        rules={validationRules.auditHistoryProgram}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="プログラム"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="auditHistoryName"
                        control={control}
                        rules={validationRules.auditHistoryName}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="バリデーション／検証機関名"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <Controller
                        name="auditHistoryYears"
                        control={control}
                        rules={validationRules.auditHistoryYears}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="年数"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addAuditHistoryRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delAuditHistoryRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForAuditHistory}
                  columns={auditHistoryColumns}
                  rows={auditHistoryRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {1 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step3 */}
          <Step key={3}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(2)}
            >
              {steps[2].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.1 プロジェクトデザイン</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectDesignCategory"
                  control={control}
                  // rules={validationRules.priority}
                  render={({ field, fieldState }) => (
                    <CategoryDropdown
                      categoryName="projectDesign"
                      categoryLabel=""
                      field={field}
                      fieldState={fieldState}
                      onChange={handleProjectDesignCategoryChange}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.2 プロジェクト活動の説明</Typography>
              <Typography>
                プロジェクトの活動と、それがどのようにGHG排出削減または二酸化炭素除去を達成するかを記載してください。<br />
                プロジェクト活動の実施スケジュールを記載してください。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="actExplain"
                  control={control}
                  rules={validationRules.actExplain}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="actExplain"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.3 プロジェクト所在地</Typography>
              <Typography>測地座標を含むプロジェクトの位置と地理的境界（該当する場合）を記載してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectPlace"
                  control={control}
                  // rules={validationRules.projectPlace}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      label="プロジェクト所在地(KML)"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.4 プロジェクト提案者</Typography>
              <Typography>プロジェクト提案者の連絡先を記入してください。</Typography>
              {/* システム側から取る。
              TODO:「作成者と提案者が異なる」ボタンを作成して、修正が必要な場合はPOPUP内で編集可能とする。 */}
              <Box sx={{ marginBottom: "2rem", mt: 2 }}>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>組織名: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{company?.groupName}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>担当者名: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{storedUserInfo?.value.username}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>役職: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{storedUserInfo?.value.role}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>住所: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{company?.address}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>Eメール: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{storedUserInfo?.value.email}</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={1}>
                    <Box>電話番号: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>
                      <Controller
                        name="proposerTel"
                        control={control}
                        rules={validationRules.proposerTel}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="ハイフン無し"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.5 プロジェクトに関与するその他の団体</Typography>
              <Typography>もしあれば、プロジェクトの開発に関与した他の団体の情報を記入してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="refOtherCompany"
                  control={control}
                  rules={validationRules.refOtherCompany}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="refOtherCompany"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>3.6 所有権</Typography>
              <Typography>プロジェクトのオーナーシップの証拠を入力してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectOwnership"
                  control={control}
                  rules={validationRules.projectOwnership}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectOwnership"
                      multiline
                      rows={2}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {2 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step4 */}
          <Step key={4}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(3)}
            >
              {steps[3].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.1 プロジェクトの適格性</Typography>
              <Typography>
                プロジェクト活動がNCCCプログラムの範囲に含まれることを正当化すること。<br />
                適用される方法論がNCCCプログラムに適格であることを証明すること。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectEligibility"
                  control={control}
                  rules={validationRules.projectEligibility}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="projectEligibility"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.2 プロジェクト開始前の状況</Typography>
              <Typography>
              プロジェクト開始前の状況を記述し、その後の削減、除去、破壊を目的としたGHG排出を発生させるためにプロジェクトが実施されていないことを証明する。
              </Typography>
              {/* TODO: 画像の添付とかが必要かも */}
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="beforeStartingProject"
                  control={control}
                  rules={validationRules.beforeStartingProject}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="beforeStartingProject"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.3 法律、法令、その他の規制枠組みの遵守</Typography>
              <Typography>
              地元、地域、国のすべての関連法、法令、規制の枠組みを特定し、プロジェクトが遵守していることを証明する。
              </Typography>
              {/* TODO: 画像の添付とかが必要かも */}
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="legalCompliance"
                  control={control}
                  rules={validationRules.legalCompliance}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="legalCompliance"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.4 二重請求と他のプログラムへの参加</Typography>
              <Typography>
              プロジェクトは、他のプログラムに登録されているか、あるいはクレジットを受けたり求めたりしていますか？
              </Typography>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={duplicatePublishCheck} onChange={handleChangeDuplicatePublishCheck} value={1} />
                    }
                    label="はい"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!duplicatePublishCheck} onChange={handleChangeDuplicatePublishCheck} value={0} />
                    }
                    label="いいえ"
                  />
                </FormGroup>
              </FormControl>
              {
                duplicatePublishCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>二重発行とならないことの必要な証拠を提出すること。</Typography>
                  <Controller
                    name="noDuplicatePublish"
                    control={control}
                    rules={validationRules.noDuplicatePublish}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="noDuplicatePublish"
                        multiline
                        rows={4}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>4.5 二重請求、他の形態のクレジット、スコープ3排出量</Typography>
              <Typography>
              プロジェクト活動は、他の排出量取引プログラム、拘束力のある排出制限、または他のスコープ3プログラムに含まれますか？
              </Typography>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={duplicateClaimCheck} onChange={handleChangeDuplicateClaimCheck} value={1} />
                    }
                    label="はい"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!duplicateClaimCheck} onChange={handleChangeDuplicateClaimCheck} value={0} />
                    }
                    label="いいえ"
                  />
                </FormGroup>
              </FormControl>
              {
                duplicateClaimCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>二重請求がないことを示す必要な証拠をすべて提出すること。</Typography>
                  <Controller
                    name="noDuplicateClaim"
                    control={control}
                    rules={validationRules.noDuplicateClaim}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="noDuplicateClaim"
                        multiline
                        rows={4}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {3 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step5 */}
          <Step key={5}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(4)}
            >
              {steps[4].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.1 ステークホルダーの参画と協議</Typography>
              <Typography>
              ステークホルダーの関与と協議に関する特別な規定がある場合は、それを記載すること。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="stakeholderRegulations"
                  control={control}
                  rules={validationRules.stakeholderRegulations}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="stakeholderRegulations"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.2 ステークホルダーと環境へのリスク</Typography>
              <Typography>
              ステークホルダーや環境に対するリスクについて特別な処方がある場合は、その内容を記載すること。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="envRisk"
                  control={control}
                  rules={validationRules.envRisk}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="envRisk"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.3 人権と公平性の尊重</Typography>
              <Typography>
              人権と公平性の尊重に関する特別な処方がある場合は、その内容を記載する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="fairness"
                  control={control}
                  rules={validationRules.fairness}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="fairness"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.4 生態系の健全性</Typography>
              <Typography>
                環境に対するあらゆるリスクと、それを軽減するために講じた措置を特定し、要約する。
                {/* リスクが特定されない場合は、最初の欄に「リスクは特定されない」と記入し、2番目の欄にその理由を記入する。 */}
              </Typography>
              <Typography sx={{ mt: "1rem" }}>生物多様性と生態系への影響</Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={soundnessACheck} onChange={handleChangeSoundnessACheck} value={1} />
                    }
                    label="リスクあり"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!soundnessACheck} onChange={handleChangeSoundnessACheck} value={0} />
                    }
                    label="リスクなし"
                  />
                </FormGroup>
              </FormControl>
              {
                soundnessACheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>特定されたリスク</Typography>
                  <Controller
                    name="soundnessA1"
                    control={control}
                    rules={validationRules.soundnessA1}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="soundnessA1"
                        multiline
                        rows={2}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  {soundnessACheck ? "講じられた緩和策または予防策" : "理由"}
                </Typography>
                <Controller
                  name="soundnessA2"
                  control={control}
                  rules={validationRules.soundnessA2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundnessA2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: "1rem" }}>土壌劣化と土壌侵食</Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={soundnessBCheck} onChange={handleChangeSoundnessBCheck} value={1} />
                    }
                    label="リスクあり"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!soundnessBCheck} onChange={handleChangeSoundnessBCheck} value={0} />
                    }
                    label="リスクなし"
                  />
                </FormGroup>
              </FormControl>
              {
                soundnessBCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>特定されたリスク</Typography>
                  <Controller
                    name="soundnessB1"
                    control={control}
                    rules={validationRules.soundnessB1}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="soundnessB1"
                        multiline
                        rows={2}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  {soundnessBCheck ? "講じられた緩和策または予防策" : "理由"}
                </Typography>
                <Controller
                  name="soundnessB2"
                  control={control}
                  rules={validationRules.soundnessB2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundnessB2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: "1rem" }}>水の消費とストレス</Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={soundnessCCheck} onChange={handleChangeSoundnessCCheck} value={1} />
                    }
                    label="リスクあり"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!soundnessCCheck} onChange={handleChangeSoundnessCCheck} value={0} />
                    }
                    label="リスクなし"
                  />
                </FormGroup>
              </FormControl>
              {
                soundnessCCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>特定されたリスク</Typography>
                  <Controller
                    name="soundnessC1"
                    control={control}
                    rules={validationRules.soundnessC1}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="soundnessC1"
                        multiline
                        rows={2}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  {soundnessCCheck ? "講じられた緩和策または予防策" : "理由"}
                </Typography>
                <Controller
                  name="soundnessC2"
                  control={control}
                  rules={validationRules.soundnessC2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundnessC2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: "1rem" }}>肥料の使用</Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={soundnessDCheck} onChange={handleChangeSoundnessDCheck} value={1} />
                    }
                    label="リスクあり"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!soundnessDCheck} onChange={handleChangeSoundnessDCheck} value={0} />
                    }
                    label="リスクなし"
                  />
                </FormGroup>
              </FormControl>
              {
                soundnessDCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography>特定されたリスク</Typography>
                  <Controller
                    name="soundnessD1"
                    control={control}
                    rules={validationRules.soundnessD1}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        id="soundnessD1"
                        multiline
                        rows={2}
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  {soundnessDCheck ? "講じられた緩和策または予防策" : "理由"}
                </Typography>
                <Controller
                  name="soundnessD2"
                  control={control}
                  rules={validationRules.soundnessD2}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="soundnessD2"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.5 持続可能な開発への貢献</Typography>
              <Typography>
                持続可能な開発（SD）に貢献するプロジェクト活動の概要（実施した技術／対策、活動場所など）を含む簡単な説明を記入する
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="sdContribution"
                  control={control}
                  rules={validationRules.sdContribution}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="sdContribution"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>5.6 プロジェクトに関連する追加情報</Typography>
              <Typography>
              プロジェクトに関連する追加情報があれば記載する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectAddInfo"
                  control={control}
                  rules={validationRules.projectAddInfo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectAddInfo"
                      multiline
                      rows={4}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {4 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step6 */}
          <Step key={6}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(5)}
            >
              {steps[5].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.1 プロジェクト開始日</Typography>
              <Typography>
              日、月、年を明記し、プロジェクト開始日の正当性を示すこと。
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                  <Typography sx={{ mr: 1 }}>プロジェクト開始日</Typography>
                  <DatePicker
                    id="projectStartDate"
                    // label="プロジェクト開始日"
                    value={projectStartDate}
                    onChange={handleChangeProjectStartDate}
                    inputFormat="dd/MM/yyyy"
                    mask="__/__/____"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography>
                  妥当性<br />
                  プロジェクトの開始日が NCCC プログラムの要件にどのように適合するかを説明すること。
                </Typography>
                <Controller
                  name="startDateRightfulness"
                  control={control}
                  rules={validationRules.startDateRightfulness}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="startDateRightfulness"
                      multiline
                      rows={3}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.2 プロジェクトのクレジット期間</Typography>
              <Typography>
              開始日、終了日、合計年数について、日、月、年を指定することにより、プロジェクトのクレジット期間を示す。
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>クレジット期間： </Typography>
                <Controller
                  name="creditTerm"
                  control={control}
                  rules={validationRules.creditTerm}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="creditTerm"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>年</Typography>
              </Box>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ mr: 1 }}>初回または固定クレジット期間の開始日と終了日</Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DatePicker
                      id="creditStartDate"
                      // label="プロジェクト開始日"
                      value={creditStartDate}
                      onChange={handleChangeCreditStartDate}
                      inputFormat="dd/MM/yyyy"
                      mask="__/__/____"
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                    <DatePicker
                      id="creditEndDate"
                      // label="プロジェクト開始日"
                      value={creditEndDate}
                      onChange={handleChangeCreditEndDate}
                      inputFormat="dd/MM/yyyy"
                      mask="__/__/____"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </Box>
              </LocalizationProvider>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>6.3 プロジェクト規模とGHG排出削減量または除去量の推定値</Typography>
              <Typography>
              初回（更新可能な場合）または固定クレジット期間について記入する
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                      <Box sx={{ marginBottom: "1rem" }}>
                        <Typography sx={{ mr: 1 }}>クレジット期間の暦年</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <DatePicker
                            id="creditTimeStart"
                            // label="プロジェクト開始日"
                            value={creditTimeStart}
                            onChange={handleChangeCreditTimeStart}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                          <DatePicker
                            id="creditTimeEnd"
                            // label="プロジェクト開始日"
                            value={creditTimeEnd}
                            onChange={handleChangeCreditTimeEnd}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={11} lg={6}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>GHG排出削減量または除去量の推定値（tCO2e）</Typography>
                      <Controller
                        name="estimatedValue1"
                        control={control}
                        rules={validationRules.estimatedValue1}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={1} lg={1}>
                    <Box sx={{ marginTop: "2rem" }}>
                      <Button variant="contained" onClick={addEstimatedValue1Rows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delEstimatedValue1Rows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForEstimatedValue1}
                  columns={estimatedValue1Columns}
                  rows={estimatedValue1Rows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>初回または固定クレジット期間中の推定ERR： </Typography>
                <Controller
                  name="estimatedERR"
                  control={control}
                  rules={validationRules.estimatedERR}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="estimatedERR"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>合計年数： </Typography>
                <Controller
                  name="totalYears"
                  control={control}
                  rules={validationRules.totalYears}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="totalYears"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>年</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>平均年間ERR： </Typography>
                <Controller
                  name="aveYearlyERR"
                  control={control}
                  rules={validationRules.aveYearlyERR}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="aveYearlyERR"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {5 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step7 */}
          <Step key={7}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(6)}
            >
              {steps[6].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.1 方法論のタイトルと参考文献</Typography>
              <Typography>
              プロジェクトに適用された方法論、ツール、モジュール（該当する場合）について、以下の情報のタイトル、参照先、バージョン番号を記入すること。
              </Typography>
              <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={methodologyCheck} onChange={handleChangeMethodologyCheck} />
                    }
                    label="NCCCの方法論以外を使用する"
                  />
                </FormGroup>
              </FormControl>
              {
                methodologyCheck
                ? <Box sx={{ mt: 2, mb: 3 }}>
                  <Grid container spacing={4}>
                    <Grid xs={12} lg={2}>
                      <Box>
                        <Controller
                          name="methodologyType"
                          control={control}
                          // rules={validationRules.methodologyType}
                          render={({ field, fieldState }) => (
                            <CategoryDropdown
                              categoryName="methodologyType"
                              categoryLabel="タイプ"
                              field={field}
                              fieldState={fieldState}
                              onChange={handleMethodologyTypeChange}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={4}>
                      <Box>
                        <Controller
                          name="methodologyRefId"
                          control={control}
                          rules={validationRules.methodologyRefId}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="参照ID"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={7}>
                      <Box>
                        <Controller
                          name="methodologyTitle"
                          control={control}
                          rules={validationRules.methodologyTitle}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="タイトル"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={2}>
                      <Box>
                        <Controller
                          name="methodologyVersion"
                          control={control}
                          rules={validationRules.methodologyVersion}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              // required
                              fullWidth
                              label="Version"
                              type="text"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={1}>
                      <Box>
                        <Button variant="contained" onClick={addMethodologyRows}>追加</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                : <Box sx={{ marginBottom: "2rem" }}>
                  <Grid container>
                    <Grid xs={12} lg={11}>
                      <Controller
                        name="program"
                        control={control}
                        // rules={validationRules.program}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth error={fieldState.invalid}>
                            <InputLabel>適応するプログラム*</InputLabel>
                            <Select
                              {...field}
                              input={<OutlinedInput label="適応するプログラム" />}
                              MenuProps={MenuProps}
                            >
                              {programs.map((item) => (
                                <MenuItem
                                  key={item._id}
                                  value={item._id}
                                  style={getStyles(item._id, option, theme)}
                                >
                                  {item.programName}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState.invalid && (
                              <FormHelperText>{fieldState.error?.message}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid xs={12} lg={1}>
                      <Box sx={{ ml: 2 }}>
                        <Button variant="contained" onClick={addNcccMethodologyRows}>追加</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              }
              <Box sx={{ marginBottom: "2rem" }}>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                    <Button variant="contained" onClick={delMethodologyRows}>削除</Button>
                  </Box>
                <DataGrid
                  apiRef={apiRefForMethodology}
                  columns={methodologyColumns}
                  rows={methodologyRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.2 方法論の適用性</Typography>
              <Typography>
              プロジェクトの活動が、プロジェクトが適用する方法論、ツール、モジュール（該当する場合）の各適用条件をどのように満たしているかを実証し、正当化すること。各適用条件を個別に取り上げてください。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="programApplicability"
                  control={control}
                  rules={validationRules.programApplicability}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="programApplicability"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.3 プロジェクト境界</Typography>
              <Typography>
              プロジェクトのバウンダリーを定義し、プロジェクト及びベースラインシナリオに関連するGHG発生源、吸収源、貯留層を特定する（該当する場合はリークも含む）。
              {/* 必要に応じて行を追加する。 */}
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="baselineOrSource"
                        control={control}
                        rules={validationRules.baselineOrSource}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="ベースライン／ソース"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="gas"
                        control={control}
                        // rules={validationRules.gas}
                        render={({ field, fieldState }) => (
                          <CategoryDropdown
                            categoryName="gas"
                            categoryLabel="Gas"
                            field={field}
                            fieldState={fieldState}
                            onChange={handleGasCategoryChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="gasInclude"
                        control={control}
                        rules={validationRules.gasInclude}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="含まれる？"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                    {/* <Box>
                      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox checked={methodologyCheck} onChange={handleChangeMethodologyCheck} />
                            }
                            label="含まれる?"
                          />
                        </FormGroup>
                      </FormControl>
                    </Box> */}
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="boundaryExplain"
                        control={control}
                        rules={validationRules.boundaryExplain}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="正当性／説明"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addProjectBoundaryRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delProjectBoundaryRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForProjectBoundary}
                  columns={projectBoundaryColumns}
                  rows={projectBoundaryRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Typography>
              上記プロジェクト活動の説明に記載された説明に基づき、プロジェクト活動の一環として行われる様々な設置または管理活動の物理的位置を明確に示す、プロジェクト境界の図または地図を提出する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectBoundaryFile"
                  control={control}
                  rules={validationRules.projectBoundaryFile}
                  render={({ field, fieldState }) => (
                    <MuiFileInput
                      type="file"
                      fullWidth
                      {...field}
                      error={fieldState.invalid}
                      // helperText={
                      //   fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                      // }
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.4 ベースライン・シナリオ</Typography>
              <Typography>
              適用される方法論及び関連するツールに定められた手順に従って、ベースラインシナリオを特定し、正当化する。適用される方法論の手順が複数のステップを含む場合は、各ステップの適用方法を記述し、各ステップの結果を明確に文書化する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="baselineScenario"
                  control={control}
                  rules={validationRules.baselineScenario}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="baselineScenario"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.5 追加性</Typography>
              <Typography>
              適用された方法論および関連するツールに従い、プロジェクトの追加性を実証し、評価する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="additionality"
                  control={control}
                  rules={validationRules.additionality}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="additionality"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>7.6 方法論の逸脱</Typography>
              <Typography>
                適用された方法論の逸脱（過去の逸脱を含む）について説明し、その正当性を示すこと。以下を実証する証拠を含めること：<br />
                •	この乖離は、GHG排出削減・除去の定量化の保守性に悪影響を与えない。<br />
                •	この逸脱は、モニタリングまたは測定の基準と手順のみに関連し、方法論の他のいかなる部分にも関連しない。<br />
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="deviateProgram"
                  control={control}
                  rules={validationRules.deviateProgram}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="deviateProgram"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {6 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step8 */}
          <Step key={8}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(7)}
            >
              {steps[7].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>8.1 ベースライン排出量</Typography>
              <Typography>
                ベースライン排出量及び/又は炭素蓄積量の変化の定量化の手順を、適用された方法論に従って記述する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="baselineEmissions"
                  control={control}
                  rules={validationRules.baselineEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="baselineEmissions"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>8.2 プロジェクト排出量</Typography>
              <Typography>
                適用される方法論に従って、プロジェクトの排出量及び／又は炭素蓄積量の変化を定量化する手順を記述する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="projectEmissions"
                  control={control}
                  rules={validationRules.projectEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="projectEmissions"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>8.3 リーケージ排出量</Typography>
              <Typography>
                適用される方法論に従って、漏洩排出量を定量化する手順を記述する。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="leakageEmissions"
                  control={control}
                  rules={validationRules.leakageEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="leakageEmissions"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>8.4 GHG排出削減量と二酸化炭素除去量の推定値</Typography>
              <Typography>
                推定される温室効果ガス排出削減量（削減量）と二酸化炭素除去量（除去量）の定量化の手順を記述する。すべての関連式を含む。
              </Typography>
              <Typography>
                以下の、要求された情報を提供してください。
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>非永続的リスク評価(%)： </Typography>
                <Controller
                  name="nonPermanentRiskEvaluation"
                  control={control}
                  rules={validationRules.nonPermanentRiskEvaluation}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="nonPermanentRiskEvaluation"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ mr: 1 }}>％</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>非永続的リスク報告書は付録または別文書として添付されていますか？： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={nonPermanentRiskReportCheck} onChange={handleChangeNonPermanentRiskReportCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!nonPermanentRiskReportCheck} onChange={handleChangeNonPermanentRiskReportCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Typography sx={{ mr: 1 }}>伐採を伴うARR(植林、再植林、緑化)およびIFM(改善された森林管理)プロジェクトについては、長期平均(LTA)をtCO2e単位で記載する： </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                <Controller
                  name="projectLTA"
                  control={control}
                  rules={validationRules.projectLTA}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="projectLTA"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ ml: 1 }}>tCO2e</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>該当する場合、モニタリングデータに基づいてLTAを更新したか： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={updateLtaCheck} onChange={handleChangeUpdateLtaCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!updateLtaCheck} onChange={handleChangeUpdateLtaCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              {
                !updateLtaCheck &&
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography sx={{ mr: 1 }}>モニタリングデータに基づいて更新しなかった理由： </Typography>
                  <Controller
                    name="NonUpdateLtaReason"
                    control={control}
                    rules={validationRules.NonUpdateLtaReason}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        multiline
                        rows={3}
                        id="NonUpdateLtaReason"
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Typography sx={{ mr: 1 }}>現在までに期待される温室効果ガス排出総量をtCO2e単位で明記する： </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                <Controller
                  name="expectGhgEmissions"
                  control={control}
                  rules={validationRules.expectGhgEmissions}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      maxWidth="100px"
                      id="expectGhgEmissions"
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography sx={{ ml: 1 }}>tCO2e</Typography>
              </Box>
              <Box sx={{ marginBottom: "1rem", mt: 3 }}>
                <Typography sx={{ mr: 1 }}>発行されたGHGクレジットの数がLTAを下回っているか： </Typography>
                <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={ghgCreditCheck} onChange={handleChangeGhgCreditCheck} value={1} />
                      }
                      label="はい"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={!ghgCreditCheck} onChange={handleChangeGhgCreditCheck} value={0} />
                      }
                      label="いいえ"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              {
                !ghgCreditCheck &&
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ mr: 1 }}>発行されたGHGクレジットの数がLTAを下回らない理由： </Typography>
                  <Controller
                    name="NotBelowLtaReason"
                    control={control}
                    rules={validationRules.NotBelowLtaReason}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        // required
                        fullWidth
                        multiline
                        rows={3}
                        id="NotBelowLtaReason"
                        type="text"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              }
              <Typography>
                プロジェクトのクレジット期間について、記入してください。
              </Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                      <Box sx={{ marginBottom: "1rem" }}>
                        <Typography sx={{ mr: 1 }}>クレジット期間</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <DatePicker
                            id="projectCreditTermStart"
                            // label="プロジェクト開始日"
                            value={projectCreditTermStart}
                            onChange={handleChangeProjectCreditTermStart}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <Typography sx={{ ml: 1, mr: 1 }}>〜</Typography>
                          <DatePicker
                            id="projectCreditTermEnd"
                            // label="プロジェクト終了日"
                            value={projectCreditTermEnd}
                            onChange={handleChangeProjectCreditTermEnd}
                            inputFormat="dd/MM/yyyy"
                            mask="__/__/____"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定ベースライン排出量（tCO2e）</Typography>
                      <Controller
                        name="estimatedBaselineEmissions"
                        control={control}
                        rules={validationRules.estimatedBaselineEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定プロジェクト排出量（tCO2e）</Typography>
                      <Controller
                        name="estimatedProjectEmissions"
                        control={control}
                        rules={validationRules.estimatedProjectEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定リーケージ排出量（tCO2e）</Typography>
                      <Controller
                        name="estimatedLeakageEmissions"
                        control={control}
                        rules={validationRules.estimatedLeakageEmissions}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定削減クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedReductionCredit"
                        control={control}
                        rules={validationRules.estimatedReductionCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定除去クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedRemoveCredit"
                        control={control}
                        rules={validationRules.estimatedRemoveCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={11} lg={3}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>推定合計クレジット（tCO2e）</Typography>
                      <Controller
                        name="estimatedTotalCredit"
                        control={control}
                        rules={validationRules.estimatedTotalCredit}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label=""
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={1} lg={1}>
                    <Box sx={{ marginTop: "2rem" }}>
                      <Button variant="contained" onClick={addProjectCreditTermRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delProjectCreditTermRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForProjectCreditTerm}
                  columns={projectCreditTermColumns}
                  rows={projectCreditTermRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Box sx={{ marginBottom: "2rem", mt: 2 }}>
                <Typography sx={{ mr: 1 }}>合計</Typography>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定ベースライン排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedBaselineEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定プロジェクト排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedProjectEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定リーケージ排出量: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedLeakageEmissionsTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定削減クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedReductionCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定除去クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedRemoveCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid items xs={4} lg={2}>
                    <Box>推定合計クレジット: </Box>
                  </Grid>
                  <Grid items xs={8} lg={5}>
                    <Box>{estimatedTotalCreditTotal} (tCO2e)</Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {7 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step9 モニタリング */}
          <Step key={9}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(8)}
            >
              {steps[8].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ mt: 1, fontWeight: "bold" }}>9.1 検証時に利用可能なデータとパラメータ</Typography>
              <Typography>
              検証時（バリデーション時）に決定された、または入手可能で、プロジェクトのクレジット対象期間を通じて固定されているすべてのデータおよびパラメータについて、以下の表に追加する。
              </Typography>
              <Box sx={{display: "flex", alignItems: "center" }}>
                <Box sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={handleOpenMonitoringDialog1}>追加</Button>
                </Box>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delMonitoring1Rows}>削除</Button>
                </Box>
              </Box>
              <DataGrid
                apiRef={apiRefForMonitoring1}
                columns={monitoring1Columns}
                rows={monitoring1Rows}
                autoHeight
                checkboxSelection
              />
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>9.2 モニタリングされるデータとパラメータ</Typography>
              <Typography>
              プロジェクトのクレジット対象期間中にモニタリングされるすべてのデータとパラメータについて、以下の表に記入する（各データ／パラメータについて、必要に応じて表をコピーする）。提供された値は、プロジェクトのクレジット対象期間における削減量と除去量の推定値を定量化するために使用されます。
              </Typography>
              <Box sx={{display: "flex", alignItems: "center" }}>
                <Box sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={handleOpenMonitoringDialog2}>追加</Button>
                </Box>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delMonitoring2Rows}>削除</Button>
                </Box>
              </Box>
              <DataGrid
                apiRef={apiRefForMonitoring2}
                columns={monitoring2Columns}
                rows={monitoring2Rows}
                autoHeight
                checkboxSelection
              />
              <Typography sx={{ mt: 3, fontWeight: "bold" }}>9.3 モニタリング計画</Typography>
              <Typography>
                上記セクション9.2（モニタリングされるデータ及びパラメータ）に記載されたモニタリングされるデータ及びパラメータの取得、記録、集計及び分析のためのプロセス及びスケジュールを記載すること。以下の詳細を含む：<br />
                •	モニタリングされたデータ及びパラメータの測定、記録、保存、集計、照合及び報告の方法。関連する場合、モニタリング機器の校正手順を含む。<br />
                •	モニタリング活動を実施する職員の組織構造、責任、能力。<br />
                •	内部監査およびQA/QCの手順。<br />
                •	検証されたモニタリング計画に対する不適合の処理手順。<br />
                •	目標精度レベル、サンプルサイズ、サンプル採取場所、層別化、測定頻度、QA/QC 手順など、使用されるサンプリング手法。<br />
                適切な場合には、GHGデータ収集・管理システムを表示するための線図を含める。
              </Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="monitoringPlan"
                  control={control}
                  rules={validationRules.monitoringPlan}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      // required
                      fullWidth
                      id="monitoringPlan"
                      multiline
                      rows={5}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {8 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step10 */}
          <Step key={10}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(9)}
            >
              {steps[9].label}
            </StepLabel>
            <StepContent>
              <Typography>参考文献がある場合は、そのリストを提出してください。</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="references"
                  control={control}
                  rules={validationRules.references}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="references"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {9 === steps.length - 1 ? '完了' : '次へ'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          {/* step11 */}
          <Step key={11}>
            <StepLabel
              optional={null}
              onClick={() => handleJump(10)}
            >
              {steps[10].label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>11.1 商業上の機密情報</Typography>
              <Typography>プロジェクトの説明文に含まれる商業上機微な情報な情報のうち、一般公開版では除外するものについて説明してください。</Typography>
              <Box sx={{ mt: 2, mb: 3 }}>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={2}>
                    <Box>
                      <Controller
                        name="secretSection"
                        control={control}
                        rules={validationRules.secretSection}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="セクション"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <Box>
                      <Controller
                        name="secretInfo"
                        control={control}
                        rules={validationRules.secretInfo}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="情報"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={4}>
                    <Box>
                      <Controller
                        name="secretJustification"
                        control={control}
                        rules={validationRules.secretJustification}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            // required
                            fullWidth
                            label="正当化"
                            type="text"
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} lg={1}>
                    <Box>
                      <Button variant="contained" onClick={addSecretInfoRows}>追加</Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                  <Button variant="contained" onClick={delSecretInfoRows}>削除</Button>
                </Box>
                <DataGrid
                  apiRef={apiRefForSecretInfo}
                  columns={secretInfoColumns}
                  rows={secretInfoRows}
                  autoHeight
                  checkboxSelection
                />
              </Box>
              <Typography sx={{ fontWeight: "bold" }}>11.2 その他・補足事項</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="otherInfo"
                  control={control}
                  rules={validationRules.otherInfo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      id="otherInfo"
                      label="その他・補足事項等"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={onSubmit}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    申請
                  </Button>
                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={tmpSave}
                    sx={{ mt: 1, mr: 1, bgcolor: "#f0e68c" }}
                  >
                    一時保存
                  </Button>
                  <Button
                    disabled={false}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    戻る
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
              {/*

              
              <h4>プロジェクトの適格性</h4>
              <div>所有権</div>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="branchId"
                  control={control}
                  rules={validationRules.branchId}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth error={fieldState.invalid}>
                      <InputLabel>支部名*</InputLabel>
                      <Select
                        {...field}
                        input={<OutlinedInput label="支部名" />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          {branches.length ? "未選択" : "支部が存在しません"}
                        </MenuItem>
                        {branches.map((item) => (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                            style={getStyles(item._id, option, theme)}
                          >
                            {item.branchName}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.invalid && (
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
              <Typography mb={2}>実施期間*</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="startDate"
                  control={control}
                  rules={validationRules.startDate}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                        <DatePicker
                          label="開始日"
                          views={["year", "month", "day"]}
                          format="YYYY/MM/DD"
                          onChange={(event) => {
                            onChange(event);
                          }}
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            display: "block",
                            color: "#f44336",
                            margin: "3px 14px 0",
                          }}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="endDate"
                  control={control}
                  rules={validationRules.endDate}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                        <DatePicker
                          label="終了日"
                          views={["year", "month", "day"]}
                          format="YYYY/MM/DD"
                          onChange={(event) => {
                            onChange(event);
                          }}
                          renderInput={(params) => {
                            return <TextField {...params} />;
                          }}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            display: "block",
                            color: "#f44336",
                            margin: "3px 14px 0",
                          }}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Grid>
            <Grid xs={12} lg={4}>
              <Typography mb={2}>プロジェクト情報</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="programId"
                  control={control}
                  rules={validationRules.programId}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth error={fieldState.invalid}>
                      <InputLabel>適応するプログラム*</InputLabel>
                      <Select
                        {...field}
                        input={<OutlinedInput label="適応するプログラム" />}
                        MenuProps={MenuProps}
                      >
                        {programs.map((item) => (
                          <MenuItem
                            key={item._id}
                            value={item._id}
                            style={getStyles(item._id, option, theme)}
                          >
                            {item.programName}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.invalid && (
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="suitabilityReason"
                  control={control}
                  // rules={validationRules.suitabilityReason}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      id="suitabilityReason"
                      label="プログラム適合理由"
                      multiline
                      rows={6}
                      type="text"
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid xs={12} lg={4}>
              <Typography mb={2}>プロジェクト見積書類</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="planDocuments"
                  control={control}
                  rules={validationRules.planDocuments}
                  render={({ field, fieldState }) => (
                    <MuiFileInput
                      type="file"
                      fullWidth
                      {...field}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                      }
                    />
                  )}
                />
              </Box>
              <Typography mb={2}>追加書類（契約書、権利書等）</Typography>
              <Box sx={{ marginBottom: "2rem" }}>
                <Controller
                  name="additionalDocuments"
                  control={control}
                  rules={validationRules.additionalDocuments}
                  render={({ field, fieldState }) => (
                    <MuiFileInput
                      type="file"
                      fullWidth
                      {...field}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                      }
                    />
                  )}
                />
              </Box>
              <Button
                // disabled={!formState.isDirty || !formState.isValid}
                variant="contained"
                fullWidth
                sx={{ color: "#fff" }}
                type="submit"
              >
                申請
              </Button>
            </Grid>
          </Grid> */}
        </Stepper>
        {/* 9.1 検証時に利用可能なデータとパラメータ 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog
          maxWidth="lg"
          open={isOpenMonitoringDialog1}
          onClose={handleCloseMonitoringDialog1}
        >
          <DialogTitle>検証時に利用可能なデータとパラメータ</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              検証時（バリデーション時）に決定された、または入手可能で、プロジェクトのクレジット対象期間を通じて固定されているすべてのデータおよびパラメータについて入力してください。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>データ／パラメータ</Typography>
                      <Controller
                      name="monitoringDataOrParam1"
                      control={control}
                      rules={validationRules.monitoringDataOrParam1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ単位</Typography>
                      <Tooltip placement="top-start" title="単位を示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataUnit1"
                      control={control}
                      rules={validationRules.monitoringDataUnit1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ内容</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータの簡単な説明を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataContents1"
                      control={control}
                      rules={validationRules.monitoringDataContents1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データソース</Typography>
                      <Tooltip placement="top-start" title="データソースを示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataSource1"
                      control={control}
                      rules={validationRules.monitoringDataSource1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用値</Typography>
                      <Tooltip placement="top-start" title="適用される値を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringApplicableValue1"
                      control={control}
                      rules={validationRules.monitoringApplicableValue1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ選択の正当性、または適用した測定方法と手順の説明</Typography>
                      <Tooltip placement="top-start" title="データ源の選択を正当化し、必要に応じて参考文献を示す。値が測定に基づく場合は、適用された測定方法と手順（例えば、どのような基準またはプロトコルに従ったのか）の説明を含め、測定を行った責任者／団体、測定日、測定結果を示す。付録として、より詳細な情報を記載することもできる。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringExplain1"
                      control={control}
                      rules={validationRules.monitoringExplain1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={4}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データの目的</Typography>
                      <Tooltip
                        placement="top-start"
                        title={
                          <>
                            以下のいずれかを示す：<br />
                            •	ベースラインシナリオの決定（AFOLUプロジェクトのみ）<br />
                            •	ベースライン排出量の算定<br />
                            •	プロジェクト排出量の算定<br />
                            •	リーケージの算定
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringPurpose1"
                      control={control}
                      rules={validationRules.monitoringPurpose1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>コメント</Typography>
                      <Tooltip placement="top-start" title="その他コメントがあればご記入ください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringComment1"
                      control={control}
                      rules={validationRules.monitoringComment1}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseMonitoringDialog1}>戻る</Button>
            <Button
              onClick={addMonitoring1Rows}
              // disabled={true}
            >
              追加
            </Button>
          </DialogActions>
        </Dialog>
        {/* 9.2 モニタリングされるデータとパラメータ 追加・編集ダイアログ */}
        {/* TODO: 編集できるようにしたい */}
        <Dialog
          maxWidth="lg"
          open={isOpenMonitoringDialog2}
          onClose={handleCloseMonitoringDialog2}
        >
          <DialogTitle>モニタリングされるデータとパラメータ</DialogTitle>
          <DialogContent
            sx={{ bgcolor: "white" }}
          >
            <DialogContentText>
              <br />
              プロジェクトのクレジット対象期間中にモニタリングされるすべてのデータとパラメータについて入力してください。<br />
              提供された値は、プロジェクトのクレジット対象期間における削減量と除去量の推定値を定量化するために使用されます。<br />
            </DialogContentText>
            <Box sx={{ mt: 2, mb: 3 }}>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography sx={{ mr: 1 }}>データ／パラメータ</Typography>
                      <Controller
                      name="monitoringDataOrParam2"
                      control={control}
                      rules={validationRules.monitoringDataOrParam2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    </Box>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={4}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ単位</Typography>
                      <Tooltip placement="top-start" title="測定単位を示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataUnit2"
                      control={control}
                      rules={validationRules.monitoringDataUnit2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データ内容</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータの簡単な説明を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataContents2"
                      control={control}
                      rules={validationRules.monitoringDataContents2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データソース</Typography>
                      <Tooltip placement="top-start" title="データソースを示す">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDataSource2"
                      control={control}
                      rules={validationRules.monitoringDataSource2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用される測定方法と手順の説明</Typography>
                      <Tooltip placement="top-start" title="測定方法及び手順、従うべき標準又はプロトコル、及び測定に責任を有する人／組織を明記する。測定の精度に関する関連情報（例えば、計器装置または実験室試験に関連する精度）を含む。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringExplain2"
                      control={control}
                      rules={validationRules.monitoringExplain2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>モニタリング／記録の頻度</Typography>
                      <Tooltip placement="top-start" title="測定と記録の頻度を指定する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringFrequency"
                      control={control}
                      rules={validationRules.monitoringFrequency}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用値</Typography>
                      <Tooltip placement="top-start" title="適用される値を記入する">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringApplicableValue2"
                      control={control}
                      rules={validationRules.monitoringApplicableValue2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>モニタリング機器</Typography>
                      <Tooltip placement="top-start" title="データ／パラメータをモニターするために使用される機器を特定すること。必要に応じて、機器のタイプ、精度クラス、シリアル番号を含む">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringDevice"
                      control={control}
                      rules={validationRules.monitoringDevice}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>適用されるQA/QC手順</Typography>
                      <Tooltip placement="top-start" title="適用される品質保証及び品質管理（QA/QC）手順（該当する場合は校正手順を含む）を記載すること。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringQaQcProcedure"
                      control={control}
                      rules={validationRules.monitoringQaQcProcedure}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>データの目的</Typography>
                      <Tooltip
                        placement="top-start"
                        title={
                          <>
                            以下のいずれかを示すこと：<br />
                            •	ベースライン排出量の計算<br />
                            •	プロジェクト排出量の計算<br />
                            •	リーケージの計算
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringPurpose2"
                      control={control}
                      rules={validationRules.monitoringPurpose2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={3}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>計算方法</Typography>
                      <Tooltip placement="top-start" title="該当する場合、データ／パラメータを確定するために使用した計算方法（方程式を含む）を提示すること。">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="howToCalc"
                      control={control}
                      rules={validationRules.howToCalc}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          multiline
                          rows={5}
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} lg={12}>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1 }}>コメント</Typography>
                      <Tooltip placement="top-start" title="その他コメントがあればご記入ください">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Box>
                    <Controller
                      name="monitoringComment2"
                      control={control}
                      rules={validationRules.monitoringComment2}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          // required
                          fullWidth
                          label=""
                          type="text"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "white" }}
          >
            <Button onClick={handleCloseMonitoringDialog2}>戻る</Button>
            <Button
              onClick={addMonitoring2Rows}
              // disabled={true}
            >
              追加
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};
