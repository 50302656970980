import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEstimationModalOpen: false,
  isChargeApplicationModalOpen: false,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    project_setIsEstimateModalOpen: (state, action) => {
      state.isEstimationModalOpen = action.payload;
    },
    project_setInitialState: (state, action) => {
      state = {...initialState}
    },
    project_setIsChargeApplicationModalOpen: (state, action) => {
      state.isChargeApplicationModalOpen = action.payload;
    },
  },
});

export const {
  project_setIsEstimateModalOpen,
  project_setInitialState,
  project_setIsChargeApplicationModalOpen
} = projectSlice.actions;
export default projectSlice.reducer;