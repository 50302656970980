import axiosClient from "./axiosClient";

const proposalApi = {
  create: (params) => axiosClient.post("proposal/create", params),
  updateStatus: (params) => axiosClient.post("proposal/updateStatus", params),
  update: (params) => axiosClient.post("proposal/update", params),
  getById: (params) => axiosClient.post("proposal/getById", params),
  getList: (params) => axiosClient.get("proposal/getList", params),
  getByStatus: (params) => axiosClient.post("proposal/getByStatus", params),
  getByFromId: (params) => axiosClient.post("proposal/getByFromId", params),
  getByMembership: (params) => axiosClient.post("proposal/getByMembership", params),
  aggregateProposalWithChat: (params) => axiosClient.post("proposal/aggregateProposalWithChat", params),
};

export default proposalApi;
