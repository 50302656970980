import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Drawer, List, ListItemButton, Typography } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EventIcon from "@mui/icons-material/Event";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Collapse from "@mui/material/Collapse";
import { IconButton } from "@mui/material";
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUpdateBadgeFlg } from "../../redux/features/sidebarSlice";
import logo from "../../assets/imgs/NCCC_logo_symbol.png";
import HomeIcon from "@mui/icons-material/Home";
import Badge from "@mui/material/Badge";
import { useAuthUserContext, RoleType } from "../../providers/auth";
import examinationApi from "../../api/examinationApi";
import authApi from "../../api/authApi";
import memberApi from "../../api/member";
import branchApi from "../../api/branchApi";
import chatApi from "../../api/chatApi";
import userApi from "../../api/userApi";
import proposalApi from "../../api/proposalApi";
import programApi from "../../api/programApi";
import projectApi from "../../api/projectApi";
import branchUserApi from "../../api/branchUserApi";
import actWorkApi from "../../api/actWorkApi";
import MenuIcon from "@mui/icons-material/Menu";
import NCCClogo from "../../assets/imgs/NCCC_logo_symbol.png";
import TaskIcon from '@mui/icons-material/Task';


const MENU_NUMBER = 8;

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user.value);
  const sidebarBadgeUpdateFlg = useSelector((state) => state.sidebar.updateBadgeFlg);
  const ary = [...Array(MENU_NUMBER)].map(() => false);
  const [isOpenMenu, setIsOpenMenu] = React.useState(ary);
  const [isNotice, setIsNotice] = React.useState(false);
  const [isProposalNotice, setIsProposalNotice] = React.useState(false);
  const [isMemberNotice, setIsMemberNotice] = React.useState(false);
  const [isProgramNotice, setIsProgramNotice] = React.useState(false);
  const [isBranchNotice, setIsBranchNotice] = React.useState(false);
  const [isProjectNotice, setIsProjectNotice] = React.useState(false);
  const [isBranchJoinNotice, setIsBranchJoinNotice] = React.useState(false);
  const [isProjectJoinNotice, setIsProjectJoinNotice] = React.useState(false);
  const [hasBranchMaster, setHasBranchMaster] = React.useState(false);
  const storedUserInfo = useSelector((state) => state.user);
  const toggleMenu = (num) => {
    setIsOpenMenu((prevState) => prevState.map((value, index) => (index === num ? !value : value)));
  };
  const [isOpen, setIsOpen] = useState(false);

  const authUser = useAuthUserContext();

  const logout = () => {
    // console.log("logout");
    localStorage.removeItem("token");
    authUser.signout(() => {
      navigate("/member/login", { replace: true });
    });
    // navigate("/login");
  };

  const checkMemberNotice = async () => {
    const fullMemberList = await userApi.getList();
    let approvalMemberIdList = [];
    fullMemberList.users.forEach((member) => {
      if (member.companyStatus === 3) {
        approvalMemberIdList.push(member.companyId);
      }
    });
    const checkedMemberList = await memberApi.getMemberCheckLog({
      operateUserId: user._id,
    });
    const checkedCompanyIds = checkedMemberList.memberCheckLogList.map(
      (memberCheckLog) => memberCheckLog.checkedCompanyId
    );
    let uncheckedList = 0;
    for (let i = 0; i < approvalMemberIdList.length; i++) {
      if (!checkedCompanyIds.includes(approvalMemberIdList[i])) {
        uncheckedList++;
      }
    }
    setIsMemberNotice(approvalMemberIdList.length !== 0 && uncheckedList > 0);
    dispatch(setUpdateBadgeFlg(false));
  };

  const checkProgramNotice = async () => {
    const fullProgramList = await programApi.getList({
      params: { applyflg: 3 },
    });
    const approvalProgramList = fullProgramList.programs.map((program) => program._id);
    const checkedProgramList = await programApi.getProgramCheckLog({
      params: { operateUserId: storedUserInfo.value._id, isExamined: false },
    });
    setIsProgramNotice(
      approvalProgramList.length !== 0 &&
        approvalProgramList.length !== checkedProgramList.programCheckLogList.length
    );
    dispatch(setUpdateBadgeFlg(false));
  };

  const checkBranchNotice = async () => {
    const fullBranchList = await branchApi.getList({
      params: { applyflg: 0 },
    });
    const approvalBranchList = fullBranchList.branchs.map((branch) => branch._id);
    setIsBranchNotice(
      approvalBranchList.length !== 0 && RoleType.BoardOfDirectors === authUser?.roleType
    );
    dispatch(setUpdateBadgeFlg(false));
  };

  const checkProjectNotice = async () => {
    const fullProjectList = await projectApi.getList({
      params: { applyflg: 0 },
    });
    let approvalProjectList = fullProjectList.projects.map((project) => project._id);
    setIsProjectNotice(
      approvalProjectList.length !== 0 && RoleType.BoardOfDirectors === authUser?.roleType
    );
    dispatch(setUpdateBadgeFlg(false));
  };

  const checkBranchJoinNotice = async () => {
    const fullBranchJoinList = await branchUserApi.getList({
      params: { applyflg: 0 },
    });
    let approvalBranchJoinList = [];
    fullBranchJoinList.branchs.map((branch) => {
      if (
        storedUserInfo.value._id === branch.branchMaster ||
        RoleType.BoardOfDirectors === authUser?.roleType
      ) {
        approvalBranchJoinList.push(branch._id);
      }
    });
    const checkedBranchJoinList = await branchUserApi.getBranchUserCheckLog({
      params: { operateUserId: storedUserInfo.value._id, isExamined: false },
    });

    setIsBranchJoinNotice(
      approvalBranchJoinList.length !== 0 &&
        approvalBranchJoinList.length !== checkedBranchJoinList.branchUserCheckLogList.length
    );
    dispatch(setUpdateBadgeFlg(false));
  };

  const checkProjectJoinNotice = async () => {
    if (
      RoleType.BoardOfDirectors === authUser?.roleType ||
      RoleType.FullMember === authUser?.roleType
    ) {
      const approvalProjectJoinList = await actWorkApi.getApprovalProjectJoinList({
        params: { roleType: authUser.roleType, userId: storedUserInfo.value._id },
      });
      const checkedProjectJoinList = await actWorkApi.getActWorkCheckLog({
        params: { operateUserId: storedUserInfo.value._id, isExamined: false },
      });
      setIsProjectJoinNotice(
        approvalProjectJoinList.projectJoinIds.length !== 0 &&
          approvalProjectJoinList.projectJoinIds.length !==
            checkedProjectJoinList.actWorkCheckLogList.length
      );
    }
    dispatch(setUpdateBadgeFlg(false));
  };

  const fetchExamination = async () => {
    const res = await examinationApi.getList();
    const examinations = res.examinations;
    // 入会審査が未完了のユーザに絞り込む
    const res2 = await authApi.getCompanyList({
      params: {
        conditions: { companyStatus: { $ne: 3 } },
      },
    });
    const companyList = res2.companys;
    const fetchProposals = await proposalApi.aggregateProposalWithChat({
      membership: user.membership,
      userId: user._id,
    });
    const proposalList = fetchProposals.proposals;
    let isExistNotReadChat = false;

    // 未承認通知
    let countList = 0;
    for (let i = 0; i < companyList.length; i++) {
      countList += examinations.filter((examination) => {
        return examination.companyId === companyList[i]._id && examination.approvalBy === user._id;
      }).length;
      // 新規理事追加の場合に、参画以前のデータを「確認不要(確認済み)」として扱う
      const createdDate = new Date(companyList[i].created);
      if (user.directorCode === "director06") {
        const baseDate1 = new Date("2023/08/31");
        if (createdDate < baseDate1) {
          countList += 1;
        }
      }
    }

    // 稟議通知
    let exCountList = 0;
    let totalMyProposal = 0;
    for (let i = 0; i < proposalList.length; i++) {
      const proposal = proposalList[i]._doc;
      const notReadChatNum = proposalList[i].notReadChatNum;
      if (notReadChatNum > 0) {
        isExistNotReadChat = true;
        setIsProposalNotice(true);
      }
      if (proposal.to === 10 && user.membership === "boardChairman") {
        totalMyProposal++;
        examinations.forEach((examination) => {
          if (
            examination.examinationCode === 1 &&
            examination.companyId === proposal._id &&
            examination.approvalBy === user._id
          ) {
            exCountList++;
          }
        });
      } else if (
        proposal.to === 11 &&
        user.membership === "boardOfDirectors" &&
        !(user.directorCode === "director01" || user.directorCode === "director02")
      ) {
        totalMyProposal++;
        examinations.forEach((examination) => {
          if (
            examination.examinationCode === 1 &&
            examination.companyId === proposal._id &&
            examination.approvalBy === user._id
          ) {
            exCountList++;
          }
        });
      } else if (
        proposal.to === 11 &&
        user.membership === "boardOfDirectors" &&
        (user.directorCode === "director01" || user.directorCode === "director02")
      ) {
        totalMyProposal++;
        examinations.forEach((examination) => {
          if (
            examination.examinationCode === 1 &&
            examination.companyId === proposal._id &&
            examination.approvalBy === user._id
          ) {
            exCountList++;
          }
        });
      } else if (
        (proposal.to === 12 &&
          user.membership === "boardOfDirectors" &&
          (user.directorCode === "director01" || user.directorCode === "director02")) ||
        (proposal.to === 12 && user.membership === "admin")
      ) {
        totalMyProposal++;
        examinations.forEach((examination) => {
          if (
            examination.examinationCode === 1 &&
            examination.companyId === proposal._id &&
            examination.approvalBy === user._id
          ) {
            exCountList++;
          }
        });
      }
    }

    if (examinations.length === 0 && proposalList.length > 0) {
      setIsProposalNotice(true);
    }

    const branchMaster = await branchApi.getTargetOne({
      params: { branchMasterId: storedUserInfo.value._id },
    });

    if (branchMaster.branch) {
      setHasBranchMaster(true);
    }
    if (totalMyProposal - exCountList > 0) {
      setIsProposalNotice(true);
    } else if (!isExistNotReadChat) {
      setIsProposalNotice(false);
    }

    setIsNotice(companyList.length - countList > 0);

    dispatch(setUpdateBadgeFlg(false));
  };
  useEffect(() => {
    fetchExamination();
    checkMemberNotice();
    checkProgramNotice();
    checkBranchNotice();
    checkProjectNotice();
    checkBranchJoinNotice();
    checkProjectJoinNotice();
  }, []);

  useEffect(() => {
    if (sidebarBadgeUpdateFlg) {
      fetchExamination();
      checkMemberNotice();
      checkProgramNotice();
      checkBranchNotice();
      checkProjectNotice();
      checkBranchJoinNotice();
      checkProjectJoinNotice();
    }
  }, [sidebarBadgeUpdateFlg]);

  useEffect(() => {
    // TODO: 画面遷移するたびに動くのを処理として許容するか検討する
    // ただ、バッチの更新がログイン時に1回のみとなっているのも微妙すぎる
    fetchExamination();
  }, [location]);

  const menuDrawer = (
    <>
      <Drawer
        container={window.document.body}
        anchor="right"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        sx={{ width: 250, height: "100vh" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            backgroundColor: "#ffffff",
            color: "#000000",
          }}
        >
          <Link to="/member">
            <img src={logo} alt="logo" width="60px" />
          </Link>
          <Box
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1, mt: 3 }}
          >
            <AccountCircleIcon />
            {user?.name || user?.username}
          </Box>
        </Box>

        <List
          sx={{
            width: 250,
            height: "100vh",
            backgroundColor: "#78B94F",
            color: "#ffffff",
          }}
        >
          <Box sx={{ padding: "10px" }} />
          <NavLink to="/member" style={{ textDecoration: "none", color: "unset" }}>
            <ListItemButton onClick={() => setIsOpen(false)}>
              <ListItemIcon>
                <HomeIcon sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              <ListItemText primary="HOME" />
            </ListItemButton>
          </NavLink>
          {/* マイページ */}
          {/* 先方都合で仕様変更となり、リリース延期となったためコメントアウトで対応する */}
          {/* {[RoleType.Admin, RoleType.FullMember, RoleType.GeneralMember, RoleType.ObserverMember].includes(authUser?.roleType) && (
      <>
        <NavLink to="/member/mypage" style={{ textDecoration: "none", color: "unset" }}>
          <ListItemButton sx={{ pl: 2 }}>
            <ListItemIcon>
              <LinearScaleIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            <ListItemText primary="マイページ" />
          </ListItemButton>
        </NavLink>
      </>
    )} */}
    {/* トピック */}
    {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
          <>
            <ListItemButton onClick={() => toggleMenu(0)}>
              <ListItemIcon>
                <TaskIcon sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              {/* 自分が担当者になっているトピックが1つでもあればバッチ付与
              {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.ThirdPartyCommittee].includes(authUser?.roleType) && isProgramNotice && (
                <Box>
                  <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                </Box>
              )}
              */}
              <ListItemText primary="トピック" />
              {isOpenMenu[0] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isOpenMenu[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NavLink to="/member/task/list" style={{ textDecoration: "none", color: "unset" }}>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="トピック一覧" />
                  </ListItemButton>
                </NavLink>
                <NavLink to="/member/task/add" style={{ textDecoration: "none", color: "unset" }}>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="トピック追加" />
                  </ListItemButton>
                </NavLink>
              </List>
            </Collapse>
          </>
        )}
          {/* イベントフロー */}
          {[RoleType.SystemAdmin, RoleType.Admin].includes(authUser?.roleType) && (
            <>
              <ListItemButton onClick={() => toggleMenu(1)}>
                <ListItemIcon>
                  <EventIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="イベント管理" />
                {isOpenMenu[1] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isOpenMenu[1]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink
                    to="/admin/event_list"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="イベント一覧" />
                    </ListItemButton>
                  </NavLink>
                  <NavLink
                    to="/admin/event_register"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="イベント作成" />
                    </ListItemButton>
                  </NavLink>
                </List>
              </Collapse>
            </>
          )}
          {/* 請求フロー */}
          {[RoleType.SystemAdmin, RoleType.Admin].includes(authUser?.roleType) && (
            <>
              <ListItemButton onClick={() => toggleMenu(7)}>
                <ListItemIcon>
                  <RequestQuoteIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="請求管理" />
                {isOpenMenu[7] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isOpenMenu[7]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink
                    to="/admin/annualFee"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="年会費請求" />
                    </ListItemButton>
                  </NavLink>
                </List>
              </Collapse>
            </>
          )}
          {/* 入会フロー */}
          {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
            <>
              <List component="div" disablePadding>
                <NavLink
                  to="/admin/primary-pass"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 2 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    {isNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                    <ListItemText primary="入会審査" sx={isNotice ? { marginLeft: "10px" } : {}} />
                  </ListItemButton>
                </NavLink>
              </List>
            </>
          )}
          {/* 会員リスト */}
          {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
            <>
              <List component="div" disablePadding>
                <NavLink
                  to="/member/members/list"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 2 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    {isMemberNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                    <ListItemText
                      primary="会員一覧"
                      sx={isMemberNotice ? { marginLeft: "10px" } : {}}
                    />
                  </ListItemButton>
                </NavLink>
              </List>
            </>
          )}
          {/* プログラム */}
          <ListItemButton onClick={() => toggleMenu(2)} disabled>
            <ListItemIcon>
              <WidgetsIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.ThirdPartyCommittee].includes(
              authUser?.roleType
            ) &&
              isProgramNotice && (
                <Box>
                  <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                </Box>
              )}
            <ListItemText primary="プログラム" sx={isProgramNotice ? { marginLeft: "10px" } : {}} />
            {isOpenMenu[2] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenMenu[2]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to="/member/programs" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  {[
                    RoleType.Admin,
                    RoleType.BoardOfDirectors,
                    RoleType.ThirdPartyCommittee,
                  ].includes(authUser?.roleType) &&
                    isProgramNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                  <ListItemText
                    primary="プログラム一覧"
                    sx={isProgramNotice ? { marginLeft: "10px" } : {}}
                  />
                </ListItemButton>
              </NavLink>
              {[RoleType.Admin, RoleType.ThirdPartyCommittee].includes(authUser?.roleType) && (
                <Link
                  to="/member/programs/create"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="プログラム登録" />
                  </ListItemButton>
                </Link>
              )}
              {[
                RoleType.SystemAdmin,
                RoleType.Admin,
                RoleType.FullMember,
                RoleType.GeneralMember,
                RoleType.ObserverMember,
              ].includes(authUser?.roleType) ? (
                <NavLink
                  to="/member/programs/offer"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="プログラム提案" />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="プログラム提案" />
                </ListItemButton>
              )}
            </List>
          </Collapse>
          {/* 支部 */}
          <ListItemButton
            onClick={() => toggleMenu(3)}
            disabled={user?.membership !== "admin" && !user?.isBetaReleaseAllowed}
          >
            <ListItemIcon>
              <AccountTreeIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(
              authUser?.roleType
            ) &&
              (isBranchNotice || isBranchJoinNotice) && (
                <Box>
                  <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                </Box>
              )}
            <ListItemText
              primary="支部"
              sx={isBranchNotice || isBranchJoinNotice ? { marginLeft: "10px" } : {}}
            />
            {isOpenMenu[3] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenMenu[3]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to="/member/branches" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  {[RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) &&
                    isBranchNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                  <ListItemText
                    primary="支部一覧"
                    sx={isBranchNotice ? { marginLeft: "10px" } : {}}
                  />
                </ListItemButton>
              </NavLink>
              {[RoleType.SystemAdmin, RoleType.Admin, RoleType.FullMember].includes(authUser?.roleType) ? (
                <NavLink
                  to="/member/branches/create"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="支部組成申請" />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="支部組成申請" />
                </ListItemButton>
              )}
              {[
                RoleType.SystemAdmin, 
                RoleType.Admin,
                RoleType.BoardOfDirectors,
                RoleType.FullMember,
                RoleType.GeneralMember,
                RoleType.ObserverMember,
              ].includes(authUser?.roleType) || hasBranchMaster ? (
                <NavLink
                  to="/member/branches/joinList"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    {[
                      RoleType.Admin,
                      RoleType.BoardOfDirectors,
                      RoleType.FullMember,
                      RoleType.GeneralMember,
                      RoleType.ObserverMember,
                    ].includes(authUser?.roleType) &&
                      isBranchJoinNotice && (
                        <Box>
                          <Badge
                            color="warning"
                            variant="dot"
                            style={{ marginBottom: "5px" }}
                          ></Badge>
                        </Box>
                      )}
                    <ListItemText
                      primary="支部入会一覧"
                      sx={isBranchJoinNotice ? { marginLeft: "10px" } : {}}
                    />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="支部入会一覧" />
                </ListItemButton>
              )}
            </List>
          </Collapse>

          {/* プロジェクト */}
          <ListItemButton
            onClick={() => toggleMenu(4)}
            disabled={user?.membership !== "admin" && !user?.isBetaReleaseAllowed}
          >
            <ListItemIcon>
              <ContentPasteSearchIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(
              authUser?.roleType
            ) &&
              (isProjectNotice || isProjectJoinNotice) && (
                <Box>
                  <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                </Box>
              )}
            <ListItemText
              primary="プロジェクト"
              sx={isProjectNotice || isProjectJoinNotice ? { marginLeft: "10px" } : {}}
            />
            {isOpenMenu[4] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenMenu[4]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to="/member/projects" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  {[RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) &&
                    isProjectNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                  <ListItemText
                    primary="プロジェクト一覧"
                    sx={isProjectNotice ? { marginLeft: "10px" } : {}}
                  />
                </ListItemButton>
              </NavLink>
              {
                [RoleType.SystemAdmin, RoleType.Admin].includes(authUser?.roleType) ||
                ([RoleType.FullMember].includes(authUser?.roleType) && hasBranchMaster)
                ? (
                  <NavLink
                    to="/member/projects/create"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="プロジェクト設計書作成" />
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <ListItemButton disabled sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="プロジェクト設計書作成" />
                  </ListItemButton>
                )
              }
              {/* {[RoleType.FullMember, RoleType.GeneralMember, RoleType.ObserverMember].includes(
                authUser?.roleType
              ) ? (
                <NavLink
                  to="/member/projects/result"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="プロジェクト成果入力" />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="プロジェクト成果入力" />
                </ListItemButton>
              )} */}
              {
                [RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType)
                && (
                  <NavLink
                    to="/member/projects/create"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="妥当性報告書作成" />
                    </ListItemButton>
                  </NavLink>
                )
              }
              {
                [RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType)
                && (
                  <NavLink
                    to="/member/projects/create"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="検証報告書作成" />
                    </ListItemButton>
                  </NavLink>
                )
              }
              {[
                RoleType.SystemAdmin,
                RoleType.Admin,
                RoleType.BoardOfDirectors,
                RoleType.FullMember,
                RoleType.GeneralMember,
                RoleType.ObserverMember,
              ].includes(authUser?.roleType) ? (
                <NavLink
                  to="/member/project_applicants"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(
                      authUser?.roleType
                    ) &&
                      isProjectJoinNotice && (
                        <Box>
                          <Badge
                            color="warning"
                            variant="dot"
                            style={{ marginBottom: "5px" }}
                          ></Badge>
                        </Box>
                      )}
                    <ListItemText
                      primary="参加申請者一覧"
                      sx={isProjectJoinNotice ? { marginLeft: "10px" } : {}}
                    />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="参加申請者一覧" />
                </ListItemButton>
              )}
            </List>
          </Collapse>
          {/* 稟議フロー */}
          {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
            <>
              <ListItemButton onClick={() => toggleMenu(5)}>
                <ListItemIcon>
                  <EventIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                {isProposalNotice ? (
                  <Box>
                    <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                  </Box>
                ) : (
                  ""
                )}
                <ListItemText primary="稟議" sx={{ marginLeft: "10px" }} />
                {isOpenMenu[5] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isOpenMenu[5]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink to="/member/proposal" style={{ textDecoration: "none", color: "unset" }}>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      {isProposalNotice ? (
                        <Box>
                          <Badge
                            color="warning"
                            variant="dot"
                            style={{ marginBottom: "5px" }}
                          ></Badge>
                        </Box>
                      ) : (
                        ""
                      )}
                      <ListItemText
                        primary="稟議一覧"
                        sx={isProposalNotice ? { marginLeft: "10px" } : {}}
                      />
                    </ListItemButton>
                  </NavLink>
                  <NavLink
                    to="/member/proposal/create"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="稟議作成" />
                    </ListItemButton>
                  </NavLink>
                </List>
              </Collapse>
            </>
          )}
          {/* 名刺作成 */}
          {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(
            authUser?.roleType
          ) ? (
            <List component="div" disablePadding>
              <NavLink
                to="/member/businessCard/create"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <ListItemButton sx={{ pl: 2 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="名刺作成" />
                </ListItemButton>
              </NavLink>
            </List>
          ) : (
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 2 }} disabled>
                <ListItemIcon>
                  <LinearScaleIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="名刺作成" />
              </ListItemButton>
            </List>
          )}
          {/* 操作マニュアル */}
          {[RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
            <>
              <List component="div" disablePadding>
                <NavLink to="/member/manual" style={{ textDecoration: "none", color: "unset" }}>
                  <ListItemButton sx={{ pl: 2 }} onClick={() => setIsOpen(false)}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="操作マニュアル" />
                  </ListItemButton>
                </NavLink>
              </List>
            </>
          )}
          {/* HPメニュー */}
          <ListItemButton onClick={() => toggleMenu(6)}>
            <ListItemIcon>
              <LinearScaleIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            <ListItemText primary="HPメニュー" sx={{ marginLeft: "10px" }} />
            {isOpenMenu[6] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenMenu[6]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink
                to="/member/hp/organization"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="社団概要" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/about" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="活動について" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/members" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="会員一覧" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/observer" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="学術パートナー研究員" />
                </ListItemButton>
              </NavLink>
              <NavLink
                to="/member/hp/constitution"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="会則" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/news" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="お知らせ" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/status" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="レポート" />
                </ListItemButton>
              </NavLink>
              <NavLink
                to="/member/hp/privacypolicy"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="プライバシーポリシー" />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>
          <ListItemButton onClick={logout}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" fontWeight="700">
                ログアウト
              </Typography>
              <IconButton onClickCapture={logout}>
                <LogoutOutlinedIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            </Box>
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );

  return (
    <>
      <Drawer
        container={window.document.body}
        variant="permanent"
        open={true}
        sx={{ width: 250, height: "100vh", display: { xs: "none", md: "block" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            backgroundColor: "#ffffff",
            color: "#000000",
          }}
        >
          <Link to="/member">
            <img src={logo} alt="logo" width="60px" />
          </Link>
          <Box
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1, mt: 3 }}
          >
            <AccountCircleIcon />
            {user?.name || user?.username}
          </Box>
        </Box>

        <List
          sx={{
            width: 250,
            height: "100vh",
            backgroundColor: "#78B94F",
            color: "#ffffff",
          }}
        >
          <Box sx={{ padding: "10px" }} />
          <NavLink to="/member" style={{ textDecoration: "none", color: "unset" }}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              <ListItemText primary="HOME" />
            </ListItemButton>
          </NavLink>
          {/* マイページ */}
          {/* 先方都合で仕様変更となり、リリース延期となったためコメントアウトで対応する */}
          {/* {[RoleType.Admin, RoleType.FullMember, RoleType.GeneralMember, RoleType.ObserverMember].includes(authUser?.roleType) && (
          <>
            <NavLink to="/member/mypage" style={{ textDecoration: "none", color: "unset" }}>
              <ListItemButton sx={{ pl: 2 }}>
                <ListItemIcon>
                  <LinearScaleIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="マイページ" />
              </ListItemButton>
            </NavLink>
          </>
        )} */}
        {/* トピック */}
        {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
          <>
            <ListItemButton onClick={() => toggleMenu(0)}>
              <ListItemIcon>
                <TaskIcon sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              {/* 自分が担当者になっているトピックが1つでもあればバッチ付与
              {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.ThirdPartyCommittee].includes(authUser?.roleType) && isProgramNotice && (
                <Box>
                  <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                </Box>
              )}
              */}
              <ListItemText primary="トピック" />
              {isOpenMenu[0] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isOpenMenu[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NavLink to="/member/task/list" style={{ textDecoration: "none", color: "unset" }}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="トピック一覧" />
                  </ListItemButton>
                </NavLink>
                <NavLink to="/member/task/add" style={{ textDecoration: "none", color: "unset" }}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="トピック追加" />
                  </ListItemButton>
                </NavLink>
              </List>
            </Collapse>
          </>
        )}
          {/* イベントフロー */}
          {[RoleType.SystemAdmin, RoleType.Admin].includes(authUser?.roleType) && (
            <>
              <ListItemButton onClick={() => toggleMenu(1)}>
                <ListItemIcon>
                  <EventIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="イベント管理" />
                {isOpenMenu[1] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isOpenMenu[1]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink
                    to="/admin/event_list"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="イベント一覧" />
                    </ListItemButton>
                  </NavLink>
                  <NavLink
                    to="/admin/event_register"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="イベント作成" />
                    </ListItemButton>
                  </NavLink>
                </List>
              </Collapse>
            </>
          )}
          {/* 請求フロー */}
          {[RoleType.SystemAdmin, RoleType.Admin].includes(authUser?.roleType) && (
            <>
              <ListItemButton onClick={() => toggleMenu(7)}>
                <ListItemIcon>
                  <RequestQuoteIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="請求管理" />
                {isOpenMenu[7] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isOpenMenu[7]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink
                    to="/admin/annual-fee"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="年会費請求" />
                    </ListItemButton>
                  </NavLink>
                </List>
              </Collapse>
            </>
          )}
          {/* 入会フロー */}
          {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
            <>
              <List component="div" disablePadding>
                <NavLink
                  to="/admin/primary-pass"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    {isNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                    <ListItemText primary="入会審査" sx={isNotice ? { marginLeft: "10px" } : {}} />
                  </ListItemButton>
                </NavLink>
              </List>
            </>
          )}
          {/* 会員リスト */}
          {[RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
            <>
              <List component="div" disablePadding>
                <NavLink
                  to="/member/members/list"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    {isMemberNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                    <ListItemText
                      primary="会員一覧"
                      sx={isMemberNotice ? { marginLeft: "10px" } : {}}
                    />
                  </ListItemButton>
                </NavLink>
              </List>
            </>
          )}
          {/* プログラム */}
          <ListItemButton onClick={() => toggleMenu(2)} disabled>
            <ListItemIcon>
              <WidgetsIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.ThirdPartyCommittee].includes(
              authUser?.roleType
            ) &&
              isProgramNotice && (
                <Box>
                  <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                </Box>
              )}
            <ListItemText primary="プログラム" sx={isProgramNotice ? { marginLeft: "10px" } : {}} />
            {isOpenMenu[2] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenMenu[2]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to="/member/programs" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  {[
                    RoleType.Admin,
                    RoleType.BoardOfDirectors,
                    RoleType.ThirdPartyCommittee,
                  ].includes(authUser?.roleType) &&
                    isProgramNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                  <ListItemText
                    primary="プログラム一覧"
                    sx={isProgramNotice ? { marginLeft: "10px" } : {}}
                  />
                </ListItemButton>
              </NavLink>
              {[RoleType.SystemAdmin, RoleType.Admin, RoleType.ThirdPartyCommittee].includes(authUser?.roleType) && (
                <Link to="/member/programs/create" style={{ textDecoration: "none", color: "unset" }}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                    <ListItemText primary="プログラム登録" />
                    </ListItemButton>
                  </Link>
              )}
              {[
                RoleType.SystemAdmin, 
                RoleType.Admin,
                RoleType.FullMember,
                RoleType.GeneralMember,
                RoleType.ObserverMember,
              ].includes(authUser?.roleType) ? (
                <NavLink
                  to="/member/programs/offer"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="プログラム提案" />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="プログラム提案" />
                </ListItemButton>
              )}
            </List>
          </Collapse>
          {/* 支部 */}
          <ListItemButton
            onClick={() => toggleMenu(3)}
            disabled={user?.membership !== "admin" && !user?.isBetaReleaseAllowed}
          >
            <ListItemIcon>
              <AccountTreeIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(
              authUser?.roleType
            ) &&
              (isBranchNotice || isBranchJoinNotice) && (
                <Box>
                  <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                </Box>
              )}
            <ListItemText
              primary="支部"
              sx={isBranchNotice || isBranchJoinNotice ? { marginLeft: "10px" } : {}}
            />
            {isOpenMenu[3] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenMenu[3]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to="/member/branches" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  {[RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) &&
                    isBranchNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                  <ListItemText
                    primary="支部一覧"
                    sx={isBranchNotice ? { marginLeft: "10px" } : {}}
                  />
                </ListItemButton>
              </NavLink>
              {[RoleType.SystemAdmin, RoleType.Admin, RoleType.FullMember].includes(authUser?.roleType) ? (
                <NavLink
                  to="/member/branches/create"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="支部組成申請" />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="支部組成申請" />
                </ListItemButton>
              )}
              {[
                RoleType.SystemAdmin,
                RoleType.Admin,
                RoleType.BoardOfDirectors,
                RoleType.FullMember,
                RoleType.GeneralMember,
                RoleType.ObserverMember,
              ].includes(authUser?.roleType) || hasBranchMaster ? (
                <NavLink
                  to="/member/branches/joinList"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    {[
                      RoleType.Admin,
                      RoleType.BoardOfDirectors,
                      RoleType.FullMember,
                      RoleType.GeneralMember,
                      RoleType.ObserverMember,
                    ].includes(authUser?.roleType) &&
                      isBranchJoinNotice && (
                        <Box>
                          <Badge
                            color="warning"
                            variant="dot"
                            style={{ marginBottom: "5px" }}
                          ></Badge>
                        </Box>
                      )}
                    <ListItemText
                      primary="支部入会一覧"
                      sx={isBranchJoinNotice ? { marginLeft: "10px" } : {}}
                    />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="支部入会一覧" />
                </ListItemButton>
              )}
            </List>
          </Collapse>

          {/* プロジェクト */}
          <ListItemButton
            onClick={() => toggleMenu(4)}
            disabled={user?.membership !== "admin" && !user?.isBetaReleaseAllowed}
          >
            <ListItemIcon>
              <ContentPasteSearchIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(
              authUser?.roleType
            ) &&
              (isProjectNotice || isProjectJoinNotice) && (
                <Box>
                  <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                </Box>
              )}
            <ListItemText
              primary="プロジェクト"
              sx={isProjectNotice || isProjectJoinNotice ? { marginLeft: "10px" } : {}}
            />
            {isOpenMenu[4] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenMenu[4]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to="/member/projects" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  {[RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) &&
                    isProjectNotice && (
                      <Box>
                        <Badge
                          color="warning"
                          variant="dot"
                          style={{ marginBottom: "5px" }}
                        ></Badge>
                      </Box>
                    )}
                  <ListItemText
                    primary="プロジェクト一覧"
                    sx={isProjectNotice ? { marginLeft: "10px" } : {}}
                  />
                </ListItemButton>
              </NavLink>
              {
                [RoleType.SystemAdmin, RoleType.Admin].includes(authUser?.roleType) ||
                ([RoleType.FullMember].includes(authUser?.roleType) && hasBranchMaster)
                ? (
                  <NavLink
                    to="/member/projects/create"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="プロジェクト設計書作成" />
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <ListItemButton disabled sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="プロジェクト設計書作成" />
                  </ListItemButton>
                )
              }
              {/* {[RoleType.FullMember, RoleType.GeneralMember, RoleType.ObserverMember].includes(
                authUser?.roleType
              ) ? (
                <NavLink
                  to="/member/projects/result"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="プロジェクト成果入力" />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="プロジェクト成果入力" />
                </ListItemButton>
              )} */}
              {
                [RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType)
                && (
                  <NavLink
                    to="/member/projects/validationReport/create"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="妥当性報告書作成" />
                    </ListItemButton>
                  </NavLink>
                )
              }
              {
                [RoleType.SystemAdmin, RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType)
                && (
                  <NavLink
                    to="/member/projects/verificationReport/create"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => setIsOpen(false)}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="検証報告書作成" />
                    </ListItemButton>
                  </NavLink>
                )
              }
              {[
                RoleType.Admin,
                RoleType.BoardOfDirectors,
                RoleType.FullMember,
                RoleType.GeneralMember,
                RoleType.ObserverMember,
              ].includes(authUser?.roleType) ? (
                <NavLink
                  to="/member/project_applicants"
                  style={{ textDecoration: "none", color: "unset" }}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(
                      authUser?.roleType
                    ) &&
                      isProjectJoinNotice && (
                        <Box>
                          <Badge
                            color="warning"
                            variant="dot"
                            style={{ marginBottom: "5px" }}
                          ></Badge>
                        </Box>
                      )}
                    <ListItemText
                      primary="参加申請者一覧"
                      sx={isProjectJoinNotice ? { marginLeft: "10px" } : {}}
                    />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton disabled sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="参加申請者一覧" />
                </ListItemButton>
              )}
            </List>
          </Collapse>
          {/* 稟議フロー */}
          {[RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
            <>
              <ListItemButton onClick={() => toggleMenu(5)}>
                <ListItemIcon>
                  <EventIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                {isProposalNotice ? (
                  <Box>
                    <Badge color="warning" variant="dot" style={{ marginBottom: "5px" }}></Badge>
                  </Box>
                ) : (
                  ""
                )}
                <ListItemText primary="稟議" sx={{ marginLeft: "10px" }} />
                {isOpenMenu[5] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isOpenMenu[5]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink to="/member/proposal" style={{ textDecoration: "none", color: "unset" }}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      {isProposalNotice ? (
                        <Box>
                          <Badge
                            color="warning"
                            variant="dot"
                            style={{ marginBottom: "5px" }}
                          ></Badge>
                        </Box>
                      ) : (
                        ""
                      )}
                      <ListItemText
                        primary="稟議一覧"
                        sx={isProposalNotice ? { marginLeft: "10px" } : {}}
                      />
                    </ListItemButton>
                  </NavLink>
                  <NavLink
                    to="/member/proposal/create"
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <LinearScaleIcon sx={{ color: "#ffffff" }} />
                      </ListItemIcon>
                      <ListItemText primary="稟議作成" />
                    </ListItemButton>
                  </NavLink>
                </List>
              </Collapse>
            </>
          )}
          {/* 名刺作成 */}
          {[RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(
            authUser?.roleType
          ) ? (
            <List component="div" disablePadding>
              <NavLink
                to="/member/businessCard/create"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <ListItemButton sx={{ pl: 2 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="名刺作成" />
                </ListItemButton>
              </NavLink>
            </List>
          ) : (
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 2 }} disabled>
                <ListItemIcon>
                  <LinearScaleIcon sx={{ color: "#ffffff" }} />
                </ListItemIcon>
                <ListItemText primary="名刺作成" />
              </ListItemButton>
            </List>
          )}
          {/* 操作マニュアル */}
          {[RoleType.Admin, RoleType.BoardOfDirectors].includes(authUser?.roleType) && (
            <>
              <List component="div" disablePadding>
                <NavLink to="/member/manual" style={{ textDecoration: "none", color: "unset" }}>
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <LinearScaleIcon sx={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="操作マニュアル" />
                  </ListItemButton>
                </NavLink>
              </List>
            </>
          )}
          {/* HPメニュー */}
          <ListItemButton onClick={() => toggleMenu(6)}>
            <ListItemIcon>
              <LinearScaleIcon sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            <ListItemText primary="HPメニュー" sx={{ marginLeft: "10px" }} />
            {isOpenMenu[6] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isOpenMenu[6]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink
                to="/member/hp/organization"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="社団概要" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/about" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="活動について" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/members" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="会員一覧" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/observer" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="学術パートナー研究員" />
                </ListItemButton>
              </NavLink>
              <NavLink
                to="/member/hp/constitution"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="会則" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/news" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="お知らせ" />
                </ListItemButton>
              </NavLink>
              <NavLink to="/member/hp/status" style={{ textDecoration: "none", color: "unset" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="レポート" />
                </ListItemButton>
              </NavLink>
              <NavLink
                to="/member/hp/privacypolicy"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <LinearScaleIcon sx={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText primary="プライバシーポリシー" />
                </ListItemButton>
              </NavLink>
            </List>
          </Collapse>
          <ListItemButton onClick={logout}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" fontWeight="700">
                ログアウト
              </Typography>
              <IconButton onClickCapture={logout}>
                <LogoutOutlinedIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            </Box>
          </ListItemButton>
        </List>
      </Drawer>

      {/* mobile view */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 10px 0px 35px",
            zIndex: 1000,
          }}
        >
          {/* <Box sx={{ width: "32px" }}></Box> */}
          <Box>
            <Link to="/member">
              <img src={NCCClogo} alt="logo" width="28px" />
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "60px",
              height: "40px",
              borderRadius: "32px",
              color: "#3a3a3a",
              cursor: "pointer",
              zIndex: 1000,
              backgroundColor: "#00ff00",
            }}
            onClick={() => setIsOpen(true)}
          >
            <MenuIcon></MenuIcon>
          </Box>
          {menuDrawer}
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
