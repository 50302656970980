/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { css, keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";
// assets
import heroMovie from "../../assets/movie/hero.mp4";
import NCCClogo from "../../assets/imgs/NCCC_logo_symbol2.png";
import aboutNCCCEyecatch from "../../assets/imgs/homepage/aboutNCCC-charm.png";
import contentBefore from "../../assets/imgs/homepage/contentBefore.svg";
import contentAfter from "../../assets/imgs/homepage/contentAfter.svg";
import hayabuchi from "../../assets/imgs/homepage/hayabuchi.webp";
import nishihara from "../../assets/imgs/homepage/nishihara.webp";
import matsuoka from "../../assets/imgs/homepage/matsuoka.webp";
import kuwae from "../../assets/imgs/homepage/kuwae.webp";
import PolygonOutline from "../../assets/imgs/homepage/Polygon-outline.png";
import Polygon3 from "../../assets/imgs/homepage/Polygon3.svg";
import Polygon14 from "../../assets/imgs/homepage/Polygon14.svg";
import Polygon13 from "../../assets/imgs/homepage/Polygon13.svg";
import projectHero from "../../assets/imgs/homepage/projectHero.png";
import partner from "../../assets/imgs/homepage/partner.png";
import vectorLine from "../../assets/imgs/homepage/vector-line.svg";
import vectorLine2 from "../../assets/imgs/homepage/vector-line02.svg";
import News from "../../components/common/News";
import { API_URL, API_KEY } from "../../utils/microcms.js";
import { Link } from "react-router-dom";

const setRamdamTop = () => {
  const ramdam = Math.floor(window.screen.height - Math.random() * 1000);
  return ramdam;
};

const setRamdamLeft = () => {
  const ramdam = Math.floor(window.screen.width - Math.random() * 2000);
  return ramdam;
};

const generateRamdamImgProperties = () => {
  const result = [];
  const Imglist = [hayabuchi, nishihara, matsuoka, kuwae];
  for (let i = 0; i < 20; i++) {
    const top = setRamdamTop();
    const left = setRamdamLeft();
    const animationRate = (Math.random() * 100) / 2;
    result.push({
      top: top,
      left: left,
      animationRate: Math.round(animationRate),
    });
  }
  for (let j = 0; j <= Imglist.length; j++) {
    const top = setRamdamTop();
    const left = setRamdamLeft();
    const animationRate = (Math.random() * 100) / 2;
    result.push({
      top: top,
      left: left,
      img: Imglist[j],
      animationRate: Math.round(animationRate),
    });
  }
  return result;
};

export const Top = () => {
  const { t } = useTranslation();
  const [release, setRelease] = useState({});
  const [news, setNews] = useState({});
  const [ramdamImgProperties, setRamdamImgProperties] = useState(generateRamdamImgProperties());
  useEffect(() => {
    fetch(API_URL + "release" + "?orders=-createdAt&limit=5", {
      headers: { "X-MICROCMS-API-KEY": API_KEY },
    })
      .then((res) => res.json())
      .then((data) => {
        setRelease(data);
      });
  }, []);

  useEffect(() => {
    fetch(API_URL + "news" + "?orders=-createdAt&limit=5", {
      headers: { "X-MICROCMS-API-KEY": API_KEY },
    })
      .then((res) => res.json())
      .then((data) => {
        setNews(data);
      });
  }, []);

  return (
    <>
      <div css={HeroWrapper}>
        <div css={videoArea}>
          {ramdamImgProperties.map((item, index) =>
            item.img ? (
              <div
                css={[hexWrapper(item?.animationRate)]}
                style={{ position: "absolute", top: item.top, left: item.left }}
              >
                <img
                  src={item.img}
                  css={hexAnime2()}
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </div>
            ) : (
              <img
                src={PolygonOutline}
                css={hexAnime(item?.animationRate)}
                style={{
                  width: "100px",
                  height: "100px",
                  position: "absolute",
                  top: item.top,
                  left: item.left,
                }}
              />
            )
          )}

          <video src={heroMovie} autoPlay muted loop playsInline css={video}>
            <source src={heroMovie} type="video/mp4" />
            <p>動画を再生できる環境ではありません。</p>
          </video>
        </div>
        <div css={tagline}>
          {t("home.contents.tagline")}
          <br />
          {t("home.contents.tagline02")}
        </div>
        <div css={scrollDowns}>
          <div css={mousey}>
            <div css={croller}></div>
          </div>
        </div>
      </div>
      <div css={contentArea}>
        <img src={NCCClogo} alt="" css={NCCClogoSymbol} />
        <img src={Polygon14} alt="" css={content01Left} />
        <img src={vectorLine} alt="" css={content01BackgroundLiner} />
        <div css={heroSection}>
          <div css={heroImgSection}>
            <img src={aboutNCCCEyecatch} alt="" css={aboutNCCCEyecatchStyle} />
            <img src={Polygon13} alt="" css={content02ImgOver} />
            <img src={Polygon13} alt="" css={content02ImgOverSub} />
          </div>
          <div css={contentSection}>
            <div css={contentTitle}>About NCCC</div>
            <div css={customeDivider}></div>
            <div css={customeBodyText}>{t("aboutNCCC.label")}</div>
            <div css={customeBodyText}>{t("aboutNCCC.content")}</div>
            <Link to="/about" css={stdBtn}>
              {t("aboutNCCC.linklabel")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M16.5 1.31982C24.8763 1.31982 31.68 8.1235 31.68 16.4998C31.68 24.8762 24.8763 31.6798 16.5 31.6798C8.12366 31.6798 1.31999 24.8762 1.31999 16.4998C1.31999 8.1235 8.12366 1.31982 16.5 1.31982ZM16.5 2.63982C8.8378 2.63982 2.63999 8.83764 2.63999 16.4998C2.63999 24.162 8.8378 30.3598 16.5 30.3598C24.1622 30.3598 30.36 24.162 30.36 16.4998C30.36 8.83764 24.1622 2.63982 16.5 2.63982ZM19.2225 10.5598C19.3694 10.5753 19.5087 10.6423 19.6144 10.7455L24.7912 15.943L24.8531 15.9636C24.8686 15.9765 24.8815 15.9894 24.8944 16.0048V16.0254C24.9098 16.0383 24.9227 16.0512 24.9356 16.0667C24.9511 16.0796 24.964 16.0925 24.9769 16.108C24.9769 16.1157 24.9769 16.1208 24.9769 16.1286C24.9923 16.1415 25.0052 16.1544 25.0181 16.1698C25.0181 16.1776 25.0181 16.1827 25.0181 16.1904C25.0594 16.2601 25.0877 16.3374 25.1006 16.4173C25.1006 16.4251 25.1006 16.4302 25.1006 16.438C25.1006 16.4586 25.1006 16.4792 25.1006 16.4998C25.1006 16.5127 25.1006 16.5282 25.1006 16.5411C25.1006 16.5488 25.1006 16.554 25.1006 16.5617C25.0903 16.6494 25.0619 16.7319 25.0181 16.8092C25.013 16.8221 25.0052 16.8376 24.9975 16.8504C24.9898 16.8582 24.9846 16.8633 24.9769 16.8711C24.9717 16.884 24.964 16.8994 24.9562 16.9123C24.9485 16.9201 24.9434 16.9252 24.9356 16.933C24.9279 16.9407 24.9227 16.9458 24.915 16.9536C24.9073 16.9613 24.9021 16.9665 24.8944 16.9742C24.8583 17.0129 24.817 17.049 24.7706 17.0773L19.6144 22.2542C19.3514 22.5172 18.9286 22.5172 18.6656 22.2542C18.4026 21.9912 18.4026 21.5684 18.6656 21.3055L22.8112 17.1598H8.57999C8.3428 17.1624 8.12108 17.0387 7.99991 16.8324C7.88132 16.6262 7.88132 16.3735 7.99991 16.1672C8.12108 15.961 8.3428 15.8372 8.57999 15.8398H22.8112L18.6656 11.6942C18.4516 11.4957 18.3949 11.1812 18.5238 10.9208C18.6501 10.6578 18.9363 10.5134 19.2225 10.5598Z"
                  fill="black"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <div css={contentArea}>
        <div css={projectArea}>
          <div css={heroSection}>
            <div css={contentSection}>
              <div css={contentTitle}>PROJECT DEVELOPMENT</div>
              <div css={customeDivider}></div>
              <div css={customeBodyText}>{t("projectDevelopment.label")}</div>
              <div css={customeBodyText}>{t("projectDevelopment.content")}</div>
              <Link to="/comingsoon" css={stdBtn}>
                {t("projectDevelopment.linklabel")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M16.5 1.31982C24.8763 1.31982 31.68 8.1235 31.68 16.4998C31.68 24.8762 24.8763 31.6798 16.5 31.6798C8.12366 31.6798 1.31999 24.8762 1.31999 16.4998C1.31999 8.1235 8.12366 1.31982 16.5 1.31982ZM16.5 2.63982C8.8378 2.63982 2.63999 8.83764 2.63999 16.4998C2.63999 24.162 8.8378 30.3598 16.5 30.3598C24.1622 30.3598 30.36 24.162 30.36 16.4998C30.36 8.83764 24.1622 2.63982 16.5 2.63982ZM19.2225 10.5598C19.3694 10.5753 19.5087 10.6423 19.6144 10.7455L24.7912 15.943L24.8531 15.9636C24.8686 15.9765 24.8815 15.9894 24.8944 16.0048V16.0254C24.9098 16.0383 24.9227 16.0512 24.9356 16.0667C24.9511 16.0796 24.964 16.0925 24.9769 16.108C24.9769 16.1157 24.9769 16.1208 24.9769 16.1286C24.9923 16.1415 25.0052 16.1544 25.0181 16.1698C25.0181 16.1776 25.0181 16.1827 25.0181 16.1904C25.0594 16.2601 25.0877 16.3374 25.1006 16.4173C25.1006 16.4251 25.1006 16.4302 25.1006 16.438C25.1006 16.4586 25.1006 16.4792 25.1006 16.4998C25.1006 16.5127 25.1006 16.5282 25.1006 16.5411C25.1006 16.5488 25.1006 16.554 25.1006 16.5617C25.0903 16.6494 25.0619 16.7319 25.0181 16.8092C25.013 16.8221 25.0052 16.8376 24.9975 16.8504C24.9898 16.8582 24.9846 16.8633 24.9769 16.8711C24.9717 16.884 24.964 16.8994 24.9562 16.9123C24.9485 16.9201 24.9434 16.9252 24.9356 16.933C24.9279 16.9407 24.9227 16.9458 24.915 16.9536C24.9073 16.9613 24.9021 16.9665 24.8944 16.9742C24.8583 17.0129 24.817 17.049 24.7706 17.0773L19.6144 22.2542C19.3514 22.5172 18.9286 22.5172 18.6656 22.2542C18.4026 21.9912 18.4026 21.5684 18.6656 21.3055L22.8112 17.1598H8.57999C8.3428 17.1624 8.12108 17.0387 7.99991 16.8324C7.88132 16.6262 7.88132 16.3735 7.99991 16.1672C8.12108 15.961 8.3428 15.8372 8.57999 15.8398H22.8112L18.6656 11.6942C18.4516 11.4957 18.3949 11.1812 18.5238 10.9208C18.6501 10.6578 18.9363 10.5134 19.2225 10.5598Z"
                    fill="black"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div css={[contentArea, paddingLayoutHelper]}>
        <img src={vectorLine2} alt="" css={content02BackgroundLiner} />
        <img src={partner} alt="" css={partnerImg} />
        <div css={[heroSectionRight]}>
          <div css={[contentSection, mobilePartner]}>
            <div css={contentTitle}>PARTNERS</div>
            <div css={customeDivider}></div>
            <div css={customeBodyText}>{t("partner.label")}</div>
            <div css={customeBodyText}>{t("partner.content")}</div>
            <Link to="/observer" css={stdBtn}>
              {t("partner.linklabel")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M16.5 1.31982C24.8763 1.31982 31.68 8.1235 31.68 16.4998C31.68 24.8762 24.8763 31.6798 16.5 31.6798C8.12366 31.6798 1.31999 24.8762 1.31999 16.4998C1.31999 8.1235 8.12366 1.31982 16.5 1.31982ZM16.5 2.63982C8.8378 2.63982 2.63999 8.83764 2.63999 16.4998C2.63999 24.162 8.8378 30.3598 16.5 30.3598C24.1622 30.3598 30.36 24.162 30.36 16.4998C30.36 8.83764 24.1622 2.63982 16.5 2.63982ZM19.2225 10.5598C19.3694 10.5753 19.5087 10.6423 19.6144 10.7455L24.7912 15.943L24.8531 15.9636C24.8686 15.9765 24.8815 15.9894 24.8944 16.0048V16.0254C24.9098 16.0383 24.9227 16.0512 24.9356 16.0667C24.9511 16.0796 24.964 16.0925 24.9769 16.108C24.9769 16.1157 24.9769 16.1208 24.9769 16.1286C24.9923 16.1415 25.0052 16.1544 25.0181 16.1698C25.0181 16.1776 25.0181 16.1827 25.0181 16.1904C25.0594 16.2601 25.0877 16.3374 25.1006 16.4173C25.1006 16.4251 25.1006 16.4302 25.1006 16.438C25.1006 16.4586 25.1006 16.4792 25.1006 16.4998C25.1006 16.5127 25.1006 16.5282 25.1006 16.5411C25.1006 16.5488 25.1006 16.554 25.1006 16.5617C25.0903 16.6494 25.0619 16.7319 25.0181 16.8092C25.013 16.8221 25.0052 16.8376 24.9975 16.8504C24.9898 16.8582 24.9846 16.8633 24.9769 16.8711C24.9717 16.884 24.964 16.8994 24.9562 16.9123C24.9485 16.9201 24.9434 16.9252 24.9356 16.933C24.9279 16.9407 24.9227 16.9458 24.915 16.9536C24.9073 16.9613 24.9021 16.9665 24.8944 16.9742C24.8583 17.0129 24.817 17.049 24.7706 17.0773L19.6144 22.2542C19.3514 22.5172 18.9286 22.5172 18.6656 22.2542C18.4026 21.9912 18.4026 21.5684 18.6656 21.3055L22.8112 17.1598H8.57999C8.3428 17.1624 8.12108 17.0387 7.99991 16.8324C7.88132 16.6262 7.88132 16.3735 7.99991 16.1672C8.12108 15.961 8.3428 15.8372 8.57999 15.8398H22.8112L18.6656 11.6942C18.4516 11.4957 18.3949 11.1812 18.5238 10.9208C18.6501 10.6578 18.9363 10.5134 19.2225 10.5598Z"
                  fill="black"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <div css={contentArea}>
        <div css={newstitle}>
          <img src={Polygon3}></img>
          <div css={contentTitle}>NEWS</div>
        </div>
        <div css={heroSection}>
          {!!news.contents?.length && (
            <>
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <News items={news.contents} contentName="news" />
                <Link to="/news" css={stdBtn} style={{ marginTop: "50px" }}>
                  {t("newsSection.linklabel")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                  >
                    <path
                      d="M16.5 1.31982C24.8763 1.31982 31.68 8.1235 31.68 16.4998C31.68 24.8762 24.8763 31.6798 16.5 31.6798C8.12366 31.6798 1.31999 24.8762 1.31999 16.4998C1.31999 8.1235 8.12366 1.31982 16.5 1.31982ZM16.5 2.63982C8.8378 2.63982 2.63999 8.83764 2.63999 16.4998C2.63999 24.162 8.8378 30.3598 16.5 30.3598C24.1622 30.3598 30.36 24.162 30.36 16.4998C30.36 8.83764 24.1622 2.63982 16.5 2.63982ZM19.2225 10.5598C19.3694 10.5753 19.5087 10.6423 19.6144 10.7455L24.7912 15.943L24.8531 15.9636C24.8686 15.9765 24.8815 15.9894 24.8944 16.0048V16.0254C24.9098 16.0383 24.9227 16.0512 24.9356 16.0667C24.9511 16.0796 24.964 16.0925 24.9769 16.108C24.9769 16.1157 24.9769 16.1208 24.9769 16.1286C24.9923 16.1415 25.0052 16.1544 25.0181 16.1698C25.0181 16.1776 25.0181 16.1827 25.0181 16.1904C25.0594 16.2601 25.0877 16.3374 25.1006 16.4173C25.1006 16.4251 25.1006 16.4302 25.1006 16.438C25.1006 16.4586 25.1006 16.4792 25.1006 16.4998C25.1006 16.5127 25.1006 16.5282 25.1006 16.5411C25.1006 16.5488 25.1006 16.554 25.1006 16.5617C25.0903 16.6494 25.0619 16.7319 25.0181 16.8092C25.013 16.8221 25.0052 16.8376 24.9975 16.8504C24.9898 16.8582 24.9846 16.8633 24.9769 16.8711C24.9717 16.884 24.964 16.8994 24.9562 16.9123C24.9485 16.9201 24.9434 16.9252 24.9356 16.933C24.9279 16.9407 24.9227 16.9458 24.915 16.9536C24.9073 16.9613 24.9021 16.9665 24.8944 16.9742C24.8583 17.0129 24.817 17.049 24.7706 17.0773L19.6144 22.2542C19.3514 22.5172 18.9286 22.5172 18.6656 22.2542C18.4026 21.9912 18.4026 21.5684 18.6656 21.3055L22.8112 17.1598H8.57999C8.3428 17.1624 8.12108 17.0387 7.99991 16.8324C7.88132 16.6262 7.88132 16.3735 7.99991 16.1672C8.12108 15.961 8.3428 15.8372 8.57999 15.8398H22.8112L18.6656 11.6942C18.4516 11.4957 18.3949 11.1812 18.5238 10.9208C18.6501 10.6578 18.9363 10.5134 19.2225 10.5598Z"
                      fill="black"
                    />
                  </svg>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const HeroWrapper = css`
  position: relative;
  height: 100vh;
  background: linear-gradient(144deg, rgba(102, 210, 106, 0.4) 0%, rgba(30, 100, 158, 0.4) 100%);
`;

const videoArea = css`
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    background-image: radial-gradient(#111 30%, transparent 31%),
      radial-gradient(#111 30%, transparent 31%);
    background-size: 4px 4px;
    background-position: 0 0, 2px 2px;
    mix-blend-mode: difference;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    height: 100vh;
  }
`;

const video = css`
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 177.77777778vh;
  height: 56.25vw;
  min-height: 100%;
  min-width: 100%;
`;

const tagline = css`
  position: absolute;
  bottom: 15%;
  left: 10%;
  z-index: 5;
  color: #ffffff;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  letter-spacing: 10px;
  @media all and (max-width: 480px) {
    position: relative;
    top: 40%;
    left: 0%;
    font-size: 24px;
    padding: 0 20px;
    margin: 0 5px;
  }
`;

const contentArea = css`
  position: relative;
  z-index: 2;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 150px 0;
  @media all and (max-width: 480px) {
    padding: 0px 20px;
  }
`;

const heroSection = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  padding: 150px 0;
  max-width: 1200px;
  @media all and (max-width: 480px) {
    flex-wrap: wrap;
    padding: 80px 20px;
    gap: 20px;
  }
`;

const heroSectionRight = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 150px 0;
  width: 100%;
  max-width: 1200px;
  @media all and (max-width: 480px) {
    position: relative;
  }
`;

const mobilePartner = css`
  @media all and (max-width: 480px) {
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const contentTitle = css`
  color: #3a3a3a;
  font-family: neue-haas-grotesk-text;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 8px;
`;
const contentSection = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  padding: 150px 50px;
  max-width: 450px;
  position: relative;
  &:before {
    content: url(${contentBefore});
    display: inline-block;
    width: 73px;
    height: 42px;
    position: absolute;
    top: 0px;
    left: -23px;
  }
  &:after {
    content: url(${contentAfter});
    display: inline-block;
    width: 73px;
    height: 42px;
    position: absolute;
    bottom: 0;
    right: -23px;
  }
  @media all and (max-width: 480px) {
    padding: 50px 20px;
  }
`;
const heroImgSection = css`
  position: relative;
`;
const customeDivider = css`
  width: 125px;
  height: 2px;
  background: #808080;
`;

const customeBodyText = css`
  color: #3a3a3a;
  font-family: source-han-sans-japanese;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  letter-spacing: 3px;
`;

const content01Left = css`
  position: absolute;
  top: 10%;
  left: -5%;
  z-index: -1;
  @media all and (max-width: 480px) {
    top: -1%;
    left: -15%;
    width: 150px;
  }
`;
const content02ImgOver = css`
  position: absolute;
  bottom: -27%;
  right: -130px;
  @media all and (max-width: 480px) {
    bottom: -10%;
    right: -50px;
    width: 80px;
  }
`;
const content02ImgOverSub = css`
  position: absolute;
  bottom: -35%;
  right: 100px;
  width: 150px;
  @media all and (max-width: 480px) {
    bottom: -14%;
    right: 40px;
    width: 40px;
  }
`;

const aboutNCCCEyecatchStyle = css`
  width: 100%;
  max-width: 550px;
  border-radius: 10px;
`;

const NCCClogoSymbol = css`
  position: absolute;
  top: 5%;
  right: 5%;
  @media all and (max-width: 480px) {
    width: 100px;
    top: -1%;
    right: 0;
  }
`;
const content01BackgroundLiner = css`
  position: absolute;
  width: 120%;
  top: -20px;
  right: -100;
  z-index: -1;
  @media all and (max-width: 480px) {
    top: 80%;
  }
`;
const content02BackgroundLiner = css`
  position: absolute;
  width: 160%;
  bottom: -50%;
  right: -30%;
  z-index: -1;
  @media all and (max-width: 480px) {
    width: 160%;
    left: -30%;
    right: 0;
    bottom: -10%;
  }
`;

const stdBtn = css`
  display: flex;
  margin: 0 auto;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #3a3a3a;
  max-width: 300px;
`;

const projectArea = css`
  position: relative;
  width: 100%;
  max-height: 900px;
  background: url(${projectHero}) no-repeat center center;
  display: block;
  @media all and (max-width: 480px) {
    background-size: cover;
    border-radius: 5px;
    width: 120%;
    margin: 0 20px;
  }
`;
const partnerImg = css`
  position: absolute;
  top: 20%;
  left: 10%;
  width: 900px;
  @media all and (max-width: 480px) {
    width: 300px;
    top: 0;
    left: 0;
    right: 0;
  }
`;
const newstitle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media all and (max-width: 480px) {
    margin-top: 50px;
  }
`;

// helper
const paddingLayoutHelper = css`
  padding: 150px 0;
`;
const paddingLeft100 = css`
  padding-left: 100px;
`;
const marginDiv = css`
  width: 550px;
`;

// animate
const hexFloatAnimation = (animationRate) => {
  return keyframes`
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, -${animationRate}%);
    }
    100% {
        transform: translate(0, 0);
    }
`;
};
const hexAnime = (animationRate) => {
  return css`
    width: 100px;
    height: 100px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    object-fit: cover;
    animation: ${hexFloatAnimation(animationRate)} ${animationRate / 2}s ease 0s infinite;
    position: absolute;
  `;
};
const hexWrapper = (animationRate) => css`
  width: 120px;
  height: 120px;
  background: linear-gradient(144deg, rgba(102, 210, 106, 1) 0%, rgba(30, 100, 158, 1) 100%);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  animation: ${hexFloatAnimation(animationRate)} ${animationRate / 2}s ease 0s infinite;
`;
const hexAnime2 = () => {
  return css`
    width: 100px;
    height: 100px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  `;
};

const scrollDown = keyframes`
  0%{
      transform-origin: top;
      transform: scaleY(0);
  }
  45%{
      transform-origin: top;
      transform: scaleY(1);
  }
  55%{
      transform-origin: bottom;
      transform: scaleY(1);
  }
  100%{
      transform-origin: bottom;
      transform: scaleY(0);
  }
`;
const scrollDowns = css`
  position: absolute;
  right: 0;
  bottom: 10%;
  left: 0;
  margin: auto;
  width: 34px;
  height: 55px;
`;
const mousey = css`
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
`;

const scroller = keyframes`
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
    `;
const croller = css`
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: ${scroller};
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
`;
