import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { MuiFileInput } from "mui-file-input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ja from "dayjs/locale/ja";
import dayjs from "dayjs";
import projectApi from "../../../api/projectApi";
import userApi from "../../../api/userApi";
import authApi from "../../../api/authApi";
import actWorkApi from "../../../api/actWorkApi";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuthUserContext, RoleType, AuthUserProvider } from "../../../providers/auth";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import uploadImg from "../../../components/common/utils/uploadImg";

const program = {
  entities: {},
  allIds: [],
};

const branchList = {
  entities: {},
  allIds: [],
};

const loginUser = {
  name: "山田 太郎",
  branch: "北海道",
};

const category = {
  entities: {},
  allIds: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, option, theme) {
  return {
    fontWeight:
      option.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function createData(label, data) {
  return { label, data };
}

export const MemberProjectJoin = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState({
    entities: {},
    allIds: [],
  });
  const [companyName, setCompanyName] = useState("");
  const { id, workId } = useParams();
  const [list, setList] = useState([]);
  const [joinData, setJoinData] = useState({});
  const storedUserInfo = useSelector((state) => state.user);
  //  const storedUserInfo = useAuthUserContext();
  const authUser = useAuthUserContext();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const actWorkId = urlParams.get("actWorkId");

  const isWorkDisplay = (work, branchMasterId, assigned) => {
    //既にアサイン済みの作業は除外する
    const isAssigned = assigned.filter((assigned) => assigned.workId === work._id);
    if (isAssigned.length) {
      return false;
    }

    const role = authUser.roleType;
    if (!work.publicScope || work.publicScope === "0") {
      return true;
      // 支部長のみ
    } else if (work.publicScope === "1" && branchMasterId === storedUserInfo.value._id) {
      return true;
      // 正会員
    } else if (work.publicScope === "2" && role === RoleType.FullMember) {
      return true;
      // オブザーバー会員限定
    } else if (work.publicScope === "3" && role === RoleType.ObserverMember) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    async function fetchProjectData() {
      const res = await projectApi.getById({
        params: { _id: id },
      });
      category.allIds = [];
      category.entities = {};
      for (let i = 0; i < res.workData.length; i++) {
        const work = res.workData[i];
        category.entities[work._id] = work.workName;
        // ログインユーザーに公開されている作業を設定
        if (isWorkDisplay(work, res.project.branchId.branchMasterId, res.assigned)) {
          category.allIds.push(work._id);
        }
      }
      setList(res.project);
    }

    fetchProjectData();
  }, []);

  useEffect(() => {
    async function fetchActWorkData() {
      if (actWorkId) {
        const Data = await actWorkApi.getById(actWorkId);
        setJoinData(Data.actWork);
      }
    }
    fetchActWorkData();
  }, []);

  useEffect(() => {
    setValue("applicantId", joinData.applicantId);
  }, [joinData.applicantId]);

  useEffect(() => {
    if (joinData.userId) {
      setValue("userId", joinData.userId._id);
    }
  }, [joinData.userId]);

  useEffect(() => {
    if (joinData.purpose) {
      setValue("purpose", joinData.purpose);
    }
  }, [joinData.purpose]);

  useEffect(() => {
    if (joinData.workId) {
      setValue("workId", joinData.workId._id);
    }
  }, [joinData.workId]);

  useEffect(() => {
    setValue("estimate", joinData.estimate);
  }, [joinData.estimate]);

  useEffect(() => {
    async function fetchUserList() {
      const users = await userApi.getTargetList({
        params: { companyId: storedUserInfo.value.companyId },
      });
      const updatedUserList = {
        entities: {},
        allIds: [],
      };
      for (let i = 0; i < users.users.length; i++) {
        const user = users.users[i];
        updatedUserList.entities[user._id] = user.username ? user.username : user.name;
        updatedUserList.allIds.push(user._id);
      }
      setUserList(updatedUserList);
      const companyData = await authApi.getCompany({
        id: storedUserInfo.value.companyId,
      });
      setCompanyName(companyData.company.groupName);
    }
    fetchUserList();
  }, []);
  // validation
  const { control, formState, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      programName: "",
      representativeCompanyName: "",
      userId: "",
      applicantId: "",
      purpose: "",
      workId: workId || "",
      startDate: "",
      endDate: "",
      estimate: "",
      quotationMaterials: "",
      projectTimeLine: [],
      projectId: "",
    },
  });

  const validationRules = {
    userId: {
      required: "代表者名を入力してください。",
    },
    purpose: {
      required: "参加目的を入力してください。",
      maxLength: { value: 400, message: "400文字以内で入力してください" },
    },
    workId: {
      required: "希望する役割を選択してください",
    },
    startDate: {
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!value && !joinData.period) {
          return "プロジェクト開始日を入力してください";
        }
        if (value) {
          if (!dayjs(formatDate).isValid()) {
            return "日付形式が間違っています";
          }
        }
        // 相関チェック　日付の前後チェック
        const formatEndDate = dayjs(getValues("endDate")).format("YYYY/MM/DD");
        const endDate = getValues("endDate");
        if (value) {
          if (value > endDate && formatDate > dayjs(list.projectTimeLine[1]).format("YYYY/MM/DD")) {
            return "開始日は終了日以前の日付を指定してください。";
          }
        }

        // 相関チェック　プロジェクトの期間内かのチェック
        const projectStartDate = dayjs(list.projectTimeLine[0]).format("YYYY/MM/DD");
        if (value) {
          if (formatDate < projectStartDate) {
            return "開始日はプロジェクトの対象期間の開始日以降の日付を指定してください。";
          }
        }
      },
    },
    endDate: {
      validate: (value) => {
        const formatDate = dayjs(value).format("YYYY/MM/DD");
        if (!value && !joinData.period) {
          return "プロジェクト終了日を入力してください";
        }
        if (value) {
          if (!dayjs(formatDate).isValid()) {
            return "日付形式が間違っています";
          }
        }
        // 相関チェック　プロジェクトの期間内かのチェック
        const projectEndDate = dayjs(list.projectTimeLine[1]).format("YYYY/MM/DD");
        if (value) {
          if (formatDate > projectEndDate) {
            return "終了日はプロジェクトの対象期間の終了日以前の日付を指定してください。";
          }
        }
      },
    },
    estimate: {
      required: "見積金額を入力してください",
      max: {
        value: 999999999,
        message: "10,000 ~ 999,999,999円 の範囲で入力してください",
      },
      min: {
        value: 10000,
        message: "10,000 ~ 999,999,999円 の範囲で入力してください",
      },
      pattern: { value: /[0-9]+/, message: "無効な値です" },
    },
    quotationMaterials: {
      validate: (value) => {
        if (!value && !joinData.quotationMaterials) {
          return "見積資料をアップロードしてください";
        }
        if (value) {
          if (!(value?.type === "application/pdf")) {
            return "ファイル形式が間違っています";
          }
          if (!(value?.size <= 5242880)) {
            return "ファイルサイズが大きすぎます";
          }
        }
      },
    },
  };

  const onSubmit = async (data) => {
    if (window.confirm("申請します。よろしいですか？")) {
      try {
        data.companyId = storedUserInfo.value.companyId;
        const joinForm = document.getElementById("joinForm");
        const joinFormData = new FormData(joinForm);
        data.projectId = joinFormData.get("projectId");
        data.applicantId = storedUserInfo.value._id;
        let period = [];
        if (data.startDate) {
          period.push(new Date(data.startDate));
        } else {
          period.push(joinData.period[0]);
        }
        if (data.endDate) {
          period.push(new Date(data.endDate));
        } else {
          period.push(joinData.period[1]);
        }
        data.period = period;

        data.quotationMaterials = data.quotationMaterials
          ? await uploadImg(data.quotationMaterials, ["quotationMaterials"])
          : joinData.quotationMaterials;

        if (joinData.status !== "9") {
          await actWorkApi.register(data);
        } else {
          data._id = joinData._id;
          await actWorkApi.remandUpdate(data);
        }
        alert("申請しました");
        navigate("/member/projects");
      } catch (error) {
        console.error(error);
        alert("申請に失敗しました");
      }
    }
  };
  ///////////////////////
  // file type指定テスト //
  ///////////////////////

  // const ref = useRef(null);
  // const handleChange = (e) => {
  //   if (!e.target.files) return;
  //   const files = Array.from(e.target.files);
  //   setFile(files);
  // };
  // const getFileNames = () =>
  //   file?.reduce(
  //     (fileNames, file) =>
  //       `${fileNames} ${fileNames !== "" ? "," : ""} ${file.name}`,
  //     ""
  //   ) || "";

  ///////////////////////

  // select
  const theme = useTheme();
  const [option, setOption] = useState([]);

  return (
    <div>
      <h3>プロジェクト参加申請</h3>
      {joinData.status === "9" && (
        <Typography mb={2} style={{ color: "#f44336" }}>
          差し戻しコメント：{joinData.approvalComment}
        </Typography>
      )}
      <form id="joinForm" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid xs={12} lg={4}>
            <Typography mb={1}>プログラム</Typography>
            {list.programId && (
              <Typography
                sx={{
                  marginBottom: "2rem",
                  marginLeft: "14px",
                  fontWeight: "bold",
                  opacity: "0.7",
                }}
              >
                {list.programId.programName}
              </Typography>
            )}
            <Typography mb={1}>プロジェクト</Typography>
            <Typography
              sx={{
                marginBottom: "2rem",
                marginLeft: "14px",
                fontWeight: "bold",
                opacity: "0.7",
              }}
            >
              {list.projectName}
            </Typography>
            <input hidden name="projectId" value={list._id} />
            <Typography mb={2}>代表者情報</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="representativeCompanyName"
                control={control}
                rules={validationRules.representativeCompanyName}
                render={({ field, fieldState }) => (
                  <Box sx={{ marginBottom: "2rem" }}>代表会社名：{companyName}</Box>
                )}
              />
            </Box>
            <Controller
              name="userId"
              control={control}
              rules={validationRules.userId}
              render={({ field, fieldState }) => (
                <FormControl fullWidth error={fieldState.invalid}>
                  <InputLabel>申請者名*</InputLabel>
                  <Select
                    {...field}
                    input={<OutlinedInput label="申請者名" />}
                    MenuProps={MenuProps}
                    id="userId"
                  >
                    {userList.allIds.map((id) => (
                      <MenuItem key={id} value={id} style={getStyles(id, option, theme)}>
                        {userList.entities[id]}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldState.invalid && (
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Box sx={{ marginBottom: "2rem" }}></Box>

            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="purpose"
                control={control}
                rules={validationRules.purpose}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="purpose"
                    label="参加目的"
                    multiline
                    rows={6}
                    type="text"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={4}>
            <Typography mb={2}>プロジェクト情報</Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="workId"
                control={control}
                rules={validationRules.workId}
                defaultValue={workId ?? ""}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel>希望役割*</InputLabel>
                    <Select
                      {...field}
                      input={<OutlinedInput label="希望役割" />}
                      MenuProps={MenuProps}
                    >
                      {category.allIds.map((id) => (
                        <MenuItem key={id} value={id} style={getStyles(id, option, theme)}>
                          {category.entities[id]}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.invalid && (
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Box>
            <Typography mb={2}>希望期間*</Typography>
            {joinData.status === "9" && (
              <Box sx={{ marginBottom: "2rem" }}>
                前回入力対象期間：
                {new Date(joinData.period[0])
                  .toLocaleDateString("ja-JP", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .replace(/\//g, "/")}{" "}
                ～{" "}
                {new Date(joinData.period[1]).toLocaleDateString("ja-JP", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </Box>
            )}
            {list.projectTimeLine && (
              <Box sx={{ marginBottom: "2rem" }}>
                プロジェクト期間：
                {new Date(list.projectTimeLine[0])
                  .toLocaleDateString("ja-JP", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .replace(/\//g, "/")}{" "}
                ～{" "}
                {new Date(list.projectTimeLine[1]).toLocaleDateString("ja-JP", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </Box>
            )}
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="startDate"
                control={control}
                rules={validationRules.startDate}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                      <DatePicker
                        label="開始日"
                        id="startDate"
                        views={["year", "month", "day"]}
                        format="YYYY/MM/DD"
                        onChange={(event) => {
                          onChange(event);
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} />;
                        }}
                        sx={{ width: "100%" }}
                      />
                      {/* <Box sx={{ marginTop: "1rem" }}>
                        <div>fieldState: {JSON.stringify(fieldState)}</div>
                        <div>value: {JSON.stringify(value)}</div>
                        <div>value: {JSON.stringify(value)}</div>
                      </Box> */}
                    </LocalizationProvider>
                    {fieldState.error && (
                      <FormHelperText
                        sx={{
                          display: "block",
                          color: "#f44336",
                          margin: "3px 14px 0",
                        }}
                      >
                        {fieldState.error?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="endDate"
                control={control}
                rules={validationRules.endDate}
                render={({ field: { onChange, value }, fieldState }) => (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
                      <DatePicker
                        label="終了日"
                        id="endDate"
                        views={["year", "month", "day"]}
                        format="YYYY/MM/DD"
                        onChange={(event) => {
                          onChange(event);
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} />;
                        }}
                        sx={{ width: "100%" }}
                      />
                      {/* <Box sx={{ marginTop: "1rem" }}>
                        <div>fieldState: {JSON.stringify(fieldState)}</div>
                        <div>value: {JSON.stringify(value)}</div>
                        <div>value: {JSON.stringify(value)}</div>
                      </Box> */}
                    </LocalizationProvider>
                    {fieldState.error && (
                      <FormHelperText
                        sx={{
                          display: "block",
                          color: "#f44336",
                          margin: "3px 14px 0",
                        }}
                      >
                        {fieldState.error?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Box>
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="estimate"
                control={control}
                rules={validationRules.estimate}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="見積金額"
                    type="number"
                    id="estimate"
                    inputProps={{
                      inputMode: "decimal",
                      step: "1",
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">円</InputAdornment>,
                    }}
                    error={fieldState.invalid}
                    helperText={
                      fieldState.error?.message ?? "設定可能価格：10,000 ~ 999,999,999 円"
                    }
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={4}>
            <Typography mb={2}>見積もり資料</Typography>
            {joinData.status === "9" && (
              <Box sx={{ marginBottom: "2rem" }}>
                前回の見積書類：
                <a href={joinData.quotationMaterials} target="_blank" rel="noopener noreferrer">
                  前回の見積書類をご覧になりたい場合はこちら
                </a>
              </Box>
            )}
            <Box sx={{ marginBottom: "2rem" }}>
              <Controller
                name="quotationMaterials"
                control={control}
                rules={validationRules.quotationMaterials}
                render={({ field, fieldState }) => (
                  <MuiFileInput
                    type="file"
                    fullWidth
                    {...field}
                    error={fieldState.invalid}
                    helperText={
                      fieldState.error?.message ?? "ファイル形式:PDF, ファイルサイズ:5MB以下"
                    }
                  />
                )}
              />
            </Box>
            <Button
              // disabled={!formState.isDirty || !formState.isValid}
              variant="contained"
              fullWidth
              sx={{ color: "#fff" }}
              type="submit"
            >
              申請
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
