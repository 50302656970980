import { Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { PieChart, Pie, Text, Cell } from "recharts";
import questionnaireApi from "../../api/questionnaireApi";

const label = ({ name, value, cx, x, y }) => {
  const textAnchor = x > cx ? "start" : "end";
  return (
    <>
      <Text x={x} y={y} textAnchor={textAnchor} fill="#82ca9d">
        {name + "　" + value}
      </Text>
    </>
  );
};
const ChartColors = ["#7cfc00", "#ffd700", "#c0c0c0", "#cd853f", "#ee82ee"];

export const QuestionnaireSummary = () => {
  let [totalling, setTotalling] = useState([]);
  let [list, setList] = useState([]);
  useEffect(() => {
    async function fetchTotalling() {
      const requestData = {
        params: {
          // TODO 後で定数化を検討
          questionnaireId: "questionnaire1",
        },
      };
      const res = await questionnaireApi.getTotalling(requestData);
      setList(res.result.list);
      // console.log(res.result.list);
      setTotalling(res.result.totalingList);
    }
    fetchTotalling();
  }, []);

  let q2DataValue1 = useState(0);
  let q2DataValue2 = useState(0);
  let q2DataValue3 = useState(0);
  let q4DataValue1 = useState(0);
  let q4DataValue2 = useState(0);
  let q4DataValue3 = useState(0);
  let q6DataValue1 = useState(0);
  let q6DataValue2 = useState(0);
  let q6DataValue3 = useState(0);
  let q8DataValue1 = useState(0);
  let q8DataValue2 = useState(0);
  let q8DataValue3 = useState(0);
  let q8DataValue4 = useState(0);
  if (!totalling || totalling.length > 0) {
    q2DataValue1 = totalling[1][0];
    q2DataValue2 = totalling[1][1];
    q2DataValue3 = totalling[1][2];
    q4DataValue1 = totalling[3][0];
    q4DataValue2 = totalling[3][1];
    q4DataValue3 = totalling[3][2];
    q6DataValue1 = totalling[5][0];
    q6DataValue2 = totalling[5][1];
    q6DataValue3 = totalling[5][2];
    q8DataValue1 = totalling[7][0];
    q8DataValue2 = totalling[7][1];
    q8DataValue3 = totalling[7][2];
    q8DataValue4 = totalling[7][3];
  }

  const q2Data = [
    {
      name: "未回答",
      value: q2DataValue1,
    },
    {
      name: "はい、理解できた",
      value: q2DataValue2,
    },
    {
      name: "いいえ、あまり理解できなかった",
      value: q2DataValue3,
    },
  ];
  const q4Data = [
    {
      name: "未回答",
      value: q4DataValue1,
    },
    {
      name: "はい、得られた",
      value: q4DataValue2,
    },
    {
      name: "いいえ、得られなかった",
      value: q4DataValue3,
    },
  ];
  const q6Data = [
    {
      name: "未回答",
      value: q6DataValue1,
    },
    {
      name: "はい、期待通りだった",
      value: q6DataValue2,
    },
    {
      name: "いいえ、期待通りではなかった",
      value: q6DataValue3,
    },
  ];
  const q8Data = [
    {
      name: "未回答",
      value: q8DataValue1,
    },
    {
      name: "はい、参加したい",
      value: q8DataValue2,
    },
    {
      name: "いいえ、参加したくない",
      value: q8DataValue3,
    },
    {
      name: "わからない",
      value: q8DataValue4,
    },
  ];

  return (
    <>
      <Typography variant="h5">アンケート集計一覧</Typography>
      <br />
      <Box component="form" sx={{ mt: 1 }} noValidate>
        <Box>
          <h4>説明会に参加した理由</h4>
          <Box sx={{ padding: "20px" }}>
            {list.map((item) => (
              <Box key={item.id}>
                <p style={{ fontSize: "12px" }}>{item.question[0]}</p>
              </Box>
            ))}
          </Box>
          <h4>★一般社団法人NCCCの取り組みについて、理解出来ましたか？</h4>
          <PieChart width={1000} height={400}>
            <Pie
              data={q2Data}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#82ca9d"
              label={label}
            >
              {q2Data.map((entry, index) => (
                <Cell key={entry.name} fill={ChartColors[index % ChartColors.length]} />
              ))}
            </Pie>
          </PieChart>
        </Box>
        <Box component="form" sx={{ mt: 1 }} noValidate>
          <h4>あまり理解できなかったとお答えの方へ(その理由)</h4>
          <Box sx={{ padding: "20px" }}>
            {list.map((item) => (
              <Box key={item.id}>
                <p style={{ fontSize: "12px" }}>{item.question[2]}</p>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <h4>★この説明会に参加して、新しい情報や知識を得られましたか？</h4>
          <PieChart width={1000} height={400}>
            <Pie
              data={q4Data}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#82ca9d"
              label={label}
            >
              {q4Data.map((entry, index) => (
                <Cell key={entry.name} fill={ChartColors[index % ChartColors.length]} />
              ))}
            </Pie>
          </PieChart>
        </Box>
        <Box>
          <h4>★この説明会の内容は、あなたの期待に沿っていましたか？</h4>
          <PieChart width={1000} height={400}>
            <Pie
              data={q6Data}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#82ca9d"
              label={label}
            >
              {q6Data.map((entry, index) => (
                <Cell key={entry.name} fill={ChartColors[index % ChartColors.length]} />
              ))}
            </Pie>
          </PieChart>
        </Box>
        <Box component="form" sx={{ mt: 1 }} noValidate>
          <h4>期待通りではなかった。（その理由）</h4>
          <Box sx={{ padding: "20px" }}>
            {list.map((item) => (
              <Box key={item.id}>
                <p style={{ fontSize: "12px" }}>{item.question[6]}</p>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <h4>★今後、一般社団法人NCCC参画に参加したいと思いますか？</h4>
          <PieChart width={1000} height={400}>
            <Pie
              data={q8Data}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#82ca9d"
              label={label}
            >
              {q8Data.map((entry, index) => (
                <Cell key={entry.name} fill={ChartColors[index % ChartColors.length]} />
              ))}
            </Pie>
          </PieChart>
        </Box>
        <Box component="form" sx={{ mt: 1 }} noValidate>
          <h4>参画したくないと答えた方へ(その理由)</h4>
          <Box sx={{ padding: "20px" }}>
            {list.map((item) => (
              <Box key={item.id}>
                <p style={{ fontSize: "12px" }}>{item.question[8]}</p>
              </Box>
            ))}
          </Box>
        </Box>
        <Box component="form" sx={{ mt: 1 }} noValidate>
          <h4>今後、一般社団法人NCCCに期待すること</h4>
          <Box sx={{ padding: "20px" }}>
            {list.map((item) => (
              <Box key={item.id}>
                <p style={{ fontSize: "12px" }}>{item.question[9]}</p>
              </Box>
            ))}
          </Box>
        </Box>
        <Box component="form" sx={{ mt: 1 }} noValidate>
          <h4>その他感想</h4>
          <Box sx={{ padding: "20px" }}>
            {list.map((item) => (
              <Box key={item.id}>
                <p style={{ fontSize: "12px" }}>{item.question[10]}</p>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};
