import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import projectApi from "../../../api/projectApi";
import programApi from "../../../api/programApi";
import reviewerApi from "../../../api/reviewerApi";
import branchUserApi from "../../../api/branchUserApi";
import pdfApi from "../../../api/pdfApi";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Backdrop,
  Box,
  Button,
  Badge,
  CircularProgress,
  Grid,
  Typography
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import "../../../assets/css/Global.css";
import moment from "moment";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useAuthUserContext, RoleType } from "../../../providers/auth";

export const ProjectList = () => {
  const [list, setList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [filter, setFilter] = useState("all");
  const [programIdFilter, setProgramIdFilter] = useState("all");
  const [tabValue, setTabValue] = useState("1");
  const [role, setRole] = useState("");
  const [joinBranches, setJoinBranches] = useState([]);
  const [reviewerInfo, setReviewerInfo] = useState({});
  const [reviewerIdList, setReviewerIdList] = useState([]);
  const [examColumns, setExamColumns] = useState([])
  const [processing, setProcessing] = useState(false);
  const authUser = useAuthUserContext();
  const storedUserInfo = useSelector((state) => state.user);
  const ChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  // 承認済みリスト
  const approvedList = list.filter((project) => project.applyflg === 1);
  // 審査済みリスト
  const examinationList = list.filter(
    (project) => project.applyflg === 1 || project.applyflg === 2
  );
  // 審査待ちリスト
  // const notExaminationList = list.filter((project) => !project.applyflg || project.applyflg === 0 || project.applyflg === 9);
  const notExaminationList = list.filter(
    (project) =>
      (project.applyflg === 0 && ["10", "11", "25"].includes(project.projectStatus)) // 書類審査申請中 or 書類審査再申請中 or 書類審査中
  )
  // 申請中・差戻しリスト
  const remandList = list.filter(
    // (project) => project.applyflg === 9 && storedUserInfo.value._id === project.applyUserId
    (project) =>
      (
        project.projectStatus === "10" || // 申請中
        project.projectStatus === "25" || // 書類審査中
        project.projectStatus === "35" || // 第三者委員会審査中
        project.projectStatus === "29" || // 書類審査差戻し
        project.projectStatus === "39"    // 第三者委員会差戻し
      )
      && (
        storedUserInfo.value.membership === "admin"
        ? true
        : storedUserInfo.value._id === project.createdUserId
      )
  );
  // 一時保存リスト
  const tmpSaveList = list.filter(
    (project) => project.projectStatus === "50" && storedUserInfo.value._id === project.createdUserId
  );

  const joinBranchProjectList = list.filter((project) => joinBranches.includes(project.branchId) && project.applyflg === 1);

  // フィルターを適用したリストを作成
  const filteredList = approvedList.filter(
    (project) =>
      (filter === "all" || project.projectStatus === filter) &&
      (programIdFilter === "all" ||
        (project.programId && project.programId._id === programIdFilter))
  );

  const fetchProjectList = async () => {
    const res = await projectApi.getList();
    const joinBranch = await branchUserApi.getTargetList({
      params: {
        userId: storedUserInfo.value._id,
        applyflg: "1",
      },
    });
    let branches = [];
    for (let i = 0; i < joinBranch.branchUsers.length; i++) {
      branches.push(joinBranch.branchUsers[i].branchId._id);
    }
    setJoinBranches(branches)

    let projects = [];
    let programs = [];
    for (let i = 0; i < res.projects.length; i++) {
      let project = {};
      let projectInfo = [];
      projectInfo.push(res.projects[i]._id);
      projectInfo.push(res.projects[i].projectName);
      project.projectInfo = projectInfo;
      project.projectName = res.projects[i].projectName;
      project.projectStatus = res.projects[i].projectStatus;
      project.projectTimeLine = res.projects[i].projectTimeLine;
      project.programId = res.projects[i].programId;
      project._id = res.projects[i]._id;
      project.applyflg = res.projects[i].applyflg;
      project.companyId = res.projects[i].companyId;
      project.applyUserId = res.projects[i].applyUserId;
      project.createdUserId = res.projects[i].createdUserId;
      // project.applyer1 = res.projects[i].reviewId?.boardChairman;
      // project.applyer2 = res.projects[i].reviewId?.director01;
      // project.applyer3 = res.projects[i].reviewId?.director02;
      // project.applyer4 = res.projects[i].reviewId?.director03;
      // project.applyer5 = res.projects[i].reviewId?.director04;
      // project.applyer6 = res.projects[i].reviewId?.director05;
      // project.auditor = res.projects[i].reviewId?.auditor;
      reviewerInfo.reviewer.workingGroup.forEach((reviewer) => {
        switch (res.projects[i].projectStatus) {
          case "10":
          case "25":
            project[reviewer.role] = res.projects[i].reviewId?.result?.documentExam[reviewer.role]
            break
          case "20":
            project[reviewer.role] = res.projects[i].reviewId?.result?.validationExam
              ? res.projects[i].reviewId?.result?.validationExam[reviewer.role]
              : "";
            break
          default:
            break
        }
      })

      if (res.projects[i].reviewId) {
        project.isNotice = res.projects[i].reviewId[storedUserInfo.value.directorCode] !== 0
      }

      if (res.projects[i].branchId) {
        project.branchId = res.projects[i].branchId._id;
        project.branchName = res.projects[i].branchId.branchName;
        project.branchMaster = res.projects[i].branchId.branchMasterId;
        if (branches.includes(res.projects[i].branchId._id) ||
          storedUserInfo.value._id === res.projects[i].branchId.branchMasterId
        ) {
          project.branchJoinFlg = "1";
        } else {
          project.branchJoinFlg = "0";
        }
      }
      projects.push(project);
      // プログラム名選択リスト用情報取得
      const program = {};
      if (1 === res.projects[i].applyflg && 
        (1 === project.programId?.applyflg || 4 === project.programId?.applyflg)) {
        program._id = project.programId?._id;
        program.programName = project.programId?.programName;
        programs.push(program);
      }
    }
    setList(projects);
    // プログラム名選択リスト作成
    const filteredProgramList = Array.from(
      new Map(programs.map((program) => [program._id, program])).values()
    );
    setProgramList(filteredProgramList);
  }

  const fetchReviewer = async () => {
    const reviewers = await reviewerApi.get({
      params: {
        funcId: "project"
      }
    });
    setReviewerInfo(reviewers)

    const reviewerIds = reviewers.reviewer.workingGroup.map((reviewer) => {
      return reviewer.userId
    })
    setReviewerIdList(reviewerIds)

    const examinationColumns = [
      {
        field: "isNotice",
        headerName: "",
        width: 5,
        renderCell: (params) => {
          return <Box>{params.value ? "" : <Badge color="warning" variant="dot"></Badge>}</Box>;
        },
      },
      {
        field: "_id",
        headerName: "詳細",
        renderCell: (params) => {
          return (
            <>
              <Link to={"/member/projects/apply/" + params.value}>
                <Button size="small" variant="contained" sx={{ fontSize: "5px", fontWeight: 600 }}>
                  詳細へ
                </Button>
              </Link>
            </>
          );
        },
      },
      {
        field: "projectName",
        headerName: "プロジェクト名",
        width: 250,
      },
      {
        field: "projectTimeLine",
        headerName: "期間",
        width: 200,
        renderCell: (params) => {
          return (
            <div style={{ flexBasis: "300px" }}>
              {params.value[0] && moment(params.value[0]).format("YYYY/MM/DD")}
              {params.value[0] && " - "}
              {params.value[1] && moment(params.value[1]).format("YYYY/MM/DD")}
            </div>
          );
        },
      },
      {
        field: "programId",
        headerName: "プログラム名",
        width: 250,
        renderCell: (params) => {
          return params.value && <div style={{ flexBasis: "300px" }}>{params.value.programName}</div>;
        },
      },
      {
        field: "applyflg",
        headerName: "Status",
        width: 75,
        renderCell: (params) => {
          if (params.value === 9) {
            return (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  border: "1px solid #FFCA28",
                  borderRadius: "20px",
                  color: "#FFCA28",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                差戻し
              </Grid>
            );
          } else {
            return (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  border: "1px solid #E53935",
                  borderRadius: "20px",
                  fontSize: "12px",
                  color: "#E53935",
                }}
              >
                未承認
              </Grid>
            );
          } 
        },
      },
    ];

    reviewers?.reviewer?.workingGroup?.forEach((reviewer) => {
      examinationColumns.push({
        field: reviewer?.role,
        headerName: reviewer?.label,
        width: 75,
        renderCell: reviewer?.role === "auditor" ? auditorComponent : approvalComponent,
      })
    })
    setExamColumns(examinationColumns)
  }

  useEffect(() => {
    fetchReviewer()
    // 権限の設定
    setRole(authUser.roleType);
  }, []);

  // fetchProjectList内でreviewerInfoを使用している箇所があるため、
  // reviewerInfo取得後に実行する
  useEffect(() => {
    if (Object.keys(reviewerInfo).length !== 0) {
      fetchProjectList();
    }
  }, [reviewerInfo])

  // セレクトボックスの値が変更されたときにフィルターを更新する関数
  const handleFilterChange = (event) => {
    // setPageNumber(0); // ページ番号を最初のページにリセットする
    setFilter(event.target.value);
  };

  // programIdのセレクトボックスの値が変更されたときにフィルターを更新する関数
  const handleProgramIdFilterChange = (event) => {
    // setPageNumber(0);
    const value = event.target.value;
    setProgramIdFilter(value);
  };

  const [pdfUrl, setPdfUrl] = useState("")
  const [pdfUpdated, setPdfUpdated] = useState(false)
  const makePdf = async (params) => {
    if (!window.confirm("本機能は開発中です。表示崩れや項目不足が存在する可能性があります。\n印刷を続けますか？")) return
    setProcessing(true)
    const payload = await projectApi.getById({
      params: {
        _id: params.id
      },
    })
    const pdf = await pdfApi.makeProjectDesignDoc(payload.project)
    setPdfUrl(pdf.url)
    setPdfUpdated(true)
    // window.open(pdf.url, "_blank")
    // setProcessing(false)
    return pdf.url
  }
  // makePdf関数内でwindow.openすると開かない(開いても真っ白)になるため、
  // URLがsetされたら開くようにする（PDFが大きいせい？）
  // 開くのに5秒以上経過するとChromeにブロックされる(ユーザ側でクリック等の操作がない場合)
  // 参考：https://mangano-ito.hatenablog.com/entry/2020/04/30/133527
  useEffect(() => {
    if (pdfUpdated) {
      window.open(pdfUrl, "_blank")
      setProcessing(false)
    }
    setPdfUpdated(false)
  }, [pdfUpdated, pdfUrl])

  const deleteProject = async (params) => {
    if (!window.confirm(`${params.row.projectName} のデータを削除します。\n本操作は取り消せません。よろしいですか？`)) return
    setProcessing(true)
    await projectApi.delete({
      params: {
        _id: params.id
      },
    })
    fetchProjectList();
    window.alert("削除が完了しました。")
    setProcessing(false)
  }

  const approvalComponent = (params) => {
    if (params.value === 1) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #388E3C",
            borderRadius: "20px",
            color: "#ffffff",
            backgroundColor: "#388E3C",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          承認
        </Grid>
      );
    } else if (params.value === 2) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #FFCA28",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#FFCA28",
          }}
        >
          否認
        </Grid>
      );
    } else if (params.value === 9) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #FFCA28",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#FFCA28",
          }}
        >
          差戻し
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #E53935",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#E53935",
          }}
        >
          未対応
        </Grid>
      );
    }
  };
  const auditorComponent = (params) => {
    if (params.value === 1) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #388E3C",
            borderRadius: "20px",
            color: "#ffffff",
            backgroundColor: "#388E3C",
            fontSize: "12px",
          }}
        >
          異議なし
        </Grid>
      );
    } else if (params.value === 2) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #FFCA28",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#FFCA28",
          }}
        >
          異議あり
        </Grid>
      );
    } else if (params.value === 9) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #FFCA28",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#FFCA28",
          }}
        >
          差戻し
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            border: "1px solid #E53935",
            borderRadius: "20px",
            fontSize: "12px",
            color: "#E53935",
          }}
        >
          未対応
        </Grid>
      );
    }
  };

  const columns = [
    {
      field: "projectInfo",
      headerName: "プロジェクト名",
      width: 300,
      renderCell: (params) => {
        return <div style={{ flexBasis: "300px" }}>{params.value[1]}</div>;
      },
    },
    {
      field: "projectStatus",
      headerName: "募集状況",
      width: 140,
      renderCell: (params) => {
        return (
          <div style={{ flexBasis: "300px" }}>
            {params.value === "0" && <span>開始前</span>}
            {params.value === "1" && <span>募集中</span>}
            {params.value === "2" && <span>作業中</span>}
            {params.value === "3" && <span>完了</span>}
            {params.value === "4" && <span>中止</span>}
          </div>
        );
      },
    },
    {
      field: "projectTimeLine",
      headerName: "期間",
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ flexBasis: "300px" }}>
              {params.value[0] && moment(params.value[0]).format("YYYY/MM/DD")}
              {params.value[0] && " - "}
              {params.value[1] && moment(params.value[1]).format("YYYY/MM/DD")}
          </div>
        );
      },
    },
    {
      field: "programId",
      headerName: "プログラム名",
      width: 300,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "300px" }}>{params.value.programName}</div>;
      },
    },
    {
      field: "applyflg",
      headerName: "承認ステータス",
      width: 150,
      renderCell: (params) => {
        const applyflg = params.value;
        let statusText = "";
        let statusColor = "";

        if (applyflg === 0) {
          statusText = "未審査";
          statusColor = "orange";
        } else if (applyflg === 1) {
          statusText = "承認";
          statusColor = "aqua";
        } else if (applyflg === 2) {
          statusText = "否認";
          statusColor = "magenta";
        }

        return (
          <Box sx={{ p: 1 }}>
            <Typography component="body2" style={{ color: statusColor }}>
              {statusText}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "_id",
      headerName: "",
      width: 100,
      align: "center",
      renderCell: (params) => {
        return (
          <Link to={`/member/projects/${params.value}`}>
            <Button variant="contained" sx={{ fontSize: "5px" }}>
              詳細
            </Button>
          </Link>
        );
      },
    },
    // {
    //   field: "progress",
    //   // headerName: "プロジェクト管理",
    //   headerName: "",
    //   width: 200,
    //   align: "center",
    //   renderCell: (params) => {
    //     if (role === RoleType.BoardOfDirectors) {
    //       return (
    //         <Link to={`/member/projects/manage/${params.row._id}`}>
    //           <Button variant="contained" color="success" sx={{ fontSize: "5px" }}>
    //             管理
    //           </Button>
    //         </Link>
    //       );
    //     } else if (params.row.projectStatus !== "1" && params.row.projectStatus !== "0") {
    //       return (
    //         <Link to={`/member/projects/progress/${params.row._id}`}>
    //           <Button variant="contained" sx={{ fontSize: "5px" }}>
    //             進捗
    //           </Button>
    //         </Link>
    //       );
    //     } else {
    //       return <Link to={`/member/projects/progress/${params.row._id}`}></Link>;
    //     }
    //   },
    // },
  ];

  const approvedColumns = [
    {
      field: "projectInfo",
      headerName: "プロジェクト名",
      width: 300,
      renderCell: (params) => {
        if (role === RoleType.Admin ||
          role === RoleType.ThirdPartyCommittee ||
          params.row.branchJoinFlg === "1") {
          return <Link to={`/member/projects/${params.value[0]}`}>{params.value[1]}</Link>;
        } else {
          return <div style={{ flexBasis: "300px" }}>{params.value[1]}</div>;
        }
      },
    },
    {
      field: "projectStatus",
      headerName: "プロジェクトステータス",
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ flexBasis: "300px" }}>
            {params.value === "0" && <span>開始前</span>}
            {params.value === "1" && <span>募集中</span>}
            {params.value === "2" && <span>作業中</span>}
            {params.value === "3" && <span>完了</span>}
            {params.value === "4" && <span>中止</span>}
          </div>
        );
      },
    },
    {
      field: "projectTimeLine",
      headerName: "期間",
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ flexBasis: "300px" }}>
              {params.value[0] && moment(params.value[0]).format("YYYY/MM/DD")}
              {params.value[0] && " - "}
              {params.value[1] && moment(params.value[1]).format("YYYY/MM/DD")}
          </div>
        );
      },
    },
    {
      field: "branchName",
      headerName: "支部名",
      width: 300,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "300px" }}>{params.value}</div>;
      },
    },
    {
      field: "programId",
      headerName: "プログラム名",
      width: 300,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "300px" }}>{params.value.programName}</div>;
      },
    },
    {
      field: "_id",
      // headerName: "プロジェクト参加申請",
      headerName: "",
      width: 200,
      align: "center",
      renderCell: (params) => {
        // 第三者委員会でログイン
        if (role === RoleType.ThirdPartyCommittee) {
          if (params.row.projectStatus !== "0" && params.row.projectStatus !== "1") {
            return (
              <Link to={`/member/projects/progress/${params.row._id}`}>
                <Button variant="contained" sx={{ fontSize: "5px" }}>
                  進捗
                </Button>
              </Link>
            );
          } else {
            return null;
          }
        // 支部長の場合かつ、プロジェクトの編集が可能な場合
        } else if (
          storedUserInfo.value._id === params.row?.branchMaster &&
          (params.row?.projectStatus === "0" || params.row?.projectStatus === "1")
        ) {
          return (
            <Link to={`/member/projects/${params.value}/edit`}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                修正
              </Button>
            </Link>
          );
        // 支部に参加していない場合
        } else if (params.row.branchJoinFlg === "0") {
          return (
            <Link to={"/member/branches/" + params.row.branchId + "/join"}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                支部参加申請
              </Button>
            </Link>
          );
        // 支部には参加済みでプロジェクトのステータスが募集中の場合
        } else if (params.row.projectStatus === "1" && params.row.branchJoinFlg === "1") {
          return (
            <Link to={`/member/projects/${params.value}/join`}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                プロジェクト参加申請
              </Button>
            </Link>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "progress",
      // headerName: "プロジェクト管理",
      headerName: "",
      width: 200,
      align: "center",
      renderCell: (params) => {
        // 支部長の場合
        if (storedUserInfo.value._id === params.row?.branchMaster) {
          return (
            <Link to={`/member/projects/manage/${params.row._id}`}>
              <Button variant="contained" color="success" sx={{ fontSize: "5px" }}>
                管理
              </Button>
            </Link>
          );
        } else if (params.row.projectStatus !== "1" && params.row.projectStatus !== "0" &&
          params.row.branchJoinFlg === "1") {
          return (
            <Link to={`/member/projects/progress/${params.row._id}`}>
              <Button variant="contained" sx={{ fontSize: "5px" }}>
                進捗
              </Button>
            </Link>
          );
        } else {
          return <Link to={`/member/projects/progress/${params.row._id}`}></Link>;
        }
      },
    },
  ];

  const remandColumns = [
    {
      field: "projectName",
      headerName: "プロジェクト名",
      width: 300,
    },
    {
      field: "projectTimeLine",
      headerName: "期間",
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ flexBasis: "300px" }}>
              {params.value[0] && moment(params.value[0]).format("YYYY/MM/DD")}
              {params.value[0] && " - "}
              {params.value[1] && moment(params.value[1]).format("YYYY/MM/DD")}
          </div>
        );
      },
    },
    {
      field: "programId",
      headerName: "プログラム名",
      width: 300,
      renderCell: (params) => {
        return params.value && <div style={{ flexBasis: "300px" }}>{params.value.programName}</div>;
      },
    },
    {
      field: "_id",
      headerName: "",
      width: 200,
      align: "center",
      renderCell: (params) => {
        switch (params.row.projectStatus) {
          case "10":
            return "申請中"
          case "25":
            return "書類審査中"
          case "35":
            return "第三者委員会審査中"
          case "29":
          case "39":
            return (
              <Link to={"/member/projects/" + params.value + "/edit"}>
                <Button variant="contained" sx={{ fontSize: "5px" }}>
                  修正
                </Button>
              </Link>
            )
          default:
            return "申請中"
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<DownloadIcon />}
          onClick={() => makePdf(params)}
        />,
      ]
    },
  ];

  const tmpSaveColumns = [
    {
      field: "projectName",
      headerName: "プロジェクト名",
      width: 300,
    },
    {
      field: "_id",
      headerName: "",
      width: 200,
      align: "center",
      renderCell: (params) => {
        return (
          <Link to={"/member/projects/" + params.value + "/edit"}>
            <Button variant="contained" sx={{ fontSize: "5px" }}>
              編集
            </Button>
          </Link>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "操作",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          label="edit"
          showInMenu={false}
          icon={<DownloadIcon />}
          onClick={() => makePdf(params)}
        />,
        <GridActionsCellItem
          key={params.id}
          label="delete"
          showInMenu={false}
          icon={<DeleteIcon />}
          onClick={() => deleteProject(params)}
        />,
      ]
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const pageSizeOptions = [25, 50, 100];

  return (
    <Box>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom>
          プロジェクト一覧
        </Typography>
      </div>
      {/* 承認者以外の表示 */}
      {role !== RoleType.BoardOfDirectors && (
        <div>
          {/* セレクトボックスを追加 */}
          <div style={{ marginBottom: "20px" }}>
            <Typography variant="body1" gutterBottom>
              プログラム名：
              <select value={programIdFilter} onChange={handleProgramIdFilterChange}>
                <option value="all">すべて</option>
                {programList.map((program) => (
                  <option key={program._id} value={program._id}>
                    {program.programName}
                  </option>
                ))}
              </select>
            </Typography>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Typography variant="body1" gutterBottom>
              ステータス：
              <select value={filter} onChange={handleFilterChange}>
                <option value="all">すべて</option>
                <option value="0">開始前</option>
                <option value="1">募集中</option>
                <option value="2">作業中</option>
                <option value="3">完了</option>
                <option value="4">中止</option>
              </select>
            </Typography>
          </div>
        </div>
      )}

      <Box sx={{ width: "100%", typography: "body1" }}>
        {/* 管理者の表示（承認者を除く） */}
        {
          !reviewerIdList.includes(storedUserInfo.value._id) &&
          [RoleType.SystemAdmin, RoleType.Admin].includes(role) && (
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                  <Tab label="一覧" value="1" />
                  <Tab label="申請中・差戻し" value="2" />
                  <Tab label="一時保存" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={examinationList}
                      columns={columns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={remandList}
                      columns={remandColumns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value="3">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={tmpSaveList}
                      columns={tmpSaveColumns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>
            </TabContext>
        )}
        {/* 承認者の表示 */}
        {reviewerIdList.includes(storedUserInfo.value._id) && (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                <Tab label="審査待ち" value="1" />
                <Tab label="審査済み" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box sx={{ display: "flex", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={notExaminationList}
                    // columns={examinationColumns}
                    columns={examColumns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box sx={{ display: "max-content", padding: "5px" }}>
                <div style={{ height: "max-content", width: "100%" }}>
                  <DataGrid
                    rows={examinationList}
                    columns={columns}
                    getRowId={(list) => list._id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageSizeOptions}
                    autoHeight
                  />
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        )}
        {/* 理事会の表示（承認者を除く） */}
        {
          !reviewerIdList.includes(storedUserInfo.value._id) &&
          [RoleType.BoardOfDirectors].includes(role) && (
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                  <Tab label="一覧" value="1" />
                  <Tab label="申請中・差戻し" value="2" />
                  {/* <Tab label="一時保存" value="3" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={examinationList}
                      columns={columns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={remandList}
                      columns={remandColumns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>

              {/* <TabPanel value="3">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={tmpSaveList}
                      columns={tmpSaveColumns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel> */}
            </TabContext>
          )
        }
        {/* 正会員の表示（承認者を除く） */}
        {
          !reviewerIdList.includes(storedUserInfo.value._id) &&
          role === RoleType.FullMember && (
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={ChangeTab} aria-label="lab API tabs example">
                  <Tab label="一覧" value="1" />
                  <Tab label="所属支部一覧" value="2" />
                  <Tab label="申請中・差戻し" value="3" />
                  <Tab label="一時保存" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box sx={{ display: "flex", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={filteredList}
                      columns={approvedColumns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={joinBranchProjectList}
                      columns={approvedColumns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value="3">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={remandList}
                      columns={remandColumns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value="4">
                <Box sx={{ display: "max-content", padding: "5px" }}>
                  <div style={{ height: "max-content", width: "100%" }}>
                    <DataGrid
                      rows={tmpSaveList}
                      columns={tmpSaveColumns}
                      getRowId={(list) => list._id}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pageSizeOptions={pageSizeOptions}
                      autoHeight
                    />
                  </div>
                </Box>
              </TabPanel>
            </TabContext>
          )
        }
        {/* 管理者、理事会、正会員以外の表示 */}
        {
          !reviewerIdList.includes(storedUserInfo.value._id) &&
          !([RoleType.Admin, RoleType.BoardOfDirectors, RoleType.FullMember].includes(role)) && (
          // {role !== RoleType.BoardOfDirectors && role !== RoleType.FullMember && (
              <Box sx={{ display: "flex", padding: "5px" }}>
              <div style={{ height: "max-content", width: "100%" }}>
                <DataGrid
                  rows={filteredList}
                  columns={approvedColumns}
                  getRowId={(list) => list._id}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={pageSizeOptions}
                  autoHeight
                />
              </div>
            </Box>
          )
        }
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};