import axiosClient from "./axiosClient";

const eventApi = {
  register: (params) => axiosClient.post("event/register", params),
  application: (params) => axiosClient.post("event/application", params),
  getList: (params) => axiosClient.get("event/list", params),
  updateById: (params) => axiosClient.put("event/updateById", params),
  getById: (params) => axiosClient.post("event/getById", params),
};

export default eventApi;
